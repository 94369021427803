import React from 'react'
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormRange, FormRangeProps } from '../Form/Range'
import { FormSwitch } from '../Form/Switch'
import { defaultEventSettingUnitsByType, defaultEventSettingTooltipType } from '../../../models/EventSetting'
import { groupType } from '../../../constants/groupType'
import readings from '../../../images/icons/readings.svg'
import voltage from '../../../images/icons/voltage.svg'
import { SiteSetting } from '../../../models/SiteSetting'

const GroupTypeIcon = (props: { groupTypeId: string }) => {
	switch (props.groupTypeId) {
		case groupType.Temperature.id:
			return <img src={readings} alt="Temperature Icon" style={styles.icon} />
		case groupType.Battery.id:
			return <img src={voltage} alt="Voltage Icon" style={styles.icon} />
		default:
			return null
	}
}

interface SiteSettingFieldProps {
	siteSetting: SiteSetting
	index: number
	isDisabled: boolean
	isReadOnly: boolean
	formRangeProps?: Partial<FormRangeProps>
	setFieldValue: (field: string, value: unknown) => void
}

const SiteSettingField = (props: SiteSettingFieldProps) => {
	return (
		<>
			<OverlayTrigger
				placement="top"
				overlay={
					<Tooltip id={`toolTip_${props.siteSetting.siteSetting_Value}`}>
						{defaultEventSettingTooltipType[props.siteSetting.eventSettingType_Name]}
					</Tooltip>
				}
			>
				<Col sm="auto" style={styles.col}>
					<GroupTypeIcon groupTypeId={groupType.Temperature.id} />
				</Col>
			</OverlayTrigger>
			<Col sm={8} style={styles.col}>
				<FormRange
					name={`siteSettings[${props.index}].siteSetting_Value`}
					value={props.siteSetting.siteSetting_Value}
					onChange={(e) => props.setFieldValue(`siteSettings[${props.index}].siteSetting_Value`, Number(e.target.value))}
					setFieldValue={props.setFieldValue}
					label={props.siteSetting.eventSettingType_Name}
					min={0}
					max={100}
					unit={defaultEventSettingUnitsByType[props.siteSetting.eventSettingType_Name] || ''}
					disabled={props.isDisabled || !props.siteSetting.siteSetting_IsEnabled}
					readOnly={props.isReadOnly}
					{...props.formRangeProps}
				/>
			</Col>
			<Col sm={3} style={styles.col}>
				<FormSwitch
					name={`siteSettings[${props.index}].siteSetting_IsEnabled`}
					switchLabel={props.siteSetting.siteSetting_IsEnabled ? 'Enabled' : 'Disabled'}
					checked={props.siteSetting.siteSetting_IsEnabled}
					onChange={(e) => props.setFieldValue(`siteSettings[${props.index}].siteSetting_IsEnabled`, e.target.checked)}
					disabled={props.isDisabled}
					readOnly={props.isReadOnly}
				/>
			</Col>
		</>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	col: {
		marginRight: '20px',
	},
	icon: {
		width: '50px',
		height: '50px',
		marginRight: '20px',
	},
}

export { SiteSettingField }
