import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { ChannelMap, defaultChannelMap } from '../../../models/ChannelMap'
import { ChannelMapDetailProps } from './ChannelMaps'
import { FormSelect, SelectOption } from '../Form/Select'
import { readingTypeDropdownValues } from '../../../constants/readingType'
import { v4 as uuidv4 } from 'uuid'
import { defaultCreated } from '../../../models/History'
import { hasPermission } from '../../../utilities/permissions/permission'
import { AppContext } from '../../../App'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../utilities/permissions/permission.d'

const ChannelMapDetail = (props: ChannelMapDetailProps) => {
	const { appState } = React.useContext(AppContext)
	// currently POSTPUTDELETE permissions are identical
	const hasEditPermissions = hasPermission(PermissionModelObject.ChannelMap, PermissionModelAction.POST, appState, PermissionModelContext.Site)
	const assetOptions = React.useMemo(() => {
		return props.assetOptions.map((a) => ({ label: a.asset_Name ? a.asset_Name : '', value: a.asset_Id }))
	}, [props.assetOptions])

	const formattedReadingTypeDropdownValues = readingTypeDropdownValues.map((opt) => ({
		...opt,
		label: opt.label.replace('Temperature Reading ', '').replace('Flow Reading ', ''),
	}))

	const handleSelection = (channelMap: ChannelMap) => {
		if (props.handleChange) {
			if (channelMap.channelMap_Id) {
				// Existing Channel Map
				const existingChannelMap = props.asset.channelMap.find((c: ChannelMap) => c.channelMap_Id === channelMap.channelMap_Id)
				const index = props.asset.channelMap.indexOf(existingChannelMap)

				// Channel map does not exist in database
				if (!existingChannelMap.created.create_UserId) {
					props.handleChange(`asset.channelMap[${index}]`, channelMap)
				} else {
					// Channel map does exist in database, remove old one and add new one
					props.handleChange(
						`asset.channelMap[${index}]`,
						defaultChannelMap({ channelMap_Id: existingChannelMap.channelMap_Id, created: existingChannelMap.created })
					)
					const newChannelMap = {
						...channelMap,
						channelMap_Id: uuidv4(),
						created: defaultCreated({}),
					}
					props.handleChange(`asset.channelMap[${props.asset.channelMap.length}]`, newChannelMap)
				}
			} else {
				// New Channel Map record
				const newChannelMap = {
					...channelMap,
					channelMap_Id: uuidv4(),
					created: defaultCreated({}),
				}
				props.handleChange(`asset.channelMap[${props.asset.channelMap.length}]`, newChannelMap)
			}
		}
	}

	return (
		<Row className="align-items-center justify-content-center">
			<span className="channel-detail">{props.channelMapStructure.label}</span>
			<span className="channel-detail">
				{props.pageStatus != 'Editing' ? (
					props.channelMap ? (
						<Link to={`/asset/${props.channelMap.tmv_Asset.asset_Id}`}>
							{props.channelMap.tmv_Asset.asset_Name} {props.channelMap.readingType_Name}
						</Link>
					) : (
						`-`
					)
				) : (
					<Row>
						<Col>
							<Row style={styles.row}>
								<Col>
									<FormSelect
										name={'ChannelMapDetail_AssetOptions'}
										options={assetOptions}
										value={props.channelMap?.channelMap_TmvAssetId}
										onChange={(e) => {
											const channelMap: ChannelMap = {
												...props.channelMap,
												channelMap_TmvAssetId: e ? (e as SelectOption<string>).value : '',
												readingType_Id: props.channelMap.readingType_Id || props.channelMapStructure.readingTypeDefault,
											}
											handleSelection(channelMap)
										}}
										isClearable
										isDisabled={!hasEditPermissions}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormSelect
										name={'ChannelMapDetail_ReadingTypeOptions'}
										options={formattedReadingTypeDropdownValues.filter((r) => props.channelMapStructure.readingTypeFilter(r.value))}
										value={props.channelMap?.readingType_Id}
										onChange={(e) => {
											const channelMap: ChannelMap = {
												...props.channelMap,
												readingType_Id: e ? (e as SelectOption<string>).value : '',
											}
											handleSelection(channelMap)
										}}
										isClearable
										isDisabled={props.channelMapStructure.readingTypeFixed || !hasEditPermissions}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
			</span>
		</Row>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	row: {
		marginBottom: '10px',
	},
}

export { ChannelMapDetail }
