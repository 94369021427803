export const ReportStyles = {
	w100: { width: '100%' },
	headerImg: { height: '50px' },
	headerName: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '28px',
	},
	headerTs: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	headerTitle: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		borderLeft: '2px var(--color-primary-cyan) solid',
		paddingLeft: '10px',
		height: '100%',
	},
	theadTitleRow: { borderBottom: '6px rgb(0, 74, 143) solid' },
	theadColNameRow: { borderBottom: '2px var(--color-primary-cyan) solid' },
	bottomBorder: { borderBottom: '1px var(--color-primary-cyan) solid' },
	cell: { padding: '2px 10px 2px 5px', fontSize: '14px' },
	cellLast: { padding: '2px 0', fontSize: '14px' },
	centeredCell: { textAlign: 'center' },
	topAlignedCell: { verticalAlign: 'top' },
	largeFont: { fontSize: '18px' },
	lowGrade: { backgroundColor: 'rgb(193, 231, 247)' },
	highGrade: { backgroundColor: 'rgb(226, 135, 94)' },
	exceptionBackground: { backgroundColor: 'rgb(233, 167, 137)' },
	thermalFlushBackground: { backgroundColor: 'rgb(255, 115, 115)' },
	lightText: { color: 'rgb(90, 190, 201)' },
	calBox: { width: '100px' },
	sectionTitle: { fontFamily: 'Helvetica', fontSize: '20px', fontStyle: 'normal', fontWeight: '700', lineHeight: '20px' },
} as const satisfies Record<string, React.CSSProperties>
