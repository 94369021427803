import React from 'react'
import { AppContext } from '../../../App'
import { Link } from 'react-router-dom'
import { Row, Col, Button, Container } from 'react-bootstrap'
import Logo from '../../../images/Logo.svg'
import LogoBlack from '../../../images/LogoBlack.svg'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { UserPermissionResult } from '../../../models/User'
import * as Request from '../../../utilities/request'
import { FormText } from '../../UI/Form/Text'
import bcrypt from 'bcryptjs'
import Cookies from 'universal-cookie'

const LoginSchema = Yup.object().shape({
	email: Yup.string().required('Email Address is required'),
	password: Yup.string().required('Password is required'),
})

const AuthComponentOffline = () => {
	const context = React.useContext(AppContext)
	const [message, setMessage] = React.useState('')
	const cookies = new Cookies()

	const handleSubmit = async (values: { email: string; password: string }) => {
		const tempAuthState = {
			isLoggedIn: true,
			apiToken: null,
			user_AzureId: null,
		}

		// Get user details from database
		const [userResponse] = await Promise.all([Request.get<UserPermissionResult>(`user?User_Email=${encodeURIComponent(values.email)}`, tempAuthState)])
		const user = userResponse.data.users.length > 0 ? userResponse.data.users[0].user : undefined

		if (user && user.user_OfflinePassword && bcrypt.compareSync(values.password, user.user_OfflinePassword)) {
			cookies.set('user', user, { path: '/', sameSite: true })
			cookies.set('accessToken', 'offline', { path: '/', sameSite: true })
			cookies.set('userSites', userResponse.data.users[0].userSites, { path: '/', sameSite: true })

			context.setAppState({
				state: 'signedIn',
				data: { accessToken: 'offline', userAttributes: user, userSites: userResponse.data.users[0].userSites },
			})
		} else {
			setMessage('Invalid email or password')
		}
	}

	React.useEffect(() => {
		const cookieAccessToken = cookies.get('accessToken')
		const cookieUser = cookies.get('user')
		const cookieUserSites = cookies.get('userSites')

		if (cookieAccessToken && cookieUser) {
			context.setAppState({
				state: 'signedIn',
				data: { accessToken: cookieAccessToken, userAttributes: cookieUser, userSites: cookieUserSites },
			})
		}
	}, [])

	return context.appState.authState.isLoggedIn ? null : (
		<div>
			<Row className="page-header">
				<Link to="/" className="page-header-title">
					<img src={Logo} alt={'Enware Smart FLow Logo'} />
				</Link>
			</Row>

			<Row style={styles.loginPage}>
				<Col>
					<Formik initialValues={{ email: '', password: '' }} validationSchema={LoginSchema} onSubmit={handleSubmit} enableReinitialize>
						{({ handleSubmit, isSubmitting, touched, errors, values, handleChange }) => (
							<Form>
								<Row>
									<Col>
										<img src={LogoBlack} alt={'Enware Smart FLow Logo'} />
									</Col>
								</Row>

								<Row>
									<Col>Sign In</Col>
								</Row>
								<Row>
									<Col>Sign in with your email address</Col>
								</Row>
								<Container style={styles.loginContainer}>
									<Row style={styles.loginRow}>
										<Col>
											<FormText
												name="email"
												type="email"
												value={values.email}
												onChange={handleChange}
												placeholder="Email Address"
												required
												feedback={touched.email && errors.email ? errors.email : ''}
												isInvalid={!!errors.email}
											/>
										</Col>
									</Row>
									<Row style={styles.loginRow}>
										<Col>
											<FormText
												name="password"
												type="password"
												value={values.password}
												onChange={handleChange}
												placeholder="Password"
												required
												feedback={touched.password && errors.password ? errors.password : ''}
												isInvalid={!!errors.password}
											/>
										</Col>
									</Row>
									<Row style={styles.loginRow}>
										<Col>
											<span className="invalid-feedback" style={styles.loginFeedback}>
												{message}
											</span>
										</Col>
									</Row>
									<Row style={styles.loginRow}>
										<Col>
											<Button
												disabled={isSubmitting}
												type="submit"
												onClick={() => {
													handleSubmit()
												}}
											>
												Sign In
											</Button>
										</Col>
									</Row>
								</Container>
							</Form>
						)}
					</Formik>
				</Col>
			</Row>
		</div>
	)
}

const styles = {
	loginPage: {
		textAlign: 'center' as const,
		marginTop: '5%',
	},
	loginContainer: {
		maxWidth: '600px',
	},
	loginRow: {
		paddingTop: '10px',
	},
	loginFeedback: {
		display: 'block',
	},
}

export { AuthComponentOffline }
