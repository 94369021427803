import React, { useMemo } from 'react'
import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { Row, Col } from 'react-bootstrap'
import { AssetList, AssetListResult, defaultAssetList } from '../../models/Asset'
import { PageStatus } from '../../types/PageStatus'
import PickerHeader from '../../components/UI/ContextualPickers/PickerHeader'
import { EventResult, Event } from '../../models/Event'
import { SiteSystemStatus, SiteSystemStatusResult, defaultSiteSystemStatus } from '../../models/Site'
import { userRole } from '../../constants/userRole'
import { formatIncomingDateTime } from '../../utilities/formatDate'
import { Loading } from '../../components/UI/Loading/Loading'
import { EventListing } from '../../components/UI/Event/EventListing'
import { Card } from '../../components/UI/Card/Card'
import { SiteStatusIcon } from '../../components/UI/SiteDashboard/SiteStatusIcon'
import { useInterval } from '../../utilities/useInterval'
import { severity } from '../../constants/severity'

interface GroupedEvents {
	eventSettingType_Name: string
	events: (Event & AssetList)[]
}

const CurrentStatus = () => {
	const context = React.useContext(AppContext)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [siteStatus, setSiteStatus] = React.useState<SiteSystemStatus>(defaultSiteSystemStatus({}))
	const [eventsAndAssets, setEventsAndAssets] = React.useState<(Event & AssetList)[] | null>(null)

	const getData = async () => {
		const [eventsReq, assetsReq, readingReq] = await Promise.all([
			Request.get<EventResult>(`event?site_Id=${context.appState.currentSite?.site_Id}&CurrentOnly=true`, context.appState.authState),
			Request.get<AssetListResult>(`asset?site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
			Request.get<SiteSystemStatusResult>(`site/systemStatus?Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
		])

		setSiteStatus(readingReq.data.sites[0])

		// Filter out events that don't have an asset_Id (site events)
		setEventsAndAssets(
			eventsReq.data.events
				.filter((e) => e.asset_Id)
				.map((event) => ({
					...event,
					create_Ts: formatIncomingDateTime({
						dateTime: event.create_Ts,
						format: 'DateAndTime',
						timeZone: context.appState.currentSite?.site_Timezone,
					}),
					...(assetsReq.data.assets.find((asset) => asset.asset_Id === event.asset_Id) || defaultAssetList({})),
				}))
		)
		setPageStatus('Ready')
	}

	React.useEffect(() => {
		if (context.appState.authState.isLoggedIn) {
			setPageStatus('Loading')
			getData()
		}
	}, [context.appState.currentSite])

	const groupedEvents = useMemo(() => {
		if (eventsAndAssets) {
			// Group each event into an array of events with the same event type
			return eventsAndAssets.reduce((acc, event) => {
				const existingGroup = acc.find((group) => group.eventSettingType_Name === event.eventSettingType_Name)
				if (existingGroup) {
					existingGroup.events.push(event)
				} else {
					acc.push({ eventSettingType_Name: event.eventSettingType_Name, events: [event] })
				}
				return acc
			}, [] as GroupedEvents[])
		}
		return []
	}, [eventsAndAssets])

	// Refresh the data every 10 seconds
	useInterval(() => {
		setPageStatus('Submitting')
		getData()
	}, 10000)

	return (
		<>
			<PickerHeader type="site" title="Current Status" />
			{pageStatus === 'Ready' || pageStatus === 'Submitting' ? (
				<div>
					<Row style={styles.row}>
						<Col style={styles.cell}>
							<h1 className="page-header-primary">{context.appState.currentSite?.site_Name}</h1>
							<div style={styles.gap}>
								<SiteStatusIcon site_IsAliveTs={siteStatus.site_IsAliveTs} />
							</div>
							<span className="page-header-divider">|</span>
							<h2 className="page-header-secondary">
								{siteStatus.site_IsAliveTs
									? `Last Reading Received ${formatIncomingDateTime({ dateTime: siteStatus.site_IsAliveTs, format: 'TimeAgo' })}`
									: 'No readings ever received from this site '}
							</h2>
							{pageStatus == 'Submitting' && <Loading show small style={styles.submitting} />}
						</Col>
					</Row>
					<Row>
						<Col>
							{context.appState.userAttributes.userRole_Id === userRole['System Admin'].id && (
								<Row>
									<Col>{siteStatus.detailedStatus}</Col>
								</Row>
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							{groupedEvents
								//Sort so that the list always stays in the same order
								.sort((a, b) => a.eventSettingType_Name.localeCompare(b.eventSettingType_Name))
								.map((group) => (
									<Card
										key={group.eventSettingType_Name}
										title={`Current ${group.eventSettingType_Name} Events`}
										headerCols={[
											{
												colProps: { sm: 'auto' },
												children: (
													<span>
														{group.events.length} event{group.events.length > 1 ? 's' : ''}
													</span>
												),
											},
										]}
										headerColsRight={true}
										collapsible={true}
										initialCollapsedState={
											!(
												group.events.filter(
													(e) =>
														e.severity_Id === severity.Critical.id ||
														e.severity_Id === severity.High.id ||
														e.severity_Id === severity.Medium.id
												).length > 0
											)
										}
									>
										<EventListing eventsAndAssets={group.events} />
									</Card>
								))}
						</Col>
					</Row>
					{groupedEvents.length === 0 && (
						<Card title="" collapsible={false}>
							<span style={styles.center}>No current events to display</span>
						</Card>
					)}
				</div>
			) : (
				<div style={{ ...styles.center, ...styles.container }}>
					<Loading show />
				</div>
			)}
		</>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	center: {
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
	},
	container: {
		padding: '40px',
	},
	row: {
		marginBottom: '20px',
	},
	spacedRow: {
		justifyContent: 'space-between',
	},
	cell: {
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center',
	},
	card: {
		marginTop: '20px',
	},
	gap: {
		marginLeft: '5px',
	},
	submitting: {
		float: 'left',
		marginLeft: '20px',
	},
}

export { CurrentStatus }
