import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { groupType } from '../constants/groupType'

interface AssetGroup {
	assetGroup_Id: string
	asset_Id: string
	group_Id: string
	group_Name: string
	groupType_Id: string
	groupType_Name: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface AssetGroupResult extends Validate.Result {
	assetGroups: AssetGroup[]
}

const defaultAssetGroup = (assetGroup: Partial<AssetGroup>): AssetGroup => ({
	assetGroup_Id: assetGroup.assetGroup_Id || '',
	asset_Id: assetGroup.asset_Id || '',
	group_Id: assetGroup.group_Id || '',
	group_Name: assetGroup.group_Name || '',
	groupType_Id: assetGroup.groupType_Id || '',
	groupType_Name: assetGroup.groupType_Name || '',

	created: assetGroup.created || defaultCreated({}),
	modified: assetGroup.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

const defaultTemperatureAssetGroup = defaultAssetGroup({
	group_Id: 'DefaultTemperatureGroup',
	group_Name: 'Default Temperature Group',
	groupType_Id: groupType.Temperature.id,
	groupType_Name: 'Temperature',
})

const defaultBatteryAssetGroup = defaultAssetGroup({
	group_Id: 'DefaultBatteryGroup',
	group_Name: 'Default Battery Group',
	groupType_Id: groupType.Battery.id,
	groupType_Name: 'Battery',
})

export { defaultAssetGroup, defaultTemperatureAssetGroup, defaultBatteryAssetGroup }
export type { AssetGroup, AssetGroupResult }
