const parseJsonFile = async (file: File) => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader()
		fileReader.onerror = (e) => reject(e)
		fileReader.onload = (e) => {
			if (e.target && e.target.result) {
				resolve(JSON.parse(e.target.result.toString()))
			}
		}
		fileReader.readAsText(file)
	})
}

export { parseJsonFile }
