import React from 'react'

import { Formik, FormikValues } from 'formik'
import { Modal } from 'react-bootstrap'
import * as yup from 'yup'

import { Button } from '../../Button/Button'
import { FormText } from '../../Form/Text'

interface GenericAddModalProps<T> {
	show: boolean
	editableValues: { fieldName: string; required: boolean; renderAs?: 'input' | 'textarea' }[]
	displayName: string
	validationSchema: yup.ObjectSchema<Partial<T>>
	defaultValues: () => T
	hide: () => void
	handleSubmit: (values: T) => Promise<void>
}

const GenericAddModal = <T extends FormikValues>(props: GenericAddModalProps<T>) => {
	const defaultFormValues = props.defaultValues()
	const [formValues, setFormValues] = React.useState<T>(defaultFormValues)

	return (
		<Formik initialValues={formValues} validationSchema={props.validationSchema} onSubmit={props.handleSubmit} enableReinitialize>
			{({ handleSubmit, isSubmitting, errors, values, handleChange, handleReset }) => (
				<Modal
					show={props.show}
					onHide={() => {
						if (!isSubmitting) {
							handleReset()
							props.hide()
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>Add New {props.displayName}</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						{props.editableValues.map((value, index) => (
							<FormText
								key={index}
								as={value.renderAs || 'input'}
								name={value.fieldName}
								value={values[value.fieldName] || ''}
								onChange={handleChange}
								label={value.fieldName.split('_')[1]}
								required={value.required}
								feedback={errors[value.fieldName] as string}
								isInvalid={!!errors[value.fieldName]}
								autoFocus={index === 0}
							/>
						))}
					</Modal.Body>

					<Modal.Footer>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleReset()
								setFormValues(defaultFormValues)
								props.hide()
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleSubmit()
								setFormValues(defaultFormValues)
								props.hide()
							}}
						>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
	)
}

export { GenericAddModal }
export type { GenericAddModalProps }
