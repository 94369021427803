import React from 'react'
import { Card, Row, Col, Dropdown, Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AppContext } from '../../../App'
import { Loading } from '../Loading/Loading'
import { LocationAreaLiveTempResult, LocationAreaLiveTempView } from '../../../models/LocationArea'
import { AssetLiveTempView, AssetServicedLiveTempView } from '../../../models/Asset'
import { ReadingLive, ReadingLiveResult, defaultReadingLive } from '../../../models/ReadingLive'
import { readingToLabelColour, readingType, readingTypeIdToLabel } from '../../../constants/readingType'
import * as Request from '../../../utilities/request'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import { useInterval } from '../../../utilities/useInterval'
import chevronOpen from '../../../images/icons/chevron-open.svg'
import chevronCollapsed from '../../../images/icons/chevron-collapsed.svg'
import { AssetTypeName, assetType } from '../../../constants/assetType'
import { DateTime } from 'luxon'

const LiveTemperaturesCard = () => {
	const { appState } = React.useContext(AppContext)

	const [locations, setLocations] = React.useState<LocationAreaLiveTempView[] | null>(null)
	const [readings, setReadings] = React.useState<ReadingLive[]>([])

	const [selectedLocation, setSelectedLocation] = React.useState<string>('')

	const fetchReadingData = async () => {
		const readingReq = await Request.get<ReadingLiveResult>(`readingLive?Site_Id=${appState.currentSite?.site_Id}`, appState.authState)
		setReadings(readingReq.data.readingLives)
	}

	React.useEffect(() => {
		const fetchLocationData = async () => {
			const locationReq = await Request.get<LocationAreaLiveTempResult>(
				`locationArea/liveTemp?Site_Id=${appState.currentSite?.site_Id}`,
				appState.authState
			)
			setLocations(locationReq.data.locationAreas)
		}
		if (appState.authState.isLoggedIn) {
			fetchLocationData()
			fetchReadingData()
		}
	}, [appState.currentSite])

	useInterval(() => {
		fetchReadingData()
	}, 5000)

	const locationOptions = React.useMemo(() => {
		return (
			<>
				{locations
					? locations.map((location) => (
							<Dropdown.Item key={`LocationDropdown_${location.locationArea_Id}`} onClick={() => setSelectedLocation(location.locationArea_Id)}>
								{location.location_DisplayName}
							</Dropdown.Item>
					  ))
					: []}
			</>
		)
	}, [locations])

	const locationRows = React.useMemo(() => {
		return (
			<>
				{locations
					? locations
							.filter(
								(location) => (selectedLocation === '' || location.locationArea_Id === selectedLocation) && location.location_Assets.length > 0
							)
							.map((location) => (
								<LocationCard
									key={`LocationCard_${location.locationArea_Id}`}
									location={location}
									readings={readings.filter((reading) => location.location_Assets.some((asset) => asset.asset_Id === reading.asset_Id))}
								/>
							))
					: []}
			</>
		)
	}, [locations, selectedLocation, readings])

	return (
		<>
			<Card>
				{locations ? (
					<>
						<Row>
							<Col>
								<Dropdown>
									<Dropdown.Toggle variant={'secondary'}>
										{locations.find((location) => location.locationArea_Id === selectedLocation)?.location_DisplayName || 'All Locations'}
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item onClick={() => setSelectedLocation('')}>All Locations</Dropdown.Item>
										{locationOptions}
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</Row>
						{locationRows}
					</>
				) : (
					<Loading show />
				)}
			</Card>
		</>
	)
}

const initialAssetTypeAssetGrouping: {
	assetType_Id: string
	label: string
	assets: AssetLiveTempView[]
}[] = [
	{
		assetType_Id: assetType.TMV.id,
		label: 'TMVs',
		assets: [],
	},
].concat(
	Object.keys(assetType)
		.filter((key) => assetType[key as AssetTypeName].assetClass === 'Fixture')
		.map((key) => ({
			assetType_Id: assetType[key as AssetTypeName].id,
			label: `${key}s`,
			assets: [],
		}))
)

interface LocationCardProps {
	location: LocationAreaLiveTempView
	readings: ReadingLive[]
}

const LocationCard = (props: LocationCardProps) => {
	const [collapsed, setCollapsed] = React.useState<boolean>(true)

	const assetGroupedReadings = React.useMemo(() => {
		return props.readings.reduce(
			(final, curr) => {
				const assetIndex = final.findIndex((prev) => prev.asset_Id === curr.asset_Id)
				if (assetIndex < 0) {
					final.push({
						asset_Id: curr.asset_Id,
						readings: [curr],
					})
				} else {
					final[assetIndex].readings.push(curr)
				}
				return final
			},
			[] as { asset_Id: string; readings: ReadingLive[] }[]
		)
	}, [props.readings])

	const groupedAssets = React.useMemo(() => {
		return props.location.location_Assets.reduce(
			(final, curr) => {
				const assetTypeIndex = final.findIndex((prev) => prev.assetType_Id === curr.assetType_Id)
				if (assetTypeIndex >= 0) {
					const updatedAssetType = {
						...final[assetTypeIndex],
						assets: [...final[assetTypeIndex].assets, curr],
					}
					final[assetTypeIndex] = updatedAssetType
				}
				return final
			},
			[...initialAssetTypeAssetGrouping]
		)
	}, [props.location.location_Assets])

	return (
		<Card className="live-temp-card">
			<Row style={styles.row}>
				<Col sm={2}>
					<img src={collapsed ? chevronCollapsed : chevronOpen} style={styles.chevron} onClick={() => setCollapsed(!collapsed)} />
					{props.location.location_DisplayName}
				</Col>
				<Col sm={10}>
					{groupedAssets
						.filter((group) =>
							group.assets.some(
								(a) =>
									assetGroupedReadings
										.find((assetGroupedReadings) => assetGroupedReadings.asset_Id === a.asset_Id)
										?.readings.find((reading) => reading.readingType_Id === readingType['Temperature Reading Mixed'].id)
							)
						)
						.map((group) => (
							<Row key={group.assetType_Id} style={styles.headerAssetTypeRow}>
								<Col sm={1} style={styles.assetTypeCol}>
									{group.label}
								</Col>
								<Col sm={11} style={styles.compactReadingsCol}>
									<Row>
										{group.assets
											.sort((a, b) => a.asset_Name.localeCompare(b.asset_Name, undefined, { numeric: true }))
											.map((asset) => {
												const warmReading = assetGroupedReadings
													.find((assetGroupedReadings) => assetGroupedReadings.asset_Id === asset.asset_Id)
													?.readings.find((reading) => reading.readingType_Id === readingType['Temperature Reading Mixed'].id)

												return (
													<Col sm={'auto'} style={styles.headerDataCol} key={asset.asset_Id}>
														<AssetReadingData asset={asset} readings={warmReading ? [warmReading] : []} compactView />
													</Col>
												)
											})}
									</Row>
								</Col>
							</Row>
						))}
				</Col>
			</Row>
			<Collapse in={!collapsed}>
				<div>
					<Row style={styles.headerRow}>
						<Col sm={1}>ASSET ID</Col>
						<Col sm={2}>LOCATION</Col>
						<Col sm={1}>MONITORED BY</Col>
						<Col sm={1}>HUB TYPE</Col>
						<Col sm={3}>ASSETS SERVICED</Col>
						<Col sm={1}>DATA</Col>
						<Col sm={3}>LAST UPDATED</Col>
					</Row>
					{groupedAssets
						.filter((group) => group.assets.length > 0)
						.map((group) => (
							<React.Fragment key={group.assetType_Id}>
								<Row style={styles.assetTypeRow} className="no-gutter">
									<Col>{group.label}</Col>
								</Row>
								{group.assets
									.sort((a, b) => a.asset_Name.localeCompare(b.asset_Name, undefined, { numeric: true }))
									.map((asset) => (
										<AssetRow
											key={asset.asset_Id}
											location={props.location}
											asset={asset}
											readings={assetGroupedReadings.find((group) => group.asset_Id === asset.asset_Id)?.readings || []}
										/>
									))}
							</React.Fragment>
						))}
				</div>
			</Collapse>
		</Card>
	)
}

interface AssetRowProps {
	location: LocationAreaLiveTempView
	asset: AssetLiveTempView
	readings: ReadingLive[]
}

const AssetRow = (props: AssetRowProps) => {
	const groupedServiced = React.useMemo(() => {
		return props.asset.asset_Serviced.reduce(
			(final, curr) => {
				const roomIndex = final.findIndex((prev) => prev.locationRoom_Id === curr.locationRoom_Id)
				if (roomIndex < 0) {
					final.push({
						locationRoom_Id: curr.locationRoom_Id,
						locationRoom_Name: curr.locationRoom_Name,
						assets: [curr],
					})
				} else {
					final[roomIndex].assets.push(curr)
				}
				return final
			},
			[] as { locationRoom_Id: string | null; locationRoom_Name: string | null; assets: AssetServicedLiveTempView[] }[]
		)
	}, [props.asset.asset_Serviced])

	return (
		<Row style={styles.cardRow}>
			<Col sm={1}>
				<Link to={`/asset/${props.asset.asset_Id}`}>{props.asset.asset_Name}</Link>
			</Col>
			<Col sm={2}>{props.location.location_DisplayName}</Col>
			<Col sm={1}>{props.asset.asset_MonitoredBy.map((am) => am.asset_Name).join(', ')}</Col>
			<Col sm={1}>{props.asset.asset_MonitoredBy.map((am) => am.assetType_Name).join(', ')}</Col>
			<Col sm={3}>
				{groupedServiced.length === 0 ? (
					<Col>{props.asset.asset_RoomsServiced}</Col>
				) : (
					groupedServiced
						.sort((a, b) =>
							a.locationRoom_Name === null || b.locationRoom_Name === null
								? 1
								: a.locationRoom_Name.localeCompare(b.locationRoom_Name, undefined, { numeric: true })
						)
						.map((group) => (
							<Col key={group.locationRoom_Id}>
								{group.locationRoom_Name ? `${group.locationRoom_Name}: ` : ''}
								{group.assets
									.sort((a, b) => a.asset_Name.localeCompare(b.asset_Name, undefined, { numeric: true }))
									.map((asset) => asset.asset_Name)
									.join(', ')}
							</Col>
						))
				)}
			</Col>
			<Col sm={1}>
				<AssetReadingData asset={props.asset} readings={props.readings} />
			</Col>
			<Col sm={3}>
				<AssetReadingTs readings={props.readings} />
			</Col>
		</Row>
	)
}

interface AssetReadingDataProps {
	readings: ReadingLive[]
	asset: AssetLiveTempView
	compactView?: boolean
}

const AssetReadingData = (props: AssetReadingDataProps) => {
	return (
		<Row>
			<Col style={styles.readingCol}>
				<AssetReading
					reading={
						props.readings.find((reading) => reading.readingType_Id === readingType['Temperature Reading Mixed'].id) ||
						defaultReadingLive({
							readingType_Id: readingType['Temperature Reading Mixed'].id,
						})
					}
					asset={props.asset}
					compactView={props.compactView}
				/>
				{!props.compactView && (
					<>
						<AssetReading
							reading={
								props.readings.find((reading) => reading.readingType_Id === readingType['Temperature Reading Hot'].id) ||
								defaultReadingLive({
									readingType_Id: readingType['Temperature Reading Hot'].id,
								})
							}
							asset={props.asset}
							compactView={props.compactView}
						/>
						<AssetReading
							reading={
								props.readings.find((reading) => reading.readingType_Id === readingType['Temperature Reading Cold'].id) ||
								defaultReadingLive({
									readingType_Id: readingType['Temperature Reading Cold'].id,
								})
							}
							asset={props.asset}
							compactView={props.compactView}
						/>
					</>
				)}
			</Col>
		</Row>
	)
}

interface AssetReadingProps {
	reading: ReadingLive | undefined
	asset: AssetLiveTempView
	compactView?: boolean
}

/* TODO: add logic for errors/invalid readings */
const AssetReading = (props: AssetReadingProps) => {
	const { appState } = React.useContext(AppContext)

	const isWithinDay = (reading_Ts : string | undefined) => {
		if(!reading_Ts) return false

		const now = DateTime.utc()
		const diff = now.diff(DateTime.fromISO(reading_Ts, {zone: 'utc', setZone: true}), 'minutes').minutes

		return diff < 1440;
	}

	const type = readingTypeIdToLabel(props.reading?.readingType_Id)
	const label = readingToLabelColour(props.reading?.reading_Data)
	return (
		<OverlayTrigger
			placement="top"
			overlay={
				<Tooltip id={`toolTip_${props.reading?.readingLive_Id}`}>
					<Row>
						<Col>{props.asset?.asset_Name}</Col>
					</Row>
					<Row>
						<Col>
							{props.reading?.reading_Ts
								? formatIncomingDateTime({
										dateTime: props.reading?.reading_Ts,
										format: 'DateAndTimeAndTimezoneContext',
										timeZone: appState.currentSite?.site_Timezone,
								  })
								: null}
						</Col>
					</Row>
				</Tooltip>
			}
		>
			<Link to={`/asset/${props.asset?.asset_Id}`} className={`stealthLink`}>
				<Row style={styles.readingRow}>
					{props.compactView ? null : <Col sm={3}>{type.slice(0, 1)}</Col>}
					<Col sm={2} className={`live-temp-data ${props.reading?.readingLive_Id ? isWithinDay(props.reading?.reading_Ts) && label.toLowerCase() : ''}`}>
						 {props.reading?.readingLive_Id ? isWithinDay(props.reading?.reading_Ts) && props.reading.reading_Data : ''}
					</Col>
				</Row>
			</Link>
		</OverlayTrigger>
	)
}

interface AssetReadingTsProps {
	readings: ReadingLive[]
}

const AssetReadingTs = (props: AssetReadingTsProps) => {
	const { appState } = React.useContext(AppContext)

	const warm = props.readings.find((reading) => reading.readingType_Id === readingType['Temperature Reading Mixed'].id)
	const hot = props.readings.find((reading) => reading.readingType_Id === readingType['Temperature Reading Hot'].id)
	const cold = props.readings.find((reading) => reading.readingType_Id === readingType['Temperature Reading Cold'].id)

	return (
		<Row>
			<Col style={styles.readingCol}>
				<div style={styles.readingTsCol}>
					{warm
						? formatIncomingDateTime({
								dateTime: warm.reading_Ts,
								format: 'DateAndTimeAndTimezoneContext',
								timeZone: appState.currentSite?.site_Timezone,
						  })
						: ''}
				</div>
				<div style={styles.readingTsCol}>
					{hot
						? formatIncomingDateTime({
								dateTime: hot.reading_Ts,
								format: 'DateAndTimeAndTimezoneContext',
								timeZone: appState.currentSite?.site_Timezone,
						  })
						: ''}
				</div>
				<div style={styles.readingTsCol}>
					{cold
						? formatIncomingDateTime({
								dateTime: cold.reading_Ts,
								format: 'DateAndTimeAndTimezoneContext',
								timeZone: appState.currentSite?.site_Timezone,
						  })
						: ''}
				</div>
			</Col>
		</Row>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	row: {
		margin: '10px 0px 0px',
	},
	headerRow: {
		margin: '10px 0px 0px',
		fontSize: '12px',
		padding: '10px 0px',
		borderBottom: '1px solid lightgrey',
		borderTop: '1px solid lightgrey',
	},
	headerAssetTypeRow: {
		marginBottom: '20px',
	},
	compactReadingsCol: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	headerDataCol: {
		marginBottom: '10px',
		paddingBottom: '10px',
		borderBottom: '1px solid lightgrey',
	},
	assetTypeRow: {
		paddingTop: '20px',
		fontWeight: 'bold',
		fontSize: '18px',
	},
	assetTypeCol: {
		fontSize: '18px',
		fontWeight: 'bold',
		textAlign: 'right',
		paddingTop: '5px',
	},
	cardRow: {
		margin: '10px 0px 10px',
		borderBottom: '1px solid lightgrey',
		paddingBottom: '10px',
	},
	chevron: {
		padding: '10px',
	},
	readingCol: {
		flexDirection: 'column',
		display: 'flex',
	},
	readingRow: {
		alignItems: 'center',
	},
	readingTsCol: {
		minHeight: '34px', // to match height of reading live data component
		display: 'flex',
		alignItems: 'center',
	},
}

export default LiveTemperaturesCard
