import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'

interface AssetMake {
	assetMake_Id: string
	assetMake_Name: string
	assetMake_Note: string | null

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface AssetMakeResult extends Validate.Result {
	assetMakes: AssetMake[]
}

const defaultAssetMake = (assetMake: Partial<AssetMake>): AssetMake => ({
	assetMake_Id: assetMake.assetMake_Id || '',
	assetMake_Name: assetMake.assetMake_Name || '',
	assetMake_Note: assetMake.assetMake_Note || null,

	created: assetMake.created || defaultCreated({}),
	modified: assetMake.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultAssetMake }
export type { AssetMake, AssetMakeResult }
