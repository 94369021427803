import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { AppContext } from '../../../App'

import { ImportStatus, ImportUserFeedback } from '../AssetImport/assetImport'
import { FormFile } from '../Form/File'
import { Frown } from 'react-feather'

import { ExportData } from '../SiteExport/SiteExporter'
import { Site, SiteResult } from '../../../models/Site'
import { AssetMake, AssetMakeResult } from '../../../models/AssetMake'
import { AssetModel, AssetModelResult } from '../../../models/AssetModel'
import { LocationBuilding, LocationBuildingResult } from '../../../models/LocationBuilding'
import { LocationFloor, LocationFloorResult } from '../../../models/LocationFloor'
import { LocationArea, LocationAreaResult } from '../../../models/LocationArea'
import { LocationRoom, LocationRoomResult } from '../../../models/LocationRoom'
import { Asset, AssetResult } from '../../../models/Asset'
import { AssetTree, AssetTreeResult } from '../../../models/AssetTree'
import { ChannelMap, ChannelMapResult } from '../../../models/ChannelMap'
import { Group, GroupResult } from '../../../models/Group'
import { AssetGroup, AssetGroupResult } from '../../../models/AssetGroup'
import { EventSetting, EventSettingResult } from '../../../models/EventSetting'
import { BacnetTemperatureAlarmSetting, BacnetTemperatureAlarmSettingsResult } from '../../../models/BacnetTemperatureAlarmSetting'
import { Bacnet, BacnetResult } from '../../../models/Bacnet'
import { Maintenance, MaintenanceResult } from '../../../models/Maintenance'

import { parseJsonFile } from '../../../utilities/file'
import { toProperCase } from '../../../utilities/strings'
import * as Request from '../../../utilities/request'
import * as Helpers from './siteImportHelpers'

export interface SiteImportFeedback {
	sites: ImportUserFeedback | null
	assetMakes: ImportUserFeedback | null
	assetModels: ImportUserFeedback | null
	locationBuildings: ImportUserFeedback | null
	locationFloors: ImportUserFeedback | null
	locationAreas: ImportUserFeedback | null
	locationRooms: ImportUserFeedback | null
	assets: ImportUserFeedback | null
	assetTrees: ImportUserFeedback | null
	channelMaps: ImportUserFeedback | null
	groups: ImportUserFeedback | null
	assetGroups: ImportUserFeedback | null
	eventSettings: ImportUserFeedback | null
	bacnetTemperatureAlarmSettings: ImportUserFeedback | null
	bacnets: ImportUserFeedback | null
	maintenances: ImportUserFeedback | null
}

const defaultFeedback = {
	sites: null,
	assetMakes: null,
	assetModels: null,
	locationBuildings: null,
	locationFloors: null,
	locationAreas: null,
	locationRooms: null,
	assets: null,
	assetTrees: null,
	channelMaps: null,
	groups: null,
	assetGroups: null,
	eventSettings: null,
	bacnetTemperatureAlarmSettings: null,
	bacnets: null,
	maintenances: null,
}

const SiteImporter = () => {
	const { appState } = React.useContext(AppContext)

	const [importStatus, setImportStatus] = React.useState<ImportStatus>('Ready')
	const [file, setFile] = React.useState<File | null>(null)
	const [userFeedback, setUserFeedback] = React.useState<SiteImportFeedback>(defaultFeedback)

	const handleImport = async () => {
		setImportStatus('InProgress')
		setUserFeedback(defaultFeedback)
		try {
			const data = (await parseJsonFile(file as File)) as ExportData
			const finalFeedback = userFeedback

			// use export endpoints here to minimise data & db calls
			const siteRes = await Request.get<SiteResult>(`site/export?Id=${data.sites[0].site_Id}`, appState.authState)
			finalFeedback.sites = await Helpers.importHandler<Site>(
				data.sites,
				'sites',
				setUserFeedback,
				(site) => Helpers.importSite(appState, site, siteRes.data.sites),
				defaultFeedback // pass through initial feedback here so default is used in setState call, incase of loading multiple files
			)
			const assetMakeRes = await Request.get<AssetMakeResult>('assetMake/export', appState.authState)
			finalFeedback.assetMakes = await Helpers.importHandler<AssetMake>(data.assetMakes, 'assetMakes', setUserFeedback, (assetMake) =>
				Helpers.importAssetMake(appState, assetMake, assetMakeRes.data.assetMakes)
			)
			const assetModelRes = await Request.get<AssetModelResult>('assetModel/export', appState.authState)
			finalFeedback.assetModels = await Helpers.importHandler<AssetModel>(data.assetModels, 'assetModels', setUserFeedback, (assetModel) =>
				Helpers.importAssetModel(appState, assetModel, assetModelRes.data.assetModels)
			)
			const locationBuildingRes = await Request.get<LocationBuildingResult>(
				`locationBuilding/export?Site_Id=${data.sites[0].site_Id}`,
				appState.authState
			)
			finalFeedback.locationBuildings = await Helpers.importHandler<LocationBuilding>(
				data.locationBuildings,
				'locationBuildings',
				setUserFeedback,
				(locationBuilding) => Helpers.importLocationBuilding(appState, locationBuilding, locationBuildingRes.data.locationBuildings)
			)
			const locationFloorRes = await Request.get<LocationFloorResult>(`locationFloor/export?Site_Id=${data.sites[0].site_Id}`, appState.authState)
			finalFeedback.locationFloors = await Helpers.importHandler<LocationFloor>(data.locationFloors, 'locationFloors', setUserFeedback, (locationFloor) =>
				Helpers.importLocationFloor(appState, locationFloor, locationFloorRes.data.locationFloors)
			)
			const locationAreaRes = await Request.get<LocationAreaResult>(`locationArea/export?Site_Id=${data.sites[0].site_Id}`, appState.authState)
			finalFeedback.locationAreas = await Helpers.importHandler<LocationArea>(data.locationAreas, 'locationAreas', setUserFeedback, (locationArea) =>
				Helpers.importLocationArea(appState, locationArea, locationAreaRes.data.locationAreas)
			)
			const locationRoomRes = await Request.get<LocationRoomResult>(`locationRoom/export?Site_Id=${data.sites[0].site_Id}`, appState.authState)
			finalFeedback.locationRooms = await Helpers.importHandler<LocationRoom>(data.locationRooms, 'locationRooms', setUserFeedback, (locationRoom) =>
				Helpers.importLocationRoom(appState, locationRoom, locationRoomRes.data.locationRooms)
			)
			const assetRes = await Request.get<AssetResult>(`asset/export?Site_Id=${data.sites[0].site_Id}`, appState.authState)
			finalFeedback.assets = await Helpers.importHandler<Asset>(data.assets, 'assets', setUserFeedback, (asset) =>
				Helpers.importAsset(appState, asset, assetRes.data.assets)
			)
			const assetTreeRes = await Request.get<AssetTreeResult>(`assetTree/export?Site_Id=${data.sites[0].site_Id}`, appState.authState)
			finalFeedback.assetTrees = await Helpers.importHandler<AssetTree>(data.assetTrees, 'assetTrees', setUserFeedback, (assetTree) =>
				Helpers.importAssetTree(appState, assetTree, assetTreeRes.data.assetTrees)
			)
			const channelMapRes = await Request.get<ChannelMapResult>(`channelMap/export?Site_Id=${data.sites[0].site_Id}`, appState.authState)
			finalFeedback.channelMaps = await Helpers.importHandler<ChannelMap>(data.channelMaps, 'channelMaps', setUserFeedback, (channelMap) =>
				Helpers.importChannelMap(appState, channelMap, channelMapRes.data.channelMaps)
			)
			const groupRes = await Request.get<GroupResult>(`group/export?Site_Id=${data.sites[0].site_Id}`, appState.authState)
			finalFeedback.groups = await Helpers.importHandler<Group>(data.groups, 'groups', setUserFeedback, (group) =>
				Helpers.importGroup(appState, group, groupRes.data.groups)
			)
			const assetGroupRes = await Request.get<AssetGroupResult>(`assetGroup/export?Site_Id=${data.sites[0].site_Id}`, appState.authState)
			finalFeedback.assetGroups = await Helpers.importHandler<AssetGroup>(data.assetGroups, 'assetGroups', setUserFeedback, (assetGroup) =>
				Helpers.importAssetGroup(appState, assetGroup, assetGroupRes.data.assetGroups)
			)
			const eventSettingRes = await Request.get<EventSettingResult>(`eventSetting/export?Site_Id=${data.sites[0].site_Id}`, appState.authState)
			finalFeedback.eventSettings = await Helpers.importHandler<EventSetting>(data.eventSettings, 'eventSettings', setUserFeedback, (eventSetting) =>
				Helpers.importEventSetting(appState, eventSetting, eventSettingRes.data.eventSettings)
			)
			const bacnetTemperatureAlarmSettingRes = await Request.get<BacnetTemperatureAlarmSettingsResult>(
				`bacnetTemperatureAlarmSetting/export?Site_Id=${data.sites[0].site_Id}`,
				appState.authState
			)
			finalFeedback.bacnetTemperatureAlarmSettings = await Helpers.importHandler<BacnetTemperatureAlarmSetting>(
				data.bacnetTemperatureAlarmSettings,
				'bacnetTemperatureAlarmSettings',
				setUserFeedback,
				(bacnetTemperatureAlarmSetting) =>
					Helpers.importBacnetTemperatureAlarmSetting(
						appState,
						bacnetTemperatureAlarmSetting,
						bacnetTemperatureAlarmSettingRes.data.bacnetTemperatureAlarmSettings
					)
			)
			const bacnetRes = await Request.get<BacnetResult>(`bacnet/export?Site_Id=${data.sites[0].site_Id}`, appState.authState)
			finalFeedback.bacnets = await Helpers.importHandler<Bacnet>(data.bacnets, 'bacnet', setUserFeedback, (bacnet) =>
				Helpers.importBacnet(appState, bacnet, bacnetRes.data.bacnets)
			)
			const maintenanceRes = await Request.get<MaintenanceResult>(`maintenance/export?Site_Id=${data.sites[0].site_Id}`, appState.authState)
			finalFeedback.maintenances = await Helpers.importHandler<Maintenance>(data.maintenances, 'maintenance', setUserFeedback, (maintenance) =>
				Helpers.importMaintenance(appState, maintenance, maintenanceRes.data.maintenances)
			)

			if (Object.keys(finalFeedback).every((key) => finalFeedback[key as keyof SiteImportFeedback]?.errors.length === 0)) {
				setImportStatus('Finished')
			} else {
				setImportStatus('Error')
			}
		} catch (e) {
			// if there's an error here we don't want to continue - means we couldn't fetch db data to compare
			console.log(e)
			setImportStatus('Error')
		}
	}

	return (
		<>
			<Row style={styles.spaciousRow}>
				<Col sm={2}>
					<FormFile
						name="ImportJSON"
						label="Upload Site Configuration File"
						accept=".json"
						onChange={(e) => {
							const target = e.target as HTMLInputElement
							if (target.files) {
								setFile(target.files[0])
							}
						}}
						disabled={importStatus === 'InProgress'}
					/>
				</Col>
				<Col sm={2}>{file?.name}</Col>
			</Row>
			{file && (
				<Row style={styles.spaciousRow}>
					<Col>
						<Button variant="primary" onClick={handleImport} disabled={importStatus === 'InProgress'}>
							Start Import
						</Button>
					</Col>
				</Row>
			)}
			{(importStatus === 'InProgress' || importStatus === 'Error') && (
				<Row style={styles.spaciousRow}>
					<Col>
						{importStatus === 'InProgress' && (
							<>
								<Row>
									<Col style={styles.centred}>Import in Progress</Col>
								</Row>
								<Row>
									<Col style={styles.centred}>Please do not leave or close the page</Col>
								</Row>
							</>
						)}
						{Object.keys(userFeedback)
							.filter((key) => userFeedback[key as keyof SiteImportFeedback] !== null)
							.map((key) => {
								const feedback = userFeedback[key as keyof SiteImportFeedback] as ImportUserFeedback
								return (
									<Row key={key}>
										<Row>
											<Col style={styles.centred}>
												<h1>
													{key
														.split(/(?=[A-Z])/)
														.map((str) => toProperCase(str))
														.join(' ')}
													: Importing row {feedback.totalComplete} out of {feedback.totalCount}
												</h1>
											</Col>
										</Row>

										{feedback.errors.length !== 0 && (
											<>
												<Row>
													<Col style={styles.centred} className={'invalid-feedback-custom'}>
														Unable to import {feedback.errors.length} rows
													</Col>
												</Row>
												{feedback.errors.map((error, i) => (
													<Row key={`importError${i}`}>
														<Col className={'invalid-feedback-custom'}>{error}</Col>
													</Row>
												))}
											</>
										)}
									</Row>
								)
							})}
					</Col>
				</Row>
			)}
			{importStatus === 'Finished' && (
				<Row style={styles.spaciousRow}>
					<Col style={styles.centred}>
						<h1>Import Complete</h1>
					</Col>
				</Row>
			)}
			{importStatus === 'Error' && (
				<>
					<Row style={styles.row}>
						<Col style={styles.centred}>
							<h1>Something went wrong</h1>
						</Col>
					</Row>
					<Row style={styles.row}>
						<Col style={styles.centred}>
							<Frown />
						</Col>
					</Row>
				</>
			)}
		</>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	row: {
		marginBottom: '1rem',
		marginTop: '1rem',
	},
	spaciousRow: {
		marginTop: '1rem',
		marginBottom: '2rem',
	},
	centred: {
		textAlign: 'center',
	},
}

export { SiteImporter }
