import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Col, Modal, Row } from 'react-bootstrap'
import * as Request from '../../../utilities/request'
import { Button } from '../Button/Button'
import { FormText } from '../Form/Text'
import { PaymentAgreement, PaymentAgreementMigrationResult } from '../../../models/PaymentAgreement'
import { FormNumber } from '../Form/Number'
import { FormDate } from '../Form/Date'
import { Contract } from '../../../models/Contract'
import { FormSelect, SelectOption } from '../Form/Select'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { PageStatus } from '../../../types/PageStatus'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import { DateTime } from 'luxon'

interface PaymentAgreementMigrationModalProps {
	paymentAgreement: PaymentAgreement | null
	setPaymentAgreement: React.Dispatch<React.SetStateAction<PaymentAgreement | null>>
	contract: Contract
	setContract: (values: React.SetStateAction<Contract>, shouldValidate?: boolean | undefined) => void
}

const PaymentAgreementMigrationModal = (props: PaymentAgreementMigrationModalProps) => {
	const context = useContext(AppContext)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Ready')
	const [showModal, setShowModal] = React.useState<boolean>(false)

	const [selectedPaymentAgreement, setSelectedPaymentAgreement] = React.useState<PaymentAgreement | null>(null)

	const handleSubmit = () => {
		setPageStatus('Submitting')

		const body = {
			from_PaymentAgreement_Id: props.paymentAgreement?.paymentAgreement_Id,
			to_PaymentAgreement_Id: selectedPaymentAgreement?.paymentAgreement_Id,
		}

		return Request.handleRequest(() => Request.put<PaymentAgreementMigrationResult>(`PaymentAgreementMigrate`, body, context.appState.authState), {
			successFunction: () => {
				setPageStatus('Ready')
				// Update the contract values
				const updatedContractAgreements = props.contract.contract_PaymentAgreements

				// Update the to payment agreement asset assigned count to the sum of the two
				const toIndex = updatedContractAgreements.findIndex(
					(agreement) => agreement.paymentAgreement_Id === selectedPaymentAgreement?.paymentAgreement_Id
				)
				updatedContractAgreements[toIndex].paymentAgreement_AssetCountAssigned =
					updatedContractAgreements[toIndex].paymentAgreement_AssetCountAssigned + (props.paymentAgreement?.paymentAgreement_AssetCountAssigned || 0)

				// Update the from payment agreement asset assigned count to 0
				const fromIndex = updatedContractAgreements.findIndex(
					(agreement) => agreement.paymentAgreement_Id === props.paymentAgreement?.paymentAgreement_Id
				)
				updatedContractAgreements[fromIndex].paymentAgreement_AssetCountAssigned = 0

				props.setContract({
					...props.contract,
					contract_PaymentAgreements: updatedContractAgreements,
				})
				props.setPaymentAgreement(null)
			},
			messageAction: 'editing',
			messageObject: 'payment agreement',
		})
	}

	const paymentAgreementOptions = React.useMemo(() => {
		return props.contract.contract_PaymentAgreements
			.filter((p) => p.paymentAgreement_Id != props.paymentAgreement?.paymentAgreement_Id)
			.map((paymentAgreement) => ({ value: paymentAgreement.paymentAgreement_Id, label: paymentAgreement.paymentAgreement_OrderNumber || '' }))
	}, [props.paymentAgreement, props.contract.contract_PaymentAgreements])

	return (
		<Modal
			show={!!props.paymentAgreement}
			onHide={() => {
				props.setPaymentAgreement(null)
			}}
		>
			<ConfirmModal
				show={showModal}
				setShow={setShowModal}
				onConfirm={handleSubmit}
				title={'Confirm migration'}
				body={`Are you sure you want to migrate ${props.paymentAgreement?.paymentAgreement_AssetCountAssigned} assets from Payment Agreement ${props.paymentAgreement?.paymentAgreement_OrderNumber} to ${selectedPaymentAgreement?.paymentAgreement_OrderNumber}?`}
			/>

			<Modal.Header closeButton>
				<Modal.Title>Migrate Payment Agreement</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Row>
					<Col>
						<FormText
							name={`paymentAgreement_OrderNumber`}
							label="Agreement ID"
							value={props.paymentAgreement?.paymentAgreement_OrderNumber || ''}
							disabled
						/>
					</Col>
					<Col>
						<FormNumber
							name={`paymentAgreement_LineNumber`}
							label={'PO/Line Item #'}
							value={props.paymentAgreement?.paymentAgreement_LineNumber || 0}
							onChange={() => {
								return
							}}
							disabled
						/>
					</Col>
				</Row>
				<Row className="d-flex align-items-end">
					<Col>
						<FormDate
							name={`paymentAgreement_StartDate`}
							label={'Start Date'}
							value={
								formatIncomingDateTime({
									dateTime: DateTime.fromISO(props.paymentAgreement?.paymentAgreement_StartDate || '').toISO({ includeOffset: false }) || '',
									format: 'FileDate',
								}) || undefined
							}
							disabled
						/>
					</Col>
					<Col>
						<FormDate
							name={`paymentAgreement_EndDate`}
							label={'Start Date'}
							value={
								formatIncomingDateTime({
									dateTime: DateTime.fromISO(props.paymentAgreement?.paymentAgreement_StartDate || '').toISO({ includeOffset: false }) || '',
									format: 'FileDate',
								}) || undefined
							}
							disabled
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormNumber
							name={`paymentAgreement_AssetCount`}
							label={'Quantity'}
							value={props.paymentAgreement?.paymentAgreement_AssetCount || 0}
							onChange={() => {
								return
							}}
							disabled
						/>
					</Col>
					<Col>
						<FormNumber
							name={`paymentAgreement_AssetCountAssigned`}
							label={'Assigned Quantity'}
							value={props.paymentAgreement?.paymentAgreement_AssetCountAssigned || 0}
							onChange={() => {
								return
							}}
							disabled
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormText name={`paymentAgreement_Note`} label="Notes" value={props.paymentAgreement?.paymentAgreement_Note || ''} disabled />
					</Col>
				</Row>

				<Row>
					<Col>
						<FormSelect
							name="paymentAgreement"
							label={'Migrate Assigned Assets to Payment Agreement'}
							options={paymentAgreementOptions}
							value={selectedPaymentAgreement?.paymentAgreement_Id}
							onChange={(e) => {
								const paymentAgreement_Id = (e as SelectOption<string>).value
								setSelectedPaymentAgreement(
									props.contract.contract_PaymentAgreements.find((p) => p.paymentAgreement_Id === paymentAgreement_Id) || null
								)
							}}
							required
						/>
					</Col>
				</Row>
			</Modal.Body>

			<Modal.Footer>
				<Button
					disabled={pageStatus != 'Ready'}
					onClick={() => {
						// handleReset()
						props.setPaymentAgreement(null)
					}}
				>
					Cancel
				</Button>
				<Button disabled={pageStatus != 'Ready'} onClick={() => setShowModal(true)}>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export { PaymentAgreementMigrationModal }
