import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { TaskHistory } from './TaskHistory'
import { TaskComment } from './TaskComment'
import { Severity, SeverityId, severity } from '../constants/severity'
import { TaskUser } from './TaskUser'
import { TaskAsset } from './TaskAsset'

interface Task {
	task_Id: string
	task_Name: string
	task_Note: string
	taskStatus_Id: string
	taskStatus_Name: string
	task_History: TaskHistory[]
	task_Comments: TaskComment[]
	severity_Id: SeverityId
	severity_Name: Severity
	site_Id: string
	task_TaskAssets: TaskAsset[]
	task_TaskUsers: TaskUser[]

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface TaskList {
	task_Id: string
	task_Name: string
	task_Note: string
	taskStatus_Id: string
	taskStatus_Name: string
	severity_Id: SeverityId
	severity_Name: Severity
	site_Id: string
	task_TaskAssets: TaskAsset[]
	task_TaskUsers: TaskUser[]
}

interface TaskResult extends Validate.Result {
	tasks: Task[]
}

interface TaskListResult extends Validate.Result {
	tasks: TaskList[]
}

const defaultTask = (task: Partial<Task>): Task => ({
	task_Id: task.task_Id || '',
	task_Name: task.task_Name || '',
	task_Note: task.task_Note || '',
	taskStatus_Id: task.taskStatus_Id || '',
	taskStatus_Name: task.taskStatus_Name || '',
	task_History: task.task_History || [],
	task_Comments: task.task_Comments || [],
	severity_Id: task.severity_Id || severity.Info.id,
	severity_Name: task.severity_Name || 'Info',
	site_Id: task.site_Id || '',
	task_TaskAssets: task.task_TaskAssets || [],
	task_TaskUsers: task.task_TaskUsers || [],

	created: task.created || defaultCreated({}),
	modified: task.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultTask }
export type { Task, TaskList, TaskResult, TaskListResult }
