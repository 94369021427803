import React, { useContext } from 'react'
import { AppContext } from '../../../App'

import bcrypt from 'bcryptjs'
import { Formik, FormikHelpers } from 'formik'
import { Modal } from 'react-bootstrap'
import * as yup from 'yup'

import { Button } from '../Button/Button'
import { FormText } from '../Form/Text'
import { User, UserResult } from '../../../models/User'
import * as Request from '../../../utilities/request'

interface FormUser extends User {
	user_OfflinePasswordConfirm: string | null
}

const userValidationSchema = yup.object().shape({
	user_OfflinePassword: yup.string().required('Password is required').max(1000, 'Password must be less than ${max} characters'),
	user_OfflinePasswordConfirm: yup
		.string()
		.required('Password Confirmation is required')
		.max(1000, 'Password must be less than ${max} characters')
		.test({
			name: 'match-password',
			message: 'Passwords must match.',
			test: (value, context) => value === context.parent.user_OfflinePassword,
		}),
})

interface ResetPasswordModalProps {
	showResetPasswordModal: boolean
	setShowResetPasswordModal: React.Dispatch<React.SetStateAction<boolean>>
	user: User
}

const ResetPasswordModal = (props: ResetPasswordModalProps) => {
	const context = useContext(AppContext)

	const [formUser, setFormUser] = React.useState<FormUser>({ ...props.user, user_OfflinePassword: null, user_OfflinePasswordConfirm: null })

	React.useEffect(() => {
		setFormUser({ ...props.user, user_OfflinePassword: null, user_OfflinePasswordConfirm: null })
	}, [props])

	const handleResetPasswordSubmit = (values: FormUser, formikHelpers: FormikHelpers<FormUser>) => {
		const salt = bcrypt.genSaltSync(10)
		const hash = bcrypt.hashSync(values.user_OfflinePassword || '', salt)
		values.user_OfflinePassword = hash
		values.user_OfflinePasswordConfirm = null

		return Request.handleRequest(() => Request.put<UserResult>(`user`, values, context.appState.authState), {
			successFunction: (data) => {
				if (data.users.length > 0) {
					props.setShowResetPasswordModal(false)
					formikHelpers.resetForm()
				}
			},
			messageAction: 'creating',
			messageObject: 'user',
		})
	}

	return (
		<Formik initialValues={formUser} validationSchema={userValidationSchema} onSubmit={handleResetPasswordSubmit} enableReinitialize>
			{({ handleSubmit, isSubmitting, touched, errors, values, handleChange, handleReset }) => (
				<Modal
					show={props.showResetPasswordModal}
					onHide={() => {
						if (!isSubmitting) {
							handleReset()
							props.setShowResetPasswordModal(false)
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>Reset Password</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<FormText
							name="user_OfflinePassword"
							value={values.user_OfflinePassword || ''}
							onChange={handleChange}
							label="Password"
							type="password"
							required
							feedback={touched.user_OfflinePassword && errors.user_OfflinePassword ? errors.user_OfflinePassword : ''}
							isInvalid={touched.user_OfflinePassword && !!errors.user_OfflinePassword}
						/>
						<FormText
							name="user_OfflinePasswordConfirm"
							value={values.user_OfflinePasswordConfirm || ''}
							onChange={handleChange}
							label="Confirm Password"
							type="password"
							required
							feedback={touched.user_OfflinePasswordConfirm && errors.user_OfflinePasswordConfirm ? errors.user_OfflinePasswordConfirm : ''}
							isInvalid={touched.user_OfflinePasswordConfirm && !!errors.user_OfflinePasswordConfirm}
						/>
					</Modal.Body>

					<Modal.Footer>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleReset()
								props.setShowResetPasswordModal(false)
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleSubmit()
							}}
						>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
	)
}

export { ResetPasswordModal }
