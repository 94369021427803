import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'

interface LocationFloor {
	locationFloor_Id: string
	locationFloor_Name: string
	locationFloor_Note: string | null
	locationBuilding_Id: string | null
	site_Id: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface LocationFloorResult extends Validate.Result {
	locationFloors: LocationFloor[]
}

const defaultLocationFloor = (locationFloor: Partial<LocationFloor>): LocationFloor => ({
	locationFloor_Id: locationFloor.locationFloor_Id || '',
	locationFloor_Name: locationFloor.locationFloor_Name || '',
	locationFloor_Note: locationFloor.locationFloor_Note || null,
	locationBuilding_Id: locationFloor.locationBuilding_Id || null,
	site_Id: locationFloor.site_Id || '',

	created: locationFloor.created || defaultCreated({}),
	modified: locationFloor.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultLocationFloor }
export type { LocationFloor, LocationFloorResult }
