import React, { useEffect, useState } from 'react'
import './FileUpload.css'
import { Buffer } from 'buffer'
import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { hasPermission } from '../../../utilities/permissions/permission'

import { Messages, useMessageReducer } from '../Messages/Messages'
import { Loading } from '../Loading/Loading'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'

import { Document, DocumentResult } from '../../../models/Document'
import { defaultCreated, defaultModified } from '../../../models/History'
import { recordStatus } from '../../../constants/recordStatus'
import { PageStatus } from '../../../types/PageStatus'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../utilities/permissions/permission.d'

import documentIcon from '../../../images/icons/pdf.svg'
import uploadIcon from '../../../images/icons/upload.svg'
import deleteIcon from '../../../images/icons/trash.svg'
import PermissionsCheck from '../../Permissions/PermissionsCheck'
import { userRole } from '../../../constants/userRole'

type FileUploadProps = {
	asset_Id?: string | null
	assetMake_Id?: string | null
	assetModel_Id?: string | null
	locationBuilding_Id?: string | null
	locationFloor_Id?: string | null
	locationArea_Id?: string | null
	locationRoom_Id?: string | null
	contract_Id?: string | null
	readOnly: boolean
	hideTitle?: boolean
	listView?: boolean
	styles?: {
		outerContainerStyle?: React.CSSProperties
		uploadedContainerStyle?: React.CSSProperties
		uploaderContainerStyle?: React.CSSProperties
	}
}

const FileUpload = (props: FileUploadProps) => {
	const context = React.useContext(AppContext)

	// Currently POSTPUTDELETE permissions are identical
	// Special Document case - allow field technicians to upload/delete documents UNLESS they relate to asset make or asset model
	const hasEditPermissions =
		context.appState.userSites.find((us) => us.site_Id === context.appState.currentSite?.site_Id && us.userRole_Id === userRole['Field Technician'].id) &&
		(props.assetMake_Id || props.assetModel_Id)
			? false
			: hasPermission(PermissionModelObject.Document, PermissionModelAction.POST, context.appState, PermissionModelContext.Site)

	const [pageStatus, setPageStatus] = useState<PageStatus>('Loading')

	const [uploadedFiles, setUploadedFiles] = useState<Document[]>([])
	const [uploadError, setUploadError] = useState(false)

	const [documentIdToDelete, setDocumentIdToDelete] = React.useState<string>('')
	const [showConfirm, setShowConfirm] = React.useState<boolean>(false)

	const [messages, setMessages] = useMessageReducer([])

	useEffect(() => {
		const loadFiles = async () => {
			const [assetDocs, assetMakeDocs, assetModelDocs, locationBuildingDocs, locationFloorDocs, locationAreaDocs, locationRoomDocs, contractDocs] =
				await Promise.all([
					props.asset_Id
						? Request.get<DocumentResult>(`document?asset_Id=${props.asset_Id}`, context.appState.authState)
						: Promise.resolve({ status: 200, data: { documents: [] as Document[] } }),
					props.assetMake_Id
						? Request.get<DocumentResult>(`document?assetMake_Id=${props.assetMake_Id}`, context.appState.authState)
						: Promise.resolve({ status: 200, data: { documents: [] as Document[] } }),
					props.assetModel_Id
						? Request.get<DocumentResult>(`document?assetModel_Id=${props.assetModel_Id}`, context.appState.authState)
						: Promise.resolve({ status: 200, data: { documents: [] as Document[] } }),
					props.locationBuilding_Id
						? Request.get<DocumentResult>(`document?locationBuilding_Id=${props.locationBuilding_Id}`, context.appState.authState)
						: Promise.resolve({ status: 200, data: { documents: [] as Document[] } }),
					props.locationFloor_Id
						? Request.get<DocumentResult>(`document?locationFloor_Id=${props.locationFloor_Id}`, context.appState.authState)
						: Promise.resolve({ status: 200, data: { documents: [] as Document[] } }),
					props.locationArea_Id
						? Request.get<DocumentResult>(`document?locationArea_Id=${props.locationArea_Id}`, context.appState.authState)
						: Promise.resolve({ status: 200, data: { documents: [] as Document[] } }),
					props.locationRoom_Id
						? Request.get<DocumentResult>(`document?locationRoom_Id=${props.locationRoom_Id}`, context.appState.authState)
						: Promise.resolve({ status: 200, data: { documents: [] as Document[] } }),
					props.contract_Id
						? Request.get<DocumentResult>(`document?contract_Id=${props.contract_Id}`, context.appState.authState)
						: Promise.resolve({ status: 200, data: { documents: [] as Document[] } }),
				])
			if (
				assetDocs.status !== 200 ||
				assetMakeDocs.status !== 200 ||
				assetModelDocs.status !== 200 ||
				locationBuildingDocs.status !== 200 ||
				locationFloorDocs.status !== 200 ||
				locationAreaDocs.status !== 200 ||
				locationRoomDocs.status !== 200 ||
				contractDocs.status !== 200
			) {
				setPageStatus('Error')
			} else {
				setUploadedFiles(
					assetDocs.data.documents
						.concat(assetMakeDocs.data.documents)
						.concat(assetModelDocs.data.documents)
						.concat(locationBuildingDocs.data.documents)
						.concat(locationFloorDocs.data.documents)
						.concat(locationAreaDocs.data.documents)
						.concat(locationRoomDocs.data.documents)
						.concat(contractDocs.data.documents)
				)
				setPageStatus('Ready')
			}
		}

		loadFiles()
	}, [
		context.appState.authState,
		props.asset_Id,
		props.assetMake_Id,
		props.assetModel_Id,
		props.locationFloor_Id,
		props.locationBuilding_Id,
		props.locationArea_Id,
		props.locationRoom_Id,
		props.contract_Id,
	])

	const handleAddImage = () => {
		const input = document.createElement('input')
		input.type = 'file'

		input.addEventListener('change', () => {
			if (!input.files) return
			const file = input.files[0]
			if (!file) return
			uploadImage(file)
		})

		input.click()
	}

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
	}

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		for (let i = 0; i < e.dataTransfer.files.length; i++) {
			const file = e.dataTransfer.files.item(i)
			if (file !== null) {
				uploadImage(file)
			}
		}
	}

	const uploadImage = async (file: File) => {
		setPageStatus('Submitting')
		setUploadError(false)

		const content: ArrayBuffer = await file.arrayBuffer()
		const fileBuffer: string = Buffer.from(content).toString('base64')

		const document: Partial<Document> = {
			document_Id: '',
			document_Name: file.name,
			document_Mime: file.type,
			document_Buffer: fileBuffer,
			document_Url: '',
			asset_Id: props.asset_Id,
			assetMake_Id: !props.asset_Id ? props.assetMake_Id : null,
			assetModel_Id: !props.asset_Id ? props.assetModel_Id : null,
			locationBuilding_Id: !props.asset_Id ? props.locationBuilding_Id : null,
			locationFloor_Id: !props.asset_Id ? props.locationFloor_Id : null,
			locationArea_Id: !props.asset_Id ? props.locationArea_Id : null,
			locationRoom_Id: !props.asset_Id ? props.locationRoom_Id : null,
			contract_Id: !props.asset_Id ? props.contract_Id : null,
			site_Id: context.appState.currentSite?.site_Id,
			created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
			modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
			recordStatus_Id: recordStatus.Active.id,
		}

		await Request.handleRequest(() => Request.post<DocumentResult>('document', document, context.appState.authState), {
			successFunction: (data) => {
				setUploadedFiles((prev) => [...prev, data.documents[0]])
				setPageStatus('Ready')
			},
			failedFunction: () => {
				setUploadError(true)
				setPageStatus('Error')
			},
			setMessageFunction: setMessages,
			messageAction: 'creating',
			messageObject: 'document',
		})
	}

	const viewImage = (url: string) => {
		window.open(url)
	}

	const removeImage = async (documentId: string) => {
		await Request.handleRequest(() => Request.del<DocumentResult>(`document?Id=${documentId}`, context.appState.authState), {
			successFunction: () => {
				setUploadedFiles(uploadedFiles.filter((document) => document.document_Id !== documentId))
				setDocumentIdToDelete('')
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'document',
		})
	}

	return (
		<div className="file-uploader-wrapper" style={props.styles?.outerContainerStyle}>
			<Messages messages={messages} updateMessage={setMessages} />
			<ConfirmModal
				show={showConfirm}
				setShow={setShowConfirm}
				onConfirm={() => removeImage(documentIdToDelete)}
				title={'Confirm delete document'}
				body={'Are you sure you want to delete this document?'}
			/>
			<div className="file-uploader-wrapper" style={props.styles?.uploadedContainerStyle}>
				{!props.hideTitle && (
					<>
						<span className="dashboard-card-titles" style={styles.label}>
							Documents & Uploads
						</span>
						<br />
					</>
				)}
				{pageStatus === 'Loading' ? (
					<div>
						<Loading show />
					</div>
				) : (
					<>
						{uploadedFiles
							.filter(
								(document) =>
									document.assetMake_Id ||
									document.assetModel_Id ||
									document.locationBuilding_Id ||
									document.locationArea_Id ||
									document.locationRoom_Id ||
									document.contract_Id ||
									(props.listView && document.locationFloor_Id)
							)
							.map((document) => (
								<div key={document.document_Id} title={document.document_Name} className="file-uploader-list">
									<img src={documentIcon} alt="Document Icon" style={styles.icon} />
									<a href={document.document_Url || ''} target="_blank" rel="noreferrer">
										{document.document_Name}
									</a>
									{!props.readOnly && hasEditPermissions && (
										<button
											onClick={() => {
												setDocumentIdToDelete(document.document_Id)
												setShowConfirm(true)
											}}
										>
											<img src={deleteIcon} alt="Delete Icon" style={styles.deleteIcon} />
										</button>
									)}
								</div>
							))}
						{!props.listView &&
							uploadedFiles
								.filter((document) => document.locationFloor_Id)
								.map((document) => (
									<div
										key={document.document_Id}
										title={document.document_Name}
										className="file-uploader-image file-uploader-image-floorplan"
									>
										<img
											alt={document.document_Name}
											src={document.document_Url || ''}
											onError={({ currentTarget }) => {
												currentTarget.onerror = null
												currentTarget.src = documentIcon
											}}
										/>
										<button
											onClick={() => {
												viewImage(document.document_Url || '')
											}}
											className={`file-uploader-floorplan`}
										>
											FLOORPLAN
										</button>
									</div>
								))}
						<div className="file-uploader-wrapper" style={props.styles?.uploaderContainerStyle}>
							{uploadedFiles
								.filter((document) => document.asset_Id)
								.map((document) => {
									return props.listView ? (
										<div key={document.document_Id} title={document.document_Name} className="file-uploader-list">
											<a href={document.document_Url || ''} target="_blank" rel="noreferrer">
												{document.document_Name}
											</a>
											{!props.readOnly && hasEditPermissions && (
												<button
													onClick={() => {
														setDocumentIdToDelete(document.document_Id)
														setShowConfirm(true)
													}}
												>
													<img src={deleteIcon} alt="Delete Icon" style={styles.deleteIcon} />
												</button>
											)}
										</div>
									) : (
										<div key={document.document_Id} title={document.document_Name} className="file-uploader-image">
											<img
												alt={document.document_Name}
												src={document.document_Url || ''}
												onError={({ currentTarget }) => {
													currentTarget.onerror = null
													currentTarget.src = documentIcon
												}}
											/>
											<button
												onClick={() => {
													viewImage(document.document_Url || '')
												}}
												className={`file-uploader-view${props.readOnly ? ' file-uploader-read-only' : ''}`}
											>
												View
											</button>
											<PermissionsCheck
												object={PermissionModelObject.Document}
												action={PermissionModelAction.DELETE}
												context={PermissionModelContext.Site}
											>
												<button
													onClick={() => {
														setDocumentIdToDelete(document.document_Id)
														setShowConfirm(true)
													}}
													className={`file-uploader-remove${props.readOnly ? ' file-uploader-read-only' : ''}`}
												>
													Remove
												</button>
											</PermissionsCheck>
										</div>
									)
								})}
						</div>
					</>
				)}
			</div>
			{!props.readOnly && hasEditPermissions ? (
				<div
					title={'Upload An Image'}
					className={`file-uploader-add${uploadError ? ' file-uploader-error' : ''}`}
					onClick={handleAddImage}
					onDragOver={handleDragOver}
					onDrop={handleDrop}
					style={props.styles?.uploaderContainerStyle}
				>
					{pageStatus === 'Ready' ? (
						<>
							<img src={uploadIcon} alt="Upload Icon" style={styles.icon} />
							<p>Drag files to upload</p>
							<p>or</p>
							<p className="fake-link">Browse your files</p>
						</>
					) : (
						<Loading show />
					)}
				</div>
			) : null}
		</div>
	)
}

const styles = {
	icon: {
		padding: '10px',
		width: '50px',
		height: '50px',
	},
	deleteIcon: {
		width: '30px',
		height: '30px',
	},
	label: {
		height: 'unset',
	},
}

export { FileUpload }
export type { FileUploadProps }
