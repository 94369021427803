import React, { useContext } from 'react'
import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { Col, Container, Row } from 'react-bootstrap'
import { PageStatus } from '../../types/PageStatus'
import ContractDashboardHeader from '../../components/UI/ContractDashboard/ContractDashboardHeader'
import ContractDashboardStats from '../../components/UI/ContractDashboard/ContractStats'
import ContractSidebarStats from '../../components/UI/ContractDashboard/ContractSidebarStats'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { Contract, ContractResult } from '../../models/Contract'
import { ContractCard } from '../../components/UI/ContractCard/ContractCard'

export interface Card {
	name: string
	created: string
	count: number
	siteCard: JSX.Element
}

const ContractDashboard = () => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [messages, setMessages] = useMessageReducer([])

	const [contracts, setContracts] = React.useState<Contract[]>([])

	const [cards, setCards] = React.useState<Card[]>([])
	const [sortedFilteredCards, setSortedFilteredCards] = React.useState<Card[]>([])

	React.useEffect(() => {
		const getData = async () => {
			const contractsReq = await Request.get<ContractResult>(`contract`, context.appState.authState)

			setContracts(contractsReq.data.contracts)

			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [])

	React.useEffect(() => {
		const generateContractCards = (contracts: Contract[]) => {
			const contractCards: Card[] = contracts.map((contract: Contract) => {
				return {
					name: contract.site?.site_Name || contract.siteGroup?.siteGroup_Name || '',
					created: contract.created.create_Ts,
					count: contract.contract_PaymentAgreements.length,
					siteCard: <ContractCard contract={contract} />,
				}
			})
			setCards(contractCards)
			setSortedFilteredCards(contractCards)
		}
		if (contracts) {
			generateContractCards(contracts)
		}
	}, [contracts])

	return (
		<div>
			<Messages messages={messages} updateMessage={setMessages} />
			<ContractDashboardHeader cards={cards} sortedFilteredCards={sortedFilteredCards} setSortedFilteredCards={setSortedFilteredCards} />

			<Container fluid style={styles.ContractDashboardContainer}>
				<Row>
					<Col sm={9}>
						<ContractDashboardStats contracts={contracts} />

						<Row style={styles.siteListHeader}>
							<Col sm={4}>
								<span className="dashboard-list-titles">AGREEMENT ID</span>
							</Col>
							<Col sm={7}>
								<span className="dashboard-list-titles">AGREEMENT END DATE</span>
							</Col>
							<Col sm={1} className="text-center">
								<span className="dashboard-list-titles">ASSETS</span>
							</Col>
						</Row>
						{pageStatus !== 'Loading' ? (
							<Row>
								<Col>
									{sortedFilteredCards.map((card) => (
										<div key={card.name}>{card.siteCard}</div>
									))}
								</Col>
							</Row>
						) : null}
					</Col>
					<Col sm={3}>
						<Row>
							<Col>
								<ContractSidebarStats contracts={contracts} />
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

const styles = {
	ContractDashboardContainer: {
		paddingLeft: '50px',
		paddingRight: '50px',
		display: 'grid',
		marginTop: '-3%',
	},
	siteListHeader: { paddingLeft: '10px', paddingRight: '10px', marginTop: '20px' },
}

export { ContractDashboard }
