import React from 'react'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import { AppContext } from '../../../App'

import { Line } from '../Line/Line'
import { Back } from '../Back/Back'
import { FileUpload, FileUploadProps } from '../FileUpload/FileUpload'
import { Note } from '../Note/Note'
import { AssetOverviewListing, AssetOverviewListingProps } from '../../../screens/Asset/AssetOverviewListing'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import Error from '../Error/Error'
import PermissionsCheck from '../../Permissions/PermissionsCheck'
import { FormText } from '../Form/Text'

import { PageStatus } from '../../../types/PageStatus'
import { userRole } from '../../../constants/userRole'

import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../utilities/permissions/permission.d'
import { hasPermission } from '../../../utilities/permissions/permission'

const validationSchema = yup.object().shape({
	name: yup.string().required('Name is required.').max(100, 'Name must be less than ${max} characters.'),
	note: yup.string().optional().max(1000, 'Note must be less than ${max} characters.'),
})

interface InformationProps {
	pageStatus: PageStatus
	name: string | null
	note: string | null
	customDeleteMessage?: string
	fileUploadProps: Omit<FileUploadProps, 'readOnly'>
	assetOverviewListingProps: Omit<AssetOverviewListingProps, 'readOnly'>
	permissionModelObject: PermissionModelObject
	handleSave: (values: { name: string; note: string }) => Promise<void>
	handleDelete: () => Promise<void>
}

const Information = (props: InformationProps) => {
	const { appState } = React.useContext(AppContext)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Ready')
	const [showModal, setShowModal] = React.useState<boolean>(false)

	const handleCancel = (resetForm: () => void) => {
		resetForm()
		setPageStatus('Ready')
	}

	const handleSave = async (values: { name: string; note: string }) => {
		setPageStatus('Submitting')
		await props.handleSave(values)
		setPageStatus('Ready')
	}

	const handleDelete = async () => {
		setPageStatus('Submitting')
		await props.handleDelete()
	}

	// Special Document case - allow field technicians to upload/delete documents UNLESS they relate to asset make or asset model
	const hasEditPermissions =
		appState.userSites.find((us) => us.site_Id === appState.currentSite?.site_Id && us.userRole_Id === userRole['Field Technician'].id) &&
		(props.permissionModelObject === PermissionModelObject.AssetMake || props.permissionModelObject === PermissionModelObject.AssetModel)
			? false
			: hasPermission(PermissionModelObject.Document, PermissionModelAction.POST, appState, PermissionModelContext.Site)
	const calculatedUploadStyles = uploadStyles(hasEditPermissions)

	return (
		<div>
			<ConfirmModal show={showModal} setShow={setShowModal} onConfirm={handleDelete} title={'Confirm delete'} body={props.customDeleteMessage || ''} />
			<Row className="page-header-row">
				<Col>
					<Back />
				</Col>
			</Row>
			{props.pageStatus === 'Error' && props.name === null ? (
				<Error />
			) : (
				<Formik
					initialValues={{ name: props.name || '', note: props.note || '' }}
					validationSchema={validationSchema}
					onSubmit={handleSave}
					enableReinitialize
				>
					{({ handleSubmit, errors, values, handleChange, resetForm }) => (
						<>
							{props.name !== null && (
								<Row className="page-header-row">
									<Col>
										{pageStatus === 'Editing' ? (
											<FormText name={'name'} className="page-header-primary" value={values.name} onChange={handleChange} />
										) : (
											<h1 className="page-header-primary">{values.name}</h1>
										)}
										<p className="invalid-feedback-custom">{errors.name}</p>
									</Col>
									<Col />
									{(pageStatus === 'Editing' || pageStatus === 'Error') && (
										<>
											<Col sm={1}>
												<Button variant={'secondary'} onClick={() => handleCancel(resetForm)}>
													Cancel
												</Button>
											</Col>
											<Col sm={1}>
												<Button variant={'primary'} onClick={() => handleSubmit()}>
													Save
												</Button>
											</Col>
											<PermissionsCheck
												object={props.permissionModelObject}
												action={PermissionModelAction.DELETE}
												context={PermissionModelContext.Site}
											>
												<Col sm={1}>
													<Button variant={'danger'} onClick={() => setShowModal(true)}>
														Delete
													</Button>
												</Col>
											</PermissionsCheck>
										</>
									)}
									{pageStatus === 'Ready' && (
										<PermissionsCheck
											object={props.permissionModelObject}
											action={PermissionModelAction.PUT}
											context={PermissionModelContext.Site}
										>
											<Col sm={1}>
												<Button variant={'primary'} onClick={() => setPageStatus('Editing')}>
													Edit
												</Button>
											</Col>
										</PermissionsCheck>
									)}
								</Row>
							)}
							<Line />
							<Row>
								<Col>
									<FileUpload {...props.fileUploadProps} readOnly={false} listView={true} styles={calculatedUploadStyles} />
								</Col>
								<Col>
									<Card style={noteStyles.card}>
										<Note note={values.note} pageStatus={pageStatus} handleChange={handleChange} />
									</Card>
								</Col>
							</Row>
							<Row>
								<h1 className="page-header-primary">Assets</h1>
								<AssetOverviewListing {...props.assetOverviewListingProps} readOnly />
							</Row>
						</>
					)}
				</Formik>
			)}
		</div>
	)
}

const uploadStyles: (editable: boolean) => { [key: string]: React.CSSProperties } = (editable: boolean) => ({
	outerContainerStyle: {
		flexDirection: 'column',
	},
	uploadedContainerStyle: {
		boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.06)',
		borderRadius: '8px',
		backgroundColor: 'white',
		border: '1px solid rgba(0, 0, 0, 0.5)',
		height: editable ? '400px' : '570px',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '30px',
		flexDirection: 'column',
		flexWrap: 'nowrap',
	},
})

const noteStyles: { [key: string]: React.CSSProperties } = {
	card: {
		height: '570px',
		marginTop: '0px',
		padding: '30px',
	},
}

export { Information }
