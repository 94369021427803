import React from 'react'
import { Card } from 'react-bootstrap'

import { BacnetExporter } from '../SiteExport/BacnetExport'

const BacnetExportCard = () => {
	return (
		<Card className="p-0">
			<Card.Header>Export BACnet Points</Card.Header>
			<Card.Body>
				<BacnetExporter />
			</Card.Body>
		</Card>
	)
}

export { BacnetExportCard }
