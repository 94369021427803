export const ModuleNameValues = ['SmartFlow Hardware as a Service', 'SmartFlow Managed Service', 'SmartFlow Software as a Service'] as const
export type ModuleName = (typeof ModuleNameValues)[number]

export const ModuleIdValues = ['HAAS0000-0000-0000-0000-000000000000', 'managed0-0000-0000-0000-000000000000', 'SAAS0000-0000-0000-0000-000000000000'] as const
export type ModuleId = (typeof ModuleIdValues)[number]

export type Module = { id: ModuleId; defaultAssetRate: number; productCode: string }

export type ModuleRecord = Record<ModuleName, Module>
export const module: ModuleRecord = {
	'SmartFlow Hardware as a Service': { id: 'HAAS0000-0000-0000-0000-000000000000', defaultAssetRate: 16, productCode: 'SF-HAAS' },
	'SmartFlow Managed Service': { id: 'managed0-0000-0000-0000-000000000000', defaultAssetRate: 4, productCode: 'SF-MANAGEDSERV' },
	'SmartFlow Software as a Service': { id: 'SAAS0000-0000-0000-0000-000000000000', defaultAssetRate: 7, productCode: 'SF-SAAS' },
}

export const ModuleDropdownValues = ModuleNameValues.map((value) => ({
	value: module[value].id,
	label: value,
}))

export const getModuleNameFromId = (Module_Id: ModuleId) => {
	const Module_Name = ModuleNameValues.find((Module_Name) => module[Module_Name].id === Module_Id)
	if (!Module_Name) throw new Error(`Module name not found for Module_Id: ${Module_Id}`)
	return Module_Name
}

export const getModuleProductCodeFromId = (Module_Id: ModuleId) => {
	const Module_Name = ModuleNameValues.find((Module_Name) => module[Module_Name].id === Module_Id)
	if (!Module_Name) throw new Error(`Module name not found for Module_Id: ${Module_Id}`)
	else return module[Module_Name].productCode
}

export const getModuleIdFromName = (Module_Name: ModuleName) => {
	const Module_Id = ModuleIdValues.find((Module_Id) => module[Module_Name].id === Module_Id)
	if (!Module_Id) throw new Error(`Module_Id not found for name: ${Module_Name}`)
	return Module_Id
}
