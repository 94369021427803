import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { FieldArray, Formik } from 'formik'
import * as yup from 'yup'

import { AppContext } from '../../../../App'
import * as Request from '../../../../utilities/request'
import { hasPermission } from '../../../../utilities/permissions/permission'

import { Loading } from '../../Loading/Loading'
import { BacnetSettingField } from './BacnetSettingField'

import { CommonSettingsComponentProps } from '../GroupInformation'
import { PageStatus } from '../../../../types/PageStatus'
import { defaultModified } from '../../../../models/History'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../../utilities/permissions/permission.d'
import {
	BacnetTemperatureAlarmSetting,
	BacnetTemperatureAlarmSettingsResult,
	defaultBacnetTemperatureAlarmSettingsForGroup,
} from '../../../../models/BacnetTemperatureAlarmSetting'

// validation currently done through min/max on number field
const bacnetSettingsValidationSchema = yup.object().shape({
	bacnetSettings: yup.array().of(
		yup.object().shape({
			bacnetTemperatureAlarmSetting_Temperature: yup
				.number()
				.required()
				.min(0, 'Temperature must be at least 0 degrees')
				.max(100, 'Temperature must not be more than 100 degrees'),
			bacnetTemperatureAlarmSetting_Duration: yup.number().required().min(1, 'Duration must be at least 1'), // not sure what the unit is here
		})
	),
})

interface BacnetSettingsData {
	bacnetSettings: BacnetTemperatureAlarmSetting[]
}

const TemperatureBacnetSettings = (props: CommonSettingsComponentProps) => {
	const context = React.useContext(AppContext)
	const isDefaultGroup = props.groupId === 'DefaultTemperatureGroup'
	const hasEditPermissions = hasPermission(PermissionModelObject.Group, PermissionModelAction.PUT, context.appState, PermissionModelContext.Site)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [data, setData] = React.useState<BacnetSettingsData | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			if (isDefaultGroup) {
				setData({ bacnetSettings: defaultBacnetTemperatureAlarmSettingsForGroup(props.groupId, context.appState.userAttributes.user_Id) })
			} else {
				const bacnetReq = await Request.get<BacnetTemperatureAlarmSettingsResult>(
					`bacnetTemperatureAlarmSetting?Group_Id=${props.groupId}`,
					context.appState.authState
				)
				setData({ bacnetSettings: bacnetReq.data.bacnetTemperatureAlarmSettings })
			}
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [props.groupId])

	const handleSave = async (values: BacnetSettingsData) => {
		setPageStatus('Submitting')
		const modified = defaultModified({ modified_UserId: context.appState.userAttributes.user_Id })
		const bacnetRes = await Promise.all(
			values.bacnetSettings.map((bacnetSetting) =>
				Request.put<BacnetTemperatureAlarmSettingsResult>('bacnetTemperatureAlarmSetting', { ...bacnetSetting, modified }, context.appState.authState)
			)
		)
		if (bacnetRes.every((res) => res.status === 200)) {
			setData({
				bacnetSettings: bacnetRes.flatMap((res) => res.data.bacnetTemperatureAlarmSettings),
			})
			setPageStatus('Ready')
		} else {
			props.setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Error editing the group settings`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
	}

	return (
		<Card style={styles.card} className="site-card">
			<Row className="generic-card-list-heading-row">
				<Col sm="auto" style={styles.cellGrow}>
					<span className="dashboard-card-titles">Bacnet Temperature Alarm Settings</span>
				</Col>
			</Row>
			<Row>
				{data ? (
					<Formik
						initialValues={data}
						validationSchema={bacnetSettingsValidationSchema}
						onSubmit={handleSave}
						enableReinitialize
						innerRef={props.saveBacnetSettingsRef}
					>
						{({ values, setFieldValue }) => (
							<Row style={styles.row} className="no-gutter">
								<FieldArray
									name={'bacnetSettings'}
									render={() => (
										<>
											{values.bacnetSettings.map((bacnetSetting, index) => (
												<Col sm={6} key={bacnetSetting.bacnetTemperatureAlarmSetting_Id} style={styles.formRow}>
													<BacnetSettingField
														bacnetSetting={bacnetSetting}
														index={index}
														setFieldValue={setFieldValue}
														isDisabled={pageStatus === 'Submitting' || !hasEditPermissions}
														isReadOnly={props.pageStatus !== 'Editing'}
													/>
												</Col>
											))}
										</>
									)}
								/>
							</Row>
						)}
					</Formik>
				) : (
					<Row style={styles.row}>
						<Loading show={true} />
					</Row>
				)}
			</Row>
		</Card>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	card: {
		minHeight: '250px',
	},
	row: {
		marginTop: '20px',
		justifyContent: 'space-between',
	},
	formRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		marginBottom: '20px',
	},
}

export { TemperatureBacnetSettings }
export type { BacnetSettingsData }
