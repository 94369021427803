export const UserRoleValues = ['System Admin', 'System Specialist', 'Contract Manager', 'Field Technician', 'Display User', 'Site User'] as const
export type UserRole = (typeof UserRoleValues)[number]

export const UserRoleIdValues = [
	'sysAdmin-0000-0000-0000-000000000000',
	'sysSpeci-0000-0000-0000-000000000000',
	'contract-0000-0000-0000-000000000000',
	'technici-0000-0000-0000-000000000000',
	'display0-0000-0000-0000-000000000000',
	'site0000-0000-0000-0000-000000000000',
] as const
export type UserRoleId = (typeof UserRoleIdValues)[number]

export const userRole: Record<UserRole, { id: UserRoleId }> = {
	'System Admin': { id: 'sysAdmin-0000-0000-0000-000000000000' },
	'System Specialist': { id: 'sysSpeci-0000-0000-0000-000000000000' },
	'Contract Manager': { id: 'contract-0000-0000-0000-000000000000' },
	'Field Technician': { id: 'technici-0000-0000-0000-000000000000' },
	'Display User': { id: 'display0-0000-0000-0000-000000000000' },
	'Site User': { id: 'site0000-0000-0000-0000-000000000000' },
}

export interface IUserRole {
	userRole_Id: string
	userRole_Name: string
}

export const systemUserRoleIds = [userRole['System Admin'].id, userRole['System Specialist'].id, userRole['Contract Manager'].id]

export const userRoleDropdownValues = UserRoleValues.map((value) => ({
	value: userRole[value].id,
	label: value,
}))
/*
 These roles are 'super user' roles. They don't apply to a site, they effect the User table userRole_Id
*/
export const userRoleAdminDropdownValues = userRoleDropdownValues.filter(
	(value) => value.label === 'System Admin' || value.label === 'System Specialist' || value.label === 'Contract Manager'
)

/*
 These roles are standard site roles, they are recorded in the UserSite table
*/
export const userRoleSiteDropdownValues = userRoleDropdownValues.filter(
	(value) => value.label === 'Field Technician' || value.label === 'Display User' || value.label === 'Site User'
)
