import { UserRoleId } from '../../constants/userRole'

export enum PermissionModelObject {
	Asset = 'Asset',
	AssetClass = 'AssetClass',
	AssetMake = 'AssetMake',
	AssetModel = 'AssetModel',
	AssetTree = 'AssetTree',
	AssetTreeStatus = 'AssetTreeStatus',
	AssetType = 'AssetType',
	Bacnet = 'Bacnet',
	ChannelMap = 'ChannelMap',
	Contract = 'Contract',
	ContractModule = 'ContractModule',
	Document = 'Document',
	Event = 'Event',
	EventSetting = 'EventSetting',
	EventSettingType = 'EventSettingType',
	Export = 'Export',
	Group = 'Group',
	GroupType = 'GroupType',
	Import = 'Import',
	Invoice = 'Invoice',
	InvoiceItem = 'InvoiceItem',
	LocationArea = 'LocationArea',
	LocationBuilding = 'LocationBuilding',
	LocationFloor = 'LocationFloor',
	LocationRoom = 'LocationRoom',
	Maintenance = 'Maintenance',
	MaintenanceType = 'MaintenanceType',
	Module = 'Module',
	PaymentAgreement = 'PaymentAgreement',
	Reading = 'Reading',
	ReadingType = 'ReadingType',
	Site = 'Site',
	SiteGroup = 'SiteGroup',
	SiteStatus = 'SiteStatus',
	Stats = 'Stats',
	Task = 'Task',
	TaskAsset = 'TaskAsset',
	TaskComment = 'TaskComment',
	TaskHistory = 'TaskHistory',
	TaskStatus = 'TaskStatus',
	TaskUser = 'TaskUser',
	User = 'User',
	UserInvite = 'UserInvite',
	UserRole = 'UserRole',
	UserSite = 'UserSite',
}

export const ALL_OBJECTS = Object.keys(PermissionModelObject)

export enum PermissionModelAction {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	DELETE = 'DELETE',
}

export enum PermissionModelContext {
	Site = 'Site',
	SiteGroup = 'SiteGroup',
	None = 'None',
}

export type PermissionRequirementArgs = { userRoleId: UserRoleId | ''; object: PermissionModelObject; action: PermissionModelAction }
export type PermissionRequirement = (args: PermissionRequirementArgs) => boolean

export type PermissionModelObjectMatrix = {
	[key in PermissionModelObject]: {
		[key in PermissionModelAction]: {
			PermissionsRequired: PermissionRequirement[]
		}
	}
}
