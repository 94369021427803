import React from 'react'
import { Dropdown } from 'react-bootstrap'
import download from '../../../images/icons/download.svg'
import chevron from '../../../images/icons/chevron.svg'
import pdf from '../../../images/icons/pdf.svg'
import { Columns } from 'react-feather'
import { AppContext } from '../../../App'
import { base64ToArrayBuffer, downloadBlob, fetchPDFData } from '../../../utilities/download'
import { PageStatus } from '../../../types/PageStatus'
import { Loading } from '../Loading/Loading'
import { MessageAction } from '../Messages/Message'
import * as Papa from 'papaparse'

interface PdfDownload {
	menuLabel: string
	pdfUrl: string
	pdfFilename: string
}

interface CsvData {
	[key: string]: string | number | boolean | null
}

interface CsvHeader {
	key: string
	columnName: string
}

interface CsvDownload {
	menuLabel: string
	csvFilename: string
	data: CsvData[]
	headers: CsvHeader[] // ordered as they should appear in csv
}

interface ExportDropdownProps {
	pageStatus: PageStatus
	setPageStatus: React.Dispatch<React.SetStateAction<PageStatus>>
	setMessages: (action: MessageAction) => void
	pdfDownloads?: { options: PdfDownload[]; downloadAllOption?: boolean }
	csvDownloads?: { options: CsvDownload[] }
	buttonText?: string
}

const ExportDropdown = (props: ExportDropdownProps) => {
	const context = React.useContext(AppContext)

	const downloadPDF = async (pdfUrl: string, pdfFilename: string) => {
		props.setPageStatus('Submitting')
		const data = await fetchPDFData(context.appState.authState, pdfUrl, props.setMessages)
		if (data) downloadBlob(base64ToArrayBuffer(data), `${pdfFilename}.pdf`, 'application/pdf')
		props.setPageStatus('Ready')
	}

	const downloadAllPDFs = async () => {
		props.setPageStatus('Submitting')

		const pdfData: { pdf: PdfDownload; data: string | undefined }[] = []

		if (props.pdfDownloads !== undefined) {
			for (const pdf of props.pdfDownloads.options) {
				const res = await fetchPDFData(context.appState.authState, pdf.pdfUrl)
				pdfData.push({ pdf: pdf, data: res })
			}
		}

		if (pdfData.some((pdf) => typeof pdf.data === 'undefined')) {
			props.setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Error downloading PDFs',
					timeout: 5000,
					dismissible: true,
				},
			})
		} else {
			pdfData.forEach((pdf) => {
				downloadBlob(base64ToArrayBuffer(pdf.data as string), `${pdf.pdf.pdfFilename}.pdf`, 'application/pdf')
			})
		}
		props.setPageStatus('Ready')
	}

	const downloadCsv = (filename: string, data: CsvData[], headers: CsvHeader[]) => {
		const orderedData: CsvData[] = []
		data.forEach((row, index) => {
			orderedData[index] = {}
			headers.forEach((header) => {
				orderedData[index][header.columnName] = row[header.key]
			})
		})
		const csvData = Papa.unparse(orderedData)
		downloadBlob(csvData, `${filename}.csv`, 'text/csv;charset=utf-8;')
	}

	return (
		<Dropdown>
			<Dropdown.Toggle
				className={`action-dropdown d-flex justify-content-${
					props.pageStatus !== 'Submitting' ? 'between' : 'center'
				} align-items-center px-3 hide-after`}
				variant="secondary"
				id="dropdown-action"
				disabled={props.pageStatus !== 'Ready'}
			>
				{props.pageStatus !== 'Submitting' ? (
					<>
						<img src={download} />
						<span className="span-bold px-2">{props.buttonText || 'Export'}</span>
						<img src={chevron} />
					</>
				) : (
					<Loading show small />
				)}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{/* PDF downloads */}
				{props.pdfDownloads !== undefined &&
					props.pdfDownloads.options.map((option, index) => (
						<Dropdown.Item
							key={index}
							onClick={() => {
								downloadPDF(option.pdfUrl, option.pdfFilename)
							}}
							className="d-flex justify-content-between align-items-center"
						>
							<img src={pdf} />
							<span style={styles.labelText}>{option.menuLabel}</span>
							<span></span>
						</Dropdown.Item>
					))}
				{props.pdfDownloads !== undefined && props.pdfDownloads.options.length > 0 && props.pdfDownloads.downloadAllOption && (
					<Dropdown.Item
						onClick={() => {
							downloadAllPDFs()
						}}
						className="d-flex justify-content-between align-items-center"
					>
						<img src={pdf} />
						<span style={styles.labelText}>Download All Reports</span>
						<span></span>
					</Dropdown.Item>
				)}

				{/* CSV downloads */}
				{props.csvDownloads !== undefined &&
					props.csvDownloads.options.map((option, index) => (
						<Dropdown.Item
							key={index}
							onClick={() => {
								downloadCsv(option.csvFilename, option.data, option.headers)
							}}
							className="d-flex justify-content-between align-items-center"
						>
							<Columns size={16} />
							<span style={styles.labelText}>{option.menuLabel}</span>
							<span></span>
						</Dropdown.Item>
					))}
			</Dropdown.Menu>
		</Dropdown>
	)
}

const styles = {
	labelText: { paddingLeft: '10px', paddingRight: '10px' },
} satisfies Record<string, React.CSSProperties>

export default ExportDropdown
export type { CsvData }
