import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Col, Form, Row } from 'react-bootstrap'
import { PageStatus } from '../../../types/PageStatus'
import 'react-datepicker/dist/react-datepicker.css'
import { Listing } from '../Listing/Listing'
import { ConsecutiveFlow, ConsecutiveFlowResult } from '../../../models/Report'
import { Link } from 'react-router-dom'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import { ReportApiType } from '../../../constants/report'
import * as Request from '../../../utilities/request'
import ExportDropdown from './ExportDropdown'
import { Messages, useMessageReducer } from '../Messages/Messages'
import { Button } from '../Button/Button'

const csvFileHeaders = [
	{ key: 'asset_Name', columnName: 'TMV ID' },
	{ key: 'asset_Location', columnName: 'Location' },
	{ key: 'daysBelowThreshold', columnName: 'Days Below Threshold' },
	{ key: 'dateLastRun', columnName: 'Date Last Run' },
]

interface ConsecutiveFlowListingProps {
	reportTypeSelect: JSX.Element
	usageContext: 'site' | 'siteGroup'
}

const ConsecutiveFlowListing = (props: ConsecutiveFlowListingProps) => {
	const context = useContext(AppContext)
	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [consecutiveFlows, setConsecutiveFlows] = React.useState<ConsecutiveFlow[]>([])
	const [belowThresholdDays, setBelowThresholdDays] = React.useState(7)
	const [inputBelowThresholdDays, setInputBelowThresholdDays] = React.useState(7)
	const belowThresholdDaysMin = 0
	const [tmvOrFixture, setTmvOrFixture] = React.useState<string>('TmvOnly=true')

	const reportQueryUrl = `${ReportApiType.CONSECUTIVE_FLOW.value}?${
		props.usageContext === 'site' ? `Site_Id=${context.appState.currentSite?.site_Id}` : `SiteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
	}&BelowThresholdDays=${belowThresholdDays}&${tmvOrFixture}`

	React.useEffect(() => {
		const getData = async () => {
			const [consecutiveFlowsReq] = await Promise.all([Request.get<ConsecutiveFlowResult>(`report/${reportQueryUrl}`, context.appState.authState)])
			setConsecutiveFlows(consecutiveFlowsReq.data.consecutiveFlows)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, belowThresholdDays, tmvOrFixture])

	React.useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			setBelowThresholdDays(inputBelowThresholdDays)
		}, 1000)

		return () => clearTimeout(delayDebounceFn)
	}, [inputBelowThresholdDays])

	const fileName = `${
		props.usageContext === 'site' ? context.appState.currentSite?.site_Name : context.appState.currentSiteGroup?.siteGroup_Name
	} - Consecutive Flow Report`

	return (
		<>
			<Messages messages={messages} updateMessage={setMessages} />
			<Row>
				<Col sm="auto" style={styles.select}>
					{props.reportTypeSelect}
				</Col>
				<Col sm="auto">
					<Button
						variant={tmvOrFixture === 'TmvOnly=true' ? 'primary' : 'secondary'}
						style={{ ...styles.button, ...styles.first }}
						onClick={() => {
							setTmvOrFixture('TmvOnly=true')
						}}
					>
						Tmvs
					</Button>
					<Button
						variant={tmvOrFixture === 'FixtureOnly=true' ? 'primary' : 'secondary'}
						style={{ ...styles.button, ...styles.last }}
						onClick={() => {
							setTmvOrFixture('FixtureOnly=true')
						}}
					>
						Fixtures
					</Button>
				</Col>
				<Col sm="auto" className="center-flex px-1">
					<span>Display {tmvOrFixture === 'TmvOnly=true' ? 'TMV' : 'Fixture'}s that have not met the usage threshold for </span>
				</Col>
				<Col sm="auto" className="px-0">
					<Form.Control
						type="number"
						min={belowThresholdDaysMin}
						value={inputBelowThresholdDays}
						onChange={(e) => {
							if (Number(e.currentTarget.value) < belowThresholdDaysMin) setInputBelowThresholdDays(belowThresholdDaysMin)
							else setInputBelowThresholdDays(Number(e.currentTarget.value))
						}}
					/>
				</Col>
				<Col sm="auto" className="center-flex ps-1">
					<span> days</span>
				</Col>
				<Col />
				<Col sm="auto">
					<ExportDropdown
						pageStatus={pageStatus}
						setPageStatus={setPageStatus}
						setMessages={setMessages}
						pdfDownloads={{
							options: [
								{
									menuLabel: 'PDF',
									pdfUrl: reportQueryUrl,
									pdfFilename: fileName,
								},
							],
						}}
						csvDownloads={{
							options: [
								{
									menuLabel: 'CSV',
									csvFilename: fileName,
									data: consecutiveFlows.map((flow) => ({
										...flow,
										dateLastRun: flow.dateLastRun
											? formatIncomingDateTime({
													dateTime: flow.dateLastRun,
													format: 'Date',
											  })
											: 'No Reading',
									})),
									headers: csvFileHeaders,
								},
							],
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Listing
						name="Consecutive Flow"
						namePlural="Consecutive Flows"
						list={consecutiveFlows}
						getIDFunc={(cf) => cf.asset_Id}
						selectedActions={[]}
						isLoading={pageStatus !== 'Ready' && pageStatus !== 'Submitting'}
						columns={[
							{
								value: (item) => item.asset_Name,
								render: (item) => (
									<Link
										to={{
											pathname: `/asset/${item.asset_Id}`,
										}}
									>
										{item.asset_Name}
									</Link>
								),
								showHeader: true,
								headerText: 'Asset ID',
								sortColumnName: 'asset_Name',
								filterType: 'string',
								filterOptions: {
									columnName: 'asset_Name',
								},
							},
							{
								value: (item) => item.asset_Location || '',
								render: (item) => <>{item.asset_Location}</>,
								showHeader: true,
								headerText: 'Location',
								sortColumnName: 'asset_Location',
								filterType: 'string',
								filterOptions: {
									columnName: 'asset_Location',
								},
							},
							{
								value: (item) => item.daysBelowThreshold.toString().padStart(6, '0'),
								render: (item) => <>{item.daysBelowThreshold}</>,
								showHeader: true,
								headerText: 'Days Below Threshold',
								sortColumnName: 'daysBelowThreshold',
							},
							{
								value: (item) => item.dateLastRun || '',
								render: (item) => (
									<>
										{item.dateLastRun
											? formatIncomingDateTime({
													dateTime: item.dateLastRun,
													format: 'Date',
											  })
											: 'No Reading'}
									</>
								),
								showHeader: true,
								headerText: 'Date Last Run',
								sortColumnName: 'dateLastRun',
							},
						]}
						defaultSort={{ column: 'asset_Name', order: 'DSC' }}
					/>
				</Col>
			</Row>
		</>
	)
}

const styles = {
	select: { minWidth: '300px' },
	button: { minHeight: '38px' },
	first: {
		borderRadius: '4px 0 0 4px',
		marginRight: '-1px',
	},
	last: {
		borderRadius: '0 4px 4px 0',
	},
} satisfies Record<string, React.CSSProperties>

export default ConsecutiveFlowListing
