import { PermissionModelObject, ALL_OBJECTS } from '../permission.d'

// System Specialist has GET permissions for everything, POSTPUTDELETE permissions are currently identical
const GET_PERMISSIONS = ALL_OBJECTS
const POST_PERMISSIONS = [
	PermissionModelObject.Asset,
	PermissionModelObject.AssetClass,
	PermissionModelObject.AssetMake,
	PermissionModelObject.AssetModel,
	PermissionModelObject.AssetTree,
	PermissionModelObject.AssetTreeStatus,
	PermissionModelObject.AssetType,
	PermissionModelObject.Bacnet,
	PermissionModelObject.ChannelMap,
	PermissionModelObject.Document,
	PermissionModelObject.Event,
	PermissionModelObject.EventSetting,
	PermissionModelObject.EventSettingType,
	PermissionModelObject.Export,
	PermissionModelObject.Group,
	PermissionModelObject.GroupType,
	PermissionModelObject.Import,
	PermissionModelObject.LocationArea,
	PermissionModelObject.LocationBuilding,
	PermissionModelObject.LocationFloor,
	PermissionModelObject.LocationRoom,
	PermissionModelObject.Maintenance,
	PermissionModelObject.MaintenanceType,
	PermissionModelObject.Module,
	PermissionModelObject.Reading,
	PermissionModelObject.ReadingType,
	PermissionModelObject.Site,
	PermissionModelObject.SiteGroup,
	PermissionModelObject.SiteStatus,
	PermissionModelObject.Stats,
	PermissionModelObject.Task,
	PermissionModelObject.TaskAsset,
	PermissionModelObject.TaskComment,
	PermissionModelObject.TaskHistory,
	PermissionModelObject.TaskStatus,
	PermissionModelObject.TaskUser,
]
const PUT_PERMISSIONS = POST_PERMISSIONS
const DELETE_PERMISSIONS = POST_PERMISSIONS

export const systemSpecialistPermissionObject = {
	GET: GET_PERMISSIONS,
	POST: POST_PERMISSIONS,
	PUT: PUT_PERMISSIONS,
	DELETE: DELETE_PERMISSIONS,
}
