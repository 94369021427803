import React, { useContext } from 'react'
import { AppContext } from '../../../App'

import { Formik, FormikHelpers } from 'formik'
import { Modal } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'

import * as Request from '../../../utilities/request'
import { Button } from '../Button/Button'
import { FormText } from '../Form/Text'
import { defaultCreated, defaultModified } from '../../../models/History'
import { SiteGroup, SiteGroupResult, defaultSiteGroup } from '../../../models/SiteGroup'
import { useNavigate } from 'react-router-dom'

const groupValidationSchema = yup.object().shape({
	siteGroup_Name: yup.string().required('Name is required').max(100, 'Name must be less than ${max} characters'),
	/* siteGroup_Email: yup.string().required('Email is required'), */
})

interface AddGroupModalProps {
	showAddGroupModal: boolean
	siteGroups: SiteGroup[] | null
	setSiteGroups: React.Dispatch<React.SetStateAction<SiteGroup[] | null>>
	setShowAddGroupModal: React.Dispatch<React.SetStateAction<boolean>>
	siteGroup_ParentId?: string
	customOnSubmit?: (values: SiteGroup, formikHelpers: FormikHelpers<SiteGroup>) => Promise<void>
}

const AddGroupModal = (props: AddGroupModalProps) => {
	const context = useContext(AppContext)
	const navigate = useNavigate();

	const defaultFormSiteGroup = defaultSiteGroup({
		siteGroup_Id: uuidv4(),
		siteGroup_ParentId: props.siteGroup_ParentId,
		created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
	})
	const [formSiteGroup, setFormSiteGroup] = React.useState<SiteGroup>(defaultFormSiteGroup)

	React.useEffect(() => {
		setFormSiteGroup(defaultFormSiteGroup)
	}, [props.siteGroup_ParentId])

	const handleAddGroupSubmit = (values: SiteGroup, formikHelpers: FormikHelpers<SiteGroup>) => {
		return Request.handleRequest(() => Request.post<SiteGroupResult>(`siteGroup`, values, context.appState.authState), {
			successFunction: (data) => {
				if (data.siteGroups.length > 0) {
					props.setSiteGroups([...(props.siteGroups || []), ...data.siteGroups])
					props.setShowAddGroupModal(false)
					formikHelpers.resetForm()
					navigate(`/editSiteGroup/${data.siteGroups[0].siteGroup_Id}`)
				}
			},
			messageAction: 'creating',
			messageObject: 'siteGroup',
		})
	}

	return (
		<Formik
			initialValues={formSiteGroup}
			validationSchema={groupValidationSchema}
			onSubmit={props.customOnSubmit || handleAddGroupSubmit}
			enableReinitialize
		>
			{({ handleSubmit, isSubmitting, touched, errors, values, handleChange, handleReset }) => (
				<Modal
					show={props.showAddGroupModal}
					onHide={() => {
						if (!isSubmitting) {
							handleReset()
							props.setShowAddGroupModal(false)
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>Add New Group</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<FormText
							name="siteGroup_Name"
							value={values.siteGroup_Name}
							onChange={handleChange}
							label="Name"
							required
							feedback={touched.siteGroup_Name && errors.siteGroup_Name ? errors.siteGroup_Name : ''}
							isInvalid={!!errors.siteGroup_Name}
						/>
						{/* <FormText
							name="siteGroup_Email"
							value={values.siteGroup_Email}
							onChange={handleChange}
							label="Email"
							required
							feedback={touched.siteGroup_Email && errors.siteGroup_Email ? errors.siteGroup_Email : ''}
							isInvalid={!!errors.siteGroup_Email}
						/> */}
					</Modal.Body>

					<Modal.Footer>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleReset()
								setFormSiteGroup(defaultFormSiteGroup)
								props.setShowAddGroupModal(false)
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleSubmit()
								setFormSiteGroup(defaultFormSiteGroup)
							}}
						>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
	)
}

export { AddGroupModal }
