import * as Validate from '../utilities/validate'

interface TaskHistory {
	taskHistory_Id: string
	taskHistory_Note: string | null
	taskHistory_Ts: string
	task_Id: string
}

interface TaskHistoryResult extends Validate.Result {
	taskHistories: TaskHistory[]
}

const defaultTaskHistory = (taskHistory: Partial<TaskHistory>): TaskHistory => ({
	taskHistory_Id: taskHistory.taskHistory_Id || '',
	taskHistory_Note: taskHistory.taskHistory_Note || null,
	taskHistory_Ts: taskHistory.taskHistory_Ts || '',
	task_Id: taskHistory.task_Id || '',
})

export { defaultTaskHistory }
export type { TaskHistory, TaskHistoryResult }
