export const colors = {
	colorPrimaryCyan: '#56c5d0',
	colorPrimaryBlack: '#000000',
	colorPrimaryGrey: '#505050',
	colorStatusRed: '#ff5630',
	colorStatusOrange: '#eb7f31',
	colorStatusYellow: '#ffab00',
	colorStatusGreen: '#36b37f',
	colorStatusGrey: '#7c839d',
	colorStatusLightGrey: '#d3d3d3',
	colorStatusBlue: '#308dfb',
	colorSecondaryGreyLight: '#f4f4f4',
	colorSecondaryWhite: '#f4f4f4',
	colorSecondaryCyanLight: '#8fd9e0',
}
