import React from 'react'
import { Card } from 'react-bootstrap'

import { SiteExporter } from '../SiteExport/SiteExporter'

const SiteExportCard = () => {
	return (
		<Card className="p-0">
			<Card.Header>Export Site Configuration File</Card.Header>
			<Card.Body>
				<SiteExporter />
			</Card.Body>
		</Card>
	)
}

export { SiteExportCard }
