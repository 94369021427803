import React from 'react'

interface LineProps {
	style?: React.CSSProperties
}

const Line = (props: LineProps) => {
	return <div style={{ ...style, ...props.style }}></div>
}

const style = { borderBottom: '1px solid #A5A5A5', marginTop: '10px', marginBottom: '10px' }

export { Line }
