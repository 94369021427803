import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { eventSettingType } from '../constants/eventSettingType'
import { defaultEventSettingValuesByType } from './EventSetting'

interface SiteSetting {
	siteSetting_Id: string
	siteSetting_Value: number
	siteSetting_IsEnabled: boolean
	eventSettingType_Id: string
	eventSettingType_Name: string
	site_Id: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface SiteSettingResult extends Validate.Result {
	siteSettings: SiteSetting[]
}

const defaultSiteSetting = (siteSetting: Partial<SiteSetting>): SiteSetting => ({
	siteSetting_Id: siteSetting.siteSetting_Id || '',
	siteSetting_Value: siteSetting.siteSetting_Value || 0,
	siteSetting_IsEnabled: siteSetting.siteSetting_IsEnabled != null ? siteSetting.siteSetting_IsEnabled : true,
	eventSettingType_Id: siteSetting.eventSettingType_Id || '',
	eventSettingType_Name: siteSetting.eventSettingType_Name || '',
	site_Id: siteSetting.site_Id || '',

	created: siteSetting.created || defaultCreated({}),
	modified: siteSetting.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

const defaultSiteSettings = [
	defaultSiteSetting({
		siteSetting_Id: 'Default TMV Flow Metric Days',
		eventSettingType_Id: eventSettingType['TMV Flow Metric Days'].id,
		eventSettingType_Name: 'TMV Flow Metric Days',
		siteSetting_Value: defaultEventSettingValuesByType['TMV Flow Metric Days'],
		siteSetting_IsEnabled: true,
	}),
	defaultSiteSetting({
		siteSetting_Id: 'Default Fixture Flow Metric Days',
		eventSettingType_Id: eventSettingType['Fixture Flow Metric Days'].id,
		eventSettingType_Name: 'Fixture Flow Metric Days',
		siteSetting_Value: defaultEventSettingValuesByType['Fixture Flow Metric Days'],
		siteSetting_IsEnabled: false,
	}),
]

export { defaultSiteSetting, defaultSiteSettings }
export type { SiteSetting, SiteSettingResult }
