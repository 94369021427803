import React from 'react'
import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { TrendsApiType } from '../../../constants/trends'
import { PageStatus } from '../../../types/PageStatus'
import { Card } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import { DateTime } from 'luxon'
import { colors } from '../../../constants/colors'
import { PreviousYearPercents, PreviousYearPercentsResult } from '../../../models/Trends'

interface TempExceptionEventsGraphProps {
	context: 'site' | 'siteGroup'
}

const MonthlyReadingGraph = (props: TempExceptionEventsGraphProps) => {
	const context = React.useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [firstMonthDisplayDate, setFirstMonthDisplayDate] = React.useState<DateTime>(DateTime.now().minus({ months: 11 }))

	const [previousYearPercents, setPreviousYearPercents] = React.useState<PreviousYearPercents>()

	React.useEffect(() => {
		const getData = async () => {
			const [previousYearPercentsReq] = await Promise.all([
				Request.get<PreviousYearPercentsResult>(
					`trends/${TrendsApiType.MONTHLY_READING.value}?${
						props.context === 'site'
							? `Site_Id=${context.appState.currentSite?.site_Id}`
							: `SiteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
					}`,
					context.appState.authState
				),
			])
			if (previousYearPercentsReq.data) {
				setPreviousYearPercents(previousYearPercentsReq.data.previousYearPercents)
				setFirstMonthDisplayDate(
					DateTime.fromObject({
						month: previousYearPercentsReq.data.previousYearPercents.displayMonthFirstMonthlyPercent,
						year: previousYearPercentsReq.data.previousYearPercents.displayYearFirstMonthlyPercent,
					})
				)
				setPageStatus('Ready')
			} else {
				setPageStatus('Error')
			}
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props])

	return (
		<Card className="p-0">
			<Card.Header>Monthly Reading{pageStatus === 'Error' && ' - Error Loading Data'}</Card.Header>
			<Card.Body>
				<div style={styles.graphContainer}>
					<Bar
						data={{
							labels: Array.from({ length: 12 }, (_, i) => firstMonthDisplayDate.plus({ months: i }).toFormat('MMM yy')),
							datasets: [
								{
									data: previousYearPercents?.monthlyPercents,
									backgroundColor: (ctx) => {
										const value = ctx.dataset.data[ctx.dataIndex] as number
										return value === 0
											? colors.colorStatusBlue
											: value < 50
											? colors.colorStatusRed
											: value < 75
											? colors.colorStatusOrange
											: value < 100
											? colors.colorStatusYellow
											: colors.colorStatusGreen
									},
								},
							],
						}}
						options={{
							maintainAspectRatio: false,
							scales: {
								y: {
									title: { display: true, text: '% of TMVs with a Monthly Reading' },
									min: 0,
									max: 100,
								},
							},
							plugins: {
								legend: {
									display: false,
								},
								tooltip: {
									callbacks: {
										label: (ctx) => {
											const value = ctx.dataset.data[ctx.dataIndex] as number
											return `${value}%`
										},
									},
								},
								datalabels: {
									display: false,
								},
							},
						}}
					/>
				</div>
			</Card.Body>
		</Card>
	)
}

const styles = {
	graphContainer: {
		position: 'relative',
		minHeight: '300px',
		width: '100%',
	},
} satisfies Record<string, React.CSSProperties>

export default MonthlyReadingGraph
