import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { StatsCard } from '../StatsCard/StatsCard'
import { Contract } from '../../../models/Contract'

interface StatProps {
	contracts: Contract[]
}

const SiteDashboardStats = (props: StatProps) => {
	return (
		<Row>
			<Col>
				<ContractStat {...props} />
			</Col>
			<Col>
				<EventStat />
			</Col>
			<Col>
				<TaskStat />
			</Col>
		</Row>
	)
}

const TaskStat = () => {
	return <StatsCard icon={'bell'} title={``} subtitle="" pageStatus={'Ready'} stats={[]} />
}

const EventStat = () => {
	return <StatsCard icon={'event'} title={``} subtitle="" pageStatus={'Ready'} stats={[]} />
}

const ContractStat = (props: StatProps) => {
	return <StatsCard icon={'site'} title={`${props.contracts.length}`} subtitle="Contracts" pageStatus={'Ready'} stats={[]} />
}

export default SiteDashboardStats
