import React from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap'
import { Frown } from 'react-feather'

class ErrorBoundary extends React.Component<{ children: JSX.Element }, { hasError: boolean; url: string }> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(props: any) {
		super(props)
		this.state = {
			hasError: false,
			url: '',
		}
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async componentDidCatch(error: any, info: any) {
		console.log('error', error)
		console.log('info', info)

		// // Log error
		// const currentSession: any = await Auth.currentSession()
		// const authState = {
		// 	isLoggedIn: true,
		// 	apiToken: currentSession.idToken.jwtToken,
		// }

		// const errorObject = {
		// 	ErrorLogType: 'WebsiteError',
		// 	ErrorLogDescription: JSON.stringify(error.message).concat(' | ', JSON.stringify(info)),
		// 	ErrorLogPage: window.location.href,
		// }

		// if (!window.location.href.includes('localhost')) {
		// 	put('error', errorObject, authState)
		// }
	}

	// UNSAFE_componentWillReceiveProps(nextProps: any) {
	// 	// Used so when the navbar is used we try the request again
	// 	this.setState({
	// 		hasError: false,
	// 		url: window.location.href,
	// 	})
	// }

	render() {
		if (this.state.hasError) {
			return (
				<Container>
					<Card style={{ backgroundColor: 'white', marginTop: '20%', textAlign: 'center' }}>
						<Row style={{ marginBottom: '20px' }}>
							<Col>
								<Frown />
							</Col>
						</Row>
						<Row>
							<Col>
								<h1>Ooops! We&apos;re sorry, it looks like something has gone wrong</h1>
							</Col>
						</Row>
						<Row>
							<Col>
								<h1>Our development team has been notified</h1>
							</Col>
						</Row>
					</Card>
				</Container>
			)
		}
		return this.props.children
	}
}

export { ErrorBoundary }
