import React from 'react'
import PickerHeader from '../../components/UI/ContextualPickers/PickerHeader'
import LiveTemperaturesCard from '../../components/UI/LiveTemperatures/LiveTemperaturesCard'

const LiveTemperatures = () => {
	return (
		<>
			<PickerHeader type="site" title="Live Temperatures" />
			<LiveTemperaturesCard />
		</>
	)
}

export default LiveTemperatures
