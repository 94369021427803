import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { Task, defaultTask } from './Task'
import { User, defaultUser } from './User'

interface TaskUser {
	taskUser_Id: string
	task_Id: string
	taskUser_Task: Task
	user_Id: string | null
	taskUser_User: User
	userRole_Id: string | null
	userRole_Name: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface TaskUserResult extends Validate.Result {
	taskUsers: TaskUser[]
}

const defaultTaskUser = (taskUser: Partial<TaskUser>): TaskUser => ({
	taskUser_Id: taskUser.taskUser_Id || '',
	task_Id: taskUser.task_Id || '',
	taskUser_Task: taskUser.taskUser_Task || defaultTask({}),
	user_Id: taskUser.user_Id || null,
	taskUser_User: taskUser.taskUser_User || defaultUser({}),
	userRole_Id: taskUser.userRole_Id || null,
	userRole_Name: taskUser.userRole_Name || '',

	created: taskUser.created || defaultCreated({}),
	modified: taskUser.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultTaskUser }
export type { TaskUser, TaskUserResult }
