import React from 'react'
import './EditAsset.css'
import { Row, Col, Card } from 'react-bootstrap'
import { FormText } from '../../Form/Text'
import { FormDate } from '../../Form/Date'

import { CommonComponentProps } from '../../../../screens/Asset/EditAssetDetails'

const AssetInstallation = (props: CommonComponentProps) => {
	return (
		<Card className="site-card" style={styles.card}>
			<Row className="site-card-list-heading-row">
				<span className="dashboard-card-titles">Installation Information</span>
			</Row>
			<Row style={styles.row}>
				<Col sm={6} className="edit-asset-field">
					<FormDate
						name={'asset_asset_InstallDate'}
						label={'Installation Date'}
						value={props.asset.asset_InstallDate || undefined}
						onChange={(e) => props.handleChange('asset.asset_InstallDate', e.target.value)}
						disabled={props.pageStatus === 'Submitting'}
						feedback={props.errors?.asset_InstallDate}
					/>
				</Col>
			</Row>
			<Row style={styles.row}>
				<Col sm={6} className="edit-asset-field">
					<FormText
						name={'asset_asset_InstallNote'}
						label={'Installation Notes'}
						as={'textarea'}
						value={props.asset.asset_InstallNote || ''}
						onChange={(e) => props.handleChange('asset.asset_InstallNote', e.target.value)}
						disabled={props.pageStatus === 'Submitting'}
						feedback={props.errors?.asset_InstallNote}
						isInvalid={!!props.errors?.asset_InstallNote}
					/>
				</Col>
			</Row>
		</Card>
	)
}

const styles = {
	card: {
		minHeight: '308px',
	},
	row: {
		padding: '20px',
		justifyContent: 'space-between',
	},
	disabledPlusButton: {
		cursor: 'not-allowed',
	},
}

export { AssetInstallation }
