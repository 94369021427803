import React from 'react'
import { Card, Row } from 'react-bootstrap'

import { SiteImporter } from '../../components/UI/SiteImport/SiteImporter'

const SiteImport = () => {
	return (
		<div className="page">
			<Card style={styles.card}>
				<Card.Title>
					<Row className="generic-card-list-heading-row">Site Import</Row>
				</Card.Title>
				<Card.Body>
					<SiteImporter />
				</Card.Body>
			</Card>
		</div>
	)
}

const styles = {
	card: {
		padding: '20px 30px',
	},
}

export { SiteImport }
