import React from 'react'
import { useLocation } from 'react-router-dom'

import { Tabs, Tab } from 'react-bootstrap'
import { BacnetSettingsCard } from '../../components/UI/SiteSettings/BacnetSettingsCard'
import { SiteSettingsCard } from '../../components/UI/SiteSettings/SiteSettingsCard'
import { GroupSettingsCard } from '../../components/UI/SiteSettings/GroupSettingsCard'
import { AssetImportCard } from '../../components/UI/SiteSettings/AssetImportCard'
import { SiteExportCard } from '../../components/UI/SiteSettings/SiteExportCard'
import PickerHeader from '../../components/UI/ContextualPickers/PickerHeader'

import { AppContext } from '../../App'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../utilities/permissions/permission.d'
import { hasPermission } from '../../utilities/permissions/permission'
import { BacnetExportCard } from '../../components/UI/SiteSettings/BacnetExportCard'

type TabKey = 'general' | 'bacnet' | 'groups' | 'assetImport' | 'siteExport'

const SiteSettings = () => {
	const { appState } = React.useContext(AppContext)
	const location = useLocation()

	const getTab = () => {
		const hash = location.hash.substring(1)
		return ['bacnet', 'groups', 'assetImport', 'siteExport'].includes(hash) ? (hash as TabKey) : 'general'
	}

	const [activeKey, setActiveKey] = React.useState<TabKey>(getTab())

	const changeTab = (key: string | null) => {
		const tabKey = (key || 'general') as TabKey
		window.location.hash = tabKey
		setActiveKey(tabKey)
	}

	return (
		<>
			<PickerHeader type="site" title="Settings" />
			<Tabs activeKey={activeKey} onSelect={changeTab}>
				<Tab eventKey="general" title="General">
					<SiteSettingsCard />
				</Tab>
				<Tab eventKey="bacnet" title="Bacnet Settings">
					<BacnetSettingsCard />
					<BacnetExportCard />
				</Tab>
				<Tab eventKey="groups" title="Group Settings">
					<GroupSettingsCard />
				</Tab>
				{/* Cannot use PermissionsCheck component here as Tab is an abstract concept */}
				{hasPermission(PermissionModelObject.Import, PermissionModelAction.POST, appState, PermissionModelContext.Site) && (
					<Tab eventKey="assetImport" title="Asset Import">
						<AssetImportCard />
					</Tab>
				)}
				{hasPermission(PermissionModelObject.Export, PermissionModelAction.GET, appState, PermissionModelContext.Site) && (
					<Tab eventKey="siteExport" title="Site Export">
						<SiteExportCard />
					</Tab>
				)}
			</Tabs>
		</>
	)
}

export { SiteSettings }
