import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { AppContext } from '../../../App'
import { Site, SiteResult } from '../../../models/Site'
import { PageStatus } from '../../../types/PageStatus'
import * as Request from '../../../utilities/request'
import SiteSummaryCard from './SiteSummaryCard'
import { Loading } from '../Loading/Loading'

const SiteSummaryCards = () => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [sites, setSites] = React.useState<Site[]>([])

	React.useEffect(() => {
		const getData = async () => {
			const [siteReq] = await Promise.all([
				Request.get<SiteResult>(`site?siteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`, context.appState.authState),
			])
			setSites(siteReq.data.sites)
			setPageStatus('Ready')
		}
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	return (
		<Row>
			{pageStatus === 'Ready' ? (
				sites.map((site) => (
					<Col key={site.site_Id} sm={4}>
						<SiteSummaryCard site={site} />
					</Col>
				))
			) : (
				<Col className="d-flex align-items-center justify-content-center">
					<Loading show />
				</Col>
			)}
		</Row>
	)
}

export default SiteSummaryCards
