import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { FormikProps } from 'formik'
import { Loading } from '../Loading/Loading'
import { TemperatureEventSettings, EventSettingsData } from './Temperature/TemperatureEventSettings'
import { TemperatureBacnetSettings, BacnetSettingsData } from './Temperature/TemperatureBacnetSettings'
import { BatteryEventSettings } from './Battery/BatteryEventSettings'
import { GroupAssets, AssetGroupData } from './GroupAssets'
import PermissionsCheck from '../../Permissions/PermissionsCheck'
import { GroupList } from '../../../models/Group'
import { PageStatus } from '../../../types/PageStatus'
import { groupType } from '../../../constants/groupType'
import { MessageAction } from '../Messages/Message'
import { assetClass } from '../../../constants/assetClass'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../utilities/permissions/permission.d'

interface GroupInformationProps {
	pageStatus: PageStatus
	group: GroupList | null
	setMessages: (messages: MessageAction) => void
}

const GroupInformation = (props: GroupInformationProps) => {
	const saveEventSettingsRef: React.RefObject<FormikProps<EventSettingsData>> = React.useRef(null)
	const saveBacnetSettingsRef: React.RefObject<FormikProps<BacnetSettingsData>> = React.useRef(null)
	const saveAssetsRef: React.RefObject<FormikProps<AssetGroupData>> = React.useRef(null)

	const isDefaultGroup = props.group?.group_Id === 'DefaultTemperatureGroup' || props.group?.group_Id === 'DefaultBatteryGroup'

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const handleSave = () => {
		setPageStatus('Submitting')
		saveEventSettingsRef.current?.handleSubmit()
		saveBacnetSettingsRef.current?.handleSubmit()
		saveAssetsRef.current?.handleSubmit()
		setPageStatus('Ready')
	}

	const handleCancel = () => {
		saveEventSettingsRef.current?.resetForm()
		saveBacnetSettingsRef.current?.resetForm()
		saveAssetsRef.current?.resetForm()
		setPageStatus('Ready')
	}

	const EventSettingsComponent = SettingsComponentByGroupType[props.group?.groupType_Id || '']
	const assetClassFilter = AssetClassFilterByGroupType[props.group?.groupType_Id || '']

	return (
		<>
			{props.pageStatus === 'Ready' && props.group && EventSettingsComponent ? (
				<>
					<EventSettingsComponent
						groupId={props.group.group_Id}
						pageStatus={pageStatus}
						saveEventSettingsRef={saveEventSettingsRef}
						saveBacnetSettingsRef={saveBacnetSettingsRef}
						setPageStatus={setPageStatus}
						setMessages={props.setMessages}
					/>
					{props.group.groupType_Id === groupType.Temperature.id && (
						<TemperatureBacnetSettings
							groupId={props.group.group_Id}
							pageStatus={pageStatus}
							saveEventSettingsRef={saveEventSettingsRef}
							saveBacnetSettingsRef={saveBacnetSettingsRef}
							setPageStatus={setPageStatus}
							setMessages={props.setMessages}
						/>
					)}
				</>
			) : props.pageStatus === 'Loading' ? (
				<Loading show={true} />
			) : null}
			{props.pageStatus === 'Ready' && props.group && !isDefaultGroup ? (
				<GroupAssets
					groupId={props.group.group_Id}
					groupTypeId={props.group.groupType_Id}
					pageStatus={pageStatus}
					assetClassId={assetClassFilter}
					saveAssetsRef={saveAssetsRef}
					setPageStatus={setPageStatus}
					setMessages={props.setMessages}
				/>
			) : props.pageStatus === 'Loading' ? (
				<Loading show={true} />
			) : null}
			{pageStatus === 'Editing' && (
				<PermissionsCheck object={PermissionModelObject.Group} action={PermissionModelAction.PUT} context={PermissionModelContext.Site}>
					<Row style={styles.row}>
						<Col sm={'1'}>
							<Button style={styles.button} variant={'secondary'} onClick={handleCancel}>
								Cancel
							</Button>
						</Col>
						<Col sm={'1'}>
							<Button style={styles.button} onClick={handleSave}>
								Save
							</Button>
						</Col>
					</Row>
				</PermissionsCheck>
			)}
		</>
	)
}

interface CommonSettingsComponentProps {
	groupId: string
	pageStatus: PageStatus
	saveEventSettingsRef: React.RefObject<FormikProps<EventSettingsData>>
	saveBacnetSettingsRef: React.RefObject<FormikProps<BacnetSettingsData>>
	setPageStatus: React.Dispatch<React.SetStateAction<PageStatus>>
	setMessages: (message: MessageAction) => void
}

const SettingsComponentByGroupType: { [key: string]: ((props: CommonSettingsComponentProps) => JSX.Element) | null } = {
	[groupType.Temperature.id]: TemperatureEventSettings,
	[groupType.Battery.id]: BatteryEventSettings,
	[groupType.Flow.id]: () => <></>,
	[groupType.Custom.id]: null,
	['']: null,
}

const AssetClassFilterByGroupType = {
	[groupType.Temperature.id]: assetClass.Tmv.id,
	[groupType.Battery.id]: assetClass.Transmitter.id,
	[groupType.Flow.id]: '',
	['']: '',
}

const styles: { [key: string]: React.CSSProperties } = {
	row: {
		marginTop: '20px',
		marginBottom: '20px',
	},
	button: {
		width: '100%',
	},
}

export { GroupInformation }
export type { CommonSettingsComponentProps }
