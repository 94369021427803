import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

import { Messages, useMessageReducer } from '../Messages/Messages'
import { FilterDropdownOption, Listing } from '../Listing/Listing'
import { AddGroupModal } from '../GroupDetails/AddGroupModal'

import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { hasPermission } from '../../../utilities/permissions/permission'

import { PageStatus } from '../../../types/PageStatus'
import { GroupList, GroupListResult, defaultGroups } from '../../../models/Group'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../utilities/permissions/permission.d'

import addIcon from '../../../images/icons/add-white.svg'

interface GroupSettingsCardProps {
	filter?: string
	readOnly?: boolean
}

const GroupSettingsCard = (props: GroupSettingsCardProps) => {
	const context = useContext(AppContext)
	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [showModal, setShowModal] = React.useState<boolean>(false)

	const [groups, setGroups] = React.useState<GroupList[] | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [groupResponse] = await Promise.all([
				Request.get<GroupListResult>(`group?${props.filter || `site_Id=${context.appState.currentSite?.site_Id}`}`, context.appState.authState),
			])
			setGroups(defaultGroups.concat(groupResponse.data.groups))
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	const groupTypes =
		groups?.reduce((accumulator, group) => {
			const typeIndex = accumulator.findIndex((acc) => acc.value === group.groupType_Id)
			if (typeIndex < 0) {
				accumulator.push({ value: group.groupType_Id, text: group.groupType_Name })
			}
			return accumulator
		}, [] as FilterDropdownOption[]) || []

	return (
		<>
			<Messages messages={messages} updateMessage={setMessages} />

			<AddGroupModal show={showModal} setShow={setShowModal} />

			<Card className="p-0">
				<Card.Header>Groups</Card.Header>
				<Card.Body>
					<Listing<GroupList>
						name="Group"
						namePlural="Groups"
						list={groups || []}
						getIDFunc={(item) => item.group_Id}
						selectedActions={[]}
						headerButtons={
							!props.readOnly &&
							hasPermission(PermissionModelObject.Group, PermissionModelAction.POST, context.appState, PermissionModelContext.Site)
								? [
										{
											content: (
												<>
													<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
													<span style={{ color: 'white' }}>Add New Group</span>
												</>
											),
											onClick: () => {
												setShowModal(true)
											},
										},
								  ]
								: []
						}
						columns={[
							{
								value: (item) => item.groupType_Id,
								render: (item) => <>{item.groupType_Name}</>,
								showHeader: true,
								headerText: 'Type',
								sortColumnName: 'groupType_Name',
								filterType: 'dropdown',
								filterOptions: {
									columnName: 'groupType_Name',
									options: groupTypes,
								},
							},
							{
								value: (item) => item.group_Name || '',
								render: (item) => (
									<Link
										to={{
											pathname: `/groupSetting/${item.group_Id}`,
										}}
									>
										{item.group_Name}
									</Link>
								),
								showHeader: true,
								headerText: 'Name',
								sortColumnName: 'group_Name',
								filterType: 'string',
								filterOptions: {
									columnName: 'group_Name',
								},
							},
						]}
						defaultSort={{ column: 'group_Name', order: 'ASC' }}
						isLoading={pageStatus !== 'Ready'}
					/>
				</Card.Body>
			</Card>
		</>
	)
}

export { GroupSettingsCard }
