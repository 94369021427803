import React, { useContext } from 'react'
import { AppContext } from '../../../App'

import * as Request from '../../../utilities/request'
import { PageStatus } from '../../../types/PageStatus'

import { Card, Col, Row } from 'react-bootstrap'
import { AssetTypeCount, AssetTypeCountResult } from '../../../models/Stats'
import { Loading } from '../Loading/Loading'
import { useNavigate } from 'react-router-dom'

const AssetCountBar = () => {
	const context = useContext(AppContext)
	const navigate = useNavigate()
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [siteAssetCount, setSiteAssetCount] = React.useState<AssetTypeCount | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [siteAssetCountReq] = await Promise.all([
				Request.get<AssetTypeCountResult>(`stats/assetTypeCount?site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
			])
			setSiteAssetCount(siteAssetCountReq.data)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context.appState.currentSite])

	return (
		<Card className="p-0" style={styles.card}>
			<Row>
				{pageStatus === 'Ready' && siteAssetCount ? (
					<>
						<Col
							style={styles.stat}
							className="p-3 stealth-text-link"
							onClick={() => {
								navigate('/assets#tmv')
							}}
						>
							<Row>
								<Col className={'center-flex'}>TMVs</Col>
							</Row>
							<Row>
								<Col className={'center-flex'}>{siteAssetCount.tmv}</Col>
							</Row>
						</Col>

						<Col
							style={styles.stat}
							className="p-3 stealth-text-link"
							onClick={() => {
								navigate('/assets#fixture')
							}}
						>
							<Row>
								<Col className={'center-flex'}>FIXTURES</Col>
							</Row>
							<Row>
								<Col className={'center-flex'}>{siteAssetCount.fixture}</Col>
							</Row>
						</Col>
						<Col
							style={styles.stat}
							className="p-3 stealth-text-link"
							onClick={() => {
								navigate('/assets#hub')
							}}
						>
							<Row>
								<Col className={'center-flex'}>HUBS</Col>
							</Row>
							<Row>
								<Col className={'center-flex'}>{siteAssetCount.hub}</Col>
							</Row>
						</Col>
						<Col
							className="p-3 stealth-text-link"
							onClick={() => {
								navigate('/assets#transmitter')
							}}
						>
							<Row>
								<Col className={'center-flex'}>TRANSMITTERS</Col>
							</Row>
							<Row>
								<Col className={'center-flex'}>{siteAssetCount.transmitter}</Col>
							</Row>
						</Col>
					</>
				) : (
					<Col className={'center-flex'}>
						<Loading show />
					</Col>
				)}
			</Row>
		</Card>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	card: {
		minHeight: '80px',
	},
	stat: {
		borderRight: `1px solid var(--bs-card-border-color)`,
	},
}

export { AssetCountBar }
