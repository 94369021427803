import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Col, Row } from 'react-bootstrap'
import { PageStatus } from '../../../types/PageStatus'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { AfterContent, Listing } from '../Listing/Listing'
import { TemperatureException, TemperatureExceptionResult, TemperatureExceptionSummary } from '../../../models/Report'
import { Link } from 'react-router-dom'
import { formatIncomingDateTime, formatOutgoingDateTime } from '../../../utilities/formatDate'
import { ReportApiTempExceptionFilterType, ReportApiTempExceptionFilterTypeSelectOptions, ReportApiType } from '../../../constants/report'
import * as Request from '../../../utilities/request'
import { DateTime } from 'luxon'
import ExportDropdown, { CsvData } from './ExportDropdown'
import { Messages, useMessageReducer } from '../Messages/Messages'
import { AssetList, AssetListResult } from '../../../models/Asset'
import { Button } from '../Button/Button'
import Select from 'react-select'

import chevron from '../../../images/icons/chevron.svg'
import { Loading } from '../Loading/Loading'
import { assetType } from '../../../constants/assetType'
import TemperatureExceptionDetailModal from './TemperauteExceptionDetailModal'
import { AddTempExceptionDetailEventToReportFunction } from './FleetHealthReport'
import graph from '../../../images/icons/graph.svg'
import { secondsToReadable } from '../../../utilities/strings'

const getCsvFileHeaders = (monthlyReport: boolean, onlyThermalFlush?: boolean, summary?: boolean) =>
	summary && monthlyReport
		? [
				{ key: 'grade', columnName: 'Grade' },
				{ key: 'asset_Name', columnName: 'TMV ID' },
				{ key: 'asset_Location', columnName: 'Location' },
				{ key: 'alertCount', columnName: onlyThermalFlush ? 'Thermal Flush Count' : 'Exception Count' },
				{ key: 'previousMonthCount', columnName: 'Last Month' },
				{ key: 'comments', columnName: 'Comments' },
		  ]
		: [
				{ key: 'asset_Name', columnName: 'TMV ID' },
				{ key: 'asset_Location', columnName: 'Location' },
				...(summary
					? [{ key: 'alertCount', columnName: onlyThermalFlush ? 'Thermal Flush Count' : 'Exception Count' }]
					: [
							{ key: 'startTs', columnName: 'Start Time' },
							{ key: 'finishTs', columnName: 'End Time' },
					  ]),
		  ]

interface TemperatureExceptionReportListingProps {
	usageContext: 'site' | 'siteGroup'
	reportTypeSelect?: React.JSX.Element
	renderTitle?: boolean
	hideDownload?: boolean
	onlyThermalFlush?: boolean
	embeddedInFleetHealth?: {
		temperatureExceptionSummaries: TemperatureExceptionSummary[]
		addToFleetHealthReportFunction: AddTempExceptionDetailEventToReportFunction
		isTempExceptionEventInReportFunction: (event: TemperatureException) => boolean
	}
}

const TemperatureExceptionReportListing = (props: TemperatureExceptionReportListingProps) => {
	const context = useContext(AppContext)
	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [assetPageStatus, setAssetPageStatus] = React.useState<PageStatus>('Loading')
	const [modalTempException, setModalTempException] = React.useState<TemperatureException>()
	const [temperatureExceptionSummaries, setTemperatureExceptionSummaries] = React.useState<TemperatureExceptionSummary[]>(
		props.embeddedInFleetHealth ? props.embeddedInFleetHealth.temperatureExceptionSummaries : []
	)
	const [assets, setAssets] = React.useState<AssetList[]>([])
	const [expanded, setExpanded] = React.useState<Record<string, boolean>>({})
	const [reportDate, setReportDate] = React.useState(DateTime.now().set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }))
	const [tempExceptionFilterType, setTempExceptionFilterType] = React.useState<ReportApiTempExceptionFilterType>(ReportApiTempExceptionFilterType.MONTH)
	const [assetId, setAssetId] = React.useState<string>('')

	const assetOptions = React.useMemo(
		() =>
			assets.map((a) => ({
				value: a.asset_Id,
				label: `${props.usageContext === 'siteGroup' ? `(${a.site_Name}) ` : ''}${a.asset_Name}`,
			})),
		[assets]
	)

	const reportQueryUrl = `?TempExceptionFilterType=${tempExceptionFilterType.value}&${
		tempExceptionFilterType === ReportApiTempExceptionFilterType.TMV
			? `Asset_Id=${assetId}`
			: `${
					props.usageContext === 'site'
						? `Site_Id=${context.appState.currentSite?.site_Id}`
						: `SiteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
			  }&Date=${formatOutgoingDateTime({
					dateTime: reportDate,
					format: 'DateTimeObject',
					timeZone:
						props.usageContext === 'site' ? context.appState.currentSite?.site_Timezone : context.appState.currentSiteGroup?.siteGroup_Timezone,
			  })}`
	}`

	React.useEffect(() => {
		const getData = async () => {
			const [assetReq] = await Promise.all([
				Request.get<AssetListResult>(
					`asset?${
						props.usageContext === 'site'
							? `Site_Id=${context.appState.currentSite?.site_Id}`
							: `SiteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
					}`,
					context.appState.authState
				),
			])
			const tmvs = assetReq.data.assets.filter((a) => a.assetType_Id === assetType.TMV.id)
			if (tmvs.length > 0) setAssetId(tmvs[0].asset_Id)
			else setAssetId('')
			setAssets(tmvs)
			setAssetPageStatus('Ready')
		}

		setAssetPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			if (props.embeddedInFleetHealth) {
				setTemperatureExceptionSummaries(props.embeddedInFleetHealth.temperatureExceptionSummaries)
				setAssetPageStatus('Ready')
			} else {
				getData()
			}
		}
	}, [context, props])

	React.useEffect(() => {
		const getData = async () => {
			const [temperatureExceptionsReq] = await Promise.all([
				Request.get<TemperatureExceptionResult>(`report/${ReportApiType.TEMP_EXCEPTIONS.value}${reportQueryUrl}`, context.appState.authState),
			])
			setTemperatureExceptionSummaries(temperatureExceptionsReq.data.temperatureExceptionSummaries)
			setExpanded(
				temperatureExceptionsReq.data.temperatureExceptionSummaries.reduce<typeof expanded>((map, tempEx) => {
					map[tempEx.asset_Id] = false
					return map
				}, {})
			)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			if (tempExceptionFilterType === ReportApiTempExceptionFilterType.TMV && assets.length === 0) {
				setTemperatureExceptionSummaries([])
				setExpanded({})
				setPageStatus('Ready')
			} else {
				getData()
			}
		}
	}, [tempExceptionFilterType, tempExceptionFilterType === ReportApiTempExceptionFilterType.TMV ? assetId : reportDate])

	const expandedContent = temperatureExceptionSummaries.reduce<AfterContent>((map, summary) => {
		map[summary.asset_Id] = expanded[summary.asset_Id] ? (
			<Row style={styles.expandedRow}>
				<Col sm={4} />
				<Col sm={8}>
					<Row style={styles.expandedTableRow}>
						<Col sm={props.onlyThermalFlush ? 4 : 3}>Start Time</Col>
						<Col sm={props.onlyThermalFlush ? 4 : 3}>End Time</Col>
						<Col sm={2}>Duration</Col>
						{!props.onlyThermalFlush && <Col sm={2}>Thermal Flush</Col>}
						<Col sm={2}>View Graph</Col>
					</Row>
					{summary.temperatureExceptions
						.sort((a, b) => ((a.startTs || '') < (b.startTs || '') ? -1 : 1))
						.filter((te) => (props.onlyThermalFlush ? te.isThermalFlush : true))
						.map((te) => (
							<Row
								key={te.event_Id}
								style={styles.expandedTableRow}
								className={
									props.embeddedInFleetHealth && props.embeddedInFleetHealth.isTempExceptionEventInReportFunction(te)
										? 'text-line-through'
										: undefined
								}
								onClick={
									te.startTs && (props.embeddedInFleetHealth ? !props.embeddedInFleetHealth.isTempExceptionEventInReportFunction(te) : true)
										? () => {
												setModalTempException(te)
										  }
										: undefined
								}
							>
								<Col sm={props.onlyThermalFlush ? 4 : 3}>
									{te.startTs
										? formatIncomingDateTime({
												dateTime: te.startTs,
												format: 'DateAndTimeWithSeconds',
												timeZone: te.site_Timezone,
										  })
										: ''}
								</Col>
								<Col sm={props.onlyThermalFlush ? 4 : 3}>
									{te.finishTs
										? formatIncomingDateTime({
												dateTime: te.finishTs,
												format: 'DateAndTimeWithSeconds',
												timeZone: te.site_Timezone,
										  })
										: ''}
								</Col>
								<Col sm={2}>{te.duration ? secondsToReadable(te.duration) : ''}</Col>
								{!props.onlyThermalFlush && <Col sm={2}>{te.isThermalFlush ? 'Yes' : 'No'}</Col>}
								<Col
									sm={2}
									className={
										te.startTs && te.finishTs
											? props.embeddedInFleetHealth && props.embeddedInFleetHealth.isTempExceptionEventInReportFunction(te)
												? 'text-line-through'
												: 'text-link'
											: undefined
									}
									onClick={
										te.startTs &&
										(props.embeddedInFleetHealth ? !props.embeddedInFleetHealth.isTempExceptionEventInReportFunction(te) : true)
											? () => {
													setModalTempException(te)
											  }
											: undefined
									}
								>
									<img src={graph} />
								</Col>
							</Row>
						))}
				</Col>
			</Row>
		) : null
		return map
	}, {})

	const reportName = (summary?: boolean) => {
		return `${
			tempExceptionFilterType === ReportApiTempExceptionFilterType.TMV
				? assets.find((a) => a.asset_Id === assetId)?.asset_Name || 'Asset'
				: reportDate.toFormat(tempExceptionFilterType === ReportApiTempExceptionFilterType.DAY ? 'yyyy-MM-dd' : 'yyyy-MMMM')
		} - ${props.usageContext === 'site' ? context.appState.currentSite?.site_Name : context.appState.currentSiteGroup?.siteGroup_Name} - ${
			props.onlyThermalFlush ? 'Thermal Flush' : 'Temperature Exception'
		}${summary ? ' Summary ' : ' '}Report`
	}

	const filteredListings = temperatureExceptionSummaries.filter((te) => (props.onlyThermalFlush ? te.thermalFlushCount > 0 : true))

	return (
		<>
			<Messages messages={messages} updateMessage={setMessages} />
			{props.renderTitle && <div style={styles.header}>Temperature Exceptions</div>}
			{!props.embeddedInFleetHealth && (
				<Row>
					{props.reportTypeSelect && (
						<Col sm="auto" style={styles.select}>
							{props.reportTypeSelect}
						</Col>
					)}
					<Col sm="auto">
						{ReportApiTempExceptionFilterTypeSelectOptions.map((filterType, index, array) => (
							<Button
								key={index}
								variant={filterType === tempExceptionFilterType ? 'primary' : 'secondary'}
								style={{ ...styles.button, ...(index === 0 ? styles.first : index === array.length - 1 ? styles.last : styles.middle) }}
								onClick={() => {
									if (filterType === ReportApiTempExceptionFilterType.MONTH) {
										setReportDate((current) => current.set({ day: 1 }))
									} else if (filterType === ReportApiTempExceptionFilterType.DAY) {
										setReportDate(DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
									}
									setTempExceptionFilterType(filterType)
								}}
								disabled={filterType === ReportApiTempExceptionFilterType.TMV ? assetPageStatus !== 'Ready' : undefined}
							>
								{filterType === ReportApiTempExceptionFilterType.TMV && assetPageStatus !== 'Ready' ? <Loading show small /> : filterType.label}
							</Button>
						))}
					</Col>
					{tempExceptionFilterType === ReportApiTempExceptionFilterType.TMV ? (
						<Col sm="auto" style={styles.select}>
							<Select
								options={assetOptions}
								onChange={(e) => {
									setAssetId(e?.value || '')
								}}
								isOptionSelected={(e) => e.value === assetId}
								defaultValue={assetOptions[0] || undefined}
							/>
						</Col>
					) : (
						<Col sm="auto">
							<DatePicker
								wrapperClassName="datePicker"
								selected={reportDate.toJSDate()}
								onChange={(date) =>
									setReportDate(
										DateTime.fromJSDate(date || new Date()).set({
											day: tempExceptionFilterType === ReportApiTempExceptionFilterType.MONTH ? 1 : undefined,
											hour: 0,
											minute: 0,
											second: 0,
											millisecond: 0,
										})
									)
								}
								isClearable={false}
								dateFormat={tempExceptionFilterType === ReportApiTempExceptionFilterType.MONTH ? 'MMMM yyyy' : 'dd MMMM yyyy'}
								showMonthYearPicker={tempExceptionFilterType === ReportApiTempExceptionFilterType.MONTH}
								showIcon
							/>
						</Col>
					)}
					<Col />
					{!props.hideDownload && (
						<Col sm="auto">
							<ExportDropdown
								pageStatus={pageStatus}
								setPageStatus={setPageStatus}
								setMessages={setMessages}
								pdfDownloads={{
									options: [
										{
											menuLabel: 'Summary Report PDF',
											pdfUrl: `${
												props.onlyThermalFlush ? ReportApiType.THERMAL_FLUSH.value : ReportApiType.TEMP_EXCEPTIONS.value
											}${reportQueryUrl}&Summary=true${
												tempExceptionFilterType !== ReportApiTempExceptionFilterType.TMV
													? `&DisplayDate=${reportDate.toFormat(
															tempExceptionFilterType === ReportApiTempExceptionFilterType.MONTH ? 'MMMM yyyy' : 'dd MMMM yyyy'
													  )}`
													: ''
											}`,
											pdfFilename: reportName(true),
										},
										{
											menuLabel: 'Report PDF',
											pdfUrl: `${
												props.onlyThermalFlush ? ReportApiType.THERMAL_FLUSH.value : ReportApiType.TEMP_EXCEPTIONS.value
											}${reportQueryUrl}&Summary=false${
												tempExceptionFilterType !== ReportApiTempExceptionFilterType.TMV
													? `&DisplayDate=${reportDate.toFormat(
															tempExceptionFilterType === ReportApiTempExceptionFilterType.MONTH ? 'MMMM yyyy' : 'dd MMMM yyyy'
													  )}`
													: ''
											}`,
											pdfFilename: reportName(),
										},
									],
									downloadAllOption: true,
								}}
								csvDownloads={{
									options: [
										{
											menuLabel: 'Summary Report CSV',
											csvFilename: reportName(true),
											data: filteredListings.map((listing) => ({
												...listing,
												grade: listing.isHighGrade ? 'High' : 'Low',
												comment:
													listing.thermalFlushCount > 0
														? `${listing.thermalFlushCount} thermal flush${listing.thermalFlushCount > 1 ? 'es' : ''}`
														: '',
											})) as unknown as CsvData[],
											headers: getCsvFileHeaders(
												tempExceptionFilterType === ReportApiTempExceptionFilterType.MONTH,
												props.onlyThermalFlush,
												true
											),
										},
										{
											menuLabel: 'Report CSV',
											csvFilename: reportName(),
											data: filteredListings
												.flatMap((listing) => listing.temperatureExceptions)
												.filter((exception) => (props.onlyThermalFlush ? exception.isThermalFlush : true))
												.map((exception) => ({
													...exception,
													startTs: exception.startTs
														? formatIncomingDateTime({
																dateTime: exception.startTs,
																format: 'DateAndTime',
																timeZone: exception.site_Timezone,
														  })
														: '',
													finishTs: exception.finishTs
														? formatIncomingDateTime({
																dateTime: exception.finishTs,
																format: 'DateAndTime',
																timeZone: exception.site_Timezone,
														  })
														: '',
												})),
											headers: getCsvFileHeaders(
												tempExceptionFilterType === ReportApiTempExceptionFilterType.MONTH,
												props.onlyThermalFlush
											),
										},
									],
								}}
							/>
						</Col>
					)}
				</Row>
			)}
			<Row>
				<Col>
					<Listing
						name={props.onlyThermalFlush ? 'Thermal Flush' : 'Temperature Exception'}
						namePlural={props.onlyThermalFlush ? 'Thermal Flushes' : 'Temperature Exceptions'}
						list={filteredListings}
						afterContent={expandedContent}
						getIDFunc={(te) => te.asset_Id}
						selectedActions={[]}
						isLoading={pageStatus !== 'Ready' && pageStatus !== 'Submitting'}
						columns={[
							{
								value: (item) => item.asset_Id,
								render: (item) => (
									<div
										className="center-flex"
										style={styles.expandArrow}
										onClick={() => {
											setExpanded((expanded) => {
												expanded[item.asset_Id] = !expanded[item.asset_Id]
												return { ...expanded }
											})
										}}
									>
										<img src={chevron} style={expanded[item.asset_Id] ? styles.expanded : styles.closed} />
									</div>
								),
								showHeader: false,
							},
							{
								value: (item) => item.asset_Name,
								render: (item) =>
									props.embeddedInFleetHealth ? (
										<>{item.asset_Name}</>
									) : (
										<Link
											to={{
												pathname: `/asset/${item.asset_Id}`,
											}}
										>
											{item.asset_Name}
										</Link>
									),
								showHeader: true,
								headerText: 'TMV ID',
								sortColumnName: 'asset_Name',
								filterType: 'string',
								filterOptions: {
									columnName: 'asset_Name',
								},
							},
							{
								value: (item) => item.asset_Location || '',
								render: (item) => <>{item.asset_Location}</>,
								showHeader: true,
								headerText: 'Location',
								sortColumnName: 'asset_Location',
								filterType: 'string',
								filterOptions: {
									columnName: 'asset_Location',
								},
							},
							{
								value: (item) => item.alertCount.toString().padStart(10, '0') || '0',
								render: (item) => <>{item.alertCount.toString() || 'No Count'}</>,
								showHeader: true,
								headerText: props.onlyThermalFlush ? 'Thermal Flush Count' : 'Exception Count',
								sortColumnName: 'alertCount',
							},
						]}
						defaultSort={{ column: 'alertCount', order: 'DSC' }}
					/>
				</Col>
			</Row>

			<TemperatureExceptionDetailModal
				temperatureException={modalTempException}
				showReadingDetail={!props.embeddedInFleetHealth}
				setTemperatureException={setModalTempException}
				addToFleetHealthReportFunction={props.embeddedInFleetHealth ? props.embeddedInFleetHealth.addToFleetHealthReportFunction : undefined}
			/>
		</>
	)
}

const styles = {
	header: {
		fontSize: '26px',
		lineHeight: '30px',
		fontWeight: 700,
		margin: '30px 0px 20px',
	},
	select: { minWidth: '300px' },
	button: { minHeight: '38px' },
	first: {
		borderRadius: '4px 0 0 4px',
		marginRight: '-1px',
	},
	middle: {
		borderRadius: '0',
		marginRight: '-1px',
	},
	last: {
		borderRadius: '0 4px 4px 0',
	},
	closed: {
		transition: '0.1s all linear',
		transform: 'rotate(-90deg)',
	},
	expanded: {
		transition: '0.1s all linear',
	},
	expandArrow: { margin: '0 -10px 0 -10px' },
	expandedRow: { padding: '8px' },
	expandedTableRow: {
		borderBottom: '1px grey solid',
		marginBottom: '2px',
		paddingBottom: '2px',
	},
} satisfies Record<string, React.CSSProperties>

export default TemperatureExceptionReportListing
