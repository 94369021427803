import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'

interface TaskComment {
	taskComment_Id: string
	taskComment_Note: string
	taskComment_Ts: string
	task_Id: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface TaskCommentResult extends Validate.Result {
	taskComments: TaskComment[]
}

const defaultTaskComment = (taskComment: Partial<TaskComment>): TaskComment => ({
	taskComment_Id: taskComment.taskComment_Id || '',
	taskComment_Note: taskComment.taskComment_Note || '',
	taskComment_Ts: taskComment.taskComment_Ts || '',
	task_Id: taskComment.task_Id || '',

	created: taskComment.created || defaultCreated({}),
	modified: taskComment.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultTaskComment }
export type { TaskComment, TaskCommentResult }
