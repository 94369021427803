export const DocumentTypeNameValues = [
	'Uploaded',
	'Monthly Reading Report',
	'Monthly Reading Preparation Report',
	'Temperature Exceptions Report',
	'Temperature Exception History Report',
	'Flow Report',
	'Consecutive Flow Report',
	'Thermal Flush Report',
] as const
export type DocumentTypeName = (typeof DocumentTypeNameValues)[number]

export const DocumentTypeIdValues = [
	'uploaded-0000-0000-0000-000000000000',
	'report00-mnth-read-0000-000000000000',
	'report00-mnth-read-prep-000000000000',
	'report00-temp-excp-0000-000000000000',
	'report00-temp-excp-hist-000000000000',
	'report00-flow-0000-0000-000000000000',
	'report00-cnsc-flow-0000-000000000000',
	'report00-thrm-flsh-0000-000000000000',
] as const
export type DocumentTypeId = (typeof DocumentTypeIdValues)[number]

export type DocumentType = { id: DocumentTypeId }

export const documentType: Record<DocumentTypeName, DocumentType> = {
	Uploaded: { id: 'uploaded-0000-0000-0000-000000000000' },
	'Monthly Reading Report': { id: 'report00-mnth-read-0000-000000000000' },
	'Monthly Reading Preparation Report': { id: 'report00-mnth-read-prep-000000000000' },
	'Temperature Exceptions Report': { id: 'report00-temp-excp-0000-000000000000' },
	'Temperature Exception History Report': { id: 'report00-temp-excp-hist-000000000000' },
	'Flow Report': { id: 'report00-flow-0000-0000-000000000000' },
	'Consecutive Flow Report': { id: 'report00-cnsc-flow-0000-000000000000' },
	'Thermal Flush Report': { id: 'report00-thrm-flsh-0000-000000000000' },
}

// This controls what report types are fetch/displayed on the archive page, edit this as necessary for reports that should be shown
export const ReportArchiveDocumentTypes = [documentType['Monthly Reading Report'], documentType['Monthly Reading Preparation Report']]

export const documentTypeDropdownValues = DocumentTypeNameValues.map((value) => ({
	value: documentType[value].id,
	label: value,
}))

export const getDocumentTypeNameFromId = (assetType_Id: DocumentTypeId) => {
	const documentType_Name = DocumentTypeNameValues.find((documentType_Name) => documentType[documentType_Name].id === assetType_Id)
	if (!documentType_Name) throw new Error(`Document name not found for assetType_Id: ${assetType_Id}`)
	return documentType_Name
}
