import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { StatsCard, StatsCardStat } from '../StatsCard/StatsCard'
import { AppContext } from '../../../App'
import { PageStatus } from '../../../types/PageStatus'
import * as Request from '../../../utilities/request'
import { TaskOrEventCount, TaskOrEventCountResult, OnlineCount, OnlineCountResult } from '../../../models/Stats'
import { severity } from '../../../constants/severity'

interface StatProps {
	context?: 'site' | 'siteGroup'
}

const SiteDashboardStats = (props: StatProps) => {
	return (
		<Row>
			<Col>
				<SiteStat {...props} />
			</Col>
			<Col>
				<EventStat {...props} />
			</Col>
			<Col>
				<TaskStat {...props} />
			</Col>
		</Row>
	)
}

// Only push into the stats if we have some number to show
const generateEventOrTaskStat = (taskOrEvent: TaskOrEventCount | null) => {
	const statsCardStats: StatsCardStat[] = []

	if (taskOrEvent) {
		// Critical Stat
		if (taskOrEvent.critical > 0) {
			statsCardStats.push({
				severityId: severity.Critical.id,
				text: `${taskOrEvent ? taskOrEvent.critical : ''}x Critical`,
			})
		}
		// High Stat
		if (taskOrEvent.high > 0) {
			statsCardStats.push({
				severityId: severity.High.id,
				text: `${taskOrEvent ? taskOrEvent.high : ''}x High`,
			})
		}
		// Medium Stat
		if (taskOrEvent.medium > 0) {
			statsCardStats.push({
				severityId: severity.Medium.id,
				text: `${taskOrEvent ? taskOrEvent.medium : ''}x Medium`,
			})
		}
		// Low Stat
		if (taskOrEvent.low > 0) {
			statsCardStats.push({
				severityId: severity.Low.id,
				text: `${taskOrEvent ? taskOrEvent.info : ''}x Low`,
			})
		}
		// Info Stat
		if (taskOrEvent.info > 0) {
			statsCardStats.push({
				severityId: severity.Info.id,
				text: `${taskOrEvent ? taskOrEvent.info : ''}x Info`,
			})
		}
	}

	return statsCardStats
}

const TaskStat = (props: StatProps) => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [taskStat, setTaskStat] = React.useState<TaskOrEventCount | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [taskStatReq] = await Promise.all([
				Request.get<TaskOrEventCountResult>(
					`stats/taskCount${
						props.context
							? props.context === 'siteGroup'
								? `?siteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
								: `?site_Id=${context.appState.currentSite?.site_Id}`
							: ''
					}`,
					context.appState.authState
				),
			])
			setTaskStat(taskStatReq.data)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	return (
		<StatsCard
			icon={'bell'}
			title={`${taskStat ? taskStat.total : ''}`}
			subtitle="Tasks"
			pageStatus={pageStatus}
			stats={generateEventOrTaskStat(taskStat)}
		/>
	)
}

const EventStat = (props: StatProps) => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [eventStat, setEventStat] = React.useState<TaskOrEventCount | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [eventStatReq] = await Promise.all([
				Request.get<TaskOrEventCountResult>(
					`stats/eventCount${
						props.context
							? props.context === 'siteGroup'
								? `?siteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
								: `?site_Id=${context.appState.currentSite?.site_Id}`
							: ''
					}`,
					context.appState.authState
				),
			])
			setEventStat(eventStatReq.data)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	return (
		<StatsCard
			icon={'event'}
			title={`${eventStat ? eventStat.total : ''}`}
			subtitle="Events Last 7 Days"
			pageStatus={pageStatus}
			stats={generateEventOrTaskStat(eventStat)}
		/>
	)
}

const SiteStat = (props: StatProps) => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [siteStat, setSiteStat] = React.useState<OnlineCount | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [sitesStatReq] = await Promise.all([
				Request.get<OnlineCountResult>(
					`stats/siteCount${
						props.context
							? props.context === 'siteGroup'
								? `?siteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
								: `?site_Id=${context.appState.currentSite?.site_Id}`
							: ''
					}`,
					context.appState.authState
				),
			])
			setSiteStat(sitesStatReq.data)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	return (
		<StatsCard
			icon={'site'}
			title={`${siteStat ? siteStat.total : ''}`}
			subtitle="Sites"
			pageStatus={pageStatus}
			stats={[
				{
					severityId: severity.Critical.id,
					text: `${siteStat ? siteStat.online : ''}x Sites online`,
				},
				{
					severityId: severity.Critical.id,
					text: `${siteStat ? siteStat.offline : ''}x Sites offline`,
				},
			]}
		/>
	)
}

export default SiteDashboardStats
