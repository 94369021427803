import React from 'react'
import { TemperatureException } from '../../../models/Report'
import { Button, Col, Row, Table } from 'react-bootstrap'
import { AppContext } from '../../../App'
import { Reading, ReadingResult } from '../../../models/Reading'
import * as Request from '../../../utilities/request'
import { PageStatus } from '../../../types/PageStatus'
import { Loading } from '../Loading/Loading'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import 'chartjs-adapter-luxon'
import { Line } from 'react-chartjs-2'
import { colors } from '../../../constants/colors'
import { calculateSuggestedMax, calculateSuggestedMin } from '../../../utilities/graphHelpers'
import { Messages, useMessageReducer } from '../Messages/Messages'
import ExportDropdown from './ExportDropdown'
import { ReportApiType } from '../../../constants/report'
import { secondsToReadable } from '../../../utilities/strings'
import chevron from '../../../images/icons/chevron.svg'
import { ReadingLiveResult } from '../../../models/ReadingLive'
import { readingType } from '../../../constants/readingType'

interface TemperatureExceptionEventDetailProps {
	temperatureException: TemperatureException
	commentBox?: React.ReactNode
	disableAnimation?: boolean
	pointLabels?: boolean
	showReadingDetail?: boolean
}

interface PointData {
	x: number
	y: number
}

// The readings calls fetch reading 3 minutes before and after the start/finish timestamp, this constant
// adds additional padding to the x-axis min/max to allow those readings to be displayed
const threeMinsThirtySeconds = 210000

const TemperatureExceptionEventDetail = (props: TemperatureExceptionEventDetailProps) => {
	const context = React.useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [eventReadings, setEventReadings] = React.useState<Reading[]>([])
	const [readingsCollapsed, setReadingsCollapsed] = React.useState(true)
	const [messages, setMessages] = useMessageReducer([])

	React.useEffect(() => {
		const getData = async () => {
			let finishTs = ''
			if (props.temperatureException.finishTs) {
				finishTs = props.temperatureException.finishTs
			} else {
				const readingLiveReq = await Request.get<ReadingLiveResult>(
					`readingLive?Asset_Id=${props.temperatureException.asset_Id}`,
					context.appState.authState
				)

				const mixedReadings = readingLiveReq.data.readingLives.filter((rl) => rl.readingType_Id === readingType['Temperature Reading Mixed'].id)

				if (mixedReadings.length === 1) {
					finishTs = mixedReadings[0].reading_Ts
				}
			}

			if (finishTs) {
				const readingReq = await Request.get<ReadingResult>(
					`reading/temperatureException?Asset_Id=${props.temperatureException.asset_Id}&ExceptionStartTs=${props.temperatureException.startTs}&ExceptionFinishTs=${finishTs}`,
					context.appState.authState
				)

				setEventReadings(readingReq.data.readings)
				setPageStatus('Ready')
			} else {
				setPageStatus('Error')
			}
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props.temperatureException])

	const tempExceptionStartEpochMillis = props.temperatureException.startTs
		? parseInt(
				formatIncomingDateTime({
					dateTime: props.temperatureException.startTs,
					format: 'Custom',
					timeZone: props.temperatureException.site_Timezone,
					customFormat: 'x',
				})
		  )
		: 0

	const tempExceptionFinishEpochMillis = props.temperatureException.finishTs
		? parseInt(
				formatIncomingDateTime({
					dateTime: props.temperatureException.finishTs,
					format: 'Custom',
					timeZone: props.temperatureException.site_Timezone,
					customFormat: 'x',
				})
		  )
		: eventReadings.at(-1)
		? parseInt(
				formatIncomingDateTime({
					dateTime: eventReadings.at(-1)?.reading_Ts || '',
					format: 'Custom',
					timeZone: props.temperatureException.site_Timezone,
					customFormat: 'x',
				})
		  )
		: tempExceptionStartEpochMillis

	const readings = React.useMemo(
		() =>
			eventReadings
				.sort((a, b) => (a.reading_Ts < b.reading_Ts ? -1 : 1))
				.map((r) => ({
					x: parseInt(
						formatIncomingDateTime({
							dateTime: r.reading_Ts,
							format: 'Custom',
							timeZone: props.temperatureException.site_Timezone,
							customFormat: 'x',
						})
					),
					y: r.reading_Data, // TODO: Convert to fahrenheit based on site setting
				})),
		[eventReadings]
	)

	const tempExceptionThreshold = React.useMemo(
		() => [
			{
				x: tempExceptionStartEpochMillis - threeMinsThirtySeconds,
				y: props.temperatureException.temperatureExceptionThreshold, // TODO: Convert to fahrenheit based on site setting
			},
			{
				x: tempExceptionFinishEpochMillis + threeMinsThirtySeconds,
				y: props.temperatureException.temperatureExceptionThreshold, // TODO: Convert to fahrenheit based on site setting
			},
		],
		[tempExceptionStartEpochMillis, tempExceptionFinishEpochMillis]
	)

	const setTemperatureThreshold = React.useMemo(
		() => [
			{
				x: tempExceptionStartEpochMillis - threeMinsThirtySeconds,
				y: props.temperatureException.setTemperature, // TODO: Convert to fahrenheit based on site setting
			},
			{
				x: tempExceptionFinishEpochMillis + threeMinsThirtySeconds,
				y: props.temperatureException.setTemperature, // TODO: Convert to fahrenheit based on site setting
			},
		],
		[tempExceptionStartEpochMillis, tempExceptionFinishEpochMillis]
	)

	const setTemperatureThresholdUpper = React.useMemo(
		() => [
			{
				x: tempExceptionStartEpochMillis - threeMinsThirtySeconds,
				y: props.temperatureException.setTemperature + 2, // TODO: Convert to fahrenheit based on site setting
			},
			{
				x: tempExceptionFinishEpochMillis + threeMinsThirtySeconds,
				y: props.temperatureException.setTemperature + 2, // TODO: Convert to fahrenheit based on site setting
			},
		],
		[tempExceptionStartEpochMillis, tempExceptionFinishEpochMillis]
	)

	const setTemperatureThresholdLower = React.useMemo(
		() => [
			{
				x: tempExceptionStartEpochMillis - threeMinsThirtySeconds,
				y: props.temperatureException.setTemperature - 2, // TODO: Convert to fahrenheit based on site setting
			},
			{
				x: tempExceptionFinishEpochMillis + threeMinsThirtySeconds,
				y: props.temperatureException.setTemperature - 2, // TODO: Convert to fahrenheit based on site setting
			},
		],
		[tempExceptionStartEpochMillis, tempExceptionFinishEpochMillis]
	)

	const reportQueryUrl = `${ReportApiType.TEMP_EXCEPTION_DETAIL.value}?Asset_ID=${props.temperatureException.asset_Id}&ExceptionStart_Ts=${props.temperatureException.startTs}&ExceptionFinish_Ts=${props.temperatureException.finishTs}`

	const getFilename = (graph?: boolean) =>
		`Temperature Exception Detail ${graph ? 'Graph ' : ''} Report for ${props.temperatureException.asset_Name}: - ${formatIncomingDateTime({
			dateTime: props.temperatureException.startTs || '',
			format: 'DateAndTimeWithSeconds',
			timeZone: props.temperatureException.site_Timezone,
		})} - ${formatIncomingDateTime({
			dateTime: props.temperatureException.finishTs || '',
			format: 'DateAndTimeWithSeconds',
			timeZone: props.temperatureException.site_Timezone,
		})}`

	const colorStatusGreenOpacity = 'rgba(54, 179, 127, 0.2)' // colors.colorStatusGreen with opacity

	return pageStatus === 'Ready' || pageStatus === 'Submitting' ? (
		<>
			<Messages messages={messages} updateMessage={setMessages} />

			<Row>
				<Col className="d-flex flex-column">
					<span className="span-bold">TMV ID</span>
					<span>{props.temperatureException.asset_Name}</span>
				</Col>

				<Col className="d-flex flex-column">
					<span className="span-bold">TMV Location</span>
					<span>{props.temperatureException.asset_Location}</span>
				</Col>
			</Row>
			<Row className="mt-2">
				<Col className="d-flex flex-column text-nowrap">
					<span className="span-bold">Exception Details</span>
					<span>
						{`Started at ${
							props.temperatureException.startTs
								? formatIncomingDateTime({
										dateTime: props.temperatureException.startTs,
										format: 'DateAndTimeWithSeconds',
										timeZone: props.temperatureException.site_Timezone,
								  })
								: 'nil'
						}`}
					</span>
					<span>
						{props.temperatureException.finishTs
							? `and ended at ${formatIncomingDateTime({
									dateTime: props.temperatureException.finishTs,
									format: 'DateAndTimeWithSeconds',
									timeZone: props.temperatureException.site_Timezone,
							  })}`
							: `and is still ongoing`}
					</span>
				</Col>
				<Col className="d-flex flex-column">
					<span className="span-bold">Duration</span>
					<span>
						{props.temperatureException.duration ? secondsToReadable(props.temperatureException.duration) : 'Missing Start or Finish timestamp'}
					</span>
				</Col>
			</Row>
			{props.commentBox && (
				<Row className="mt-2">
					<Col sm={6} className="d-flex flex-column">
						<span className="span-bold">Comments</span>
						{props.commentBox}
					</Col>
				</Row>
			)}
			{props.showReadingDetail && (
				<Row className="mt-2">
					<Col className="d-flex justify-content-end">
						<ExportDropdown
							buttonText="Export Graph"
							pageStatus={pageStatus}
							setPageStatus={setPageStatus}
							setMessages={setMessages}
							pdfDownloads={{
								options: [
									{
										menuLabel: 'PDF',
										pdfUrl: `${reportQueryUrl}&View=graph`,
										pdfFilename: getFilename(true),
									},
								],
							}}
						/>
					</Col>
				</Row>
			)}
			<Row className="mt-2">
				<Col style={styles.graphCol}>
					<Line
						data={{
							datasets: [
								{
									label: 'Mixed',
									borderColor: colors.colorStatusYellow,
									backgroundColor: colors.colorStatusYellow,
									data: readings,
									indexAxis: 'x',
									parsing: false,
								},
								{
									label: 'Temperature Exception Threshold',
									borderColor: colors.colorStatusRed,
									backgroundColor: colors.colorStatusRed,
									data: tempExceptionThreshold,
									indexAxis: 'x',
									parsing: false,
									pointStyle: 'line',
								},
								{
									label: 'Set Temperature Upper Bound',
									borderColor: colorStatusGreenOpacity,
									backgroundColor: colorStatusGreenOpacity,
									data: setTemperatureThresholdUpper,
									indexAxis: 'x',
									parsing: false,
									pointStyle: 'line',
									fill: { value: props.temperatureException.setTemperature - 2 },
								},
								{
									label: 'Set Temperature',
									borderColor: colors.colorStatusGreen,
									backgroundColor: colors.colorStatusGreen,
									data: setTemperatureThreshold,
									indexAxis: 'x',
									parsing: false,
									pointStyle: 'line',
								},
								{
									label: 'Set Temperature Lower Bound',
									borderColor: colorStatusGreenOpacity,
									backgroundColor: colorStatusGreenOpacity,
									data: setTemperatureThresholdLower,
									indexAxis: 'x',
									parsing: false,
									pointStyle: 'line',
									fill: { value: props.temperatureException.setTemperature + 2 },
								},
							],
						}}
						options={{
							parsing: false,
							normalized: true,
							interaction: {
								mode: 'nearest',
								axis: 'x',
								intersect: false,
							},
							maintainAspectRatio: false,
							scales: {
								x: {
									type: 'time',
									time: {
										tooltipFormat: 'dd/MM/yyyy h:mm:ss.SSS a',
										displayFormats: {
											millisecond: 'h:mm:ss:SSS a',
											second: 'h:mm:ss a',
											minute: 'h:mm a',
											hour: 'dd/MM h a',
											day: 'dd/MM/yy',
											week: 'dd/MM/yy',
											month: 'MM/yyyy',
											quarter: 'MM/yyyy',
											year: 'yyyy',
										},
									},
									adapters: {
										date: {
											zone: props.temperatureException.site_Timezone,
										},
									},
									title: {
										display: true,
										text: 'Timestamps',
									},
									min: tempExceptionStartEpochMillis - threeMinsThirtySeconds,
									max: tempExceptionFinishEpochMillis + threeMinsThirtySeconds,
								},
								y: {
									title: { display: true, text: 'Mixed Temperature (°C)' }, // TODO: Show fahrenheit based on site setting
									suggestedMin: calculateSuggestedMin(readings),
									suggestedMax: calculateSuggestedMax(readings),
									grid: {
										color: (ctx) => {
											if (ctx.tick.value === 0) {
												return 'rgba(0, 0, 0, 0.5)'
											}

											return 'rgba(0, 0, 0, 0.1)'
										},
									},
								},
							},
							plugins: {
								legend: {
									display: false,
								},
								datalabels: {
									display: !!props.pointLabels,
									formatter: (value) => `${value.y}`,
									anchor: 'center',
									align: (ctx) => {
										const value = (ctx.dataset.data[ctx.dataIndex] as PointData).y
										const prev = ctx.dataIndex === 0 ? value : (ctx.dataset.data[ctx.dataIndex - 1] as PointData).y
										const next =
											ctx.dataIndex === ctx.dataset.data.length - 1 ? value : (ctx.dataset.data[ctx.dataIndex + 1] as PointData).y

										// Work out which side of the point has a larger change, then set the label above/below based on +/- change
										// start -> below point, end -> above point
										if (Math.abs(value - prev) > Math.abs(value - next)) {
											if (value - prev > 0) return 'end'
											else return 'start'
										} else if (Math.abs(value - prev) < Math.abs(value - next)) {
											if (value - next > 0) return 'end'
											else return 'start'
										} else {
											return 'end'
										}
									},
									color: 'black',
									font: {
										size: 12,
									},
								},
							},
							animation: props.disableAnimation ? false : undefined,
						}}
					/>
				</Col>
			</Row>
			{props.showReadingDetail && (
				<>
					<Row className="mt-2">
						<Col className="d-flex justify-content-end">
							<ExportDropdown
								buttonText="Export Readings"
								pageStatus={pageStatus}
								setPageStatus={setPageStatus}
								setMessages={setMessages}
								pdfDownloads={{
									options: [
										{
											menuLabel: 'PDF',
											pdfUrl: `${reportQueryUrl}&View=table`,
											pdfFilename: getFilename(),
										},
									],
								}}
								csvDownloads={{
									options: [
										{
											menuLabel: 'CSV',
											csvFilename: getFilename(),
											data: eventReadings.map((reading) => ({
												...reading,
												reading_Ts: formatIncomingDateTime({
													dateTime: reading.reading_Ts,
													format: 'DateAndTimeWithSeconds',
													timeZone: props.temperatureException.site_Timezone,
												}),
											})),
											headers: [
												{ key: 'reading_Ts', columnName: 'Timestamp' },
												{ key: 'reading_Data', columnName: 'Temperature' },
											],
										},
									],
								}}
							/>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							<Table>
								<thead>
									<tr>
										<th>Timestamp</th>
										<th>Temperature</th>
									</tr>
								</thead>
								<tbody>
									{eventReadings.slice(0, readingsCollapsed ? 3 : undefined).map((reading) => (
										<tr key={reading.reading_Id}>
											<td>
												{formatIncomingDateTime({
													dateTime: reading.reading_Ts,
													format: 'DateAndTimeWithSeconds',
													timeZone: props.temperatureException.site_Timezone,
												})}
											</td>
											<td>{reading.reading_Data}</td>
										</tr>
									))}
									<tr>
										<td colSpan={2}>
											<Row>
												<Col />
												<Col sm="auto">
													<Button onClick={() => setReadingsCollapsed(!readingsCollapsed)} variant="secondary">
														<img src={chevron} style={readingsCollapsed ? undefined : styles.flippedImg} className="me-3" />
														<span>{readingsCollapsed ? 'Show' : 'Hide'} Readings</span>
													</Button>
												</Col>
												<Col />
											</Row>
										</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</>
			)}
		</>
	) : (
		<Col className="center-flex">
			<Loading show />
		</Col>
	)
}

const styles = {
	graphCol: { minHeight: '300px' },
	flippedImg: { transform: 'rotate(180deg)' },
} satisfies Record<string, React.CSSProperties>

export default TemperatureExceptionEventDetail
