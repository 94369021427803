import React from 'react'
import './EditAsset.css'
import { Row, Col, Card } from 'react-bootstrap'

import { FileUpload } from '../../FileUpload/FileUpload'

import { CommonComponentProps } from '../../../../screens/Asset/EditAssetDetails'

const AssetFiles = (props: CommonComponentProps) => {
	return (
		<Card className="site-card" style={styles.card}>
			<Row className="site-card-list-heading-row">
				<span className="dashboard-card-titles">Files</span>
			</Row>
			<Row style={styles.row}>
				<Col>
					<FileUpload asset_Id={props.asset.asset_Id} readOnly={false} hideTitle listView styles={uploadStyles} />
				</Col>
			</Row>
		</Card>
	)
}

const styles = {
	card: {
		height: '300px',
	},
	row: {
		padding: '20px',
		justifyContent: 'space-between',
	},
	disabledPlusButton: {
		cursor: 'not-allowed',
	},
}

const uploadStyles: { [key: string]: React.CSSProperties } = {
	outerContainerStyle: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	uploadedContainerStyle: {
		boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.06)',
		borderRadius: '8px',
		backgroundColor: 'white',
		border: '1px solid rgba(0, 0, 0, 0.5)',
		height: '200px',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '10px 20px 20px 0px',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		flexBasis: '48%',
	},
	uploaderContainerStyle: {
		flexBasis: '48%',
		marginTop: '0px',
		height: '200px',
	},
}

export { AssetFiles }
