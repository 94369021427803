import React from 'react'
import { Spinner, SpinnerProps } from 'react-bootstrap'

interface LoadingProps extends SpinnerProps {
	show?: boolean
	small?: boolean
	style?: React.CSSProperties
}

export const Loading = (props: LoadingProps) => {
	const { show, small, ...spinnerProps } = props
	if (show) {
		return <Spinner animation="border" role="status" size={small ? 'sm' : undefined} style={styles.spinner} {...spinnerProps}></Spinner>
	} else {
		return <div></div>
	}
}

const styles = {
	spinner: {
		margin: 'auto',
	},
}
