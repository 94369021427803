import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Formik } from 'formik'
import { Col, Modal, Row } from 'react-bootstrap'
import * as Request from '../../../utilities/request'
import { Button } from '../Button/Button'
import { FormText } from '../Form/Text'
import { ContractModule, ContractModuleResult, defaultContractModule } from '../../../models/ContractModule'
import { FormNumber } from '../Form/Number'
import { FormSelect, SelectOption } from '../Form/Select'
import { Contract } from '../../../models/Contract'

interface ContractModuleModalProps {
	contractModule: ContractModule | null
	setContractModule: React.Dispatch<React.SetStateAction<ContractModule | null>>
	modalModulesFiltered: { value: string; label: string }[]
	editMode: boolean
	contract: Contract
	setContract: (values: React.SetStateAction<Contract>, shouldValidate?: boolean | undefined) => void
}

const ContractModuleModal = (props: ContractModuleModalProps) => {
	const context = useContext(AppContext)

	const handleSubmit = (values: ContractModule) => {
		if (props.editMode) {
			return Request.handleRequest(() => Request.put<ContractModuleResult>(`contractModule`, values, context.appState.authState), {
				successFunction: (data) => {
					const updatedContractModules = props.contract.contract_Modules
					const moduleIndex = updatedContractModules.findIndex((module) => module.module_Id === values.module_Id)
					updatedContractModules[moduleIndex] = data.contractModules[0];
					props.setContract({
						...props.contract,
						contract_Modules: updatedContractModules,
					})

					props.setContractModule(null)
				},
				messageAction: 'editing',
				messageObject: 'site',
			})
		}
		return Request.handleRequest(() => Request.post<ContractModuleResult>(`contractModule`, values, context.appState.authState), {
			successFunction: (data) => {
				props.setContract({
					...props.contract,
					contract_Modules: [...props.contract.contract_Modules, data.contractModules[0]],
				})

				props.setContractModule(null)
			},
			messageAction: 'creating',
			messageObject: 'site',
		})
	}

	return (
		<Formik initialValues={props.contractModule ? props.contractModule : defaultContractModule({})} onSubmit={handleSubmit} enableReinitialize>
			{({ handleSubmit, isSubmitting, errors, values, handleReset, setFieldValue }) => (
				<Modal
					show={!!props.contractModule}
					onHide={() => {
						if (!isSubmitting) {
							handleReset()
							props.setContractModule(null)
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>{props.editMode ? 'Edit' : 'Add New'} Payment Agreement</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Row>
							<Col>
								{props.editMode ? (
									<FormText name={`module_Name`} label="Module Name" value={values.module_Name || ''} disabled={true} />
								) : (
									<FormSelect
										name="module_Name"
										label={'Module Name'}
										options={props.modalModulesFiltered}
										value={values.module_Id}
										onChange={(e) => {
											setFieldValue(`module_Id`, (e as SelectOption<string>).value)
											setFieldValue(`module_Name`, (e as SelectOption<string>).label)
										}}
										required
									/>
								)}
							</Col>
							<Col>
								<FormNumber
									name={`contractModule_AssetRate`}
									label={'Price Per Asset'}
									value={values.contractModule_AssetRate || 0}
									onChange={(value) => setFieldValue(`contractModule_AssetRate`, value)}
									min={0}
									feedback={errors?.contractModule_AssetRate}
									isInvalid={!!errors?.contractModule_AssetRate}
								/>
							</Col>
						</Row>
					</Modal.Body>

					<Modal.Footer>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleReset()
								props.setContractModule(null)
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleSubmit()
							}}
						>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
	)
}

export { ContractModuleModal }
