import React from 'react'
import { Col, Collapse, Row } from 'react-bootstrap'
import { CollapseChevron } from '../collapseChevron/collapseChevron'

interface CardHeaderColsProps {
	colProps: object
	show?: boolean
	children: React.ReactElement
}

interface CardProps {
	title: string
	collapsible: boolean
	initialCollapsedState?: boolean
	headerComponent?: (cardProps: unknown) => React.ReactElement
	headerCols?: CardHeaderColsProps[]
	headerColsRight?: boolean
	children: React.ReactElement[] | React.ReactElement
	centreTitle?: boolean
}

const CardHeaderCols = (props: CardHeaderColsProps) => {
	if (props.show === undefined || props.show === true) {
		return <Col {...props.colProps}>{props.children}</Col>
	} else {
		return null
	}
}

export const Card = (props: CardProps) => {
	const [isCollapsed, updateCollapsed] = React.useState<boolean>(
		props.collapsible ? (props.initialCollapsedState === undefined ? false : props.initialCollapsedState) : false
	)

	let headerRow = null
	if (props.headerComponent) {
		headerRow = props.headerComponent({ isCollapsed, updateCollapsed })
	} else if (props.headerCols) {
		headerRow = props.headerCols.map((props: CardHeaderColsProps, index: number) => <CardHeaderCols key={index} {...props} />)
	} else {
		headerRow = <Col></Col>
	}

	return (
		<div className="card">
			<Row className="card-title">
				{props.centreTitle ? <Col></Col> : null}
				{props.title ? (
					<Col sm={props.headerColsRight ? undefined : 'auto'}>
						<h1>{props.title}</h1>
					</Col>
				) : null}
				{headerRow}

				<CardHeaderCols show={props.collapsible} colProps={{ sm: 'auto' }}>
					<CollapseChevron collapsed={isCollapsed} updateCollapsed={updateCollapsed} />
				</CardHeaderCols>
			</Row>
			<Collapse in={!isCollapsed}>
				<div>
					<hr />
					<div className="card-content">
						<Row>
							<Col>{props.children}</Col>
						</Row>
					</div>
				</div>
			</Collapse>
		</div>
	)
}
