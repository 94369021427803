import React, { useContext } from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { Button } from '../Button/Button'
import { AppContext } from '../../../App'
import plus from '../../../images/icons/add-white.svg'
import filters from '../../../images/icons/filter.svg'
import { colors } from '../../../constants/colors'
import { Card } from '../../../screens/Contracts/ContractDashboard'
import { useNavigate } from 'react-router-dom'

interface ContractDashboardHeaderProps {
	setSortedFilteredCards: React.Dispatch<React.SetStateAction<Card[]>>
	cards: Card[]
	sortedFilteredCards: Card[]
}

const ContractDashboardHeader = (props: ContractDashboardHeaderProps) => {
	const context = useContext(AppContext)
	const navigate = useNavigate()

	const goodMorningAndInCaseIDontSeeYaGoodAfternoonGoodEveningAndGoodNight = () => {
		const date = new Date()
		const hours = date.getHours()

		if (hours < 12) {
			return 'Good morning'
		} else if (hours >= 12 && hours < 18) {
			return 'Good afternoon'
		} else {
			return 'Good evening'
		}
	}

	const siteCardSort = (a: Card, b: Card, value: keyof Card) => {
		const order = 'ASC'

		const valueA = a[value]
		const valueB = b[value]

		if (Number.isInteger(valueA)) {
			return Number(valueB) - Number(valueA)
		}

		if (valueA === '' || valueA === null || valueA === 0) return 1
		if (valueB === '' || valueB === null || valueB === 0) return -1

		return valueA && valueB ? (order === 'ASC' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA))) : 1
	}

	return (
		<Row style={styles.ContractDashboardHeader}>
			<Col>
				<Row>
					<Col>
						<span style={styles.headerTextSmall}>Contract Management Dashboard</span>
					</Col>
				</Row>
				<Row style={styles.headerText}>
					<Col>
						<span style={styles.headerTextLarge} id="user-welcome">
							{goodMorningAndInCaseIDontSeeYaGoodAfternoonGoodEveningAndGoodNight()}, {context.appState.userAttributes.user_FirstName}
						</span>
					</Col>
				</Row>
				<Row style={styles.headerButtons}>
					<Col sm={'auto'}>
						<Button
							onClick={() => {
								navigate('/provisionContract')
							}}
						>
							<img style={styles.buttonIcon} src={plus} alt={'plus icon'} />
							Create New Contract
						</Button>
					</Col>
					{/* <PermissionsCheck object={PermissionModelObject.SiteGroup} action={PermissionModelAction.POST} context={PermissionModelContext.None}>
					</PermissionsCheck> */}
					<Col />
					<Col sm={'auto'}>
						<Dropdown style={styles.rightAlign}>
							<Dropdown.Toggle variant="secondary">Sort By</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => props.setSortedFilteredCards(props.cards)}>None</Dropdown.Item>
								<Dropdown.Item
									onClick={() =>
										props.setSortedFilteredCards([...props.sortedFilteredCards].sort((a: Card, b: Card) => siteCardSort(a, b, 'name')))
									}
								>
									Alphabetical
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() =>
										props.setSortedFilteredCards([...props.sortedFilteredCards].sort((a: Card, b: Card) => siteCardSort(a, b, 'created')))
									}
								>
									Date Created
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() =>
										props.setSortedFilteredCards([...props.sortedFilteredCards].sort((a: Card, b: Card) => siteCardSort(a, b, 'count')))
									}
								>
									Number of Agreements
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
					<Col sm={'auto'}>
						<Dropdown style={styles.rightAlign}>
							<Dropdown.Toggle variant="secondary">
								<img style={styles.buttonIcon} src={filters} alt={'plus icon'} /> Filters
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => props.setSortedFilteredCards(props.cards)}>None</Dropdown.Item>
								<Dropdown.Item onClick={() => props.setSortedFilteredCards([...props.cards].filter((card) => card.count > 0))}>
									With Agreements
								</Dropdown.Item>
								<Dropdown.Item onClick={() => props.setSortedFilteredCards([...props.cards].filter((card) => card.count === 0))}>
									Without Agreements
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

const styles = {
	ContractDashboardHeader: {
		backgroundColor: colors.colorPrimaryCyan,
		height: '257px',
		paddingTop: '25px',
		paddingLeft: '50px',
		paddingRight: '50px',
	},
	headerText: {
		paddingTop: '10px',
	},
	headerButtons: {
		paddingTop: '30px',
	},
	headerTextSmall: {
		fontSize: '12px',
	},
	headerTextLarge: {
		fontSize: '26px',
	},
	rightAlign: {
		float: 'right' as const,
	},
	buttonIcon: {
		marginRight: '10px',
	},
}

export default ContractDashboardHeader
