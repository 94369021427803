import React from 'react'
import ReportArchive from '../../components/UI/Reports/ReportArchive'
import { Col, Row } from 'react-bootstrap'
import { Back } from '../../components/UI/Back/Back'
import { colors } from '../../constants/colors'

const FullReportArchive = () => {
	return (
		<>
			<Row style={styles.siteDashboardHeader}>
				<Col>
					<Row className="pt-3">
						<Col sm="auto">
							<Back />
						</Col>
					</Row>
					<Row>
						<Col sm="auto" className={'center-flex h1'}>
							<span>Report Archive</span>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row className="pt-3">
				<Col style={styles.listingCol}>
					<ReportArchive context="none" />
				</Col>
			</Row>
		</>
	)
}

const styles = {
	siteDashboardHeader: {
		backgroundColor: colors.colorPrimaryCyan,
		height: '150px',
		paddingLeft: '50px',
		paddingRight: '50px',
	},
	listingCol: {
		paddingLeft: '50px',
		paddingRight: '50px',
	},
} satisfies Record<string, React.CSSProperties>

export default FullReportArchive
