import * as Validate from '../utilities/validate'

interface ReadingLive {
	readingLive_Id: string
	reading_Ts: string
	reading_Data: number
	readingType_Id: string
	asset_Id: string
	site_Id: string
}

interface ReadingLiveResult extends Validate.Result {
	readingLives: ReadingLive[]
}

const defaultReadingLive = (reading: Partial<ReadingLive>): ReadingLive => ({
	readingLive_Id: reading.readingLive_Id || '',
	reading_Ts: reading.reading_Ts || '',
	reading_Data: reading.reading_Data || 0,
	readingType_Id: reading.readingType_Id || '',
	asset_Id: reading.asset_Id || '',
	site_Id: reading.site_Id || '',
})

export { defaultReadingLive }
export type { ReadingLive, ReadingLiveResult }
