import React from 'react'
import { Form, FormControlProps } from 'react-bootstrap'

interface FormTextProps extends FormControlProps {
	name: string
	accept?: string
	label?: string
	required?: boolean
	feedback?: string
}

const FormFile = (props: FormTextProps) => {
	return (
		<Form.Group controlId={props.name}>
			{props.label ? (
				<Form.Label className={`btn btn-secondary round${props.disabled ? ' disabled' : ''}`}>
					{props.label}
					{props.required ? ' *' : ''}
				</Form.Label>
			) : null}
			<Form.Control
				{...props}
				hidden
				className="btn btn-secondary round"
				type="file"
				onChange={(e) => {
					if (props.onChange) {
						props.onChange(e)
						e.target.value = ''
					}
				}}
			/>
			{props.feedback ? <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback> : null}
		</Form.Group>
	)
}

export { FormFile }
