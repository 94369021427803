import React, { useContext } from 'react'
import { AppContext } from '../../../App'

import { Formik, FormikHelpers, FormikProps } from 'formik'
import { Card, Col, Form, Row } from 'react-bootstrap'
import * as yup from 'yup'

import { Button } from '../Button/Button'
import { FormRange } from '../Form/Range'
import { FormText } from '../Form/Text'
import { Loading } from '../Loading/Loading'
import { Bacnet, BacnetResult, defaultBacnet } from '../../../models/Bacnet'
import { defaultCreated, defaultModified } from '../../../models/History'
import { PageStatus } from '../../../types/PageStatus'
import * as Request from '../../../utilities/request'
import { hasPermission } from '../../../utilities/permissions/permission'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../utilities/permissions/permission.d'
import PermissionsCheck from '../../Permissions/PermissionsCheck'
import { FormNumber } from '../Form/Number'

const bacnetValidationSchema = yup.object().shape({
	bacnet_IsEnabled: yup.boolean().required(),
	bacnet_RegistrationCode: yup.string().nullable().max(36, 'Registration Code must be ${max} characters or less'),
	bacnet_NetworkNumber: yup.string().nullable().max(36, 'Network Number must be ${max} characters or less'),
	bacnet_IPAddress: yup.string().nullable().max(36, 'IP Address must be ${max} characters or less'),
	Bacnet_PortNumber: yup.string().nullable().max(36, 'Port Number must be ${max} characters or less'),
	bacnet_DeviceInstanceNumber: yup.string().nullable().max(36, 'Device Instance Number must be ${max} characters or less'),
	bacnet_DeviceName: yup.string().nullable().max(100, 'Device Name must be ${max} characters or less'),
	bacnet_DeviceDescription: yup.string().nullable().max(1000, 'Device Description must be ${max} characters or less'),
	bacnet_DeviceLocation: yup.string().nullable().max(1000, 'Device Location must be ${max} characters or less'),
	bacnet_UseRecipientDevice: yup.boolean().required(),
	bacnet_RecipientDeviceInstanceNumber: yup.string().nullable().max(36, 'Recipient Device Instance Number must be ${max} characters or less'),
	bacnet_RecipientDeviceProcessNumber: yup.string().nullable().max(36, 'Recipient Device Process Number must be ${max} characters or less'),
	bacnet_HubErrorPriority: yup.number().nullable(),
	bacnet_PortErrorPriority: yup.number().nullable(),
	bacnet_TmvLowLevelAlertPriority: yup.number().nullable(),
	bacnet_TmvHighLevelAlertPriority: yup.number().nullable(),
	bacnet_AlertDuration: yup.number().nullable(),
	bacnet_CovUpdateInterval: yup.number().nullable(),
	bacnet_MaximumForeignDevices: yup.number().nullable(),
	bacnet_BBMDIPAddress: yup.string().nullable().max(36, 'BBMD IP Address must be ${max} characters or less'),
	bacnet_BBMDPortNumber: yup.string().nullable().max(36, 'BBMD Port Number must be ${max} characters or less'),
	bacnet_HubErrorsTriggerGeneralAlarm: yup.boolean().required(),
	bacnet_PortErrorsTriggerGeneralAlarm: yup.boolean().required(),
	bacnet_HighLevelAlertsTriggerGeneralAlarm: yup.boolean().required(),
	bacnet_HighLevelAlertInstanceNumber: yup.number().nullable(),
})

const BacnetSettingsCard = () => {
	const context = useContext(AppContext)
	const hasEditPermissions = hasPermission(PermissionModelObject.Bacnet, PermissionModelAction.PUT, context.appState, PermissionModelContext.Site)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [formBacnet, setFormBacnet] = React.useState<Bacnet>(defaultBacnet({}))
	const formRef = React.useRef<FormikProps<Bacnet>>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [bacnetReq] = await Promise.all([
				Request.get<BacnetResult>(`bacnet?site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
			])
			formRef.current?.resetForm()
			setFormBacnet(
				bacnetReq.data.bacnets.length > 0
					? bacnetReq.data.bacnets[0]
					: defaultBacnet({
							site_Id: context.appState.currentSite?.site_Id,
							created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
							modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
					  })
			)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	const handleBacnetSubmit = async (values: Bacnet, formikHelpers: FormikHelpers<Bacnet>) => {
		console.log(values)

		if (values.bacnet_Id) {
			return Request.handleRequest(() => Request.put<BacnetResult>(`bacnet`, values, context.appState.authState), {
				successFunction: (data) => {
					if (data.bacnets.length > 0) {
						setFormBacnet(data.bacnets[0])
						formikHelpers.resetForm()
					}
				},
				messageAction: 'editing',
				messageObject: 'bacnet',
			})
		} else {
			return Request.handleRequest(() => Request.post<BacnetResult>(`bacnet`, values, context.appState.authState), {
				successFunction: (data) => {
					if (data.bacnets.length > 0) {
						setFormBacnet(data.bacnets[0])
						formikHelpers.resetForm()
					}
				},
				messageAction: 'editing',
				messageObject: 'bacnet',
			})
		}
	}

	return (
		<Formik initialValues={formBacnet} validationSchema={bacnetValidationSchema} onSubmit={handleBacnetSubmit} enableReinitialize innerRef={formRef}>
			{({ handleSubmit, isSubmitting, touched, errors, values, handleChange, setFieldValue }) => (
				<Card className="p-0">
					<Card.Header>
						<Row>
							<Col>BACnet Settings</Col>
							<Col sm="auto">
								<Form.Check
									name="bacnet_IsEnabled"
									type="switch"
									checked={values.bacnet_IsEnabled}
									onChange={handleChange}
									label="Enabled"
									disabled={!hasEditPermissions}
								/>
							</Col>
						</Row>
					</Card.Header>
					{pageStatus === 'Loading' ? (
						<Card.Body>
							<Loading show />
						</Card.Body>
					) : (
						<Card.Body>
							<Row>
								<Col>
									<FormText
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_RegistrationCode"
										value={values.bacnet_RegistrationCode || ''}
										onChange={handleChange}
										label="Registration Code"
										feedback={touched.bacnet_RegistrationCode && errors.bacnet_RegistrationCode ? errors.bacnet_RegistrationCode : ''}
										isInvalid={touched.bacnet_RegistrationCode && !!errors.bacnet_RegistrationCode}
									/>
									<FormText
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_NetworkNumber"
										value={values.bacnet_NetworkNumber || ''}
										onChange={handleChange}
										label="Network Number"
										feedback={touched.bacnet_NetworkNumber && errors.bacnet_NetworkNumber ? errors.bacnet_NetworkNumber : ''}
										isInvalid={touched.bacnet_NetworkNumber && !!errors.bacnet_NetworkNumber}
									/>
									<FormText
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_IPAddress"
										value={values.bacnet_IPAddress || ''}
										onChange={handleChange}
										label="IP Address"
										feedback={touched.bacnet_IPAddress && errors.bacnet_IPAddress ? errors.bacnet_IPAddress : ''}
										isInvalid={touched.bacnet_IPAddress && !!errors.bacnet_IPAddress}
									/>
									<FormText
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_PortNumber"
										value={values.bacnet_PortNumber || ''}
										onChange={handleChange}
										label="Port Number"
										feedback={touched.bacnet_PortNumber && errors.bacnet_PortNumber ? errors.bacnet_PortNumber : ''}
										isInvalid={touched.bacnet_PortNumber && !!errors.bacnet_PortNumber}
									/>
									<FormText
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_DeviceInstanceNumber"
										value={values.bacnet_DeviceInstanceNumber || ''}
										onChange={handleChange}
										label="Device Instance Number"
										feedback={
											touched.bacnet_DeviceInstanceNumber && errors.bacnet_DeviceInstanceNumber ? errors.bacnet_DeviceInstanceNumber : ''
										}
										isInvalid={touched.bacnet_DeviceInstanceNumber && !!errors.bacnet_DeviceInstanceNumber}
									/>
									<FormText
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_DeviceName"
										value={values.bacnet_DeviceName || ''}
										onChange={handleChange}
										label="Device Name"
										feedback={touched.bacnet_DeviceName && errors.bacnet_DeviceName ? errors.bacnet_DeviceName : ''}
										isInvalid={touched.bacnet_DeviceName && !!errors.bacnet_DeviceName}
									/>
									<FormText
										disabled={!hasEditPermissions}
										name="bacnet_DeviceDescription"
										value={values.bacnet_DeviceDescription || ''}
										onChange={handleChange}
										label="Device Description"
										feedback={touched.bacnet_DeviceDescription && errors.bacnet_DeviceDescription ? errors.bacnet_DeviceDescription : ''}
										isInvalid={touched.bacnet_DeviceDescription && !!errors.bacnet_DeviceDescription}
									/>
									<FormText
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_DeviceLocation"
										value={values.bacnet_DeviceLocation || ''}
										onChange={handleChange}
										label="Device Location"
										feedback={touched.bacnet_DeviceLocation && errors.bacnet_DeviceLocation ? errors.bacnet_DeviceLocation : ''}
										isInvalid={touched.bacnet_DeviceLocation && !!errors.bacnet_DeviceLocation}
									/>
									<FormNumber
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_HighLevelAlertInstanceNumber"
										value={values.bacnet_HighLevelAlertInstanceNumber || 0}
										onChange={(value) => setFieldValue(`bacnet_HighLevelAlertInstanceNumber`, value)}
										min={0}
										label="High Level Alert Instance Number"
										feedback={errors.bacnet_HighLevelAlertInstanceNumber}
										isInvalid={touched.bacnet_HighLevelAlertInstanceNumber && !!errors.bacnet_HighLevelAlertInstanceNumber}
									/>
									<Form.Check
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_UseRecipientDevice"
										checked={values.bacnet_UseRecipientDevice}
										onChange={handleChange}
										label="Use Recipient Device"
									/>
									<FormText
										disabled={!values.bacnet_IsEnabled || !values.bacnet_UseRecipientDevice || !hasEditPermissions}
										name="bacnet_RecipientDeviceInstanceNumber"
										value={values.bacnet_RecipientDeviceInstanceNumber || ''}
										onChange={handleChange}
										label="Recipient Device Instance Number"
										required={values.bacnet_UseRecipientDevice}
										feedback={
											touched.bacnet_RecipientDeviceInstanceNumber && errors.bacnet_RecipientDeviceInstanceNumber
												? errors.bacnet_RecipientDeviceInstanceNumber
												: ''
										}
										isInvalid={touched.bacnet_RecipientDeviceInstanceNumber && !!errors.bacnet_RecipientDeviceInstanceNumber}
									/>
									<FormText
										disabled={!values.bacnet_IsEnabled || !values.bacnet_UseRecipientDevice || !hasEditPermissions}
										name="bacnet_RecipientDeviceProcessNumber"
										value={values.bacnet_RecipientDeviceProcessNumber || ''}
										onChange={handleChange}
										label="Recipient Device Process Number"
										required={values.bacnet_UseRecipientDevice}
										feedback={
											touched.bacnet_RecipientDeviceProcessNumber && errors.bacnet_RecipientDeviceProcessNumber
												? errors.bacnet_RecipientDeviceProcessNumber
												: ''
										}
										isInvalid={touched.bacnet_RecipientDeviceProcessNumber && !!errors.bacnet_RecipientDeviceProcessNumber}
									/>
								</Col>
								<Col>
									<FormRange
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_HubErrorPriority"
										value={values.bacnet_HubErrorPriority || 0}
										onChange={handleChange}
										setFieldValue={setFieldValue}
										label="Hub Error Priority"
										note="(Must be between 0 & 255)"
										min={0}
										max={255}
									/>
									<FormRange
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_PortErrorPriority"
										value={values.bacnet_PortErrorPriority || 0}
										onChange={handleChange}
										setFieldValue={setFieldValue}
										label="Port Error Priority"
										note="(Must be between 0 & 255)"
										min={0}
										max={255}
									/>
									<FormRange
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_TmvLowLevelAlertPriority"
										value={values.bacnet_TmvLowLevelAlertPriority || 0}
										onChange={handleChange}
										setFieldValue={setFieldValue}
										label="TMV Low Level Alert Priority"
										note="(Must be between 0 & 255)"
										min={0}
										max={255}
									/>
									<FormRange
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_TmvHighLevelAlertPriority"
										value={values.bacnet_TmvHighLevelAlertPriority || 0}
										onChange={handleChange}
										setFieldValue={setFieldValue}
										label="TMV High Level Alert Priority"
										note="(Must be between 0 & 255)"
										min={0}
										max={255}
									/>
									<FormRange
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_AlertDuration"
										value={values.bacnet_AlertDuration || 0}
										onChange={handleChange}
										setFieldValue={setFieldValue}
										label="Alert Duration"
										note="(Must be between 0 & 300 seconds)"
										min={0}
										max={300}
									/>
									<FormRange
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_CovUpdateInterval"
										value={values.bacnet_CovUpdateInterval || 0}
										onChange={handleChange}
										setFieldValue={setFieldValue}
										label="COV Update Interval"
										note="(Must be between 0 & 300 seconds)"
										min={0}
										max={300}
									/>
									<FormRange
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_MaximumForeignDevices"
										value={values.bacnet_MaximumForeignDevices || 0}
										onChange={handleChange}
										setFieldValue={setFieldValue}
										label="Maximum Foreign Devices"
										note="(Set Between 1 and 255 to enable a BBMD Server)"
										min={0}
										max={255}
									/>
									<FormText
										disabled={
											!values.bacnet_IsEnabled ||
											(values.bacnet_MaximumForeignDevices && values.bacnet_MaximumForeignDevices > 0 ? false : true) ||
											!hasEditPermissions
										}
										name="bacnet_BBMDIPAddress"
										value={values.bacnet_BBMDIPAddress || ''}
										onChange={handleChange}
										label="BBMD IP Address"
										required={!!values.bacnet_MaximumForeignDevices && values.bacnet_MaximumForeignDevices > 0}
										feedback={touched.bacnet_BBMDIPAddress && errors.bacnet_BBMDIPAddress ? errors.bacnet_BBMDIPAddress : ''}
										isInvalid={touched.bacnet_BBMDIPAddress && !!errors.bacnet_BBMDIPAddress}
									/>
									<FormText
										disabled={
											!values.bacnet_IsEnabled ||
											(values.bacnet_MaximumForeignDevices && values.bacnet_MaximumForeignDevices > 0 ? false : true) ||
											!hasEditPermissions
										}
										name="bacnet_BBMDPortNumber"
										value={values.bacnet_BBMDPortNumber || ''}
										onChange={handleChange}
										label="BBMD Port Number"
										required={!!values.bacnet_MaximumForeignDevices && values.bacnet_MaximumForeignDevices > 0}
										feedback={touched.bacnet_BBMDPortNumber && errors.bacnet_BBMDPortNumber ? errors.bacnet_BBMDPortNumber : ''}
										isInvalid={touched.bacnet_BBMDPortNumber && !!errors.bacnet_BBMDPortNumber}
									/>
									<Form.Check
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_HubErrorsTriggerGeneralAlarm"
										checked={values.bacnet_HubErrorsTriggerGeneralAlarm}
										onChange={handleChange}
										label="Hub Errors Trigger General Alarm"
									/>
									<Form.Check
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_PortErrorsTriggerGeneralAlarm"
										checked={values.bacnet_PortErrorsTriggerGeneralAlarm}
										onChange={handleChange}
										label="Port Errors Trigger General Alarm"
									/>
									<Form.Check
										disabled={!values.bacnet_IsEnabled || !hasEditPermissions}
										name="bacnet_HighLevelAlertsTriggerGeneralAlarm"
										checked={values.bacnet_HighLevelAlertsTriggerGeneralAlarm}
										onChange={handleChange}
										label="High Level Alerts Trigger General Alarm"
									/>
								</Col>
							</Row>
							<PermissionsCheck object={PermissionModelObject.Bacnet} action={PermissionModelAction.PUT} context={PermissionModelContext.Site}>
								<Row className="mt-3">
									<Col sm="auto">
										<Button
											disabled={isSubmitting}
											variant="secondary"
											onClick={() => {
												context.setAppState({ state: 'setCurrentSite', data: { site: context.appState.currentSite } })
											}}
										>
											Cancel
										</Button>
									</Col>
									<Col sm="auto">
										<Button
											disabled={isSubmitting}
											variant="primary"
											onClick={() => {
												handleSubmit()
											}}
										>
											Save
										</Button>
									</Col>
								</Row>
							</PermissionsCheck>
						</Card.Body>
					)}
				</Card>
			)}
		</Formik>
	)
}

export { BacnetSettingsCard }
