import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { Asset, defaultAsset } from './Asset'
import { Site, defaultSite } from './Site'

interface Maintenance {
	maintenance_Id: string
	maintenance_ScheduledDate: string
	maintenance_CompletionDate: string | null
	maintenance_CompletedBy: string | null
	maintenance_Comments: string | null
	maintenance_Ts: string | null
	maintenance_FormVersion: number
	maintenance_LicenseNumber: string | null
	maintenance_StrainersCleaned: boolean
	maintenance_StrainersReplaced: boolean
	maintenance_NonReturnValvesCleaned: boolean
	maintenance_NonReturnValvesReplaced: boolean
	maintenance_ORingsAndSealsCleaned: boolean
	maintenance_ORingsAndSealsReplaced: boolean
	maintenanceElementReplaced: boolean
	maintenanceElementCleaned: boolean
	maintenanceOtherCriticalComponentsCleaned: boolean
	maintenanceOtherCriticalComponentsReplaced: boolean
	maintenanceValveReplaced: boolean
	maintenanceHotWaterTemperature: number
	maintenanceColdWaterTemperature: number
	maintenanceMixedWaterTemperature: number
	maintenanceMixedWaterTemperatureAtMaximumFlow: number
	maintenanceMixedWaterTemperatureAtMinimumFlow: number
	maintenancePassedHotWaterIsolationTest: boolean
	maintenancePassedColdWaterIsolationTest: boolean
	maintenanceType_Id: string
	maintenanceType_Name: string
	asset_Id: string
	maintenance_Asset: Asset
	site_Id: string
	maintenance_Site: Site
	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface MaintenanceResult extends Validate.Result {
	maintenances: Maintenance[]
}

const defaultMaintenance = (maintenance: Partial<Maintenance>): Maintenance => ({
	maintenance_Id: maintenance.maintenance_Id || '',
	maintenance_ScheduledDate: maintenance.maintenance_ScheduledDate || '',
	maintenance_CompletionDate: maintenance.maintenance_CompletionDate || null,
	maintenance_CompletedBy: maintenance.maintenance_CompletedBy || null,
	maintenance_Comments: maintenance.maintenance_Comments || null,
	maintenance_Ts: maintenance.maintenance_Ts || null,
	maintenance_FormVersion: maintenance.maintenance_FormVersion || 0,
	maintenance_LicenseNumber: maintenance.maintenance_LicenseNumber || null,
	maintenance_StrainersCleaned: maintenance.maintenance_StrainersCleaned || false,
	maintenance_StrainersReplaced: maintenance.maintenance_StrainersReplaced || false,
	maintenance_NonReturnValvesCleaned: maintenance.maintenance_NonReturnValvesCleaned || false,
	maintenance_NonReturnValvesReplaced: maintenance.maintenance_NonReturnValvesReplaced || false,
	maintenance_ORingsAndSealsCleaned: maintenance.maintenance_ORingsAndSealsCleaned || false,
	maintenance_ORingsAndSealsReplaced: maintenance.maintenance_ORingsAndSealsReplaced || false,
	maintenanceElementReplaced: maintenance.maintenanceElementReplaced || false,
	maintenanceElementCleaned: maintenance.maintenanceElementCleaned || false,
	maintenanceOtherCriticalComponentsCleaned: maintenance.maintenanceOtherCriticalComponentsCleaned || false,
	maintenanceOtherCriticalComponentsReplaced: maintenance.maintenanceOtherCriticalComponentsReplaced || false,
	maintenanceValveReplaced: maintenance.maintenanceValveReplaced || false,
	maintenanceHotWaterTemperature: maintenance.maintenanceHotWaterTemperature || 0,
	maintenanceColdWaterTemperature: maintenance.maintenanceColdWaterTemperature || 0,
	maintenanceMixedWaterTemperature: maintenance.maintenanceMixedWaterTemperature || 0,
	maintenanceMixedWaterTemperatureAtMaximumFlow: maintenance.maintenanceMixedWaterTemperatureAtMaximumFlow || 0,
	maintenanceMixedWaterTemperatureAtMinimumFlow: maintenance.maintenanceMixedWaterTemperatureAtMinimumFlow || 0,
	maintenancePassedHotWaterIsolationTest: maintenance.maintenancePassedHotWaterIsolationTest || false,
	maintenancePassedColdWaterIsolationTest: maintenance.maintenancePassedColdWaterIsolationTest || false,
	maintenanceType_Id: maintenance.maintenanceType_Id || '',
	maintenanceType_Name: maintenance.maintenanceType_Name || '',
	asset_Id: maintenance.asset_Id || '',
	maintenance_Asset: maintenance.maintenance_Asset || defaultAsset({}),
	site_Id: maintenance.site_Id || '',
	maintenance_Site: maintenance.maintenance_Site || defaultSite({}),

	created: maintenance.created || defaultCreated({}),
	modified: maintenance.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultMaintenance }
export type { Maintenance, MaintenanceResult }
