import { genericRoleRequirement, forbidden } from '../shared'

export const importPermissionObject = {
	// GET and DELETE for import don't exist
	GET: {
		PermissionsRequired: [forbidden],
	},
	POST: {
		PermissionsRequired: [genericRoleRequirement],
	},
	PUT: {
		PermissionsRequired: [genericRoleRequirement],
	},
	DELETE: {
		PermissionsRequired: [forbidden],
	},
}
