import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'

interface SiteGroup {
	siteGroup_Id: string
	siteGroup_Name: string
	siteGroup_ParentId: string | null
	siteGroup_Timezone: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface SiteGroupResult extends Validate.Result {
	siteGroups: SiteGroup[]
}

const defaultSiteGroup = (siteGroup: Partial<SiteGroup>): SiteGroup => ({
	siteGroup_Id: siteGroup.siteGroup_Id || '',
	siteGroup_Name: siteGroup.siteGroup_Name || '',
	siteGroup_ParentId: siteGroup.siteGroup_ParentId || null,
	siteGroup_Timezone: siteGroup.siteGroup_Timezone || 'UTC',

	created: siteGroup.created || defaultCreated({}),
	modified: siteGroup.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultSiteGroup }
export type { SiteGroup, SiteGroupResult }
