import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { SitePicker } from './SitePicker'
import { SiteGroupPicker } from './SiteGroupPicker'

interface PickerHeaderProps {
	type: 'site' | 'siteGroup'
	title: string
	container?: boolean
	rightElement?: React.ReactNode
}

const PickerHeader = (props: PickerHeaderProps) => {
	const contents = (
		<>
			<Row>
				<Col sm="auto" className={'center-flex'}>
					<h1 className="mb-0" style={styles.title}>
						{props.title}
					</h1>
				</Col>
				<Col sm="auto" className={'center-flex'} style={styles.picker}>
					{props.type === 'site' ? <SitePicker /> : <SiteGroupPicker />}
				</Col>
				<Col />
				<Col sm="auto" className={'center-flex'}>
					{props.rightElement}
				</Col>
			</Row>
		</>
	)

	return (
		<Row style={styles.container}>
			{props.container ? (
				<Col>
					<Container>{contents}</Container>
				</Col>
			) : (
				contents
			)}
		</Row>
	)
}

const styles = {
	container: { background: 'var(--color-primary-cyan)', margin: '0 -12px 1rem -12px', padding: '1.5rem 12px' },
	title: { fontFamily: 'Helvetica', fontSize: '32px', fontStyle: 'normal', fontWeight: '700', lineHeight: '32px' },
	picker: { minHeight: '38px' },
}

export default PickerHeader
