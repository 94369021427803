import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { AssetStub, defaultAssetStub } from './Asset'

interface ChannelMap {
	channelMap_Id: string
	channelMap_PortNumber: number
	channelMap_SubChannel: number | null
	channelMap_HubAssetId: string
	hub_Asset: AssetStub
	channelMap_TmvAssetId: string
	tmv_Asset: AssetStub
	readingType_Id: string
	readingType_Name: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface ChannelMapResult extends Validate.Result {
	channelMaps: ChannelMap[]
}

const defaultChannelMap = (channelMap: Partial<ChannelMap>): ChannelMap => ({
	channelMap_Id: channelMap.channelMap_Id || '',
	channelMap_PortNumber: channelMap.channelMap_PortNumber || 0,
	channelMap_SubChannel: channelMap.channelMap_SubChannel || null,
	channelMap_HubAssetId: channelMap.channelMap_HubAssetId || '',
	hub_Asset: channelMap.hub_Asset || defaultAssetStub({}),
	channelMap_TmvAssetId: channelMap.channelMap_TmvAssetId || '',
	tmv_Asset: channelMap.tmv_Asset || defaultAssetStub({}),
	readingType_Id: channelMap.readingType_Id || '',
	readingType_Name: channelMap.readingType_Name || '',

	created: channelMap.created || defaultCreated({}),
	modified: channelMap.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultChannelMap }
export type { ChannelMap, ChannelMapResult }
