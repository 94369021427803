import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { PageStatus } from '../../../types/PageStatus'

interface NoteProps {
	note: string
	pageStatus: PageStatus
	handleChange: React.ChangeEventHandler<HTMLInputElement>
}

const Note = (props: NoteProps) => {
	return (
		<div className="note">
			<Row>
				<Col sm={8}>
					<span className="dashboard-card-titles" style={styles.label}>
						Notes
					</span>
				</Col>
			</Row>
			<Form.Control as="textarea" name={'note'} rows={18} onChange={props.handleChange} value={props.note} readOnly={props.pageStatus !== 'Editing'} />
		</div>
	)
}

const styles = {
	label: {
		height: 'unset',
		marginBottom: '20px',
	},
}

export { Note }
export type { NoteProps }
