import React from 'react'
import { Form, FormControlProps } from 'react-bootstrap'

interface FormDatePickerProps extends Omit<FormControlProps, 'value'> {
	name: string
	value: string | null | undefined // yyyy-MM-dd
	label?: string
	required?: boolean
	feedback?: string
}

const FormDate = (props: FormDatePickerProps) => {
	return (
		<Form.Group controlId={props.name}>
			{props.label ? (
				<Form.Label>
					{props.label}
					{props.required ? ' *' : ''}
				</Form.Label>
			) : null}
			<Form.Control type={'date'} {...props} value={props.value || ''} />
			{props.feedback ? <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback> : null}
		</Form.Group>
	)
}

export { FormDate }
