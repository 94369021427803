import { genericRoleRequirement, forbidden } from '../shared'

export const exportPermissionObject = {
	GET: {
		PermissionsRequired: [genericRoleRequirement],
	},
	// POSTPUTDELETE for export doesn't exist
	POST: {
		PermissionsRequired: [forbidden],
	},
	PUT: {
		PermissionsRequired: [forbidden],
	},
	DELETE: {
		PermissionsRequired: [forbidden],
	},
}
