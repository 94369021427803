import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'

interface PaymentAgreement {
	paymentAgreement_Id: string
	paymentAgreement_StartDate: string | null
	paymentAgreement_EndDate: string | null
	paymentAgreement_AssetCount: number
	paymentAgreement_AssetCountAssigned: number
	paymentAgreement_OrderNumber: string | null
	paymentAgreement_LineNumber: number
	paymentAgreement_Note: string | null
	contract_Id: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface PaymentAgreementResult extends Validate.Result {
	paymentAgreements: PaymentAgreement[]
}

interface PaymentAgreementMigrationResult extends Validate.Result {
	result: string
}

const defaultPaymentAgreement = (paymentAgreement: Partial<PaymentAgreement>): PaymentAgreement => ({
	paymentAgreement_Id: paymentAgreement.paymentAgreement_Id || '',
	paymentAgreement_StartDate: paymentAgreement.paymentAgreement_StartDate || null,
	paymentAgreement_EndDate: paymentAgreement.paymentAgreement_EndDate || null,
	paymentAgreement_AssetCount: paymentAgreement.paymentAgreement_AssetCount || 0,
	paymentAgreement_AssetCountAssigned: paymentAgreement.paymentAgreement_AssetCountAssigned || 0,
	paymentAgreement_OrderNumber: paymentAgreement.paymentAgreement_OrderNumber || null,
	paymentAgreement_LineNumber: paymentAgreement.paymentAgreement_LineNumber || 0,
	paymentAgreement_Note: paymentAgreement.paymentAgreement_Note || null,
	contract_Id: paymentAgreement.contract_Id || '',

	created: paymentAgreement.created || defaultCreated({}),
	modified: paymentAgreement.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultPaymentAgreement }
export type { PaymentAgreement, PaymentAgreementResult, PaymentAgreementMigrationResult }
