import React from 'react'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { FormText } from '../Form/Text'
import { FormStepProps } from '../../../screens/Contracts/ContractWizard'
import { ABNValidChecksum, ABNValidForm, NameResultItem, SearchABNforName, SearchNameForABN } from '../../../utilities/abn'
import { AppContext } from '../../../App'
import { Contract, ContractResult } from '../../../models/Contract'
import * as Request from '../../../utilities/request'

const CustomerValidationSchema = Yup.object().shape({
	contract_BuyerName: Yup.string().notRequired().max(100, 'Cannot be longer than ${max} characters.'),
	contract_BuyerABN: Yup.string()
		.notRequired()
		.test('abn-form', 'An ABN must be 11 digits', (value) => {
			return value ? ABNValidForm(value) : true
		})
		.test('abn-valid', 'This is not a valid ABN', (value) => {
			return value ? ABNValidChecksum(value) : true
		}),
	contract_VisualNumber: Yup.string().notRequired().max(36, 'Cannot be longer than ${max} characters.'),
	contract_VisualShipTo: Yup.string().notRequired().max(36, 'Cannot be longer than ${max} characters.'),
	contract_CustomerAccount: Yup.string().notRequired().max(10, 'Cannot be longer than ${max} characters.'),
	contract_CustomerCostCentre: Yup.string().notRequired().max(10, 'Cannot be longer than ${max} characters.'),
	contract_NswHealthPrefix: Yup.string().notRequired().max(36, 'Cannot be longer than ${max} characters.'),
})

const CustomerForm = (props: FormStepProps) => {
	const context = React.useContext(AppContext)

	const { values, handleChange, setFieldValue, setValues, errors, touched, handleBlur } = props.formikProps
	const [abnNameSearchResults, setAbnNameSearchResults] = React.useState<NameResultItem[]>([])
	const [contracts, setContracts] = React.useState<Contract[]>([])

	React.useEffect(() => {
		const getData = async () => {
			const contractsReq = await Request.get<ContractResult>(`contract`, context.appState.authState)

			setContracts(contractsReq.data.contracts)
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [])

	React.useEffect(() => {
		if (contracts.length > 0) {
			const newContractNumber = contracts.filter((c) => c.contract_VisualNumber === values.contract_VisualNumber).length + 1
			setFieldValue('contract_Number', newContractNumber.toString().padStart(3, '0'))
		}
	}, [values.contract_VisualNumber])

	return (
		<>
			<Row>
				<Col>
					<span className="span-bold">Customer Details</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormText
						name="contract_BuyerName"
						label="Customer Name"
						value={values.contract_BuyerName || ''}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_BuyerName && errors.contract_BuyerName ? errors.contract_BuyerName : ''}
						isInvalid={touched.contract_BuyerName && !!errors.contract_BuyerName}
						rightSideContent={
							<Button
								onClick={async () => {
									const res = await SearchNameForABN(values.contract_BuyerName || '')
									if (res !== null) {
										setAbnNameSearchResults(res.Names)
									}
								}}
								style={styles.button}
							>
								Search
							</Button>
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormText
						name="contract_BuyerABN"
						label="ABN"
						value={values.contract_BuyerABN || ''}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_BuyerABN && errors.contract_BuyerABN ? errors.contract_BuyerABN : ''}
						isInvalid={touched.contract_BuyerABN && !!errors.contract_BuyerABN}
						rightSideContent={
							<Button
								// disabled={!touched.contract_BuyerABN || !!errors.contract_BuyerABN}
								onClick={async () => {
									const abnRes = await SearchABNforName(values.contract_BuyerABN || '')
									if (abnRes !== null) {
										setFieldValue('contract_BuyerName', abnRes.EntityName)
									}
								}}
								style={styles.button}
							>
								Search
							</Button>
						}
					/>
				</Col>
			</Row>
			<Row className="border-bottom mt-4 mb-2" />
			<Row>
				<Col>
					<FormText
						name="statementOfWork"
						label="Enware Statement Of Work"
						value={`${values.contract_VisualNumber || ''}_${values.contract_VisualShipTo || ''}_${values.contract_Number}`}
						disabled
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormText
						name="contract_VisualNumber"
						label="Visual Customer Number"
						value={values.contract_VisualNumber || ''}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_VisualNumber && errors.contract_VisualNumber ? errors.contract_VisualNumber : ''}
						isInvalid={touched.contract_VisualNumber && !!errors.contract_VisualNumber}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormText
						name="contract_VisualShipTo"
						label="Visual Ship-To Number"
						value={values.contract_VisualShipTo || ''}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_VisualShipTo && errors.contract_VisualShipTo ? errors.contract_VisualShipTo : ''}
						isInvalid={touched.contract_VisualShipTo && !!errors.contract_VisualShipTo}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormText
						name="contract_CustomerAccount"
						label="Customer Account"
						value={values.contract_CustomerAccount || ''}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_CustomerAccount && errors.contract_CustomerAccount ? errors.contract_CustomerAccount : ''}
						isInvalid={touched.contract_CustomerAccount && !!errors.contract_CustomerAccount}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormText
						name="contract_CustomerCostCentre"
						label="Customer Cost Centre"
						value={values.contract_CustomerCostCentre || ''}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_CustomerCostCentre && errors.contract_CustomerCostCentre ? errors.contract_CustomerCostCentre : ''}
						isInvalid={touched.contract_CustomerCostCentre && !!errors.contract_CustomerCostCentre}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormText
						name="contract_NswHealthPrefix"
						label="NSW Health File Prefix"
						value={values.contract_NswHealthPrefix || ''}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_NswHealthPrefix && errors.contract_NswHealthPrefix ? errors.contract_NswHealthPrefix : ''}
						isInvalid={touched.contract_NswHealthPrefix && !!errors.contract_NswHealthPrefix}
					/>
				</Col>
			</Row>

			<Modal show={abnNameSearchResults.length > 0} onHide={() => setAbnNameSearchResults([])}>
				<ModalHeader closeButton>Select a Result</ModalHeader>
				<ModalBody>
					{abnNameSearchResults.map((res, index, arr) => (
						<Row key={res.Abn} className={index !== arr.length - 1 ? 'pb-2 mb-2 border-bottom' : undefined}>
							<Col>
								<Row>
									<Col sm={3} className="d-flex justify-content-end">
										<span className="span-bold" style={styles.modalSpan}>
											ABN:
										</span>
									</Col>
									<Col>
										<span style={styles.modalSpan}>{res.Abn}</span>
									</Col>
								</Row>
								<Row>
									<Col sm={3} className="d-flex justify-content-end">
										<span className="span-bold" style={styles.modalSpan}>
											Location:
										</span>
									</Col>
									<Col>
										<span style={styles.modalSpan}>
											{res.Postcode} {res.State}
										</span>
									</Col>
								</Row>
								<Row>
									<Col sm={3} className="d-flex justify-content-end">
										<span className="span-bold" style={styles.modalSpan}>
											Name:
										</span>
									</Col>
									<Col>
										<span style={styles.modalSpan}>{res.Name}</span>
									</Col>
								</Row>
							</Col>
							<Col sm="auto" className="center-flex">
								<Button
									onClick={() => {
										setValues((values) => ({ ...values, contract_BuyerABN: res.Abn, contract_BuyerName: res.Name }))
										setAbnNameSearchResults([])
									}}
								>
									Select
								</Button>
							</Col>
						</Row>
					))}
				</ModalBody>
				<ModalFooter>
					<Row>
						<Col />
						<Col>
							<Button onClick={() => setAbnNameSearchResults([])}>Cancel</Button>
						</Col>
					</Row>
				</ModalFooter>
			</Modal>
		</>
	)
}

const styles = {
	modalSpan: {
		minHeight: '25px',
	},
	button: {
		minHeight: '38px',
	},
} satisfies Record<string, React.CSSProperties>

export { CustomerForm, CustomerValidationSchema }
