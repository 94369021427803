import React from 'react'

import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { useNavigate, useParams } from 'react-router-dom'

import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { Information } from '../../components/UI/Information/Information'

import { LocationArea as ILocationArea, LocationAreaResult } from '../../models/LocationArea'
import { PageStatus } from '../../types/PageStatus'

import { PermissionModelObject } from '../../utilities/permissions/permission.d'

const LocationArea = () => {
	const context = React.useContext(AppContext)
	const navigate = useNavigate()
	const id = useParams().id || ''

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [messages, setMessages] = useMessageReducer([])

	const [locationArea, setLocationArea] = React.useState<ILocationArea | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const locationAreaReq = await Request.get<LocationAreaResult>(`locationArea?Id=${id}`, context.appState.authState)
			if (locationAreaReq.data.locationAreas.length > 0) {
				setLocationArea(locationAreaReq.data.locationAreas[0])
				setPageStatus('Ready')
			} else {
				setPageStatus('Error')
			}
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [id])

	const handleSave = async (values: { name: string; note: string }) => {
		setPageStatus('Submitting')
		await Request.handleRequest(
			() =>
				Request.put<LocationAreaResult>(
					'locationArea',
					{ ...locationArea, locationArea_Name: values.name, locationArea_Note: values.note },
					context.appState.authState
				),
			{
				successFunction: (data) => {
					setLocationArea(data.locationAreas[0])
					setPageStatus('Ready')
				},
				failedFunction: () => {
					setPageStatus('Error')
				},
				setMessageFunction: setMessages,
				messageAction: 'editing',
				messageObject: 'location area',
			}
		)
	}

	const handleDelete = async () => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.del<LocationAreaResult>(`locationArea?Id=${locationArea?.locationArea_Id}`, context.appState.authState), {
			successFunction: () => {
				setTimeout(() => navigate(-1), 500)
			},
			failedFunction: () => {
				setPageStatus('Error')
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'location area',
		})
	}

	return (
		<div className="page-container">
			<Messages messages={messages} updateMessage={setMessages} />
			<Information
				pageStatus={pageStatus}
				name={locationArea?.locationArea_Name || null}
				note={locationArea?.locationArea_Note || null}
				customDeleteMessage={'Are you sure you want to delete this area? All of its rooms, plus any associated documents, will also be deleted.'}
				fileUploadProps={{ locationArea_Id: id }}
				assetOverviewListingProps={{ filter: `locationArea_Id=${id}` }}
				permissionModelObject={PermissionModelObject.LocationArea}
				handleSave={handleSave}
				handleDelete={handleDelete}
			/>
		</div>
	)
}

export { LocationArea }
