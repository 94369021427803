import React from 'react'
import { TaskOverviewListing } from './TaskOverviewListing'
import PickerHeader from '../../components/UI/ContextualPickers/PickerHeader'

const TaskOverview = () => {
	return (
		<>
			<PickerHeader type="site" title="Tasks" />
			<TaskOverviewListing canCreate />
		</>
	)
}

export { TaskOverview }
