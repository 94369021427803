import React, { useContext } from 'react'
import { AppContext } from '../../../App'

import { Formik, FormikHelpers } from 'formik'
import { Modal } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'

import * as Request from '../../../utilities/request'
import { Button } from '../Button/Button'
import { FormText } from '../Form/Text'
import { defaultCreated, defaultModified } from '../../../models/History'
import { Site, SiteResult, defaultSite } from '../../../models/Site'
import { MessageAction } from '../Messages/Message'

const siteValidationSchema = yup.object().shape({
	site_Name: yup.string().required('Name is required').max(100, 'Name must be less than ${max} characters'),
})

interface AddSiteModalProps {
	showAddSiteModal: boolean
	setShowAddSiteModal: React.Dispatch<React.SetStateAction<boolean>>
	siteGroup_Id?: string
	customOnSubmit?: (values: Site, formikHelpers: FormikHelpers<Site>) => Promise<void>
	setMessages?: (action: MessageAction) => void
}

const AddSiteModal = (props: AddSiteModalProps) => {
	const context = useContext(AppContext)
	const navigate = useNavigate()

	const defaultFormSite = defaultSite({
		site_Id: uuidv4(),
		siteGroup_Id: props.siteGroup_Id,
		created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
	})
	const [formSite, setFormSite] = React.useState<Site>(defaultFormSite)

	React.useEffect(() => {
		setFormSite(defaultFormSite)
	}, [props.siteGroup_Id])

	const handleAddSiteSubmit = (values: Site) => {
		return Request.handleRequest(() => Request.post<SiteResult>(`site`, values, context.appState.authState), {
			successFunction: (data) => {
				if (data.sites.length > 0) {
					context.setAppState({
						state: 'setCurrentSite',
						data: { site: data.sites[0] },
					})
					navigate('site')
				}
			},
			messageAction: 'creating',
			messageObject: 'site',
		})
	}

	return (
		<Formik initialValues={formSite} validationSchema={siteValidationSchema} onSubmit={props.customOnSubmit || handleAddSiteSubmit} enableReinitialize>
			{({ handleSubmit, isSubmitting, touched, errors, values, handleChange, handleReset }) => (
				<Modal
					show={props.showAddSiteModal}
					onHide={() => {
						if (!isSubmitting) {
							handleReset()
							props.setShowAddSiteModal(false)
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>Add New Site</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<FormText
							name="site_Name"
							value={values.site_Name}
							onChange={handleChange}
							label="Name"
							required
							feedback={touched.site_Name && errors.site_Name ? errors.site_Name : ''}
							isInvalid={!!errors.site_Name}
							autoFocus
						/>
					</Modal.Body>

					<Modal.Footer>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleReset()
								setFormSite(defaultFormSite)
								props.setShowAddSiteModal(false)
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleSubmit()
								setFormSite(defaultFormSite)
							}}
						>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
	)
}

export { AddSiteModal }
