import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { PageStatus } from '../../../types/PageStatus'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'
import { Doughnut } from 'react-chartjs-2'
import { ChartData, ChartOptions } from 'chart.js'
import './DoughnutGraphBox.css'
import { Loading } from '../Loading/Loading'
import info from '../../../images/icons/alert-info.svg'
import htmlLegendPlugin from '../AssetDetails/htmlLegend'

interface DoughnutGraphBoxProps {
	orientation: 'horizontal' | 'vertical'
	pageStatus: PageStatus
	data: ChartData<'doughnut', number[], unknown>
	title: string
	subtitle?: string | string[]
	centerText?: string
	centerSubText?: string
	buttonLink?: string
	disableAnimation?: boolean
	showLegend?: boolean
}

const DoughnutGraphBox = (props: DoughnutGraphBoxProps) => {
	const graphStyle = React.useMemo<React.CSSProperties>(() => {
		return {
			position: 'relative',
			height: `${props.orientation === 'vertical' ? '200px' : '100%'}`,
		}
	}, [props.orientation])

	const graphOptions: ChartOptions<'doughnut'> = React.useMemo(() => {
		const commonOptions = {
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false,
				},
				doughnutCenterText: {
					text: props.centerText,
					subText: props.centerSubText,
					font: 'Helvetica',
					padding: props.orientation === 'vertical' ? 20 : 15,
				},
				datalabels: {
					display: false,
				},
			},
			cutout: `${props.orientation === 'vertical' ? '70%' : '65%'}`,
			animation: props.disableAnimation ? false : undefined,
		}
		if (props.showLegend) {
			return {
				...commonOptions,
				plugins: {
					...commonOptions.plugins,
					htmlLegend: {
						containerID: `legend-container-doughnut-${props.title}`,
					},
				},
			}
		}
		return commonOptions
	}, [props])

	const colSm = React.useMemo(() => {
		if (props.orientation === 'vertical') {
			if (props.showLegend) {
				return 9
			} else {
				return 12
			}
		} else {
			if (props.showLegend) {
				return 4
			} else {
				return 7
			}
		}
	}, [props.orientation, props.showLegend])

	const Graph = (
		<>
			<Col className={'center-flex'} sm={colSm}>
				{props.pageStatus === 'Ready' ? (
					<div style={graphStyle}>
						{props.data.datasets[0].data.reduce((a, b) => a + b, 0) > 0 ? (
							<>
								{props.showLegend ? (
									<Doughnut id="chart" data={props.data} plugins={[htmlLegendPlugin]} options={graphOptions} />
								) : (
									<Doughnut id="chart" data={props.data} options={graphOptions} />
								)}
							</>
						) : (
							<div className={'center-flex-col h-100'}>
								<img className="no-data-logo" src={info} alt="info logo" />
								<div className="no-data-text">No Data Available</div>
							</div>
						)}
					</div>
				) : (
					<Loading show />
				)}
			</Col>
			{props.showLegend && (
				<Col sm={3} style={styles.legend}>
					<div id={`legend-container-doughnut-${props.title}`}></div>
				</Col>
			)}
		</>
	)

	const subtitleArray = props.subtitle !== undefined ? (Array.isArray(props.subtitle) ? props.subtitle : [props.subtitle]) : []

	const Info = (
		<>
			<Col className={'center-flex-col'} sm={colSm}>
				<Row className={'center-flex'}>
					<Col className={'center-flex' + ' h5 fw-bold'}>{props.title}</Col>
				</Row>
				{subtitleArray.map((sub, index) => (
					<Row key={index}>
						<Col className={'center-flex'}>{sub}</Col>
					</Row>
				))}
				{props.buttonLink && (
					<Row>
						<Col className={'center-flex'}>
							<Link to={props.buttonLink}>
								<Button>View</Button>
							</Link>
						</Col>
					</Row>
				)}
			</Col>
			{props.showLegend && <Col sm={3}></Col>}
		</>
	)

	return (
		<Card className="doughnut-graph-card h-100 my-0">
			{props.orientation === 'horizontal' ? (
				<Row>
					{Graph}
					{Info}
				</Row>
			) : (
				<>
					<Row className="mt-4">{Graph}</Row>
					<Row className="mt-3 mb-4">{Info}</Row>
				</>
			)}
		</Card>
	)
}

const styles = {
	legend: { display: 'flex', alignItems: 'center', textWrap: 'nowrap' },
}

export default DoughnutGraphBox
