import React from 'react'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { FieldArray, Formik } from 'formik'
import * as yup from 'yup'

import { AppContext } from '../../../../App'
import * as Request from '../../../../utilities/request'
import { hasPermission } from '../../../../utilities/permissions/permission'

import { Loading } from '../../Loading/Loading'
import { EventSettingField } from '../EventSettingField'
import PermissionsCheck from '../../../Permissions/PermissionsCheck'

import { CommonSettingsComponentProps } from '../GroupInformation'
import { EventSetting, EventSettingResult, defaultTemperatureGroupEventSettings } from '../../../../models/EventSetting'
import { PageStatus } from '../../../../types/PageStatus'
import { defaultModified } from '../../../../models/History'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../../utilities/permissions/permission.d'

// validation currently done through min/max on number field
const eventSettingsValidationSchema = yup.object().shape({
	eventSettings: yup.array().of(
		yup.object().shape({
			eventSetting_Value: yup.number().required().min(0, 'Value must be at least 0 degrees').max(100, 'Value must not exceed 100 degrees'),
		})
	),
})

interface EventSettingsData {
	eventSettings: EventSetting[]
}

const TemperatureEventSettings = (props: CommonSettingsComponentProps) => {
	const context = React.useContext(AppContext)
	const isDefaultGroup = props.groupId === 'DefaultTemperatureGroup'
	const hasEditPermissions = hasPermission(PermissionModelObject.EventSetting, PermissionModelAction.PUT, context.appState, PermissionModelContext.Site)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [eventSettingsData, setEventSettingsData] = React.useState<EventSettingsData | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			if (isDefaultGroup) {
				setEventSettingsData({ eventSettings: defaultTemperatureGroupEventSettings })
			} else {
				const settingsReq = await Request.get<EventSettingResult>(`eventSetting?Group_Id=${props.groupId}`, context.appState.authState)
				setEventSettingsData({ eventSettings: settingsReq.data.eventSettings })
			}
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [props.groupId])

	const handleSave = async (values: EventSettingsData) => {
		setPageStatus('Submitting')
		const modified = defaultModified({ modified_UserId: context.appState.userAttributes.user_Id })
		const settingsRes = await Promise.all(
			values.eventSettings.map((eventSetting) =>
				Request.put<EventSettingResult>('eventSetting', { ...eventSetting, modified }, context.appState.authState)
			)
		)
		if (settingsRes.every((res) => res.status === 200)) {
			setEventSettingsData({
				eventSettings: settingsRes.flatMap((res) => res.data.eventSettings),
			})
			setPageStatus('Ready')
		} else {
			props.setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Error editing the group settings`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
	}

	return (
		<Card style={styles.card} className="site-card">
			<Row className="generic-card-list-heading-row">
				<Col sm="auto" style={styles.cellGrow}>
					<span className="dashboard-card-titles">Group Settings</span>
				</Col>
				{props.pageStatus !== 'Editing' && !isDefaultGroup && (
					<PermissionsCheck object={PermissionModelObject.Group} action={PermissionModelAction.PUT} context={PermissionModelContext.Site}>
						<Col sm="auto">
							<Button onClick={() => props.setPageStatus('Editing')}>EDIT GROUP</Button>
						</Col>
					</PermissionsCheck>
				)}
				{isDefaultGroup && (
					<Col sm="auto">
						<div className="invalid-feedback-custom">
							These are the default values that are used if an asset has no group, they can not be modified
						</div>
					</Col>
				)}
			</Row>
			<Row>
				{eventSettingsData ? (
					<Formik
						initialValues={eventSettingsData}
						validationSchema={eventSettingsValidationSchema}
						onSubmit={handleSave}
						enableReinitialize
						innerRef={props.saveEventSettingsRef}
					>
						{({ values, setFieldValue }) => (
							<>
								<Row style={styles.row} className="no-gutter">
									<FieldArray
										name={'eventSettings'}
										render={() => (
											<>
												{values.eventSettings
													.sort((a, b) => a.eventSettingType_Name.localeCompare(b.eventSettingType_Name))
													.map((eventSetting, index) => (
														<Col sm={6} key={eventSetting.eventSetting_Id} style={styles.formRow}>
															<EventSettingField
																eventSetting={eventSetting}
																index={index}
																setFieldValue={setFieldValue}
																isDisabled={
																	pageStatus === 'Submitting' || props.pageStatus !== 'Editing' || !hasEditPermissions
																}
																isReadOnly={props.pageStatus !== 'Editing'}
															/>
														</Col>
													))}
											</>
										)}
									/>
								</Row>
							</>
						)}
					</Formik>
				) : (
					<Row style={styles.row}>
						<Loading show={true} />
					</Row>
				)}
			</Row>
		</Card>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	card: {
		minHeight: '450px',
		marginTop: '20px',
	},
	row: {
		marginTop: '20px',
		justifyContent: 'space-between',
		minHeight: '300px',
	},
	cellGrow: {
		flex: '1 1 auto',
	},
	formRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		marginBottom: '20px',
	},
}

export { TemperatureEventSettings }
export type { EventSettingsData }
