import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { FieldArray, Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { hasPermission } from '../../../utilities/permissions/permission'
import { PageStatus } from '../../../types/PageStatus'
import { defaultCreated, defaultModified } from '../../../models/History'
import { Loading } from '../Loading/Loading'
import { PermissionModelObject, PermissionModelAction, PermissionModelContext } from '../../../utilities/permissions/permission.d'
import { SiteSetting, SiteSettingResult, defaultSiteSettings } from '../../../models/SiteSetting'
import { SiteSettingField } from './SiteSettingField'
import { MessageAction } from '../Messages/Message'

// validation currently done through min/max on number field
const siteSettingsValidationSchema = yup.object().shape({
	siteSettings: yup.array().of(
		yup.object().shape({
			siteSetting_Value: yup.number().required().min(0, 'Value must be at least 0').max(100, 'Value must not exceed 100'),
		})
	),
})

interface SiteSettingsData {
	siteSettings: SiteSetting[]
}

interface SiteSettingsComponentProps {
	pageStatus: PageStatus
	saveSiteSettingsRef: React.RefObject<FormikProps<SiteSettingsData>>
	setPageStatus: React.Dispatch<React.SetStateAction<PageStatus>>
	setMessages: (message: MessageAction) => void
}

const SiteEventSettings = (props: SiteSettingsComponentProps) => {
	const context = React.useContext(AppContext)
	const hasEditPermissions = hasPermission(PermissionModelObject.EventSetting, PermissionModelAction.PUT, context.appState, PermissionModelContext.Site)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [siteSettingsData, setSiteSettingsData] = React.useState<SiteSettingsData | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const settingsReq = await Request.get<SiteSettingResult>(`siteSetting?Site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState)
			console.log('settingsReq', settingsReq)
			if (settingsReq.data.siteSettings.length > 0) {
				setSiteSettingsData({ siteSettings: settingsReq.data.siteSettings })
			} else {
				setSiteSettingsData({ siteSettings: defaultSiteSettings.map((ds) => ({ ...ds, site_Id: context.appState.currentSite?.site_Id || '' })) })
			}

			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context.appState.currentSite?.site_Id])

	const handleSave = async (values: SiteSettingsData) => {
		setPageStatus('Submitting')
		const created = defaultCreated({ create_UserId: context.appState.userAttributes.user_Id })
		const modified = defaultModified({ modified_UserId: context.appState.userAttributes.user_Id })

		console.log(values.siteSettings)

		const settingsNew = await Promise.all(
			values.siteSettings
				.filter((s) => defaultSiteSettings.map((ds) => ds.siteSetting_Id).includes(s.siteSetting_Id))
				.map((siteSetting) =>
					Request.post<SiteSettingResult>('siteSetting', { ...siteSetting, siteSetting_Id: null, created, modified }, context.appState.authState)
				)
		)
		const settingsRes = await Promise.all(
			values.siteSettings
				.filter((s) => !defaultSiteSettings.map((ds) => ds.siteSetting_Id).includes(s.siteSetting_Id))
				.map((siteSetting) => Request.put<SiteSettingResult>('siteSetting', { ...siteSetting, modified }, context.appState.authState))
		)

		if (settingsNew.concat(settingsRes).every((res) => res.status === 200)) {
			setSiteSettingsData({
				siteSettings: settingsNew.concat(settingsRes).flatMap((res) => res.data.siteSettings),
			})
			setPageStatus('Editing')
		} else {
			props.setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Error editing the group settings`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
	}

	return (
		<>
			{siteSettingsData ? (
				<Formik
					initialValues={siteSettingsData}
					validationSchema={siteSettingsValidationSchema}
					onSubmit={handleSave}
					enableReinitialize
					innerRef={props.saveSiteSettingsRef}
				>
					{({ values, setFieldValue }) => (
						<>
							<Row style={styles.row} className="no-gutter">
								<FieldArray
									name={'siteSettings'}
									render={() => (
										<>
											{values.siteSettings
												.sort((a, b) => a.eventSettingType_Name.localeCompare(b.eventSettingType_Name))
												.map((siteSetting, index) => (
													<Col sm={6} key={siteSetting.siteSetting_Id} style={styles.formRow}>
														<SiteSettingField
															siteSetting={siteSetting}
															index={index}
															setFieldValue={setFieldValue}
															isDisabled={pageStatus === 'Submitting' || !hasEditPermissions}
															isReadOnly={false}
														/>
													</Col>
												))}
										</>
									)}
								/>
							</Row>
						</>
					)}
				</Formik>
			) : (
				<Row style={styles.row}>
					<Loading show={true} />
				</Row>
			)}
		</>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	row: {
		marginTop: '20px',
		justifyContent: 'space-between',
	},
	cellGrow: {
		flex: '1 1 auto',
	},
	formRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		marginBottom: '20px',
	},
}

export { SiteEventSettings }
export type { SiteSettingsData }
