import { genericSiteRequirement, genericRoleRequirement } from '../shared'

export const userPermissionObject = {
	GET: {
		PermissionsRequired: [genericSiteRequirement],
	},
	POST: {
		PermissionsRequired: [genericSiteRequirement, genericRoleRequirement],
	},
	PUT: {
		PermissionsRequired: [genericSiteRequirement, genericRoleRequirement],
	},
	DELETE: {
		PermissionsRequired: [genericSiteRequirement, genericRoleRequirement],
	},
}
