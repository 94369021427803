import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { PageStatus } from '../../../types/PageStatus'
import { Site, SiteResult } from '../../../models/Site'
import * as Request from '../../../utilities/request'
import Select from 'react-select'
import './SitePicker.css'
import { Loading } from '../Loading/Loading'

const SitePicker = () => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [sites, setSites] = React.useState<Site[] | null>(null)

	const options = sites?.map((site) => ({ value: site, label: site.site_Name })) || []

	React.useEffect(() => {
		const getData = async () => {
			const [sitesReq] = await Promise.all([Request.get<SiteResult>(`site`, context.appState.authState)])
			setSites(sitesReq.data.sites)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context.appState.currentSite])

	return pageStatus === 'Ready' ? (
		<Select
			className="site-picker"
			options={options}
			isOptionSelected={(option) => option.value?.site_Id === context.appState.currentSite?.site_Id}
			defaultValue={context.appState.currentSite ? options.find((option) => option.value?.site_Id === context.appState.currentSite?.site_Id) : options[0]}
			onChange={(e) => {
				context.setAppState({ state: 'setCurrentSite', data: { site: e?.value || null } })
			}}
			isSearchable={false}
			styles={{
				control: (base, state) => ({
					...base,
					background: state.menuIsOpen ? 'white' : 'var(--color-primary-cyan)',
					border: '1px black solid',
					fontFamily: 'Helvetica',
					fontSize: '16px',
					fontStyle: 'normal',
					fontWeight: '700',
					lineHeight: '24px',
					color: 'black',
					':hover': {
						border: '1px black solid',
						boxShadow: '3px 3px 4px #00000040',
					},
					':focus-within': {
						boxShadow: '3px 3px 4px #00000040 !important',
					},
				}),

				dropdownIndicator: (base) => ({
					...base,
					color: 'black',
					':hover': {
						color: 'black',
					},
				}),
				indicatorSeparator: () => ({
					display: 'none',
				}),
			}}
		/>
	) : (
		<Loading show />
	)
}

export { SitePicker }
