import React from 'react'
import './EditAsset.css'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { Button } from '../../Button/Button'
import { AssetGroupsModal } from './AssetGroupsModal'
import PermissionsCheck from '../../../Permissions/PermissionsCheck'

import { CommonComponentProps } from '../../../../screens/Asset/EditAssetDetails'
import { AssetGroup } from '../../../../models/AssetGroup'

import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../../utilities/permissions/permission.d'

import trash from '../../../../images/icons/trash.svg'

const AssetGroups = (props: CommonComponentProps) => {
	const [showModal, setShowModal] = React.useState<boolean>(false)

	const handleDeleteAssetGroup = async (assetGroupId: string) => {
		props.handleChange(
			'asset.asset_Groups',
			props.asset.asset_Groups.filter((ag) => ag.assetGroup_Id !== assetGroupId)
		)
	}

	const handleAddAssetGroup = async (assetGroup: AssetGroup) => {
		props.handleChange('asset.asset_Groups', props.asset.asset_Groups.concat(assetGroup))
	}

	return (
		<Card className="site-card" style={styles.card}>
			<AssetGroupsModal show={showModal} setShow={setShowModal} handleSubmit={handleAddAssetGroup} asset={props.asset} />

			<Row className="generic-card-list-heading-row">
				<Col>
					<span className="dashboard-card-titles">Groups</span>
				</Col>
				{/* Although this is creating/deleting an AssetGroup, not a Group, we use Group permissions */}
				<PermissionsCheck object={PermissionModelObject.Group} action={PermissionModelAction.POST} context={PermissionModelContext.Site}>
					<Col sm={'auto'}>
						<Button
							variant="secondary"
							onClick={() => {
								setShowModal(true)
							}}
						>
							Add Group
						</Button>
					</Col>
				</PermissionsCheck>
			</Row>
			<AssetGroupDisplay assetGroups={props.asset?.asset_Groups || []} handleDeleteAssetGroup={handleDeleteAssetGroup} />
		</Card>
	)
}

interface AssetGroupDisplayProps {
	assetGroups: AssetGroup[]
	handleDeleteAssetGroup: (assetGroupId: string) => Promise<void>
}

const AssetGroupDisplay = (props: AssetGroupDisplayProps) => {
	return (
		<Row style={styles.row}>
			{props.assetGroups.length > 0 ? (
				props.assetGroups.map((assetGroup) => (
					<Col sm={'auto'} key={assetGroup.assetGroup_Id} style={styles.col}>
						<AssetGroupCell assetGroup={assetGroup} handleDeleteAssetGroup={props.handleDeleteAssetGroup} />
					</Col>
				))
			) : (
				<span className="card-text">-</span>
			)}
		</Row>
	)
}

interface AssetGroupCellProps {
	assetGroup: AssetGroup
	handleDeleteAssetGroup: (assetGroupId: string) => Promise<void>
}

const AssetGroupCell = (props: AssetGroupCellProps) => {
	return (
		<Container fluid style={styles.assetGroupCell}>
			<Row>
				<Col>
					<Link to={`/groupSetting/${props.assetGroup.group_Id}`}>{props.assetGroup.group_Name}</Link>
				</Col>
				<PermissionsCheck object={PermissionModelObject.Group} action={PermissionModelAction.DELETE} context={PermissionModelContext.Site}>
					<Col sm={'auto'}>
						<img
							src={trash}
							alt={'delete icon'}
							className="generic-icon-button"
							onClick={() => props.handleDeleteAssetGroup(props.assetGroup.assetGroup_Id)}
						/>
					</Col>
				</PermissionsCheck>
			</Row>
			<Row>
				<Col>
					<span className="card-text">{props.assetGroup.groupType_Name}</span>
				</Col>
			</Row>
		</Container>
	)
}

const styles = {
	card: {
		minHeight: '120px',
	},
	row: {
		padding: '20px 20px 0px 20px',
	},
	col: {
		marginBottom: '20px',
	},
	assetGroupCell: {
		border: '1px solid #000000',
		width: '305px',
		minHeight: '88px',
		borderRadius: '8px',
		padding: '10px',
	},
}

export { AssetGroups }
