import React from 'react'
import './AssetInformation.css'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../../App'

import { Card, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Loading } from '../Loading/Loading'
import { Line } from '../Line/Line'
import { FileUpload } from '../FileUpload/FileUpload'

import { Asset, AssetList } from '../../../models/Asset'
import { PageStatus } from '../../../types/PageStatus'

import { defaultBatteryAssetGroup, defaultTemperatureAssetGroup } from '../../../models/AssetGroup'
import { calculateMonitored, calculateMonitoredBy, calculateServiced, calculateServicedBy } from '../../../models/AssetTree'
import { assetClass } from '../../../constants/assetClass'
import { groupType } from '../../../constants/groupType'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import { CardText } from '../Form/CardText'
import PermissionsCheck from '../../Permissions/PermissionsCheck'

import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../utilities/permissions/permission.d'

interface AssetInformationProps {
	pageStatus: PageStatus
	asset: Asset | null
}

const AssetInformation = (props: AssetInformationProps) => {
	const context = React.useContext(AppContext)
	const navigate = useNavigate()

	const servicedBy = React.useMemo(() => {
		return calculateServicedBy(props.asset)
	}, [props.asset?.asset_Serviced])

	const monitoredBy = React.useMemo(() => {
		return calculateMonitoredBy(props.asset)
	}, [props.asset?.asset_Serviced])

	const serviced = React.useMemo(() => {
		return calculateServiced(props.asset)
	}, [props.asset?.asset_Serviced])

	const monitored = React.useMemo(() => {
		return calculateMonitored(props.asset)
	}, [props.asset?.asset_Serviced])

	const assetGroups = React.useMemo(() => {
		const assetGroups = props.asset?.asset_Groups || []
		if (
			props.asset?.assetClass_Id === assetClass.Tmv.id &&
			!props.asset?.asset_Groups.some((assetGroup) => assetGroup.groupType_Id === groupType.Temperature.id)
		) {
			assetGroups.push(defaultTemperatureAssetGroup)
		}
		if (
			props.asset?.assetClass_Id === assetClass.Transmitter.id &&
			!props.asset?.asset_Groups.some((assetGroup) => assetGroup.groupType_Id === groupType.Battery.id)
		) {
			assetGroups.push(defaultBatteryAssetGroup)
		}
		return assetGroups
	}, [props.asset?.asset_Groups])

	return (
		<Card style={styles.card}>
			{props.pageStatus === 'Ready' && props.asset ? (
				<>
					<Row style={styles.row}>
						<Col sm="auto" style={styles.cellGrow}>
							<span className="dashboard-card-titles">Asset Information</span>
						</Col>
						<PermissionsCheck object={PermissionModelObject.Asset} action={PermissionModelAction.PUT} context={PermissionModelContext.Site}>
							<Col sm="auto">
								<Button onClick={() => navigate(`/editAsset/${props.asset?.asset_Id}`)}>EDIT ASSET</Button>
							</Col>
						</PermissionsCheck>
					</Row>
					<Line />
					<Row>
						<Col sm="9" style={styles.cellGrow}>
							<Row style={styles.row}>
								<Col style={styles.cell}>
									<span className="card-label">Asset Type</span>
									<span className="card-text">{props.asset.assetType_Name}</span>
								</Col>
								<Col style={styles.cell}>
									<span className="card-label">Serial Number</span>
									<CardText text={props.asset.asset_SerialNo} />
								</Col>
								<Col style={styles.cell}>
									<span className="card-label">Make</span>
									<CardText text={props.asset.assetMake_Name} link={`/assetMake/${props.asset.assetMake_Id}`} />
								</Col>
								<Col style={styles.cell}>
									<span className="card-label">Model</span>
									<CardText text={props.asset.assetModel_Name} link={`/assetModel/${props.asset.assetModel_Id}`} />
								</Col>
							</Row>

							<Row style={styles.row}>
								{(props.asset.assetClass_Id === assetClass.Tmv.id || props.asset.assetClass_Id === assetClass.Fixture.id) && (
									<>
										<PermissionsCheck
											object={PermissionModelObject.Contract}
											action={PermissionModelAction.GET}
											context={PermissionModelContext.None}
										>
											<>
												<Col style={styles.cell}>
													<span className="card-label">Contract</span>
													<CardText text={props.asset.contract_Name} link={`/contract/${props.asset.contract_Name}`} />
												</Col>
												<Col style={styles.cell}>
													<span className="card-label">Payment Agreement</span>
													<CardText text={props.asset.paymentAgreement_OrderNumber} link={`/contract/${props.asset.contract_Name}`} />
												</Col>
											</>
										</PermissionsCheck>
										<Col style={styles.cell}>
											<span className="card-label">Facility Asset Number</span>
											<CardText text={props.asset.asset_FacilityAssetNumber} />
										</Col>
										{props.asset.assetClass_Id === assetClass.Tmv.id ? (
											<Col style={styles.cell}>
												<span className="card-label">Rooms Serviced</span>
												<CardText text={props.asset.asset_RoomsServiced} />
											</Col>
										) : (
											<Col style={styles.cell} />
										)}
									</>
								)}
							</Row>

							{props.asset.assetClass_Id === assetClass.Network.id && (
								<>
									<Row style={styles.row}>
										<Col style={styles.cell}>
											<span className="card-label">COM Port</span>
											<CardText text={props.asset.serialPort_COMPort} />
										</Col>
										<Col style={styles.cell}>
											<span className="card-label">IP Address</span>
											<CardText text={props.asset.serialPort_IPAddress} />
										</Col>
										<Col style={styles.cell}>
											<span className="card-label">Facility Asset Number</span>
											<CardText text={props.asset.asset_FacilityAssetNumber} />
										</Col>
										<Col style={styles.cell}></Col>
									</Row>
								</>
							)}
							{props.asset.assetClass_Id === assetClass.Hub.id && (
								<>
									<Row style={styles.row}>
										<Col style={styles.cell}>
											<span className="card-label">Hardware Version</span>
											<CardText text={props.asset.assetTransmitter_HardwareVersion} />
										</Col>
										<Col style={styles.cell}>
											<span className="card-label">Software Version</span>
											<CardText text={props.asset.assetTransmitter_SoftwareVersion} />
										</Col>
										<Col style={styles.cell}>
											<span className="card-label">Hub Number</span>
											<CardText text={props.asset.hub_Number?.toString()} />
										</Col>
										<Col style={styles.cell}>
											<span className="card-label">Facility Asset Number</span>
											<CardText text={props.asset.asset_FacilityAssetNumber} />
										</Col>
									</Row>
								</>
							)}
							{props.asset.assetClass_Id === assetClass.Transmitter.id && (
								<>
									<Row style={styles.row}>
										<Col style={styles.cell}>
											<span className="card-label">Hardware Version</span>
											<CardText text={props.asset.assetTransmitter_HardwareVersion} />
										</Col>
										<Col style={styles.cell}>
											<span className="card-label">Software Version</span>
											<CardText text={props.asset.assetTransmitter_SoftwareVersion} />
										</Col>
										<Col style={styles.cell}>
											<span className="card-label">EUI</span>
											<CardText text={props.asset.assetTransmitter_EUI} />
										</Col>
										<Col style={styles.cell}>
											<span className="card-label">Transmitter Type</span>
											<CardText text={props.asset.assetTransmitter_Type} />
										</Col>
									</Row>
									<Row style={styles.row}>
										<Col style={styles.cell}>
											<span className="card-label">Facility Asset Number</span>
											<CardText text={props.asset.asset_FacilityAssetNumber} />
										</Col>
										<Col style={styles.cell}></Col>
										<Col style={styles.cell}></Col>
										<Col style={styles.cell}></Col>
									</Row>
								</>
							)}
							{(props.asset.assetClass_Id === assetClass.Tmv.id || props.asset.assetClass_Id === assetClass.Hub.id) && (
								<Row style={styles.row}>
									<Col style={styles.cell}>
										<span className="card-label">Bacnet Point</span>
										<CardText text={props.asset.asset_BacnetPoint?.toString()} />
									</Col>
								</Row>
							)}
							<Line />
							<Row style={styles.row}>
								<Col style={styles.cell}>
									<span className="card-label">Building</span>
									<CardText text={props.asset.locationBuilding_Name} link={`/locationBuilding/${props.asset.locationBuilding_Id}`} />
								</Col>
								<Col style={styles.cell}>
									<span className="card-label">Floor</span>
									<CardText text={props.asset.locationFloor_Name} link={`/locationFloor/${props.asset.locationFloor_Id}`} />
								</Col>
								<Col style={styles.cell}>
									<span className="card-label">Area</span>
									<CardText text={props.asset.locationArea_Name} link={`/locationArea/${props.asset.locationArea_Id}`} />
								</Col>
								<Col style={styles.cell}>
									<span className="card-label">Room</span>
									<CardText text={props.asset.locationRoom_Name} link={`/locationRoom/${props.asset.locationRoom_Id}`} />
								</Col>
							</Row>
							<Row style={styles.row}>
								<Col style={styles.cell}>
									<span className="card-label">Asset Location</span>
									<CardText text={props.asset.asset_LocationNote} />
								</Col>
							</Row>
							<Line />
							<Row style={styles.row}>
								<Col sm="8" style={styles.cell}>
									<span className="card-label">Serviced By</span>
									<Row>
										{servicedBy.length > 0 ? (
											servicedBy.map((assetTree) => (
												<Col style={styles.cell50} key={assetTree.assetTree_Id}>
													<AssetStubCell asset={assetTree.asset} />
												</Col>
											))
										) : (
											<CardText text={null} />
										)}
									</Row>
								</Col>
								<Col sm="2" style={styles.cell}>
									<span className="card-label">Monitored By</span>
									{monitoredBy.length > 0 ? (
										monitoredBy.map((assetTree) => <AssetStubCell key={assetTree.assetTree_Id} asset={assetTree.asset} />)
									) : (
										<CardText text={null} />
									)}
								</Col>
							</Row>
							<Line />
							<Row style={styles.row}>
								<Col sm="8" style={styles.cell}>
									<span className="card-label">Assets Serviced</span>
									<Row>
										{serviced.length > 0 ? (
											serviced.map((assetTree) => (
												<Col style={styles.cell50} key={assetTree.assetTree_Id}>
													<AssetStubCell asset={assetTree.asset} />
												</Col>
											))
										) : (
											<CardText text={null} />
										)}
									</Row>
								</Col>
								<Col sm="2" style={styles.cell}>
									<span className="card-label">Assets Monitored</span>
									{monitored.length > 0 ? (
										monitored.map((assetTree) => <AssetStubCell key={assetTree.assetTree_Id} asset={assetTree.asset} />)
									) : (
										<CardText text={null} />
									)}
								</Col>
							</Row>
							<Line />

							{assetGroups.length > 0 && (
								<>
									<Row style={styles.row}>
										{assetGroups.map((assetGroup) => (
											<Col sm={4} key={assetGroup.group_Id} style={styles.cell}>
												<span className="card-label">
													<Link to={`/groupSetting/${assetGroup.group_Id}`}>{assetGroup.group_Name}</Link>
												</span>
												<CardText text={assetGroup.groupType_Name} />
											</Col>
										))}
									</Row>
									<Line />
								</>
							)}
							<Row style={styles.row}>
								<Col style={styles.cell}>
									<span className="card-label">Installation Information</span>
									<span className="card-text">
										Installation Date:{' '}
										{props.asset.asset_InstallDate
											? formatIncomingDateTime({
													dateTime: props.asset.asset_InstallDate,
													format: 'Date',
													timeZone: context.appState.currentSite?.site_Timezone,
											  })
											: '-'}
									</span>
								</Col>
								<Col style={styles.cell}>
									<span className="card-label">Installation Notes</span>
									<CardText text={props.asset.asset_InstallNote} />
								</Col>
							</Row>
						</Col>
						<Col sm="3">
							<Card style={styles.uploadCard}>
								<FileUpload
									asset_Id={props.asset.asset_Id}
									assetMake_Id={props.asset.assetMake_Id}
									assetModel_Id={props.asset.assetModel_Id}
									locationFloor_Id={props.asset.locationFloor_Id}
									locationBuilding_Id={props.asset.locationBuilding_Id}
									locationArea_Id={props.asset.locationArea_Id}
									locationRoom_Id={props.asset.locationRoom_Id}
									readOnly={true}
									styles={{ uploaderContainerStyle: styles.uploaderContainer }}
								/>
							</Card>
						</Col>
					</Row>
				</>
			) : props.pageStatus === 'Loading' ? (
				<Loading show={true} />
			) : null}
		</Card>
	)
}

const AssetStubCell = (props: { asset: AssetList }) => {
	return (
		<>
			<span className="card-text">
				<Link to={`/asset/${props.asset.asset_Id}`}>
					{props.asset.asset_Name} - {props.asset.assetType_Name}
				</Link>
			</span>
			<span className="card-text">
				{props.asset.locationFloor_Name} - {props.asset.locationRoom_Name}
				{props.asset.asset_LocationNote ? ` - ${props.asset.asset_LocationNote}` : ''}
			</span>
		</>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	card: {
		marginTop: '20px',
		minHeight: '745px',
	},
	row: {
		marginTop: '20px',
		marginBottom: '20px',
	},
	cellGrow: {
		flex: '1 1 auto',
	},
	cell: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '48px',
	},
	cell50: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '48px',
		flexBasis: '50%',
	},
	cell33: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '48px',
		flexBasis: '33%',
	},
	uploadCard: {
		minHeight: '600px',
		maxHeight: '800px',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '30px',
	},
	uploaderContainer: {
		flexDirection: 'row',
	},
}

export { AssetInformation }
