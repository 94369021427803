import React from 'react'

import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { useNavigate, useParams } from 'react-router-dom'

import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { Information } from '../../components/UI/Information/Information'

import { AssetMake as IAssetMake, AssetMakeResult } from '../../models/AssetMake'
import { PageStatus } from '../../types/PageStatus'

import { PermissionModelObject } from '../../utilities/permissions/permission.d'

const AssetMake = () => {
	const context = React.useContext(AppContext)
	const navigate = useNavigate()
	const id = useParams().id || ''

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [messages, setMessages] = useMessageReducer([])

	const [assetMake, setAssetMake] = React.useState<IAssetMake | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const assetMakeReq = await Request.get<AssetMakeResult>(`assetMake?Id=${id}`, context.appState.authState)
			if (assetMakeReq.data.assetMakes.length > 0) {
				setAssetMake(assetMakeReq.data.assetMakes[0])
				setPageStatus('Ready')
			} else {
				setPageStatus('Error')
			}
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [id])

	const handleSave = async (values: { name: string; note: string }) => {
		setPageStatus('Submitting')
		await Request.handleRequest(
			() =>
				Request.put<AssetMakeResult>(
					'assetMake',
					{ ...assetMake, assetMake_Name: values.name, assetMake_Note: values.note },
					context.appState.authState
				),
			{
				successFunction: (data) => {
					setAssetMake(data.assetMakes[0])
					setPageStatus('Ready')
				},
				failedFunction: () => {
					setPageStatus('Error')
				},
				setMessageFunction: setMessages,
				messageAction: 'editing',
				messageObject: 'asset make',
			}
		)
	}

	const handleDelete = async () => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.del<AssetMakeResult>(`assetMake?Id=${assetMake?.assetMake_Id}`, context.appState.authState), {
			successFunction: () => {
				setTimeout(() => navigate(-1), 500)
			},
			failedFunction: () => {
				setPageStatus('Error')
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'asset make',
		})
	}

	return (
		<div className="page-container">
			<Messages messages={messages} updateMessage={setMessages} />
			<Information
				pageStatus={pageStatus}
				name={assetMake?.assetMake_Name || null}
				note={assetMake?.assetMake_Note || null}
				customDeleteMessage={'Are you sure you want to delete this make? All of its models, plus any associated documents, will also be deleted.'}
				fileUploadProps={{ assetMake_Id: id }}
				assetOverviewListingProps={{ filter: `assetMake_Id=${id}` }}
				permissionModelObject={PermissionModelObject.AssetMake}
				handleSave={handleSave}
				handleDelete={handleDelete}
			/>
		</div>
	)
}

export { AssetMake }
