import React from 'react'
import { Container, Card, Row, Col } from 'react-bootstrap'
import { Frown } from 'react-feather'
import { Link } from 'react-router-dom'

interface ErrorProps {
	showHomeButton?: boolean
}

const Error = (props: ErrorProps) => {
	return (
		<Container>
			<Card style={styles.card}>
				<Row style={styles.row}>
					<Col>
						<Frown />
					</Col>
				</Row>
				<Row>
					<Col>
						<h1>Ooops! We&apos;re sorry, we couldn&apos;t find what you&apos;re looking for.</h1>
					</Col>
				</Row>
				{props.showHomeButton && (
					<Row>
						<Col>
							<Link to={'/'} className="btn btn-primary">
								Return home
							</Link>
						</Col>
					</Row>
				)}
			</Card>
		</Container>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	card: {
		backgroundColor: 'white',
		marginTop: '20%',
		textAlign: 'center',
	},
	row: {
		marginBottom: '20px',
	},
}

export default Error
