import { Asset } from '../../../models/Asset'
import { AssetMake } from '../../../models/AssetMake'
import { AssetModel } from '../../../models/AssetModel'
import { LocationBuilding } from '../../../models/LocationBuilding'
import { LocationFloor } from '../../../models/LocationFloor'
import { LocationArea } from '../../../models/LocationArea'
import { LocationRoom } from '../../../models/LocationRoom'
import { PaymentAgreement } from '../../../models/PaymentAgreement'

export type ImportStatus = 'Ready' | 'InProgress' | 'Finished' | 'Error'

export interface AssetImportFiles {
	serialPort: FileData
	gateway: FileData
	tmv: FileData
	rfCompactTransmitter: FileData
	rfMultiTransmitter: FileData
	sfmStandardHub: FileData
	sfmTfpHub: FileData
	fixtures: FileData
}

export interface FileData {
	label: string
	file: File | null
	templateFields: string[]
	userFeedback: ImportUserFeedback | null
}

export interface ImportUserFeedback {
	inProgress: boolean
	totalComplete: number
	totalCount: number
	errors: string[]
}

export interface AuxiliaryData {
	assets: Asset[]
	assetMakes: AssetMake[]
	assetModels: AssetModel[]
	locationBuildings: LocationBuilding[]
	locationFloors: LocationFloor[]
	locationAreas: LocationArea[]
	locationRooms: LocationRoom[]
	paymentAgreements: PaymentAgreement[]
}

export interface ImportFileRow {
	Type: string
	Name: string
	SerialNumber: string
	FacilityNumber: string
	InstallDate: string
	Building: string
	Floor: string
	Area: string
	Room: string
	LocationNote: string
}

export interface FixtureImportFileRow extends ImportFileRow {
	Make: string
	Model: string
}

export interface TmvImportFileRow extends ImportFileRow {
	Make: string
	Model: string
	AssetsServiced: string
	PaymentAgreement: string
	SF_Id: string
	SF_Site_Id: string
	SF_BacnetPoint: number
	RoomsServiced: string
}

export interface SfmStandardHubImportFileRow extends ImportFileRow {
	HubNumber: number
	AssetsMonitored: string
	'P1 ASSET': string
	P1T: string
	'P2 ASSET': string
	P2T: string
	'P3 ASSET': string
	P3T: string
	'P4 ASSET': string
	P4T: string
	'P5 ASSET': string
	P5T: string
	'P6 ASSET': string
	P6T: string
	'P7 ASSET': string
	P7T: string
	'P8 ASSET': string
	P8T: string
	SF_BacnetPoint: number
}

export interface SfmTfpHubImportFileRow extends ImportFileRow {
	HubNumber: number
	AssetsMonitored: string
	'P1 ASSET': string
	P1CH0F: string
	P1CH0T: string
	P1CH1F: string
	P1CH1T: string
	P1CH2F: string
	P1CH2T: string
	'P2 ASSET': string
	P2CH0F: string
	P2CH0T: string
	P2CH1F: string
	P2CH1T: string
	P2CH2F: string
	P2CH2T: string
	'P3 ASSET': string
	P3CH0F: string
	P3CH0T: string
	P3CH1F: string
	P3CH1T: string
	P3CH2F: string
	P3CH2T: string
	'P4 ASSET': string
	P4CH0F: string
	P4CH0T: string
	P4CH1F: string
	P4CH1T: string
	P4CH2F: string
	P4CH2T: string
	'P5 ASSET': string
	P5CH0F: string
	P5CH0T: string
	P5CH1F: string
	P5CH1T: string
	P5CH2F: string
	P5CH2T: string
	'P6 ASSET': string
	P6CH0P: string
	P6CH1P: string
}

export interface RfCompactTransmitterFileRow extends ImportFileRow {
	EUI: string
	HardwareVersion: string
	SoftwareVersion: string
	AssetsMonitored: string
	'P1 ASSET': string
	CH0F: string
	CH0T: string
}

export interface RfMultiTransmitterFileRow extends ImportFileRow {
	EUI: string
	HardwareVersion: string
	SoftwareVersion: string
	AssetsMonitored: string
	'P1 ASSET': string
	CH0F: string
	CH0T: string
	CH1F: string
	CH1T: string
	CH2F: string
	CH2T: string
}

export interface SerialPortImportFileRow extends ImportFileRow {
	'IP Address': string
	'COM Port': string
	AssetsMonitored: string
}

export interface GatewayImportFileRow extends ImportFileRow {
	'IP Address': string
	EUI: string
	HardwareVersion: string
	SoftwareVersion: string
	AssetsMonitored: string
}

export interface ChannelMapFromFile {
	fileHeader: string // only used to display errors messages in a helpful way
	portNumber: number
	assetName: string
	subChannelNumber: number
	readingTypeId: string
}

export const defaultFixtureImport: FixtureImportFileRow = {
	Type: '',
	Name: '',
	SerialNumber: '',
	FacilityNumber: '',
	InstallDate: '',
	Make: '',
	Model: '',
	Building: '',
	Floor: '',
	Area: '',
	Room: '',
	LocationNote: '',
}

export const defaultTmvImport: TmvImportFileRow = {
	Type: '',
	Name: '',
	SerialNumber: '',
	FacilityNumber: '',
	RoomsServiced: '',
	InstallDate: '',
	Make: '',
	Model: '',
	Building: '',
	Floor: '',
	Area: '',
	Room: '',
	LocationNote: '',
	AssetsServiced: '',
	PaymentAgreement: '',
	SF_Id: '',
	SF_Site_Id: '',
	SF_BacnetPoint: '',
}

export const defaultSfmStandardImport: SfmStandardHubImportFileRow = {
	Type: '',
	Name: '',
	SerialNumber: '',
	FacilityNumber: '',
	InstallDate: '',
	HubNumber: 0,
	Make: '',
	Model: '',
	Building: '',
	Floor: '',
	Area: '',
	Room: '',
	LocationNote: '',
	AssetsMonitored: '',
	'P1 ASSET': '',
	P1T: '',
	'P2 ASSET': '',
	P2T: '',
	'P3 ASSET': '',
	P3T: '',
	'P4 ASSET': '',
	P4T: '',
	'P5 ASSET': '',
	P5T: '',
	'P6 ASSET': '',
	P6T: '',
	'P7 ASSET': '',
	P7T: '',
	'P8 ASSET': '',
	P8T: '',
	SF_BacnetPoint: '',
}

export const defaultSfmTfpImport: SfmTfpHubImportFileRow = {
	Type: '',
	Name: '',
	SerialNumber: '',
	FacilityNumber: '',
	InstallDate: '',
	HubNumber: 0,
	Make: '',
	Model: '',
	Building: '',
	Floor: '',
	Area: '',
	Room: '',
	LocationNote: '',
	AssetsMonitored: '',
	'P1 ASSET': '',
	P1CH0F: '',
	P1CH0T: '',
	P1CH1F: '',
	P1CH1T: '',
	P1CH2F: '',
	P1CH2T: '',
	'P2 ASSET': '',
	P2CH0F: '',
	P2CH0T: '',
	P2CH1F: '',
	P2CH1T: '',
	P2CH2F: '',
	P2CH2T: '',
	'P3 ASSET': '',
	P3CH0F: '',
	P3CH0T: '',
	P3CH1F: '',
	P3CH1T: '',
	P3CH2F: '',
	P3CH2T: '',
	'P4 ASSET': '',
	P4CH0F: '',
	P4CH0T: '',
	P4CH1F: '',
	P4CH1T: '',
	P4CH2F: '',
	P4CH2T: '',
	'P5 ASSET': '',
	P5CH0F: '',
	P5CH0T: '',
	P5CH1F: '',
	P5CH1T: '',
	P5CH2F: '',
	P5CH2T: '',
	'P6 ASSET': '',
	P6CH0P: '',
	P6CH1P: '',
}

export const defaultRfCompactImport: RfCompactTransmitterFileRow = {
	Type: '',
	Name: '',
	SerialNumber: '',
	FacilityNumber: '',
	InstallDate: '',
	EUI: '',
	HardwareVersion: '',
	SoftwareVersion: '',
	Building: '',
	Floor: '',
	Area: '',
	Room: '',
	LocationNote: '',
	AssetsMonitored: '',
	'P1 ASSET': '',
	CH0F: '',
	CH0T: '',
}

export const defaultRfMultiImport: RfCMultiTransmitterFileRow = {
	Type: '',
	Name: '',
	SerialNumber: '',
	FacilityNumber: '',
	InstallDate: '',
	EUI: '',
	HardwareVersion: '',
	SoftwareVersion: '',
	Building: '',
	Floor: '',
	Area: '',
	Room: '',
	LocationNote: '',
	AssetsMonitored: '',
	'P1 ASSET': '',
	CH0F: '',
	CH0T: '',
	CH1F: '',
	CH1T: '',
	CH2F: '',
	CH2T: '',
}

export const defaultGatewayImport: GatewayImportFileRow = {
	Type: '',
	Name: '',
	SerialNumber: '',
	FacilityNumber: '',
	InstallDate: '',
	'IP Address': '',
	EUI: '',
	HardwareVersion: '',
	SoftwareVersion: '',
	Building: '',
	Floor: '',
	Area: '',
	Room: '',
	LocationNote: '',
	AssetsMonitored: '',
}

export const defaultSerialPortImport: SerialPortImportFileRow = {
	Type: '',
	Name: '',
	SerialNumber: '',
	FacilityNumber: '',
	InstallDate: '',
	'IP Address': '',
	'COM Port': '',
	Building: '',
	Floor: '',
	Area: '',
	Room: '',
	LocationNote: '',
	AssetsMonitored: '',
}
