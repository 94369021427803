export const TrendsApiType = {
	MONTHLY_READING: { value: 'monthlyReading', label: 'Monthly Reading' },
	TEMP_EXCEPTION_EVENTS: { value: 'temperatureExceptionEvents', label: 'Temperature Exception Events' },
	TEMP_EXCEPTION_EVENTS_LOW_COUNT: { value: 'temperatureExceptionEventsLowCount', label: 'Temperature Exception Events Low Count' },
	TEMP_EXCEPTION_VOLUME: { value: 'temperatureExceptionVolume', label: 'Temperature Exception Volume' },
	CONSECUTIVE_FLOW: { value: 'consecutiveFlow', label: 'Consecutive Flow' },
	FLOW_MONTHLY_RATE: { value: 'flowMonthlyRate', label: 'Flow Monthly Rate' },
} as const

export type TrendsApiType = (typeof TrendsApiType)[keyof typeof TrendsApiType]

export const TrendsApiTypeSelectOptions = Object.values(TrendsApiType)
