import React from 'react'
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { FormRange, FormRangeProps } from '../Form/Range'
import { FormSwitch } from '../Form/Switch'

import { EventSetting, defaultEventSettingUnitsByType, defaultEventSettingTooltipType } from '../../../models/EventSetting'
import { groupType } from '../../../constants/groupType'

import readings from '../../../images/icons/readings.svg'
import voltage from '../../../images/icons/voltage.svg'

const GroupTypeIcon = (props: { groupTypeId: string }) => {
	switch (props.groupTypeId) {
		case groupType.Temperature.id:
			return <img src={readings} alt="Temperature Icon" style={styles.icon} />
		case groupType.Battery.id:
			return <img src={voltage} alt="Voltage Icon" style={styles.icon} />
		default:
			return null
	}
}

interface EventSettingFieldProps {
	eventSetting: EventSetting
	index: number
	isDisabled: boolean
	isReadOnly: boolean
	formRangeProps?: Partial<FormRangeProps>
	setFieldValue: (field: string, value: unknown) => void
}

const EventSettingField = (props: EventSettingFieldProps) => {
	return (
		<>
			<OverlayTrigger
				placement="top"
				overlay={
					<Tooltip id={`toolTip_${props.eventSetting.eventSetting_Value}`}>
						{defaultEventSettingTooltipType[props.eventSetting.eventSettingType_Name]}
					</Tooltip>
				}
			>
				<Col sm="auto" style={styles.col}>
					<GroupTypeIcon groupTypeId={props.eventSetting.groupType_Id} />
				</Col>
			</OverlayTrigger>
			<Col sm={8} style={styles.col}>
				<FormRange
					name={`eventSettings[${props.index}].eventSetting_Value`}
					value={props.eventSetting.eventSetting_Value}
					onChange={(e) => props.setFieldValue(`eventSettings[${props.index}].eventSetting_Value`, Number(e.target.value))}
					setFieldValue={props.setFieldValue}
					label={props.eventSetting.eventSettingType_Name}
					min={0}
					max={100}
					unit={defaultEventSettingUnitsByType[props.eventSetting.eventSettingType_Name] || ''}
					disabled={props.isDisabled || !props.eventSetting.eventSetting_IsEnabled}
					readOnly={props.isReadOnly}
					{...props.formRangeProps}
				/>
			</Col>
			<Col sm={3} style={styles.col}>
				<FormSwitch
					name={`eventSettings[${props.index}].eventSetting_IsEnabled`}
					switchLabel={props.eventSetting.eventSetting_IsEnabled ? 'Enabled' : 'Disabled'}
					checked={props.eventSetting.eventSetting_IsEnabled}
					onChange={(e) => props.setFieldValue(`eventSettings[${props.index}].eventSetting_IsEnabled`, e.target.checked)}
					disabled={props.isDisabled}
					readOnly={props.isReadOnly}
				/>
			</Col>
		</>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	col: {
		marginRight: '20px',
	},
	icon: {
		width: '50px',
		height: '50px',
		marginRight: '20px',
	},
}

export { EventSettingField }
