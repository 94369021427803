export const SeverityValues = ['Critical', 'High', 'Medium', 'Low', 'Info'] as const
export type Severity = (typeof SeverityValues)[number]

export const SeverityIdValues = [
	'critical-0000-0000-0000-000000000000',
	'high0000-0000-0000-0000-000000000000',
	'medium00-0000-0000-0000-000000000000',
	'low00000-0000-0000-0000-000000000000',
	'info0000-0000-0000-0000-000000000000',
] as const
export type SeverityId = (typeof SeverityIdValues)[number]

export const severity: Record<Severity, { id: SeverityId }> = {
	Critical: { id: 'critical-0000-0000-0000-000000000000' },
	High: { id: 'high0000-0000-0000-0000-000000000000' },
	Medium: { id: 'medium00-0000-0000-0000-000000000000' },
	Low: { id: 'low00000-0000-0000-0000-000000000000' },
	Info: { id: 'info0000-0000-0000-0000-000000000000' },
}

export const severityDropdownValues = SeverityValues.map((value) => ({
	value: severity[value].id,
	label: value,
}))
