import { GroupType } from './groupType'

export const EventSettingTypeValues = [
	'Temperature Exception High',
	'Set Temperature',
	'Mixed Filter Temperature',
	'Monthly Reading Duration',
	'Minimum Monthly Reading Temperature',
	'Maximum Monthly Reading Temperature',
	'Flow Threshold Temperature',
	'Flow Threshold Minutes',
	'Low Battery',
	'Battery Replaced',
	'Offline',
	'Error',
	'Thermal Flush',
	'TMV Flow Metric Days',
	'Fixture Flow Metric Days',
] as const
export type EventSettingType = (typeof EventSettingTypeValues)[number]

export const EventSettingTypeIdValues = [
	'tempHigh-0000-0000-0000-000000000000',
	'tempSet0-0000-0000-0000-000000000000',
	'battLow0-0000-0000-0000-000000000000',
	'battRepl-0000-0000-0000-000000000000',
	'tempFilt-mix0-0000-0000-000000000000',
	'tempMont-0000-0000-0000-000000000000',
	'tempMont-min0-0000-0000-000000000000',
	'tempMont-max0-0000-0000-000000000000',
	'tempFlow-0000-0000-0000-000000000000',
	'tempDail-0000-0000-0000-000000000000',
	'offline0-0000-0000-0000-000000000000',
	'errorEve-0000-0000-0000-000000000000',
	'thermalf-0000-0000-0000-000000000000',
	'tmvFlowM-0000-0000-0000-000000000000',
	'fixFlowM-0000-0000-0000-000000000000',
] as const
export type EventSettingTypeId = (typeof EventSettingTypeIdValues)[number]

export const eventSettingType: Record<EventSettingType, { id: EventSettingTypeId; groupType: GroupType | null }> = {
	'Temperature Exception High': { id: 'tempHigh-0000-0000-0000-000000000000', groupType: 'Temperature' },
	'Set Temperature': { id: 'tempSet0-0000-0000-0000-000000000000', groupType: 'Temperature' },
	'Mixed Filter Temperature': { id: 'tempFilt-mix0-0000-0000-000000000000', groupType: 'Temperature' },
	'Monthly Reading Duration': { id: 'tempMont-0000-0000-0000-000000000000', groupType: 'Temperature' },
	'Minimum Monthly Reading Temperature': { id: 'tempMont-min0-0000-0000-000000000000', groupType: 'Temperature' },
	'Maximum Monthly Reading Temperature': { id: 'tempMont-max0-0000-0000-000000000000', groupType: 'Temperature' },
	'Flow Threshold Temperature': { id: 'tempFlow-0000-0000-0000-000000000000', groupType: 'Temperature' },
	'Flow Threshold Minutes': { id: 'tempDail-0000-0000-0000-000000000000', groupType: 'Temperature' },
	'Low Battery': { id: 'battLow0-0000-0000-0000-000000000000', groupType: 'Battery' },
	'Battery Replaced': { id: 'battRepl-0000-0000-0000-000000000000', groupType: null },
	Offline: { id: 'offline0-0000-0000-0000-000000000000', groupType: null },
	Error: { id: 'errorEve-0000-0000-0000-000000000000', groupType: null },
	'Thermal Flush': { id: 'thermalf-0000-0000-0000-000000000000', groupType: null },
	'TMV Flow Metric Days': { id: 'tmvFlowM-0000-0000-0000-000000000000', groupType: null },
	'Fixture Flow Metric Days': { id: 'fixFlowM-0000-0000-0000-000000000000', groupType: null },
}

export const eventSettingTypeDropdownValues = EventSettingTypeValues.map((value) => ({
	value: eventSettingType[value].id,
	label: value,
}))
