import './App.css'
import React, { useReducer, Reducer, useContext } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate, useParams, useSearchParams } from 'react-router-dom'
import { AppState, AppStateAction } from './App.d'

import { Chart, registerables } from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom'
import { DoughnutCenterText } from './components/UI/Graphs/DoughnutCenterText'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import 'bootstrap/dist/css/bootstrap.min.css'

Chart.register(...registerables)
Chart.register(zoomPlugin)
Chart.register(DoughnutCenterText)
Chart.register(ChartDataLabels)

import { SiteDashboard } from './screens/Site/SiteDashboard'
import { SiteDetails } from './screens/Site/SiteDetails'
import { ErrorBoundary } from './screens/ErrorBoundary/ErrorBoundary'
import { PageNotFound } from './screens/PageNotFound/PageNotFound'
import { PageWrapper } from './screens/PageWrapper/PageWrapper'
import { AuthComponent } from './components/Login/AuthComponent/AuthComponent'
import { AuthComponentOffline } from './components/Login/AuthComponent/AuthComponentOffline'
import { ScreensLogin } from './screens/Login/Login'
import { ScreensUserManagement } from './screens/UserManagement/UserManagement'
import { AssetOverview } from './screens/Asset/AssetOverview'
import { EditSiteGroup } from './screens/SiteGroup/EditSiteGroup'
import { defaultUser } from './models/User'
import { AssetDetails } from './screens/Asset/AssetDetails'
import { EditAssetDetails } from './screens/Asset/EditAssetDetails'
import { AssetMake } from './screens/AssetMake/AssetMake'
import { AssetModel } from './screens/AssetModel/AssetModel'
import { LocationBuilding } from './screens/LocationBuilding/LocationBuilding'
import { LocationFloor } from './screens/LocationFloor/LocationFloor'
import { LocationArea } from './screens/LocationArea/LocationArea'
import { LocationRoom } from './screens/LocationRoom/LocationRoom'
import { SiteSettings } from './screens/Site/SiteSettings'
import { SiteGroupDetails } from './screens/SiteGroup/SiteGroupDetails'
import { TaskOverview } from './screens/Task/TaskOverview'
import { TaskDetails } from './screens/Task/TaskDetails'
import { GroupDetails } from './screens/Group/GroupDetails'
import LiveTemperatures from './screens/LiveTemperatures/LiveTemperatures'
import MonthlyReadingRender from './screens/Reports/ReportRenders/MonthlyReadingRender'
import Reports from './screens/Reports/Reports'
import { ScreensUserManagementSite } from './screens/UserManagement/UserManagementSite'
import { SiteImport } from './screens/Settings/SiteImport'
import TemperatureExceptionRender from './screens/Reports/ReportRenders/TemperatureExceptionRender'
import TempExceptionHistoryRender from './screens/Reports/ReportRenders/TempExceptionHistoryRender'
import { ScreensTaskDashboard } from './screens/TaskDashboard/TaskDashboard'
import FlowRender from './screens/Reports/ReportRenders/FlowRender'
import ConsecutiveFlowRender from './screens/Reports/ReportRenders/ConsecutiveFlowRender'
import ThermalFlushRender from './screens/Reports/ReportRenders/ThermalFlushRender'
import Trends from './screens/Trends/Trends'
import FullReportArchive from './screens/Reports/FullReportArchive'
import FleetHealthRender from './screens/Reports/ReportRenders/FleetHealthRender'
import { ContractDashboard } from './screens/Contracts/ContractDashboard'
import ContractWizard from './screens/Contracts/ContractWizard'
import TemperatureExceptionDetailRender from './screens/Reports/ReportRenders/TemperatureExceptionDetailRender'
import { ContractDetails } from './screens/Contracts/ContractDetails'
import { CurrentStatus } from './screens/CurrentStatus/CurrentStatus'

const initialState: AppState = {
	authState: {
		isLoggedIn: false,
		apiToken: null,
		user_AzureId: null,
	},
	userAttributes: defaultUser({}),
	userSites: [],
	currentSite: localStorage.getItem('currentSite') ? JSON.parse(localStorage.getItem('currentSite') as string) : null,
	currentSiteGroup: localStorage.getItem('currentSiteGroup') ? JSON.parse(localStorage.getItem('currentSiteGroup') as string) : null,
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const AppContext = React.createContext<{ appState: AppState; setAppState: React.Dispatch<AppStateAction> }>({ appState: initialState, setAppState: () => {} })

type ProtectedRouteProps = {
	isAuthenticated: boolean
	authenticationPath: string
	outlet: JSX.Element
	outletPath: string | null
	noWrapper?: boolean
}

const ProtectedRoute = ({ isAuthenticated, authenticationPath, outlet, outletPath, noWrapper }: ProtectedRouteProps) => {
	const params = useParams()
	if (Object.keys(params).length !== 0) {
		outletPath = `${outletPath}/${params[Object.keys(params)[0]]}`
	}

	const [searchParams] = useSearchParams()
	const searchParamsString = searchParams.toString()

	if (isAuthenticated) {
		if (noWrapper) {
			return outlet
		}
		return <PageWrapper renderPage={outlet} />
	} else {
		return (
			<Navigate
				to={{ pathname: authenticationPath, search: `?redirect=${outletPath}${searchParamsString.length > 0 ? `&${searchParamsString}` : ''}` }}
			/>
		)
	}
}

const AllRoutes = () => {
	const context = useContext(AppContext)
	const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet' | 'outletPath'> = {
		isAuthenticated: !!context.appState.authState.isLoggedIn,
		authenticationPath: '/login',
	}

	return (
		<Routes>
			<Route path="/" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SiteDashboard />} outletPath="" />} />
			<Route path="/siteImport" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SiteImport />} outletPath="/siteImport" />} />
			<Route path="/site" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SiteDetails />} outletPath="/site" />} />
			<Route path="/siteSettings" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SiteSettings />} outletPath="/siteSettings" />} />
			<Route path="/siteGroup" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SiteGroupDetails />} outletPath="/siteGroup" />} />
			<Route
				path="/editSiteGroup/:siteGroup_Id"
				element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<EditSiteGroup />} outletPath="/editSiteGroup" />}
			/>
			<Route path="/assets" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<AssetOverview />} outletPath="/assets" />} />
			<Route path="/asset/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<AssetDetails />} outletPath="/asset" />} />
			<Route path="/editAsset/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<EditAssetDetails />} outletPath="/editAsset" />} />
			<Route path="/assetMake/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<AssetMake />} outletPath="/assetMake" />} />
			<Route path="/assetModel/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<AssetModel />} outletPath="/assetModel" />} />
			<Route
				path="/locationBuilding/:id"
				element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<LocationBuilding />} outletPath="/locationBuilding" />}
			/>
			<Route
				path="/locationFloor/:id"
				element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<LocationFloor />} outletPath="/locationFloor" />}
			/>
			<Route path="/locationArea/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<LocationArea />} outletPath="/locationArea" />} />
			<Route path="/locationRoom/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<LocationRoom />} outletPath="/locationRoom" />} />
			<Route path="/tasks" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<TaskOverview />} outletPath="/tasks" />} />
			<Route path="/task/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<TaskDetails />} outletPath="/task" />} />
			<Route
				path="/userManagement"
				element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ScreensUserManagement />} outletPath="/userManagement" />}
			/>
			<Route
				path="/userManagementSite"
				element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ScreensUserManagementSite />} outletPath="/userManagementSite" />}
			/>
			<Route
				path="/taskDashboard"
				element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ScreensTaskDashboard />} outletPath="/taskDashboard" />}
			/>
			<Route path="/groupSetting/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<GroupDetails />} outletPath="/groupSetting" />} />
			<Route
				path="/liveTemperatures"
				element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<LiveTemperatures />} outletPath="/liveTemperatures" />}
			/>
			<Route path="/reports" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Reports />} outletPath="/reports" />} />
			<Route path="/reportRenders">
				<Route
					path="monthlyReading"
					element={
						<ProtectedRoute
							{...defaultProtectedRouteProps}
							outlet={<MonthlyReadingRender />}
							outletPath="/reportRenders/monthlyReading"
							noWrapper
						/>
					}
				/>
				<Route
					path="tempExceptions"
					element={
						<ProtectedRoute
							{...defaultProtectedRouteProps}
							outlet={<TemperatureExceptionRender />}
							outletPath="/reportRenders/tempExceptions"
							noWrapper
						/>
					}
				/>
				<Route
					path="tempExceptionDetail"
					element={
						<ProtectedRoute
							{...defaultProtectedRouteProps}
							outlet={<TemperatureExceptionDetailRender />}
							outletPath="/reportRenders/tempExceptionDetail"
							noWrapper
						/>
					}
				/>
				<Route
					path="tempExceptionHistory"
					element={
						<ProtectedRoute
							{...defaultProtectedRouteProps}
							outlet={<TempExceptionHistoryRender />}
							outletPath="/reportRenders/tempExceptionHistory"
							noWrapper
						/>
					}
				/>
				<Route
					path="flow"
					element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<FlowRender />} outletPath="/reportRenders/flow" noWrapper />}
				/>
				<Route
					path="consecutiveFlow"
					element={
						<ProtectedRoute
							{...defaultProtectedRouteProps}
							outlet={<ConsecutiveFlowRender />}
							outletPath="/reportRenders/consecutiveFlow"
							noWrapper
						/>
					}
				/>
				<Route
					path="thermalFlush"
					element={
						<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ThermalFlushRender />} outletPath="/reportRenders/thermalFlush" noWrapper />
					}
				/>
				<Route
					path="fleetHealth"
					element={
						<ProtectedRoute {...defaultProtectedRouteProps} outlet={<FleetHealthRender />} outletPath="/reportRenders/fleetHealth" noWrapper />
					}
				/>
			</Route>
			<Route path="/trends" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Trends />} outletPath="/trends" />} />
			<Route
				path="/reportArchive"
				element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<FullReportArchive />} outletPath="/reportArchive" />}
			/>
			<Route path="/contracts" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ContractDashboard />} outletPath="/contracts" />} />
			<Route path="/contract/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ContractDetails />} outletPath="/contract" />} />
			<Route
				path="/provisionContract"
				element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ContractWizard />} outletPath="/provisionContract" />}
			/>
			<Route path="/currentStatus" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<CurrentStatus />} outletPath="/currentStatus" />} />
			<Route path="/login" element={<ScreensLogin />} />
			<Route path="*" element={<ErrorBoundaryWrapper />} />
		</Routes>
	)
}

const appReducer: React.Reducer<AppState, AppStateAction> = (state, action) => {
	switch (action.state) {
		case 'signedIn':
			return {
				...state,
				authState: {
					isLoggedIn: true,
					apiToken: action.data.accessToken,
					user_AzureId: action.data.userAttributes.user_AzureId,
				},
				userAttributes: action.data.userAttributes,
				userSites: action.data.userSites,
			}
		case 'signOut':
			return {
				...initialState,
			}
		case 'setCurrentSite':
			if (action.data.site) {
				localStorage.setItem('currentSite', JSON.stringify(action.data.site))
			} else {
				localStorage.removeItem('currentSite')
			}
			return {
				...state,
				currentSite: action.data.site,
			}
		case 'setCurrentSiteGroup':
			localStorage.removeItem('currentSite')
			if (action.data.siteGroup) {
				localStorage.setItem('currentSiteGroup', JSON.stringify(action.data.siteGroup))
			} else {
				localStorage.removeItem('currentSiteGroup')
			}
			return {
				...state,
				currentSite: null,
				currentSiteGroup: action.data.siteGroup,
			}
		default:
			return state
	}
}

function App() {
	const [appState, setAppState] = useReducer<Reducer<AppState, AppStateAction>>(appReducer, initialState)

	return (
		<AppContext.Provider value={{ appState, setAppState }}>
			<Routes>
				<Route>
					<Route path="*" element={process.env.REACT_APP_IsLocalMode === 'true' ? <AuthComponentOffline /> : <AuthComponent />} />
				</Route>
			</Routes>
			<ErrorBoundary>
				<AllRoutes />
			</ErrorBoundary>
		</AppContext.Provider>
	)
}

const ErrorBoundaryWrapper = () => {
	const { appState } = useContext(AppContext)
	return <ErrorBoundary>{appState.authState.isLoggedIn ? <PageNotFound /> : <></>}</ErrorBoundary>
}

const AppWithRouter: React.FC = () => {
	return (
		<Router>
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
		</Router>
	)
}

export { AppContext }
export default AppWithRouter
