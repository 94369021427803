import React from 'react'

import { AppContext } from '../../App'
import * as Request from '../../utilities/request'

import { Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { AssetInformation } from '../../components/UI/AssetDetails/AssetInformation'
import { Line } from '../../components/UI/Line/Line'
import { Back } from '../../components/UI/Back/Back'
import { ChannelMapping } from '../../components/UI/AssetDetails/ChannelMapping'
import { AssetTemperatureReading } from '../../components/UI/AssetDetails/AssetTemperatureReading'

import { Asset, AssetResult } from '../../models/Asset'
import { PageStatus } from '../../types/PageStatus'
import { assetClass } from '../../constants/assetClass'
import AssetLiveTemperatureGraph from '../../components/UI/AssetDetails/AssetLiveTemperatureGraph'
import AssetLiveVoltageGraph from '../../components/UI/AssetDetails/AssetLiveVoltageGraph'
import AssetVoltageReading from '../../components/UI/AssetDetails/AssetVoltageReading'

const AssetDetails = () => {
	const context = React.useContext(AppContext)
	const id = useParams().id

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [messages, setMessages] = useMessageReducer([])

	const [asset, setAsset] = React.useState<Asset | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [assetReq] = await Promise.all([Request.get<AssetResult>(`asset?Id=${id}`, context.appState.authState)])
			if (assetReq.data.assets.length > 0) {
				setAsset(assetReq.data.assets[0])
				setPageStatus('Ready')
			} else {
				setPageStatus('Error')
			}
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [id])

	return (
		<div className="page-container">
			<Messages messages={messages} updateMessage={setMessages} />
			<Row className="page-header-row">
				<Col>
					<Back />
				</Col>
			</Row>
			{asset && (
				<Row style={styles.row}>
					<Col style={styles.cell}>
						<h1 className="page-header-primary">{asset.asset_Name}</h1>
						<span className="page-header-divider">|</span>
						<h2 className="page-header-secondary">{asset.site_Name}</h2>
					</Col>
				</Row>
			)}
			<Line />
			<AssetInformation asset={asset} pageStatus={pageStatus} />
			{(asset?.assetClass_Id === assetClass.Hub.id || asset?.assetClass_Id === assetClass.Transmitter.id) && (
				<ChannelMapping asset={asset} editMode={false} />
			)}

			{asset?.assetClass_Id === assetClass.Tmv.id && (
				<Row className="no-gutter" style={styles.spacedRow}>
					<AssetTemperatureReading asset={asset} />
					{/* Asset flow component goes here */}
				</Row>
			)}
			{asset?.assetClass_Id === assetClass.Transmitter.id && (
				<Row className="no-gutter" style={styles.spacedRow}>
					<AssetVoltageReading asset={asset} />
				</Row>
			)}

			{(asset?.assetClass_Id === assetClass.Fixture.id || asset?.assetClass_Id === assetClass.Tmv.id) && <AssetLiveTemperatureGraph asset={asset} />}
			{asset?.assetClass_Id === assetClass.Transmitter.id && <AssetLiveVoltageGraph asset={asset} />}
		</div>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	container: {
		padding: '40px',
	},
	row: {
		marginBottom: '40px',
	},
	spacedRow: {
		justifyContent: 'space-between',
	},
	cell: {
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center',
	},
	card: {
		marginTop: '20px',
	},
}

export { AssetDetails }
