import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Col, Modal, Row } from 'react-bootstrap'

import { Button } from '../../Button/Button'
import { Loading } from '../../Loading/Loading'
import { FormSelect, SelectOption } from '../../Form/Select'

import { defaultCreated, defaultModified } from '../../../../models/History'
import { Asset } from '../../../../models/Asset'
import { PageStatus } from '../../../../types/PageStatus'
import { GroupList, GroupListResult } from '../../../../models/Group'
import { AssetGroup, defaultAssetGroup } from '../../../../models/AssetGroup'
import { assetClass } from '../../../../constants/assetClass'
import { groupType, groupTypeDropdownValues } from '../../../../constants/groupType'

import { AppContext } from '../../../../App'
import * as Request from '../../../../utilities/request'

interface AssetGroupsModalProps {
	show: boolean
	setShow: React.Dispatch<React.SetStateAction<boolean>>
	handleSubmit: (group: AssetGroup) => Promise<void>
	asset: Asset
}

const AssetGroupsModal = (props: AssetGroupsModalProps) => {
	const context = useContext(AppContext)

	const defaultFormValues = defaultAssetGroup({
		asset_Id: props.asset.asset_Id,
		created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
	})

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [assetGroup, setAssetGroup] = React.useState<AssetGroup>(defaultFormValues)
	const [groups, setGroups] = React.useState<GroupList[]>([])

	const groupTypeOptions = React.useMemo(() => {
		return calculateValidGroupTypes(props.asset)
	}, [props.asset.assetClass_Id])

	React.useEffect(() => {
		const getData = async () => {
			const [groupRes] = await Promise.all([
				Request.get<GroupListResult>(`group?site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
			])
			const validGroupTypes = calculateValidGroupTypes(props.asset)
			setGroups(groupRes.data.groups.filter((group) => validGroupTypes.some((groupType) => groupType.value === group.groupType_Id)))
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn && props.show) {
			setPageStatus('Loading')
			getData()
		}
	}, [context, props.show])

	return (
		<Formik initialValues={{ assetGroup, groups }} onSubmit={(values) => props.handleSubmit(values.assetGroup)} enableReinitialize>
			{({ handleSubmit, isSubmitting, values, setValues, handleReset }) => (
				<Modal
					show={props.show}
					onHide={() => {
						if (!isSubmitting) {
							handleReset()
							props.setShow(false)
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>Add New Group</Modal.Title>
					</Modal.Header>

					{pageStatus === 'Loading' ? (
						<Row style={styles.loadingRow}>
							<Col />
							<Col sm="auto">
								<Loading show={true} />
							</Col>
							<Col />
						</Row>
					) : (
						<Modal.Body>
							{groupTypeOptions.length > 0 && (
								<FormSelect
									name={'assetGroup.groupType_Id'}
									label={'Group Type'}
									options={groupTypeOptions}
									value={values.assetGroup.groupType_Id}
									onChange={(e) => {
										setValues({
											assetGroup: {
												...values.assetGroup,
												groupType_Id: e ? (e as SelectOption<string>).value : '',
											},
											groups: calculateValidGroups(
												{ ...values.assetGroup, groupType_Id: e ? (e as SelectOption<string>).value : '' },
												props.asset.asset_Groups,
												groups
											),
										})
									}}
									isClearable
									isDisabled={pageStatus === 'Submitting'}
								/>
							)}
							<FormSelect
								name={'assetGroup.group_Id'}
								label={'Group'}
								options={calculateValidGroups(values.assetGroup, props.asset.asset_Groups, values.groups).map((group) => ({
									value: group.group_Id,
									label: group.group_Name,
								}))}
								value={values.assetGroup.group_Id}
								onChange={(e) => {
									setValues({
										...values,
										assetGroup: {
											...values.assetGroup,
											group_Id: e ? (e as SelectOption<string>).value : '',
											group_Name: e ? (e as SelectOption<string>).label : '',
											groupType_Id:
												values.groups.find((group) => group.group_Id === (e as SelectOption<string>).value)?.groupType_Id || '',
										},
									})
								}}
								isClearable
								isDisabled={pageStatus === 'Submitting'}
							/>
						</Modal.Body>
					)}

					<Modal.Footer>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleReset()
								setAssetGroup(defaultFormValues)
								props.setShow(false)
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={isSubmitting || !values.assetGroup.group_Id}
							onClick={() => {
								handleSubmit()
								setAssetGroup(defaultFormValues)
								props.setShow(false)
							}}
						>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
	)
}

const calculateValidGroups = (assetGroup: AssetGroup, existingAssetGroups: AssetGroup[], groups: GroupList[]) => {
	return groups.filter(
		(group) =>
			(!assetGroup.groupType_Id || group.groupType_Id === assetGroup.groupType_Id) &&
			!existingAssetGroups.some((assetGroup) => assetGroup.group_Id === group.group_Id) &&
			!existingAssetGroups.some((assetGroup) => assetGroup.groupType_Id !== groupType.Custom.id && assetGroup.groupType_Id === group.groupType_Id)
	)
}

const calculateValidGroupTypes = (asset: Asset) => {
	if (asset.assetClass_Id === assetClass.Tmv.id) {
		return groupTypeDropdownValues.filter((option) => option.value === groupType.Temperature.id || option.value === groupType.Custom.id)
	}
	if (asset.assetClass_Id === assetClass.Transmitter.id) {
		return groupTypeDropdownValues.filter((option) => option.value === groupType.Battery.id || option.value === groupType.Custom.id)
	}
	return groupTypeDropdownValues.filter((option) => option.value === groupType.Custom.id)
}

const styles: { [key: string]: React.CSSProperties } = {
	loadingRow: {
		margin: '20px',
	},
}

export { AssetGroupsModal }
