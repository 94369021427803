import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { useSearchParams } from 'react-router-dom'
import { PageStatus } from '../../../types/PageStatus'
import { MonthlyReading, MonthlyReadingResult } from '../../../models/Report'
import * as Request from '../../../utilities/request'
import { ReportApiType } from '../../../constants/report'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import logo from '../../../images/LogoBlack.svg'
import { Col, Row } from 'react-bootstrap'
import { ReportStyles as styles } from './ReportStyles'
import './ReportRender.css'
import { Site, SiteResult } from '../../../models/Site'
import { SiteGroup, SiteGroupResult } from '../../../models/SiteGroup'

const MonthlyReadingRender = () => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [monthlyReadings, setMonthlyReadings] = React.useState<MonthlyReading[]>([])
	const [site, setSite] = React.useState<Site | null>(null)
	const [siteGroup, setSiteGroup] = React.useState<SiteGroup | null>(null)

	const [searchParams] = useSearchParams()
	const siteId = searchParams.get('Site_Id')
	const siteGroupId = searchParams.get('SiteGroup_Id')
	const date = searchParams.get('Date')
	const displayDate = searchParams.get('DisplayDate')
	const excludeAssetsWithReadings = searchParams.get('ExcludeAssetsWithReadings')

	React.useEffect(() => {
		const getData = async () => {
			const [monthlyReadingsReq, siteReq, siteGroupReq] = await Promise.all([
				Request.get<MonthlyReadingResult>(
					`report/${ReportApiType.MONTHLY_READING.value}?${
						siteId !== null ? `Site_Id=${siteId}` : `SiteGroup_Id=${siteGroupId}`
					}&Date=${date}&ExcludeAssetsWithReadings=${excludeAssetsWithReadings}`,
					context.appState.authState
				),
				siteId !== null ? Request.get<SiteResult>(`site?Id=${siteId}`, context.appState.authState) : Promise.resolve({ data: { sites: [] } }),
				siteGroupId !== null
					? Request.get<SiteGroupResult>(`siteGroup?Id=${siteGroupId}`, context.appState.authState)
					: Promise.resolve({ data: { siteGroups: [] } }),
			])
			setMonthlyReadings(monthlyReadingsReq.data.monthlyReadings)
			setSite(siteReq.data.sites[0] || null)
			setSiteGroup(siteGroupReq.data.siteGroups[0] || null)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			if ((siteId === null) === (siteGroupId === null) || date === null || excludeAssetsWithReadings === null) {
				setPageStatus('Error')
			} else {
				getData()
			}
		}
	}, [context])

	return (
		<div style={styles.w100}>
			{pageStatus === 'Ready' && (
				<table style={styles.w100}>
					<thead>
						<tr style={styles.theadTitleRow}>
							<td colSpan={6}>
								<Row>
									<Col sm="auto">
										<img src={logo} alt="logo" style={styles.headerImg} />
									</Col>
									<Col style={styles.headerName}>{site?.site_Name || siteGroup?.siteGroup_Name || ''}</Col>
									<Col sm="auto">
										<div style={styles.headerTitle}>
											<span>
												Monthly Reading{excludeAssetsWithReadings && excludeAssetsWithReadings === 'true' ? ' Preparation ' : ' '}Report
											</span>
											<span style={styles.lightText}>{date ? displayDate : ''}</span>
										</div>
									</Col>
								</Row>
							</td>
						</tr>
						<tr style={{ ...styles.theadColNameRow, ...styles.lightText }}>
							<th style={styles.cell}>TMV ID</th>
							<th style={styles.cell}>Asset Number</th>
							<th style={styles.cell}>TMV Location</th>
							<th style={styles.cell}>Start Time</th>
							<th style={styles.cell}>End Time</th>
							<th style={styles.cellLast}>Temperature</th>
						</tr>
					</thead>
					<tbody>
						{monthlyReadings
							.sort((a, b) => (a.asset_Name < b.asset_Name ? -1 : 1))
							.map((reading) => (
								<tr key={reading.asset_Id} style={styles.bottomBorder}>
									<td style={styles.cell}>{reading.asset_Name}</td>
									<td style={styles.cell}>{reading.asset_SerialNo}</td>
									<td style={styles.cell}>{reading.asset_Location}</td>

									<td style={styles.cell}>
										{reading.startTs
											? formatIncomingDateTime({
													dateTime: reading.startTs,
													format: 'DateAndTimeWithSeconds',
													timeZone: reading.site_Timezone,
											  })
											: ''}
									</td>

									<td style={styles.cell}>
										{reading.finishTs
											? formatIncomingDateTime({
													dateTime: reading.finishTs,
													format: 'DateAndTimeWithSeconds',
													timeZone: reading.site_Timezone,
											  })
											: ''}
									</td>
									<td style={styles.cellLast}>{reading.temperature || 'No Reading'}</td>
								</tr>
							))}
						<tr>
							<td colSpan={6} style={{ ...styles.centeredCell, ...styles.largeFont }}>
								<span>
									{monthlyReadings.length} TMV{monthlyReadings.length > 1 ? 's' : ''} in Total
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			)}
			{pageStatus === 'Loading' && <div>Loading...</div>}
			{pageStatus === 'Error' && <div>Error</div>}
		</div>
	)
}

export default MonthlyReadingRender
