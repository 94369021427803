const ABNValidForm = (input: string) => {
	const abnForm = /^ *(\d *){11}$/g //11 digits with optional spaces between
	return abnForm.test(input)
}

/// http://stackoverflow.com/questions/38781377
/// 1. Subtract 1 from the first (left) digit to give a new eleven digit number
/// 2. Multiply each of the digits in this new number by its weighting factor
/// 3. Sum the resulting 11 products
/// 4. Divide the total by 89, noting the remainder
/// 5. If the remainder is zero the number is valid
const ABNValidChecksum = (input: string) => {
	//0. ABN must be 11 digits long
	if (!ABNValidForm(input)) return false

	const abnWeights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19] as const
	const abn = input.replaceAll(' ', '')

	let weightedSum = 0

	//Rules: 1,2,3
	for (let i = 0; i < abnWeights.length; i++) {
		weightedSum += (parseInt(abn[i]) - (i === 0 ? 1 : 0)) * abnWeights[i]
	}

	//Rules: 4,5
	return weightedSum % 89 === 0
}

const ABNFormat = (input: string | null) => {
	if (input && input.length == 11) {
		return `${input.slice(0, 2)} ${input.slice(2, 5)} ${input.slice(5, 8)} ${input.slice(8, 11)}`
	} else if (input) {
		return input
	}
	return ``
}

interface ABNSearchResult {
	Abn: string
	AbnStatus: string
	AbnStatusEffectiveFrom: string
	Acn: string
	AddressDate: string
	AddressPostcode: string
	AddressState: string
	BusinessName: string[]
	EntityName: string
	EntityTypeCode: string
	EntityTypeName: string
	Gst: string
	Message: string
}

const SearchABNforName = async (abn: string) => {
	if (!ABNValidChecksum(abn)) return null

	try {
		const response = await fetch(
			`https://abr.business.gov.au/json/AbnDetails.aspx?abn=${abn.replaceAll(' ', '')}&callback=callback&guid=b9743790-0dae-4ede-885b-a8d1cc38389d`,
			{
				method: 'GET',
				redirect: 'follow',
			}
		)
		//Clean the JSON-P wrapper callback from the result
		const jsonText = (await response.text()).slice(9, -1)

		const abnResult: ABNSearchResult = JSON.parse(jsonText)
		return abnResult
	} catch (error) {
		console.log('error', error)
		return null
	}
}

interface NameResultItem {
	Abn: string
	AbnStatus: string
	IsCurrent: boolean
	Name: string
	NameType: string
	Postcode: string
	Score: number
	State: string
}

interface NameSearchResult {
	Message: string
	Names: NameResultItem[]
}

const SearchNameForABN = async (name: string) => {
	if (name === '') return null

	try {
		const response = await fetch(
			`https://abr.business.gov.au/json/MatchingNames.aspx?name=${name}&maxResults=10&callback=callback&guid=b9743790-0dae-4ede-885b-a8d1cc38389d`,
			{
				method: 'GET',
				redirect: 'follow',
			}
		)
		//Clean the JSON-P wrapper callback from the result
		const jsonText = (await response.text()).slice(9, -1)

		const nameResult: NameSearchResult = JSON.parse(jsonText)
		return nameResult
	} catch (error) {
		console.log('error', error)
		return null
	}
}

export { ABNValidForm, ABNValidChecksum, ABNFormat, SearchABNforName, SearchNameForABN }
export type { ABNSearchResult, NameSearchResult, NameResultItem }
