import React from 'react'
import { Col } from 'react-bootstrap'

import { FormNumber } from '../../Form/Number'

import { BacnetTemperatureAlarmSetting } from '../../../../models/BacnetTemperatureAlarmSetting'

interface BacnetSettingFieldProps {
	bacnetSetting: BacnetTemperatureAlarmSetting
	index: number
	isDisabled: boolean
	isReadOnly: boolean
	setFieldValue: (field: string, value: unknown) => void
}

const BacnetSettingField = (props: BacnetSettingFieldProps) => {
	return (
		<>
			<Col sm={3} style={styles.col}>
				<FormNumber
					name={`bacnetSettings[${props.index}].bacnetTemperatureAlarmSetting_Temperature`}
					value={props.bacnetSetting.bacnetTemperatureAlarmSetting_Temperature}
					onChange={(value) => props.setFieldValue(`bacnetSettings[${props.index}].bacnetTemperatureAlarmSetting_Temperature`, value)}
					label={'Alert Temperature'}
					disabled={props.isDisabled}
					readOnly={props.isReadOnly}
					min={0}
					max={100}
				/>
			</Col>
			<Col sm={3} style={styles.col}>
				<FormNumber
					name={`bacnetSettings[${props.index}].bacnetTemperatureAlarmSetting_Duration`}
					value={props.bacnetSetting.bacnetTemperatureAlarmSetting_Duration}
					onChange={(value) => props.setFieldValue(`bacnetSettings[${props.index}].bacnetTemperatureAlarmSetting_Duration`, value)}
					label={'Temperature Duration'}
					disabled={props.isDisabled}
					readOnly={props.isReadOnly}
					min={0}
				/>
			</Col>
			<Col sm={3} style={styles.col}>
				<span className="form-label">Level</span>
				{props.bacnetSetting.severity_Name}
			</Col>
		</>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	col: {
		marginRight: '20px',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
}

export { BacnetSettingField }
