import React from 'react'
import { Col, Form, FormControlProps, Row } from 'react-bootstrap'

interface FormTextProps extends FormControlProps {
	name: string
	label?: string
	note?: string
	required?: boolean
	feedback?: string
	rows?: number
	rightSideContent?: React.JSX.Element
}

const FormText = (props: FormTextProps) => {
	const { rightSideContent, ...formProps } = props

	return (
		<Form.Group controlId={formProps.name}>
			{formProps.label ? (
				<Form.Label>
					{formProps.label}
					{formProps.required ? ' *' : ''}
					{formProps.note ? <span className="text-muted"> {formProps.note}</span> : null}
				</Form.Label>
			) : null}
			<Row>
				<Col>
					<Form.Control {...formProps} />
				</Col>
				{rightSideContent && <Col sm="auto">{rightSideContent}</Col>}
			</Row>
			{formProps.feedback ? <Form.Control.Feedback type="invalid">{formProps.feedback}</Form.Control.Feedback> : null}
		</Form.Group>
	)
}

export { FormText }
