import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { User, defaultUser } from './User'

interface UserSite {
	userSite_Id: string
	user_Id: string
	userSite_User: User
	site_Id: string | null
	site_Name: string | null
	siteGroup_Id: string | null
	siteGroup_Name: string | null
	userRole_Id: string
	userRole_Name: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface UserSiteResult extends Validate.Result {
	userSites: UserSite[]
}

const defaultUserSite = (userSite: Partial<UserSite>): UserSite => ({
	userSite_Id: userSite.userSite_Id || '',
	user_Id: userSite.user_Id || '',
	userSite_User: userSite.userSite_User || defaultUser({}),
	site_Id: userSite.site_Id || null,
	site_Name: userSite.site_Name || null,
	siteGroup_Id: userSite.siteGroup_Id || null,
	siteGroup_Name: userSite.siteGroup_Name || null,
	userRole_Id: userSite.userRole_Id || '',
	userRole_Name: userSite.userRole_Name || '',

	created: userSite.created || defaultCreated({}),
	modified: userSite.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

/*
	Function used by some user pages to generate a description of the user's sites / roles
	e.g System - System Specialist or {SiteName} - Site User
*/
const generateUserSiteDescription = (user: User, userSites: UserSite[]) => {
	// This is a System user, no need to check userSites
	if (user.userRole_Id) {
		return `System - ${user.userRole_Name}`
	}

	// Get a list of user Sites for this user
	const relevantUserSites = userSites.filter((userSite) => userSite.user_Id === user.user_Id)
	const responseArray = relevantUserSites.map((userSite) => {
		return `${userSite.siteGroup_Id ? userSite.siteGroup_Name : userSite.site_Name} - ${userSite.userRole_Name}`
	})
	return responseArray.join(', ')
}

export { defaultUserSite, generateUserSiteDescription }
export type { UserSite, UserSiteResult }
