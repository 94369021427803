import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { Asset, AssetList, defaultAssetList } from './Asset'
import { assetClass, AssetClassId } from '../constants/assetClass'
import { AssetTreeStatusId, AssetTreeStatus, assetTreeStatus } from '../constants/assetTreeStatus'
import { assetType } from '../constants/assetType'

interface AssetTree {
	assetTree_Id: string
	assetTree_ParentAssetId: string
	assetTree_ChildAssetId: string
	assetTreeStatus_Id: AssetTreeStatusId
	assetTreeStatus_Name: AssetTreeStatus

	asset: AssetList

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface AssetTreeResult extends Validate.Result {
	assetTrees: AssetTree[]
}

const defaultAssetTree = (assetTree: Partial<AssetTree>): AssetTree => ({
	assetTree_Id: assetTree.assetTree_Id || '',
	assetTree_ParentAssetId: assetTree.assetTree_ParentAssetId || '',
	assetTree_ChildAssetId: assetTree.assetTree_ChildAssetId || '',
	assetTreeStatus_Id: assetTree.assetTreeStatus_Id || assetTreeStatus.Monitor.id,
	assetTreeStatus_Name: assetTree.assetTreeStatus_Name || 'Monitor',

	asset: assetTree.asset || defaultAssetList({}),

	created: assetTree.created || defaultCreated({}),
	modified: assetTree.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export interface AssetTreeAllowedTypes {
	assetClass_Id: AssetClassId
	allowedChildTypes: AssetClassId[]
	allowedParentTypes: AssetClassId[]
}

export const assetTreeAllowedTypes: AssetTreeAllowedTypes[] = [
	{
		assetClass_Id: assetClass.Fixture.id,
		allowedChildTypes: [],
		allowedParentTypes: [assetClass.Tmv.id, assetClass.Hub.id, assetClass.Transmitter.id, assetClass.Pipework.id],
	},
	{
		assetClass_Id: assetClass.Hub.id,
		allowedChildTypes: [assetClass.Tmv.id, assetClass.Fixture.id],
		allowedParentTypes: [assetClass.Network.id],
	},
	{
		assetClass_Id: assetClass.Transmitter.id,
		allowedChildTypes: [assetClass.Tmv.id],
		allowedParentTypes: [assetClass.Network.id],
	},
	{
		assetClass_Id: assetClass.Network.id,
		allowedChildTypes: [assetClass.Hub.id, assetClass.Transmitter.id],
		allowedParentTypes: [],
	},
	{
		assetClass_Id: assetClass.Pipework.id,
		allowedChildTypes: [assetClass.Hub.id, assetClass.Transmitter.id],
		allowedParentTypes: [],
	},
	{
		assetClass_Id: assetClass.Tmv.id,
		allowedChildTypes: [assetClass.Fixture.id],
		allowedParentTypes: [assetClass.Hub.id, assetClass.Transmitter.id],
	},
]

const calculateServicedBy = (asset: Asset | null) => {
	return asset
		? asset.asset_Serviced.filter((at) => asset?.asset_Id === at.assetTree_ChildAssetId && at.assetTreeStatus_Id === assetTreeStatus.Service.id)
		: []
}

const calculateMonitoredBy = (asset: Asset | null) => {
	return asset
		? asset.asset_Serviced.filter((at) => asset?.asset_Id === at.assetTree_ChildAssetId && at.assetTreeStatus_Id === assetTreeStatus.Monitor.id)
		: []
}

const calculateServiced = (asset: Asset | null) => {
	return asset
		? asset.asset_Serviced.filter((at) => asset?.asset_Id === at.assetTree_ParentAssetId && at.assetTreeStatus_Id === assetTreeStatus.Service.id)
		: []
}

const calculateMonitored = (asset: Asset | null) => {
	return asset
		? asset.asset_Serviced.filter((at) => asset?.asset_Id === at.assetTree_ParentAssetId && at.assetTreeStatus_Id === assetTreeStatus.Monitor.id)
		: []
}

const isMonitoredBySerialPort = (asset: Asset | null) => {
	if (asset?.assetClass_Id !== assetClass.Hub.id) return false // if not a hub returns false always
	return asset.asset_Serviced.filter((at) => asset.asset_Id === at.assetTree_ChildAssetId && at.asset.assetType_Id === assetType['Serial Port'].id).length > 0
}

/*
Business Logic around adding Asset Tree records to an Asset:

Asset Classes:
 fixture0-0000-0000-0000-000000000000 // Plumbing
 tmv00000-0000-0000-0000-000000000000 // Plumbing
 pipework-0000-0000-0000-000000000000 // Plumbing
 hub00000-0000-0000-0000-000000000000 // Network
 transmit-0000-0000-0000-000000000000 // Network
 network0-0000-0000-0000-000000000000 // Network

If it is a Network Asset:
- Serviced By has nothing, not possible (disable the button)
- Monitored By has Network Assets
- Assets Serviced has nothing, not possible (disable the button)
- Assets Monitored has Network and Plumbing assets

If it is a Plumbing Asset:
- Serviced By has Plumbing Assets
- Monitored By has Network Assets
- Assets Serviced has Plumbing assets
- Assets Monitored has nothing, not possible (disable the button)


This is on top of the Type filters provided in assetTreeAllowedTypes

*/

const addAssetTreeFilterAssetOptions = (type: 'Serviced By' | 'Monitored By' | 'Serviced' | 'Monitored', asset: Asset, assets: AssetList[]) => {
	const networkAssetClasses = [assetClass.Hub.id, assetClass.Network.id, assetClass.Transmitter.id, assetClass.Fixture.id]
	const plumbingAssetClasses = [assetClass.Tmv.id, assetClass.Pipework.id, assetClass.Fixture.id]

	// Immediately filter down to only assets that are not the current asset or already in the tree
	const filteredAssets = assets.filter((a) => a.asset_Id !== asset.asset_Id && !asset.asset_Serviced.map((at) => at.asset.asset_Id).includes(a.asset_Id))

	// Prepare list of allowed types
	const allowedType: AssetTreeAllowedTypes | undefined = assetTreeAllowedTypes.find((t) => t.assetClass_Id === asset.assetClass_Id)
	if (!allowedType) throw new Error(`Asset Class ${asset.assetClass_Id} not found in assetTreeAllowedTypes`)

	console.log({
		type: type,
		allowedType: allowedType,
	})

	switch (type) {
		case 'Serviced By':
			if (networkAssetClasses.includes(asset.assetClass_Id)) {
				return []
			} else {
				return filteredAssets.filter((a) => plumbingAssetClasses.includes(a.assetClass_Id) && allowedType.allowedParentTypes.includes(a.assetClass_Id))
			}
		case 'Monitored By':
			return filteredAssets.filter((a) => networkAssetClasses.includes(a.assetClass_Id) && allowedType.allowedParentTypes.includes(a.assetClass_Id))
		case 'Serviced':
			if (networkAssetClasses.includes(asset.assetClass_Id)) {
				return []
			} else {
				return filteredAssets.filter((a) => plumbingAssetClasses.includes(a.assetClass_Id) && allowedType.allowedChildTypes.includes(a.assetClass_Id))
			}
		case 'Monitored':
			if (networkAssetClasses.includes(asset.assetClass_Id)) {
				return filteredAssets.filter((a) => allowedType.allowedChildTypes.includes(a.assetClass_Id))
			} else {
				return []
			}
	}
}

export {
	defaultAssetTree,
	calculateServicedBy,
	calculateMonitoredBy,
	calculateServiced,
	calculateMonitored,
	addAssetTreeFilterAssetOptions,
	isMonitoredBySerialPort,
}
export type { AssetTree, AssetTreeResult }
