import React from 'react'
import { Button, Container } from 'react-bootstrap'
import SiteDashboardStats from '../../components/UI/SiteDashboard/SiteDashboardStats'
import SiteGroupOverviewGraphs from '../../components/UI/SiteGroupDetails/SiteGroupOverviewGraphs'
import SiteSummaryCards from '../../components/UI/SiteGroupDetails/SiteSummaryCards'
import PickerHeader from '../../components/UI/ContextualPickers/PickerHeader'
import { Link } from 'react-router-dom'
import { AppContext } from '../../App'

const SiteGroupDetails = () => {
	const context = React.useContext(AppContext)

	return (
		<>
			<PickerHeader
				type="siteGroup"
				title="Overview"
				rightElement={
					<Link to="/reports">
						<Button
							variant="secondary"
							onClick={() => {
								context.setAppState({ state: 'setCurrentSite', data: { site: null } })
							}}
						>
							Reports
						</Button>
					</Link>
				} // TODO: Placeholder until siteGroup menu is made (if made?)
			/>
			<Container fluid>
				<SiteDashboardStats context="siteGroup" />
				<SiteGroupOverviewGraphs siteGroup />
				<SiteSummaryCards />
			</Container>
		</>
	)
}

export { SiteGroupDetails }
