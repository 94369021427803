export const GroupTypeValues = ['Battery', 'Custom', 'Flow', 'Temperature'] as const
export type GroupType = (typeof GroupTypeValues)[number]

export const groupType: Record<GroupType, { id: string }> = {
	Battery: { id: 'batt0000-0000-0000-0000-000000000000' },
	Custom: { id: 'custom00-0000-0000-0000-000000000000' },
	Flow: { id: 'flow0000-0000-0000-0000-000000000000' },
	Temperature: { id: 'temp0000-0000-0000-0000-000000000000' },
}

export const groupTypeDropdownValues = GroupTypeValues.map((value) => ({
	value: groupType[value].id,
	label: value,
}))
