import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import { Loading } from '../Loading/Loading'
import { Asset } from '../../../models/Asset'
import voltage from '../../../images/icons/voltage.svg'
import { ReadingLive, ReadingLiveResult } from '../../../models/ReadingLive'
import { readingType } from '../../../constants/readingType'
import { PageStatus } from '../../../types/PageStatus'

interface AssetVoltageReadingProps {
	asset: Asset | null
}

const AssetVoltageReading = (props: AssetVoltageReadingProps) => {
	const context = React.useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [batteryLiveReading, setBatteryLiveReading] = React.useState<ReadingLive | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			try {
				const readingLiveResponse = await Request.get<ReadingLiveResult>(`readingLive?Asset_Id=${props.asset?.asset_Id}`, context.appState.authState)

				const battReading = readingLiveResponse.data.readingLives.filter((r) => r.readingType_Id === readingType['Battery Voltage Reading'].id)
				if (battReading.length === 1) setBatteryLiveReading(battReading[0])
				setPageStatus('Ready')
			} catch (e) {
				console.log(e)
				setPageStatus('Error')
			}
		}

		setPageStatus('Loading')
		if (props.asset && context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [props.asset, context.appState.authState])

	return (
		<Card style={styles.card}>
			{pageStatus === 'Ready' ? (
				<Row>
					<Col style={styles.col}>
						<h1>Battery Voltage</h1>
						<img src={voltage} alt="A voltage icon" style={styles.image}></img>
						{batteryLiveReading ? (
							<>
								<p style={styles.valueBig}>{(batteryLiveReading.reading_Data / 1000).toFixed(3)} V</p>
								<p style={styles.labelSmall}>
									Last reading {formatIncomingDateTime({ dateTime: batteryLiveReading.reading_Ts, format: 'TimeAgo' })}
								</p>
							</>
						) : (
							<p>No readings yet</p>
						)}
					</Col>
				</Row>
			) : (
				<Loading show />
			)}
		</Card>
	)
}

const styles = {
	card: {
		marginTop: '20px',
		minHeight: '200px',
		flexBasis: '49%',
		padding: '30px 10px',
	},
	image: {
		height: '60px',
	},
	col: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	label: {
		marginBottom: '0px',
		lineHeight: 'unset',
	},
	labelSmall: {
		fontSize: '10px',
	},
	value: {
		fontWeight: 'bold',
		marginBottom: '.5rem',
		lineHeight: 'unset',
	},
	valueBig: {
		fontSize: '40px',
		lineHeight: 'unset',
		marginBottom: '0px',
	},
} satisfies Record<string, React.CSSProperties>

export default AssetVoltageReading
