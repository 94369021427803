import React from 'react'
import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { TrendsApiType } from '../../../constants/trends'
import { PageStatus } from '../../../types/PageStatus'
import { Card } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import { DateTime } from 'luxon'
import { colors } from '../../../constants/colors'
import { PreviousYearPercents, PreviousYearPercentsResult } from '../../../models/Trends'
import { formatOutgoingDateTime } from '../../../utilities/formatDate'

interface TempExceptionEventsGraphProps {
	context: 'site' | 'siteGroup'
	endMonthDate?: DateTime // First day of final month to display, ie. 01/07/2023 00:00:00
	noCard?: boolean
	disableAnimation?: boolean
	width?: string
	height?: string
	barLabels?: boolean
}

const TemperatureExceptionEventsGraph = (props: TempExceptionEventsGraphProps) => {
	const context = React.useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [firstMonthDisplayDate, setFirstMonthDisplayDate] = React.useState<DateTime>(DateTime.now().minus({ months: 11 }))

	const [previousYearPercents, setPreviousYearPercents] = React.useState<PreviousYearPercents>()

	React.useEffect(() => {
		const getData = async () => {
			const [previousYearPercentsReq] = await Promise.all([
				Request.get<PreviousYearPercentsResult>(
					`trends/${TrendsApiType.TEMP_EXCEPTION_EVENTS.value}?${
						props.context === 'site'
							? `Site_Id=${context.appState.currentSite?.site_Id}`
							: `SiteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
					}${
						props.endMonthDate
							? `&EndMonth=${formatOutgoingDateTime({
									dateTime: props.endMonthDate,
									format: 'DateTimeObject',
									timeZone:
										props.context === 'site'
											? context.appState.currentSite?.site_Timezone
											: context.appState.currentSiteGroup?.siteGroup_Timezone,
							  })}`
							: ''
					}`,
					context.appState.authState
				),
			])
			if (previousYearPercentsReq.status === 200) {
				setPreviousYearPercents(previousYearPercentsReq.data.previousYearPercents)
				if (!props.endMonthDate) {
					setFirstMonthDisplayDate(
						DateTime.fromObject({
							month: previousYearPercentsReq.data.previousYearPercents.displayMonthFirstMonthlyPercent,
							year: previousYearPercentsReq.data.previousYearPercents.displayYearFirstMonthlyPercent,
						})
					)
				} else {
					setFirstMonthDisplayDate(props.endMonthDate.minus({ months: 11 }))
				}
				setPageStatus('Ready')
			} else {
				setPageStatus('Error')
			}
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props])

	const graph = (
		<Bar
			width={props.width}
			height={props.height}
			data={{
				labels: Array.from({ length: 12 }, (_, i) => firstMonthDisplayDate.plus({ months: i }).toFormat('MMM yy')),
				datasets: [
					{
						data: previousYearPercents?.monthlyPercents,
						backgroundColor: (ctx) => {
							const value = ctx.dataset.data[ctx.dataIndex] as number
							return value === 0
								? colors.colorStatusBlue
								: value < 25
								? colors.colorStatusYellow
								: value < 50
								? colors.colorStatusOrange
								: colors.colorStatusRed
						},
					},
				],
			}}
			options={{
				maintainAspectRatio: false,
				scales: {
					y: {
						title: { display: true, text: ['% of TMVs with 1 or more', 'Temperature Exception'] },
						min: 0,
						max: 100,
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						callbacks: {
							label: (ctx) => {
								const value = ctx.dataset.data[ctx.dataIndex] as number
								return `${value}%`
							},
						},
					},
					datalabels: {
						display: (ctx) => (props.barLabels && (ctx.dataset.data[ctx.dataIndex] as number) > 0 ? true : false),
						formatter: (value) => `${value}%`,
						anchor: 'end',
						align: 'start',
						offset: (ctx) => ((ctx.dataset.data[ctx.dataIndex] as number) > 95 ? 0 : -20),
						color: (ctx) => ((ctx.dataset.data[ctx.dataIndex] as number) > 95 ? 'white' : 'black'),
						font: {
							size: 14,
						},
					},
				},
				animation: props.disableAnimation ? false : undefined,
			}}
		/>
	)

	return props.noCard ? (
		<>{graph}</>
	) : (
		<Card className="p-0">
			<Card.Header>Temperature Exception Events{pageStatus === 'Error' && ' - Error Loading Data'}</Card.Header>
			<Card.Body>
				<div style={styles.graphContainer}>{graph}</div>
			</Card.Body>
		</Card>
	)
}

const styles = {
	graphContainer: {
		position: 'relative',
		minHeight: '300px',
		width: '100%',
	},
} satisfies Record<string, React.CSSProperties>

export default TemperatureExceptionEventsGraph
