import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import { AppContext } from '../../App'
import * as Request from '../../utilities/request'

import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { Line } from '../../components/UI/Line/Line'
import { Back } from '../../components/UI/Back/Back'
import { GroupInformation } from '../../components/UI/GroupDetails/GroupInformation'

import { Group, GroupList, GroupResult, defaultTemperatureGroup, defaultBatteryGroup } from '../../models/Group'
import { PageStatus } from '../../types/PageStatus'

const GroupDetails = () => {
	const context = React.useContext(AppContext)
	const id = useParams().id

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [messages, setMessages] = useMessageReducer([])

	const [group, setGroup] = React.useState<Group | GroupList | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			if (id === 'DefaultTemperatureGroup') {
				setGroup(defaultTemperatureGroup)
			} else if (id === 'DefaultBatteryGroup') {
				setGroup(defaultBatteryGroup)
			} else {
				const [groupReq] = await Promise.all([Request.get<GroupResult>(`group?Id=${id}`, context.appState.authState)])
				if (groupReq.data.groups.length > 0) {
					setGroup(groupReq.data.groups[0])
				} else {
					setPageStatus('Error')
				}
			}
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [id])

	return (
		<Container fluid>
			<Messages messages={messages} updateMessage={setMessages} />
			<Row className="page-header-row">
				<Col>
					<Back />
				</Col>
			</Row>
			{group && (
				<>
					<Row style={styles.row}>
						<Col style={styles.cell}>
							<h1 className="page-header-primary">{group.group_Name}</h1>
							<span className="page-header-divider">|</span>
							<h2 className="page-header-secondary">{context.appState.currentSite?.site_Name}</h2>
						</Col>
					</Row>
					<Line />
					<GroupInformation group={group} pageStatus={pageStatus} setMessages={setMessages} />
				</>
			)}
		</Container>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	row: {
		marginBottom: '40px',
	},
	cell: {
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center',
	},
}

export { GroupDetails }
