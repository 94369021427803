import React from 'react'
import { AppContext } from '../../App'
import './PageWrapper.css'

import { Row, Col, Container } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { Menu } from '../../components/UI/Menu/Menu'
import { UserInfo } from '../../components/UI/UserInfo/UserInfo'
import { SettingsMenu } from '../../components/UI/Settings/SettingsMenu'

import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import Logo from '../../images/Logo.svg'
import { EventNotificationsIcon } from '../../components/UI/EventNotificationsIcon/EventNotificationsIcon'
import { TermsAndConditions } from '../../components/UI/TermsAndConditions/TermsAndConditions'
import { userRole } from '../../constants/userRole'

interface PageWrapperProps {
	renderPage: JSX.Element
}

export const PageWrapper = (props: PageWrapperProps) => {
	const context = React.useContext(AppContext)
	const location = useLocation()
	const path = location.pathname.toString()

	const [messages, setMessages] = useMessageReducer([])

	// TODO: Update with new paths that require menu as they're built
	const showMenuPaths = [
		'/site',
		'/assets',
		'/siteSettings',
		'/asset',
		'/editAsset',
		'/tasks',
		'/task',
		'/groupSettings',
		'/groupSetting',
		'/liveTemperatures',
		'/reports',
		'/userManagementSite',
		'/trends',
		'/currentStatus',
	]
	// TODO: Need a Site group specific menu? because otherwise the site context menu is loaded on the sitegroup overview page
	// (which will be confusing as it contains site related links with the last used site in context, possibly not even a site in the current sitegroup being viewed)
	const excludeMenuPaths = ['/siteGroup', '/siteImport', '/taskDashboard']

	const showMenu =
		context.appState.currentSite !== null &&
		showMenuPaths.some((showPath) => path.startsWith(showPath)) &&
		!excludeMenuPaths.some((excludePath) => path.startsWith(excludePath))

	const settingsAllowUserRoles = [userRole['System Admin'], userRole['System Specialist'], userRole['Contract Manager']]

	return (
		<Container fluid className="body">
			<Row className="page-header">
				<Col sm={'auto'} className={`page-header-logo${showMenu ? '-menu' : ''}`}>
					<Link to="/">
						<img src={Logo} alt={'Enware Smart Flow Logo'} />
					</Link>
				</Col>
				<Col />
				<Col sm={'auto'}>
					<EventNotificationsIcon />
				</Col>
				{settingsAllowUserRoles.some((a) => a.id === context.appState.userAttributes.userRole_Id) && (
					<Col sm={'auto'}>
						<SettingsMenu />
					</Col>
				)}
				<Col sm={'auto'}>
					<UserInfo />
				</Col>
			</Row>

			<Row className="page-body">
				{showMenu && <Menu menuSelectedItem={path} />}
				<Col className="scrollable-content">
					<Messages messages={messages} updateMessage={setMessages} />
					{props.renderPage}
					<TermsAndConditions />
				</Col>
			</Row>
		</Container>
	)
}
