import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Col, Modal, Row } from 'react-bootstrap'
import { Button } from '../../Button/Button'
import { defaultCreated, defaultModified } from '../../../../models/History'
import { AppContext } from '../../../../App'
import { Asset, AssetResult, defaultAsset } from '../../../../models/Asset'
import { PageStatus } from '../../../../types/PageStatus'
import * as Request from '../../../../utilities/request'
import { Loading } from '../../Loading/Loading'
import { FormSelect, SelectOption } from '../../Form/Select'
import { assetType, assetTypeDropdownValues } from '../../../../constants/assetType'
import { FormText } from '../../Form/Text'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'

interface AssetAddModalProps {
	show: boolean
	setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const assetValidationSchema = yup.object().shape({
	asset_Name: yup.string().required('Object ID is Required').max(100, 'Name must be less than ${max} characters'),
	assetType_Id: yup.string().required('Asset Type must be selected'),
	// TODO: Add validation for unique hub number on same serial port
	hub_Number: yup
		.number()
		.nullable()
		.test('is-required', 'Hub Number is Required', (value, context) => {
			if (context.parent.assetType_Id === assetType['SFM Standard Hub'].id || context.parent.assetType_Id === assetType['SFM TFP Hub'].id) {
				return !!value
			} else {
				return true
			}
		}),
})

const AssetAddModal = (props: AssetAddModalProps) => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Ready')
	const navigate = useNavigate()

	const defaultFormValues = defaultAsset({
		created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
		site_Id: context.appState.currentSite?.site_Id,
	})
	const [asset, setAsset] = React.useState<Asset>(defaultFormValues)

	const handleSubmit = async (asset: Asset) => {
		setPageStatus('Submitting')

		await Request.handleRequest(() => Request.post<AssetResult>('asset', asset, context.appState.authState), {
			successFunction: (data) => {
				navigate(`/editAsset/${data.assets[0].asset_Id}`)
			},
			messageAction: 'creating',
			messageObject: 'asset',
		})
	}

	return (
		<Formik initialValues={asset} validationSchema={assetValidationSchema} onSubmit={handleSubmit} enableReinitialize>
			{({ handleSubmit, isSubmitting, touched, errors, handleChange, setFieldValue, values, handleReset, dirty, isValid, setValues }) => (
				<Modal
					show={props.show}
					onHide={() => {
						if (!isSubmitting) {
							handleReset()
							props.setShow(false)
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>Add New Asset</Modal.Title>
					</Modal.Header>

					{pageStatus === 'Loading' || pageStatus == 'Submitting' ? (
						<Row style={styles.loadingRow}>
							<Col />
							<Col sm="auto">
								<Loading show={true} />
							</Col>
							<Col />
						</Row>
					) : (
						<Modal.Body>
							<FormText
								name={'asset_Name'}
								value={values.asset_Name ? values.asset_Name : ''}
								label={'Object ID'}
								onChange={handleChange}
								required
								feedback={touched.asset_Name && errors.asset_Name ? errors.asset_Name : ''}
								isInvalid={touched.asset_Name && !!errors.asset_Name}
							/>

							<FormSelect
								name={'assetType'}
								label={'Asset Type'}
								options={assetTypeDropdownValues}
								value={values.assetType_Id}
								onChange={(e) => {
									const value = (e as SelectOption<string>).value
									if (value !== assetType['SFM Standard Hub'].id && value !== assetType['SFM TFP Hub'].id) {
										setValues({ ...values, assetType_Id: value, hub_Number: null })
									} else {
										setFieldValue('assetType_Id', value)
									}
								}}
								required
								feedback={touched.assetType_Id && errors.assetType_Id ? errors.assetType_Id : ''}
							/>

							{(values.assetType_Id === assetType['SFM Standard Hub'].id || values.assetType_Id === assetType['SFM TFP Hub'].id) && (
								<FormText
									name={'hub_Number'}
									value={values.hub_Number ? values.hub_Number.toString() : ''}
									label={'Hub Number'}
									note="(Numbers Only)"
									onChange={(e) => {
										setFieldValue('hub_Number', parseInt(e.target.value))
									}}
									required
									feedback={touched.hub_Number && errors.hub_Number ? errors.hub_Number : ''}
									isInvalid={touched.hub_Number && !!errors.hub_Number}
								/>
							)}
						</Modal.Body>
					)}

					<Modal.Footer>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleReset()
								setAsset(defaultFormValues)
								props.setShow(false)
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={isSubmitting || !dirty || !isValid}
							onClick={() => {
								handleSubmit()
								setAsset(defaultFormValues)
								props.setShow(false)
							}}
						>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	loadingRow: {
		margin: '20px',
	},
}

export { AssetAddModal }
