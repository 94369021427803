export const ReportApiType = {
	MONTHLY_READING: { value: 'monthlyReading', label: 'Monthly Reading' },
	TEMP_EXCEPTIONS: { value: 'tempExceptions', label: 'Temperature Exceptions' },
	TEMP_EXCEPTION_DETAIL: { value: 'tempExceptionDetail', label: 'Temperature Exception Detail' },
	TEMP_EXCEPTION_HISTORY: { value: 'tempExceptionHistory', label: 'Temperature Exception History' },
	FLOW: { value: 'flow', label: 'Flow' },
	CONSECUTIVE_FLOW: { value: 'consecutiveFlow', label: 'Consecutive Flow' },
	THERMAL_FLUSH: { value: 'thermalFlush', label: 'Thermal Flush' },
	FLEET_HEALTH: { value: 'fleetHealth', label: 'Fleet Health' },
} as const

export type ReportApiType = (typeof ReportApiType)[keyof typeof ReportApiType]

export const ReportApiTypeSelectOptions = Object.values(ReportApiType)

export const ReportApiTempExceptionFilterType = {
	MONTH: { value: 'month', label: 'Month' },
	DAY: { value: 'day', label: 'Day' },
	TMV: { value: 'tmv', label: 'TMV' },
} as const

export type ReportApiTempExceptionFilterType = (typeof ReportApiTempExceptionFilterType)[keyof typeof ReportApiTempExceptionFilterType]

export const ReportApiTempExceptionFilterTypeSelectOptions = Object.values(ReportApiTempExceptionFilterType)

export const ReportApiFlowWindowType = {
	MONTH: { value: 'month', label: 'Month' },
	WEEK: { value: 'week', label: 'Week' },
	DAY: { value: 'day', label: 'Day' },
} as const

export type ReportApiFlowWindowType = (typeof ReportApiFlowWindowType)[keyof typeof ReportApiFlowWindowType]

export const ReportApiFlowWindowTypeSelectOptions = Object.values(ReportApiFlowWindowType)
