import React from 'react'

// components
import { Form } from 'react-bootstrap'
import Select, { SingleValue, MultiValue, ActionMeta } from 'react-select'
import { Loading } from '../Loading/Loading'

interface Option<T> {
	label: T
	value: string
}
type OptionValue = SingleValue<Option<string>> | MultiValue<Option<string>>

interface FormSelectProps {
	name: string
	options: Option<string>[]
	label?: string
	required?: boolean
	feedback?: string
	isMulti?: boolean
	isDisabled?: boolean
	isClearable?: boolean
	loading?: boolean
	placeholder?: string | null | number | boolean | JSX.Element
	value: string | string[] | null | undefined
	onChange: (newValue: OptionValue, actionMeta?: ActionMeta<OptionValue>) => void
	onBlur?: React.FocusEventHandler<HTMLInputElement>
}

const FormSelect = (props: FormSelectProps) => {
	const selectedValue = props.value
		? props.options.filter((option) => (props.isMulti ? props.value?.includes(option.value) : option.value === props.value))
		: []

	return (
		<Form.Group controlId={props.name}>
			{props.label ? (
				<Form.Label>
					{props.label}
					{props.required ? ' *' : ''}
				</Form.Label>
			) : null}
			{props.loading ? <Loading show /> : <Select {...props} value={selectedValue} />}
			{!!props.feedback && <div className="invalid-feedback-custom">{props.feedback}</div>}
		</Form.Group>
	)
}

export { FormSelect }
export type SelectOption<T> = Option<T>
