import React from 'react'

import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { useNavigate, useParams } from 'react-router-dom'

import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { Information } from '../../components/UI/Information/Information'

import { LocationBuilding as ILocationBuilding, LocationBuildingResult } from '../../models/LocationBuilding'
import { PageStatus } from '../../types/PageStatus'

import { PermissionModelObject } from '../../utilities/permissions/permission.d'

const LocationBuilding = () => {
	const context = React.useContext(AppContext)
	const navigate = useNavigate()
	const id = useParams().id || ''

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [messages, setMessages] = useMessageReducer([])

	const [locationBuilding, setLocationBuilding] = React.useState<ILocationBuilding | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const locationBuildingReq = await Request.get<LocationBuildingResult>(`locationBuilding?Id=${id}`, context.appState.authState)
			if (locationBuildingReq.data.locationBuildings.length > 0) {
				setLocationBuilding(locationBuildingReq.data.locationBuildings[0])
				setPageStatus('Ready')
			} else {
				setPageStatus('Error')
			}
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [id])

	const handleSave = async (values: { name: string; note: string }) => {
		setPageStatus('Submitting')
		await Request.handleRequest(
			() =>
				Request.put<LocationBuildingResult>(
					'locationBuilding',
					{ ...locationBuilding, locationBuilding_Name: values.name, locationBuilding_Note: values.note },
					context.appState.authState
				),
			{
				successFunction: (data) => {
					setLocationBuilding(data.locationBuildings[0])
					setPageStatus('Ready')
				},
				failedFunction: () => {
					setPageStatus('Error')
				},
				setMessageFunction: setMessages,
				messageAction: 'editing',
				messageObject: 'location building',
			}
		)
	}

	const handleDelete = async () => {
		setPageStatus('Submitting')
		await Request.handleRequest(
			() => Request.del<LocationBuildingResult>(`locationBuilding?Id=${locationBuilding?.locationBuilding_Id}`, context.appState.authState),
			{
				successFunction: () => {
					setTimeout(() => navigate(-1), 500)
				},
				failedFunction: () => {
					setPageStatus('Error')
				},
				setMessageFunction: setMessages,
				messageAction: 'deleting',
				messageObject: 'location building',
			}
		)
	}

	return (
		<div className="page-container">
			<Messages messages={messages} updateMessage={setMessages} />
			<Information
				pageStatus={pageStatus}
				name={locationBuilding?.locationBuilding_Name || null}
				note={locationBuilding?.locationBuilding_Note || null}
				customDeleteMessage={
					'Are you sure you want to delete this building? All of its floors, areas, and rooms, plus any associated documents, will also be deleted.'
				}
				fileUploadProps={{ locationBuilding_Id: id }}
				assetOverviewListingProps={{ filter: `locationBuilding_Id=${id}` }}
				permissionModelObject={PermissionModelObject.LocationBuilding}
				handleSave={handleSave}
				handleDelete={handleDelete}
			/>
		</div>
	)
}

export { LocationBuilding }
