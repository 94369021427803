import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'

interface MenuItemProps {
	menuIsOpen: boolean
	isSelected: boolean
	link: string
	icon: string
	title: string
}
export const MenuItem = (props: MenuItemProps) => {
	return (
		<OverlayTrigger
			delay={{ show: 400, hide: 400 }}
			placement="auto"
			overlay={props.menuIsOpen ? <></> : <Tooltip id={`toolTip_${props.title}`}>{props.title}</Tooltip>}
		>
			<Link to={props.link} className={`sideNav-row-${props.title} hidden-link `}>
				<Row>
					<Col>
						<div className={props.isSelected ? `sideNav-row selected` : `sideNav-row`}>
							<img src={props.icon} alt={`A ${props.title} Icon`} className={props.isSelected ? 'sideNav-icon selected' : 'sideNav-icon'}></img>
							{props.menuIsOpen ? <span className={props.isSelected ? 'sideNav-text selected' : 'sideNav-text'}>{props.title}</span> : null}
						</div>
					</Col>
				</Row>
			</Link>
		</OverlayTrigger>
	)
}
