/* Configuration for Local PRs to allow pipeline to run */
// import { msalInstance_Local as instance, msalConfig_Local as config } from './azureAdConfigLocal'
// import { apiEndpoint_Dev as apiEndpoint, reportApiEndpoint_Dev as reportEndpoint } from './azureAdConfigDev'

// import {
// 	msalInstance_Local as instance,
// 	msalConfig_Local as config,
// 	apiEndpoint_Local as apiEndpoint,
// 	reportApiEndpoint_Local as reportEndpoint,
// } from './azureAdConfigLocal'
// import {
// 	msalInstance_Dev as instance,
// 	msalConfig_Dev as config,
// 	apiEndpoint_Dev as apiEndpoint,
// 	reportApiEndpoint_Dev as reportEndpoint,
// } from './azureAdConfigDev'
import {
	msalInstance_Prod as instance,
	msalConfig_Prod as config,
	apiEndpoint_Prod as apiEndpoint,
	reportApiEndpoint_Prod as reportEndpoint,
} from './azureAdConfigProd'

export const msalInstance = instance
export const msalConfig = config

export const ApiConfig = {
	BaseUrl: apiEndpoint,
	ReportUrl: reportEndpoint,
}
