import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Formik } from 'formik'
import { Col, Modal, Row } from 'react-bootstrap'
import * as yup from 'yup'

import * as Request from '../../../utilities/request'
import { Button } from '../Button/Button'
import { FormText } from '../Form/Text'
import { PaymentAgreement, PaymentAgreementResult, defaultPaymentAgreement } from '../../../models/PaymentAgreement'
import { FormNumber } from '../Form/Number'
import { FormDate } from '../Form/Date'
import { Contract } from '../../../models/Contract'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import { DateTime } from 'luxon'

const paymentAgreementValidationSchema = yup.object().shape({
	paymentAgreement_OrderNumber: yup.string().notRequired().max(36, 'Cannot be longer than ${max} characters.'),
	paymentAgreement_LineNumber: yup.number().integer('Quantity must be a whole number'),
	paymentAgreement_StartDate: yup
		.string()
		.notRequired()
		.test('validPAStartDate', 'Start Date cannot be later than End Date', (value, context) => {
			return value && context.parent.contract_EndDate ? value < context.parent.contract_EndDate : true
		}),
	paymentAgreement_EndDate: yup
		.string()
		.notRequired()
		.test('validPAEndDate', 'End Date cannot be earlier than Start Date', (value, context) => {
			return value && context.parent.contract_StartDate ? value > context.parent.contract_StartDate : true
		}),
	paymentAgreement_AssetCount: yup.number().integer('Quantity must be a whole number'),
	paymentAgreement_Note: yup.string().notRequired().max(500, 'Cannot be longer than ${max} characters.'),
})

interface PaymentAgreementModalProps {
	paymentAgreement: PaymentAgreement | null
	setPaymentAgreement: React.Dispatch<React.SetStateAction<PaymentAgreement | null>>
	editMode: boolean
	contract: Contract
	setContract: (values: React.SetStateAction<Contract>, shouldValidate?: boolean | undefined) => void
}

const PaymentAgreementModal = (props: PaymentAgreementModalProps) => {
	const context = useContext(AppContext)

	const handleSubmit = (values: PaymentAgreement) => {
		if (props.editMode) {
			return Request.handleRequest(() => Request.put<PaymentAgreementResult>(`paymentAgreement`, values, context.appState.authState), {
				successFunction: () => {
					const updatedContractAgreements = props.contract.contract_PaymentAgreements
					const agreementIndex = updatedContractAgreements.findIndex((agreement) => agreement.paymentAgreement_Id === values.paymentAgreement_Id)
					updatedContractAgreements[agreementIndex] = values
					props.setContract({
						...props.contract,
						contract_PaymentAgreements: updatedContractAgreements,
					})

					props.setPaymentAgreement(null)
				},
				messageAction: 'editing',
				messageObject: 'payment agreement',
			})
		}
		return Request.handleRequest(() => Request.post<PaymentAgreementResult>(`paymentAgreement`, values, context.appState.authState), {
			successFunction: (data) => {
				props.setContract({
					...props.contract,
					contract_PaymentAgreements: [...props.contract.contract_PaymentAgreements, data.paymentAgreements[0]],
				})

				props.setPaymentAgreement(null)
			},
			messageAction: 'creating',
			messageObject: 'payment agreement',
		})
	}

	return (
		<Formik
			initialValues={props.paymentAgreement ? props.paymentAgreement : defaultPaymentAgreement({})}
			validationSchema={paymentAgreementValidationSchema}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ handleSubmit, isSubmitting, errors, values, handleChange, handleReset, setFieldValue }) => (
				<Modal
					show={!!props.paymentAgreement}
					onHide={() => {
						if (!isSubmitting) {
							handleReset()
							props.setPaymentAgreement(null)
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>{props.editMode ? 'Edit' : 'Add New'} Payment Agreement</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Row>
							<Col>
								<FormText
									name={`paymentAgreement_OrderNumber`}
									label="Agreement ID"
									value={values.paymentAgreement_OrderNumber || ''}
									onChange={handleChange}
									feedback={errors?.paymentAgreement_OrderNumber}
									isInvalid={!!errors?.paymentAgreement_OrderNumber}
								/>
							</Col>
							<Col>
								<FormNumber
									name={`paymentAgreement_LineNumber`}
									label={'PO/Line Item #'}
									value={values.paymentAgreement_LineNumber || 0}
									onChange={(value) => setFieldValue(`paymentAgreement_LineNumber`, Math.round(value))}
									min={0}
									feedback={errors?.paymentAgreement_LineNumber}
									isInvalid={!!errors?.paymentAgreement_LineNumber}
								/>
							</Col>
						</Row>
						<Row className="d-flex align-items-end">
							<Col>
								<FormDate
									name={`paymentAgreement_StartDate`}
									label={'Start Date'}
									value={
										formatIncomingDateTime({
											dateTime: DateTime.fromISO(values.paymentAgreement_StartDate || '').toISO({ includeOffset: false }) || '',
											format: 'FileDate',
										}) || undefined
									}
									onChange={handleChange}
									feedback={errors?.paymentAgreement_StartDate}
									isInvalid={!!errors?.paymentAgreement_StartDate}
								/>
							</Col>
							<Col>
								<FormDate
									name={`paymentAgreement_EndDate`}
									label={'End Date'}
									value={
										formatIncomingDateTime({
											dateTime: DateTime.fromISO(values.paymentAgreement_EndDate || '').toISO({ includeOffset: false }) || '',
											format: 'FileDate',
										}) || undefined
									}
									onChange={handleChange}
									feedback={errors?.paymentAgreement_EndDate}
									isInvalid={!!errors?.paymentAgreement_EndDate}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormNumber
									name={`paymentAgreement_AssetCount`}
									label={'Quantity'}
									value={values.paymentAgreement_AssetCount || 0}
									onChange={(value) => setFieldValue(`paymentAgreement_AssetCount`, Math.round(value))}
									min={0}
									feedback={errors?.paymentAgreement_AssetCount}
									isInvalid={!!errors?.paymentAgreement_AssetCount}
								/>
							</Col>
							<Col>
								<FormNumber
									name={`paymentAgreement_AssetCountAssigned`}
									label={'Assigned Quantity'}
									value={props.paymentAgreement?.paymentAgreement_AssetCountAssigned || 0}
									onChange={() => {
										return
									}}
									disabled
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormText
									name={`paymentAgreement_Note`}
									label="Notes"
									value={values.paymentAgreement_Note || ''}
									onChange={handleChange}
									feedback={errors?.paymentAgreement_Note}
									isInvalid={!!errors?.paymentAgreement_Note}
									rows={3}
								/>
							</Col>
						</Row>
					</Modal.Body>

					<Modal.Footer>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleReset()
								props.setPaymentAgreement(null)
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleSubmit()
							}}
						>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
	)
}

export { PaymentAgreementModal }
