import React from 'react'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { FormText } from '../Form/Text'
import { FormStepProps } from '../../../screens/Contracts/ContractWizard'
import { FormSelect, SelectOption } from '../Form/Select'
import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { PageStatus } from '../../../types/PageStatus'
import { Site, SiteResult, defaultSite } from '../../../models/Site'
import { FormDate } from '../Form/Date'
import plus from '../../../images/icons/add-white.svg'
import trash from '../../../images/icons/trash_white.svg'
import { FormNumber } from '../Form/Number'
import { ModuleDropdownValues, ModuleId, getModuleNameFromId, module } from '../../../constants/module'
import Select from 'react-select'
import { defaultContractModule } from '../../../models/ContractModule'
import { SiteGroup, SiteGroupResult, defaultSiteGroup } from '../../../models/SiteGroup'

const ContractValidationSchema = Yup.object().shape({
	site_Id: Yup.string()
		.notRequired()
		.test('siteRequired', 'A Site or Site Group must be selected', function (value) {
			const siteGroup_Id = this.parent.siteGroup_Id
			return !!(value || siteGroup_Id)
		}),
	siteGroup_Id: Yup.string().notRequired(),
	contract_StartDate: Yup.string()
		.notRequired()
		.test('validStartDate', 'Start Date cannot be later than End Date', (value, context) => {
			return value && context.parent.contract_EndDate ? value < context.parent.contract_EndDate : true
		}),
	contract_EndDate: Yup.string()
		.notRequired()
		.test('validEndDate', 'End Date cannot be earlier than Start Date', (value, context) => {
			return value && context.parent.contract_StartDate ? value > context.parent.contract_StartDate : true
		}),
})

const ContractForm = (props: FormStepProps) => {
	const { values, handleChange, setFieldValue, errors, touched, handleBlur, setFieldTouched, setTouched, setErrors, setValues } = props.formikProps

	const context = React.useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [sites, setSites] = React.useState<Site[]>([])
	const [siteGroups, setSiteGroups] = React.useState<SiteGroup[]>([])

	const [showAddModuleModal, setShowAddModuleModal] = React.useState(false)
	const [modalModuleId, setModalModuleId] = React.useState('')

	React.useEffect(() => {
		const getData = async () => {
			const [sitesReq, siteGroupsReq] = await Promise.all([
				Request.get<SiteResult>(`site`, context.appState.authState),
				Request.get<SiteGroupResult>(`siteGroup`, context.appState.authState),
			])

			setSites(sitesReq.data.sites)
			setSiteGroups(siteGroupsReq.data.siteGroups)

			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	const siteDropdownValues = React.useMemo(
		() =>
			sites
				.sort((a, b) => (a.site_Name.toLowerCase() < b.site_Name.toLowerCase() ? -1 : 1))
				.map((site) => ({ value: site.site_Id, label: site.site_Name })),
		[sites, siteGroups]
	)

	const siteGroupDropdownValues = React.useMemo(
		() =>
			siteGroups
				.sort((a, b) => (a.siteGroup_Name.toLowerCase() < b.siteGroup_Name.toLowerCase() ? -1 : 1))
				.map((siteGroup) => ({ value: siteGroup.siteGroup_Id, label: siteGroup.siteGroup_Name })),
		[sites, siteGroups]
	)

	const modalModulesFiltered = React.useMemo(
		() => ModuleDropdownValues.filter((md) => !values.contract_Modules.some((m) => m.module_Id === (md.value as string))),
		[values.contract_Modules]
	)

	const handleModuleModalClose = () => {
		setModalModuleId('')
		setShowAddModuleModal(false)
	}

	return (
		<>
			<Row>
				<Col>
					<span className="span-bold">Select Site or Site Group</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormSelect
						name="siteGroup_Id"
						label={'Site Group'}
						options={siteGroupDropdownValues}
						value={values.siteGroup_Id}
						onChange={(e) => {
							const siteGroup_Id = (e as SelectOption<string>).value
							setValues({
								...values,
								siteGroup_Id: siteGroup_Id,
								siteGroup: siteGroups.find((s) => s.siteGroup_Id === siteGroup_Id) || defaultSiteGroup({}),
								site_Id: null,
								site: defaultSite({}),
							})
						}}
						onBlur={() => {
							setFieldTouched('site_Id')
						}}
						feedback={touched.site_Id && errors.site_Id ? errors.site_Id : ''}
						loading={pageStatus === 'Loading'}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormSelect
						name="site_Id"
						label={'Site'}
						options={siteDropdownValues}
						value={values.site_Id}
						onChange={(e) => {
							const site_Id = (e as SelectOption<string>).value
							setValues({
								...values,
								site_Id: site_Id,
								site: sites.find((s) => s.site_Id === site_Id) || defaultSite({}),
								siteGroup_Id: null,
								siteGroup: defaultSiteGroup({}),
							})
						}}
						onBlur={() => {
							setFieldTouched('site_Id')
						}}
						feedback={touched.site_Id && errors.site_Id ? errors.site_Id : ''}
						loading={pageStatus === 'Loading'}
					/>
				</Col>
			</Row>

			<Row className="mt-3">
				<Col>
					<span className="span-bold">Contract Duration</span>
				</Col>
			</Row>
			<Row className="d-flex align-items-end">
				<Col>
					<FormDate
						name={'contract_StartDate'}
						label={'Start Date'}
						value={values.contract_StartDate || undefined}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_StartDate && errors.contract_StartDate ? errors.contract_StartDate : ''}
						isInvalid={touched.contract_StartDate && !!errors.contract_StartDate}
					/>
				</Col>
				<Col>
					<FormDate
						name={'contract_EndDate'}
						label={'End Date'}
						value={values.contract_EndDate || undefined}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_EndDate && errors.contract_EndDate ? errors.contract_EndDate : ''}
						isInvalid={touched.contract_EndDate && !!errors.contract_EndDate}
					/>
				</Col>
			</Row>

			<Row className="border-bottom mt-4 mb-2" />

			<Row className="mt-3">
				<Col>
					<span className="span-bold">Contract Modules</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormNumber
						name={'contract_AssetCount'}
						label={'Asset Count'}
						value={values.contract_AssetCount || 0}
						onChange={(value) => setFieldValue(`contract_AssetCount`, Math.round(value))}
						onBlur={handleBlur}
						min={0}
					/>
				</Col>
				<Col sm="auto" className="d-flex align-items-end">
					<Button
						style={styles.button}
						onClick={() => setShowAddModuleModal(true)}
						disabled={values.contract_Modules.length === Object.keys(module).length}
					>
						<div className="center-flex">
							<img src={plus} className="me-2" />
							Add Contract Module
						</div>
					</Button>
				</Col>
			</Row>

			{values.contract_Modules.map((cm, index) => (
				<React.Fragment key={index}>
					<Row>
						<Col>
							<FormText name="contract_Module_Name" label="Contract Module" value={getModuleNameFromId(cm.module_Id as ModuleId)} disabled />
						</Col>
						<Col sm="auto">
							<FormNumber
								name={`contract_Modules[${index}].contractModule_AssetRate`}
								label={'Price Per Asset'}
								value={values.contract_Modules[index].contractModule_AssetRate || 0}
								onChange={(value) => setFieldValue(`contract_Modules[${index}].contractModule_AssetRate`, value)}
								onBlur={handleBlur}
								min={0}
							/>
						</Col>
						<Col sm="auto" className="d-flex align-items-end">
							<Button
								style={styles.button}
								className="px-3"
								onClick={() => {
									setFieldValue(
										`contract_Modules`,
										values.contract_Modules.filter((m) => m.module_Id !== cm.module_Id)
									)
									setTouched({})
									setErrors({})
								}}
							>
								<div className="center-flex">
									<img src={trash} />
								</div>
							</Button>
						</Col>
					</Row>
				</React.Fragment>
			))}

			{/* <Row className="border-bottom mt-4 mb-2" />

			<Row className="mt-3">
				<Col>
					<span className="span-bold">Products</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<div>{'Soon™'}</div>
				</Col>
			</Row>

			<Row className="border-bottom mt-4 mb-2" />

			<Row className="mt-3">
				<Col>
					<span className="span-bold">Related Documents</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<div>{'Soon™'}</div>
				</Col>
			</Row> */}

			<Modal show={showAddModuleModal} onHide={() => handleModuleModalClose()}>
				<ModalHeader closeButton>Add a Module</ModalHeader>
				<ModalBody>
					<Select
						options={modalModulesFiltered}
						onChange={(e) => {
							setModalModuleId(e?.value || '')
						}}
						isOptionSelected={(e) => e.value === modalModuleId}
					/>
				</ModalBody>
				<ModalFooter>
					<Row>
						<Col />
						<Col>
							<Button onClick={() => handleModuleModalClose()}>Cancel</Button>
						</Col>
						<Col>
							<Button
								disabled={modalModuleId === ''}
								onClick={() => {
									setFieldValue(`contract_Modules`, [
										...values.contract_Modules,
										defaultContractModule({
											contract_Id: values.contract_Id,
											module_Id: modalModuleId,
											contractModule_AssetRate: module[getModuleNameFromId(modalModuleId as ModuleId)].defaultAssetRate * 1.0,
											created: values.created,
											modified: values.modified,
											recordStatus_Id: values.recordStatus_Id,
										}),
									])

									handleModuleModalClose()
								}}
							>
								Add
							</Button>
						</Col>
					</Row>
				</ModalFooter>
			</Modal>
		</>
	)
}

const styles = {
	button: {
		minHeight: '38px',
	},
} satisfies Record<string, React.CSSProperties>

export { ContractForm, ContractValidationSchema }
