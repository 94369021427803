import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormStepProps } from '../../../screens/Contracts/ContractWizard'
import circleTick from '../../../images/icons/circle_tick.svg'
import { DateTime } from 'luxon'
import { ModuleId, getModuleProductCodeFromId } from '../../../constants/module'
import { ABNFormat } from '../../../utilities/abn'

const ReviewRow = (props: { children: React.ReactNode | React.ReactNode[]; setActiveStep?: () => void }) => (
	<Row className="my-2">
		<Col sm={1} className="center-flex">
			{props.setActiveStep && <img src={circleTick} />}
		</Col>
		{props.children}
		<Col sm={1} className="center-flex">
			{props.setActiveStep && (
				<span
					onClick={() => {
						console.log(props.setActiveStep)
						if (props.setActiveStep) {
							props.setActiveStep()
						}
					}}
					className="text-link"
				>
					Edit
				</span>
			)}
		</Col>
	</Row>
)

const ReviewContract = (props: Required<Omit<FormStepProps, 'siteSelect'>>) => {
	const { values } = props.formikProps

	const customerDetails = (
		<>
			<ReviewRow setActiveStep={() => props.setActiveStep(0)}>
				<Col sm={10}>
					<span className="span-bold fs-4">Customer Details</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>ABN</span>
				</Col>
				<Col sm={5}>
					<span>{ABNFormat(values.contract_BuyerABN)}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>Entity Name</span>
				</Col>
				<Col sm={5}>
					<span>{values.contract_BuyerName}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>Enware Statement of Work</span>
				</Col>
				<Col sm={5}>
					<span>{`${values.contract_VisualNumber || ''}_${values.contract_VisualShipTo || ''}_${values.contract_Number}`}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>Visual Customer Number</span>
				</Col>
				<Col sm={5}>
					<span>{values.contract_VisualNumber}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>Visual Ship-To</span>
				</Col>
				<Col sm={5}>
					<span>{values.contract_VisualShipTo}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>Customer Account</span>
				</Col>
				<Col sm={5}>
					<span>{values.contract_CustomerAccount}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>Customer Cost Center</span>
				</Col>
				<Col sm={5}>
					<span>{values.contract_CustomerCostCentre}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>NSW Health Prefix</span>
				</Col>
				<Col sm={5}>
					<span>{values.contract_NswHealthPrefix}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={10}>
					<Row className="border-bottom mt-2 mb-2" />
				</Col>
			</ReviewRow>
		</>
	)

	const contractDetails = (
		<>
			<ReviewRow setActiveStep={() => props.setActiveStep(1)}>
				<Col sm={10}>
					<span className="span-bold fs-4">Contract Details</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>Group / Site</span>
				</Col>
				<Col sm={5}>
					<span>{values.site?.site_Name || values.siteGroup?.siteGroup_Name}</span>
				</Col>
			</ReviewRow>
			{/* TODO: Counts? */}
			<ReviewRow>
				<Col sm={5}>
					<span>Contract Duration</span>
				</Col>
				<Col sm={5}>
					<span>{`${values.contract_StartDate && DateTime.fromISO(values.contract_StartDate).toFormat('d MMM yyyy')}${
						values.contract_EndDate && ` - ${DateTime.fromISO(values.contract_EndDate).toFormat('d MMM yyyy')}`
					}`}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>Contract Asset Count</span>
				</Col>
				<Col sm={5}>
					<span>{values.contract_AssetCount}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={10}>
					<Row>
						<Col sm={6}>
							<span className="span-bold">Contract Module</span>
						</Col>
						<Col sm={6}>
							<span className="span-bold">Price per Asset</span>
						</Col>
					</Row>
					{values.contract_Modules.map((cm, i) => (
						<Row key={i}>
							<Col sm={6}>
								<span>{getModuleProductCodeFromId(cm.module_Id as ModuleId)}</span>
							</Col>
							<Col sm={6}>
								<span>{cm.contractModule_AssetRate ? `$${cm.contractModule_AssetRate.toFixed(2)}` : 'nil'}</span>
							</Col>
						</Row>
					))}
				</Col>
			</ReviewRow>
			{/* TODO: Products and Documents(?) */}
			<ReviewRow>
				<Col sm={10}>
					<Row className="border-bottom mt-2 mb-2" />
				</Col>
			</ReviewRow>
		</>
	)

	const activePaymentDetails = (
		<>
			<ReviewRow setActiveStep={() => props.setActiveStep(2)}>
				<Col sm={10}>
					<span className="span-bold fs-4">Active Payment Agreements</span>
				</Col>
			</ReviewRow>
			{values.contract_PaymentAgreements.map((pa, i) => (
				<ReviewRow key={i}>
					<Col sm={10}>
						<Row>
							<Col sm={6}>
								<span>Agreement ID</span>
							</Col>
							<Col sm={6}>
								<span>{pa.paymentAgreement_OrderNumber}</span>
							</Col>
						</Row>
						<Row>
							<Col sm={6}>
								<span>Item</span>
							</Col>
							<Col sm={6}>
								<span>{pa.paymentAgreement_LineNumber}</span>
							</Col>
						</Row>
						<Row>
							<Col sm={6}>
								<span>Duration</span>
							</Col>
							<Col sm={6}>
								<span>{`${pa.paymentAgreement_StartDate && DateTime.fromISO(pa.paymentAgreement_StartDate).toFormat('d MMM yyyy')}${
									pa.paymentAgreement_EndDate && ` - ${DateTime.fromISO(pa.paymentAgreement_EndDate).toFormat('d MMM yyyy')}`
								}`}</span>
							</Col>
						</Row>
						<Row>
							<Col sm={6}>
								<span>Quantity</span>
							</Col>
							<Col sm={6}>
								<span>{pa.paymentAgreement_AssetCount}</span>
							</Col>
						</Row>
						<Row>
							<Col sm={6}>
								<span>Notes</span>
							</Col>
							<Col sm={6}>
								<span>{pa.paymentAgreement_Note}</span>
							</Col>
						</Row>
					</Col>
				</ReviewRow>
			))}
			<ReviewRow>
				<Col sm={10}>
					<Row className="border-bottom mt-2 mb-2" />
				</Col>
			</ReviewRow>
		</>
	)

	const contactDetails = (
		<>
			<ReviewRow setActiveStep={() => props.setActiveStep(3)}>
				<Col sm={10}>
					<span className="span-bold fs-4">Contact Details</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>First Name</span>
				</Col>
				<Col sm={5}>
					<span>{values.contract_FirstName}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>Last Name</span>
				</Col>
				<Col sm={5}>
					<span>{values.contract_LastName}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>Email</span>
				</Col>
				<Col sm={5}>
					<span>{values.contract_Email}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>Phone</span>
				</Col>
				<Col sm={5}>
					<span>{values.contract_Phone}</span>
				</Col>
			</ReviewRow>
			<ReviewRow>
				<Col sm={5}>
					<span>Notes</span>
				</Col>
				<Col sm={5}>
					<span>{values.contract_Note}</span>
				</Col>
			</ReviewRow>
		</>
	)

	return (
		<>
			{customerDetails}
			{contractDetails}
			{activePaymentDetails}
			{contactDetails}
		</>
	)
}

export { ReviewContract }
