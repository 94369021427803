import axios, { AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from 'axios'
import { ApiConfig } from '../config/config'
import { AuthState } from '../App.d'
import { MessageAction } from '../components/UI/Messages/Message'
import { apiEndpoint_Local, reportApiEndpoint_Local } from '../config/azureAdConfigLocal'

const setConfig = (authState: AuthState): RawAxiosRequestHeaders => {
	if (ApiConfig.BaseUrl === apiEndpoint_Local && ApiConfig.ReportUrl === reportApiEndpoint_Local) {
		return {
			Authorization: 'Bearer ' + authState.apiToken,
			'Access-Control-Allow-Origin': '*',
			'X-MS-CLIENT-PRINCIPAL-ID': authState.user_AzureId,
		}
	}
	return {
		Authorization: 'Bearer ' + authState.apiToken,
	}
}

const getUrl = (endpoint: string): string =>
	ApiConfig.BaseUrl === apiEndpoint_Local ? `http://${ApiConfig.BaseUrl}/${endpoint}` : `https://${ApiConfig.BaseUrl}/${endpoint}`

const getReportUrl = (endpoint: string): string =>
	ApiConfig.ReportUrl === reportApiEndpoint_Local ? `http://${ApiConfig.ReportUrl}/${endpoint}` : `https://${ApiConfig.ReportUrl}/${endpoint}`

const handleRefreshTokens = <T>(args: AxiosRequestConfig<T>) =>
	new Promise<AxiosResponse<T>>((resolve, reject) => {
		axios(args)
			.catch((error) => {
				// if (error.response && error.response.status === 401) {
				// 	window.location.href = '/login?redirect=' + encodeURIComponent(window.location.pathname)
				// }
				reject(error)
			})
			.then((value: AxiosResponse<T> | void) => {
				if (value) {
					resolve(value)
				}
			})
	})

const get = <T>(endpoint: string, authState: AuthState) => handleRefreshTokens<T>({ method: 'get', url: getUrl(endpoint), headers: setConfig(authState) })

const getReport = <T>(endpoint: string, authState: AuthState) =>
	handleRefreshTokens<T>({ method: 'get', url: getReportUrl(endpoint), headers: setConfig(authState) })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const post = <T>(endpoint: string, body: any, authState: AuthState) =>
	handleRefreshTokens<T>({ method: 'post', url: getUrl(endpoint), data: body, headers: setConfig(authState) })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const put = <T>(endpoint: string, body: any, authState: AuthState) =>
	handleRefreshTokens<T>({ method: 'put', url: getUrl(endpoint), data: body, headers: setConfig(authState) })

const del = <T>(endpoint: string, authState: AuthState) => handleRefreshTokens<T>({ method: 'delete', url: getUrl(endpoint), headers: setConfig(authState) })

type Request = {
	errors?: unknown
	success: boolean
	message?: string
}

interface HandleRequestParams<T> {
	successFunction?: (data: T) => void
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	failedFunction?: (data: any) => void
	setMessageFunction?: (action: MessageAction) => void
	messageAction?: 'deleting' | 'creating' | 'editing' | 'inviting'
	messageObject?: string
}

const handleRequest = async <T extends Request>(requestFunction: () => Promise<AxiosResponse<T>>, params: HandleRequestParams<T>) => {
	try {
		const req = await requestFunction()
		if (req.data) {
			if (req.data.errors) {
				if (params.setMessageFunction) {
					params.setMessageFunction({
						type: 'add',
						data: {
							severity: 'danger',
							message: `${req.data.errors}`,
							dismissible: true,
							timeout: 5000,
						},
					})
				}
				if (params.failedFunction) {
					params.failedFunction(req)
				}
			} else {
				if (params.setMessageFunction) {
					if (!params.messageAction || !params.messageObject) {
						console.error('You need to specify messageAction and messageObject')
					}
					params.setMessageFunction({
						type: 'add',
						data: {
							severity: 'success',
							message: `Success ${params.messageAction} the ${params.messageObject}`,
							dismissible: true,
							timeout: 5000,
						},
					})
				}
				if (params.successFunction) {
					params.successFunction(req.data)
				}
			}
		} else {
			if (params.setMessageFunction) {
				params.setMessageFunction({
					type: 'add',
					data: {
						severity: 'danger',
						message: `Failed ${params.messageAction} the ${params.messageObject}`,
						dismissible: true,
						timeout: 5000,
					},
				})
			}
			if (params.failedFunction) {
				params.failedFunction(req)
			}
		}
	} catch (err) {
		if (params.setMessageFunction) {
			params.setMessageFunction({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Failed ${params.messageAction} the ${params.messageObject}`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
		if (params.failedFunction) {
			params.failedFunction({})
		}
	}
}

export { del, get, post, put, handleRequest, getUrl, getReport }
export type { Request }
