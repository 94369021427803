import React, { useContext } from 'react'
import { AppContext } from '../../../App'

import { Formik, FormikHelpers } from 'formik'
import { Modal } from 'react-bootstrap'
import * as yup from 'yup'

import * as Request from '../../../utilities/request'
import { Button } from '../Button/Button'
import { SiteGroup, SiteGroupResult } from '../../../models/SiteGroup'
import { Site, SiteResult } from '../../../models/Site'
import { FormSelect, SelectOption } from '../Form/Select'

const groupValidationSchema = yup.object().shape({
	siteGroup_Id: yup.string().required('Site Group is required'),
})

interface MoveToExternalModalProps {
	showMoveExternalModal: boolean
	siteGroups: SiteGroup[] | null
	setShowMoveExternalModal: React.Dispatch<React.SetStateAction<boolean>>
	resetData: boolean
	setResetData: React.Dispatch<React.SetStateAction<boolean>>
	handleSave: () => Promise<void>
	site: Site | null
	siteGroup: SiteGroup | null
}

interface FormSiteGroup {
	siteGroup_Id: string | null
}

const MoveToExternalModal = (props: MoveToExternalModalProps) => {
	const context = useContext(AppContext)

	const defaultFormSiteGroup = {
		siteGroup_Id: null,
	}
	const [formSiteGroup, setFormSiteGroup] = React.useState<FormSiteGroup>(defaultFormSiteGroup)

	React.useEffect(() => {
		setFormSiteGroup(defaultFormSiteGroup)
	}, [props.siteGroup, props.site])

	const handleMoveExternalSubmit = async (values: FormSiteGroup, formikHelpers: FormikHelpers<FormSiteGroup>) => {
		await props.handleSave()
		if (props.site) {
			return Request.handleRequest(
				() => Request.put<SiteResult>(`site`, { ...props.site, siteGroup_Id: values.siteGroup_Id } as Site, context.appState.authState),
				{
					successFunction: (data) => {
						if (data.sites.length > 0) {
							props.setShowMoveExternalModal(false)
							formikHelpers.resetForm()
							props.setResetData(!props.resetData)
						}
					},
					messageAction: 'editing',
					messageObject: 'site',
				}
			)
		} else if (props.siteGroup) {
			return Request.handleRequest(
				() =>
					Request.put<SiteGroupResult>(
						`siteGroup`,
						{ ...props.siteGroup, siteGroup_ParentId: values.siteGroup_Id } as SiteGroup,
						context.appState.authState
					),
				{
					successFunction: (data) => {
						if (data.siteGroups.length > 0) {
							props.setShowMoveExternalModal(false)
							formikHelpers.resetForm()
							props.setResetData(!props.resetData)
						}
					},
					messageAction: 'editing',
					messageObject: 'siteGroup',
				}
			)
		}
	}

	return (
		<Formik initialValues={formSiteGroup} validationSchema={groupValidationSchema} onSubmit={handleMoveExternalSubmit} enableReinitialize>
			{({ handleSubmit, isSubmitting, errors, values, handleReset, setFieldValue }) => (
				<Modal
					show={props.showMoveExternalModal}
					onHide={() => {
						if (!isSubmitting) {
							handleReset()
							props.setShowMoveExternalModal(false)
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>Move to Site Group</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<FormSelect
							name="siteGroup_Id"
							required
							options={
								props.siteGroups?.map((siteGroup) => {
									return {
										value: siteGroup.siteGroup_Id,
										label: siteGroup.siteGroup_Name,
									}
								}) || []
							}
							value={values.siteGroup_Id || ''}
							onChange={(option) => {
								setFieldValue('siteGroup_Id', (option as SelectOption<string>).value)
							}}
							label="Destination Site Group"
							feedback={errors.siteGroup_Id}
						/>
						<span>
							Moving this {props.site && 'Site'}
							{props.siteGroup && 'Site Group'} will save any other changes made on the Edit Site Group page
						</span>
					</Modal.Body>

					<Modal.Footer>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleReset()
								setFormSiteGroup(defaultFormSiteGroup)
								props.setShowMoveExternalModal(false)
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleSubmit()
								setFormSiteGroup(defaultFormSiteGroup)
							}}
						>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
	)
}

export { MoveToExternalModal }
