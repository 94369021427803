import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'

interface LocationRoom {
	locationRoom_Id: string
	locationRoom_Name: string
	locationRoom_Note: string | null
	locationArea_Id: string | null
	site_Id: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface LocationRoomResult extends Validate.Result {
	locationRooms: LocationRoom[]
}

const defaultLocationRoom = (locationRoom: Partial<LocationRoom>): LocationRoom => ({
	locationRoom_Id: locationRoom.locationRoom_Id || '',
	locationRoom_Name: locationRoom.locationRoom_Name || '',
	locationRoom_Note: locationRoom.locationRoom_Note || null,
	locationArea_Id: locationRoom.locationArea_Id || null,
	site_Id: locationRoom.site_Id || '',

	created: locationRoom.created || defaultCreated({}),
	modified: locationRoom.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultLocationRoom }
export type { LocationRoom, LocationRoomResult }
