import React from 'react'
import { Card } from 'react-bootstrap'

import { AssetImporter } from '../AssetImport/AssetImporter'

const AssetImportCard = () => {
	return (
		<Card className="p-0">
			<Card.Header>Asset Import</Card.Header>
			<Card.Body>
				<AssetImporter />
			</Card.Body>
		</Card>
	)
}

export { AssetImportCard }
