import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { useSearchParams } from 'react-router-dom'
import { PageStatus } from '../../../types/PageStatus'
import { TempExceptionHistory, TempExceptionHistoryResult } from '../../../models/Report'
import * as Request from '../../../utilities/request'
import { ReportApiType } from '../../../constants/report'
import logo from '../../../images/LogoBlack.svg'
import { Col, Row } from 'react-bootstrap'
import { ReportStyles as styles } from './ReportStyles'
import './ReportRender.css'
import { DateTime } from 'luxon'
import { Site, SiteResult } from '../../../models/Site'
import { SiteGroup, SiteGroupResult } from '../../../models/SiteGroup'

const TempExceptionHistoryRender = () => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [tempExceptionHistory, setTempExceptionHistory] = React.useState<TempExceptionHistory>()
	const [site, setSite] = React.useState<Site | null>(null)
	const [siteGroup, setSiteGroup] = React.useState<SiteGroup | null>(null)
	const displayDateObject = tempExceptionHistory
		? DateTime.fromObject({
				year: tempExceptionHistory.startingDisplayYear,
				month: tempExceptionHistory.startingDisplayMonth,
				day: 1,
		  })
		: DateTime.now()

	const [searchParams] = useSearchParams()
	const siteId = searchParams.get('Site_Id')
	const siteGroupId = searchParams.get('SiteGroup_Id')
	const date = searchParams.get('Date')
	const displayDate = searchParams.get('DisplayDate')

	React.useEffect(() => {
		const getData = async () => {
			const [tempExceptionHistoryReq, siteReq, siteGroupReq] = await Promise.all([
				Request.get<TempExceptionHistoryResult>(
					`report/${ReportApiType.TEMP_EXCEPTION_HISTORY.value}?${
						siteId !== null ? `Site_Id=${siteId}` : `SiteGroup_Id=${siteGroupId}`
					}&Date=${date}&DisplayDate=${displayDate}`,
					context.appState.authState
				),
				siteId !== null ? Request.get<SiteResult>(`site?Id=${siteId}`, context.appState.authState) : Promise.resolve({ data: { sites: [] } }),
				siteGroupId !== null
					? Request.get<SiteGroupResult>(`siteGroup?Id=${siteGroupId}`, context.appState.authState)
					: Promise.resolve({ data: { siteGroups: [] } }),
			])
			setTempExceptionHistory(tempExceptionHistoryReq.data.tempExceptionHistory)
			setSite(siteReq.data.sites[0] || null)
			setSiteGroup(siteGroupReq.data.siteGroups[0] || null)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			if ((siteId === null) === (siteGroupId === null) || date === null || displayDate === null) {
				setPageStatus('Error')
			} else {
				getData()
			}
		}
	}, [context])

	return (
		<div style={styles.w100}>
			{pageStatus === 'Ready' && tempExceptionHistory && (
				<table style={styles.w100}>
					<thead>
						<tr style={styles.theadTitleRow}>
							<td colSpan={8}>
								<Row>
									<Col sm="auto">
										<img src={logo} alt="logo" style={styles.headerImg} />
									</Col>
									<Col style={styles.headerName}>{site?.site_Name || siteGroup?.siteGroup_Name || ''}</Col>
									<Col sm="auto">
										<div style={styles.headerTitle}>
											<span>Temperature Exception History</span>
											<span>Report for {displayDateObject.plus({ months: 5 }).toFormat('MMMM yyyy')}</span>
										</div>
									</Col>
								</Row>
							</td>
						</tr>
						<tr style={{ ...styles.theadColNameRow, ...styles.lightText }}>
							<th style={styles.cell}>TMV ID</th>
							<th style={styles.cell}>TMV Location</th>
							{Array.from({ length: 6 }).map((_, i, arr) => (
								<th key={i} style={i === arr.length - 1 ? styles.cellLast : styles.cell}>
									{displayDateObject.plus({ months: i }).toFormat('MM/yyyy')}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{tempExceptionHistory.assets
							.sort((a, b) => (a.asset_Name < b.asset_Name ? -1 : 1))
							.map((reading) => (
								<tr key={reading.asset_Id} style={styles.bottomBorder}>
									<td style={styles.cell}>{reading.asset_Name}</td>
									<td style={styles.cell}>{reading.asset_Location}</td>

									{reading.monthlyAlertCounts.map((count, i, arr) => (
										<td key={i} style={i === arr.length - 1 ? styles.cellLast : styles.cell}>
											{count}
										</td>
									))}
								</tr>
							))}
						{tempExceptionHistory.assets.length === 0 && (
							<tr>
								<td colSpan={8} style={{ ...styles.centeredCell, ...styles.largeFont }}>
									<span>No Temperature Exception events detected in {displayDateObject.plus({ months: 5 }).toFormat('MMMM yyyy')}</span>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			)}
			{pageStatus === 'Loading' && <div>Loading...</div>}
			{pageStatus === 'Error' && <div>Error</div>}
		</div>
	)
}

export default TempExceptionHistoryRender
