import React, { useContext } from 'react'
import { AxiosResponse } from 'axios'
import { Formik } from 'formik'
import { Col, Modal, Row } from 'react-bootstrap'
import { Button } from '../Button/Button'
import { defaultCreated, defaultModified } from '../../../models/History'
import { AppContext } from '../../../App'
import { Group, GroupResult, defaultGroup } from '../../../models/Group'
import { PageStatus } from '../../../types/PageStatus'
import * as Request from '../../../utilities/request'
import { Loading } from '../Loading/Loading'
import { FormSelect, SelectOption } from '../Form/Select'
import { groupType, groupTypeDropdownValues } from '../../../constants/groupType'
import { FormText } from '../Form/Text'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { EventSettingResult, defaultBatteryGroupEventSettings, defaultTemperatureGroupEventSettings } from '../../../models/EventSetting'
import { BacnetTemperatureAlarmSettingsResult, defaultBacnetTemperatureAlarmSettingsForGroup } from '../../../models/BacnetTemperatureAlarmSetting'
import { v4 as uuidv4 } from 'uuid'

interface AddGroupModalProps {
	show: boolean
	setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const groupValidationSchema = yup.object().shape({
	group_Name: yup.string().required('Group Name is required').max(100, 'Name must be less than ${max} characters'),
	groupType_Id: yup.string().required('Group Type must be selected'),
})

const AddGroupModal = (props: AddGroupModalProps) => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Ready')
	const navigate = useNavigate()

	const created = defaultCreated({ create_UserId: context.appState.userAttributes.user_Id })
	const modified = defaultModified({ modified_UserId: context.appState.userAttributes.user_Id })
	const defaultFormValues = defaultGroup({
		created: created,
		modified: modified,
		site_Id: context.appState.currentSite?.site_Id,
	})
	const [group, setGroup] = React.useState<Group>(defaultFormValues)

	const handleSubmit = async (group: Group) => {
		setPageStatus('Submitting')
		const groupRes = await Request.post<GroupResult>('group', group, context.appState.authState)
		if (groupRes.data.groups.length > 0) {
			const settings = (
				group.groupType_Id === groupType.Temperature.id
					? defaultTemperatureGroupEventSettings
					: group.groupType_Id === groupType.Battery.id
					? defaultBatteryGroupEventSettings
					: []
			).map((setting) => ({ ...setting, eventSetting_Id: uuidv4(), group_Id: groupRes.data.groups[0].group_Id, created, modified }))
			const eventRes = await Promise.all(settings.map((setting) => Request.post<EventSettingResult>('eventSetting', setting, context.appState.authState)))
			const bacnetRes =
				group.groupType_Id === groupType.Temperature.id
					? await Promise.all(
							defaultBacnetTemperatureAlarmSettingsForGroup(groupRes.data.groups[0].group_Id, context.appState.userAttributes.user_Id).map(
								(setting) =>
									Request.post<BacnetTemperatureAlarmSettingsResult>(
										'bacnetTemperatureAlarmSetting',
										{ ...setting, bacnetTemperatureAlarmSetting_Id: uuidv4() },
										context.appState.authState
									)
							)
					  )
					: []
			if (eventRes.concat(bacnetRes as AxiosResponse[]).every((res) => res.status === 200)) {
				navigate(`/groupSetting/${groupRes.data.groups[0].group_Id}`)
				props.setShow(false)
			}
		}
	}

	return (
		<Formik initialValues={group} validationSchema={groupValidationSchema} onSubmit={handleSubmit} enableReinitialize>
			{({ handleSubmit, isSubmitting, touched, errors, handleChange, setFieldValue, values, handleReset, dirty, isValid }) => (
				<Modal
					show={props.show}
					onHide={() => {
						if (!isSubmitting) {
							handleReset()
							props.setShow(false)
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>Add New Group</Modal.Title>
					</Modal.Header>

					{pageStatus === 'Loading' || pageStatus == 'Submitting' ? (
						<Row style={styles.loadingRow}>
							<Col />
							<Col sm="auto">
								<Loading show={true} />
							</Col>
							<Col />
						</Row>
					) : (
						<Modal.Body>
							<FormText
								name={'group_Name'}
								value={values.group_Name ? values.group_Name : ''}
								label={'Group Name'}
								onChange={handleChange}
								required
								feedback={touched.group_Name && errors.group_Name ? errors.group_Name : ''}
								isInvalid={touched.group_Name && !!errors.group_Name}
							/>

							<FormSelect
								name={'groupType'}
								label={'Group Type'}
								options={groupTypeDropdownValues}
								value={values.groupType_Id}
								onChange={(e) => {
									setFieldValue('groupType_Id', (e as SelectOption<string>).value)
								}}
								required
								feedback={touched.groupType_Id && errors.groupType_Id ? errors.groupType_Id : ''}
							/>
						</Modal.Body>
					)}

					<Modal.Footer>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleReset()
								setGroup(defaultFormValues)
								props.setShow(false)
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={isSubmitting || !dirty || !isValid}
							onClick={() => {
								handleSubmit()
								setGroup(defaultFormValues)
							}}
						>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	loadingRow: {
		margin: '20px',
	},
}

export { AddGroupModal }
