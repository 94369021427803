import React from 'react'
import { Asset } from '../../../models/Asset'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { AppContext } from '../../../App'
import { PageStatus } from '../../../types/PageStatus'
import { Reading, ReadingApiWindow, ReadingApiWindowSelectOptions, ReadingResult, defaultReading } from '../../../models/Reading'
import { readingType } from '../../../constants/readingType'
import * as Request from '../../../utilities/request'
import Select from 'react-select'
import { Chart, ChartOptions, ScaleOptionsByType, CartesianScaleTypeRegistry, CartesianScaleOptions, CartesianTickOptions, ChartDataset } from 'chart.js'
import 'chartjs-adapter-luxon'
import htmlLegendPlugin from './htmlLegend'
import { Line } from 'react-chartjs-2'
import { formatIncomingDateTime, formatOutgoingDateTime } from '../../../utilities/formatDate'
import { DateTime } from 'luxon'
import { useInterval } from '../../../utilities/useInterval'
import { Loading } from '../Loading/Loading'
import { colors } from '../../../constants/colors'
import { FormDate } from '../Form/Date'
import { calculateSuggestedMax, calculateSuggestedMin } from '../../../utilities/graphHelpers'

const getAllCharts = () => Object.keys(Chart.instances).map((key) => Chart.instances[key])

const getCommonChartOptions = (xAxisMin: number, xAxisMax: number, onMove: (context: { chart: Chart }) => void): ChartOptions<'line'> => ({
	animation: false,
	parsing: false,
	normalized: true,
	interaction: {
		mode: 'nearest',
		axis: 'x',
		intersect: false,
	},
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			caretPadding: 500,
			yAlign: 'bottom',
			callbacks: {
				label: (ctx) => {
					const value = (ctx.dataset.data[ctx.dataIndex] as { x: number; y: number | null }).y
					return value ? `${value.toFixed(3)} V` : ''
				},
			},
		},
		legend: {
			display: false,
		},
		zoom: {
			pan: {
				enabled: true,
				mode: 'x',
				onPan: onMove,
			},
			zoom: {
				wheel: {
					enabled: true,
				},
				pinch: {
					enabled: true,
				},
				mode: 'x',
				onZoom: onMove,
			},
			limits: {
				x: { min: xAxisMin, max: xAxisMax },
			},
		},
		decimation: {
			enabled: false, // TODO: Play around with decimation thresholds to make graph more performant with big data sets
			algorithm: 'lttb',
			samples: 400,
			threshold: 400,
		},
		datalabels: {
			display: false,
		},
	},
})

const getCommonXScaleOptions = (xAxisMin: number, xAxisMax: number, timezone: string) =>
	({
		type: 'time',
		time: {
			tooltipFormat: 'dd/MM/yyyy h:mm:ss.SSS a',
			displayFormats: {
				millisecond: 'h:mm:ss:SSS a',
				second: 'h:mm:ss a',
				minute: 'h:mm a',
				hour: 'dd/MM h a',
				day: 'dd/MM/yy',
				week: 'dd/MM/yy',
				month: 'MM/yyyy',
				quarter: 'MM/yyyy',
				year: 'yyyy',
			},
		},
		adapters: {
			date: {
				zone: timezone,
			},
		},
		title: {
			display: true,
			text: 'Timestamps',
		},
		min: xAxisMin,
		max: xAxisMax,
		ticks: {
			minRotation: 45,
			maxRotation: 45,
		},
	}) as unknown as ScaleOptionsByType<keyof CartesianScaleTypeRegistry>

const getCommonYScaleOptions = (): Partial<CartesianScaleOptions> => ({
	grid: {
		color: (ctx) => {
			if (ctx.tick.value === 0) {
				return 'rgba(0, 0, 0, 0.5)'
			}

			return 'rgba(0, 0, 0, 0.1)'
		},
	},
	ticks: {
		minRotation: 0,
		maxRotation: 0,
	} as CartesianTickOptions,
})

interface AssetLiveVoltageGraphProps {
	asset: Asset
}

const AssetLiveVoltageGraph = (props: AssetLiveVoltageGraphProps) => {
	const context = React.useContext(AppContext)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [readingsVoltage, setReadingsVoltage] = React.useState<Reading[]>([])
	const [window, setWindow] = React.useState<ReadingApiWindow>(ReadingApiWindow.DAY)
	const [customWindow, setCustomWindow] = React.useState<string>(DateTime.now().toFormat('yyyy-MM-dd'))
	const [nowUtc, setNowUtc] = React.useState<DateTime>(DateTime.utc())

	// Options to not show in the dropdown due to large gap between voltage readings
	const ReadingApiWindowExclusions = [ReadingApiWindow.HOUR, ReadingApiWindow.FIVE_MINUTE]

	const getData = async () => {
		setPageStatus('Loading')
		try {
			const windowString =
				window.value === ReadingApiWindow.CUSTOM.value
					? formatOutgoingDateTime({ dateTime: customWindow, format: 'DateString', timeZone: context.appState.currentSite?.site_Timezone })
					: window.value
			const readingVoltageReq = await Request.get<ReadingResult>(
				`reading?Asset_Id=${props.asset.asset_Id}&ReadingType_Id=${readingType['Battery Voltage Reading'].id}&Window=${windowString}`,
				context.appState.authState
			)
			setReadingsVoltage(readingVoltageReq.data.readings)
			setNowUtc(DateTime.utc())
			setPageStatus('Ready')
		} catch (e) {
			console.log(e)
			setPageStatus('Error')
		}
	}

	React.useEffect(() => {
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props, window, window == ReadingApiWindow.CUSTOM ? customWindow : undefined])

	useInterval(
		async () => {
			setPageStatus('Submitting')
			const readingVoltageReq = await Request.get<ReadingResult>(
				`reading?Asset_Id=${props.asset.asset_Id}&ReadingType_Id=${readingType['Battery Voltage Reading'].id}&Window=${window.value}&SinceUtcTs=${
					readingsVoltage.length > 0 ? readingsVoltage.at(-1)?.reading_Ts : nowUtc.toISO()
				}`,
				context.appState.authState
			)
			const newNowUtc = DateTime.utc()
			setReadingsVoltage((readingsTemp) => [
				...readingsTemp.filter(
					(reading) =>
						parseInt(formatIncomingDateTime({ dateTime: reading.reading_Ts, format: 'Custom', customFormat: 'x' })) >
						newNowUtc.minus({ minutes: 5 }).toMillis()
				),
				...readingVoltageReq.data.readings,
			])
			setNowUtc(newNowUtc)
			setPageStatus('Ready')
		},
		pageStatus !== 'Loading' && window === ReadingApiWindow.FIVE_MINUTE ? 5000 : null
	)

	/*
		mapReading maps a Reading to a Chart.js data point
	*/
	const mapReading = (reading: Reading) => {
		return {
			x: parseInt(
				formatIncomingDateTime({
					dateTime: reading.reading_Ts,
					format: 'Custom',
					timeZone: context.appState.currentSite?.site_Timezone,
					customFormat: 'x',
				})
			),
			y: reading.reading_Data >= 0 ? reading.reading_Data / 1000 : null, // TODO: Convert to fahrenheit based on site setting
		}
	}

	/*
		addGaps creates gaps between 2 data points if the difference between them is greater than 48 hours
	*/
	const addGaps = (accumulator: Reading[], currentValue: Reading, index: number, array: Reading[]) => {
		accumulator.push(currentValue)

		if (index < array.length - 1) {
			const currTs = DateTime.fromISO(currentValue.reading_Ts)
			const nextTs = DateTime.fromISO(array[index + 1].reading_Ts)

			if (nextTs.diff(currTs, 'hour').hours >= 48) {
				accumulator.push(
					defaultReading({
						...currentValue,
						reading_Ts: currentValue.reading_Ts,
						reading_Data: -1,
					})
				)
			}
		}

		return accumulator
	}

	const voltageReadings = React.useMemo(() => readingsVoltage.reduce(addGaps, []).map(mapReading), [readingsVoltage])

	const xAxisMin = React.useMemo(() => {
		let date: DateTime = nowUtc
		switch (window.value) {
			case 'month':
				date = nowUtc.minus({ months: 1 })
				break
			case 'week':
				date = nowUtc.minus({ weeks: 1 })
				break
			case 'day':
				date = nowUtc.minus({ hours: 24 })
				break
			case 'hour':
				date = nowUtc.minus({ hours: 1 })
				break
			case 'fiveMinute':
				date = nowUtc.minus({ minutes: 5 })
				break
			case 'custom':
				date = DateTime.fromFormat(customWindow, 'yyyy-MM-dd', { zone: context.appState.currentSite?.site_Timezone })
				break
			default:
				break
		}
		return parseInt(
			formatIncomingDateTime({
				dateTime: date.toISO() || '',
				format: 'Custom',
				timeZone: context.appState.currentSite?.site_Timezone,
				customFormat: 'x',
			})
		)
	}, [nowUtc])

	const xAxisMax = React.useMemo(() => {
		const date =
			window === ReadingApiWindow.CUSTOM
				? DateTime.fromFormat(customWindow, 'yyyy-MM-dd', { zone: context.appState.currentSite?.site_Timezone }).plus({ hours: 24 })
				: nowUtc
		return parseInt(
			formatIncomingDateTime({
				dateTime: date.toISO() || '',
				format: 'Custom',
				timeZone: context.appState.currentSite?.site_Timezone,
				customFormat: 'x',
			})
		)
	}, [nowUtc])

	const onChartMove = (context: { chart: Chart }) => {
		const min = context.chart.scales.x.min
		const max = context.chart.scales.x.max

		getAllCharts().forEach((chart) => {
			if (chart.id !== context.chart.id && chart.options.scales?.x) {
				chart.options.scales.x.min = min
				chart.options.scales.x.max = max
				chart.update()
			}
		})
	}

	const resetChartZoom = () => {
		getAllCharts().forEach((chart) => {
			if (chart.options.scales?.x) {
				chart.options.scales.x.min = xAxisMin
				chart.options.scales.x.max = xAxisMax
				chart.update()
			}
		})
	}

	const voltageChartOptions: ChartOptions<'line'> = React.useMemo(() => {
		const commonOptions = getCommonChartOptions(xAxisMin, xAxisMax, onChartMove)
		return {
			...commonOptions,
			plugins: {
				...commonOptions.plugins,
				htmlLegend: {
					containerID: 'legend-container',
				},
			},
			scales: {
				x: {
					...getCommonXScaleOptions(xAxisMin, xAxisMax, props.asset.site_Timezone),
				},
				y: {
					...getCommonYScaleOptions(),
					title: { display: true, text: 'Voltage (V)' },
					suggestedMin: calculateSuggestedMin(voltageReadings),
					suggestedMax: calculateSuggestedMax(voltageReadings),
				},
			},
		}
	}, [voltageReadings, xAxisMin, xAxisMax])

	const voltageDataSet: ChartDataset<
		'line',
		{
			x: number
			y: number | null
		}[]
	>[] = React.useMemo(() => {
		const dataSet: ChartDataset<
			'line',
			{
				x: number
				y: number | null
			}[]
		>[] = []

		if (voltageReadings.length > 0) {
			dataSet.push({
				label: 'Battery Voltage',
				borderColor: colors.colorStatusBlue,
				backgroundColor: colors.colorStatusBlue,
				data: voltageReadings,
				indexAxis: 'x',
				parsing: false,
			})
		}

		if (voltageReadings.length === 0) {
			dataSet.push({
				label: 'No data to display',
				data: [],
				indexAxis: 'x',
				parsing: false,
			})
		}

		return dataSet
	}, [voltageReadings])

	return (
		<Card style={styles.card}>
			{pageStatus === 'Loading' ? (
				<Loading show />
			) : (
				<Row>
					<Col sm={10} style={styles.graph}>
						<Line
							data={{
								datasets: voltageDataSet,
							}}
							options={voltageChartOptions}
							plugins={[htmlLegendPlugin]}
						/>
					</Col>

					<Col sm={2} className="d-flex flex-column">
						<Select
							value={window}
							onChange={(e) => setWindow(e as ReadingApiWindow)}
							options={ReadingApiWindowSelectOptions.filter((option) => !ReadingApiWindowExclusions.some((exclusion) => option === exclusion))}
						/>
						{window === ReadingApiWindow.CUSTOM && (
							<>
								<FormDate
									name="CustomWindow"
									label={''}
									value={customWindow}
									onChange={(e) => setCustomWindow(e.target.value)}
									className="w-100 mt-3"
								/>
							</>
						)}
						<Button variant="secondary" className="w-100 mt-3" onClick={resetChartZoom}>
							Reset Zoom
						</Button>

						{window === ReadingApiWindow.FIVE_MINUTE && (
							<Row className="mt-3">
								<Col className="center-flex-col">
									<span>Polling for New Readings</span>
								</Col>
							</Row>
						)}

						<Row className="flex-fill d-flex">
							<Col className="d-flex align-items-center">
								{/* This has a legend inserted by htmlLegendPlugin using the id for the chart */}
								<div id="legend-container"></div>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
		</Card>
	)
}

const styles = {
	card: {
		minHeight: '400px',
	},
	graph: {
		height: '400px',
	},
} satisfies Record<string, React.CSSProperties>
export default AssetLiveVoltageGraph
