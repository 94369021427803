import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { formatIncomingDateTime } from '../../../utilities/formatDate'

import { Loading } from '../Loading/Loading'

import { Asset } from '../../../models/Asset'
import { AssetTemperatureReading as IAssetTemperatureReading, AssetTemperatureReadingResult } from '../../../models/Stats'

import thermometer from '../../../images/icons/readings.svg'

interface AssetTemperatureReadingProps {
	asset: Asset | null
}

const AssetTemperatureReading = (props: AssetTemperatureReadingProps) => {
	const context = React.useContext(AppContext)

	const [data, setData] = React.useState<IAssetTemperatureReading | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			try {
				const statsResponse = await Request.get<AssetTemperatureReadingResult>(
					`stats/assetTemperatureReading?Asset_Id=${props.asset?.asset_Id}`,
					context.appState.authState
				)
				setData(statsResponse.data)
			} catch (e) {
				console.log(e)
			}
		}
		if (props.asset && context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [props.asset, context.appState.authState])

	return (
		<Card style={styles.card}>
			{data ? (
				<Row>
					<Col style={styles.col}>
						<img src={thermometer} alt="A thermometer icon" style={styles.image}></img>
						{data.lastReadingTempMixed ? (
							<>
								<p style={styles.valueBig}>{data.lastReadingTempMixed.reading_Data}&deg;C</p>
								<p style={styles.labelSmall}>
									Last reading {formatIncomingDateTime({ dateTime: data.lastReadingTempMixed.reading_Ts, format: 'TimeAgo' })}
								</p>
							</>
						) : (
							<p>No readings yet</p>
						)}
					</Col>
					<Col style={styles.col}>
						<h1>TMV Temperature</h1>
						<p style={styles.label}>Mixed filter temperature</p>
						<p style={styles.value}>{data.groupMixedFilterTemperature.toFixed(1)}&deg;C</p>
						<p style={styles.label}>Temperature exception high</p>
						<p style={styles.value}>{data.groupTemperatureExceptionHigh.toFixed(1)}&deg;C</p>
						<p style={styles.label}>Temperature Events</p>
						<p style={styles.label}>
							<span style={styles.value}>{data.weeklyEventCount}</span> in last 7 days
						</p>
					</Col>
				</Row>
			) : (
				<Loading show />
			)}
		</Card>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	card: {
		marginTop: '20px',
		minHeight: '200px',
		flexBasis: '49%',
		padding: '30px 10px',
	},
	image: {
		height: '60px',
	},
	col: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	label: {
		marginBottom: '0px',
		lineHeight: 'unset',
	},
	labelSmall: {
		fontSize: '10px',
	},
	value: {
		fontWeight: 'bold',
		marginBottom: '.5rem',
		lineHeight: 'unset',
	},
	valueBig: {
		fontSize: '40px',
		lineHeight: 'unset',
		marginBottom: '0px',
	},
}

export { AssetTemperatureReading }
