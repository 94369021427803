import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from '../Button/Button'

interface ConfirmModalProps {
	show: boolean
	setShow: React.Dispatch<React.SetStateAction<boolean>>
	title?: string
	body: string
	confirmButtonText?: string
	cancelButtonText?: string
	onConfirm: () => void
}

export const ConfirmModal = (props: ConfirmModalProps) => {
	return (
		<Modal show={props.show}>
			{props.title && (
				<Modal.Header>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body>{props.body}</Modal.Body>
			<Modal.Footer>
				<Button
					variant="secondary"
					onClick={() => {
						props.setShow(false)
					}}
				>
					{props.cancelButtonText || 'Cancel'}
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						props.onConfirm()
						props.setShow(false)
					}}
				>
					{props.confirmButtonText || 'Confirm'}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
