import React from 'react'
import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { v4 as uuidv4 } from 'uuid'
import { Button, Col, Form, ProgressBar, Row } from 'react-bootstrap'
import { Back } from '../../components/UI/Back/Back'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { Contract, ContractResult, defaultContract } from '../../models/Contract'
import circleEmpty from '../../images/icons/circle_empty.svg'
import circleEllipsis from '../../images/icons/circle_ellipsis.svg'
import circleTick from '../../images/icons/circle_tick.svg'
import { CustomerForm, CustomerValidationSchema } from '../../components/UI/ContractWizard/CustomerForm'
import { ContractForm, ContractValidationSchema } from '../../components/UI/ContractWizard/ContractForm'
import { defaultCreated, defaultModified } from '../../models/History'
import { recordStatus } from '../../constants/recordStatus'
import { module } from '../../constants/module'
import { ContractModuleResult, defaultContractModule } from '../../models/ContractModule'
import { PaymentAgreementResult, defaultPaymentAgreement } from '../../models/PaymentAgreement'
import { DateTime } from 'luxon'
import { PaymentAgreementValidationSchema, PaymentAgreementsForm } from '../../components/UI/ContractWizard/PaymentAgreementsForm'
import { ContactForm, ContactValidationSchema } from '../../components/UI/ContractWizard/ContactForm'
import { ReviewContract } from '../../components/UI/ContractWizard/ReviewContract'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { useNavigate } from 'react-router'
import { formatIncomingDateTime } from '../../utilities/formatDate'

interface FormStepProps {
	formikProps: FormikProps<Contract>
	setActiveStep?: React.Dispatch<React.SetStateAction<number>>
	siteSelect?: React.ReactNode
}

const steps = ['Customer', 'Contract', 'Agreements', 'Contact', 'Review']

const validationSchemaArray = [CustomerValidationSchema, ContractValidationSchema, PaymentAgreementValidationSchema, ContactValidationSchema]

const renderStepContent = (step: number, formStepProps: FormStepProps, setActiveStep: React.Dispatch<React.SetStateAction<number>>) => {
	switch (step) {
		case 0:
			return <CustomerForm {...formStepProps} />
		case 1:
			return <ContractForm {...formStepProps} />
		case 2:
			return <PaymentAgreementsForm {...formStepProps} />
		case 3:
			return <ContactForm {...formStepProps} />
		case 4:
			return <ReviewContract {...formStepProps} setActiveStep={setActiveStep} />
		default:
			return <div>Not Found</div>
	}
}

const ContractWizard = () => {
	const context = React.useContext(AppContext)
	const navigate = useNavigate()
	const [messages, setMessages] = useMessageReducer([])

	const newContract_Id = uuidv4()
	const initialValues = defaultContract({
		contract_Id: newContract_Id,
		contract_StartDate: formatIncomingDateTime({
			dateTime: DateTime.now().toISO({ includeOffset: false }) || '',
			format: 'FileDate',
		}),
		contract_EndDate: formatIncomingDateTime({
			dateTime: DateTime.now().plus({ years: 1 }).toISO({ includeOffset: false }) || '',
			format: 'FileDate',
		}),
		contract_Modules: Object.values(module).map((m) =>
			defaultContractModule({
				contract_Id: newContract_Id,
				module_Id: m.id,
				contractModule_AssetRate: m.defaultAssetRate,
				created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
				modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
				recordStatus_Id: recordStatus.Active.id,
			})
		),
		contract_PaymentAgreements: [
			defaultPaymentAgreement({
				contract_Id: newContract_Id,
				paymentAgreement_StartDate: formatIncomingDateTime({
					dateTime: DateTime.now().toISO({ includeOffset: false }) || '',
					format: 'FileDate',
				}),
				paymentAgreement_EndDate: formatIncomingDateTime({
					dateTime: DateTime.now().plus({ years: 1 }).toISO({ includeOffset: false }) || '',
					format: 'FileDate',
				}),
				created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
				modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
				recordStatus_Id: recordStatus.Active.id,
			}),
		],
		created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
		recordStatus_Id: recordStatus.Active.id,
	})

	const [activeStep, setActiveStep] = React.useState(0)
	const currentValidationSchema = validationSchemaArray[activeStep]
	const isLastStep = activeStep === steps.length - 1

	const submitForm = async (values: Contract) => {
		let stopSubmit = false

		// submit the Contract
		await Request.handleRequest(
			() =>
				Request.post<ContractResult>(
					'contract',
					{ ...values, contract_BuyerABN: values.contract_BuyerABN?.replace(/\s/g, '') }, // Trim all spaces from the ABN
					context.appState.authState
				),
			{
				failedFunction: () => {
					stopSubmit = true
				},
			}
		)

		// submit the Contract Modules
		if (!stopSubmit) {
			await Promise.all(
				values.contract_Modules.map(async (contract_Module) => {
					await Request.handleRequest(() => Request.post<ContractModuleResult>('contractModule', contract_Module, context.appState.authState), {
						failedFunction: () => {
							stopSubmit = true
						},
					})
				})
			)
		}

		// submit the Payment Agreements
		if (!stopSubmit) {
			await Promise.all(
				values.contract_PaymentAgreements.map(async (paymentAgreement) => {
					await Request.handleRequest(() => Request.post<PaymentAgreementResult>('paymentAgreement', paymentAgreement, context.appState.authState), {
						failedFunction: () => {
							stopSubmit = true
						},
					})
				})
			)
		}

		if (stopSubmit) {
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Error creating the contract`,
					dismissible: true,
					timeout: 5000,
				},
			})
		} else {
			setMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: `Successfully created the contract`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}

		navigate(`/contract/${values.contract_Id}`)
	}

	const handleSubmit = async (values: Contract, formikHelpers: FormikHelpers<Contract>) => {
		if (isLastStep) {
			await submitForm(values)
		} else {
			setActiveStep(activeStep + 1)
			formikHelpers.setTouched({})
			formikHelpers.setSubmitting(false)
		}
	}

	const handleBack = () => {
		setActiveStep(activeStep - 1)
	}

	return (
		<>
			<Messages messages={messages} updateMessage={setMessages} />

			<Row style={styles.ContractDashboardHeader}>
				<Col>
					<Row>
						<Col>
							<Back />
						</Col>
					</Row>
					<Row style={styles.headerText}>
						<Col>
							<span style={styles.headerTextLarge} id="user-welcome">
								Provision Contract
							</span>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col />
				<Col sm={4}>
					<Row>
						<Col>
							<Row>
								<Col>
									<span>Contract Status</span>
								</Col>
							</Row>
							<Row>
								<Col>
									<span className="span-bold">{Math.min(activeStep * 25, 100)}% Complete</span>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>
									<ProgressBar now={Math.min(activeStep * 25, 100)} />
								</Col>
								<Col sm="auto">
									<span>{Math.min(activeStep + 1, 4)} of 4</span>
								</Col>
							</Row>
							{!isLastStep && (
								<Row className="mb-3 p-2">
									{steps.slice(0, 4).map((step, index) => (
										<Col
											key={index}
											className="center-flex py-2"
											style={{ ...styles.stepName, ...(index === activeStep ? styles.stepNameActive : undefined) }}
										>
											<img src={index < activeStep ? circleTick : index === activeStep ? circleEllipsis : circleEmpty} className="me-2" />
											{step}
										</Col>
									))}
								</Row>
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							<Formik initialValues={initialValues} validationSchema={currentValidationSchema} onSubmit={handleSubmit} validateOnBlur>
								{(formikProps) => {
									const { isSubmitting } = formikProps

									return (
										<Form>
											{renderStepContent(activeStep, { formikProps }, setActiveStep)}

											<Row className="mt-3">
												{activeStep !== 0 && (
													<Col sm="auto">
														<Button onClick={handleBack}>Back</Button>
													</Col>
												)}
												<Col />
												{/* <Col sm="auto">
													<Button
														disabled={isSubmitting}
														onClick={() => {
															console.log('values:', formikProps.values)
															console.log('errors:', formikProps.errors)
															console.log('touched:', formikProps.touched)
														}}
													>
														log
													</Button>
												</Col> */}
												<Col sm="auto">
													<Button disabled={isSubmitting} onClick={() => formikProps.handleSubmit()}>
														{isLastStep ? 'Submit' : 'Next'}
													</Button>
												</Col>
											</Row>
										</Form>
									)
								}}
							</Formik>
						</Col>
					</Row>
				</Col>
				<Col />
			</Row>
		</>
	)
}

const styles = {
	ContractDashboardHeader: {
		height: '157px',
		paddingTop: '25px',
		paddingLeft: '50px',
		paddingRight: '50px',
	},
	headerText: {
		paddingTop: '10px',
	},
	headerTextLarge: {
		fontSize: '26px',
	},
	stepName: { backgroundColor: 'white' },
	stepNameActive: { boxShadow: '0 2px 0 black' },
} satisfies Record<string, React.CSSProperties>

export type { FormStepProps }
export default ContractWizard
