import React, { useContext } from 'react'
import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { PageStatus } from '../../types/PageStatus'
import { Loading } from '../../components/UI/Loading/Loading'
import { User, UserResult } from '../../models/User'
import { UserSiteResult, generateUserSiteDescription } from '../../models/UserSite'
import { UserList } from './UserList'

const ScreensUserManagement = () => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [users, setUsers] = React.useState<User[] | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [usersResponse, userSiteResponse] = await Promise.all([
				Request.get<UserResult>(`user`, context.appState.authState),
				Request.get<UserSiteResult>(`userSite`, context.appState.authState),
			])

			// Generate UserSites_Description
			usersResponse.data.users.forEach((user) => {
				user.userSites_Description = generateUserSiteDescription(user, userSiteResponse.data.userSites)
			})
			setUsers(usersResponse.data.users)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	return (
		<div className="page">
			{pageStatus !== 'Loading' && users ? (
				<>
					<UserList type="System" title={`All Users`} users={users} setUsers={setUsers} />
				</>
			) : (
				<Loading show={true} />
			)}
		</div>
	)
}

export { ScreensUserManagement }
