export const ReadingTypeNameValues = [
	'Battery Voltage Reading',
	'Flow Reading Hot',
	'Flow Reading Cold',
	'Flow Reading Mixed',
	'Temperature Reading Hot',
	'Temperature Reading Cold',
	'Temperature Reading Mixed',
	'Pressure Reading',
	'Error',
	'Packet Reading',
] as const
export type ReadingTypeName = (typeof ReadingTypeNameValues)[number]

export const ReadingTypeIdValues = [
	'tempRead-hot0-0000-0000-000000000000',
	'tempRead-cold-0000-0000-000000000000',
	'tempRead-mix0-0000-0000-000000000000',
	'flowRead-hot0-0000-0000-000000000000',
	'flowRead-cold-0000-0000-000000000000',
	'flowRead-mix0-0000-0000-000000000000',
	'battRead-0000-0000-0000-000000000000',
	'presRead-0000-0000-0000-000000000000',
	'error000-0000-0000-0000-000000000000',
	'packet00-0000-0000-0000-000000000000',
] as const
export type ReadingTypeId = (typeof ReadingTypeIdValues)[number]

export type ReadingType = { id: ReadingTypeId }

export const readingType: Record<ReadingTypeName, ReadingType> = {
	'Battery Voltage Reading': { id: 'battRead-0000-0000-0000-000000000000' },
	'Pressure Reading': { id: 'presRead-0000-0000-0000-000000000000' },
	'Flow Reading Hot': { id: 'flowRead-hot0-0000-0000-000000000000' },
	'Flow Reading Cold': { id: 'flowRead-cold-0000-0000-000000000000' },
	'Flow Reading Mixed': { id: 'flowRead-mix0-0000-0000-000000000000' },
	'Temperature Reading Hot': { id: 'tempRead-hot0-0000-0000-000000000000' },
	'Temperature Reading Cold': { id: 'tempRead-cold-0000-0000-000000000000' },
	'Temperature Reading Mixed': { id: 'tempRead-mix0-0000-0000-000000000000' },
	Error: { id: 'error000-0000-0000-0000-000000000000' },
	'Packet Reading': { id: 'packet00-0000-0000-0000-000000000000' },
}

export const FlowReadingTypes = [readingType['Flow Reading Cold'], readingType['Flow Reading Hot'], readingType['Flow Reading Mixed']]
export const TempReadingTypes = [readingType['Temperature Reading Cold'], readingType['Temperature Reading Hot'], readingType['Temperature Reading Mixed']]

export const isFlowReadingType = (id: ReadingTypeId) => FlowReadingTypes.some((readingType) => readingType.id === id)
export const isTempReadingType = (id: ReadingTypeId) => TempReadingTypes.some((readingType) => readingType.id === id)
export const isPressureReadingType = (id: ReadingTypeId) => readingType['Pressure Reading'].id === id

export const readingTypeDropdownValues = ReadingTypeNameValues.map((value) => ({
	value: readingType[value].id,
	label: value,
}))

export const readingToLabelColour = (reading_Data: number | undefined) => {
	if (!reading_Data) {
		return ''
	}

	if (reading_Data >= 38) {
		return 'Hot'
	} else if (reading_Data > 28) {
		return 'Warm'
	} else if (reading_Data > 1) {
		return 'Cold'
	} else {
		return ''
	}
}

export const readingTypeIdToLabel = (readingTypeId: string | undefined) => {
	switch (readingTypeId) {
		case readingType['Temperature Reading Mixed'].id:
			return 'Warm'
		case readingType['Temperature Reading Hot'].id:
			return 'Hot'
		case readingType['Temperature Reading Cold'].id:
			return 'Cold'
		default:
			return ''
	}
}
