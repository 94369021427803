export const SiteStatusValues = ['Audit', 'Live'] as const
export type SiteStatus = (typeof SiteStatusValues)[number]

export const SiteStatusIdValues = ['audit000-0000-0000-0000-000000000000', 'live0000-0000-0000-0000-000000000000'] as const
export type SiteStatusId = (typeof SiteStatusIdValues)[number]

export const siteStatus: Record<SiteStatus, { id: SiteStatusId }> = {
	Audit: { id: 'audit000-0000-0000-0000-000000000000' },
	Live: { id: 'live0000-0000-0000-0000-000000000000' },
}

export const siteStatusDropdownValues = SiteStatusValues.map((value) => ({
	value: siteStatus[value].id,
	label: value,
}))
