import React from 'react'
import { TemperatureException } from '../../../models/Report'
import { Col, Row } from 'react-bootstrap'
import { AppContext } from '../../../App'
import { Reading, ReadingResult } from '../../../models/Reading'
import * as Request from '../../../utilities/request'
import { PageStatus } from '../../../types/PageStatus'
import { Loading } from '../Loading/Loading'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import 'chartjs-adapter-luxon'
import { Line } from 'react-chartjs-2'
import { colors } from '../../../constants/colors'
import { calculateSuggestedMax, calculateSuggestedMin } from '../../../utilities/graphHelpers'
import { secondsToReadable } from '../../../utilities/strings'

interface TemperatureExceptionEventDetailProps {
	temperatureException: TemperatureException
	width: string
	height: string
	commentBox?: React.ReactNode
}

interface PointData {
	x: number
	y: number
}

// The readings calls fetch reading 3 minutes before and after the start/finish timestamp, this constant
// adds additional padding to the x-axis min/max to allow those readings to be displayed
const threeMinsThirtySeconds = 210000

const TemperatureExceptionEventDetail = (props: TemperatureExceptionEventDetailProps) => {
	const context = React.useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [eventReadings, setEventReadings] = React.useState<Reading[]>([])

	React.useEffect(() => {
		const getData = async () => {
			const [readingReq] = await Promise.all([
				Request.get<ReadingResult>(
					`reading/temperatureException?Asset_Id=${props.temperatureException.asset_Id}&ExceptionStartTs=${props.temperatureException.startTs}&ExceptionFinishTs=${props.temperatureException.finishTs}`,
					context.appState.authState
				),
			])

			setEventReadings(readingReq.data.readings)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props.temperatureException])

	const tempExceptionStartEpochMillis = props.temperatureException.startTs
		? parseInt(
				formatIncomingDateTime({
					dateTime: props.temperatureException.startTs,
					format: 'Custom',
					timeZone: props.temperatureException.site_Timezone,
					customFormat: 'x',
				})
		  )
		: 0

	const tempExceptionFinishEpochMillis = props.temperatureException.finishTs
		? parseInt(
				formatIncomingDateTime({
					dateTime: props.temperatureException.finishTs,
					format: 'Custom',
					timeZone: props.temperatureException.site_Timezone,
					customFormat: 'x',
				})
		  )
		: eventReadings.at(-1)
		? parseInt(
				formatIncomingDateTime({
					dateTime: eventReadings.at(-1)?.reading_Ts || '',
					format: 'Custom',
					timeZone: props.temperatureException.site_Timezone,
					customFormat: 'x',
				})
		  )
		: tempExceptionStartEpochMillis

	const readings = React.useMemo(
		() =>
			eventReadings
				.sort((a, b) => (a.reading_Ts < b.reading_Ts ? -1 : 1))
				.map((r) => ({
					x: parseInt(
						formatIncomingDateTime({
							dateTime: r.reading_Ts,
							format: 'Custom',
							timeZone: props.temperatureException.site_Timezone,
							customFormat: 'x',
						})
					),
					y: r.reading_Data, // TODO: Convert to fahrenheit based on site setting
				})),
		[eventReadings]
	)

	const tempExceptionThreshold = React.useMemo(
		() => [
			{
				x: tempExceptionStartEpochMillis - threeMinsThirtySeconds,
				y: props.temperatureException.temperatureExceptionThreshold, // TODO: Convert to fahrenheit based on site setting
			},
			{
				x: tempExceptionFinishEpochMillis + threeMinsThirtySeconds,
				y: props.temperatureException.temperatureExceptionThreshold, // TODO: Convert to fahrenheit based on site setting
			},
		],
		[tempExceptionStartEpochMillis, tempExceptionFinishEpochMillis]
	)

	const setTemperatureThreshold = React.useMemo(
		() => [
			{
				x: tempExceptionStartEpochMillis - threeMinsThirtySeconds,
				y: props.temperatureException.setTemperature, // TODO: Convert to fahrenheit based on site setting
			},
			{
				x: tempExceptionFinishEpochMillis + threeMinsThirtySeconds,
				y: props.temperatureException.setTemperature, // TODO: Convert to fahrenheit based on site setting
			},
		],
		[tempExceptionStartEpochMillis, tempExceptionFinishEpochMillis]
	)

	const setTemperatureThresholdUpper = React.useMemo(
		() => [
			{
				x: tempExceptionStartEpochMillis - threeMinsThirtySeconds,
				y: props.temperatureException.setTemperature + 2, // TODO: Convert to fahrenheit based on site setting
			},
			{
				x: tempExceptionFinishEpochMillis + threeMinsThirtySeconds,
				y: props.temperatureException.setTemperature + 2, // TODO: Convert to fahrenheit based on site setting
			},
		],
		[tempExceptionStartEpochMillis, tempExceptionFinishEpochMillis]
	)

	const setTemperatureThresholdLower = React.useMemo(
		() => [
			{
				x: tempExceptionStartEpochMillis - threeMinsThirtySeconds,
				y: props.temperatureException.setTemperature - 2, // TODO: Convert to fahrenheit based on site setting
			},
			{
				x: tempExceptionFinishEpochMillis + threeMinsThirtySeconds,
				y: props.temperatureException.setTemperature - 2, // TODO: Convert to fahrenheit based on site setting
			},
		],
		[tempExceptionStartEpochMillis, tempExceptionFinishEpochMillis]
	)

	const colorStatusGreenOpacity = 'rgba(54, 179, 127, 0.2)' // colors.colorStatusGreen with opacity

	return pageStatus === 'Ready' || pageStatus === 'Submitting' ? (
		<>
			<Row>
				<Col>
					<Row>
						<Col className="d-flex flex-column">
							<span className="span-bold">TMV ID</span>
							<span>{props.temperatureException.asset_Name}</span>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col className="d-flex flex-column">
							<span className="span-bold">TMV Location</span>
							<span>{props.temperatureException.asset_Location}</span>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col className="d-flex flex-column text-nowrap">
							<span className="span-bold">Exception Details</span>
							<span>
								{`Started at ${formatIncomingDateTime({
									dateTime: props.temperatureException.startTs || '',
									format: 'DateAndTimeWithSeconds',
									timeZone: props.temperatureException.site_Timezone,
								})}`}
							</span>
							<span>
								{`and ended at ${formatIncomingDateTime({
									dateTime: props.temperatureException.finishTs || '',
									format: 'DateAndTimeWithSeconds',
									timeZone: props.temperatureException.site_Timezone,
								})}`}
							</span>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col className="d-flex flex-column">
							<span className="span-bold">Duration</span>
							<span>
								{props.temperatureException.duration
									? secondsToReadable(props.temperatureException.duration)
									: 'Missing Start or Finish timestamp'}
							</span>
						</Col>
					</Row>
					{props.commentBox && (
						<Row className="mt-2">
							<Col className="d-flex flex-column">
								<span className="span-bold">Comments</span>
								{props.commentBox}
							</Col>
						</Row>
					)}
				</Col>
				<Col style={styles.graphCol}>
					<Line
						width={props.width}
						height={props.height}
						data={{
							datasets: [
								{
									label: 'Mixed',
									borderColor: colors.colorStatusYellow,
									backgroundColor: colors.colorStatusYellow,
									data: readings,
									indexAxis: 'x',
									parsing: false,
								},
								{
									label: 'Temperature Exception Threshold',
									borderColor: colors.colorStatusRed,
									backgroundColor: colors.colorStatusRed,
									data: tempExceptionThreshold,
									indexAxis: 'x',
									parsing: false,
									pointStyle: 'line',
								},
								{
									label: 'Set Temperature Upper Bound',
									borderColor: colorStatusGreenOpacity,
									backgroundColor: colorStatusGreenOpacity,
									data: setTemperatureThresholdUpper,
									indexAxis: 'x',
									parsing: false,
									pointStyle: 'line',
									fill: { value: props.temperatureException.setTemperature - 2 },
								},
								{
									label: 'Set Temperature',
									borderColor: colors.colorStatusGreen,
									backgroundColor: colors.colorStatusGreen,
									data: setTemperatureThreshold,
									indexAxis: 'x',
									parsing: false,
									pointStyle: 'line',
								},
								{
									label: 'Set Temperature Lower Bound',
									borderColor: colorStatusGreenOpacity,
									backgroundColor: colorStatusGreenOpacity,
									data: setTemperatureThresholdLower,
									indexAxis: 'x',
									parsing: false,
									pointStyle: 'line',
									fill: { value: props.temperatureException.setTemperature + 2 },
								},
							],
						}}
						options={{
							parsing: false,
							normalized: true,
							interaction: {
								mode: 'nearest',
								axis: 'x',
								intersect: false,
							},
							maintainAspectRatio: false,
							scales: {
								x: {
									type: 'time',
									time: {
										tooltipFormat: 'dd/MM/yyyy h:mm:ss.SSS a',
										displayFormats: {
											millisecond: 'h:mm:ss:SSS a',
											second: 'h:mm:ss a',
											minute: 'h:mm a',
											hour: 'dd/MM h a',
											day: 'dd/MM/yy',
											week: 'dd/MM/yy',
											month: 'MM/yyyy',
											quarter: 'MM/yyyy',
											year: 'yyyy',
										},
									},
									adapters: {
										date: {
											zone: props.temperatureException.site_Timezone,
										},
									},
									title: {
										display: true,
										text: 'Timestamps',
									},
									min: tempExceptionStartEpochMillis - threeMinsThirtySeconds,
									max: tempExceptionFinishEpochMillis + threeMinsThirtySeconds,
								},
								y: {
									title: { display: true, text: 'Mixed Temperature (°C)' }, // TODO: Show fahrenheit based on site setting
									suggestedMin: calculateSuggestedMin(readings, true),
									suggestedMax: calculateSuggestedMax(readings, true),
									grid: {
										color: (ctx) => {
											if (ctx.tick.value === 0) {
												return 'rgba(0, 0, 0, 0.5)'
											}

											return 'rgba(0, 0, 0, 0.1)'
										},
									},
								},
							},
							plugins: {
								legend: {
									display: false,
								},
								datalabels: {
									display: (ctx) => {
										if (ctx.dataset.label !== 'Mixed') {
											return false
										}

										if (ctx.dataIndex === 0 || ctx.dataIndex === ctx.dataset.data.length - 1) {
											return true
										}

										const value = (ctx.dataset.data[ctx.dataIndex] as PointData).y
										const allValues = ctx.dataset.data.map((d) => (d as PointData).y)
										const min = Math.min(...allValues, 0)
										const max = Math.max(...allValues, 0)

										if (value === min || value === max) {
											return true
										}

										return false
									},
									formatter: (value) => `${value.y}`,
									anchor: 'center',
									clamp: true,
									align: (ctx) => {
										const value = (ctx.dataset.data[ctx.dataIndex] as PointData).y
										const prev = ctx.dataIndex === 0 ? value : (ctx.dataset.data[ctx.dataIndex - 1] as PointData).y
										const next =
											ctx.dataIndex === ctx.dataset.data.length - 1 ? value : (ctx.dataset.data[ctx.dataIndex + 1] as PointData).y

										// Work out which side of the point has a larger change, then set the label above/below based on +/- change
										// start -> below point, end -> above point
										if (Math.abs(value - prev) > Math.abs(value - next)) {
											if (value - prev > 0) return 'end'
											else return 'start'
										} else if (Math.abs(value - prev) < Math.abs(value - next)) {
											if (value - next > 0) return 'end'
											else return 'start'
										} else {
											return 'end'
										}
									},
									color: 'black',
									font: {
										size: 12,
									},
								},
							},
							animation: false,
						}}
					/>
				</Col>
			</Row>
		</>
	) : (
		<Col className="center-flex">
			<Loading show />
		</Col>
	)
}

const styles = {
	graphCol: { minHeight: '300px' },
} satisfies Record<string, React.CSSProperties>

export default TemperatureExceptionEventDetail
