import React from 'react'
import { Form, FormControlProps, Row, Col } from 'react-bootstrap'

interface FormNumberProps extends Omit<FormControlProps, 'onChange'> {
	name: string
	label?: string
	required?: boolean
	feedback?: string
	min?: number
	max?: number
	onChange: (value: number) => void
}

const FormNumber = (props: FormNumberProps) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let numValue = Number(e.target.value)
		if (props.min !== undefined) {
			numValue = Math.max(numValue, props.min)
		}
		if (props.max !== undefined) {
			numValue = Math.min(numValue, props.max)
		}
		props.onChange(numValue)
	}
	return (
		<Form.Group controlId={props.name}>
			{props.label ? (
				<Form.Label>
					{props.label}
					{props.required ? ' *' : ''}
				</Form.Label>
			) : null}

			{props.readOnly ? (
				<Row className={'form-control-plaintext'}>
					<Col>{props.value}</Col>
				</Row>
			) : (
				<Form.Control type="number" {...props} onChange={handleChange} />
			)}

			{props.feedback ? <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback> : null}
		</Form.Group>
	)
}

export { FormNumber }
