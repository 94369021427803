import { PermissionModelObject } from '../permission.d'

// Display User has limited GET permissions, POSTPUTDELETE permissions are currently identical (none)
const GET_PERMISSIONS = [
	PermissionModelObject.Asset,
	PermissionModelObject.AssetClass,
	PermissionModelObject.AssetMake,
	PermissionModelObject.AssetModel,
	PermissionModelObject.AssetTree,
	PermissionModelObject.AssetTreeStatus,
	PermissionModelObject.AssetType,
	PermissionModelObject.ChannelMap,
	PermissionModelObject.Document,
	PermissionModelObject.Event,
	PermissionModelObject.EventSetting,
	PermissionModelObject.EventSettingType,
	PermissionModelObject.Group,
	PermissionModelObject.GroupType,
	PermissionModelObject.LocationArea,
	PermissionModelObject.LocationBuilding,
	PermissionModelObject.LocationFloor,
	PermissionModelObject.LocationRoom,
	PermissionModelObject.Maintenance,
	PermissionModelObject.MaintenanceType,
	PermissionModelObject.Module,
	PermissionModelObject.Reading,
	PermissionModelObject.ReadingType,
	PermissionModelObject.Site,
	PermissionModelObject.SiteGroup,
	PermissionModelObject.SiteStatus,
	PermissionModelObject.Stats,
	PermissionModelObject.Task,
	PermissionModelObject.TaskAsset,
	PermissionModelObject.TaskComment,
	PermissionModelObject.TaskHistory,
	PermissionModelObject.TaskStatus,
	PermissionModelObject.TaskUser,
	PermissionModelObject.User,
	PermissionModelObject.UserInvite,
	PermissionModelObject.UserRole,
	PermissionModelObject.UserSite,
]
const POST_PERMISSIONS = [] as PermissionModelObject[]
const PUT_PERMISSIONS = POST_PERMISSIONS
const DELETE_PERMISSIONS = POST_PERMISSIONS

export const displayUserPermissionObject = {
	GET: GET_PERMISSIONS,
	POST: POST_PERMISSIONS,
	PUT: PUT_PERMISSIONS,
	DELETE: DELETE_PERMISSIONS,
}
