import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { AppContext } from '../../../App'
import Papa from 'papaparse'
import { FormFile } from '../Form/File'

import { AssetResult } from '../../../models/Asset'
import { AssetMakeResult } from '../../../models/AssetMake'
import { AssetModelResult } from '../../../models/AssetModel'
import { LocationBuildingResult } from '../../../models/LocationBuilding'
import { LocationFloorResult } from '../../../models/LocationFloor'
import { LocationAreaResult } from '../../../models/LocationArea'
import { LocationRoomResult } from '../../../models/LocationRoom'
import * as Types from './assetImport.d'

import download from '../../../images/icons/download.svg'

import * as Request from '../../../utilities/request'
import * as Helpers from './assetImportHelpers'
import { PaymentAgreementResult } from '../../../models/PaymentAgreement'
import { assetImportExampleData } from './AssetImportExampleData'

// in order of files loaded
const fileData = {
	fixtures: {
		label: 'Fixtures',
		file: null,
		templateFields: Object.keys(Types.defaultFixtureImport),
		userFeedback: null,
	},
	tmv: {
		label: 'TMV',
		file: null,
		templateFields: Object.keys(Types.defaultTmvImport),
		userFeedback: null,
	},
	sfmStandardHub: {
		label: 'SFM Standard Hub',
		file: null,
		templateFields: Object.keys(Types.defaultSfmStandardImport),
		userFeedback: null,
	},
	sfmTfpHub: {
		label: 'SFM TFP Hub',
		file: null,
		templateFields: Object.keys(Types.defaultSfmTfpImport),
		userFeedback: null,
	},
	rfCompactTransmitter: {
		label: 'RF Compact Transmitter',
		file: null,
		templateFields: Object.keys(Types.defaultRfCompactImport),
		userFeedback: null,
	},
	rfMultiTransmitter: {
		label: 'RF Multi Transmitter',
		file: null,
		templateFields: Object.keys(Types.defaultRfMultiImport),
		userFeedback: null,
	},
	gateway: {
		label: 'Gateway',
		file: null,
		templateFields: Object.keys(Types.defaultGatewayImport),
		userFeedback: null,
	},
	serialPort: {
		label: 'Serial Port',
		file: null,
		templateFields: Object.keys(Types.defaultSerialPortImport),
		userFeedback: null,
	},
}

const AssetImporter = () => {
	const context = React.useContext(AppContext)
	const site_Id = context.appState.currentSite?.site_Id || ''

	const [files, setFiles] = React.useState<Types.AssetImportFiles>(fileData)
	const [importStatus, setImportStatus] = React.useState<Types.ImportStatus>('Ready')

	const updateFeedback = (importType: keyof Types.AssetImportFiles, feedback: Types.ImportUserFeedback) => {
		setFiles((prev) => ({ ...prev, [importType]: { ...prev[importType], userFeedback: feedback } }))
	}

	const handleImport = async () => {
		setImportStatus('InProgress')
		// reset feedback in case of retry
		const newFileData = files
		Object.keys(newFileData).map((key) => {
			newFileData[key as keyof Types.AssetImportFiles].userFeedback = null
		})
		setFiles(newFileData)

		// Collect surrounding information we will need to import assets
		const [
			assetsResponse,
			assetMakesResponse,
			assetModelsResponse,
			buildingsResponse,
			floorResponse,
			areaResponse,
			roomResponse,
			paymentAgreementResponse,
		] = await Promise.all([
			Request.get<AssetResult>(`asset?Site_Id=${site_Id}`, context.appState.authState),
			Request.get<AssetMakeResult>(`assetMake`, context.appState.authState),
			Request.get<AssetModelResult>(`assetModel`, context.appState.authState),
			Request.get<LocationBuildingResult>(`locationBuilding?Site_Id=${site_Id}`, context.appState.authState),
			Request.get<LocationFloorResult>(`locationFloor?Site_Id=${site_Id}`, context.appState.authState),
			Request.get<LocationAreaResult>(`locationArea?Site_Id=${site_Id}`, context.appState.authState),
			Request.get<LocationRoomResult>(`locationRoom?Site_Id=${site_Id}`, context.appState.authState),
			Request.get<PaymentAgreementResult>(`paymentAgreement?Site_Id=${site_Id}`, context.appState.authState),
		])
		const auxiliaryData = {
			assets: assetsResponse.data.assets,
			assetMakes: assetMakesResponse.data.assetMakes,
			assetModels: assetModelsResponse.data.assetModels,
			locationBuildings: buildingsResponse.data.locationBuildings,
			locationFloors: floorResponse.data.locationFloors,
			locationAreas: areaResponse.data.locationAreas,
			locationRooms: roomResponse.data.locationRooms,
			paymentAgreements: paymentAgreementResponse.data.paymentAgreements,
		}

		// return final feedback here as sometimes file import can be quicker than react state updates
		const importParams = { appState: context.appState, auxData: auxiliaryData }
		const fixturesFeedback = await Helpers.importHandler(
			{
				...importParams,
				updateFeedback: (feedback) => updateFeedback('fixtures', feedback),
			},
			files.fixtures,
			Helpers.importFixtures
		)
		const tmvFeedback = await Helpers.importHandler(
			{
				...importParams,
				updateFeedback: (feedback) => updateFeedback('tmv', feedback),
			},
			files.tmv,
			Helpers.importTmvs
		)
		const sfmStandardHubFeedback = await Helpers.importHandler(
			{
				...importParams,
				updateFeedback: (feedback) => updateFeedback('sfmStandardHub', feedback),
			},
			files.sfmStandardHub,
			Helpers.importSfmStandardHub
		)
		const sfmTfpHubFeedback = await Helpers.importHandler(
			{
				...importParams,
				updateFeedback: (feedback) => updateFeedback('sfmTfpHub', feedback),
			},
			files.sfmTfpHub,
			Helpers.importSfmTfpHub
		)
		const rfCompactTransmitterFeedback = await Helpers.importHandler(
			{
				...importParams,
				updateFeedback: (feedback) => updateFeedback('rfCompactTransmitter', feedback),
			},
			files.rfCompactTransmitter,
			Helpers.importRfCompactTransmitter
		)
		const rfMultiTransmitterFeedback = await Helpers.importHandler(
			{
				...importParams,
				updateFeedback: (feedback) => updateFeedback('rfMultiTransmitter', feedback),
			},
			files.rfMultiTransmitter,
			Helpers.importRfMultiTransmitter
		)
		const gatewayFeedback = await Helpers.importHandler(
			{
				...importParams,
				updateFeedback: (feedback) => updateFeedback('gateway', feedback),
			},
			files.gateway,
			Helpers.importGateway
		)
		const serialPortFeedback = await Helpers.importHandler(
			{
				...importParams,
				updateFeedback: (feedback) => updateFeedback('serialPort', feedback),
			},
			files.serialPort,
			Helpers.importSerialPort
		)

		if (
			fixturesFeedback.errors.length === 0 &&
			tmvFeedback.errors.length === 0 &&
			sfmStandardHubFeedback.errors.length === 0 &&
			sfmTfpHubFeedback.errors.length === 0 &&
			rfCompactTransmitterFeedback.errors.length === 0 &&
			rfMultiTransmitterFeedback.errors.length === 0 &&
			gatewayFeedback.errors.length === 0 &&
			serialPortFeedback.errors.length === 0
		) {
			setImportStatus('Finished')
		} else {
			setImportStatus('Error')
		}
	}

	// redo this to use utility
	const onGenerateTemplate = async (importType: Types.FileData) => {
		const templateData = {
			fields: importType.templateFields,
			data: [],
		}

		const csv = Papa.unparse(templateData)

		const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
		const csvURL = window.URL.createObjectURL(csvData)

		const tempLink = document.createElement('a')
		tempLink.href = csvURL
		tempLink.setAttribute('download', `SmartFlowImportTemplate - ${importType.label}.csv`)
		tempLink.click()
	}

	const onGenerateExample = async (importType: Types.FileData) => {
		console.log(importType)

		const exampleData = {
			fields: importType.templateFields,
			data: assetImportExampleData[`${importType.label}`],
		}

		const csv = Papa.unparse(exampleData)

		const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
		const csvURL = window.URL.createObjectURL(csvData)

		const tempLink = document.createElement('a')
		tempLink.href = csvURL
		tempLink.setAttribute('download', `SmartFlowImportExample - ${importType.label}.csv`)
		tempLink.click()
	}

	return (
		<>
			{Object.keys(files).map((key) => {
				const fData = files[key as keyof Types.AssetImportFiles]
				return (
					<Row key={key} style={styles.row}>
						<Col sm={'auto'}>
							<Button variant="link" onClick={() => onGenerateTemplate(fData)}>
								<img src={download} alt={'A download icon'} style={styles.image} /> Download Template
							</Button>
							<Button variant="link" onClick={() => onGenerateExample(fData)}>
								<img src={download} alt={'A download icon'} style={styles.image} /> Download Example
							</Button>
						</Col>
						<Col sm={3}>
							<FormFile
								name={`${key}ImportCSV`}
								label={`Upload ${fData.label} Import File`}
								accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
								onChange={(e) => {
									const target = e.target as HTMLInputElement
									if (target.files) {
										setFiles({
											...files,
											[key]: {
												...fData,
												file: target.files[0],
											},
										})
									}
								}}
							/>
						</Col>
						<Col sm={'auto'}>{fData.file !== null && <span>{(fData.file as File).name}</span>}</Col>
					</Row>
				)
			})}
			{importStatus !== 'InProgress' && (
				<Row style={styles.spaciousRow}>
					<Col>
						<Button variant="primary" onClick={handleImport}>
							Start Import
						</Button>
					</Col>
				</Row>
			)}
			{(importStatus === 'InProgress' || importStatus === 'Error') && (
				<Row style={styles.spaciousRow}>
					<Col>
						{importStatus === 'InProgress' && (
							<>
								<Row>
									<Col style={styles.centred}>Import in Progress</Col>
								</Row>
								<Row>
									<Col style={styles.centred}>Please do not leave or close the page</Col>
								</Row>
							</>
						)}
						{Object.keys(files)
							.filter((key) => files[key as keyof Types.AssetImportFiles].userFeedback)
							.map((key) => {
								const fData = files[key as keyof Types.AssetImportFiles]
								return (
									<Row key={key}>
										<Row>
											<Col style={styles.centred}>
												<h1>
													{fData.label}: Importing Asset {fData.userFeedback?.totalComplete} out of {fData.userFeedback?.totalCount}
												</h1>
											</Col>
										</Row>

										{fData.userFeedback?.errors.length !== 0 && (
											<>
												<Row>
													<Col style={styles.centred} className={'invalid-feedback-custom'}>
														Unable to import {fData.userFeedback?.errors.length} rows
													</Col>
												</Row>
												{fData.userFeedback?.errors.map((error, i) => (
													<Row key={`importError${i}`}>
														<Col className={'invalid-feedback-custom'}>{error}</Col>
													</Row>
												))}
											</>
										)}
									</Row>
								)
							})}
					</Col>
				</Row>
			)}

			{importStatus === 'Finished' && (
				<Row style={styles.spaciousRow}>
					<Col style={styles.centred}>
						<h1>Import Complete</h1>
					</Col>
				</Row>
			)}
		</>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	row: {
		marginBottom: '1rem',
		marginTop: '1rem',
	},
	spaciousRow: {
		marginTop: '1rem',
		marginBottom: '2rem',
	},
	label: {
		width: 'unset',
	},
	image: {
		height: '20px',
		marginBottom: '5px',
		marginRight: '10px',
	},
	centred: {
		textAlign: 'center',
	},
}

export { AssetImporter }
