import React from 'react'
import './EditAsset.css'

import { AppContext } from '../../../../App'
import * as Request from '../../../../utilities/request'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../../utilities/permissions/permission.d'

import { Row, Col, Card } from 'react-bootstrap'
import { FormText } from '../../Form/Text'
import { FormSelect, SelectOption } from '../../Form/Select'
import { ModalComponentByName } from '../../../../screens/Asset/EditAssetDetailsModals'
import PermissionsCheck from '../../../Permissions/PermissionsCheck'

import { CommonComponentProps } from '../../../../screens/Asset/EditAssetDetails'
import { Asset } from '../../../../models/Asset'
import { AssetMake, AssetMakeResult } from '../../../../models/AssetMake'
import { AssetModel, AssetModelResult } from '../../../../models/AssetModel'

import plus2 from '../../../../images/icons/add.svg'
import { assetClass } from '../../../../constants/assetClass'
import { FormNumber } from '../../Form/Number'

const AssetDetails = (componentProps: CommonComponentProps) => {
	const context = React.useContext(AppContext)

	const [assetMakes, setAssetMakes] = React.useState<AssetMake[] | null>(null)
	const [assetModels, setAssetModels] = React.useState<AssetModel[] | null>(null)

	const [modal, setModal] = React.useState<string>('')

	React.useEffect(() => {
		const getData = async () => {
			const [assetMakeReq, assetModelReq] = await Promise.all([
				Request.get<AssetMakeResult>('assetMake', context.appState.authState),
				Request.get<AssetModelResult>('assetModel', context.appState.authState),
			])
			if (assetMakeReq.status === 200 && assetModelReq.status === 200) {
				setAssetMakes(assetMakeReq.data.assetMakes)
				setAssetModels(assetModelReq.data.assetModels)
			}
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [componentProps.asset.asset_Id])

	const handleAddAssetMake = async (assetMake: AssetMake) => {
		await Request.handleRequest(() => Request.post<AssetMakeResult>('assetMake', assetMake, context.appState.authState), {
			successFunction: (data) => {
				setAssetMakes((prev) => [...(prev || []), ...data.assetMakes].sort((a, b) => a.assetMake_Name.localeCompare(b.assetMake_Name)))
				componentProps.handleChange('asset.assetMake_Id', data.assetMakes[0].assetMake_Id)
				componentProps.handleChange('asset.assetModel_Id', null)
			},
			setMessageFunction: componentProps.setMessages,
			messageAction: 'creating',
			messageObject: 'asset make',
		})
	}

	const handleAddAssetModel = async (assetModel: AssetModel) => {
		await Request.handleRequest(() => Request.post<AssetModelResult>('assetModel', assetModel, context.appState.authState), {
			successFunction: (data) => {
				setAssetModels((prev) => [...(prev || []), ...data.assetModels].sort((a, b) => a.assetModel_Name.localeCompare(b.assetModel_Name)))
				componentProps.handleChange('asset.assetModel_Id', data.assetModels[0].assetModel_Id)
			},
			setMessageFunction: componentProps.setMessages,
			messageAction: 'creating',
			messageObject: 'asset model',
		})
	}

	const assetMakeOptions = React.useMemo(() => {
		return assetMakes ? assetMakes.map((am) => ({ label: am.assetMake_Name, value: am.assetMake_Id })) : []
	}, [assetMakes])

	const assetModelOptions = React.useMemo(() => {
		return assetModels
			? assetModels
					.filter((am) => am.assetMake_Id === componentProps.asset.assetMake_Id)
					.map((am) => ({ label: am.assetModel_Name, value: am.assetModel_Id }))
			: []
	}, [assetModels, componentProps.asset.assetMake_Id])

	const ModalComponent = React.useMemo(
		() => (modalProps: { asset: Asset }) =>
			modal
				? ModalComponentByName[modal]({
						hide: () => setModal(''),
						user_Id: context.appState.userAttributes.user_Id,
						site_Id: context.appState.currentSite?.site_Id || '',
						asset: modalProps.asset,
						handleAddAssetMake: (assetMake) => handleAddAssetMake(assetMake),
						handleAddAssetModel: (assetModel) => handleAddAssetModel(assetModel),
				  })
				: null,
		[modal]
	)

	return (
		<Card className="site-card">
			<Row className="generic-card-list-heading-row">
				<Col>
					<span className="dashboard-card-titles">Asset Information</span>
				</Col>
			</Row>
			<Row style={styles.row}>
				<Col sm={6} className="edit-asset-field">
					<FormSelect
						name={'asset_assetType_Id'}
						label={'Asset Type'}
						options={[{ value: componentProps.asset.assetType_Id, label: componentProps.asset.assetType_Name }]}
						isDisabled
						value={componentProps.asset.assetType_Id}
						onChange={(e) => console.log(e)}
					/>
				</Col>
				<Col sm={6} className="edit-asset-field">
					<FormText
						name={'asset_serialNo'}
						label={'Serial Number'}
						value={componentProps.asset.asset_SerialNo || ''}
						onChange={(e) => componentProps.handleChange('asset.asset_SerialNo', e.target.value)}
						disabled={componentProps.pageStatus === 'Submitting'}
						feedback={componentProps.errors?.asset_SerialNo}
						isInvalid={!!componentProps.errors?.asset_SerialNo}
					/>
				</Col>
			</Row>
			<Row style={styles.row}>
				<Col sm={6} className="edit-asset-field">
					<FormSelect
						name={'asset_assetMake_Id'}
						label={'Asset Make'}
						options={assetMakeOptions}
						value={componentProps.asset.assetMake_Id}
						onChange={(e) => {
							componentProps.handleChange('asset.assetMake_Id', e ? (e as SelectOption<string>).value : null)
							componentProps.handleChange('asset.assetModel_Id', null)
						}}
						isClearable
						isDisabled={componentProps.pageStatus === 'Submitting'}
						loading={!assetMakes}
					/>
					<PermissionsCheck object={PermissionModelObject.AssetMake} action={PermissionModelAction.POST} context={PermissionModelContext.Site}>
						<img
							src={plus2}
							alt={'plus icon'}
							className="edit-asset-plus-button"
							style={!assetMakes ? styles.disabledPlusButton : {}}
							onClick={() => assetMakes && setModal('assetMake')}
						/>
					</PermissionsCheck>
				</Col>
				<Col sm={6} className="edit-asset-field">
					<FormSelect
						name={'asset_assetModel_Id'}
						label={'Asset Model'}
						options={assetModelOptions}
						value={componentProps.asset.assetModel_Id}
						onChange={(e) => componentProps.handleChange('asset.assetModel_Id', e ? (e as SelectOption<string>).value : null)}
						isClearable
						isDisabled={componentProps.pageStatus === 'Submitting'}
						loading={!assetModels}
					/>
					<PermissionsCheck object={PermissionModelObject.AssetModel} action={PermissionModelAction.POST} context={PermissionModelContext.Site}>
						<img
							src={plus2}
							alt={'plus icon'}
							className="edit-asset-plus-button"
							style={!componentProps.asset.assetMake_Id || !assetModels ? styles.disabledPlusButton : {}}
							onClick={() => componentProps.asset.assetMake_Id && assetModels && setModal('assetModel')}
						/>
					</PermissionsCheck>
				</Col>
			</Row>

			{componentProps.asset.assetClass_Id === assetClass.Network.id && (
				<Row style={styles.row}>
					<Col sm={6} className="edit-asset-field">
						<FormText
							name={'serialPort_COMPort'}
							label={'COM Port'}
							value={componentProps.asset.serialPort_COMPort || ''}
							onChange={(e) => componentProps.handleChange('asset.serialPort_COMPort', e.target.value)}
							disabled={componentProps.pageStatus === 'Submitting'}
							feedback={componentProps.errors?.serialPort_COMPort}
							isInvalid={!!componentProps.errors?.serialPort_COMPort}
						/>
					</Col>
					<Col sm={6} className="edit-asset-field">
						<FormText
							name={'serialPort_IPAddress'}
							label={'IP Address'}
							value={componentProps.asset.serialPort_IPAddress || ''}
							onChange={(e) => componentProps.handleChange('asset.serialPort_IPAddress', e.target.value)}
							disabled={componentProps.pageStatus === 'Submitting'}
							feedback={componentProps.errors?.serialPort_IPAddress}
							isInvalid={!!componentProps.errors?.serialPort_IPAddress}
						/>
					</Col>
				</Row>
			)}
			{componentProps.asset.assetClass_Id === assetClass.Hub.id && (
				<>
					<Row style={styles.row}>
						<Col sm={6} className="edit-asset-field">
							<FormText
								name={'assetTransmitter_HardwareVersion'}
								label={'Hardware Version'}
								value={componentProps.asset.assetTransmitter_HardwareVersion || ''}
								onChange={(e) => componentProps.handleChange('asset.assetTransmitter_HardwareVersion', e.target.value)}
								disabled={true}
								feedback={componentProps.errors?.assetTransmitter_HardwareVersion}
								isInvalid={!!componentProps.errors?.assetTransmitter_HardwareVersion}
							/>
						</Col>
						<Col sm={6} className="edit-asset-field">
							<FormText
								name={'assetTransmitter_SoftwareVersion'}
								label={'Software Version'}
								value={componentProps.asset.assetTransmitter_SoftwareVersion || ''}
								onChange={(e) => componentProps.handleChange('asset.assetTransmitter_SoftwareVersion', e.target.value)}
								disabled={true}
								feedback={componentProps.errors?.assetTransmitter_SoftwareVersion}
								isInvalid={!!componentProps.errors?.assetTransmitter_SoftwareVersion}
							/>
						</Col>
					</Row>
					<Row style={styles.row}>
						<Col sm={6} className="edit-asset-field">
							<FormNumber
								name={`hub_Number`}
								label={'Hub Number'}
								value={componentProps.asset.hub_Number || 0}
								onChange={(value) => componentProps.handleChange('asset.hub_Number', value)}
								min={0}
								feedback={componentProps.errors?.hub_Number}
								isInvalid={!!componentProps.errors?.hub_Number}
							/>
						</Col>
						<Col sm={6} className="edit-asset-field">
							<FormText
								name={'asset_FacilityAssetNumber'}
								label={'Facility Asset Number'}
								value={componentProps.asset.asset_FacilityAssetNumber || ''}
								onChange={(e) => componentProps.handleChange('asset.asset_FacilityAssetNumber', e.target.value)}
								disabled={componentProps.pageStatus === 'Submitting'}
								feedback={componentProps.errors?.asset_FacilityAssetNumber}
								isInvalid={!!componentProps.errors?.asset_FacilityAssetNumber}
							/>
						</Col>
					</Row>
				</>
			)}

			{componentProps.asset.assetClass_Id === assetClass.Transmitter.id && (
				<>
					<Row style={styles.row}>
						<Col sm={6} className="edit-asset-field">
							<FormText
								name={'assetTransmitter_HardwareVersion'}
								label={'Hardware Version'}
								value={componentProps.asset.assetTransmitter_HardwareVersion || ''}
								onChange={(e) => componentProps.handleChange('asset.assetTransmitter_HardwareVersion', e.target.value)}
								disabled={true}
								feedback={componentProps.errors?.assetTransmitter_HardwareVersion}
								isInvalid={!!componentProps.errors?.assetTransmitter_HardwareVersion}
							/>
						</Col>
						<Col sm={6} className="edit-asset-field">
							<FormText
								name={'assetTransmitter_SoftwareVersion'}
								label={'Software Version'}
								value={componentProps.asset.assetTransmitter_SoftwareVersion || ''}
								onChange={(e) => componentProps.handleChange('asset.assetTransmitter_SoftwareVersion', e.target.value)}
								disabled={true}
								feedback={componentProps.errors?.assetTransmitter_SoftwareVersion}
								isInvalid={!!componentProps.errors?.assetTransmitter_SoftwareVersion}
							/>
						</Col>
					</Row>
					<Row style={styles.row}>
						<Col sm={6} className="edit-asset-field">
							<FormText
								name={'assetTransmitter_EUI'}
								label={'EUI'}
								value={componentProps.asset.assetTransmitter_EUI || ''}
								onChange={(e) => componentProps.handleChange('asset.assetTransmitter_EUI', e.target.value.trim())}
								disabled={componentProps.pageStatus === 'Submitting'}
								feedback={componentProps.errors?.assetTransmitter_EUI}
								isInvalid={!!componentProps.errors?.assetTransmitter_EUI}
							/>
						</Col>
						<Col sm={6} className="edit-asset-field">
							<FormText
								name={'assetTransmitter_Type'}
								label={'Transmitter Type'}
								value={componentProps.asset.assetTransmitter_Type || ''}
								onChange={(e) => componentProps.handleChange('asset.assetTransmitter_Type', e.target.value)}
								disabled={true}
								feedback={componentProps.errors?.assetTransmitter_Type}
								isInvalid={!!componentProps.errors?.assetTransmitter_Type}
							/>
						</Col>
					</Row>
				</>
			)}
			{componentProps.asset.assetClass_Id !== assetClass.Hub.id && (
				<>
					<Row style={styles.row}>
						<Col sm={6} className="edit-asset-field">
							<FormText
								name={'asset_FacilityAssetNumber'}
								label={'Facility Asset Number'}
								value={componentProps.asset.asset_FacilityAssetNumber || ''}
								onChange={(e) => componentProps.handleChange('asset.asset_FacilityAssetNumber', e.target.value)}
								disabled={componentProps.pageStatus === 'Submitting'}
								feedback={componentProps.errors?.asset_FacilityAssetNumber}
								isInvalid={!!componentProps.errors?.asset_FacilityAssetNumber}
							/>
						</Col>

						{componentProps.asset.assetClass_Id === assetClass.Tmv.id ? (
							<Col sm={6} className="edit-asset-field">
								<FormText
									name={'asset_RoomsServiced'}
									label={'Rooms Serviced'}
									value={componentProps.asset.asset_RoomsServiced || ''}
									onChange={(e) => componentProps.handleChange('asset.asset_RoomsServiced', e.target.value)}
									disabled={componentProps.pageStatus === 'Submitting'}
									feedback={componentProps.errors?.asset_RoomsServiced}
									isInvalid={!!componentProps.errors?.asset_RoomsServiced}
								/>
							</Col>
						) : (
							<Col sm={6} className="edit-asset-field" />
						)}
					</Row>
				</>
			)}

			{(componentProps.asset.assetClass_Id === assetClass.Tmv.id || componentProps.asset.assetClass_Id === assetClass.Hub.id) && (
				<Row style={styles.row}>
					<Col sm={6} className="edit-asset-field">
						<FormNumber
							name={`asset_bacnetPoint`}
							label={'Bacnet Point'}
							value={componentProps.asset.asset_BacnetPoint || 0}
							onChange={(value) => componentProps.handleChange('asset.asset_BacnetPoint', value)}
							min={1}
							feedback={componentProps.errors?.asset_BacnetPoint}
							isInvalid={!!componentProps.errors?.asset_BacnetPoint}
						/>
					</Col>
					<Col sm={6} className="edit-asset-field"></Col>
				</Row>
			)}

			<ModalComponent asset={componentProps.asset} />
		</Card>
	)
}

const styles = {
	row: {
		padding: '20px',
		justifyContent: 'space-between',
	},
	disabledPlusButton: {
		cursor: 'not-allowed',
	},
}

export { AssetDetails }
