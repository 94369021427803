import React from 'react'
import { Pagination } from 'react-bootstrap'

interface FiltersState {
	page: number
	pageSize: number
}

const storePagingPreferences = (pageSize: number) => {
	if (typeof Storage !== 'undefined') {
		window.localStorage.setItem('pageSize', String(pageSize))
	}
}

const getPagingPreferences = (defaultPageSize?: number): number => {
	if (typeof Storage !== 'undefined') {
		return Number(window.localStorage.getItem('pageSize') || defaultPageSize || 10)
	}

	return defaultPageSize || 10
}

const pageFilter = (filters: FiltersState, index: number) => index < filters.page * filters.pageSize && index >= (filters.page - 1) * filters.pageSize

const calculatePageStart = (filters: FiltersState) => {
	if (filters.page === 1) {
		return filters.page
	}
	return filters.page * filters.pageSize - filters.pageSize
}

const calculatePageTo = (filters: FiltersState, unFilteredRecordsLength: number) => {
	if (filters.page * filters.pageSize < unFilteredRecordsLength) {
		return filters.page * filters.pageSize
	}
	return unFilteredRecordsLength
}
interface PaginationProps {
	filters: FiltersState
	setFilterDispatch: React.Dispatch<React.SetStateAction<FiltersState>>
	allPaginatedRecordsLength: number
}

const Paginator = (props: PaginationProps) => {
	const numPages = Math.ceil(props.allPaginatedRecordsLength / props.filters.pageSize)

	return (
		<Pagination style={{ marginBottom: '0px' }}>
			<Pagination.First disabled={!(props.filters.page > 1 && numPages > 2)} onClick={() => props.setFilterDispatch({ ...props.filters, page: 1 })} />

			<Pagination.Prev disabled={!(props.filters.page > 1)} onClick={() => props.setFilterDispatch({ ...props.filters, page: props.filters.page - 1 })} />

			{props.filters.page > 6 ? <Pagination.Ellipsis /> : null}

			{props.allPaginatedRecordsLength > 0
				? Array.from({ length: numPages }).map((value: unknown, index: number) =>
						index >= props.filters.page - 6 && index <= props.filters.page + 4 ? (
							<Pagination.Item
								key={index}
								active={props.filters.page === index + 1}
								activeLabel=""
								onClick={() => props.setFilterDispatch({ ...props.filters, page: index + 1 })}
							>
								{index + 1}
							</Pagination.Item>
						) : null
				  )
				: null}

			{props.filters.page <= numPages - 6 ? <Pagination.Ellipsis /> : null}

			<Pagination.Next
				disabled={!(props.filters.page < numPages)}
				onClick={() => props.setFilterDispatch({ ...props.filters, page: props.filters.page + 1 })}
			/>

			<Pagination.Last
				disabled={!(props.filters.page < numPages && numPages > 2)}
				onClick={() => props.setFilterDispatch({ ...props.filters, page: numPages })}
			/>
		</Pagination>
	)
}

export { Paginator, calculatePageTo, calculatePageStart, getPagingPreferences, pageFilter, storePagingPreferences }
export type { FiltersState }
