import React, { useContext } from 'react'
import { AppContext } from '../../../App'

import { Modal } from 'react-bootstrap'

import { Button } from '../Button/Button'
import { FormSelect, SelectOption } from '../Form/Select'

import { defaultModified } from '../../../models/History'
import { TaskList, TaskResult } from '../../../models/Task'
import { MessageAction } from '../Messages/Message'
import { TaskStatus, TaskStatusId, taskStatus, taskStatusDropdownValues } from '../../../constants/taskStatus'
import { recordStatus } from '../../../constants/recordStatus'

import * as Request from '../../../utilities/request'

interface UpdateStatusModalProps {
	show: boolean
	tasks: TaskList[]
	onSaveComplete: (updatedTasks: TaskList[]) => void
	setShow: React.Dispatch<React.SetStateAction<boolean>>
	setMessages: (action: MessageAction) => void
}

const UpdateStatusModal = (props: UpdateStatusModalProps) => {
	const context = useContext(AppContext)

	const [taskStatusId, setTaskStatusId] = React.useState<TaskStatusId | null>(null)
	const [error, setError] = React.useState<string>('')
	const [submitting, setSubmitting] = React.useState<boolean>(false)

	const handleSubmit = async () => {
		if (!taskStatusId) {
			setError('Status is required')
			return
		}
		setSubmitting(true)
		const modified = defaultModified({ modified_UserId: context.appState.userAttributes.user_Id })
		const results = await Promise.all(
			props.tasks.map((task) =>
				Request.put<TaskResult>(
					'task',
					{ ...task, taskStatus_Id: taskStatusId, modified: modified, recordStatus_Id: recordStatus.Active.id },
					context.appState.authState
				)
			)
		)

		const success = results.every((result) => result.status === 200)
		if (success) {
			const statusName = Object.keys(taskStatus).find((key) => taskStatus[key as TaskStatus].id === taskStatusId)
			props.onSaveComplete(props.tasks.map((task) => ({ ...task, taskStatus_Id: taskStatusId, taskStatus_Name: statusName || '' })))
			setTaskStatusId(null)
			props.setShow(false)
			props.setMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Success updating the tasks',
					dismissible: true,
					timeout: 5000,
				},
			})
		} else {
			props.setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Error updating the tasks',
					dismissible: true,
					timeout: 5000,
				},
			})
		}
		setSubmitting(false)
	}

	const handleChange = (value: TaskStatusId | null) => {
		setTaskStatusId(value)
		setError('')
	}

	return (
		<Modal
			show={props.show}
			onHide={() => {
				setTaskStatusId(null)
				props.setShow(false)
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title>Update Status</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<FormSelect
					name="taskStatusId"
					value={taskStatusId}
					options={taskStatusDropdownValues}
					onChange={(e) => handleChange(e ? ((e as SelectOption<TaskStatusId>).value as TaskStatusId) : null)}
					label="Status"
					required
					feedback={error}
				/>
			</Modal.Body>

			<Modal.Footer>
				<Button
					disabled={submitting}
					onClick={() => {
						setTaskStatusId(null)
						props.setShow(false)
					}}
				>
					Cancel
				</Button>
				<Button disabled={submitting} onClick={handleSubmit}>
					Update {props.tasks.length} tasks
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export { UpdateStatusModal }
