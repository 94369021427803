import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import document from '../../../images/icons/report.svg'
import { DateTime } from 'luxon'
// import { SiteStatusValues } from '../../../constants/siteStatus'

const SiteDashboardMonthlyReports = () => {
	// The Fleet Health Report is manually generated on the 1st of each month
	const daysUntilFirstOfNextMonth = React.useMemo(() => {
		const now = DateTime.now()
		if (now.day === 1) {
			return 0
		}

		const daysInCurrentMonth = now.daysInMonth
		if (daysInCurrentMonth) {
			return daysInCurrentMonth - now.day + 1
		}
		return undefined
	}, [DateTime.now().day])

	// The Monthly Reading Prep Report is automatically generated 5 days before the end of each month
	const daysUntilFiveDaysBeforeFirstOfMonth = React.useMemo(() => {
		const now = DateTime.now()
		const daysInCurrentMonth = now.daysInMonth

		if (daysInCurrentMonth) {
			const fifthLastDayOfMonth = daysInCurrentMonth - 4 // Calculate the fifth last day of the month
			if (now.day > fifthLastDayOfMonth) {
				// The fifth last day has already passed this month
				const nextMonth = now.plus({ months: 1 })
				const nextMonthDaysInMonth = nextMonth.daysInMonth
				if (nextMonthDaysInMonth) {
					return nextMonthDaysInMonth - (now.day - fifthLastDayOfMonth)
				}
				return undefined
			}

			return fifthLastDayOfMonth - now.day
		}
		return undefined
	}, [DateTime.now().day])

	return (
		<Card style={styles.siteDashboardMonthlyReports}>
			<Row style={styles.headerRow}>
				<Col sm="auto">
					<img src={document} alt={'Monthly Report Icon'} style={styles.icon} />
				</Col>
				<Col>
					<span className="dashboard-card-titles">Reports</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<span className="dashboard-card-subtitle">Your Fleet Health report is due in:</span>
				</Col>
			</Row>
			<Row style={styles.midRowGap}>
				<Col>
					<span className="dashboard-card-stat">{daysUntilFirstOfNextMonth != 0 ? `${daysUntilFirstOfNextMonth} days` : 'today'}</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<span className="dashboard-card-subtitle">Automatic Monthly Readings Prep report will be generated in:</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<span className="dashboard-card-stat">
						{daysUntilFiveDaysBeforeFirstOfMonth != 0 ? `${daysUntilFiveDaysBeforeFirstOfMonth} days` : 'today'}
					</span>
				</Col>
			</Row>
		</Card>
	)
}

const styles = {
	siteDashboardMonthlyReports: {
		marginTop: '0px',
		paddingTop: '30px',
	},
	headerRow: {
		marginBottom: '10px',
	},
	midRowGap: {
		marginBottom: '30px',
	},
	icon: {
		height: '32px',
	},
}

export default SiteDashboardMonthlyReports
