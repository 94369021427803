import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { useSearchParams } from 'react-router-dom'
import { PageStatus } from '../../../types/PageStatus'
import {
	FleetHealthReportData,
	TempExceptionHistoryAsset,
	TempExceptionHistoryResult,
	TemperatureExceptionResult,
	TemperatureExceptionSummary,
	TemperatureExceptionSummaryIdWithComment,
	TemperatureExceptionWithComment,
} from '../../../models/Report'
import * as Request from '../../../utilities/request'
import { ReportApiTempExceptionFilterType, ReportApiType } from '../../../constants/report'
import logo from '../../../images/LogoBlack.svg'
import { Col, Row } from 'react-bootstrap'
import { ReportStyles as styles } from './ReportStyles'
import './ReportRender.css'
import { formatIncomingDateTime, formatOutgoingDateTime } from '../../../utilities/formatDate'
import { ReportGenerationDataResult } from '../../../models/ReportGenerationData'
import { SiteResult } from '../../../models/Site'
import { SiteGroupResult } from '../../../models/SiteGroup'
import { DateTime } from 'luxon'
import { AppState } from '../../../App.d'
import SiteGroupOverviewGraphs from '../../../components/UI/SiteGroupDetails/SiteGroupOverviewGraphs'
import TemperatureExceptionEventsGraph from '../../../components/UI/Trends/TemperatureExceptionEventsGraph'
import TemperatureExceptionEventDetailReport from '../../../components/UI/Reports/TemperatureExceptionEventDetailReport'

const ReportTable = (props: {
	children: React.JSX.Element
	appState: AppState
	isSiteContext: boolean
	monthDate: DateTime
	title?: string
	columnHeaders?: string[]
}) => (
	<table style={{ ...styles.w100, pageBreakAfter: 'always' }}>
		<thead>
			<tr>
				<td colSpan={props.columnHeaders ? props.columnHeaders.length : undefined} style={styles.theadTitleRow}>
					<Row>
						<Col sm="auto">
							<img src={logo} alt="logo" style={styles.headerImg} />
						</Col>
						<Col />
						{props.title && (
							<>
								<Col sm="auto" className="center-flex">
									<span style={styles.sectionTitle}>{props.title}</span>
								</Col>
								<Col />
							</>
						)}
						<Col sm="auto">
							<div style={styles.headerTitle}>
								<span>Fleet Health Report for {props.monthDate.toFormat('MMMM yyyy')} </span>
								<span>
									{props.isSiteContext
										? `Site: ${props.appState.currentSite?.site_Name}`
										: `Site Group: ${props.appState.currentSiteGroup?.siteGroup_Name}`}
								</span>
							</div>
						</Col>
					</Row>
				</td>
			</tr>
			{props.columnHeaders && (
				<tr style={{ ...styles.theadColNameRow, ...styles.lightText }}>
					{props.columnHeaders.map((col, idx, arr) => (
						<th key={idx} style={idx === arr.length + 1 ? styles.cellLast : styles.cell}>
							{}
							{col}
						</th>
					))}
				</tr>
			)}
		</thead>
		<tbody>{props.children}</tbody>
	</table>
)

const lastRow = (index: number, arr: unknown[]) => {
	return index === arr.length - 1 ? styles.bottomBorder : {}
}

const FleetHealthRender = () => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [searchParams] = useSearchParams()
	const reportGenerationData_Id = searchParams.get('Id')

	const [isSiteContext, setIsSiteContext] = React.useState(false)

	const [monthDate, setMonthDate] = React.useState<DateTime>(DateTime.now())

	const [temperatureExceptionSummaries, setTemperatureExceptionSummaries] = React.useState<TemperatureExceptionSummary[]>([])
	const [temperatureExceptionSummaryIdWithComment, setTemperatureExceptionSummaryIdWithComment] = React.useState<TemperatureExceptionSummaryIdWithComment[]>(
		[]
	)
	const [temperatureExceptionDetailEventWithComment, setTemperatureExceptionDetailEventWithComment] = React.useState<TemperatureExceptionWithComment[]>([])

	const [tempExceptionHistoryAssets, setTempExceptionHistoryAssets] = React.useState<TempExceptionHistoryAsset[]>([])

	React.useEffect(() => {
		const getData = async () => {
			const reportGenerationDataReq = await Request.get<ReportGenerationDataResult>(
				`reportGenerationData?Id=${reportGenerationData_Id}`,
				context.appState.authState
			)
			const fleetHealthReportData: FleetHealthReportData = JSON.parse(
				window.atob(reportGenerationDataReq.data.reportGenerationData[0].reportGenerationData_Data)
			)

			let siteOrSiteGroupId = ''
			let timeZone = ''

			if (fleetHealthReportData.context === 'site') {
				setIsSiteContext(true)
				const siteReq = await Request.get<SiteResult>(`site?Id=${fleetHealthReportData.site_Id}`, context.appState.authState)
				siteOrSiteGroupId = siteReq.data.sites[0].site_Id
				context.setAppState({ state: 'setCurrentSite', data: { site: siteReq.data.sites[0] } })
				timeZone = siteReq.data.sites[0].site_Timezone
			} else {
				setIsSiteContext(false)
				const siteGroupReq = await Request.get<SiteGroupResult>(`siteGroup?Id=${fleetHealthReportData.siteGroup_Id}`, context.appState.authState)
				siteOrSiteGroupId = siteGroupReq.data.siteGroups[0].siteGroup_Id
				context.setAppState({ state: 'setCurrentSiteGroup', data: { siteGroup: siteGroupReq.data.siteGroups[0] } })
				timeZone = siteGroupReq.data.siteGroups[0].siteGroup_Timezone
			}

			const reportDate = DateTime.fromObject({
				year: fleetHealthReportData.year,
				month: fleetHealthReportData.month,
				day: 1,
				hour: 0,
				minute: 0,
				second: 0,
				millisecond: 0,
			})
			setMonthDate(reportDate)

			const [temperatureExceptionResult, tempExceptionHistoryResult] = await Promise.all([
				Request.get<TemperatureExceptionResult>(
					`report/${ReportApiType.TEMP_EXCEPTIONS.value}?TempExceptionFilterType=${ReportApiTempExceptionFilterType.MONTH.value}&${
						fleetHealthReportData.context === 'site' ? `Site_Id=${siteOrSiteGroupId}` : `SiteGroup_Id=${siteOrSiteGroupId}`
					}&Date=${formatOutgoingDateTime({ dateTime: reportDate, format: 'DateTimeObject', timeZone: timeZone })}`,
					context.appState.authState
				),
				Request.get<TempExceptionHistoryResult>(
					`report/${ReportApiType.TEMP_EXCEPTION_HISTORY.value}?${
						fleetHealthReportData.context === 'site' ? `Site_Id=${siteOrSiteGroupId}` : `SiteGroup_Id=${siteOrSiteGroupId}`
					}&Date=${formatOutgoingDateTime({
						dateTime: reportDate,
						format: 'DateTimeObject',
						timeZone: timeZone,
					})}&DisplayDate=${formatOutgoingDateTime({
						dateTime: reportDate,
						format: 'DateTimeObject',
					})}`,
					context.appState.authState
				),
			])
			setTemperatureExceptionSummaries(temperatureExceptionResult.data.temperatureExceptionSummaries)
			setTempExceptionHistoryAssets(tempExceptionHistoryResult.data.tempExceptionHistory.assets)

			setTemperatureExceptionSummaryIdWithComment(fleetHealthReportData.temperatureExceptionSummariesIdWithComment)
			setTemperatureExceptionDetailEventWithComment(
				temperatureExceptionResult.data.temperatureExceptionSummaries
					.flatMap((summ) => summ.temperatureExceptions)
					.filter((event) => fleetHealthReportData.temperatureExceptionsDetailIdWithComment.map((obj) => obj.event_Id).includes(event.event_Id))
					.map((event) => ({
						...event,
						comment:
							fleetHealthReportData.temperatureExceptionsDetailIdWithComment.find((eventComment) => eventComment.event_Id === event.event_Id)
								?.comment || '',
					}))
			)

			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			if (reportGenerationData_Id === null) {
				setPageStatus('Error')
			} else {
				getData()
			}
		}
	}, [context.appState.authState, context.appState.userAttributes])

	return (
		<div style={styles.w100}>
			{pageStatus === 'Ready' && (
				<>
					<ReportTable appState={context.appState} isSiteContext={isSiteContext} monthDate={monthDate} title="Fleet Health Overview">
						<SiteGroupOverviewGraphs siteGroup={!isSiteContext} startDate={monthDate} flowCountConsecutiveDaysWithoutFlow disableAnimation />
					</ReportTable>
					<ReportTable
						appState={context.appState}
						isSiteContext={isSiteContext}
						monthDate={monthDate}
						title="Temperature Exception Summary - High Grade"
						columnHeaders={['TMV ID', 'LOCATION', 'EXCEPTION COUNT', 'LAST MONTH', 'COMMENTS']}
					>
						{temperatureExceptionSummaries.length > 0 ? (
							<>
								{temperatureExceptionSummaries
									.filter((e) => e.isHighGrade)
									.map((e) => (
										<tr key={e.asset_Id}>
											<td style={{ ...styles.cell, ...styles.bottomBorder }}>{e.asset_Name}</td>
											<td style={{ ...styles.cell, ...styles.bottomBorder }}>{e.asset_Location}</td>
											<td style={{ ...styles.cell, ...styles.highGrade, ...styles.centeredCell, ...styles.bottomBorder }}>
												{e.alertCount}
											</td>
											<td style={{ ...styles.cell, ...styles.centeredCell, ...styles.bottomBorder }}>{e.previousMonthCount}</td>
											<td style={{ ...styles.cellLast, ...styles.bottomBorder }}>
												{temperatureExceptionSummaryIdWithComment.find((comment) => comment.asset_Id === e.asset_Id)?.comment}
											</td>
										</tr>
									))}
							</>
						) : (
							<tr>
								<td colSpan={5} style={{ ...styles.centeredCell, ...styles.largeFont }}>
									<span>No Temperature Exceptions</span>
								</td>
							</tr>
						)}
					</ReportTable>
					<ReportTable
						appState={context.appState}
						isSiteContext={isSiteContext}
						monthDate={monthDate}
						title="Temperature Exception Events - Detailed View"
					>
						{temperatureExceptionDetailEventWithComment.length > 0 ? (
							<>
								{temperatureExceptionDetailEventWithComment.map((eventWithComment) => (
									<tr key={eventWithComment.event_Id}>
										<td style={{ ...styles.cellLast, ...styles.bottomBorder }}>
											<TemperatureExceptionEventDetailReport
												temperatureException={eventWithComment}
												commentBox={<span>{eventWithComment.comment}</span>}
												width="700px"
												height="300px"
											/>
										</td>
									</tr>
								))}
							</>
						) : (
							<tr>
								<td style={{ ...styles.centeredCell, ...styles.largeFont }}>
									<span>No Detailed Views selected</span>
								</td>
							</tr>
						)}
					</ReportTable>
					<ReportTable
						appState={context.appState}
						isSiteContext={isSiteContext}
						monthDate={monthDate}
						title="Temperature Exception Details"
						columnHeaders={['GRADE', 'TMV ID', 'LOCATION', 'EXCEPTION COUNT', 'START TIME', 'END TIME']}
					>
						<>
							{temperatureExceptionSummaries.length > 0 ? (
								temperatureExceptionSummaries
									.sort((a, b) => b.alertCount - a.alertCount)
									.map((tempExSumm) =>
										tempExSumm.temperatureExceptions
											.sort((a, b) => ((a.startTs || '') < (b.startTs || '') ? -1 : 1))
											.map((tempEx, index, arr) =>
												arr.length > 20 && index > 9 && index < arr.length - 10 ? (
													index === 10 ? (
														<tr key={`${tempEx.event_Id}`}>
															<td
																style={{
																	...styles.cell,
																	...(tempExSumm.isHighGrade ? styles.highGrade : styles.lowGrade),
																	...lastRow(index, arr),
																}}
																colSpan={2}
															></td>
															<td
																style={{
																	...styles.cell,
																	...styles.topAlignedCell,
																	...lastRow(index, arr),
																}}
																colSpan={2}
															></td>

															<td style={{ ...styles.cell, ...styles.centeredCell, ...styles.bottomBorder }} colSpan={2}>
																{arr.slice(10, -10).length} exceptions omitted
															</td>
														</tr>
													) : null
												) : (
													<tr key={`${tempEx.event_Id}`}>
														<td
															style={{
																...styles.cell,
																...(tempExSumm.isHighGrade ? styles.highGrade : styles.lowGrade),
																...lastRow(index, arr),
															}}
														>
															{index === 0 ? (tempExSumm.isHighGrade ? 'HIGH' : 'LOW') : ''}
														</td>
														<td
															style={{
																...styles.cell,
																...(tempExSumm.isHighGrade ? styles.highGrade : styles.lowGrade),
																...lastRow(index, arr),
															}}
														>
															{index === 0 ? tempEx.asset_Name : ''}
														</td>
														<td
															style={{
																...styles.cell,
																...styles.topAlignedCell,
																...lastRow(index, arr),
															}}
														>
															{index === 0 ? tempEx.asset_Location : ''}
														</td>
														<td
															style={{
																...styles.cell,
																...styles.topAlignedCell,
																...lastRow(index, arr),
															}}
														>
															{index === 0 ? tempExSumm.alertCount : ''}
														</td>

														<td style={{ ...styles.cell, ...styles.bottomBorder }}>
															{tempEx.startTs
																? formatIncomingDateTime({
																		dateTime: tempEx.startTs,
																		format: 'DateAndTime',
																		timeZone: tempEx.site_Timezone,
																  })
																: ''}
														</td>

														<td style={{ ...styles.cellLast, ...styles.bottomBorder }}>
															{tempEx.finishTs
																? formatIncomingDateTime({
																		dateTime: tempEx.finishTs,
																		format: 'DateAndTime',
																		timeZone: tempEx.site_Timezone,
																  })
																: ''}
														</td>
													</tr>
												)
											)
									)
							) : (
								<tr>
									<td colSpan={6} style={{ ...styles.centeredCell, ...styles.largeFont }}>
										<span>No Temperature Exception events detected</span>
									</td>
								</tr>
							)}
						</>
					</ReportTable>
					<ReportTable
						appState={context.appState}
						isSiteContext={isSiteContext}
						monthDate={monthDate}
						title="Thermal Flush Events"
						columnHeaders={['TMV ID', 'LOCATION', 'THERMAL FLUSH COUNT']}
					>
						<>
							{temperatureExceptionSummaries.filter((summ) => summ.thermalFlushCount > 0).length > 0 ? (
								temperatureExceptionSummaries
									.filter((summ) => summ.thermalFlushCount > 0)
									.sort((a, b) => b.thermalFlushCount - a.thermalFlushCount)
									.map((tempExSumm) =>
										tempExSumm.temperatureExceptions
											.sort((a, b) => ((a.startTs || '') < (b.startTs || '') ? -1 : 1))
											.map((tempEx) => (
												<tr key={`${tempEx.event_Id}`}>
													<td
														style={{
															...styles.cell,
															...styles.bottomBorder,
														}}
													>
														{tempEx.asset_Name}
													</td>
													<td
														style={{
															...styles.cell,
															...styles.bottomBorder,
														}}
													>
														{tempEx.asset_Location}
													</td>
													<td
														style={{
															...styles.cell,
															...styles.centeredCell,
															...styles.bottomBorder,
														}}
													>
														{tempExSumm.thermalFlushCount}
													</td>
												</tr>
											))
									)
							) : (
								<tr>
									<td colSpan={3} style={{ ...styles.centeredCell, ...styles.largeFont }}>
										<span>No Thermal Flushes</span>
									</td>
								</tr>
							)}
						</>
					</ReportTable>
					<ReportTable
						appState={context.appState}
						isSiteContext={isSiteContext}
						monthDate={monthDate}
						title="Temperature Exception History"
						columnHeaders={['TMV ID', 'LOCATION'].concat(
							Array.from({ length: 6 }).map((_, i) => monthDate.minus({ months: 5 - i }).toFormat('MM/yyyy'))
						)}
					>
						<>
							{tempExceptionHistoryAssets
								.sort((a, b) => (a.asset_Name < b.asset_Name ? -1 : 1))
								.map((reading) => (
									<tr key={reading.asset_Id} style={styles.bottomBorder}>
										<td style={styles.cell}>{reading.asset_Name}</td>
										<td style={styles.cell}>{reading.asset_Location}</td>

										{reading.monthlyAlertCounts.map((count, i, arr) => (
											<td key={i} style={i === arr.length - 1 ? styles.cellLast : styles.cell}>
												{count}
											</td>
										))}
									</tr>
								))}
							{tempExceptionHistoryAssets.length === 0 && (
								<tr>
									<td colSpan={8} style={{ ...styles.centeredCell, ...styles.largeFont }}>
										<span>No Temperature Exception events detected in {monthDate.toFormat('MMMM yyyy')}</span>
									</td>
								</tr>
							)}
						</>
					</ReportTable>
					<ReportTable appState={context.appState} isSiteContext={isSiteContext} monthDate={monthDate} title="Temperature Exception Events">
						<tr>
							<td style={{ ...styles.centeredCell, ...styles.cell }}>
								<TemperatureExceptionEventsGraph
									context={isSiteContext ? 'site' : 'siteGroup'}
									endMonthDate={monthDate}
									noCard
									disableAnimation
									barLabels
									width="1000px"
									height="500px"
								/>
							</td>
						</tr>
					</ReportTable>
				</>
			)}
			{pageStatus === 'Loading' && <div>Loading...</div>}
			{pageStatus === 'Error' && <div>Error</div>}
		</div>
	)
}

export default FleetHealthRender
