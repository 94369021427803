import React from 'react'
import { AppContext } from '../../App'
import PickerHeader from '../../components/UI/ContextualPickers/PickerHeader'
import Error from '../../components/UI/Error/Error'
import { Col, Row } from 'react-bootstrap'
import TemperatureExceptionEventsGraph from '../../components/UI/Trends/TemperatureExceptionEventsGraph'
import TemperatureExceptionEventsLowCountGraph from '../../components/UI/Trends/TemperatureExceptionEventsLowCountGraph'
import MonthlyReadingGraph from '../../components/UI/Trends/MonthlyReadingGraph'

const Trends = () => {
	const context = React.useContext(AppContext)
	const usageContext = context.appState.currentSite !== null ? 'site' : context.appState.currentSiteGroup !== null ? 'siteGroup' : null

	return usageContext ? (
		<>
			<PickerHeader title="Trends" type={usageContext} />
			<Row>
				<Col sm={6}>
					<MonthlyReadingGraph context={usageContext} />
				</Col>
				<Col sm={6}>
					<TemperatureExceptionEventsGraph context={usageContext} />
				</Col>
			</Row>
			<Row>
				<Col sm={6}>
					<TemperatureExceptionEventsLowCountGraph context={usageContext} />
				</Col>
			</Row>
		</>
	) : (
		<Error showHomeButton />
	)
}

export default Trends
