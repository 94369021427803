import { AssetClassName } from './assetClass'

export const AssetTypeNameValues = [
	'Basin',
	'Bath',
	'Other Fixture',
	'Shower',
	'Sink',
	'Tub',
	'Water Closet',
	'SFM Standard Hub',
	'SFM TFP Hub',
	'RF Compact Transmitter',
	'RF Multi Transmitter',
	'Serial Port',
	'Gateway',
	'Supply Line',
	'TMV',
] as const
export type AssetTypeName = (typeof AssetTypeNameValues)[number]

export const AssetTypeIdValues = [
	'fixture0-basi-0000-0000-000000000000',
	'fixture0-bath-0000-0000-000000000000',
	'fixture0-oth0-0000-0000-000000000000',
	'fixture0-show-0000-0000-000000000000',
	'fixture0-sink-0000-0000-000000000000',
	'fixture0-tub0-0000-0000-000000000000',
	'fixture0-wc00-0000-0000-000000000000',
	'sfmstd00-hub0-0000-0000-000000000000',
	'sfmtfp00-hub0-0000-0000-000000000000',
	'rfcompac-tran-0000-0000-000000000000',
	'rfmulti0-tran-0000-0000-000000000000',
	'network0-seri-0000-0000-000000000000',
	'network0-gate-0000-0000-000000000000',
	'supply00-pipe-0000-0000-000000000000',
	'tmv00000-tmv0-0000-0000-000000000000',
] as const
export type AssetTypeId = (typeof AssetTypeIdValues)[number]

export type AssetType = { id: AssetTypeId; assetClass: AssetClassName }

export type AssetTypeRecord = Record<AssetTypeName, AssetType>
export const assetType: AssetTypeRecord = {
	Basin: { id: 'fixture0-basi-0000-0000-000000000000', assetClass: 'Fixture' },
	Bath: { id: 'fixture0-bath-0000-0000-000000000000', assetClass: 'Fixture' },
	'Other Fixture': { id: 'fixture0-oth0-0000-0000-000000000000', assetClass: 'Fixture' },
	Shower: { id: 'fixture0-show-0000-0000-000000000000', assetClass: 'Fixture' },
	Sink: { id: 'fixture0-sink-0000-0000-000000000000', assetClass: 'Fixture' },
	Tub: { id: 'fixture0-tub0-0000-0000-000000000000', assetClass: 'Fixture' },
	'Water Closet': { id: 'fixture0-wc00-0000-0000-000000000000', assetClass: 'Fixture' },
	'SFM Standard Hub': { id: 'sfmstd00-hub0-0000-0000-000000000000', assetClass: 'Hub' },
	'SFM TFP Hub': { id: 'sfmtfp00-hub0-0000-0000-000000000000', assetClass: 'Hub' },
	'RF Compact Transmitter': { id: 'rfcompac-tran-0000-0000-000000000000', assetClass: 'Transmitter' },
	'RF Multi Transmitter': { id: 'rfmulti0-tran-0000-0000-000000000000', assetClass: 'Transmitter' },
	'Serial Port': { id: 'network0-seri-0000-0000-000000000000', assetClass: 'Network' },
	Gateway: { id: 'network0-gate-0000-0000-000000000000', assetClass: 'Network' },
	'Supply Line': { id: 'supply00-pipe-0000-0000-000000000000', assetClass: 'Pipework' },
	TMV: { id: 'tmv00000-tmv0-0000-0000-000000000000', assetClass: 'Tmv' },
}

export const assetTypeDropdownValues = AssetTypeNameValues.map((value) => ({
	value: assetType[value].id,
	label: value,
}))

export const getAssetTypeNameFromId = (assetType_Id: AssetTypeId) => {
	const assetType_Name = AssetTypeNameValues.find((assetType_Name) => assetType[assetType_Name].id === assetType_Id)
	if (!assetType_Name) throw new Error(`Asset name not found for assetType_Id: ${assetType_Id}`)
	return assetType_Name
}

export const getAssetTypeIdFromName = (assetType_Name: AssetTypeName) => {
	const assetType_Id = AssetTypeIdValues.find((assetType_Id) => assetType[assetType_Name].id === assetType_Id)
	if (!assetType_Id) throw new Error(`AssetType_Id not found for name: ${assetType_Name}`)
	return assetType_Id
}

export const getAssetTypeAssetClassFromId = (assetType_Id: AssetTypeId) => {
	return assetType[getAssetTypeNameFromId(assetType_Id as AssetTypeId)].assetClass
}
