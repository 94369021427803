import React, { useContext } from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { Button } from '../Button/Button'
import { AppContext } from '../../../App'
import plus from '../../../images/icons/add-white.svg'
import filters from '../../../images/icons/filter.svg'
import { colors } from '../../../constants/colors'
import { Card } from '../../../screens/Site/SiteDashboard'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../utilities/permissions/permission.d'
import PermissionsCheck from '../../Permissions/PermissionsCheck'

interface SiteDashboardHeaderProps {
	setShowAddGroupModal: React.Dispatch<React.SetStateAction<boolean>>
	setShowAddSiteModal: React.Dispatch<React.SetStateAction<boolean>>
	setSortBy: React.Dispatch<React.SetStateAction<keyof Card>>
	setFilters: React.Dispatch<React.SetStateAction<string>>
}

const SiteDashboardHeader = (props: SiteDashboardHeaderProps) => {
	const context = useContext(AppContext)

	const goodMorningAndInCaseIDontSeeYaGoodAfternoonGoodEveningAndGoodNight = () => {
		const date = new Date()
		const hours = date.getHours()

		if (hours < 12) {
			return 'Good morning'
		} else if (hours >= 12 && hours < 18) {
			return 'Good afternoon'
		} else {
			return 'Good evening'
		}
	}

	return (
		<Row style={styles.siteDashboardHeader}>
			<Col>
				<Row>
					<Col>
						<span style={styles.headerTextSmall}>Site Management Dashboard</span>
					</Col>
				</Row>
				<Row style={styles.headerText}>
					<Col>
						<span style={styles.headerTextLarge} id="user-welcome">
							{goodMorningAndInCaseIDontSeeYaGoodAfternoonGoodEveningAndGoodNight()}, {context.appState.userAttributes.user_FirstName}
						</span>
					</Col>
				</Row>
				<Row style={styles.headerButtons}>
					<PermissionsCheck object={PermissionModelObject.SiteGroup} action={PermissionModelAction.POST} context={PermissionModelContext.None}>
						<Col sm={'auto'}>
							<Button onClick={() => props.setShowAddGroupModal(true)}>
								<img style={styles.buttonIcon} src={plus} alt={'plus icon'} />
								Add New Group
							</Button>
						</Col>
					</PermissionsCheck>
					<PermissionsCheck object={PermissionModelObject.Site} action={PermissionModelAction.POST} context={PermissionModelContext.None}>
						<Col sm={'auto'}>
							<Button onClick={() => props.setShowAddSiteModal(true)}>
								<img style={styles.buttonIcon} src={plus} alt={'plus icon'} />
								Add New Site
							</Button>
						</Col>
					</PermissionsCheck>
					<Col />
					<Col sm={'auto'}>
						<Dropdown style={styles.rightAlign}>
							<Dropdown.Toggle variant="secondary">Sort By</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => props.setSortBy('name')}>Alphabetical</Dropdown.Item>
								<Dropdown.Item onClick={() => props.setSortBy('created')}>Date Created</Dropdown.Item>
								<Dropdown.Item onClick={() => props.setSortBy('assetCount')}>Number of Assets</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
					<Col sm={'auto'}>
						<Dropdown style={styles.rightAlign}>
							<Dropdown.Toggle variant="secondary">
								<img style={styles.buttonIcon} src={filters} alt={'plus icon'} /> Filters
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => props.setFilters('none')}>None</Dropdown.Item>
								<Dropdown.Item onClick={() => props.setFilters('withAssets')}>With Assets</Dropdown.Item>
								<Dropdown.Item onClick={() => props.setFilters('withoutAssets')}>Without Assets</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

const styles = {
	siteDashboardHeader: {
		backgroundColor: colors.colorPrimaryCyan,
		height: '257px',
		paddingTop: '25px',
		paddingLeft: '50px',
		paddingRight: '50px',
	},
	headerText: {
		paddingTop: '10px',
	},
	headerButtons: {
		paddingTop: '30px',
	},
	headerTextSmall: {
		fontSize: '12px',
	},
	headerTextLarge: {
		fontSize: '26px',
	},
	rightAlign: {
		float: 'right' as const,
	},
	buttonIcon: {
		marginRight: '10px',
	},
}

export default SiteDashboardHeader
