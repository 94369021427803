// prettier-ignore
export const assetImportExampleData: Record<string, string[][]> = {
	Fixtures: [
		['Basin', 'BSN-001-1-R01', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R01', ''],
		['Shower', 'SWR-001-1-R01', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R01', ''],
		['Basin', 'BSN-001-1-R02', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R02', ''],
		['Shower', 'SWR-001-1-R02', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R02', ''],
		['Basin', 'BSN-001-1-H01', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'H01', 'Near R02'],
		['Basin', 'BSN-001-2-R03', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R03', ''],
		['Shower', 'SWR-001-2-R03', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R03', ''],
		['Basin', 'BSN-001-2-R04', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R04', ''],
		['Shower', 'SWR-001-2-R04', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R04', ''],
		['Basin', 'BSN-001-3-R05', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R05', ''],
		['Shower', 'SWR-001-3-R05', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R05', ''],
		['Basin', 'BSN-001-3-R06', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R06', ''],
		['Shower', 'SWR-001-3-R06', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R06', ''],
		['Basin', 'BSN-001-4-R07', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R07', ''],
		['Shower', 'SWR-001-4-R07', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R07', ''],
		['Basin', 'BSN-001-4-R08', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R08', ''],
		['Shower', 'SWR-001-4-R08', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R08', ''],
		['Basin', 'BSN-001-4-H02', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'H02', 'Near R08'],
		['Basin', 'BSN-001-5-R09', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R09', ''],
		['Shower', 'SWR-001-5-R09', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R09', ''],
		['Basin', 'BSN-001-5-R10', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R10', ''],
		['Shower', 'SWR-001-5-R10', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R10', ''],
		['Basin', 'BSN-001-6-R11', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R11', ''],
		['Shower', 'SWR-001-6-R11', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R11', ''],
		['Basin', 'BSN-001-6-R12', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R12', ''],
		['Shower', 'SWR-001-6-R12', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R12', ''],
		['Basin', 'BSN-001-7-R13', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R13', ''],
		['Shower', 'SWR-001-7-R13', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R13', ''],
		['Basin', 'BSN-001-7-R14', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R14', ''],
		['Shower', 'SWR-001-7-R14', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R14', ''],
		['Basin', 'BSN-001-7-H03', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'H03', 'Near R14'],
		['Basin', 'BSN-001-8-R15', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R15', ''],
		['Shower', 'SWR-001-8-R15', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R15', ''],
		['Basin', 'BSN-001-8-R16', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R16', ''],
		['Shower', 'SWR-001-8-R16', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R16', ''],
		['Basin', 'BSN-002-1-R17', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R17', ''],
		['Shower', 'SWR-002-1-R17', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R17', ''],
		['Basin', 'BSN-002-1-R18', '', '', '2023-08-01', 'Enware', 'Basin 5000', 'B1', 'L1', 'A1', 'R18', ''],
		['Shower', 'SWR-002-1-R18', '', '', '2023-08-01', 'Enware', 'Shower 2000', 'B1', 'L1', 'A1', 'R18', '']
		
	],
	TMV: [
		['TMV', 'TMV-001-1', '', '', '', '2023-08-01', 'Enware', 'AQUABLEND 1500', 'B1', 'L1', 'A1', 'R01', 'Ensuite Wall', 'BSN-001-1-R01;SWR-001-1-R01;BSN-001-1-R02;SWR-001-1-R02;BSN-001-1-H01', ''],
		['TMV', 'TMV-001-2', '', '', '', '2023-08-01', 'Enware', 'AQUABLEND 1500', 'B1', 'L1', 'A1', 'R03', 'Ensuite Wall', 'BSN-001-2-R03;SWR-001-2-R03;BSN-001-2-R04;SWR-001-2-R04', ''],
		['TMV', 'TMV-001-3', '', '', '', '2023-08-01', 'Enware', 'AQUABLEND 1500', 'B1', 'L1', 'A1', 'R05', 'Ensuite Wall', 'BSN-001-3-R05;SWR-001-3-R05;BSN-001-3-R06;SWR-001-3-R06', ''],
		['TMV', 'TMV-001-4', '', '', '', '2023-08-01', 'Enware', 'AQUABLEND 1500', 'B1', 'L1', 'A1', 'R07', 'Ensuite Wall', 'BSN-001-4-R07;SWR-001-4-R07;BSN-001-4-R08;SWR-001-4-R08;BSN-001-4-H02', ''],
		['TMV', 'TMV-001-5', '', '', '', '2023-08-01', 'Enware', 'AQUABLEND 1500', 'B1', 'L1', 'A1', 'R09', 'Ensuite Wall', 'BSN-001-5-R09;SWR-001-5-R09;BSN-001-5-R10;SWR-001-5-R10', ''],
		['TMV', 'TMV-001-6', '', '', '', '2023-08-01', 'Enware', 'AQUABLEND 1500', 'B1', 'L1', 'A1', 'R11', 'Ensuite Wall', 'BSN-001-6-R11;SWR-001-6-R11;BSN-001-6-R12;SWR-001-6-R12', ''],
		['TMV', 'TMV-001-7', '', '', '', '2023-08-01', 'Enware', 'AQUABLEND 1500', 'B1', 'L1', 'A1', 'R13', 'Ensuite Wall', 'BSN-001-7-R13;SWR-001-7-R13;BSN-001-7-R14;SWR-001-7-R14;BSN-001-7-H03', ''],
		['TMV', 'TMV-001-8', '', '', '', '2023-08-01', 'Enware', 'AQUABLEND 1500', 'B1', 'L1', 'A1', 'R15', 'Ensuite Wall', 'BSN-001-8-R15;SWR-001-8-R15;BSN-001-8-R16;SWR-001-8-R16', ''],
		['TMV', 'TMV-002-1', '', '', '', '2023-08-01', 'Enware', 'AQUABLEND 1500', 'B1', 'L1', 'A1', 'R17', 'Ensuite Wall', 'BSN-002-1-R17;SWR-002-1-R17;BSN-002-1-R18;SWR-002-1-R18', '']
	],
	'SFM TFP Hub': [
		['SFM TFP HUB', 'HUB-001', '', '', '2023-08-01', '1', 'STF-TFP', 'SFM-TFP-001', 'B1', 'L1', 'A1', 'Hallway', 'in ceiling near R01', 'TMV-E-001;TMV-C-001;BSN-E-001;BSN-H-001', 'TMV-E-001', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', 'TMV-C-001', '', 'COLD', '', 'HOT', 'WARM', 'WARM', 'BSN-E-001', '', '', 'HOT', 'HOT', '', '', '', '', '', '', '', '', 'BSN-H-001', '', '', 'HOT', 'HOT', '', '', '', '', ''],
		['SFM TFP HUB', 'HUB-002', '', '', '2023-08-01', '2', 'STF-TFP', 'SFM-TFP-001', 'B1', 'L1', 'A1', 'Hallway', 'in ceiling near R02', 'TMV-E-002;TMV-C-002;BSN-E-002;BSN-H-002', 'TMV-E-002', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', 'TMV-C-002', '', 'COLD', '', 'HOT', 'WARM', 'WARM', 'BSN-E-002', '', '', 'HOT', 'HOT', '', '', '', '', '', '', '', '', 'BSN-H-002', '', '', 'HOT', 'HOT', '', '', '', '', ''],
		['SFM TFP HUB', 'HUB-003', '', '', '2023-08-01', '3', 'STF-TFP', 'SFM-TFP-001', 'B1', 'L1', 'A1', 'Hallway', 'in ceiling near R03', 'TMV-E-003;TMV-C-003;BSN-E-003;BSN-H-003', 'TMV-E-003', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', 'TMV-C-003', '', 'COLD', '', 'HOT', 'WARM', 'WARM', 'BSN-E-003', '', '', 'HOT', 'HOT', '', '', '', '', '', '', '', '', 'BSN-H-003', '', '', 'HOT', 'HOT', '', '', '', '', ''],
		['SFM TFP HUB', 'HUB-004', '', '', '2023-08-01', '4', 'STF-TFP', 'SFM-TFP-001', 'B1', 'L1', 'A1', 'Hallway', 'in ceiling near R04', 'TMV-E-004;TMV-C-004;BSN-E-004;BSN-H-004', 'TMV-E-004', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', 'TMV-C-004', '', 'COLD', '', 'HOT', 'WARM', 'WARM', 'BSN-E-004', '', '', 'HOT', 'HOT', '', '', '', '', '', '', '', '', 'BSN-H-004', '', '', 'HOT', 'HOT', '', '', '', '', ''],
		['SFM TFP HUB', 'HUB-005', '', '', '2023-08-01', '5', 'STF-TFP', 'SFM-TFP-001', 'B1', 'L1', 'A1', 'Hallway', 'in ceiling near R05', 'TMV-E-005;TMV-C-005;BSN-E-005;BSN-H-005', 'TMV-E-005', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', 'TMV-C-005', '', 'COLD', '', 'HOT', 'WARM', 'WARM', 'BSN-E-005', '', '', 'HOT', 'HOT', '', '', '', '', '', '', '', '', 'BSN-H-005', '', '', 'HOT', 'HOT', '', '', '', '', ''],
		['SFM TFP HUB', 'HUB-006', '', '', '2023-08-01', '6', 'STF-TFP', 'SFM-TFP-001', 'B1', 'L1', 'A1', 'Hallway', 'in ceiling near R06', 'TMV-E-006;TMV-C-006;BSN-E-006;BSN-H-006', 'TMV-E-006', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', 'TMV-C-006', '', 'COLD', '', 'HOT', 'WARM', 'WARM', 'BSN-E-006', '', '', 'HOT', 'HOT', '', '', '', '', '', '', '', '', 'BSN-H-006', '', '', 'HOT', 'HOT', '', '', '', '', ''],
		['SFM TFP HUB', 'HUB-007', '', '', '2023-08-01', '7', 'STF-TFP', 'SFM-TFP-001', 'B1', 'L1', 'A1', 'Hallway', 'in ceiling near R07', 'TMV-E-007;TMV-C-007;BSN-E-007;BSN-H-007', 'TMV-E-007', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', 'TMV-C-007', '', 'COLD', '', 'HOT', 'WARM', 'WARM', 'BSN-E-007', '', '', 'HOT', 'HOT', '', '', '', '', '', '', '', '', 'BSN-H-007', '', '', 'HOT', 'HOT', '', '', '', '', ''],
		['SFM TFP HUB', 'HUB-008', '', '', '2023-08-01', '8', 'STF-TFP', 'SFM-TFP-001', 'B1', 'L1', 'A1', 'Hallway', 'in ceiling near R08', 'TMV-E-008;TMV-C-008;BSN-E-008;BSN-H-008', 'TMV-E-008', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', 'TMV-C-008', '', 'COLD', '', 'HOT', 'WARM', 'WARM', 'BSN-E-008', '', '', 'HOT', 'HOT', '', '', '', '', '', '', '', '', 'BSN-H-008', '', '', 'HOT', 'HOT', '', '', '', '', ''],
		['SFM TFP HUB', 'HUB-009', '', '', '2023-08-01', '9', 'STF-TFP', 'SFM-TFP-001', 'B1', 'L1', 'A1', 'Hallway', 'in ceiling near R09', 'TMV-E-009;TMV-C-009;BSN-E-009;BSN-H-009', 'TMV-E-009', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', 'TMV-C-009', '', 'COLD', '', 'HOT', 'WARM', 'WARM', 'BSN-E-009', '', '', 'HOT', 'HOT', '', '', '', '', '', '', '', '', 'BSN-H-009', '', '', 'HOT', 'HOT', '', '', '', '', ''],
		['SFM TFP HUB', 'HUB-010', '', '', '2023-08-01', '10', 'STF-TFP', 'SFM-TFP-001', 'B1', 'L1', 'A1', 'Hallway', 'in ceiling near R10', 'TMV-E-010;TMV-C-010;BSN-E-010;BSN-H-010', 'TMV-E-010', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', 'TMV-C-010', '', 'COLD', '', 'HOT', 'WARM', 'WARM', 'BSN-E-010', '', '', 'HOT', 'HOT', '', '', '', '', '', '', '', '', 'BSN-H-010', '', '', 'HOT', 'HOT', '', '', '', '', '']
	],
	'SFM Standard Hub': [
		['SFM STANDARD HUB', 'HUB-011', '', '', '2023-08-01', '11', 'SFM-HUB-STANDARD', 'SFM-STD', 'B1', 'L1', 'A2', 'Hallway', 'in ceiling near R11', 'TMV-011-1;TMV-011-2;TMV-011-3;TMV-011-4;TMV-011-5;TMV-011-6;TMV-011-7;TMV-011-8', 'TMV-011-1', 'WARM', 'TMV-011-2', 'WARM', 'TMV-011-3', 'WARM', 'TMV-011-4', 'WARM', 'TMV-011-5', 'WARM', 'TMV-011-6', 'WARM', 'TMV-011-7', 'WARM', 'TMV-011-8', 'WARM', ''],
		['SFM STANDARD HUB', 'HUB-012', '', '', '2023-08-01', '12', 'SFM-HUB-STANDARD', 'SFM-STD', 'B1', 'L1', 'A2', 'Hallway', 'in ceiling near R12', 'TMV-012-1;TMV-012-2;TMV-012-3;TMV-012-4;TMV-012-5;TMV-012-6;TMV-012-7;TMV-012-8', 'TMV-012-1', 'WARM', 'TMV-012-2', 'WARM', 'TMV-012-3', 'WARM', 'TMV-012-4', 'WARM', 'TMV-012-5', 'WARM', 'TMV-012-6', 'WARM', 'TMV-012-7', 'WARM', 'TMV-012-8', 'WARM', ''],
		['SFM STANDARD HUB', 'HUB-013', '', '', '2023-08-01', '13', 'SFM-HUB-STANDARD', 'SFM-STD', 'B1', 'L1', 'A2', 'Hallway', 'in ceiling near R13', 'TMV-013-1;TMV-013-2;TMV-013-3;TMV-013-4;TMV-013-5;TMV-013-6', 'TMV-013-1', 'WARM', 'TMV-013-2', 'WARM', 'TMV-013-3', 'WARM', 'TMV-013-4', 'WARM', 'TMV-013-5', 'WARM', 'TMV-013-6', 'WARM', '', '', '', '', ''],
		['SFM STANDARD HUB', 'HUB-014', '', '', '2023-08-01', '14', 'SFM-HUB-STANDARD', 'SFM-STD', 'B1', 'L1', 'A2', 'Hallway', 'in ceiling near R14', 'TMV-014-1;TMV-014-2;TMV-014-3;TMV-014-4;TMV-014-5;TMV-014-6;TMV-014-7;TMV-014-8', 'TMV-014-1', 'WARM', 'TMV-014-2', 'WARM', 'TMV-014-3', 'WARM', 'TMV-014-4', 'WARM', 'TMV-014-5', 'WARM', 'TMV-014-6', 'WARM', 'TMV-014-7', 'WARM', 'TMV-014-8', 'WARM', ''],
		['SFM STANDARD HUB', 'HUB-015', '', '', '2023-08-01', '15', 'SFM-HUB-STANDARD', 'SFM-STD', 'B1', 'L1', 'A2', 'Hallway', 'in ceiling near R15', 'TMV-015-1;TMV-015-2;TMV-015-3;TMV-015-4;TMV-015-5;TMV-015-6;TMV-015-7', 'TMV-015-1', 'WARM', 'TMV-015-2', 'WARM', 'TMV-015-3', 'WARM', 'TMV-015-4', 'WARM', 'TMV-015-5', 'WARM', 'TMV-015-6', 'WARM', 'TMV-015-7', 'WARM', '', '', '', ''],
		['SFM STANDARD HUB', 'HUB-016', '', '', '2023-08-01', '16', 'SFM-HUB-STANDARD', 'SFM-STD', 'B1', 'L1', 'A2', 'Hallway', 'in ceiling near R16', 'TMV-016-1;TMV-016-2;TMV-016-3;TMV-016-4;TMV-016-5;TMV-016-6;TMV-016-7;TMV-016-8', 'TMV-016-1', 'WARM', 'TMV-016-2', 'WARM', 'TMV-016-3', 'WARM', 'TMV-016-4', 'WARM', 'TMV-016-5', 'WARM', 'TMV-016-6', 'WARM', 'TMV-016-7', 'WARM', 'TMV-016-8', 'WARM', ''],
		['SFM STANDARD HUB', 'HUB-017', '', '', '2023-08-01', '17', 'SFM-HUB-STANDARD', 'SFM-STD', 'B1', 'L1', 'A2', 'Hallway', 'in ceiling near R17', 'TMV-017-1;TMV-017-2;TMV-017-3;TMV-017-4;TMV-017-5;TMV-017-6;TMV-017-7', 'TMV-017-1', 'WARM', 'TMV-017-2', 'WARM', 'TMV-017-3', 'WARM', 'TMV-017-4', 'WARM', 'TMV-017-5', 'WARM', 'TMV-017-6', 'WARM', 'TMV-017-7', 'WARM', '', '', '', ''],
		['SFM STANDARD HUB', 'HUB-018', '', '', '2023-08-01', '18', 'SFM-HUB-STANDARD', 'SFM-STD', 'B1', 'L1', 'A2', 'Hallway', 'in ceiling near R18', 'TMV-018-1;TMV-018-2;TMV-018-3;TMV-018-4;TMV-018-5;TMV-018-6;TMV-018-7;TMV-018-8', 'TMV-018-1', 'WARM', 'TMV-018-2', 'WARM', 'TMV-018-3', 'WARM', 'TMV-018-4', 'WARM', 'TMV-018-5', 'WARM', 'TMV-018-6', 'WARM', 'TMV-018-7', 'WARM', 'TMV-018-8', 'WARM', ''],
		['SFM STANDARD HUB', 'HUB-019', '', '', '2023-08-01', '19', 'SFM-HUB-STANDARD', 'SFM-STD', 'B1', 'L1', 'A2', 'Hallway', 'in ceiling near R19', 'TMV-019-1;TMV-019-2;TMV-019-3;TMV-019-4', 'TMV-019-1', 'WARM', 'TMV-019-2', 'WARM', 'TMV-019-3', 'WARM', 'TMV-019-4', 'WARM', '', '', '', '', '', '', '', '', ''],
		['SFM STANDARD HUB', 'HUB-020', '', '', '2023-08-01', '20', 'SFM-HUB-STANDARD', 'SFM-STD', 'B1', 'L1', 'A2', 'Hallway', 'in ceiling near R20', 'TMV-020-1;TMV-020-2;TMV-020-3;TMV-020-4;TMV-020-5;TMV-020-6;TMV-020-7;TMV-020-8', 'TMV-020-1', 'WARM', 'TMV-020-2', 'WARM', 'TMV-020-3', 'WARM', 'TMV-020-4', 'WARM', 'TMV-020-5', 'WARM', 'TMV-020-6', 'WARM', 'TMV-020-7', 'WARM', 'TMV-020-8', 'WARM', '']
	],
	'RF Multi Transmitter': [
		['SFM MULTI TRANSMITTER', 'RF-001', '', '', '2023-08-01', '22-36-36-32-7C-39-69-18', 'stm32wl-v1', 'v1.8.1-0-ge6b875b5', 'B1', 'L1', 'A1', 'R21', 'Ensuite Wall', 'TMV-001', 'TMV-001', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', ''],
		['SFM MULTI TRANSMITTER', 'RF-002', '', '', '2023-08-01', '22-38-30-34-5A-39-6C-02', 'stm32wl-v1', 'v1.8.1-0-ge6b875b5', 'B1', 'L1', 'A1', 'R22', 'Ensuite Wall', 'TMV-002', 'TMV-002', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', ''],
		['SFM MULTI TRANSMITTER', 'RF-003', '', '', '2023-08-01', '22-38-30-34-4B-39-81-02', 'stm32wl-v1', 'v1.8.1-0-ge6b875b5', 'B1', 'L1', 'A1', 'R23', 'Ensuite Wall', 'TMV-003', 'TMV-003', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', ''],
		['SFM MULTI TRANSMITTER', 'RF-004', '', '', '2023-08-01', '22-36-36-32-74-39-6A-18', 'stm32wl-v1', 'v1.8.1-0-ge6b875b5', 'B1', 'L1', 'A1', 'R24', 'Ensuite Wall', 'TMV-004', 'TMV-004', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', ''],
		['SFM MULTI TRANSMITTER', 'RF-005', '', '', '2023-08-01', '22-36-36-32-7E-39-69-18', 'stm32wl-v1', 'v1.8.1-0-ge6b875b5', 'B1', 'L1', 'A1', 'R25', 'Ensuite Wall', 'TMV-005', 'TMV-005', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', ''],
		['SFM MULTI TRANSMITTER', 'RF-006', '', '', '2023-08-01', '22-38-30-34-57-39-65-02', 'murata-v1', 'v1.8.1-0-g75b5e6b8', 'B1', 'L1', 'A1', 'R26', 'Ensuite Wall', 'TMV-006', 'TMV-006', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', ''],
		['SFM MULTI TRANSMITTER', 'RF-007', '', '', '2023-08-01', '22-38-30-34-69-39-6C-02', 'murata-v1', 'v1.8.1-0-g75b5e6b8', 'B1', 'L1', 'A1', 'R27', 'Ensuite Wall', 'TMV-007', 'TMV-007', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', ''],
		['SFM MULTI TRANSMITTER', 'RF-008', '', '', '2023-08-01', '22-37-32-36-54-39-80-0E', 'murata-v1', 'v1.8.1-0-g75b5e6b8', 'B1', 'L1', 'A1', 'R28', 'Ensuite Wall', 'TMV-008', 'TMV-008', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', ''],
		['SFM MULTI TRANSMITTER', 'RF-009', '', '', '2023-08-01', '22-36-36-32-68-39-66-18', 'murata-v1', 'v1.8.1-0-g75b5e6b8', 'B1', 'L1', 'A1', 'R29', 'Ensuite Wall', 'TMV-009', 'TMV-009', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', ''],
		['SFM MULTI TRANSMITTER', 'RF-010', '', '', '2023-08-01', '22-38-30-34-70-39-7B-02', 'murata-v1', 'v1.8.1-0-g75b5e6b8', 'B1', 'L1', 'A1', 'R30', 'Ensuite Wall', 'TMV-010', 'TMV-010', 'COLD', 'COLD', 'HOT', 'HOT', '', 'WARM', '']
	],
	'RF Compact Transmitter': [
		['SFM COMPACT TRANSMITTER', 'RF-011', '', '', '2023-08-01', '22-36-36-32-1C-39-69-00', 'stm32wl-v1', 'v1.8.1-0-ge6b875b5', 'B1', 'L1', 'A1', 'R21', 'Ensuite Spout', 'TMV-001', 'TMV-001', 'WARM', 'WARM', ''],
		['SFM COMPACT TRANSMITTER', 'RF-012', '', '', '2023-08-01', '22-36-36-32-7C-39-69-A1', 'stm32wl-v1', 'v1.8.1-0-ge6b875b5', 'B1', 'L1', 'A1', 'R22', 'Ensuite Spout', 'TMV-002', 'TMV-002', 'WARM', 'WARM', ''],
		['SFM COMPACT TRANSMITTER', 'RF-013', '', '', '2023-08-01', '22-36-36-32-1C-39-69-01', 'stm32wl-v1', 'v1.8.1-0-ge6b875b5', 'B1', 'L1', 'A1', 'R23', 'Ensuite Spout', 'TMV-003', 'TMV-003', 'WARM', 'WARM', ''],
		['SFM COMPACT TRANSMITTER', 'RF-014', '', '', '2023-08-01', '22-36-36-32-7C-39-69-A2', 'stm32wl-v1', 'v1.8.1-0-ge6b875b5', 'B1', 'L1', 'A1', 'R24', 'Ensuite Spout', 'TMV-004', 'TMV-004', 'WARM', 'WARM', ''],
		['SFM COMPACT TRANSMITTER', 'RF-015', '', '', '2023-08-01', '22-36-36-32-1C-39-69-02', 'stm32wl-v1', 'v1.8.1-0-ge6b875b5', 'B1', 'L1', 'A1', 'R25', 'Ensuite Spout', 'TMV-005', 'TMV-005', 'WARM', 'WARM', ''],
		['SFM COMPACT TRANSMITTER', 'RF-016', '', '', '2023-08-01', '22-36-36-32-7C-39-69-A3', 'murata-v1', 'v1.8.1-0-g75b5e6b8', 'B1', 'L1', 'A1', 'R26', 'Ensuite Spout', 'TMV-006', 'TMV-006', 'WARM', 'WARM', ''],
		['SFM COMPACT TRANSMITTER', 'RF-017', '', '', '2023-08-01', '22-36-36-32-1C-39-69-03', 'murata-v1', 'v1.8.1-0-g75b5e6b8', 'B1', 'L1', 'A1', 'R27', 'Ensuite Spout', 'TMV-007', 'TMV-007', 'WARM', 'WARM', ''],
		['SFM COMPACT TRANSMITTER', 'RF-018', '', '', '2023-08-01', '22-36-36-32-7C-39-69-A4', 'murata-v1', 'v1.8.1-0-g75b5e6b8', 'B1', 'L1', 'A1', 'R28', 'Ensuite Spout', 'TMV-008', 'TMV-008', 'WARM', 'WARM', ''],
		['SFM COMPACT TRANSMITTER', 'RF-019', '', '', '2023-08-01', '22-36-36-32-1C-39-69-04', 'murata-v1', 'v1.8.1-0-g75b5e6b8', 'B1', 'L1', 'A1', 'R29', 'Ensuite Spout', 'TMV-009', 'TMV-009', 'WARM', 'WARM', ''],
		['SFM COMPACT TRANSMITTER', 'RF-020', '', '', '2023-08-01', '22-36-36-32-7C-39-69-A5', 'murata-v1', 'v1.8.1-0-g75b5e6b8', 'B1', 'L1', 'A1', 'R30', 'Ensuite Spout', 'TMV-010', 'TMV-010', 'WARM', 'WARM', '']
	],
	'Serial Port': [
		['SF LAN INTERFACE', 'LAN-001', '', '', '2023-08-01', '192.168.1.51', 'COM11', 'B1', 'L1', 'A1', 'Comm1', 'Rack 5-1', 'HUB-001;HUB-002;HUB-003;HUB-004;HUB-005;HUB-006;HUB-007;HUB-008;HUB-009;HUB-010'],
		['SF LAN INTERFACE', 'LAN-002', '', '', '2023-08-01', '192.168.1.52', 'COM12', 'B1', 'L2', 'B1', 'Comm2', 'Rack 5-1', 'HUB-011;HUB-012;HUB-013;HUB-014'],
		['SF LAN INTERFACE', 'LAN-003', '', '', '2023-08-01', '192.168.1.53', 'COM13', 'B1', 'L3', 'C1', 'Comm3', 'Rack 5-1', 'HUB-015;HUB-016;HUB-017'],
		['SF LAN INTERFACE', 'LAN-004', '', '', '2023-08-01', '192.168.1.54', 'COM14', 'B1', 'L4', 'D1', 'Comm4', 'Rack 5-1', 'HUB-018;HUB-019;HUB-020']
	],
	Gateway: [
		['LORAWAN NS GATEWAY', 'GW-001', '', '', '2019-01-01', '192.168.1.2', '22-90-36-32-7C-39-69-18', 'WMSRF001', 'v5.0.0', 'Calvary', 'Gnd', 'Admin', 'Corridor', 'nurse station', 'GW-002;GW-003;GW-004;GW-005;RF-001;RF-002;RF-003;RF-004;RF-005;RF-006;RF-007;RF-008;RF-009;RF-010'],
		['LORAWAN PF GATEWAY', 'GW-002', '', '', '2019-01-01', '192.168.1.3', '22-56-30-34-5A-39-6C-02', 'WMSRF002', 'v5.0.0', 'Calvary', 'L1', 'Maternity', 'Corridor', 'nurse station'],
		['LORAWAN PF GATEWAY', 'GW-003', '', '', '2019-01-01', '192.168.1.4', '22-65-30-34-4B-39-81-02', 'WMSRF002', 'v5.0.0', 'Calvary', 'L2', 'Cancer', 'Corridor', 'nurse station'],
		['LORAWAN PF GATEWAY', 'GW-004', '', '', '2019-01-01', '192.168.1.5', '22-89-36-32-74-39-6A-18', 'WMSRF002', 'v5.0.0', 'Calvary', 'L3', 'Emergency', 'Corridor', 'nurse station'],
		['LORAWAN PF GATEWAY', 'GW-005', '', '', '2019-01-01', '192.168.1.6', '22-98-36-32-7E-39-69-18', 'WMSRF002', 'v5.0.0', 'Calvary', 'L4', 'Radiology', 'Corridor', 'nurse station']
	],
}
