import React from 'react'
import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { FormikErrors, FormikTouched } from 'formik'
import { Card, Row, Col } from 'react-bootstrap'

import { Loading } from '../Loading/Loading'
import { FormText } from '../Form/Text'
import { FormSelect, SelectOption } from '../Form/Select'

import { Task } from '../../../models/Task'
import { PageStatus } from '../../../types/PageStatus'
import { severityDropdownValues } from '../../../constants/severity'
import { taskStatusDropdownValues } from '../../../constants/taskStatus'
import { User, UserResult } from '../../../models/User'
import { userRoleDropdownValues } from '../../../constants/userRole'
import { defaultTaskUser } from '../../../models/TaskUser'
import { defaultCreated, defaultModified } from '../../../models/History'
import { AssetResult, AssetStub } from '../../../models/Asset'
import { defaultTaskAsset } from '../../../models/TaskAsset'

interface TaskInformationProps {
	pageStatus: PageStatus
	task: Task | null
	errors: FormikErrors<Task>
	touched: FormikTouched<Task>
	handleChange: (field: string, value: unknown) => void
}

const TaskInformation = (props: TaskInformationProps) => {
	const context = React.useContext(AppContext)

	const [users, setUsers] = React.useState<User[] | null>(null)
	const [assets, setAssets] = React.useState<AssetStub[] | null>(null)

	React.useEffect(() => {
		const fetchData = async () => {
			const [userReq, assetReq] = await Promise.all([
				Request.get<UserResult>(`task/user?Site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
				Request.get<AssetResult>(`asset/stub?Site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
			])
			setUsers(userReq.data.users)
			setAssets(assetReq.data.assets)
		}
		if (context.appState.authState.isLoggedIn && context.appState.currentSite) {
			fetchData()
		}
	}, [])

	const handleUserOrRoleChange = (newValue: SelectOption<string>[] | null) => {
		const newTaskUsers = newValue
			? newValue.map((option) =>
					defaultTaskUser({
						task_Id: props.task?.task_Id,
						user_Id: users?.find((user) => user.user_Id === option.value)?.user_Id || null,
						taskUser_User: users?.find((user) => user.user_Id === option.value) || undefined,
						userRole_Id: userRoleDropdownValues.find((userRole) => userRole.value === option.value)?.value || null,
						userRole_Name: userRoleDropdownValues.find((userRole) => userRole.value === option.value)?.label || undefined,
						created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
						modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
					})
			  )
			: []
		props.handleChange('task_TaskUsers', newTaskUsers)
	}

	const handlAssetChange = (newValue: SelectOption<string>[] | null) => {
		const newTaskAssets = newValue
			? newValue.map((option) =>
					defaultTaskAsset({
						task_Id: props.task?.task_Id,
						asset_Id: option.value,
						taskAsset_Task: props.task || undefined,
						taskAsset_Asset: assets?.find((asset) => asset.asset_Id === option.value),
						created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
						modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
					})
			  )
			: []
		props.handleChange('task_TaskAssets', newTaskAssets)
	}

	const userDropdownOptions = React.useMemo(() => {
		return users
			? users
					.map((user) => ({ value: user.user_Id, label: `${user.user_FirstName} ${user.user_LastName}` }))
					.concat(userRoleDropdownValues)
					.sort((a, b) => a.label.localeCompare(b.label))
			: []
	}, [users])

	const assetDropdownOptions = React.useMemo(() => {
		return assets ? assets.map((asset) => ({ value: asset.asset_Id, label: asset.asset_Name || '' })) : []
	}, [assets])

	const assignedUserValues = React.useMemo(() => {
		return props.task ? props.task.task_TaskUsers.map((taskUser) => (taskUser.user_Id || taskUser.userRole_Id) as string) : []
	}, [props.task?.task_TaskUsers])

	const assignedAssetValues = React.useMemo(() => {
		return props.task ? props.task.task_TaskAssets.map((taskAsset) => taskAsset.asset_Id as string) : []
	}, [props.task?.task_TaskAssets])

	return (
		<Card style={styles.card} className="site-card">
			{props.task ? (
				<>
					<Row className="generic-card-list-heading-row">
						<Col sm="auto" style={styles.cellGrow}>
							<span className="dashboard-card-titles">Task Information</span>
						</Col>
					</Row>
					<Row style={styles.row}>
						<Col sm={6} className="edit-task-field">
							<FormText
								name={'task_Name'}
								label={'Task Name'}
								value={props.task.task_Name || ''}
								onChange={(e) => props.handleChange('task_Name', e.target.value)}
								disabled={props.pageStatus !== 'Editing'}
								feedback={props.errors?.task_Name}
								isInvalid={props.touched.task_Name && !!props.errors?.task_Name}
								required
							/>
						</Col>
					</Row>
					<Row style={styles.row}>
						<Col sm={6} className="edit-task-field">
							<FormText
								name={'task_Note'}
								label={'Task Description'}
								as="textarea"
								value={props.task.task_Note || ''}
								onChange={(e) => props.handleChange('task_Note', e.target.value)}
								disabled={props.pageStatus !== 'Editing'}
								feedback={props.errors?.task_Note}
								isInvalid={props.touched.task_Note && !!props.errors?.task_Note}
								required
							/>
						</Col>
					</Row>
					<Row style={styles.row}>
						<Col sm={6} className="edit-task-field">
							<FormSelect
								name={'taskUserOrRole_Id'}
								label={props.pageStatus === 'Editing' ? 'Assign to User or Role' : 'Assigned User or Role'}
								options={userDropdownOptions}
								isDisabled={props.pageStatus !== 'Editing'}
								isMulti
								value={assignedUserValues}
								onChange={(options) => handleUserOrRoleChange(options as SelectOption<string>[] | null)}
								loading={!users}
							/>
						</Col>
						<Col sm={6} className="edit-task-field">
							<FormSelect
								name={'taskAsset_Id'}
								label={props.pageStatus === 'Editing' ? 'Assign to Asset' : 'Assigned Asset'}
								options={assetDropdownOptions}
								isDisabled={props.pageStatus !== 'Editing'}
								isMulti
								value={assignedAssetValues}
								onChange={(options) => handlAssetChange(options as SelectOption<string>[] | null)}
								loading={!assets}
							/>
						</Col>
					</Row>
					<Row style={styles.row}>
						<Col sm={6} className="edit-task-field">
							<FormSelect
								name={'severity_Id'}
								label={'Priority'}
								options={severityDropdownValues}
								isDisabled={props.pageStatus !== 'Editing'}
								value={props.task.severity_Id}
								feedback={props.errors?.severity_Id}
								onChange={(e) => props.handleChange('severity_Id', e ? (e as SelectOption<string>).value : null)}
								required
							/>
						</Col>
						<Col sm={6} className="edit-task-field">
							<FormSelect
								name={'taskStatus_Id'}
								label={'Status'}
								options={taskStatusDropdownValues}
								isDisabled={props.pageStatus !== 'Editing'}
								value={props.task.taskStatus_Id}
								feedback={props.touched.taskStatus_Id ? props.errors?.taskStatus_Id : ''}
								onChange={(e) => props.handleChange('taskStatus_Id', e ? (e as SelectOption<string>).value : null)}
								required
							/>
						</Col>
					</Row>
				</>
			) : props.pageStatus === 'Loading' ? (
				<Loading show={true} />
			) : null}
		</Card>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	card: {
		marginTop: '20px',
		minHeight: '560px',
	},
	row: {
		padding: '20px',
		justifyContent: 'space-between',
	},
	cellGrow: {
		flex: '1 1 auto',
	},
	cell: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '48px',
	},
	cell50: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '48px',
		flexBasis: '50%',
	},
}

export { TaskInformation }
