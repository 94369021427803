import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { AssetLiveTempView } from './Asset'

interface LocationArea {
	locationArea_Id: string
	locationArea_Name: string
	locationArea_Note: string | null
	locationFloor_Id: string | null
	site_Id: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface LocationAreaLiveTempView {
	locationArea_Id: string
	location_DisplayName: string
	location_Assets: AssetLiveTempView[]
}

interface LocationAreaResult extends Validate.Result {
	locationAreas: LocationArea[]
}

interface LocationAreaLiveTempResult extends Validate.Result {
	locationAreas: LocationAreaLiveTempView[]
}

const defaultLocationArea = (locationArea: Partial<LocationArea>): LocationArea => ({
	locationArea_Id: locationArea.locationArea_Id || '',
	locationArea_Name: locationArea.locationArea_Name || '',
	locationArea_Note: locationArea.locationArea_Note || null,
	locationFloor_Id: locationArea.locationFloor_Id || null,
	site_Id: locationArea.site_Id || '',

	created: locationArea.created || defaultCreated({}),
	modified: locationArea.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultLocationArea }
export type { LocationArea, LocationAreaLiveTempView, LocationAreaResult, LocationAreaLiveTempResult }
