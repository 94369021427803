import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'

interface Bacnet {
	bacnet_Id: string
	bacnet_IsEnabled: boolean
	bacnet_RegistrationCode: string | null
	bacnet_NetworkNumber: string | null
	bacnet_IPAddress: string | null
	bacnet_PortNumber: string | null
	bacnet_DeviceInstanceNumber: string | null
	bacnet_DeviceName: string | null
	bacnet_DeviceDescription: string | null
	bacnet_DeviceLocation: string | null
	bacnet_UseRecipientDevice: boolean
	bacnet_RecipientDeviceInstanceNumber: string | null
	bacnet_RecipientDeviceProcessNumber: string | null
	bacnet_HubErrorPriority: number | null
	bacnet_PortErrorPriority: number | null
	bacnet_TmvLowLevelAlertPriority: number | null
	bacnet_TmvHighLevelAlertPriority: number | null
	bacnet_AlertDuration: number | null
	bacnet_CovUpdateInterval: number | null
	bacnet_HighLevelAlertInstanceNumber: number | null
	bacnet_HighLevelAlertObjectName: string | null
	bacnet_MaximumForeignDevices: number | null
	bacnet_BBMDIPAddress: string | null
	bacnet_BBMDPortNumber: string | null
	bacnet_HubErrorsTriggerGeneralAlarm: boolean
	bacnet_PortErrorsTriggerGeneralAlarm: boolean
	bacnet_HighLevelAlertsTriggerGeneralAlarm: boolean
	site_Id: string
	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface BacnetResult extends Validate.Result {
	bacnets: Bacnet[]
}

const defaultBacnet = (bacnet: Partial<Bacnet>): Bacnet => ({
	bacnet_Id: bacnet.bacnet_Id || '',
	bacnet_IsEnabled: bacnet.bacnet_IsEnabled || false,
	bacnet_RegistrationCode: bacnet.bacnet_RegistrationCode || null,
	bacnet_NetworkNumber: bacnet.bacnet_NetworkNumber || '1',
	bacnet_IPAddress: bacnet.bacnet_IPAddress || null,
	bacnet_PortNumber: bacnet.bacnet_PortNumber || '47808',
	bacnet_DeviceInstanceNumber: bacnet.bacnet_DeviceInstanceNumber || '125',
	bacnet_DeviceName: bacnet.bacnet_DeviceName || 'SmartFlow',
	bacnet_DeviceDescription: bacnet.bacnet_DeviceDescription || 'Smart Flow Monitoring System',
	bacnet_DeviceLocation: bacnet.bacnet_DeviceLocation || null,
	bacnet_UseRecipientDevice: bacnet.bacnet_UseRecipientDevice || false,
	bacnet_RecipientDeviceInstanceNumber: bacnet.bacnet_RecipientDeviceInstanceNumber || '250',
	bacnet_RecipientDeviceProcessNumber: bacnet.bacnet_RecipientDeviceProcessNumber || '1',
	bacnet_HubErrorPriority: bacnet.bacnet_HubErrorPriority || 50,
	bacnet_PortErrorPriority: bacnet.bacnet_PortErrorPriority || 100,
	bacnet_TmvLowLevelAlertPriority: bacnet.bacnet_TmvLowLevelAlertPriority || 100,
	bacnet_TmvHighLevelAlertPriority: bacnet.bacnet_TmvHighLevelAlertPriority || 50,
	bacnet_AlertDuration: bacnet.bacnet_AlertDuration || 60,
	bacnet_CovUpdateInterval: bacnet.bacnet_CovUpdateInterval || 300,
	bacnet_HighLevelAlertInstanceNumber: bacnet.bacnet_HighLevelAlertInstanceNumber || 0,
	bacnet_HighLevelAlertObjectName: bacnet.bacnet_HighLevelAlertObjectName || 'TMV High Temperature Alert',
	bacnet_MaximumForeignDevices: bacnet.bacnet_MaximumForeignDevices || 0,
	bacnet_BBMDIPAddress: bacnet.bacnet_BBMDIPAddress || null,
	bacnet_BBMDPortNumber: bacnet.bacnet_BBMDPortNumber || '47808',
	bacnet_HubErrorsTriggerGeneralAlarm: bacnet.bacnet_HubErrorsTriggerGeneralAlarm || true,
	bacnet_PortErrorsTriggerGeneralAlarm: bacnet.bacnet_PortErrorsTriggerGeneralAlarm || true,
	bacnet_HighLevelAlertsTriggerGeneralAlarm: bacnet.bacnet_HighLevelAlertsTriggerGeneralAlarm || true,
	site_Id: bacnet.site_Id || '',
	created: bacnet.created || defaultCreated({}),
	modified: bacnet.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

interface BacnetPoint {
	pointType: string
	pointDescription: string
	pointLocation: string
	bacnetDeviceId: string
	bacnetObjectType: string
	bacnetInstanceId: string
	bacnetObjectName: string
	bacnetDataDescription: string
	bacnetLowLevelAlarmEventEnrolmentInstanceId: string
	bacnetHighLevelAlarmEventEnrolmentInstanceId: string
}

interface BacnetPointResult extends Validate.Result {
	bacnetPoints: BacnetPoint[]
}

export { defaultBacnet }
export type { Bacnet, BacnetResult, BacnetPoint, BacnetPointResult }
