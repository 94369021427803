import React, { useContext, useMemo } from 'react'
import { AssetList, AssetResult } from '../../../models/Asset'
import { TemperatureException } from '../../../models/Report'
import { Event } from '../../../models/Event'
import { PageStatus } from '../../../types/PageStatus'
import { Listing } from '../Listing/Listing'
import { eventSettingType } from '../../../constants/eventSettingType'
import { SeverityValues } from '../../../constants/severity'
import { AssetTypeNameValues } from '../../../constants/assetType'
import { AppContext } from '../../../App'
import { Link } from 'react-router-dom'
import { Button } from '../Button/Button'
import { Loading } from '../Loading/Loading'
import { Col, Modal, Row } from 'react-bootstrap'
import TemperatureExceptionDetailModal from '../Reports/TemperauteExceptionDetailModal'
import * as Request from '../../../utilities/request'
import { DateTime } from 'luxon'
import { EventSettingResult, defaultEventSettingValuesByType } from '../../../models/EventSetting'
import { groupType } from '../../../constants/groupType'
import { formatIncomingDateTime } from '../../../utilities/formatDate'

interface EventListingProps {
	eventsAndAssets: (Event & AssetList)[] | null
}

export const EventListing = (props: EventListingProps) => {
	const context = React.useContext(AppContext)

	const [tempExceptLoadingStatus, setTempExceptLoadingStatus] = React.useState<PageStatus>('Ready')
	const [modalTempException, setModalTempException] = React.useState<TemperatureException>()
	const [showEventModal, setShowEventModal] = React.useState<(Event & AssetList) | null>(null)

	const severityValues = useMemo(() => {
		return SeverityValues.map((severity) => ({
			value: severity,
			text: severity,
		}))
	}, [props.eventsAndAssets])

	const assetTypeValues = useMemo(() => {
		if (props.eventsAndAssets) {
			return AssetTypeNameValues.filter((val) => props.eventsAndAssets?.some((e) => e.assetType_Name == val)).map((event) => ({
				value: event,
				text: event,
			}))
		}
		return []
	}, [props.eventsAndAssets])

	const loadTempExceptModalData = async (item: Event & AssetList) => {
		let tempExceptThreshold = defaultEventSettingValuesByType['Temperature Exception High']
		let setTemperature = defaultEventSettingValuesByType['Set Temperature']

		const assetReq = await Request.get<AssetResult>(`asset?Id=${item.asset_Id}`, context.appState.authState)
		const assetTempGroup = assetReq.data.assets[0]?.asset_Groups.find((group) => group.groupType_Id === groupType.Temperature.id)
		if (assetTempGroup) {
			const groupReq = await Request.get<EventSettingResult>(`eventSetting?Group_Id=${assetTempGroup.group_Id}`, context.appState.authState)

			const tempExceptHigh = groupReq.data.eventSettings.find((es) => es.eventSettingType_Id === eventSettingType['Temperature Exception High'].id)
			if (tempExceptHigh) tempExceptThreshold = tempExceptHigh.eventSetting_Value

			const setTemp = groupReq.data.eventSettings.find((es) => es.eventSettingType_Id === eventSettingType['Set Temperature'].id)
			if (setTemp) setTemperature = setTemp.eventSetting_Value
		}

		setModalTempException({
			asset_Id: item.asset_Id,
			asset_Name: item.asset_Name || '',
			asset_Location: `${item.site_Name}, ${item.locationBuilding_Name}, ${item.locationFloor_Name}, ${item.locationArea_Name}, ${item.locationRoom_Name}`,
			event_Id: '',
			temperatureExceptionThreshold: tempExceptThreshold,
			setTemperature: setTemperature,
			site_Timezone: item.site_Timezone,
			startTs: item.event_StartTs,
			finishTs: item.event_FinishTs,
			duration:
				item.event_StartTs && item.event_FinishTs
					? DateTime.fromISO(item.event_FinishTs).diff(DateTime.fromISO(item.event_StartTs), 'seconds').seconds
					: null,
			isThermalFlush: false,
		})
	}

	return (
		<>
			<Listing
				name="Event"
				namePlural="Events"
				list={props.eventsAndAssets || []}
				getIDFunc={(item) => item.event_Id}
				selectedActions={[]}
				headerButtons={[]}
				columns={[
					{
						value: (item) => item.assetType_Name,
						render: (item) => <>{item.assetType_Name}</>,
						showHeader: true,
						headerText: 'Asset Type',
						sortColumnName: 'assetType_Name',
						filterType: 'dropdown',
						filterOptions: {
							columnName: 'assetType_Name',
							options: assetTypeValues,
						},
					},
					{
						value: (item) => item.asset_Name || '',
						render: (item) => (
							<Link
								to={{
									pathname: `/asset/${item.asset_Id}`,
								}}
							>
								{item.asset_Name}
							</Link>
						),
						showHeader: true,
						headerText: 'Asset Name',
						sortColumnName: 'asset_Name',
						filterType: 'string',
						filterOptions: {
							columnName: 'asset_Name',
						},
					},
					{
						value: (item) => item.locationBuilding_Name,
						render: (item) => <>{item.locationBuilding_Name}</>,
						showHeader: true,
						headerText: 'Building',
						sortColumnName: 'locationBuilding_Name',
						filterType: 'string',
						filterOptions: {
							columnName: 'locationBuilding_Name',
						},
					},
					{
						value: (item) => item.locationFloor_Name,
						render: (item) => <>{item.locationFloor_Name}</>,
						showHeader: true,
						headerText: 'Floor',
						sortColumnName: 'locationFloor_Name',
						filterType: 'string',
						filterOptions: {
							columnName: 'locationFloor_Name',
						},
					},
					{
						value: (item) => item.locationArea_Name,
						render: (item) => <>{item.locationArea_Name}</>,
						showHeader: true,
						headerText: 'Area',
						sortColumnName: 'locationArea_Name',
						filterType: 'string',
						filterOptions: {
							columnName: 'locationArea_Name',
						},
					},
					{
						value: (item) => item.locationRoom_Name,
						render: (item) => <>{item.locationRoom_Name}</>,
						showHeader: true,
						headerText: 'Room',
						sortColumnName: 'locationRoom_Name',
						filterType: 'string',
						filterOptions: {
							columnName: 'locationRoom_Name',
						},
					},
					{
						value: (item) => item.severity_Name,
						render: (item) => <>{item.severity_Name}</>,
						showHeader: true,
						headerText: 'Severity',
						sortColumnName: 'severity_Name',
						filterType: 'dropdown',
						filterOptions: {
							columnName: 'severity_Name',
							options: severityValues,
						},
					},
					{
						value: (item) => item.create_Ts,
						render: (item) => <>{item.create_Ts}</>,
						showHeader: true,
						headerText: 'Date/Time',
						sortColumnName: 'create_Ts',
						filterType: 'string',
						filterOptions: {
							columnName: 'create_Ts',
						},
					},
					{
						value: (item) => item.event_Id,
						render: (item) => (
							<Button
								onClick={async () => {
									if (item.eventSettingType_Id === eventSettingType['Temperature Exception High'].id) {
										setTempExceptLoadingStatus('Loading')
										await loadTempExceptModalData(item)
										setTempExceptLoadingStatus('Ready')
									} else {
										setShowEventModal(item)
									}
								}}
								disabled={tempExceptLoadingStatus !== 'Ready'}
							>
								{tempExceptLoadingStatus === 'Ready' ? 'View' : <Loading show small />}
							</Button>
						),

						showHeader: true,
						headerText: 'View',
						centerColumn: true,
					},
				]}
				defaultSort={{ column: 'create_Ts', order: 'DSC' }}
				isLoading={!props.eventsAndAssets}
			/>
			<EventModal event={showEventModal} setShow={setShowEventModal} />
			<TemperatureExceptionDetailModal temperatureException={modalTempException} setTemperatureException={setModalTempException} showReadingDetail />
		</>
	)
}

interface EventModalProps {
	setShow: React.Dispatch<React.SetStateAction<(Event & AssetList) | null>>
	event: (Event & AssetList) | null
}

const EventModal = (props: EventModalProps) => {
	const context = useContext(AppContext)

	return (
		<Modal
			show={props.event != null}
			onHide={() => {
				props.setShow(null)
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{props.event?.eventSettingType_Name} Event for {props.event?.asset_Name || context.appState.currentSite?.site_Name || ''}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Row style={styles.row}>
					<Col style={styles.cell}>
						<span className="card-label">Severity</span>
						<span className="card-text">{props.event?.severity_Name}</span>
					</Col>
					{props.event?.asset_Id && (
						<Col style={styles.cell}>
							<span className="card-label">Asset</span>
							<span className="card-text">
								{
									<Link
										to={{
											pathname: `/asset/${props.event?.asset_Id}`,
										}}
									>
										{props.event?.asset_Name}
									</Link>
								}
							</span>
						</Col>
					)}
				</Row>
				<Row style={styles.row}>
					<Col style={styles.cell}>
						<span className="card-label">Start Time</span>
						<span className="card-text">
							{formatIncomingDateTime({
								dateTime: props.event?.event_StartTs || '',
								format: 'DateAndTimeAndTimezoneContext',
								timeZone: context.appState.currentSite?.site_Timezone,
							})}
						</span>
					</Col>
					<Col style={styles.cell}>
						<span className="card-label">Finish Time</span>
						<span className="card-text">
							{props.event?.event_FinishTs
								? formatIncomingDateTime({
										dateTime: props.event?.event_FinishTs || '',
										format: 'DateAndTimeAndTimezoneContext',
										timeZone: context.appState.currentSite?.site_Timezone,
								  })
								: 'Ongoing'}
						</span>
					</Col>
				</Row>
				<Row style={styles.row}>
					{props.event?.event_Data && (
						<Col style={styles.cell}>
							<span className="card-label">Data</span>
							<span className="card-text">{props.event?.event_Data}</span>
						</Col>
					)}
					<Col style={styles.cell}>
						<span className="card-label">Event Created Timestamp</span>
						<span className="card-text">{props.event?.create_Ts}</span>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	container: {
		padding: '40px',
	},
	row: {
		marginBottom: '20px',
	},
	spacedRow: {
		justifyContent: 'space-between',
	},
	cell: {
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center',
	},
	card: {
		marginTop: '20px',
	},
}
