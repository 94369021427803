export const TaskStatusValues = ['Complete', 'In Progress', 'To Do'] as const
export type TaskStatus = (typeof TaskStatusValues)[number]

export const TaskStatusIdValues = [
	'complete-0000-0000-0000-000000000000',
	'progress-0000-0000-0000-000000000000',
	'todo0000-0000-0000-0000-000000000000',
] as const
export type TaskStatusId = (typeof TaskStatusIdValues)[number]

export const taskStatus: Record<TaskStatus, { id: TaskStatusId }> = {
	Complete: { id: 'complete-0000-0000-0000-000000000000' },
	'In Progress': { id: 'progress-0000-0000-0000-000000000000' },
	'To Do': { id: 'todo0000-0000-0000-0000-000000000000' },
}

export const taskStatusDropdownValues = TaskStatusValues.map((value) => ({
	value: taskStatus[value].id,
	label: value,
}))
