import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { Site, defaultSite } from './Site'
import { ContractModule } from './ContractModule'
import { PaymentAgreement } from './PaymentAgreement'
import { defaultSiteGroup, SiteGroup } from './SiteGroup'

interface Contract {
	contract_Id: string
	contract_Number: string
	contract_VisualNumber: string | null
	contract_VisualShipTo: string | null
	contract_BuyerName: string | null
	contract_FirstName: string | null
	contract_LastName: string | null
	contract_Phone: string | null
	contract_Email: string | null
	contract_BuyerABN: string | null
	contract_CustomerAccount: string | null
	contract_CustomerCostCentre: string | null
	contract_StartDate: string | null
	contract_EndDate: string | null
	contract_AssetCount: number | null
	contract_NswHealthPrefix: string | null
	contract_Note: string | null
	site_Id: string | null
	site: Site | null
	siteGroup_Id: string | null
	siteGroup: SiteGroup | null

	contract_Modules: ContractModule[]
	contract_PaymentAgreements: PaymentAgreement[]

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface ContractResult extends Validate.Result {
	contracts: Contract[]
}

const defaultContract = (contract: Partial<Contract>): Contract => ({
	contract_Id: contract.contract_Id || '',
	contract_Number: contract.contract_Number || '',
	contract_VisualNumber: contract.contract_VisualNumber || null,
	contract_VisualShipTo: contract.contract_VisualShipTo || null,
	contract_BuyerName: contract.contract_BuyerName || null,
	contract_FirstName: contract.contract_FirstName || null,
	contract_LastName: contract.contract_LastName || null,
	contract_Phone: contract.contract_Phone || null,
	contract_Email: contract.contract_Email || null,
	contract_BuyerABN: contract.contract_BuyerABN || null,
	contract_CustomerAccount: contract.contract_CustomerAccount || null,
	contract_CustomerCostCentre: contract.contract_CustomerCostCentre || null,
	contract_StartDate: contract.contract_StartDate || null,
	contract_EndDate: contract.contract_EndDate || null,
	contract_AssetCount: contract.contract_AssetCount || null,
	contract_NswHealthPrefix: contract.contract_NswHealthPrefix || null,
	contract_Note: contract.contract_Note || null,
	site_Id: contract.site_Id || null,
	site: contract.site || defaultSite({}),
	siteGroup_Id: contract.siteGroup_Id || null,
	siteGroup: contract.siteGroup || defaultSiteGroup({}),

	contract_Modules: contract.contract_Modules || [],
	contract_PaymentAgreements: contract.contract_PaymentAgreements || [],

	created: contract.created || defaultCreated({}),
	modified: contract.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultContract }
export type { Contract, ContractResult }
