import React, { useContext } from 'react'
import { AppContext } from '../../../App'

import { Formik, FormikHelpers } from 'formik'
import { Modal } from 'react-bootstrap'
import * as yup from 'yup'

import * as Request from '../../../utilities/request'
import { Button } from '../Button/Button'
import { SiteGroup } from '../../../models/SiteGroup'
import { Site, SiteResult } from '../../../models/Site'
import { FormSelect, SelectOption } from '../Form/Select'

const siteValidationSchema = yup.object().shape({
	site_Id: yup.string().required('Site is required'),
})

interface MoveFromExternalModalProps {
	destinationSiteGroup: SiteGroup | null
	setDestinationSiteGroup: React.Dispatch<React.SetStateAction<SiteGroup | null>>
	sites: Site[]
	setResetData: React.Dispatch<React.SetStateAction<boolean>>
	handleSave: () => Promise<void>
}

interface FormSite {
	site_Id: string | null
}
const defaultFormSite: FormSite = {
	site_Id: null,
}

const MoveFromExternalModal = (props: MoveFromExternalModalProps) => {
	const context = useContext(AppContext)

	const [formSite, setFormSite] = React.useState<FormSite>(defaultFormSite)

	React.useEffect(() => {
		setFormSite(defaultFormSite)
	}, [props.destinationSiteGroup])

	const handleMoveExternalSubmit = async (values: FormSite, formikHelpers: FormikHelpers<FormSite>) => {
		await props.handleSave()
		const site = props.sites.find((s) => s.site_Id === values.site_Id)

		console.log(site, props.destinationSiteGroup)
		if (!site || !props.destinationSiteGroup) return

		return Request.handleRequest(
			() => Request.put<SiteResult>(`site`, { ...site, siteGroup_Id: props.destinationSiteGroup?.siteGroup_Id } as Site, context.appState.authState),
			{
				successFunction: (data) => {
					if (data.sites.length > 0) {
						formikHelpers.resetForm()
						props.setResetData((val) => !val)
						props.setDestinationSiteGroup(null)
					}
				},
				messageAction: 'editing',
				messageObject: 'site',
			}
		)
	}

	return (
		<Formik initialValues={formSite} validationSchema={siteValidationSchema} onSubmit={handleMoveExternalSubmit} enableReinitialize>
			{({ handleSubmit, isSubmitting, errors, values, handleReset, setFieldValue }) => (
				<Modal
					show={!!props.destinationSiteGroup}
					onHide={() => {
						if (!isSubmitting) {
							handleReset()
							props.setDestinationSiteGroup(null)
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>Move Site to {props.destinationSiteGroup?.siteGroup_Name || 'Site Group'}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<FormSelect
							name="site_Id"
							required
							options={
								props.sites?.map((site) => {
									return {
										value: site.site_Id,
										label: site.site_Name,
									}
								}) || []
							}
							value={values.site_Id || ''}
							onChange={(option) => {
								setFieldValue('site_Id', (option as SelectOption<string>).value)
							}}
							label="Site to Move"
							feedback={errors.site_Id}
						/>
						<span>Moving this Site will save any other changes made on the Edit Site Group page</span>
					</Modal.Body>

					<Modal.Footer>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleReset()
								setFormSite(defaultFormSite)
								props.setDestinationSiteGroup(null)
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleSubmit()

								setFormSite(defaultFormSite)
							}}
						>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
	)
}

export { MoveFromExternalModal }
