import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { FormRangeProps as BaseFormRangeProps } from 'react-bootstrap/esm/FormRange'

interface FormRangeProps extends BaseFormRangeProps {
	name: string
	label?: string
	required?: boolean
	note?: string
	feedback?: string
	min: number
	max: number
	unit?: string
	setFieldValue: (field: string, value: number, shouldValidate?: boolean | undefined) => void
}

const FormRange = (props: FormRangeProps) => {
	const { setFieldValue, ...htmlProps } = props

	const rangeRef = React.useRef<HTMLInputElement>(null)

	return (
		<Form.Group controlId={props.name}>
			<Row>
				<Col>
					{props.label ? (
						<Form.Label>
							{props.label}
							{props.required ? ' *' : ''}
							{props.note ? <span className="text-muted"> {props.note}</span> : null}
						</Form.Label>
					) : null}
				</Col>
			</Row>
			<Row>
				<Col className="d-flex align-items-center justify-content-center">
					{/* apparently readOnly doesn't really work on ranges */}
					<Form.Range {...htmlProps} onChange={(e) => !props.readOnly && props.onChange && props.onChange(e)} ref={rangeRef} />
				</Col>

				{!props.readOnly && (
					<Col sm={2} className="ps-0">
						<Form.Control
							disabled={props.disabled}
							readOnly={props.readOnly}
							className="p-1"
							type="number"
							min={props.min}
							max={props.max}
							value={props.value as number}
							onChange={(e) => {
								if (Number(e.currentTarget.value) < props.min) setFieldValue(props.name, props.min)
								else if (Number(e.currentTarget.value) > props.max) setFieldValue(props.name, props.max)
								else if (props.onChange) setFieldValue(props.name, Number(e.currentTarget.value))
							}}
						/>
					</Col>
				)}

				{props.readOnly && props.unit && (
					<Col sm={2}>
						{props.value} {props.unit}
					</Col>
				)}
				{!props.readOnly && props.unit && <Col sm={2}>{props.unit}</Col>}
			</Row>
			<Row>
				<Col>{props.feedback ? <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback> : null}</Col>
			</Row>
		</Form.Group>
	)
}

export { FormRange }
export type { FormRangeProps }
