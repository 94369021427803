import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { eventSettingType, EventSettingType } from '../constants/eventSettingType'
import { groupType } from '../constants/groupType'

interface EventSetting {
	eventSetting_Id: string
	eventSetting_Value: number
	eventSetting_IsEnabled: boolean
	eventSettingType_Id: string
	eventSettingType_Name: string
	groupType_Id: string
	group_Id: string
	group_Name: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface EventSettingResult extends Validate.Result {
	eventSettings: EventSetting[]
}

const defaultEventSetting = (eventSetting: Partial<EventSetting>): EventSetting => ({
	eventSetting_Id: eventSetting.eventSetting_Id || '',
	eventSetting_Value: eventSetting.eventSetting_Value || 0,
	eventSetting_IsEnabled: eventSetting.eventSetting_IsEnabled != null ? eventSetting.eventSetting_IsEnabled : true,
	eventSettingType_Id: eventSetting.eventSettingType_Id || '',
	eventSettingType_Name: eventSetting.eventSettingType_Name || '',
	groupType_Id: eventSetting.groupType_Id || '',
	group_Id: eventSetting.group_Id || '',
	group_Name: eventSetting.group_Name || '',

	created: eventSetting.created || defaultCreated({}),
	modified: eventSetting.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

type eventSettingTypeWithExclusions = Exclude<EventSettingType, 'Battery Replaced' | 'Offline' | 'Error' | 'Thermal Flush'>

const defaultEventSettingValuesByType: Record<eventSettingTypeWithExclusions, number> = {
	'Temperature Exception High': 48,
	'Set Temperature': 43.5,
	'Mixed Filter Temperature': 25,
	'Monthly Reading Duration': 60,
	'Minimum Monthly Reading Temperature': 41,
	'Maximum Monthly Reading Temperature': 46,
	'Flow Threshold Temperature': 38,
	'Flow Threshold Minutes': 3,
	'TMV Flow Metric Days': 7,
	'Fixture Flow Metric Days': 7,
	'Low Battery': 2500,
} as const

const defaultEventSettingUnitsByType: Record<string, string> = {
	'Temperature Exception High': '°C',
	'Set Temperature': '°C',
	'Mixed Filter Temperature': '°C',
	'Monthly Reading Duration': 'seconds',
	'Minimum Monthly Reading Temperature': '°C',
	'Maximum Monthly Reading Temperature': '°C',
	'Flow Threshold Temperature': '°C',
	'Flow Threshold Minutes': 'minutes',
	'TMV Flow Metric Days': 'days',
	'Fixture Flow Metric Days': 'days',
	'Low Battery': 'mV',
} as const

const defaultEventSettingTooltipType: Record<string, string> = {
	'Temperature Exception High': 'The threshold temperature used for Temperature Exceptions Events',
	'Set Temperature': 'The temperature the TMV is set to',
	'Mixed Filter Temperature': 'The threshold temperature used to filter out low mixed temperature readings',
	'Monthly Reading Duration': 'The number of seconds needed between readings to generate a potential monthly reading',
	'Minimum Monthly Reading Temperature': 'The maximum temperature for a reading to be considered as a potential monthly reading',
	'Maximum Monthly Reading Temperature': 'The minimum temperature for a reading to be considered as a potential monthly reading',
	'Flow Threshold Temperature': 'The threshold mixed temperature used to generate Flow Report Data for assets without flow sensors',
	'Flow Threshold Minutes': 'The number of minutes of cumulative use used to determine in an asset is shown in the flow graph',
	'Low Battery': 'The threshold of mV used to create aLow Battery exception event',
	'TMV Flow Metric Days': 'The maximum number of consecutive days under the minimum daily usage threshold before the TMV is added to the flow widgets',
	'Fixture Flow Metric Days':
		'The maximum number of consecutive days under the minimum daily usage threshold before the Fixture is added to the flow widgets',
} as const

const defaultTemperatureGroupEventSettings = [
	defaultEventSetting({
		eventSetting_Id: 'Default Temperature Exception High',
		eventSettingType_Id: eventSettingType['Temperature Exception High'].id,
		eventSettingType_Name: 'Temperature Exception High',
		eventSetting_Value: defaultEventSettingValuesByType['Temperature Exception High'],
		groupType_Id: groupType.Temperature.id,
	}),
	defaultEventSetting({
		eventSetting_Id: 'Default Set Temperature',
		eventSettingType_Id: eventSettingType['Set Temperature'].id,
		eventSettingType_Name: 'Set Temperature',
		eventSetting_Value: defaultEventSettingValuesByType['Set Temperature'],
		groupType_Id: groupType.Temperature.id,
	}),
	defaultEventSetting({
		eventSetting_Id: 'Default Mixed Filter Temperature',
		eventSettingType_Id: eventSettingType['Mixed Filter Temperature'].id,
		eventSettingType_Name: 'Mixed Filter Temperature',
		eventSetting_Value: defaultEventSettingValuesByType['Mixed Filter Temperature'],
		groupType_Id: groupType.Temperature.id,
	}),
	defaultEventSetting({
		eventSetting_Id: 'Default Monthly Reading Duration',
		eventSettingType_Id: eventSettingType['Monthly Reading Duration'].id,
		eventSettingType_Name: 'Monthly Reading Duration',
		eventSetting_Value: defaultEventSettingValuesByType['Monthly Reading Duration'],
		groupType_Id: groupType.Temperature.id,
	}),
	defaultEventSetting({
		eventSetting_Id: 'Default Minimum Monthly Reading Temperature',
		eventSettingType_Id: eventSettingType['Minimum Monthly Reading Temperature'].id,
		eventSettingType_Name: 'Minimum Monthly Reading Temperature',
		eventSetting_Value: defaultEventSettingValuesByType['Minimum Monthly Reading Temperature'],
		groupType_Id: groupType.Temperature.id,
	}),
	defaultEventSetting({
		eventSetting_Id: 'Default Maximum Monthly Reading Temperature',
		eventSettingType_Id: eventSettingType['Maximum Monthly Reading Temperature'].id,
		eventSettingType_Name: 'Maximum Monthly Reading Temperature',
		eventSetting_Value: defaultEventSettingValuesByType['Maximum Monthly Reading Temperature'],
		groupType_Id: groupType.Temperature.id,
	}),
	defaultEventSetting({
		eventSetting_Id: 'Default Flow Threshold Temperature',
		eventSettingType_Id: eventSettingType['Flow Threshold Temperature'].id,
		eventSettingType_Name: 'Flow Threshold Temperature',
		eventSetting_Value: defaultEventSettingValuesByType['Flow Threshold Temperature'],
		groupType_Id: groupType.Temperature.id,
	}),
	defaultEventSetting({
		eventSetting_Id: 'Default Flow Threshold Minutes',
		eventSettingType_Id: eventSettingType['Flow Threshold Minutes'].id,
		eventSettingType_Name: 'Flow Threshold Minutes',
		eventSetting_Value: defaultEventSettingValuesByType['Flow Threshold Minutes'],
		groupType_Id: groupType.Temperature.id,
	}),
]

const defaultBatteryGroupEventSettings = [
	defaultEventSetting({
		eventSetting_Id: 'Default Battery Voltage Exception High',
		eventSettingType_Id: eventSettingType['Low Battery'].id,
		eventSettingType_Name: 'Low Battery',
		eventSetting_Value: defaultEventSettingValuesByType['Low Battery'],
		groupType_Id: groupType.Battery.id,
	}),
]

export {
	defaultEventSetting,
	defaultTemperatureGroupEventSettings,
	defaultBatteryGroupEventSettings,
	defaultEventSettingValuesByType,
	defaultEventSettingUnitsByType,
	defaultEventSettingTooltipType,
}
export type { EventSetting, EventSettingResult }
