import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'

interface ContractModule {
	contractModule_Id: string
	module_Id: string
	module_Name: string
	module_ProductCode: string
	contract_Id: string
	contractModule_AssetRate: number

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface ContractModuleResult extends Validate.Result {
	contractModules: ContractModule[]
}

const defaultContractModule = (contractModule: Partial<ContractModule>): ContractModule => ({
	contractModule_Id: contractModule.contractModule_Id || '',
	module_Id: contractModule.module_Id || '',
	module_Name: contractModule.module_Name || '',
	module_ProductCode: contractModule.module_ProductCode || '',
	contract_Id: contractModule.contract_Id || '',
	contractModule_AssetRate: contractModule.contractModule_AssetRate || 0,

	created: contractModule.created || defaultCreated({}),
	modified: contractModule.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultContractModule }
export type { ContractModule, ContractModuleResult }
