import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { useSearchParams } from 'react-router-dom'
import { PageStatus } from '../../../types/PageStatus'
import { ConsecutiveFlow, ConsecutiveFlowResult } from '../../../models/Report'
import * as Request from '../../../utilities/request'
import { ReportApiType } from '../../../constants/report'
import logo from '../../../images/LogoBlack.svg'
import { Col, Row } from 'react-bootstrap'
import { ReportStyles as styles } from './ReportStyles'
import './ReportRender.css'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import { Site, SiteResult } from '../../../models/Site'
import { SiteGroup, SiteGroupResult } from '../../../models/SiteGroup'
import { DateTime } from 'luxon'

const ConsecutiveFlowRender = () => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [consecutiveFlows, setConsecutiveFlow] = React.useState<ConsecutiveFlow[]>([])
	const [site, setSite] = React.useState<Site | null>(null)
	const [siteGroup, setSiteGroup] = React.useState<SiteGroup | null>(null)

	const [searchParams] = useSearchParams()
	const siteId = searchParams.get('Site_Id')
	const siteGroupId = searchParams.get('SiteGroup_Id')
	const belowThresholdDays = searchParams.get('BelowThresholdDays')
	const fixtureOnly = searchParams.get('FixtureOnly')

	React.useEffect(() => {
		const getData = async () => {
			const [consecutiveFlowsReq, siteReq, siteGroupReq] = await Promise.all([
				Request.get<ConsecutiveFlowResult>(
					`report/${ReportApiType.CONSECUTIVE_FLOW.value}?${
						siteId !== null ? `Site_Id=${siteId}` : `SiteGroup_Id=${siteGroupId}`
					}&BelowThresholdDays=${belowThresholdDays}&${fixtureOnly ? 'FixtureOnly=true' : 'TmvOnly=true'}`,
					context.appState.authState
				),
				siteId !== null ? Request.get<SiteResult>(`site?Id=${siteId}`, context.appState.authState) : Promise.resolve({ data: { sites: [] } }),
				siteGroupId !== null
					? Request.get<SiteGroupResult>(`siteGroup?Id=${siteGroupId}`, context.appState.authState)
					: Promise.resolve({ data: { siteGroups: [] } }),
			])
			setConsecutiveFlow(consecutiveFlowsReq.data.consecutiveFlows)
			setSite(siteReq.data.sites[0] || null)
			setSiteGroup(siteGroupReq.data.siteGroups[0] || null)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			if ((siteId === null) === (siteGroupId === null) || belowThresholdDays === null) {
				setPageStatus('Error')
			} else {
				getData()
			}
		}
	}, [context])

	return (
		<div style={styles.w100}>
			{pageStatus === 'Ready' && consecutiveFlows && (
				<table style={styles.w100}>
					<thead>
						<tr style={styles.theadTitleRow}>
							<td colSpan={5}>
								<Row>
									<Col sm="auto">
										<img src={logo} alt="logo" style={styles.headerImg} />
									</Col>
									<Col style={styles.headerName}>{site?.site_Name || siteGroup?.siteGroup_Name || ''}</Col>
									<Col style={styles.headerTs}>
										{formatIncomingDateTime({ dateTime: DateTime.utc().toJSON() || '', format: 'DateAndTime' })}
									</Col>
									<Col sm="auto">
										<div style={styles.headerTitle}>
											<span>Consecutive Flow Report for</span>
											<span>{belowThresholdDays} or more Days Below Threshold</span>
										</div>
									</Col>
								</Row>
							</td>
						</tr>
						<tr style={{ ...styles.theadColNameRow, ...styles.lightText }}>
							<th style={styles.cell}>Asset ID</th>
							<th style={styles.cell}>Asset Number</th>
							<th style={styles.cell}>TMV Location</th>
							<th style={styles.cell}>Days Below Threshold</th>
							<th style={styles.cellLast}>Date Last Run</th>
						</tr>
					</thead>
					<tbody>
						{consecutiveFlows
							.sort((a, b) => (a.asset_Name < b.asset_Name ? -1 : 1))
							.map((cf) => (
								<tr key={cf.asset_Id} style={styles.bottomBorder}>
									<td style={styles.cell}>{cf.asset_Name}</td>
									<td style={styles.cell}>{cf.asset_SerialNo}</td>
									<td style={styles.cell}>{cf.asset_Location}</td>
									<td style={styles.cell}>{cf.daysBelowThreshold}</td>
									<td style={styles.cellLast}>
										{cf.dateLastRun
											? formatIncomingDateTime({
													dateTime: cf.dateLastRun,
													format: 'Date',
											  })
											: 'No Reading'}
									</td>
								</tr>
							))}
						<tr>
							<td colSpan={6} style={{ ...styles.centeredCell, ...styles.largeFont }}>
								<span>
									{consecutiveFlows.length} TMV{consecutiveFlows.length > 1 ? 's' : ''} in Total
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			)}
			{pageStatus === 'Loading' && <div>Loading...</div>}
			{pageStatus === 'Error' && <div>Error</div>}
		</div>
	)
}

export default ConsecutiveFlowRender
