interface Created {
	create_UserId: string
	create_UserEmail: string
	create_UserFirstName: string
	create_UserLastName: string
	create_Ts: string
}

interface Modified {
	modified_UserId: string
	modified_UserEmail: string
	modified_UserFirstName: string
	modified_UserLastName: string
	modified_Ts: string
}

const defaultCreated = (created: Partial<Created>): Created => ({
	create_UserId: created.create_UserId || '',
	create_UserEmail: created.create_UserEmail || '',
	create_UserFirstName: created.create_UserFirstName || '',
	create_UserLastName: created.create_UserLastName || '',
	create_Ts: created.create_Ts || '',
})

const defaultModified = (modified: Partial<Modified>): Modified => ({
	modified_UserId: modified.modified_UserId || '',
	modified_UserEmail: modified.modified_UserEmail || '',
	modified_UserFirstName: modified.modified_UserFirstName || '',
	modified_UserLastName: modified.modified_UserLastName || '',
	modified_Ts: modified.modified_Ts || '',
})

export type { Created, Modified }
export { defaultCreated, defaultModified }
