import React, { useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Card } from '../../components/UI/Card/Card'
import { AppContext } from '../../App'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import * as Request from '../../utilities/request'
import { formatIncomingDateTime } from '../../utilities/formatDate'
import { PageStatus } from '../../types/PageStatus'
import { Loading } from '../../components/UI/Loading/Loading'
import { User, UserResult } from '../../models/User'
import { Listing, ListingColumn } from '../../components/UI/Listing/Listing'
import addIcon from '../../../src/images/icons/add-white.svg'
import ellipsis from '../../images/icons/options.svg'
import { AddEditUserModal } from '../../components/UI/ManageUsersContextual/AddEditUserModal'
import { UserSite, defaultUserSite } from '../../models/UserSite'
import { ConfirmModal } from '../../components/UI/ConfirmModal/ConfirmModal'
import { ResetPasswordModal } from '../../components/UI/ManageUsersContextual/ResetPasswordModal'
import { hasPermission } from '../../utilities/permissions/permission'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../utilities/permissions/permission.d'
import PermissionsCheck from '../../components/Permissions/PermissionsCheck'

interface UserListProps {
	title: string
	type: 'Site' | 'System'

	users: User[]
	setUsers: React.Dispatch<React.SetStateAction<User[] | null>>
	userOptions?: User[]
	userSites?: UserSite[]
	setUserSites?: React.Dispatch<React.SetStateAction<UserSite[] | null>>
}

const UserList = (props: UserListProps) => {
	const context = useContext(AppContext)
	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Ready')

	const [editUser, setEditUser] = React.useState<UserSite | undefined>(undefined)
	const [delUser, setDelUser] = React.useState<User | undefined>(undefined)

	const [showAddEditUserModal, setShowAddEditUserModal] = React.useState<boolean>(false)
	const [mode, setMode] = React.useState<'Invite' | 'AddExisting' | 'Edit'>('Invite')

	const [showResetPasswordModal, setShowResetPasswordModal] = React.useState<boolean>(false)
	const [showDeleteUserModal, setShowDeleteUserModal] = React.useState<boolean>(false)

	const handleSelectedDelete = async (selected: string[]) => {
		setPageStatus('Submitting')

		if (selected.some((user_Id) => user_Id === context.appState.userAttributes.user_Id)) {
			alert('You cannot delete your own account.')
		} else {
			await Promise.all(selected.map((user_Id) => Request.del<UserResult>(`user?Id=${user_Id}`, context.appState.authState)))
			props.setUsers(props.users?.filter((user) => !selected.includes(user.user_Id)) || [])
		}

		setPageStatus('Ready')
	}

	const displayUserActions =
		hasPermission(PermissionModelObject.User, PermissionModelAction.PUT, context.appState, PermissionModelContext.Site) ||
		hasPermission(PermissionModelObject.User, PermissionModelAction.DELETE, context.appState, PermissionModelContext.Site) ||
		process.env.REACT_APP_IsLocalMode === 'true'

	return (
		<>
			<Messages messages={messages} updateMessage={setMessages} />
			<Card title={props.title} collapsible={false}>
				{pageStatus !== 'Loading' ? (
					<>
						<Listing<User>
							name="User"
							namePlural="Users"
							list={props.users || []}
							getIDFunc={(item) => item.user_Id}
							selectedActions={
								hasPermission(PermissionModelObject.User, PermissionModelAction.DELETE, context.appState, PermissionModelContext.Site)
									? [{ name: 'Delete selected', method: handleSelectedDelete }]
									: []
							}
							headerButtons={
								hasPermission(PermissionModelObject.User, PermissionModelAction.POST, context.appState, PermissionModelContext.Site)
									? [
											{
												content: (
													<>
														<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
														<span className="span-white span-bold">Invite new user</span>
													</>
												),
												onClick: () => {
													setMode('Invite')

													setEditUser(undefined)
													setShowAddEditUserModal(true)
												},
											},
											props.userOptions
												? {
														content: (
															<>
																<img
																	src={addIcon}
																	style={{ marginRight: '15px', marginBottom: '3px' }}
																	alt={'A Plus Icon'}
																></img>
																<span className="span-white span-bold">Add existing user</span>
															</>
														),
														onClick: () => {
															setMode('AddExisting')

															setEditUser(undefined)
															setShowAddEditUserModal(true)
														},
												  }
												: {},
									  ]
									: []
							}
							columns={(
								[
									{
										value: (item) => `${item.user_FirstName} ${item.user_LastName}`,
										render: (item) => `${item.user_FirstName} ${item.user_LastName}`,
										showHeader: true,
										headerText: 'Name',
										sortColumnName: 'user_FirstName',
										filterType: 'string',
										filterOptions: {
											columnName: 'user_FirstName',
										},
									},
									{
										value: (item) => item.user_Email,
										render: (item) => <>{item.user_Email}</>,
										showHeader: true,
										headerText: 'Email Address',
										sortColumnName: 'user_Email',
										filterType: 'string',
										filterOptions: {
											columnName: 'user_Email',
										},
									},
									{
										value: (item) => item.userSites_Description,
										render: (item) => <>{item.userSites_Description}</>,
										showHeader: true,
										headerText: 'Roles',
										sortColumnName: 'roles',
										filterType: 'string',
										filterOptions: {
											columnName: 'roles',
										},
									},
									{
										value: (item) => item.created.create_Ts,
										render: (item) => (
											<>{formatIncomingDateTime({ dateTime: item.created.create_Ts, format: 'DateAndTimeAndTimezoneNoContext' })}</>
										),
										showHeader: true,
										headerText: 'Created',
										sortColumnName: 'created.create_Ts',
										filterType: 'string',
										filterOptions: {
											columnName: 'created.create_Ts',
										},
									},
								] as ListingColumn<User>[]
							).concat(
								displayUserActions
									? [
											{
												value: (item) => item.user_Id,
												render: (item) => (
													<>
														<Dropdown>
															<Dropdown.Toggle
																as="img"
																src={ellipsis}
																alt="Asset options"
																className="site-card-dropdown-toggle"
															/>
															<Dropdown.Menu>
																<PermissionsCheck
																	object={PermissionModelObject.User}
																	action={PermissionModelAction.PUT}
																	context={PermissionModelContext.Site}
																>
																	<Dropdown.Item
																		onClick={() => {
																			setMode('Edit')

																			if (props.type === 'Site') {
																				setEditUser(
																					props.userSites?.find(
																						(userSite) => userSite.userSite_User.user_Id === item.user_Id
																					)
																				)
																			} else {
																				setEditUser(
																					defaultUserSite({
																						userRole_Id: item.userRole_Id ? item.userRole_Id : '',
																						userSite_User: item,
																					})
																				)
																			}

																			setShowAddEditUserModal(true)
																		}}
																	>
																		Edit
																	</Dropdown.Item>
																</PermissionsCheck>

																{process.env.REACT_APP_IsLocalMode === 'true' && (
																	<Dropdown.Item
																		onClick={() => {
																			setDelUser(item)
																			setShowResetPasswordModal(true)
																		}}
																	>
																		Reset Password
																	</Dropdown.Item>
																)}

																<PermissionsCheck
																	object={PermissionModelObject.User}
																	action={PermissionModelAction.DELETE}
																	context={PermissionModelContext.Site}
																>
																	<Dropdown.Item
																		onClick={() => {
																			setDelUser(item)
																			setShowDeleteUserModal(true)
																		}}
																	>
																		Delete
																	</Dropdown.Item>
																</PermissionsCheck>
															</Dropdown.Menu>
														</Dropdown>
													</>
												),
												showHeader: true,
												headerText: 'Action',
												centerColumn: true,
											},
									  ]
									: []
							)}
							defaultSort={{ column: 'created.create_Ts', order: 'DSC' }}
							isLoading={pageStatus !== 'Ready'}
						/>
					</>
				) : (
					<Loading show={true} />
				)}
			</Card>

			{showAddEditUserModal && (
				<AddEditUserModal
					type={props.type}
					mode={mode}
					siteOrGroupId={props.type === 'Site' ? context.appState.currentSite?.site_Id : null}
					show={showAddEditUserModal}
					setShow={setShowAddEditUserModal}
					existingUserSites={props.userSites ? props.userSites : undefined}
					setExistingUserSites={props.setUserSites ? props.setUserSites : undefined}
					editUserSite={editUser}
					users={props.userOptions ? props.userOptions : props.users}
					setUsers={props.setUsers}
				/>
			)}

			{showResetPasswordModal && delUser && (
				<ResetPasswordModal showResetPasswordModal={showResetPasswordModal} setShowResetPasswordModal={setShowResetPasswordModal} user={delUser} />
			)}

			<ConfirmModal
				show={showDeleteUserModal}
				setShow={setShowDeleteUserModal}
				title="Delete User"
				body={`Are you sure you want to delete "${delUser?.user_FirstName} ${delUser?.user_LastName}"?`}
				confirmButtonText="Delete"
				onConfirm={() => {
					if (delUser) {
						handleSelectedDelete([delUser.user_Id])
					}
				}}
			/>
		</>
	)
}

export { UserList }
