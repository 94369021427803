import { recordStatus } from '../constants/recordStatus'
import { Created, Modified, defaultCreated, defaultModified } from './History'

interface ReportGenerationData {
	reportGenerationData_Id: string
	reportGenerationData_Data: string
	reportGenerationData_Note: string | null
	asset_Id: string | null
	site_Id: string | null
	siteGroup_Id: string | null
	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface ReportGenerationDataResult {
	reportGenerationData: ReportGenerationData[]
}

const defaultReportGenerationData = (reportGenerationData: Partial<ReportGenerationData>): ReportGenerationData => ({
	reportGenerationData_Id: reportGenerationData.reportGenerationData_Id || '',
	reportGenerationData_Data: reportGenerationData.reportGenerationData_Data || '',
	reportGenerationData_Note: reportGenerationData.reportGenerationData_Note || null,

	asset_Id: reportGenerationData.asset_Id || null,
	site_Id: reportGenerationData.site_Id || null,
	siteGroup_Id: reportGenerationData.siteGroup_Id || null,

	created: reportGenerationData.created || defaultCreated({}),
	modified: reportGenerationData.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultReportGenerationData }
export type { ReportGenerationData, ReportGenerationDataResult }
