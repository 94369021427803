import React, { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'

import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { formatIncomingDateTime } from '../../../utilities/formatDate'

import { PageStatus } from '../../../types/PageStatus'
import { Reading, ReadingResult } from '../../../models/Reading'
import { Asset, AssetResult } from '../../../models/Asset'
import { EventSettingResult, defaultEventSettingValuesByType } from '../../../models/EventSetting'
import { eventSettingType } from '../../../constants/eventSettingType'
import { groupType } from '../../../constants/groupType'

import { ReportStyles as styles } from './ReportStyles'

import logo from '../../../images/LogoBlack.svg'
import TemperatureExceptionEventDetailReport from '../../../components/UI/Reports/TemperatureExceptionEventDetailReport'
import { DateTime } from 'luxon'

const TemperatureExceptionDetailRender = () => {
	const { appState } = useContext(AppContext)
	const [searchParams] = useSearchParams()

	const assetId = searchParams.get('Asset_Id')
	const startTs = searchParams.get('ExceptionStart_Ts')
	const finishTs = searchParams.get('ExceptionFinish_Ts')
	const view = searchParams.get('View')

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [asset, setAsset] = React.useState<Asset | null>(null)
	const [readings, setReadings] = React.useState<Reading[]>([])

	const [temperatureExceptionHighSetting, setTemperatureExceptionHighSetting] = React.useState<number>(
		defaultEventSettingValuesByType['Temperature Exception High']
	)
	const [setTemperatureSetting, setSetTemperatureSetting] = React.useState<number>(defaultEventSettingValuesByType['Set Temperature'])

	React.useEffect(() => {
		const getData = async () => {
			try {
				const [assetReq, readingsReq] = await Promise.all([
					Request.get<AssetResult>(`asset?Id=${assetId}`, appState.authState),
					Request.get<ReadingResult>(
						`reading/temperatureException?Asset_Id=${assetId}&ExceptionStartTs=${startTs}&ExceptionFinishTs=${finishTs}`,
						appState.authState
					),
				])
				const assetTempGroup = assetReq.data.assets[0]?.asset_Groups.find((group) => group.groupType_Id === groupType.Temperature.id)
				if (assetTempGroup) {
					const [groupReq] = await Promise.all([
						Request.get<EventSettingResult>(`eventSetting?Group_Id=${assetTempGroup.group_Id}`, appState.authState),
					])

					const temperatureExceptionHigh = groupReq.data.eventSettings.find(
						(es) => es.eventSettingType_Id === eventSettingType['Temperature Exception High'].id
					)
					setTemperatureExceptionHighSetting(temperatureExceptionHigh ? temperatureExceptionHigh.eventSetting_Value : temperatureExceptionHighSetting)

					const setTemperature = groupReq.data.eventSettings.find((es) => es.eventSettingType_Id === eventSettingType['Set Temperature'].id)
					setSetTemperatureSetting(setTemperature ? setTemperature.eventSetting_Value : setTemperatureSetting)
				}
				setAsset(assetReq.data.assets[0])
				setReadings(readingsReq.data.readings)
				setPageStatus('Ready')
			} catch {
				setPageStatus('Error')
			}
		}

		setPageStatus('Loading')
		if (appState.authState.isLoggedIn) {
			getData()
		}
	}, [appState])

	return (
		<div style={styles.w100}>
			{pageStatus === 'Ready' && asset && (
				<table style={styles.w100}>
					<thead>
						<tr style={styles.theadTitleRow}>
							<td colSpan={6}>
								<Row>
									<Col sm="auto">
										<img src={logo} alt="logo" style={styles.headerImg} />
									</Col>
									<Col style={styles.headerName}>{asset?.site_Name || ''}</Col>
									<Col sm="auto">
										<div style={styles.headerTitle}>
											<span>Temperature Exception Details Report:</span>
											<span style={styles.lightText}>{asset?.asset_Name}</span>
										</div>
									</Col>
								</Row>
							</td>
						</tr>
						{view === 'table' && (
							<tr style={{ ...styles.theadColNameRow, ...styles.lightText }}>
								<th>Timestamp</th>
								<th>Temperature</th>
							</tr>
						)}
					</thead>
					<tbody>
						{view === 'graph' ? (
							<tr>
								<td style={{ ...styles.cellLast, ...styles.bottomBorder }}>
									<TemperatureExceptionEventDetailReport
										temperatureException={{
											asset_Id: asset.asset_Id,
											asset_Name: asset.asset_Name || '',
											asset_Location: `${asset.site_Name}, ${asset.locationBuilding_Name}, ${asset.locationFloor_Name}, ${asset.locationArea_Name}, ${asset.locationRoom_Name}`,
											event_Id: '',
											temperatureExceptionThreshold: temperatureExceptionHighSetting,
											setTemperature: setTemperatureSetting,
											site_Timezone: asset.site_Timezone,
											startTs: startTs,
											finishTs: finishTs,
											duration:
												startTs && finishTs ? DateTime.fromISO(finishTs).diff(DateTime.fromISO(startTs), 'seconds').seconds : null,
											isThermalFlush: false,
										}}
										width="700px"
										height="300px"
									/>
								</td>
							</tr>
						) : (
							<>
								{readings.map((reading) => (
									<tr key={reading.reading_Id}>
										<td>
											{formatIncomingDateTime({
												dateTime: reading.reading_Ts,
												format: 'DateAndTimeWithSeconds',
												timeZone: asset?.site_Timezone,
											})}
										</td>
										<td>{reading.reading_Data}</td>
									</tr>
								))}
							</>
						)}
					</tbody>
				</table>
			)}
			{pageStatus === 'Loading' && <div>Loading...</div>}
			{pageStatus === 'Error' && <div>Error</div>}
		</div>
	)
}

export default TemperatureExceptionDetailRender
