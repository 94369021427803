import React from 'react'
import { AssetOverviewListing } from './AssetOverviewListing'
import PickerHeader from '../../components/UI/ContextualPickers/PickerHeader'

const AssetOverview = () => {
	return (
		<>
			<PickerHeader type="site" title="Assets" />
			<AssetOverviewListing />
		</>
	)
}

export { AssetOverview }
