import React from 'react'
import { AssetCountBar } from '../../components/UI/SiteDetails/AssetCountBar'
import { UpcomingTasks } from '../../components/UI/SiteDetails/UpcomingTasks'
import PickerHeader from '../../components/UI/ContextualPickers/PickerHeader'
import SiteGroupOverviewGraphs from '../../components/UI/SiteGroupDetails/SiteGroupOverviewGraphs'

const SiteDetails = () => {
	return (
		<>
			<PickerHeader type="site" title="Overview" />
			<AssetCountBar />
			<SiteGroupOverviewGraphs />
			<UpcomingTasks />
		</>
	)
}

export { SiteDetails }
