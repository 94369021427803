export const AssetTreeStatusValues = ['Service', 'Monitor'] as const
export type AssetTreeStatus = (typeof AssetTreeStatusValues)[number]

export const AssetTreeStatusIdValues = ['service0-0000-0000-0000-000000000000', 'monitor0-0000-0000-0000-000000000000'] as const
export type AssetTreeStatusId = (typeof AssetTreeStatusIdValues)[number]

export const assetTreeStatus: Record<AssetTreeStatus, { id: AssetTreeStatusId }> = {
	Service: { id: 'service0-0000-0000-0000-000000000000' },
	Monitor: { id: 'monitor0-0000-0000-0000-000000000000' },
}

export const assetTreeStatusDropdownValues = AssetTreeStatusValues.map((value) => ({
	value: assetTreeStatus[value].id,
	label: value,
}))
