import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { useSearchParams } from 'react-router-dom'
import { PageStatus } from '../../../types/PageStatus'
import { Flow, FlowResult } from '../../../models/Report'
import * as Request from '../../../utilities/request'
import { ReportApiFlowWindowType, ReportApiType } from '../../../constants/report'
import logo from '../../../images/LogoBlack.svg'
import { Col, Row } from 'react-bootstrap'
import { ReportStyles as styles } from './ReportStyles'
import './ReportRender.css'
import { DateTime } from 'luxon'
import { secondsToReadable } from '../../../utilities/strings'
import CalendarHeatmap from 'react-calendar-heatmap'
import '../../../components/UI/Reports/CalendarHeatmap.css'
import { Site, SiteResult } from '../../../models/Site'
import { SiteGroup, SiteGroupResult } from '../../../models/SiteGroup'

const FlowRender = () => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [flow, setFlow] = React.useState<Flow>()
	const [site, setSite] = React.useState<Site | null>(null)
	const [siteGroup, setSiteGroup] = React.useState<SiteGroup | null>(null)

	const [searchParams] = useSearchParams()
	const flowWindowType = searchParams.get('FlowWindowType')
	const siteId = searchParams.get('Site_Id')
	const siteGroupId = searchParams.get('SiteGroup_Id')
	const date = searchParams.get('Date')
	const displayDate = searchParams.get('DisplayDate')
	const belowThresholdDays = searchParams.get('BelowThresholdDays')
	const fixtureOnly = searchParams.get('FixtureOnly')

	const displayDateObject = flow
		? DateTime.fromObject({
				year: flow.displayDateStartYear,
				month: flow.displayDateStartMonth,
				day: flow.displayDateStartDay,
		  })
		: DateTime.now()

	const monthly = flowWindowType === ReportApiFlowWindowType.MONTH.value
	const weekly = flowWindowType === ReportApiFlowWindowType.WEEK.value

	React.useEffect(() => {
		const getData = async () => {
			const [flowsReq, siteReq, siteGroupReq] = await Promise.all([
				Request.get<FlowResult>(
					`report/${ReportApiType.FLOW.value}?FlowWindowType=${flowWindowType}&${
						siteId !== null ? `Site_Id=${siteId}` : `SiteGroup_Id=${siteGroupId}`
					}&Date=${date}&DisplayDate=${displayDate}&BelowThresholdDays=${belowThresholdDays}&${fixtureOnly ? 'FixtureOnly=true' : 'TmvOnly=true'}`,
					context.appState.authState
				),
				siteId !== null ? Request.get<SiteResult>(`site?Id=${siteId}`, context.appState.authState) : Promise.resolve({ data: { sites: [] } }),
				siteGroupId !== null
					? Request.get<SiteGroupResult>(`siteGroup?Id=${siteGroupId}`, context.appState.authState)
					: Promise.resolve({ data: { siteGroups: [] } }),
			])
			setFlow(flowsReq.data.flow)
			setSite(siteReq.data.sites[0] || null)
			setSiteGroup(siteGroupReq.data.siteGroups[0] || null)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			if (
				(siteId === null) === (siteGroupId === null) ||
				flowWindowType === null ||
				date === null ||
				displayDate === null ||
				belowThresholdDays === null
			) {
				setPageStatus('Error')
			} else {
				getData()
			}
		}
	}, [context])

	return (
		<div style={styles.w100}>
			{pageStatus === 'Ready' && flow && (
				<table style={styles.w100}>
					<thead>
						<tr style={styles.theadTitleRow}>
							<td colSpan={monthly ? 6 : weekly ? 12 : 5}>
								<Row>
									<Col sm="auto">
										<img src={logo} alt="logo" style={styles.headerImg} />
									</Col>
									<Col style={styles.headerName}>{site?.site_Name || siteGroup?.siteGroup_Name || ''}</Col>
									<Col sm="auto">
										<div style={styles.headerTitle}>
											<span>{monthly ? 'Monthly' : weekly ? 'Weekly' : 'Daily'} Flow Report for</span>
											<span>{displayDateObject.toFormat(monthly ? 'MMMM yyyy' : 'dd MMMM yyyy')}</span>
										</div>
									</Col>
								</Row>
							</td>
						</tr>
						<tr style={{ ...styles.theadColNameRow, ...styles.lightText }}>
							<th style={styles.cell}>Asset ID</th>
							<th style={styles.cell}>Asset Number</th>
							<th style={styles.cell}>TMV Location</th>
							<th style={styles.cell}>Days Below Threshold</th>
							{monthly && <th style={styles.cell}>Daily Usage</th>}
							{weekly &&
								Array.from({ length: 7 }).map((_, i, arr) => (
									<th key={i} style={i === arr.length - 1 ? styles.cellLast : styles.cell}>
										{displayDateObject.plus({ days: i }).toFormat('dd/MM')}
									</th>
								))}
							<th style={styles.cellLast}>Total Runtime</th>
						</tr>
					</thead>
					<tbody>
						{flow.assetFlows
							.sort((a, b) => (a.asset_Name < b.asset_Name ? -1 : 1))
							.map((af) => (
								<tr key={af.asset_Id} style={styles.bottomBorder}>
									<td style={styles.cell}>{af.asset_Name}</td>
									<td style={styles.cell}>{af.asset_SerialNo}</td>
									<td style={styles.cell}>{af.asset_Location}</td>
									<td style={styles.cell}>{af.daysBelowThreshold}</td>
									{monthly && (
										<td style={styles.cell}>
											<div style={styles.calBox}>
												<CalendarHeatmap
													startDate={displayDateObject.startOf('month').minus({ days: 1 }).toISODate() || ''}
													endDate={displayDateObject.endOf('month').toISODate() || ''}
													values={af.dailyFlows.map((df) => ({
														date: df.date,
														count: df.durationSeconds,
													}))}
													horizontal={false}
													showMonthLabels={false}
													showWeekdayLabels={false}
													classForValue={(value) => {
														let color
														console.log(value, flow)
														if (value && flow && value.count >= flow.thresholdValueSeconds) {
															color = 'cal-color-pass'
														} else if (value && value.count != null) {
															color = 'cal-color-fail'
														} else if (DateTime.fromISO(value.date) >= DateTime.now().startOf('day')) {
															color = 'cal-color-clear'
														} else {
															color = 'cal-color-no-data'
														}
														return color
													}}
												/>
											</div>
										</td>
									)}
									{weekly &&
										af.dailyFlows.map((df, i) => (
											<td key={i} style={styles.cell}>
												{secondsToReadable(df.durationSeconds)}
											</td>
										))}
									<td style={styles.cellLast}>
										{secondsToReadable(
											af.dailyFlows.reduce((sum, df) => (sum += df.durationSeconds), 0),
											true
										)}
									</td>
								</tr>
							))}
					</tbody>
				</table>
			)}
			{pageStatus === 'Loading' && <div>Loading...</div>}
			{pageStatus === 'Error' && <div>Error</div>}
		</div>
	)
}

export default FlowRender
