import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import './ManageUsersContextual.css'

import { Col, Dropdown, Row } from 'react-bootstrap'

import { AddEditUserModal } from './AddEditUserModal'
import { ResetPasswordModal } from './ResetPasswordModal'
import { Button } from '../Button/Button'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { Loading } from '../Loading/Loading'
import ellipsis from '../../../images/icons/options.svg'
import { User } from '../../../models/User'
import { UserSite, UserSiteResult } from '../../../models/UserSite'
import { PageStatus } from '../../../types/PageStatus'
import * as Request from '../../../utilities/request'
import { text_truncate } from '../../../utilities/strings'

interface BaseProps {
	contextLocationId: string
}

interface SiteProps extends BaseProps {
	type: 'Site'
}

interface SiteGroupProps extends BaseProps {
	type: 'SiteGroup'
}

const ManageUsersContextual = (props: SiteProps | SiteGroupProps) => {
	const context = useContext(AppContext)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [userSites, setUserSites] = React.useState<UserSite[] | null>(null)

	const [existingUserSite, setExistingUserSite] = React.useState<UserSite | undefined>(undefined)

	const [showResetPasswordModal, setShowResetPasswordModal] = React.useState<boolean>(false)
	const [userToResetPassword, setUserToResetPassword] = React.useState<User | null>(null)
	const [showDeleteUserModal, setShowDeleteUserModal] = React.useState<boolean>(false)
	const [userSiteToDelete, setUserSiteToDelete] = React.useState<string | null>(null)

	const [showAddEditUserModal, setShowAddEditUserModal] = React.useState<boolean>(false)
	const [mode, setMode] = React.useState<'Invite' | 'AddExisting' | 'Edit'>('Invite')

	React.useEffect(() => {
		const getData = async () => {
			const [userSitesReq] = await Promise.all([
				Request.get<UserSiteResult>(
					`userSite?${props.type === 'SiteGroup' ? `SiteGroup_Id=${props.contextLocationId}` : `Site_Id=${props.contextLocationId}`}`,
					context.appState.authState
				),
			])
			setUserSites(userSitesReq.data.userSites)

			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props])

	return pageStatus === 'Ready' ? (
		<>
			<Row>
				<Col>
					<Row className="user-thead">
						<Col sm={7} className="user-td">
							Email
						</Col>
						<Col sm={3} className="user-td">
							Role
						</Col>
						<Col sm={2} className={'center-flex user-td'}>
							Actions
						</Col>
					</Row>
					{userSites && userSites.length > 0 ? (
						userSites.map((userSite) => (
							<Row key={userSite.userSite_Id}>
								<Col sm={7} className="user-td">
									{text_truncate(userSite.userSite_User.user_Email, 40)}
								</Col>
								<Col sm={3} className="user-td">
									{userSite.userRole_Name}
								</Col>
								<Col sm={2} className={'center-flex user-td'}>
									<Dropdown>
										<Dropdown.Toggle as="img" src={ellipsis} alt="User options" />
										<Dropdown.Menu>
											<Dropdown.Item
												onClick={() => {
													setMode('Edit')

													setExistingUserSite(userSite)
													setShowAddEditUserModal(true)
												}}
											>
												Edit
											</Dropdown.Item>
											{process.env.REACT_APP_IsLocalMode === 'true' && (
												<Dropdown.Item
													onClick={() => {
														setUserToResetPassword(userSite.userSite_User)
														setShowResetPasswordModal(true)
													}}
												>
													Reset Password
												</Dropdown.Item>
											)}
											<Dropdown.Item
												onClick={() => {
													setUserSiteToDelete(userSite.userSite_Id)
													setShowDeleteUserModal(true)
												}}
											>
												Delete
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</Col>
							</Row>
						))
					) : (
						<Row className={'center-flex h-100'}>
							<Col className={'center-flex'}>No Users</Col>
						</Row>
					)}
				</Col>
			</Row>
			<Row className="mt-3">
				<Col sm="auto">
					<Button
						variant="primary"
						onClick={() => {
							setMode('Invite')

							setExistingUserSite(undefined)
							setShowAddEditUserModal(true)
						}}
					>
						Add New User
					</Button>
				</Col>
				<Col sm="auto">
					<Button
						variant="primary"
						onClick={() => {
							setMode('AddExisting')

							setExistingUserSite(undefined)
							setShowAddEditUserModal(true)
						}}
					>
						Add Existing User
					</Button>
				</Col>
			</Row>

			{showAddEditUserModal && (
				<AddEditUserModal
					type={props.type}
					mode={mode}
					siteOrGroupId={props.contextLocationId}
					show={showAddEditUserModal}
					setShow={setShowAddEditUserModal}
					existingUserSites={userSites || []}
					setExistingUserSites={setUserSites}
					editUserSite={existingUserSite}
				/>
			)}
			{userToResetPassword && (
				<ResetPasswordModal
					showResetPasswordModal={showResetPasswordModal}
					setShowResetPasswordModal={setShowResetPasswordModal}
					user={userToResetPassword}
				/>
			)}

			<ConfirmModal
				show={showDeleteUserModal}
				setShow={setShowDeleteUserModal}
				title="Delete User"
				body={`Are you sure you want to delete "${userSites?.find((us) => us.userSite_Id === userSiteToDelete)?.userSite_User.user_Email}" from this ${
					props.type === 'Site' ? 'Site' : 'Site Group'
				}?`}
				confirmButtonText="Delete"
				onConfirm={() => {
					Request.del(`userSite?id=${userSiteToDelete}`, context.appState.authState)
					setUserSites((userSites || []).filter((userSite) => userSite.userSite_Id !== userSiteToDelete))
				}}
			/>
		</>
	) : (
		<Row>
			<Col className={'center-flex'}>
				<Loading show />
			</Col>
		</Row>
	)
}

export { ManageUsersContextual }
