import React from 'react'
import { AppContext } from '../../App'

import { Card } from '../../components/UI/Card/Card'
import { TaskOverviewListing } from '../Task/TaskOverviewListing'

const ScreensTaskDashboard = () => {
	const { appState } = React.useContext(AppContext)
	return (
		<div className="page">
			<Card title={'All Tasks'} collapsible={false}>
				<TaskOverviewListing filter={`user_Id=${appState.userAttributes.user_Id}`} />
			</Card>
		</div>
	)
}

export { ScreensTaskDashboard }
