import { AppState } from '../../App.d'
import { UserRoleId } from '../../constants/userRole'
import * as Types from './permission.d'
import { viewOnlyPermissionObject } from './shared'

// Permissions by object
import { assetPermissionObject } from './objects/asset'
import { assetMakePermissionObject } from './objects/assetMake'
import { assetModelPermissionObject } from './objects/assetModel'
import { assetTreePermissionObject } from './objects/assetTree'
import { bacnetPermissionObject } from './objects/bacnet'
import { channelMapPermissionObject } from './objects/channelMap'
import { contractPermissionObject } from './objects/contract'
import { contractModulePermissionObject } from './objects/contractModule'
import { documentPermissionObject } from './objects/document'
import { eventPermissionObject } from './objects/event'
import { eventSettingPermissionObject } from './objects/eventSetting'
import { exportPermissionObject } from './objects/export'
import { groupPermissionObject } from './objects/group'
import { importPermissionObject } from './objects/import'
import { invoicePermissionObject } from './objects/invoice'
import { invoiceItemPermissionObject } from './objects/invoiceItem'
import { locationAreaPermissionObject } from './objects/locationArea'
import { locationBuildingPermissionObject } from './objects/locationBuilding'
import { locationFloorPermissionObject } from './objects/locationFloor'
import { locationRoomPermissionObject } from './objects/locationRoom'
import { maintenancePermissionObject } from './objects/maintenance'
import { paymentAgreementPermissionObject } from './objects/paymentAgreement'
import { readingPermissionObject } from './objects/reading'
import { sitePermissionObject } from './objects/site'
import { siteGroupPermissionObject } from './objects/siteGroup'
import { taskPermissionObject } from './objects/task'
import { userPermissionObject } from './objects/user'
import { userInvitePermissionObject } from './objects/userInvite'
import { userSitePermissionObject } from './objects/userSite'

const permissionModel: Types.PermissionModelObjectMatrix = {
	[Types.PermissionModelObject.Asset]: assetPermissionObject,
	[Types.PermissionModelObject.AssetClass]: viewOnlyPermissionObject,
	[Types.PermissionModelObject.AssetMake]: assetMakePermissionObject,
	[Types.PermissionModelObject.AssetModel]: assetModelPermissionObject,
	[Types.PermissionModelObject.AssetTree]: assetTreePermissionObject,
	[Types.PermissionModelObject.AssetTreeStatus]: viewOnlyPermissionObject,
	[Types.PermissionModelObject.AssetType]: viewOnlyPermissionObject,
	[Types.PermissionModelObject.Bacnet]: bacnetPermissionObject,
	[Types.PermissionModelObject.ChannelMap]: channelMapPermissionObject,
	[Types.PermissionModelObject.Contract]: contractPermissionObject,
	[Types.PermissionModelObject.ContractModule]: contractModulePermissionObject,
	[Types.PermissionModelObject.Document]: documentPermissionObject,
	[Types.PermissionModelObject.Event]: eventPermissionObject,
	[Types.PermissionModelObject.EventSetting]: eventSettingPermissionObject,
	[Types.PermissionModelObject.EventSettingType]: viewOnlyPermissionObject,
	[Types.PermissionModelObject.Export]: exportPermissionObject,
	[Types.PermissionModelObject.Group]: groupPermissionObject,
	[Types.PermissionModelObject.GroupType]: viewOnlyPermissionObject,
	[Types.PermissionModelObject.Invoice]: invoicePermissionObject,
	[Types.PermissionModelObject.InvoiceItem]: invoiceItemPermissionObject,
	[Types.PermissionModelObject.Import]: importPermissionObject,
	[Types.PermissionModelObject.LocationArea]: locationAreaPermissionObject,
	[Types.PermissionModelObject.LocationBuilding]: locationBuildingPermissionObject,
	[Types.PermissionModelObject.LocationFloor]: locationFloorPermissionObject,
	[Types.PermissionModelObject.LocationRoom]: locationRoomPermissionObject,
	[Types.PermissionModelObject.Maintenance]: maintenancePermissionObject,
	[Types.PermissionModelObject.MaintenanceType]: viewOnlyPermissionObject,
	[Types.PermissionModelObject.Module]: viewOnlyPermissionObject,
	[Types.PermissionModelObject.PaymentAgreement]: paymentAgreementPermissionObject,
	[Types.PermissionModelObject.Reading]: readingPermissionObject,
	[Types.PermissionModelObject.ReadingType]: viewOnlyPermissionObject,
	[Types.PermissionModelObject.Site]: sitePermissionObject,
	[Types.PermissionModelObject.SiteGroup]: siteGroupPermissionObject,
	[Types.PermissionModelObject.SiteStatus]: viewOnlyPermissionObject,
	[Types.PermissionModelObject.Stats]: viewOnlyPermissionObject, // Possibly handled individually?
	[Types.PermissionModelObject.Task]: taskPermissionObject,
	[Types.PermissionModelObject.TaskAsset]: taskPermissionObject, // Covered by task permissions
	[Types.PermissionModelObject.TaskComment]: taskPermissionObject, // Covered by task permissions
	[Types.PermissionModelObject.TaskHistory]: viewOnlyPermissionObject,
	[Types.PermissionModelObject.TaskStatus]: viewOnlyPermissionObject,
	[Types.PermissionModelObject.TaskUser]: taskPermissionObject, // Covered by task permissions
	[Types.PermissionModelObject.User]: userPermissionObject,
	[Types.PermissionModelObject.UserInvite]: userInvitePermissionObject,
	[Types.PermissionModelObject.UserRole]: viewOnlyPermissionObject,
	[Types.PermissionModelObject.UserSite]: userSitePermissionObject,
}

const getUserRoleIdForContext = (appState: AppState, context: Types.PermissionModelContext) => {
	// External to Sites/SiteGroups, only system user role ids are relevant
	// Within Sites/SiteGroups, system user role id is relevant if present
	if (context === Types.PermissionModelContext.None || appState.userAttributes.userRole_Id !== null) {
		return appState.userAttributes.userRole_Id || ''
	}
	// Within Sites/SiteGroups, we only care about the user's role id in the current site/siteGroup context
	if (context === Types.PermissionModelContext.Site) {
		return appState.userSites.find((userSite) => userSite.site_Id === appState.currentSite?.site_Id)?.userRole_Id || ''
	}
	if (context === Types.PermissionModelContext.SiteGroup) {
		return appState.userSites.find((userSite) => userSite.siteGroup_Id === appState.currentSiteGroup?.siteGroup_Id)?.userRole_Id || ''
	}
}

export const hasPermission = (
	object: Types.PermissionModelObject,
	action: Types.PermissionModelAction,
	appState: AppState,
	context: Types.PermissionModelContext
) => {
	const userRoleId = getUserRoleIdForContext(appState, context) as UserRoleId | ''
	return permissionModel[object][action].PermissionsRequired.every((requirement) => requirement({ userRoleId, object, action }))
}
