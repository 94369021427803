import { AuthState } from '../App.d'
import { MessageAction } from '../components/UI/Messages/Message'
import * as Request from './request'

export function downloadBlob(content: string | Uint8Array, filename: string, contentType: string) {
	// Create a blob
	const blob = new Blob([content], { type: contentType })
	const url = URL.createObjectURL(blob)

	// Create a link to download it
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', filename)
	link.click()
}

export function base64ToArrayBuffer(base64: string) {
	const binaryString = window.atob(base64) // Comment this if not using base64
	const bytes = new Uint8Array(binaryString.length)
	return bytes.map((byte, i) => binaryString.charCodeAt(i))
}

export const fetchPDFData = async (authState: AuthState, pdfUrl: string, setMessages?: (action: MessageAction) => void) => {
	const MAX_ATTEMPTS = 3
	let attempts = 1

	do {
		try {
			const reportRes = await Request.getReport<string>(`report/${pdfUrl}`, authState)
			if (reportRes.status === 200 && reportRes.data) {
				return reportRes.data
			} else {
				throw new Error('No data returned from server')
			}
		} catch {
			if (setMessages) {
				if (attempts < 3) {
					setMessages({
						type: 'add',
						data: {
							severity: 'danger',
							message: 'Error downloading PDF, Retrying...',
							timeout: 5000,
							dismissible: true,
						},
					})
				} else {
					setMessages({
						type: 'add',
						data: {
							severity: 'danger',
							message: 'Error downloading PDF',
							timeout: 5000,
							dismissible: true,
						},
					})
				}
			}
		} finally {
			attempts++
		}
	} while (attempts <= MAX_ATTEMPTS)
	return undefined
}
