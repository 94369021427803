import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'

import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../utilities/permissions/permission.d'

import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { Line } from '../../components/UI/Line/Line'
import { Back } from '../../components/UI/Back/Back'
import { TaskInformation } from '../../components/UI/TaskDetails/TaskInformation'
import { TaskComments } from '../../components/UI/TaskDetails/TaskComments'
import { TaskHistory } from '../../components/UI/TaskDetails/TaskHistory'
import { ConfirmModal } from '../../components/UI/ConfirmModal/ConfirmModal'
import Error from '../../components/UI/Error/Error'
import PermissionsCheck from '../../components/Permissions/PermissionsCheck'

import { PageStatus } from '../../types/PageStatus'
import { Task, TaskResult, defaultTask } from '../../models/Task'
import { defaultCreated, defaultModified } from '../../models/History'
import { TaskUser, TaskUserResult } from '../../models/TaskUser'
import { defaultTaskHistory } from '../../models/TaskHistory'
import { formatOutgoingDateTime } from '../../utilities/formatDate'
import { TaskAsset, TaskAssetResult } from '../../models/TaskAsset'

const taskValidationSchema = yup.object().shape({
	task_Name: yup.string().required('Task name is required.').max(100, 'Max character limit is 100.'),
	task_Note: yup.string().required('Task description is required.').max(100, 'Max character limit is 1000.'),
	severity_Id: yup.string().required('Priority is required.'),
	taskStatus_Id: yup.string().required('Status is required.'),
})

const TaskDetails = () => {
	const context = React.useContext(AppContext)
	const navigate = useNavigate()
	const id = useParams().id

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [messages, setMessages] = useMessageReducer([])
	const [showModal, setShowModal] = React.useState<boolean>(false)
	const [refreshData, setRefreshData] = React.useState<boolean>(false)

	const [task, setTask] = React.useState<Task | null>(null)

	const defaultCreatedUser = defaultCreated({ create_UserId: context.appState.userAttributes.user_Id })
	const defaultModifiedUser = defaultModified({ modified_UserId: context.appState.userAttributes.user_Id })

	React.useEffect(() => {
		const getData = async () => {
			if (id !== 'new') {
				const [taskReq] = await Promise.all([Request.get<TaskResult>(`task?Id=${id}`, context.appState.authState)])
				if (taskReq.data.tasks.length > 0) {
					setTask(taskReq.data.tasks[0])
					setPageStatus('Ready')
				} else {
					setPageStatus('Error')
				}
			} else {
				setTask(
					defaultTask({
						created: defaultCreatedUser,
						modified: defaultModifiedUser,
						site_Id: context.appState.currentSite?.site_Id,
					})
				)
				setPageStatus('Editing')
			}
		}

		if (context.appState.authState.isLoggedIn && context.appState.currentSite) {
			getData()
		}
	}, [id])

	const handleUpdateAssignedUsers = async (existingUsers: TaskUser[], updatedUsers: TaskUser[]) => {
		const taskUsersToDelete = existingUsers.filter((oldUser) => !updatedUsers.some((newUser) => newUser.user_Id === oldUser.user_Id))
		const taskUsersToCreate = updatedUsers.filter((newUser) => !existingUsers.some((oldUser) => oldUser.user_Id === newUser.user_Id))
		const taskHistoryNote =
			taskUsersToDelete.concat(taskUsersToCreate).length > 0
				? `Task Updated: Assigned Users/Roles changed from '${existingUsers
						.map((tu) => (tu.taskUser_User ? `${tu.taskUser_User.user_FirstName} ${tu.taskUser_User.user_LastName}` : tu.userRole_Name))
						.join(', ')}' to '${updatedUsers
						.map((tu) => (tu.taskUser_User ? `${tu.taskUser_User.user_FirstName} ${tu.taskUser_User.user_LastName}` : tu.userRole_Name))
						.join(', ')}'.`
				: undefined
		return Promise.all([
			...taskUsersToDelete.map((taskUser) => Request.del<TaskUserResult>(`taskUser?Id=${taskUser.taskUser_Id}`, context.appState.authState)),
			...taskUsersToCreate.map((taskUser) => Request.post<TaskUserResult>('taskUser', taskUser, context.appState.authState)),
			taskHistoryNote
				? Request.post(
						'taskHistory',
						defaultTaskHistory({
							task_Id: task?.task_Id,
							taskHistory_Note: taskHistoryNote,
							taskHistory_Ts: formatOutgoingDateTime({ dateTime: new Date().toISOString(), format: 'DateString' }) || undefined,
						}),
						context.appState.authState
				  )
				: Promise.resolve({ status: 200 }),
		])
	}

	const handleUpdateAssignedAssets = async (existingAssets: TaskAsset[], updatedAssets: TaskAsset[]) => {
		const taskAssetsToDelete = existingAssets.filter((oldAsset) => !updatedAssets.some((newAsset) => newAsset.asset_Id === oldAsset.asset_Id))
		const taskAssetsToCreate = updatedAssets.filter((newAsset) => !existingAssets.some((oldAsset) => oldAsset.asset_Id === newAsset.asset_Id))
		const taskHistoryNote =
			taskAssetsToDelete.concat(taskAssetsToCreate).length > 0
				? `Task Updated: Assigned Assets changed from '${existingAssets.map((tu) => tu.taskAsset_Asset?.asset_Name).join(', ')}' to '${updatedAssets
						.map((tu) => tu.taskAsset_Asset?.asset_Name)
						.join(', ')}'.`
				: undefined
		return Promise.all([
			...taskAssetsToDelete.map((taskAsset) => Request.del<TaskAssetResult>(`taskAsset?Id=${taskAsset.taskAsset_Id}`, context.appState.authState)),
			...taskAssetsToCreate.map((taskAsset) => Request.post<TaskAssetResult>('taskAsset', taskAsset, context.appState.authState)),
			taskHistoryNote
				? Request.post(
						'taskHistory',
						defaultTaskHistory({
							task_Id: task?.task_Id,
							taskHistory_Note: taskHistoryNote,
							taskHistory_Ts: formatOutgoingDateTime({ dateTime: new Date().toISOString(), format: 'DateString' }) || undefined,
						}),
						context.appState.authState
				  )
				: Promise.resolve({ status: 200 }),
		])
	}

	const handleCreate = async (values: Task) => {
		setPageStatus('Submitting')
		const taskRes = await Request.post<TaskResult>('task', values, context.appState.authState)
		if (taskRes.status === 200 && taskRes.data.tasks.length > 0) {
			await Promise.all([
				...values.task_TaskUsers.map((taskUser) =>
					Request.post(
						'taskUser',
						{
							...taskUser,
							task_Id: taskRes.data.tasks[0].task_Id,
						},
						context.appState.authState
					)
				),
				...values.task_TaskAssets.map((taskAsset) =>
					Request.post(
						'taskAsset',
						{
							...taskAsset,
							task_Id: taskRes.data.tasks[0].task_Id,
						},
						context.appState.authState
					)
				),
			])
			setMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: `Success creating the task`,
					dismissible: true,
					timeout: 5000,
				},
			})
			setTask(taskRes.data.tasks[0])
			navigate(`/task/${taskRes.data.tasks[0].task_Id}`)
		} else {
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `${taskRes.data.errors}`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
	}

	const handleEdit = async (values: Task) => {
		setPageStatus('Submitting')
		try {
			const [userRes, assetRes] = await Promise.all([
				handleUpdateAssignedUsers(task?.task_TaskUsers || [], values.task_TaskUsers),
				handleUpdateAssignedAssets(task?.task_TaskAssets || [], values.task_TaskAssets),
			])
			// do this one separately so we get updated task and user lists
			const taskRes = await Request.put<TaskResult>('task', values, context.appState.authState)
			if (taskRes.status === 200 && userRes.every((res) => res.status === 200) && assetRes.every((res) => res.status === 200)) {
				setTask(taskRes.data.tasks[0])
				setPageStatus('Editing')
				setRefreshData(!refreshData)
			} else {
				setMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to edit task',
						dismissible: true,
						timeout: 5000,
					},
				})
				setPageStatus('Editing')
			}
		} catch (e) {
			console.log(e)
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to edit task',
					dismissible: true,
					timeout: 5000,
				},
			})
			setPageStatus('Editing')
		}
	}

	const handleDelete = async () => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.del<TaskResult>(`task?Id=${task?.task_Id}`, context.appState.authState), {
			successFunction: () => {
				navigate('/tasks')
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'task',
		})
	}

	return (
		<div className="page-container">
			<Messages messages={messages} updateMessage={setMessages} />
			<ConfirmModal
				show={showModal}
				setShow={setShowModal}
				title={'Confirm delete'}
				body={'Are you sure you want to delete this task?'}
				onConfirm={handleDelete}
			/>
			<Row className="page-header-row">
				<Col>
					<Back />
				</Col>
			</Row>
			{task && (
				<>
					<Row style={styles.row}>
						<Col style={styles.cell} sm="auto">
							<h1 className="page-header-primary">{id === 'new' ? 'Create New Task' : pageStatus === 'Editing' ? 'Edit Task' : 'View Task'}</h1>
							<span className="page-header-divider">|</span>
							<h2 className="page-header-secondary">{context.appState.currentSite?.site_Name}</h2>
						</Col>
						<Col />
						<PermissionsCheck object={PermissionModelObject.Task} action={PermissionModelAction.PUT} context={PermissionModelContext.Site}>
							<Col sm="auto">
								<Button style={styles.button} onClick={() => setPageStatus('Editing')} disabled={pageStatus !== 'Ready'}>
									Edit
								</Button>
							</Col>
						</PermissionsCheck>
					</Row>
					<Row style={styles.row}>
						<Line />
					</Row>
					<Row style={styles.row}>
						<Formik
							initialValues={task}
							validationSchema={taskValidationSchema}
							onSubmit={(values) => (id === 'new' ? handleCreate(values) : handleEdit(values))}
							enableReinitialize
						>
							{({ handleSubmit, errors, values, touched, setFieldValue, handleReset }) => (
								<>
									<TaskInformation task={values} pageStatus={pageStatus} errors={errors} touched={touched} handleChange={setFieldValue} />
									{id !== 'new' && <TaskComments taskId={task.task_Id} pageStatus={pageStatus} setMessages={setMessages} />}
									{id !== 'new' && <TaskHistory taskId={task.task_Id} pageStatus={pageStatus} refresh={refreshData} />}
									<Row style={styles.row}>
										{pageStatus === 'Editing' && (
											<Col sm={'1'}>
												<Button style={styles.button} variant={'secondary'} onClick={handleReset}>
													Cancel
												</Button>
											</Col>
										)}
										<PermissionsCheck
											object={PermissionModelObject.Task}
											action={PermissionModelAction.PUT}
											context={PermissionModelContext.Site}
										>
											<>
												{pageStatus === 'Editing' && (
													<Col sm={'1'}>
														<Button style={styles.button} onClick={() => handleSubmit()}>
															Save
														</Button>
													</Col>
												)}
												<Col sm={'1'}>
													<Button style={styles.button} onClick={() => setShowModal(true)} disabled={pageStatus === 'Submitting'}>
														Delete
													</Button>
												</Col>
											</>
										</PermissionsCheck>
									</Row>
								</>
							)}
						</Formik>
					</Row>
				</>
			)}
			{pageStatus === 'Error' && <Error />}
		</div>
	)
}

const styles = {
	row: {
		marginTop: '40px',
	},
	cell: {
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center',
	},
	button: {
		width: '100%',
	},
} satisfies Record<string, React.CSSProperties>

export { TaskDetails }
