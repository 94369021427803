import React from 'react'
import { Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { FormText } from '../Form/Text'
import { FormStepProps } from '../../../screens/Contracts/ContractWizard'

const ContactValidationSchema = Yup.object().shape({
	contract_FirstName: Yup.string().notRequired().max(100, 'Cannot be longer than ${max} characters.'),
	contract_LastName: Yup.string().notRequired().max(100, 'Cannot be longer than ${max} characters.'),
	contract_Email: Yup.string().notRequired().max(100, 'Cannot be longer than ${max} characters.').email('Not a valid email.'),
	contract_Phone: Yup.string().notRequired().max(100, 'Cannot be longer than ${max} characters.'),
	contract_Note: Yup.string().notRequired().max(1000, 'Cannot be longer than ${max} characters.'),
})

const ContactForm = (props: FormStepProps) => {
	const { values, handleChange, errors, touched, handleBlur } = props.formikProps

	return (
		<>
			<Row>
				<Col>
					<span className="span-bold">Contact Details</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormText
						name="contract_FirstName"
						label="First Name"
						value={values.contract_FirstName || ''}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_FirstName && errors.contract_FirstName ? errors.contract_FirstName : ''}
						isInvalid={touched.contract_FirstName && !!errors.contract_FirstName}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormText
						name="contract_LastName"
						label="Last Name"
						value={values.contract_LastName || ''}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_LastName && errors.contract_LastName ? errors.contract_LastName : ''}
						isInvalid={touched.contract_LastName && !!errors.contract_LastName}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormText
						name="contract_Email"
						label="Email"
						value={values.contract_Email || ''}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_Email && errors.contract_Email ? errors.contract_Email : ''}
						isInvalid={touched.contract_Email && !!errors.contract_Email}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormText
						name="contract_Phone"
						label="Phone"
						value={values.contract_Phone || ''}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_Phone && errors.contract_Phone ? errors.contract_Phone : ''}
						isInvalid={touched.contract_Phone && !!errors.contract_Phone}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormText
						name="contract_Note"
						label="Notes"
						value={values.contract_Note || ''}
						onChange={handleChange}
						onBlur={handleBlur}
						feedback={touched.contract_Note && errors.contract_Note ? errors.contract_Note : ''}
						isInvalid={touched.contract_Note && !!errors.contract_Note}
						rows={3}
					/>
				</Col>
			</Row>
		</>
	)
}

export { ContactForm, ContactValidationSchema }
