import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { PageStatus } from '../../../types/PageStatus'
import { Col, Row } from 'react-bootstrap'
import DoughnutGraphBox from '../Graphs/DoughnutGraphBox'
import {
	FlowReadingCount,
	FlowReadingCountResult,
	MaintenanceCount,
	MaintenanceCountResult,
	MonthlyReadingCount,
	MonthlyReadingCountResult,
	OnlineCount,
	OnlineCountResult,
	TemperatureExceptionCount,
	TemperatureExceptionCountResult,
} from '../../../models/Stats'
import { colors } from '../../../constants/colors'
import { DateTime } from 'luxon'
import { formatOutgoingDateTime } from '../../../utilities/formatDate'
import { SiteSetting, SiteSettingResult, defaultSiteSettings } from '../../../models/SiteSetting'
import { eventSettingType } from '../../../constants/eventSettingType'
import { Loading } from '../Loading/Loading'
import { AssetClassId, assetClass, getAssetClassNameFromId } from '../../../constants/assetClass'

interface GraphProps {
	siteGroup?: boolean
	startDate?: DateTime
	disableAnimation?: boolean
	setting?: SiteSetting
	assetClass_Id?: AssetClassId
	flowCountConsecutiveDaysWithoutFlow?: boolean
}

const SiteGroupOverviewGraphs = (props: GraphProps) => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [siteSettings, setSiteSettings] = React.useState<SiteSetting[]>(defaultSiteSettings)

	// A default start date of the first day of the current month
	const [monthDate] = React.useState<DateTime>(DateTime.now().set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }))

	React.useEffect(() => {
		const getData = async () => {
			const [siteSettingsReq] = await Promise.all([
				Request.get<SiteSettingResult>(`siteSetting?Site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
			])

			// When Site Settings are saved, they are all saved at once, so site settings either exist or not, there is no partial existence of individual settings
			if (siteSettingsReq.data.siteSettings.length > 0) {
				setSiteSettings(siteSettingsReq.data.siteSettings)
			}

			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context.appState.currentSite])

	const graphs = [
		<MonthlyReadingsDoughnutGraph key={'MonthlyReadingsDoughnutGraph'} startDate={props.startDate || monthDate} {...props} />,
		<DevicesDoughnutGraph key={'DevicesDoughnutGraph'} {...props} />,
		<TemperatureExceptionsDoughnutGraph
			key={'TemperatureExceptionsDoughnutGraph'}
			startDate={props.startDate || monthDate}
			assetClass_Id={assetClass.Tmv.id}
			{...props}
		/>,
	]

	const tmvFlowMetricDaysSettings = siteSettings.find((s) => s.eventSettingType_Id === eventSettingType['TMV Flow Metric Days'].id)
	if (tmvFlowMetricDaysSettings && tmvFlowMetricDaysSettings.siteSetting_IsEnabled) {
		graphs.push(
			<FlowReadingsDoughnutGraph key={'TMVFlowReadingsDoughnutGraph'} setting={tmvFlowMetricDaysSettings} assetClass_Id={assetClass.Tmv.id} {...props} />
		)
	}

	const fixtureFlowMetricDaysSettings = siteSettings.find((s) => s.eventSettingType_Id === eventSettingType['Fixture Flow Metric Days'].id)
	if (fixtureFlowMetricDaysSettings && fixtureFlowMetricDaysSettings.siteSetting_IsEnabled) {
		graphs.push(
			<FlowReadingsDoughnutGraph
				key={'FixtureFlowReadingsDoughnutGraph'}
				setting={fixtureFlowMetricDaysSettings}
				assetClass_Id={assetClass.Fixture.id}
				{...props}
			/>
		)
	}

	const pdfColWidths: number[] = []
	if (graphs.length === 3) pdfColWidths.push(4, 4, 4) // One row of 3 cards
	if (graphs.length === 4) pdfColWidths.push(6, 6, 6, 6) // Two rows of 2 cards
	if (graphs.length === 5) pdfColWidths.push(4, 4, 4, 6, 6) // One row of 3 cards, one row of 2 cards

	return (
		<Row style={styles.row} className="pt-3">
			{pageStatus != 'Loading' ? (
				graphs.map((graph, index) => (
					<Col key={index} sm={props.disableAnimation ? pdfColWidths[index] : undefined}>
						{graph}
					</Col>
				))
			) : (
				<Loading show={true} />
			)}
		</Row>
	)
}

const MonthlyReadingsDoughnutGraph = (props: GraphProps) => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [monthlyReadingCount, setMonthlyReadingCount] = React.useState<MonthlyReadingCount | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [monthlyReadingCountReq] = await Promise.all([
				Request.get<MonthlyReadingCountResult>(
					`stats/monthlyReadingCount${
						props.siteGroup
							? `?siteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
							: `?site_Id=${context.appState.currentSite?.site_Id}`
					}${
						props.startDate
							? `&StartDate=${formatOutgoingDateTime({
									dateTime: props.startDate,
									format: 'DateTimeObject',
									timeZone: props.siteGroup
										? context.appState.currentSiteGroup?.siteGroup_Timezone
										: context.appState.currentSite?.site_Timezone,
							  })}`
							: ''
					}`,
					context.appState.authState
				),
			])
			setMonthlyReadingCount(monthlyReadingCountReq.data)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props])

	const daysInMonth = props.startDate?.daysInMonth || DateTime.now().daysInMonth || 0

	const currentDayInMonth = React.useMemo(() => {
		if (props.startDate) {
			if (props.startDate < DateTime.now()) {
				if (props.startDate.month == DateTime.now().month && props.startDate.year == DateTime.now().year) {
					return DateTime.now().day
				} else {
					return props.startDate?.daysInMonth || 0
				}
			} else if (props.startDate > DateTime.now()) {
				return 0
			}
		}
		return DateTime.now().day
	}, [props.startDate])

	const centerText =
		monthlyReadingCount && monthlyReadingCount.total > 0
			? `Readings: ${Math.round((monthlyReadingCount.monthlyReadings / monthlyReadingCount.total) * 100)}%`
			: ''
	const centerSubText = `Days: ${Math.round((currentDayInMonth / daysInMonth) * 100)}%`

	return (
		<DoughnutGraphBox
			orientation="vertical"
			pageStatus={pageStatus}
			data={{
				labels: ['Reading', 'No Reading', 'Day in Month', 'Days in Month'],
				datasets: [
					{
						label: 'TMVs',
						data: [
							monthlyReadingCount?.monthlyReadings || 0,
							(monthlyReadingCount?.total || 0) - (monthlyReadingCount?.monthlyReadings || 0),
							0,
							0,
						],
						backgroundColor: [colors.colorStatusGreen, colors.colorStatusLightGrey],
						hoverOffset: 4,
					},
					{
						label: 'Progress',
						data: [0, 0, currentDayInMonth, daysInMonth - currentDayInMonth],
						backgroundColor: [colors.colorStatusBlue, colors.colorStatusLightGrey],
						hoverOffset: 4,
					},
				],
			}}
			title="Monthly Readings Progress"
			subtitle={props.startDate ? `${props.startDate.daysInMonth} DAYS IN ${props.startDate.monthLong?.toUpperCase()} ` : 'LAST 30 DAYS'}
			centerText={centerText}
			centerSubText={centerSubText}
			disableAnimation={props.disableAnimation}
		/>
	)
}

/* While assetClass_Id is not typed as mandatory, it is for this graph. This is because all graphs share the same props */
const TemperatureExceptionsDoughnutGraph = (props: GraphProps) => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [temperatureExceptions, setTemperatureExceptions] = React.useState<TemperatureExceptionCount | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [temperatureExceptionCountRequest] = await Promise.all([
				Request.get<TemperatureExceptionCountResult>(
					`stats/temperatureExceptionCount${
						props.siteGroup
							? `?siteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
							: `?site_Id=${context.appState.currentSite?.site_Id}`
					}${
						props.startDate
							? `&StartDate=${formatOutgoingDateTime({
									dateTime: props.startDate,
									format: 'DateTimeObject',
									timeZone: props.siteGroup
										? context.appState.currentSiteGroup?.siteGroup_Timezone
										: context.appState.currentSite?.site_Timezone,
							  })}`
							: ''
					}${props.assetClass_Id ? `&assetClass=${props.assetClass_Id}` : ''}`,
					context.appState.authState
				),
			])
			setTemperatureExceptions(temperatureExceptionCountRequest.data)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props])

	return (
		<DoughnutGraphBox
			orientation="vertical"
			pageStatus={pageStatus}
			data={{
				labels: ['0', '1 - 2', '3 - 5', '5+'],
				datasets: [
					{
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						label: `${getAssetClassNameFromId(props.assetClass_Id!)}s`,
						data: [
							temperatureExceptions?.category1 || 0,
							temperatureExceptions?.category2 || 0,
							temperatureExceptions?.category3 || 0,
							temperatureExceptions?.category4 || 0,
						],
						backgroundColor: [colors.colorStatusGreen, colors.colorStatusYellow, colors.colorStatusOrange, colors.colorStatusRed],
						hoverOffset: 4,
					},
				],
			}}
			title="Temperature Exceptions"
			subtitle={props.startDate ? `${props.startDate.daysInMonth} DAYS IN ${props.startDate.monthLong?.toUpperCase()} ` : 'LAST 30 DAYS'}
			disableAnimation={props.disableAnimation}
			showLegend={(temperatureExceptions && temperatureExceptions?.total > 0) || false}
		/>
	)
}

/* While assetClass_Id is not typed as mandatory, it is for this graph. This is because all graphs share the same props */
const FlowReadingsDoughnutGraph = (props: GraphProps) => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [flowReadingCount, setFlowReadingCount] = React.useState<FlowReadingCount | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [flowReadingCountReq] = await Promise.all([
				Request.get<FlowReadingCountResult>(
					`stats/${props.flowCountConsecutiveDaysWithoutFlow ? 'monthlyMaxDaysWithoutFlowCount' : 'flowReadingCount'}` +
						(props.siteGroup
							? `?siteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
							: `?site_Id=${context.appState.currentSite?.site_Id}`) +
						(props.assetClass_Id ? `&assetClass=${props.assetClass_Id}` : '') +
						(props.flowCountConsecutiveDaysWithoutFlow && props.startDate
							? `&StartDate=${formatOutgoingDateTime({
									dateTime: props.startDate,
									format: 'DateTimeObject',
							  })}`
							: ''),
					context.appState.authState
				),
			])

			setFlowReadingCount(flowReadingCountReq.data)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props])

	if (!props.setting?.siteSetting_IsEnabled) {
		return null
	}

	return (
		<DoughnutGraphBox
			orientation="vertical"
			pageStatus={pageStatus}
			data={{
				labels: [
					`${props.flowCountConsecutiveDaysWithoutFlow ? '<' : '<='} ${props.setting.siteSetting_Value}`,
					`${props.setting.siteSetting_Value} - ${props.setting.siteSetting_Value * 2}`,
					`${props.setting.siteSetting_Value * 2} - ${props.setting.siteSetting_Value * 4}`,
					`> ${props.setting.siteSetting_Value * 4}`,
				],
				datasets: [
					{
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						label: `${getAssetClassNameFromId(props.assetClass_Id!)}s`,
						data: [
							flowReadingCount?.category1 || 0,
							flowReadingCount?.category2 || 0,
							flowReadingCount?.category3 || 0,
							flowReadingCount?.category4 || 0,
						],
						backgroundColor: [colors.colorStatusGreen, colors.colorStatusYellow, colors.colorStatusOrange, colors.colorStatusRed],
						hoverOffset: 4,
					},
				],
			}}
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			title={`${getAssetClassNameFromId(props.assetClass_Id!)} Flow`}
			subtitle={
				props.flowCountConsecutiveDaysWithoutFlow
					? // back-end uses (today - 1 month) as range if no start date is specified (start date intended to be 1st day of month)
					  ['CONSECUTIVE DAYS WITHOUT FLOW', `${props.startDate ? `IN ${(props.startDate.monthLong || '').toUpperCase()}` : 'IN PREVIOUS MONTH'}`]
					: `${props.setting.siteSetting_Value} DAYS SINCE LAST FLOW`
			}
			disableAnimation={props.disableAnimation}
			showLegend={(flowReadingCount && flowReadingCount?.total > 0) || false}
		/>
	)
}

const MaintenanceDoughnutGraph = (props: Omit<GraphProps, 'startDate'>) => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [maintenanceCount, setMaintenanceCount] = React.useState<MaintenanceCount | null>(null)

	const centerText = maintenanceCount && maintenanceCount.total > 0 ? `${Math.round((1 - maintenanceCount.overdue / maintenanceCount.total) * 100)}%` : ''

	React.useEffect(() => {
		const getData = async () => {
			const [maintenanceCountReq] = await Promise.all([
				Request.get<MaintenanceCountResult>(
					`stats/maintenanceCount${
						props.siteGroup
							? `?siteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
							: `?site_Id=${context.appState.currentSite?.site_Id}`
					}`,
					context.appState.authState
				),
			])
			setMaintenanceCount(maintenanceCountReq.data)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props])

	return (
		<DoughnutGraphBox
			orientation="vertical"
			pageStatus={pageStatus}
			data={{
				labels: ['No Maintenance', '>30 Days', '0-30 Days'],
				datasets: [
					{
						label: 'TMVs and Fixtures',
						data: [
							(maintenanceCount?.total || 0) - (maintenanceCount?.overdue || 0),
							maintenanceCount?.overdueDanger || 0,
							maintenanceCount?.overdueWarning || 0,
						],
						backgroundColor: [colors.colorStatusBlue, colors.colorStatusLightGrey, colors.colorStatusOrange],
						hoverOffset: 4,
					},
				],
			}}
			title="Maintenance"
			subtitle="UP TO DATE"
			centerText={centerText}
			disableAnimation={props.disableAnimation}
		/>
	)
}

const DevicesDoughnutGraph = (props: Omit<GraphProps, 'startDate'>) => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [onlineAssetCount, setOnlineAssetCount] = React.useState<OnlineCount | null>(null)

	const centerText = onlineAssetCount && onlineAssetCount.total > 0 ? `${Math.round((onlineAssetCount.online / onlineAssetCount.total) * 100)}%` : ''

	React.useEffect(() => {
		const getData = async () => {
			const [onlineAssetCountReq] = await Promise.all([
				Request.get<OnlineCountResult>(
					`stats/assetOnlineCount${
						props.siteGroup
							? `?siteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
							: `?site_Id=${context.appState.currentSite?.site_Id}`
					}`,
					context.appState.authState
				),
			])
			setOnlineAssetCount(onlineAssetCountReq.data)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props])

	return (
		<DoughnutGraphBox
			orientation="vertical"
			pageStatus={pageStatus}
			data={{
				labels: ['Online', 'Offline'],
				datasets: [
					{
						label: 'Hub/Transmitter/Network Assets',
						data: [onlineAssetCount?.online || 0, onlineAssetCount?.offline || 0],
						backgroundColor: [colors.colorStatusGreen, colors.colorStatusLightGrey],
						hoverOffset: 4,
					},
				],
			}}
			title="Devices"
			subtitle="ONLINE"
			centerText={centerText}
			disableAnimation={props.disableAnimation}
		/>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	row: {
		minHeight: '386px',
	},
}

export default SiteGroupOverviewGraphs
export { MonthlyReadingsDoughnutGraph, FlowReadingsDoughnutGraph, MaintenanceDoughnutGraph, DevicesDoughnutGraph }
