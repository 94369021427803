import * as React from 'react'
import { AppContext } from '../../App'
import { hasPermission } from '../../utilities/permissions/permission'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../utilities/permissions/permission.d'

interface PermissionsCheckProps {
	object: PermissionModelObject
	action: PermissionModelAction
	context: PermissionModelContext
	children: JSX.Element
}

const PermissionsCheck = (props: PermissionsCheckProps) => {
	const { appState } = React.useContext(AppContext)
	const hasPermissions = hasPermission(props.object, props.action, appState, props.context)
	if (hasPermissions) {
		return props.children
	}
	return null
}

export default PermissionsCheck
