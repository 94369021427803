import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { UserSite } from './UserSite'

interface User {
	user_Id: string
	user_AzureId: string | null
	user_FirstName: string
	user_LastName: string
	user_Email: string
	user_OfflinePassword: string | null

	userRole_Id: string | null
	userRole_Name: string | null
	userSites_Description: string | null

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface UserStub {
	user_Id: string
	user_FirstName: string
	user_LastName: string
}

interface UserPermission {
	systemUserRole_Id: string
	user: User
	userSites: UserSite[]
}

interface UserResult extends Validate.Result {
	users: User[]
}

interface UserPermissionResult extends Validate.Result {
	users: UserPermission[]
}

const defaultUser = (user: Partial<User>): User => ({
	user_Id: user.user_Id || '',
	user_AzureId: user.user_AzureId || null,
	user_FirstName: user.user_FirstName || '',
	user_LastName: user.user_LastName || '',
	user_Email: user.user_Email || '',
	user_OfflinePassword: user.user_OfflinePassword || null,
	userRole_Id: user.userRole_Id || null,
	userRole_Name: user.userRole_Name || null,
	userSites_Description: user.userSites_Description || null,

	created: user.created || defaultCreated({}),
	modified: user.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultUser }
export type { User, UserStub, UserPermission, UserResult, UserPermissionResult }
