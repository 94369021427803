import React from 'react'
import { AppContext } from '../../../App'
import * as msal from '@azure/msal-browser'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { msalInstance, msalConfig } from '../../../config/config'
import Logo from '../../../images/Logo.svg'

import { UserPermissionResult } from '../../../models/User'
import * as Request from '../../../utilities/request'

const AuthComponent = () => {
	const context = React.useContext(AppContext)

	React.useEffect(() => {
		const getUserDetails = async (loginResult: msal.AuthenticationResult) => {
			if (loginResult.accessToken) {
				const tempAuthState = {
					isLoggedIn: true,
					apiToken: loginResult.accessToken,
					user_AzureId: loginResult.account?.localAccountId ?? null,
				}
				const user_AzureId: string = loginResult.account?.localAccountId ?? ''
				const [userResponse] = await Promise.all([
					Request.get<UserPermissionResult>(`user?User_AzureId=${encodeURIComponent(user_AzureId)}`, tempAuthState),
				])
				const user = userResponse.data.users.length > 0 ? userResponse.data.users[0].user : undefined
				if (user) {
					context.setAppState({
						state: 'signedIn',
						data: { accessToken: loginResult.accessToken, userAttributes: user, userSites: userResponse.data.users[0].userSites },
					})
				} else {
					context.setAppState({ state: 'signOut' })
					msalInstance.logoutRedirect()
				}
			}
		}

		const performLogin = async () => {
			if (!context.appState.authState.isLoggedIn) {
				const paramsLogin: msal.RedirectRequest = {
					scopes: msalConfig.scopes,
				}
				try {
					let loginResult: msal.AuthenticationResult | null = null

					// first check cache for tokens - this avoids errors with ctrl+click opening multiple tabs
					const accounts = msalInstance.getAllAccounts()
					if (accounts.length === 1) {
						const silentTokensParams = { account: accounts[0], scopes: msalConfig.scopes }
						loginResult = await msalInstance.acquireTokenSilent(silentTokensParams)
					}
					// this handles refresh on same tab
					if (!loginResult) {
						loginResult = await msalInstance.handleRedirectPromise()
					}
					if (loginResult) {
						await getUserDetails(loginResult)
					} else {
						msalInstance.acquireTokenRedirect(paramsLogin) // otherwise fall through to initial auth process
					}
				} catch (e) {
					console.error(e)
					// Log the user out to remove cache items
					msalInstance.logoutRedirect()
				}
			}
		}

		performLogin()
	}, [context])

	return context.appState.authState.isLoggedIn ? (
		<div style={{ textAlign: 'center', display: 'none' }}>Logged In</div>
	) : (
		<div style={{ overflow: 'hidden' }}>
			<Row className="page-header">
				<Link to="/" className="page-header-title">
					<img src={Logo} alt={'Enware Smart FLow Logo'} />
				</Link>
			</Row>

			<Row style={{ textAlign: 'center', marginTop: '5%' }}>
				<Col>
					<Row>
						<Col>Loading...</Col>
					</Row>
				</Col>
			</Row>
		</div>
	)
}
export { AuthComponent }
