import React, { useContext } from 'react'
import { AppContext } from '../../../App'

import * as Request from '../../../utilities/request'
import { PageStatus } from '../../../types/PageStatus'
import { Button, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { taskStatus } from '../../../constants/taskStatus'
import { TaskAsset, TaskAssetResult } from '../../../models/TaskAsset'
import { Listing } from '../Listing/Listing'
import { severityDropdownValues } from '../../../constants/severity'
import { formatIncomingDateTime } from '../../../utilities/formatDate'

const UpcomingTasks = () => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [taskAssets, setTaskAssets] = React.useState<TaskAsset[] | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [taskAssetsReq] = await Promise.all([
				Request.get<TaskAssetResult>(`taskAsset?site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
			])
			setTaskAssets(
				taskAssetsReq.data.taskAssets.filter((ta) => ta.taskAsset_Task.taskStatus_Id === taskStatus['To Do'].id) // Tasks that are to do
			)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context.appState.currentSite])

	return (
		<>
			<Row className="mt-3">
				<Col sm="auto" className="center-flex">
					<h2 className="mb-0">Tasks To-Do</h2>
				</Col>
				<Col />
				<Col sm="auto" className="center-flex">
					<Link to={`/tasks`}>
						<Button>View All Tasks</Button>
					</Link>
				</Col>
			</Row>
			<Row>
				<Col>
					<Listing
						name="Task"
						namePlural="Tasks"
						list={taskAssets || []}
						getIDFunc={(item) => item.taskAsset_Id}
						selectedActions={[]}
						headerButtons={[]}
						columns={[
							{
								value: (item) => item.taskAsset_Task.severity_Id,
								render: (item) => <>{item.taskAsset_Task.severity_Name}</>,
								showHeader: true,
								headerText: 'Severity',
								sortColumnName: 'taskAsset_Task.severity_Id',
								filterType: 'dropdown',
								filterOptions: {
									columnName: 'taskAsset_Task.severity_Id',
									options: severityDropdownValues.map((severity) => ({
										value: severity.value,
										text: severity.label,
									})),
								},
							},
							{
								value: (item) => item.taskAsset_Task.task_Name,
								render: (item) => <>{item.taskAsset_Task.task_Name}</>,
								showHeader: true,
								headerText: 'Task Name',
								sortColumnName: 'taskAsset_Task.task_Name',
								filterType: 'string',
								filterOptions: {
									columnName: 'taskAsset_Task.task_Name',
								},
							},
							{
								value: (item) => item.taskAsset_Task.created.create_Ts,
								render: (item) => (
									<>
										{formatIncomingDateTime({
											dateTime: item.taskAsset_Task.created.create_Ts,
											format: 'DateAndTimeAndTimezoneContext',
											timeZone: context.appState.currentSite?.site_Timezone,
										})}
									</>
								),
								showHeader: true,
								headerText: 'Created',
								sortColumnName: 'taskAsset_Task.created.create_Ts',
								filterType: 'string',
								filterOptions: {
									columnName: 'taskAsset_Task.created.create_Ts',
								},
							},
							{
								value: (item) => item.taskAsset_Task.task_Note,
								render: (item) => <>{item.taskAsset_Task.task_Note}</>,
								showHeader: true,
								headerText: 'Note',
								sortColumnName: 'taskAsset_Task.task_Note',
								filterType: 'string',
								filterOptions: {
									columnName: 'taskAsset_Task.task_Note',
								},
							},
							{
								value: (item) => `${item.taskAsset_Task.created.create_UserFirstName} ${item.taskAsset_Task.created.create_UserLastName}`,
								render: (item) => (
									<>{`${item.taskAsset_Task.created.create_UserFirstName} ${item.taskAsset_Task.created.create_UserLastName}`}</>
								),
								showHeader: true,
								headerText: 'Created By',
								sortColumnName: 'taskAsset_Task.created.create_UserFirstName',
								filterType: 'string',
								filterOptions: {
									columnName: 'taskAsset_Task.created.create_UserFirstName',
								},
							},
							{
								value: (item) => item.taskAsset_Asset.asset_Name || '',
								render: (item) => (
									<Link
										to={{
											pathname: `/asset/${item.asset_Id}` /* TODO: Asset Navigation */,
										}}
									>
										{item.taskAsset_Asset.asset_Name}
									</Link>
								),
								showHeader: true,
								headerText: 'Asset Name',
								sortColumnName: 'taskAsset_Asset.asset_Name ',
								filterType: 'string',
								filterOptions: {
									columnName: 'taskAsset_Asset.asset_Name ',
								},
							},
							{
								value: (item) => item.task_Id,
								render: () => <Button>View</Button>, // TODO: Task Navigation
								showHeader: true,
								headerText: 'View',
								centerColumn: true,
							},
						]}
						defaultSort={{ column: 'taskAsset_Task.created.create_Ts', order: 'ASC' }}
						isLoading={pageStatus !== 'Ready'}
					/>
				</Col>
			</Row>
		</>
	)
}

export { UpcomingTasks }
