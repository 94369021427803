import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { Formik, FormikProps } from 'formik'
import { Link } from 'react-router-dom'

import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'

import { Loading } from '../Loading/Loading'
import { FormSelect, SelectOption } from '../Form/Select'
import PermissionsCheck from '../../Permissions/PermissionsCheck'

import { PageStatus } from '../../../types/PageStatus'
import { MessageAction } from '../Messages/Message'
import { AssetList, AssetListResult } from '../../../models/Asset'
import { AssetGroup, AssetGroupResult, defaultAssetGroup } from '../../../models/AssetGroup'
import { defaultCreated, defaultModified } from '../../../models/History'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../utilities/permissions/permission.d'

import search from '../../../images/icons/search.svg'
import { XCircle } from 'react-feather'
import { groupType } from '../../../constants/groupType'

interface GroupAssetsProps {
	pageStatus: PageStatus
	groupId: string
	groupTypeId: string
	assetClassId: string
	saveAssetsRef: React.RefObject<FormikProps<AssetGroupData>>
	setMessages: (messages: MessageAction) => void
	setPageStatus: React.Dispatch<React.SetStateAction<PageStatus>>
}

interface AssetGroupData {
	assetGroups: AssetGroup[]
	assets: AssetList[]
}

const GroupAssets = (props: GroupAssetsProps) => {
	const context = React.useContext(AppContext)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [assets, setAssets] = React.useState<AssetList[] | null>(null)
	const [assetGroups, setAssetGroups] = React.useState<AssetGroup[] | null>(null)

	const created = defaultCreated({ create_UserId: context.appState.userAttributes.user_Id })
	const modified = defaultModified({ modified_UserId: context.appState.userAttributes.user_Id })

	const getAssets = async () => {
		const assetsReq = await Request.get<AssetListResult>(
			`group/assetsNotInGroupType?Site_Id=${context.appState.currentSite?.site_Id}&Group_Id=${props.groupId}&GroupType_Id=${props.groupTypeId}`,
			context.appState.authState
		)
		const assetsFilteredByAssetClass = props.assetClassId
			? assetsReq.data.assets.filter((asset) => asset.assetClass_Id == props.assetClassId)
			: assetsReq.data.assets
		setAssets(assetsFilteredByAssetClass)
	}

	const getAssetGroups = async () => {
		const assetGroupsReq = await Request.get<AssetGroupResult>(`assetGroup?Group_Id=${props.groupId}`, context.appState.authState)
		setAssetGroups(assetGroupsReq.data.assetGroups)
	}

	React.useEffect(() => {
		const getData = async () => {
			await Promise.all([getAssets(), getAssetGroups()])
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn && context.appState.currentSite) {
			getData()
		}
	}, [context.appState.currentSite])

	const handleSave = async (values: { assetGroups: AssetGroup[]; assets: AssetList[] }) => {
		setPageStatus('Submitting')
		const removedAssets =
			assetGroups?.filter((assetGroup) => !values.assetGroups.some((editedAssetGroup) => editedAssetGroup.assetGroup_Id === assetGroup.assetGroup_Id)) ||
			[]
		const addedAssets = values.assetGroups.filter(
			(editedAssetGroup) => !assetGroups?.some((assetGroup) => assetGroup.assetGroup_Id === editedAssetGroup.assetGroup_Id)
		)
		await Promise.all([
			...removedAssets.map((assetGroup) => Request.del<AssetGroupResult>(`assetGroup?Id=${assetGroup.assetGroup_Id}`, context.appState.authState)),
			...addedAssets.map((assetGroup) => Request.post<AssetGroupResult>('assetGroup', assetGroup, context.appState.authState)),
		])
		setAssetGroups((prev) => [
			...(prev || []).filter((existing) => values.assetGroups.some((editedAssetGroup) => editedAssetGroup.assetGroup_Id === existing.assetGroup_Id)),
			...addedAssets,
		])
		setPageStatus('Ready')
	}

	return (
		<Card style={styles.card} className="site-card">
			{assetGroups && assets ? (
				<Formik initialValues={{ assetGroups, assets }} onSubmit={handleSave} enableReinitialize innerRef={props.saveAssetsRef}>
					{({ values, setFieldValue }) => (
						<>
							<Row className="generic-card-list-heading-row">
								<Col sm="auto" style={styles.cellGrow}>
									<span className="dashboard-card-titles">Assets</span>
								</Col>
								{props.pageStatus !== 'Editing' && props.groupTypeId === groupType.Custom.id && (
									<PermissionsCheck
										object={PermissionModelObject.Group}
										action={PermissionModelAction.PUT}
										context={PermissionModelContext.Site}
									>
										<Col sm="auto">
											<Button onClick={() => props.setPageStatus('Editing')}>EDIT GROUP</Button>
										</Col>
									</PermissionsCheck>
								)}
								{props.pageStatus === 'Editing' && (
									<PermissionsCheck
										object={PermissionModelObject.Group}
										action={PermissionModelAction.PUT}
										context={PermissionModelContext.Site}
									>
										<AssetSearch
											pageStatus={pageStatus}
											assetGroups={values.assetGroups}
											assets={values.assets}
											handleAddAsset={(ids) =>
												setFieldValue('assetGroups', [
													...values.assetGroups,
													...ids.map((id) =>
														defaultAssetGroup({
															assetGroup_Id: uuidv4(),
															group_Id: props.groupId || '',
															asset_Id: id,
															created,
															modified,
														})
													),
												])
											}
										/>
									</PermissionsCheck>
								)}
							</Row>
							<AssetGroups
								isReadOnly={props.pageStatus !== 'Editing'}
								assetGroups={values.assetGroups}
								assets={values.assets}
								handleRemoveAsset={(id) =>
									setFieldValue(
										'assetGroups',
										values.assetGroups.filter((assetGroup) => assetGroup.assetGroup_Id !== id)
									)
								}
							/>
						</>
					)}
				</Formik>
			) : props.pageStatus === 'Loading' ? (
				<Loading show={true} />
			) : null}
		</Card>
	)
}

interface AssetSearchProps {
	pageStatus: PageStatus
	assetGroups: AssetGroup[]
	assets: AssetList[]
	handleAddAsset: (assetGroupIds: string[]) => void
}

const AssetSearch = (props: AssetSearchProps) => {
	const [selectedAssetId, setSelectedAssetId] = React.useState<string | null>(null)

	const handleAdd = () => {
		if (selectedAssetId) {
			props.handleAddAsset([selectedAssetId])
			setSelectedAssetId(null)
		}
	}

	const assetOptions = React.useMemo(() => {
		return props.assets
			.filter((asset) => !props.assetGroups.some((assetGroup) => assetGroup.asset_Id === asset.asset_Id))
			.map((asset) => ({ label: asset.asset_Name || '', value: asset.asset_Id }))
	}, [props.assets, props.assetGroups])

	return (
		<>
			<Col sm="3">
				<FormSelect
					name={'asset-dropdown'}
					options={assetOptions}
					value={selectedAssetId}
					onChange={(e) => setSelectedAssetId(e ? (e as SelectOption<string>).value : null)}
					placeholder={
						<div style={styles.placeholder}>
							<img src={search} alt="Search icon" style={styles.icon} />
							Asset Search
						</div>
					}
				/>
			</Col>
			<Col sm="auto" className="center-flex">
				<Button disabled={props.pageStatus !== 'Ready' || !selectedAssetId} onClick={handleAdd}>
					ADD
				</Button>
			</Col>
			<Col sm="auto" className="center-flex">
				<Button
					disabled={props.pageStatus !== 'Ready' || assetOptions.length === 0}
					onClick={() => props.handleAddAsset(assetOptions.map((opt) => opt.value))}
				>
					ADD ALL
				</Button>
			</Col>
		</>
	)
}

interface AssetGroupsProps {
	isReadOnly: boolean
	assetGroups: AssetGroup[]
	assets: AssetList[]
	handleRemoveAsset: (assetGroupId: string) => void
}

const AssetGroups = (props: AssetGroupsProps) => {
	const assetGroupDetails = React.useMemo(() => {
		return props.assetGroups.map((assetGroup) => {
			const asset = props.assets.find((asset) => asset.asset_Id === assetGroup.asset_Id)
			return {
				...assetGroup,
				asset_Name: asset?.asset_Name || '',
			}
		})
	}, [props.assets, props.assetGroups])

	return (
		<Row>
			{assetGroupDetails.map((assetGroup) => (
				<AssetGroupCard
					key={assetGroup.assetGroup_Id}
					isReadOnly={props.isReadOnly}
					assetGroup={assetGroup}
					handleRemoveAsset={props.handleRemoveAsset}
				/>
			))}
		</Row>
	)
}

interface AssetGroupCardProps {
	isReadOnly: boolean
	assetGroup: AssetGroup & { asset_Name: string }
	handleRemoveAsset: (assetGroupId: string) => void
}

const AssetGroupCard = (props: AssetGroupCardProps) => {
	return (
		<Col sm={2}>
			<Card style={styles.assetCard}>
				<Link to={`/asset/${props.assetGroup.asset_Id}`}>{props.assetGroup.asset_Name}</Link>
				<XCircle
					fill={'black'}
					stroke={'white'}
					size={30}
					onClick={() => !props.isReadOnly && props.handleRemoveAsset(props.assetGroup.assetGroup_Id)}
					style={!props.isReadOnly ? styles.button : {}}
				/>
			</Card>
		</Col>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	card: {
		minHeight: '160px',
	},
	assetCard: {
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '10px 20px',
	},
	cellGrow: {
		flex: '1 1 auto',
	},
	placeholder: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		padding: '5px 10px 5px 5px',
	},
	button: {
		cursor: 'pointer',
	},
}

export { GroupAssets }
export type { AssetGroupData }
