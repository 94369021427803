import React, { useContext } from 'react'
import { Card, Col, Dropdown, Row } from 'react-bootstrap'
import { AppContext } from '../../../App'
import { Site } from '../../../models/Site'
import * as Request from '../../../utilities/request'
import { AssetTypeCount } from '../../../models/Stats'
import { DateTime } from 'luxon'
import { Loading } from '../Loading/Loading'
import ellipsis from '../../../images/icons/options.svg'
import online from '../../../images/icons/online.svg'
import offline from '../../../images/icons/offline.svg'
import './SiteSummaryCard.css'
import { Link, useNavigate } from 'react-router-dom'
import { formatIncomingDateTime } from '../../../utilities/formatDate'

interface SiteSummaryCardProps {
	site: Site
}

const SiteSummaryCard = (props: SiteSummaryCardProps) => {
	const context = useContext(AppContext)
	const navigate = useNavigate()

	const now = DateTime.now()
	const isOnline = props.site.site_IsAliveTs
		? now.diff(DateTime.fromISO(props.site.site_IsAliveTs, { zone: 'utc', setZone: true }), 'minutes').minutes < 5
		: false
	const lastReadingString = props.site.site_IsAliveTs
		? formatIncomingDateTime({ dateTime: props.site.site_IsAliveTs, format: 'TimeAgo' })
		: 'No readings ever received from this site'

	const [assetCount, setAssetCount] = React.useState<AssetTypeCount | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [assetCountReq] = await Promise.all([
				Request.get<AssetTypeCount>(`stats/assetTypeCount?site_Id=${props.site.site_Id}`, context.appState.authState),
			])
			setAssetCount(assetCountReq.data)
		}
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [props])

	return (
		<Card className="site-summary-card p-0">
			<Card.Body className="p-0">
				<Row className="h5 px-3 pt-3">
					<Col className="fw-bold">
						<Link
							className="site-summary-card-link"
							to={`/site`}
							onClick={() =>
								context.setAppState({
									state: 'setCurrentSite',
									data: { site: props.site },
								})
							}
						>
							{props.site.site_Name}
						</Link>
					</Col>
					<Col className={'center-flex'} sm="auto">
						<Dropdown>
							<Dropdown.Toggle as="img" src={ellipsis} alt="Site options" className="site-summary-card-dropdown-toggle" />
							<Dropdown.Menu>
								<Dropdown.Item
									onClick={() => {
										context.setAppState({
											state: 'setCurrentSite',
											data: { site: props.site },
										})
										navigate('siteSettings')
									}}
								>
									Edit Site
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Row>
				<Row className="px-3 mt-3" style={styles.status}>
					<Col>
						<Row>
							<Col>{isOnline ? 'ONLINE' : 'OFFLINE'}</Col>
						</Row>
						<Row>
							<Col className="version-small-text">
								{props.site.site_Version ? `${props.site.site_Version} - ` : ''}
								{lastReadingString}
							</Col>
						</Row>
					</Col>
					<Col className={'center-flex'} sm="auto">
						{isOnline ? <img src={online} alt={'Site is online'} /> : <img src={offline} alt={'Site is offline'} />}
					</Col>
				</Row>
				<Row className="px-4">
					<Col>
						{/* <DoughnutGraphBox
							orientation="horizontal"
							pageStatus={pageStatus}
							data={{
								labels: ['Pass', 'Fail'],
								datasets: [
									{
										label: 'TMVs and Fixtures',
										data: [tmvFlow?.pass || 0, tmvFlow?.fail || 0],
										backgroundColor: [colors.colorStatusGreen, colors.colorStatusLightGrey],
										hoverOffset: 4,
									},
								],
							}}
							title="TMV Flow"
							subtitle="LAST 7 DAYS"
							centerText={centerText}
						/> */}
					</Col>
				</Row>
			</Card.Body>
			<Card.Footer className="bg-white py-0">
				<Row style={styles.footer}>
					{assetCount ? (
						<>
							<Col className="py-2 border-end">
								<Row>
									<Col className={'center-flex'}>TMVs</Col>
								</Row>
								<Row>
									<Col className={'center-flex'}>{assetCount.tmv}</Col>
								</Row>
							</Col>
							<Col className="py-2 border-end">
								<Row>
									<Col className={'center-flex'}>Fixtures</Col>
								</Row>
								<Row>
									<Col className={'center-flex'}>{assetCount.fixture}</Col>
								</Row>
							</Col>
							<Col className="py-2 border-end">
								<Row>
									<Col className={'center-flex'}>Hubs</Col>
								</Row>
								<Row>
									<Col className={'center-flex'}>{assetCount.hub}</Col>
								</Row>
							</Col>
							<Col className="py-2">
								<Row>
									<Col className={'center-flex'}>Transmitters</Col>
								</Row>
								<Row>
									<Col className={'center-flex'}>{assetCount.transmitter}</Col>
								</Row>
							</Col>
						</>
					) : (
						<Col className={'center-flex'}>
							<Loading show />
						</Col>
					)}
				</Row>
			</Card.Footer>
		</Card>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	footer: {
		minHeight: '64px',
	},
	status: {
		marginBottom: '1rem',
	},
}

export default SiteSummaryCard
