import React from 'react'
import { TemperatureException } from '../../../models/Report'
import { Button, Modal } from 'react-bootstrap'
import TemperatureExceptionEventDetail from './TemperatureExceptionEventDetail'
import { AddTempExceptionDetailEventToReportFunction } from './FleetHealthReport'

interface TemperatureExceptionDetailModalProps {
	temperatureException?: TemperatureException
	showReadingDetail?: boolean
	setTemperatureException: React.Dispatch<React.SetStateAction<TemperatureException | undefined>>
	addToFleetHealthReportFunction?: AddTempExceptionDetailEventToReportFunction
}

const TemperatureExceptionDetailModal = (props: TemperatureExceptionDetailModalProps) => {
	return (
		<Modal
			show={props.temperatureException !== undefined}
			onHide={() => {
				props.setTemperatureException(undefined)
			}}
			size="xl"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Temperature Exception Detail</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{props.temperatureException && (
					<TemperatureExceptionEventDetail temperatureException={props.temperatureException} showReadingDetail={props.showReadingDetail} />
				)}
			</Modal.Body>
			{props.addToFleetHealthReportFunction && (
				<Modal.Footer>
					<Button
						onClick={() => {
							if (props.addToFleetHealthReportFunction && props.temperatureException) {
								props.addToFleetHealthReportFunction(props.temperatureException)
							}
							props.setTemperatureException(undefined)
						}}
					>
						Add to Report
					</Button>
				</Modal.Footer>
			)}
		</Modal>
	)
}

export default TemperatureExceptionDetailModal
