import * as Validate from '../utilities/validate'

interface Reading {
	reading_Id: string
	reading_Ts: string
	reading_Data: number
	readingType_Id: string
	asset_Id: string
	site_Id: string
}

interface ReadingResult extends Validate.Result {
	readings: Reading[]
}

const defaultReading = (reading: Partial<Reading>): Reading => ({
	reading_Id: reading.reading_Id || '',
	reading_Ts: reading.reading_Ts || '',
	reading_Data: reading.reading_Data || 0,
	readingType_Id: reading.readingType_Id || '',
	asset_Id: reading.asset_Id || '',
	site_Id: reading.site_Id || '',
})

export const ReadingApiGroupType = {
	TEMP: 'temp',
	FLOW: 'flow',
} as const
export type ReadingApiGroupType = (typeof ReadingApiGroupType)[keyof typeof ReadingApiGroupType]

export const ReadingApiWindow = {
	MONTH: { value: 'month', label: 'Last Month' },
	WEEK: { value: 'week', label: 'Last Week' },
	DAY: { value: 'day', label: 'Last 24 Hours' },
	HOUR: { value: 'hour', label: 'Last Hour' },
	FIVE_MINUTE: { value: 'fiveMinute', label: 'Last 5 Minutes' },
	CUSTOM: { value: 'custom', label: 'Custom' },
} as const
export type ReadingApiWindow = (typeof ReadingApiWindow)[keyof typeof ReadingApiWindow]
export const ReadingApiWindowSelectOptions = Object.values(ReadingApiWindow)

export { defaultReading }
export type { Reading, ReadingResult }
