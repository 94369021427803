import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { Task, defaultTask } from './Task'
import { AssetStub, defaultAsset } from './Asset'

interface TaskAsset {
	taskAsset_Id: string
	task_Id: string
	taskAsset_Task: Task
	asset_Id: string | null
	taskAsset_Asset: AssetStub

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface TaskAssetResult extends Validate.Result {
	taskAssets: TaskAsset[]
}

const defaultTaskAsset = (taskAsset: Partial<TaskAsset>): TaskAsset => ({
	taskAsset_Id: taskAsset.taskAsset_Id || '',
	task_Id: taskAsset.task_Id || '',
	taskAsset_Task: taskAsset.taskAsset_Task || defaultTask({}),
	asset_Id: taskAsset.asset_Id || null,
	taskAsset_Asset: taskAsset.taskAsset_Asset || defaultAsset({}),

	created: taskAsset.created || defaultCreated({}),
	modified: taskAsset.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultTaskAsset }
export type { TaskAsset, TaskAssetResult }
