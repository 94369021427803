import React, { SetStateAction } from 'react'
import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { Card, Row, Col } from 'react-bootstrap'
import { Line } from '../Line/Line'
import { Loading } from '../Loading/Loading'
import { SFM_TFP_Hub } from '../ChannelMaps/SFM_TFP_Hub'
import { PageStatus } from '../../../types/PageStatus'
import { Asset, AssetList, AssetResult } from '../../../models/Asset'
import { ChannelMap, ChannelMapResult } from '../../../models/ChannelMap'
import { assetType } from '../../../constants/assetType'
import { ChannelMappingHubProps } from '../ChannelMaps/ChannelMaps'
import { assetClass } from '../../../constants/assetClass'
import { SFM_Standard_Hub } from '../ChannelMaps/SFM_Standard_Hub'
import { AxiosResponse } from 'axios'
import { RF_Multi_Transmitter } from '../ChannelMaps/RF_Multi_Transmitter'
import { RF_Compact_Transmitter } from '../ChannelMaps/RF_Compact_Transmitter'

interface ChannelMappingProps {
	asset: Asset
	editMode: boolean
	handleChange?: (field: string, value: unknown) => void
	setValues?: (values: SetStateAction<{ asset: Asset }>, shouldValidate?: boolean | undefined) => void
}

const ChannelMapping = (props: ChannelMappingProps) => {
	const context = React.useContext(AppContext)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [channelMaps, setChannelMaps] = React.useState<ChannelMap[]>([])
	const [assetOptions, setAssetOptions] = React.useState<AssetList[]>([])

	React.useEffect(() => {
		const getData = async () => {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const [channelMapReq, assetServicedReq] = await Promise.all([
				Request.get<ChannelMapResult>(`channelMap?ChannelMap_HubAssetId=${props.asset.asset_Id}`, context.appState.authState),
				props.editMode
					? Promise.all(
							props.asset.asset_Serviced
								.filter((at) => at.asset.assetClass_Id === assetClass.Tmv.id)
								.map((asset) => Request.get<AssetResult>(`asset?Id=${asset.asset.asset_Id}`, context.appState.authState))
					  )
					: Promise.resolve([{ data: { assets: [] as Asset[] } } as AxiosResponse<AssetResult>]),
			])
			if (channelMapReq.status === 200) {
				setChannelMaps(channelMapReq.data.channelMaps)

				if (props.setValues) {
					props.setValues({ asset: { ...props.asset, channelMap: channelMapReq.data.channelMaps } })
				}

				if (props.editMode) {
					// Asset Options are restricted to only TMV and Fixture Assets that are listed as being monitored by the current hub
					const assetOptions: AssetList[] = props.asset.asset_Serviced
						.filter((at) => at.asset.assetClass_Id === assetClass.Tmv.id || at.asset.assetClass_Id === assetClass.Fixture.id)
						.map((at) => at.asset)
					setAssetOptions(assetOptions)
					setPageStatus('Editing')
				} else {
					setPageStatus('Ready')
				}
			} else {
				setPageStatus('Error')
			}
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [props.asset.asset_Id, props.editMode, props.asset.asset_Serviced])

	const ChannelComponent = channelComponentMapping[props.asset.assetType_Id].component

	return (
		<Card style={styles.card}>
			<Row style={styles.row}>
				<Col sm="auto" style={styles.cellGrow}>
					<span className="dashboard-card-titles">Channel Mapping</span>
				</Col>
			</Row>
			<Line />
			{pageStatus === 'Ready' || pageStatus === 'Editing' ? (
				<div>
					<ChannelComponent
						asset={props.asset}
						assetOptions={assetOptions}
						channelMaps={props.asset.channelMap || channelMaps}
						pageStatus={pageStatus}
						setPageStatus={setPageStatus}
						handleChange={props.handleChange}
					/>
				</div>
			) : pageStatus === 'Loading' ? (
				<Loading show={true} />
			) : null}
		</Card>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	card: {
		marginTop: '20px',
		minHeight: '745px',
	},
	row: {
		marginTop: '20px',
		marginBottom: '20px',
	},
	cellGrow: {
		flex: '1 1 auto',
	},
	cell: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '48px',
	},
	cell50: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '48px',
		flexBasis: '50%',
	},
}

// TODO: Each Hub Type will need its own channel mapping component as we support more hubs
const channelComponentMapping: { [key: string]: { component: (props: ChannelMappingHubProps) => JSX.Element } } = {
	[assetType['SFM TFP Hub'].id]: {
		component: SFM_TFP_Hub,
	},
	[assetType['SFM Standard Hub'].id]: {
		component: SFM_Standard_Hub,
	},
	[assetType['RF Multi Transmitter'].id]: {
		component: RF_Multi_Transmitter,
	},
	[assetType['RF Compact Transmitter'].id]: {
		component: RF_Compact_Transmitter,
	},
}

export { ChannelMapping }
export type { ChannelMappingHubProps }
