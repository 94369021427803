import { AppState } from '../../../App.d'
import { ExportUserFeedback } from './SiteExporter'
import { SiteResult } from '../../../models/Site'

import { AssetMakeResult } from '../../../models/AssetMake'
import { AssetModelResult } from '../../../models/AssetModel'
import { LocationBuildingResult } from '../../../models/LocationBuilding'
import { LocationFloorResult } from '../../../models/LocationFloor'
import { LocationAreaResult } from '../../../models/LocationArea'
import { LocationRoomResult } from '../../../models/LocationRoom'
import { AssetResult } from '../../../models/Asset'
import { AssetTreeResult } from '../../../models/AssetTree'
import { ChannelMapResult } from '../../../models/ChannelMap'
import { GroupResult } from '../../../models/Group'
import { AssetGroupResult } from '../../../models/AssetGroup'
import { EventSettingResult } from '../../../models/EventSetting'
import { BacnetTemperatureAlarmSettingsResult } from '../../../models/BacnetTemperatureAlarmSetting'
import { BacnetResult } from '../../../models/Bacnet'
import { MaintenanceResult } from '../../../models/Maintenance'

import * as Request from '../../../utilities/request'
import { toProperCase } from '../../../utilities/strings'
import { SiteSettingResult } from '../../../models/SiteSetting'

export const exportHandler = async <T,>(
	appState: AppState,
	updateUserFeedback: React.Dispatch<React.SetStateAction<ExportUserFeedback>>,
	object: string,
	fetchData: (appState: AppState) => Promise<T[]>
) => {
	const label = object
		.split(/(?=[A-Z])/)
		.map((str) => toProperCase(str))
		.join(' ')
	try {
		updateUserFeedback((prev) => ({ ...prev, [object]: `Fetching ${label}...` }))
		const result = await fetchData(appState)
		updateUserFeedback((prev) => ({ ...prev, [object]: `${result.length} ${label} found.` }))
		return result
	} catch (e) {
		console.log('site error', e)
		updateUserFeedback((prev) => ({ ...prev, [object]: `Error getting ${label}: ${e}` }))
		// stop as soon as we get an error, it could cause imports further down the line to fail anyway
		throw e
	}
}

export const getSites = async (appState: AppState) => {
	const siteRes = await Request.get<SiteResult>(`site/export?Id=${appState.currentSite?.site_Id}`, appState.authState)
	return siteRes.data.sites
}

export const getAssetMakes = async (appState: AppState) => {
	const assetMakeRes = await Request.get<AssetMakeResult>('assetMake/export', appState.authState)
	return assetMakeRes.data.assetMakes
}

export const getAssetModels = async (appState: AppState) => {
	const assetModelRes = await Request.get<AssetModelResult>('assetModel/export', appState.authState)
	return assetModelRes.data.assetModels
}

export const getLocationBuildings = async (appState: AppState) => {
	const locationBuildingRes = await Request.get<LocationBuildingResult>(
		`locationBuilding/export?Site_Id=${appState.currentSite?.site_Id}`,
		appState.authState
	)
	return locationBuildingRes.data.locationBuildings
}

export const getLocationFloors = async (appState: AppState) => {
	const locationFloorRes = await Request.get<LocationFloorResult>(`locationFloor/export?Site_Id=${appState.currentSite?.site_Id}`, appState.authState)
	return locationFloorRes.data.locationFloors
}

export const getLocationAreas = async (appState: AppState) => {
	const locationAreaRes = await Request.get<LocationAreaResult>(`locationArea/export?Site_Id=${appState.currentSite?.site_Id}`, appState.authState)
	return locationAreaRes.data.locationAreas
}

export const getLocationRooms = async (appState: AppState) => {
	const locationRoomRes = await Request.get<LocationRoomResult>(`locationRoom/export?Site_Id=${appState.currentSite?.site_Id}`, appState.authState)
	return locationRoomRes.data.locationRooms
}

export const getAssets = async (appState: AppState) => {
	const assetRes = await Request.get<AssetResult>(`asset/export?Site_Id=${appState.currentSite?.site_Id}`, appState.authState)
	return assetRes.data.assets
}

export const getAssetTrees = async (appState: AppState) => {
	const assetTreeRes = await Request.get<AssetTreeResult>(`assetTree/export?Site_Id=${appState.currentSite?.site_Id}`, appState.authState)
	return assetTreeRes.data.assetTrees
}

export const getChannelMaps = async (appState: AppState) => {
	const channelMapRes = await Request.get<ChannelMapResult>(`channelMap/export?Site_Id=${appState.currentSite?.site_Id}`, appState.authState)
	return channelMapRes.data.channelMaps
}

export const getGroups = async (appState: AppState) => {
	const groupRes = await Request.get<GroupResult>(`group/export?Site_Id=${appState.currentSite?.site_Id}`, appState.authState)
	return groupRes.data.groups
}

export const getAssetGroups = async (appState: AppState) => {
	const assetGroupRes = await Request.get<AssetGroupResult>(`assetGroup/export?Site_Id=${appState.currentSite?.site_Id}`, appState.authState)
	return assetGroupRes.data.assetGroups
}

export const getEventSettings = async (appState: AppState) => {
	const eventSettingRes = await Request.get<EventSettingResult>(`eventSetting/export?Site_Id=${appState.currentSite?.site_Id}`, appState.authState)
	return eventSettingRes.data.eventSettings
}

export const getSiteSettings = async (appState: AppState) => {
	const siteSettingRes = await Request.get<SiteSettingResult>(`siteSetting/export?Site_Id=${appState.currentSite?.site_Id}`, appState.authState)
	return siteSettingRes.data.siteSettings
}

export const getBacnetTemperatureAlarmSettings = async (appState: AppState) => {
	const bacnetTemperatureAlarmSettingRes = await Request.get<BacnetTemperatureAlarmSettingsResult>(
		`bacnetTemperatureAlarmSetting/export?Site_Id=${appState.currentSite?.site_Id}`,
		appState.authState
	)
	return bacnetTemperatureAlarmSettingRes.data.bacnetTemperatureAlarmSettings
}

export const getBacnets = async (appState: AppState) => {
	const bacnetRes = await Request.get<BacnetResult>(`bacnet/export?Site_Id=${appState.currentSite?.site_Id}`, appState.authState)
	return bacnetRes.data.bacnets
}

export const getMaintenances = async (appState: AppState) => {
	const maintenanceRes = await Request.get<MaintenanceResult>(`maintenance/export?Site_Id=${appState.currentSite?.site_Id}`, appState.authState)
	return maintenanceRes.data.maintenances
}
