import React /* , { useContext }  */ from 'react'
// import { AppContext } from '../../../App'
import './EventNotificationsIcon.css'

// import * as Request from '../../../utilities/request'
// import { PageStatus } from '../../../types/PageStatus'
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import alertIcon from '../../../images/icons/bell-white.svg'
// import { TaskOrEventCount, TaskOrEventCountResult } from '../../../models/Stats'

const EventNotificationsIcon = () => {
	/* const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [eventCount, setEventCount] = React.useState<TaskOrEventCount>({
		total: 0,
		critical: 0,
		high: 0,
		medium: 0,
		low: 0,
		info: 0,
	})

	React.useEffect(() => {
		const getData = async () => {
			const [eventCountReq] = await Promise.all([Request.get<TaskOrEventCountResult>(`stats/taskCount`, context.appState.authState)])
			setEventCount(eventCountReq.data)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context.appState.authState]) */

	return (
		<OverlayTrigger placement="left" overlay={<Tooltip id={`toolTip_tasks`}>Tasks</Tooltip>}>
			<Link to="/taskDashboard">
				<Row>
					<Col className="event-div">
						<img src={alertIcon} alt={'Tasks Icon'} />
						{/* TODO: make shiny like designs */}
						{/* {pageStatus === 'Ready' && eventCount.critical > 0 && (
						<div className={`alert-count${eventCount.critical < 10 ? ' single' : eventCount.critical < 100 ? ' double' : ' max'}`}>
							{eventCount.critical < 100 ? eventCount.critical : '99+'}
						</div>
					)} */}
					</Col>
				</Row>
			</Link>
		</OverlayTrigger>
	)
}

export { EventNotificationsIcon }
