// From https://gist.github.com/elm-/d74de2284f65dc815b28f8933dcb829a

import { ChartType, Plugin } from 'chart.js/auto'

interface DoughnutCenterTextOptions {
	text: string
	subText: string | null
	textColor: string
	font: string
	padding: number
	fontSizeFactor: number
	verticalOffset: number
}

declare module 'chart.js' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface PluginOptionsByType<TType extends ChartType> {
		doughnutCenterText: DoughnutCenterTextOptions
	}
}

const DoughnutCenterText: Plugin<'doughnut', DoughnutCenterTextOptions> = {
	id: 'doughnutCenterText',
	afterDraw(chart, args, options) {
		const { ctx } = chart

		ctx.save()

		// Renders the center text behind the chart elements to avoid weird z-index issues with the chart tooltip
		ctx.globalCompositeOperation = 'destination-over'

		const calcReferenceFontSize = 10

		ctx.font = calcReferenceFontSize + 'px ' + options.font

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const innerRadius = (chart as any)._metasets[0].controller.innerRadius
		const calculatedMaxWidthFontSize = ((innerRadius * 2 - options.padding * 2) / ctx.measureText(options.text).width) * calcReferenceFontSize
		const computedFontSize = calculatedMaxWidthFontSize * options.fontSizeFactor

		ctx.font = computedFontSize + 'px ' + options.font

		ctx.textAlign = 'center'
		ctx.textBaseline = 'middle'
		const centerX = (chart.chartArea.left + chart.chartArea.right) / 2
		const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2) * options.verticalOffset
		ctx.fillStyle = options.textColor

		ctx.fillText(options.text, centerX, options.subText ? centerY - 12 : centerY)

		if (options.subText) {
			ctx.fillText(options.subText, centerX, centerY + 12)
		}

		ctx.restore()
	},
	defaults: {
		text: '',
		textColor: 'black',
		font: 'system-ui',
		padding: 20,
		fontSizeFactor: 1,
		verticalOffset: 1,
	},
}

export { DoughnutCenterText }
