import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { AppContext } from '../../../App'
import { Frown } from 'react-feather'

import * as Helpers from './siteExportHelpers'

import { ImportStatus as ExportStatus } from '../AssetImport/assetImport'
import { Site } from '../../../models/Site'

import { downloadBlob } from '../../../utilities/download'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import { AssetMake } from '../../../models/AssetMake'
import { AssetModel } from '../../../models/AssetModel'
import { LocationBuilding } from '../../../models/LocationBuilding'
import { LocationFloor } from '../../../models/LocationFloor'
import { LocationArea } from '../../../models/LocationArea'
import { LocationRoom } from '../../../models/LocationRoom'
import { Asset } from '../../../models/Asset'
import { AssetTree } from '../../../models/AssetTree'
import { ChannelMap } from '../../../models/ChannelMap'
import { Group } from '../../../models/Group'
import { AssetGroup } from '../../../models/AssetGroup'
import { EventSetting } from '../../../models/EventSetting'
import { BacnetTemperatureAlarmSetting } from '../../../models/BacnetTemperatureAlarmSetting'
import { Bacnet } from '../../../models/Bacnet'
import { Maintenance } from '../../../models/Maintenance'
import { SiteSetting } from '../../../models/SiteSetting'

export interface ExportData {
	sites: Site[]
	assetMakes: AssetMake[]
	assetModels: AssetModel[]
	locationBuildings: LocationBuilding[]
	locationFloors: LocationFloor[]
	locationAreas: LocationArea[]
	locationRooms: LocationRoom[]
	assets: Asset[]
	assetTrees: AssetTree[]
	channelMaps: ChannelMap[]
	groups: Group[]
	assetGroups: AssetGroup[]
	eventSettings: EventSetting[]
	siteSettings: SiteSetting[]
	bacnetTemperatureAlarmSettings: BacnetTemperatureAlarmSetting[]
	bacnets: Bacnet[]
	maintenances: Maintenance[]
}

export interface ExportUserFeedback {
	sites: string
	assetMakes: string
	assetModels: string
	locationBuildings: string
	locationFloors: string
	locationAreas: string
	locationRooms: string
	assets: string
	assetTrees: string
	channelMaps: string
	groups: string
	assetGroups: string
	eventSettings: string
	siteSettings: string
	bacnetTemperatureAlarmSettings: string
	bacnets: string
	maintenances: string
}

const defaultFeedback = {
	sites: '',
	assetMakes: '',
	assetModels: '',
	locationBuildings: '',
	locationFloors: '',
	locationAreas: '',
	locationRooms: '',
	assets: '',
	assetTrees: '',
	channelMaps: '',
	groups: '',
	assetGroups: '',
	eventSettings: '',
	siteSettings: '',
	bacnetTemperatureAlarmSettings: '',
	bacnets: '',
	maintenances: '',
}

const SiteExporter = () => {
	const context = React.useContext(AppContext)

	const [exportStatus, setExportStatus] = React.useState<ExportStatus>('Ready')
	const [userFeedback, setUserFeedback] = React.useState<ExportUserFeedback>(defaultFeedback)
	const [data, setData] = React.useState<ExportData | null>(null)

	const handleExport = async () => {
		setData(null)
		setUserFeedback(defaultFeedback)
		setExportStatus('InProgress')
		try {
			const sites = await Helpers.exportHandler<Site>(context.appState, setUserFeedback, 'sites', Helpers.getSites)
			const assetMakes = await Helpers.exportHandler<AssetMake>(context.appState, setUserFeedback, 'assetMakes', Helpers.getAssetMakes)
			const assetModels = await Helpers.exportHandler<AssetModel>(context.appState, setUserFeedback, 'assetModels', Helpers.getAssetModels)
			const locationBuildings = await Helpers.exportHandler<LocationBuilding>(
				context.appState,
				setUserFeedback,
				'locationBuildings',
				Helpers.getLocationBuildings
			)
			const locationFloors = await Helpers.exportHandler<LocationFloor>(context.appState, setUserFeedback, 'locationFloors', Helpers.getLocationFloors)
			const locationAreas = await Helpers.exportHandler<LocationArea>(context.appState, setUserFeedback, 'locationAreas', Helpers.getLocationAreas)
			const locationRooms = await Helpers.exportHandler<LocationRoom>(context.appState, setUserFeedback, 'locationRooms', Helpers.getLocationRooms)
			const assets = await Helpers.exportHandler<Asset>(context.appState, setUserFeedback, 'assets', Helpers.getAssets)
			const assetTrees = await Helpers.exportHandler<AssetTree>(context.appState, setUserFeedback, 'assetTrees', Helpers.getAssetTrees)
			const channelMaps = await Helpers.exportHandler<ChannelMap>(context.appState, setUserFeedback, 'channelMaps', Helpers.getChannelMaps)
			const groups = await Helpers.exportHandler<Group>(context.appState, setUserFeedback, 'groups', Helpers.getGroups)
			const assetGroups = await Helpers.exportHandler<AssetGroup>(context.appState, setUserFeedback, 'assetGroups', Helpers.getAssetGroups)
			const eventSettings = await Helpers.exportHandler<EventSetting>(context.appState, setUserFeedback, 'eventSettings', Helpers.getEventSettings)
			const siteSettings = await Helpers.exportHandler<SiteSetting>(context.appState, setUserFeedback, 'siteSettings', Helpers.getSiteSettings)
			const bacnetTemperatureAlarmSettings = await Helpers.exportHandler<BacnetTemperatureAlarmSetting>(
				context.appState,
				setUserFeedback,
				'bacnetTemperatureAlarmSettings',
				Helpers.getBacnetTemperatureAlarmSettings
			)
			const bacnets = await Helpers.exportHandler<Bacnet>(context.appState, setUserFeedback, 'bacnets', Helpers.getBacnets)
			const maintenances = await Helpers.exportHandler<Maintenance>(context.appState, setUserFeedback, 'maintenances', Helpers.getMaintenances)

			setData({
				sites,
				assetMakes,
				assetModels,
				locationBuildings,
				locationFloors,
				locationAreas,
				locationRooms,
				assets,
				assetTrees,
				channelMaps,
				groups,
				assetGroups,
				eventSettings,
				siteSettings,
				bacnetTemperatureAlarmSettings,
				bacnets,
				maintenances,
			})
			setExportStatus('Finished')
		} catch (e) {
			console.log(e)
			setExportStatus('Error')
		}
	}

	const handleDownload = () => {
		downloadBlob(
			JSON.stringify(data),
			`SmartFlowSiteExport - ${data?.sites[0].site_Name} - ${formatIncomingDateTime({
				dateTime: new Date().toISOString(),
				format: 'Custom',
				timeZone: context.appState.currentSite?.site_Timezone,
				customFormat: 'dd-MMM-yyyy',
			})}.json`,
			'text/json;charset=utf-8;'
		)
	}

	return (
		<>
			{userFeedback != defaultFeedback &&
				Object.keys(userFeedback).map((key) => {
					return (
						<Row key={key} style={styles.row}>
							<Col style={styles.centred}>
								<h1>{userFeedback[key as keyof ExportUserFeedback]}</h1>
							</Col>
						</Row>
					)
				})}
			{exportStatus === 'Error' && (
				<>
					<Row style={styles.row}>
						<Col style={styles.centred}>
							<h1>Something went wrong</h1>
						</Col>
					</Row>
					<Row style={styles.row}>
						<Col style={styles.centred}>
							<Frown />
						</Col>
					</Row>
				</>
			)}
			<Row>
				<Col sm="auto">
					{exportStatus !== 'InProgress' && (
						<Button variant="primary" onClick={handleExport}>
							{userFeedback != defaultFeedback ? 'Re-run' : 'Start'} Export
						</Button>
					)}
				</Col>
				<Col sm="auto">
					{exportStatus === 'Finished' && data && (
						<Button variant="primary" onClick={handleDownload}>
							Download file
						</Button>
					)}
				</Col>
			</Row>
		</>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	row: {
		marginBottom: '1rem',
		marginTop: '1rem',
	},
	centred: {
		textAlign: 'center',
	},
}

export { SiteExporter }
