import * as Request from '../../../utilities/request'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import { AxiosError } from 'axios'
import { MSExceptionResult } from '../../../utilities/request.d'

import { AppState } from '../../../App.d'
import { SiteImportFeedback } from './SiteImporter'
import { ImportUserFeedback } from '../AssetImport/assetImport'
import { defaultCreated, defaultModified } from '../../../models/History'
import { Site, SiteResult, defaultSite } from '../../../models/Site'
import { AssetMake, AssetMakeResult, defaultAssetMake } from '../../../models/AssetMake'
import { AssetModel, AssetModelResult, defaultAssetModel } from '../../../models/AssetModel'
import { LocationBuilding, LocationBuildingResult, defaultLocationBuilding } from '../../../models/LocationBuilding'
import { LocationFloor, LocationFloorResult, defaultLocationFloor } from '../../../models/LocationFloor'
import { LocationArea, LocationAreaResult, defaultLocationArea } from '../../../models/LocationArea'
import { LocationRoom, LocationRoomResult, defaultLocationRoom } from '../../../models/LocationRoom'
import { Asset, AssetResult, defaultAsset } from '../../../models/Asset'
import { AssetTree, AssetTreeResult, defaultAssetTree } from '../../../models/AssetTree'
import { ChannelMap, ChannelMapResult, defaultChannelMap } from '../../../models/ChannelMap'
import { Group, GroupResult, defaultGroup } from '../../../models/Group'
import { AssetGroup, AssetGroupResult, defaultAssetGroup } from '../../../models/AssetGroup'
import { EventSetting, EventSettingResult, defaultEventSetting } from '../../../models/EventSetting'
import {
	BacnetTemperatureAlarmSetting,
	BacnetTemperatureAlarmSettingsResult,
	defaultBacnetTemperatureAlarmSetting,
} from '../../../models/BacnetTemperatureAlarmSetting'
import { Bacnet, BacnetResult, defaultBacnet } from '../../../models/Bacnet'
import { Maintenance, MaintenanceResult, defaultMaintenance } from '../../../models/Maintenance'

export const importHandler = async <T,>(
	fileData: T[],
	object: string,
	updateFeedback: React.Dispatch<React.SetStateAction<SiteImportFeedback>>,
	importFn: (data: T) => Promise<void>,
	initialFeedback?: SiteImportFeedback
) => {
	const feedback: ImportUserFeedback = { inProgress: false, totalCount: 0, totalComplete: 0, errors: [] }

	if (fileData.length > 0) {
		feedback.inProgress = true
		feedback.totalCount = fileData.length
		updateFeedback((prev) => ({ ...(initialFeedback || prev), [object]: feedback }))

		for (let i = 0; i < fileData.length; i++) {
			try {
				await importFn(fileData[i])
			} catch (error) {
				feedback.errors.push(
					`Unable to process row ${JSON.stringify(fileData[i])}: "${
						(error as AxiosError<MSExceptionResult>).response?.data?.ExceptionMessage || error
					}"`
				)
			} finally {
				feedback.totalComplete = i + 1
				updateFeedback((prev) => ({ ...(initialFeedback || prev), [object]: feedback }))
			}
		}
	}

	return feedback
}

export const importSite = async (appState: AppState, site: Site, sites: Site[]) => {
	const siteIndex = sites.findIndex((dbSite) => dbSite.site_Id === site.site_Id)
	const apiRequest = siteIndex >= 0 ? Request.put : Request.post

	const newSite = defaultSite({
		...site,
		siteGroup_Id: null,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<SiteResult>('site', newSite, appState.authState)
}

export const importAssetMake = async (appState: AppState, assetMake: AssetMake, assetMakes: AssetMake[]) => {
	const assetMakeIndex = assetMakes.findIndex((dbAssetMake) => dbAssetMake.assetMake_Id === assetMake.assetMake_Id)
	const apiRequest = assetMakeIndex >= 0 ? Request.put : Request.post

	const newAssetMake = defaultAssetMake({
		...assetMake,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<AssetMakeResult>('assetMake', newAssetMake, appState.authState)
}

export const importAssetModel = async (appState: AppState, assetModel: AssetModel, assetModels: AssetModel[]) => {
	const assetModelIndex = assetModels.findIndex((dbAssetModel) => dbAssetModel.assetModel_Id === assetModel.assetModel_Id)
	const apiRequest = assetModelIndex >= 0 ? Request.put : Request.post

	const newAssetModel = defaultAssetModel({
		...assetModel,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<AssetModelResult>('assetModel', newAssetModel, appState.authState)
}

export const importLocationBuilding = async (appState: AppState, locationBuilding: LocationBuilding, locationBuildings: LocationBuilding[]) => {
	const locationBuildingIndex = locationBuildings.findIndex(
		(dbLocationBuilding) => dbLocationBuilding.locationBuilding_Id === locationBuilding.locationBuilding_Id
	)
	const apiRequest = locationBuildingIndex >= 0 ? Request.put : Request.post

	const newLocationBuilding = defaultLocationBuilding({
		...locationBuilding,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<LocationBuildingResult>('locationBuilding', newLocationBuilding, appState.authState)
}

export const importLocationFloor = async (appState: AppState, locationFloor: LocationFloor, locationFloors: LocationFloor[]) => {
	const locationFloorIndex = locationFloors.findIndex((dbLocationFloor) => dbLocationFloor.locationFloor_Id === locationFloor.locationFloor_Id)
	const apiRequest = locationFloorIndex >= 0 ? Request.put : Request.post

	const newLocationFloor = defaultLocationFloor({
		...locationFloor,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<LocationFloorResult>('locationFloor', newLocationFloor, appState.authState)
}

export const importLocationArea = async (appState: AppState, locationArea: LocationArea, locationAreas: LocationArea[]) => {
	const locationAreaIndex = locationAreas.findIndex((dbLocationArea) => dbLocationArea.locationArea_Id === locationArea.locationArea_Id)
	const apiRequest = locationAreaIndex >= 0 ? Request.put : Request.post

	const newLocationArea = defaultLocationArea({
		...locationArea,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<LocationAreaResult>('locationArea', newLocationArea, appState.authState)
}

export const importLocationRoom = async (appState: AppState, locationRoom: LocationRoom, locationRooms: LocationRoom[]) => {
	const locationRoomIndex = locationRooms.findIndex((dbLocationRoom) => dbLocationRoom.locationRoom_Id === locationRoom.locationRoom_Id)
	const apiRequest = locationRoomIndex >= 0 ? Request.put : Request.post

	const newLocationRoom = defaultLocationRoom({
		...locationRoom,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<LocationRoomResult>('locationRoom', newLocationRoom, appState.authState)
}

export const importAsset = async (appState: AppState, asset: Asset, assets: Asset[]) => {
	const assetIndex = assets.findIndex((dbAsset) => dbAsset.asset_Id === asset.asset_Id)
	const apiRequest = assetIndex >= 0 ? Request.put : Request.post

	const newAsset = defaultAsset({
		...asset,
		asset_InstallDate: asset.asset_InstallDate
			? formatIncomingDateTime({
					dateTime: asset.asset_InstallDate,
					format: 'Custom',
					timeZone: 'utc',
					customFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
			  })
			: asset.asset_InstallDate,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	// need to fix backend error handling here
	await apiRequest<AssetResult>('asset', newAsset, appState.authState)
}

export const importAssetTree = async (appState: AppState, assetTree: AssetTree, assetTrees: AssetTree[]) => {
	const assetTreeIndex = assetTrees.findIndex((dbAssetTree) => dbAssetTree.assetTree_Id === assetTree.assetTree_Id)
	const apiRequest = assetTreeIndex >= 0 ? Request.put : Request.post

	const newAssetTree = defaultAssetTree({
		...assetTree,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<AssetTreeResult>('assetTree', newAssetTree, appState.authState)
}

export const importChannelMap = async (appState: AppState, channelMap: ChannelMap, channelMaps: ChannelMap[]) => {
	const channelMapIndex = channelMaps.findIndex((dbChannelMap) => dbChannelMap.channelMap_Id === channelMap.channelMap_Id)
	const apiRequest = channelMapIndex >= 0 ? Request.put : Request.post

	const newChannelMap = defaultChannelMap({
		...channelMap,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<ChannelMapResult>('channelMap', newChannelMap, appState.authState)
}

export const importGroup = async (appState: AppState, group: Group, groups: Group[]) => {
	const groupIndex = groups.findIndex((dbGroup) => dbGroup.group_Id === group.group_Id)
	const apiRequest = groupIndex >= 0 ? Request.put : Request.post

	const newGroup = defaultGroup({
		...group,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<GroupResult>('group', newGroup, appState.authState)
}

export const importAssetGroup = async (appState: AppState, assetGroup: AssetGroup, assetGroups: AssetGroup[]) => {
	const assetGroupIndex = assetGroups.findIndex((dbAssetGroup) => dbAssetGroup.assetGroup_Id === assetGroup.assetGroup_Id)
	const apiRequest = assetGroupIndex >= 0 ? Request.put : Request.post

	const newAssetGroup = defaultAssetGroup({
		...assetGroup,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<AssetGroupResult>('assetGroup', newAssetGroup, appState.authState)
}

export const importEventSetting = async (appState: AppState, eventSetting: EventSetting, eventSettings: EventSetting[]) => {
	const eventSettingIndex = eventSettings.findIndex((dbEventSetting) => dbEventSetting.eventSetting_Id === eventSetting.eventSetting_Id)
	const apiRequest = eventSettingIndex >= 0 ? Request.put : Request.post

	const newEventSetting = defaultEventSetting({
		...eventSetting,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<EventSettingResult>('eventSetting', newEventSetting, appState.authState)
}

export const importBacnetTemperatureAlarmSetting = async (
	appState: AppState,
	bacnetTemperatureAlarmSetting: BacnetTemperatureAlarmSetting,
	bacnetTemperatureAlarmSettings: BacnetTemperatureAlarmSetting[]
) => {
	const bacnetTemperatureAlarmSettingIndex = bacnetTemperatureAlarmSettings.findIndex(
		(dbBacnetTemperatureAlarmSetting) =>
			dbBacnetTemperatureAlarmSetting.bacnetTemperatureAlarmSetting_Id === bacnetTemperatureAlarmSetting.bacnetTemperatureAlarmSetting_Id
	)
	const apiRequest = bacnetTemperatureAlarmSettingIndex >= 0 ? Request.put : Request.post

	const newBacnetTemperatureAlarmSetting = defaultBacnetTemperatureAlarmSetting({
		...bacnetTemperatureAlarmSetting,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<BacnetTemperatureAlarmSettingsResult>('bacnetTemperatureAlarmSetting', newBacnetTemperatureAlarmSetting, appState.authState)
}

export const importBacnet = async (appState: AppState, bacnet: Bacnet, bacnets: Bacnet[]) => {
	const bacnetIndex = bacnets.findIndex((dbBacnet) => dbBacnet.bacnet_Id === bacnet.bacnet_Id)
	const apiRequest = bacnetIndex >= 0 ? Request.put : Request.post

	const newBacnet = defaultBacnet({
		...bacnet,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<BacnetResult>('bacnet', newBacnet, appState.authState)
}

export const importMaintenance = async (appState: AppState, maintenance: Maintenance, maintenances: Maintenance[]) => {
	const maintenanceIndex = maintenances.findIndex((dbMaintenance) => dbMaintenance.maintenance_Id === maintenance.maintenance_Id)
	const apiRequest = maintenanceIndex >= 0 ? Request.put : Request.post

	const newMaintenance = defaultMaintenance({
		...maintenance,
		maintenance_ScheduledDate: maintenance.maintenance_ScheduledDate
			? formatIncomingDateTime({
					dateTime: maintenance.maintenance_ScheduledDate,
					format: 'Custom',
					timeZone: 'utc',
					customFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
			  }) || undefined
			: maintenance.maintenance_ScheduledDate,
		maintenance_CompletionDate: maintenance.maintenance_CompletionDate
			? formatIncomingDateTime({
					dateTime: maintenance.maintenance_CompletionDate,
					format: 'Custom',
					timeZone: 'utc',
					customFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
			  })
			: maintenance.maintenance_CompletionDate,
		maintenance_Ts: maintenance.maintenance_Ts
			? formatIncomingDateTime({
					dateTime: maintenance.maintenance_Ts,
					format: 'Custom',
					timeZone: 'utc',
					customFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
			  })
			: maintenance.maintenance_Ts,
		created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
	})

	await apiRequest<MaintenanceResult>('maintenance', newMaintenance, appState.authState)
}
