import React from 'react'
import './UserInfo.css'

import { AppContext } from '../../../App'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'

import profile from '../../../images/icons/avatar-white.svg'
import { msalInstance } from '../../../config/config'
import Cookies from 'universal-cookie'

export const UserInfo = () => {
	const { setAppState } = React.useContext(AppContext)

	const logOff = async () => {
		if (process.env.REACT_APP_IsLocalMode === 'false') {
			await msalInstance.logoutRedirect()
		} else {
			const cookies = new Cookies()
			cookies.remove('user', { path: '/', sameSite: true })
			cookies.remove('accessToken', { path: '/', sameSite: true })
		}
		setAppState({ state: 'signOut' })
	}

	return (
		<OverlayTrigger placement="left" overlay={<Tooltip id={`toolTip_user`}>User</Tooltip>}>
			<Dropdown className="dropdown-userinfo-header">
				<Dropdown.Toggle className="dropdown-userinfo shadow-none">
					<img src={profile} alt={`A User Icon`} style={{ marginRight: '10px' }} />
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item className="dropdown-userinfo-pointer" onClick={() => logOff()}>
						Log Out
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</OverlayTrigger>
	)
}
