import React from 'react'
import * as yup from 'yup'

import { Asset } from '../../models/Asset'
import { AssetMake, defaultAssetMake } from '../../models/AssetMake'
import { AssetModel, defaultAssetModel } from '../../models/AssetModel'
import { LocationBuilding, defaultLocationBuilding } from '../../models/LocationBuilding'
import { LocationFloor, defaultLocationFloor } from '../../models/LocationFloor'
import { LocationArea, defaultLocationArea } from '../../models/LocationArea'
import { LocationRoom, defaultLocationRoom } from '../../models/LocationRoom'
import { defaultCreated, defaultModified } from '../../models/History'

import { GenericAddModal } from '../../components/UI/AssetDetails/Edit/GenericAddModal'

const assetMakeValidationSchema = yup.object().shape({
	assetMake_Name: yup.string().required('Name is required'),
}) as yup.ObjectSchema<Partial<AssetMake>>

const assetModelValidationSchema = yup.object().shape({
	assetModel_Name: yup.string().required('Name is required'),
	assetModel_Note: yup.string().nullable().max(1000, 'Max character limit is 1000.'),
}) as yup.ObjectSchema<Partial<AssetModel>>

const locationBuildingValidationSchema = yup.object().shape({
	locationBuilding_Name: yup.string().required('Name is required'),
	locationBuilding_Note: yup.string().nullable().max(100, 'Max character limit is 1000.'),
}) as yup.ObjectSchema<Partial<LocationBuilding>>

const locationFloorValidationSchema = yup.object().shape({
	locationFloor_Name: yup.string().required('Name is required'),
	locationFloor_Note: yup.string().nullable().max(100, 'Max character limit is 1000.'),
}) as yup.ObjectSchema<Partial<LocationFloor>>

const locationAreaValidationSchema = yup.object().shape({
	locationArea_Name: yup.string().required('Name is required'),
	locationArea_Note: yup.string().nullable().max(100, 'Max character limit is 1000.'),
}) as yup.ObjectSchema<Partial<LocationArea>>

const locationRoomValidationSchema = yup.object().shape({
	locationRoom_Name: yup.string().required('Name is required'),
	locationRoom_Note: yup.string().nullable().max(100, 'Max character limit is 1000.'),
}) as yup.ObjectSchema<Partial<LocationRoom>>

interface CreateModalProps {
	hide: () => void
	user_Id: string
	site_Id: string
	asset: Asset
	handleAddAssetMake?: (values: AssetMake) => Promise<void>
	handleAddAssetModel?: (values: AssetModel) => Promise<void>
	handleAddLocationBuilding?: (values: LocationBuilding) => Promise<void>
	handleAddLocationFloor?: (values: LocationFloor) => Promise<void>
	handleAddLocationArea?: (values: LocationArea) => Promise<void>
	handleAddLocationRoom?: (values: LocationRoom) => Promise<void>
}

const ModalComponentByName: { [key: string]: (createModalProps: CreateModalProps) => JSX.Element } = {
	assetMake: (createModalProps: CreateModalProps) => (
		<GenericAddModal<AssetMake>
			show={true}
			hide={createModalProps.hide}
			displayName="Asset Make"
			editableValues={[{ fieldName: 'assetMake_Name', required: true }]}
			defaultValues={() =>
				defaultAssetMake({
					created: defaultCreated({ create_UserId: createModalProps.user_Id }),
					modified: defaultModified({ modified_UserId: createModalProps.user_Id }),
				})
			}
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			handleSubmit={createModalProps.handleAddAssetMake!}
			validationSchema={assetMakeValidationSchema}
		/>
	),
	assetModel: (createModalProps: CreateModalProps) => (
		<GenericAddModal<AssetModel>
			show={true}
			hide={createModalProps.hide}
			displayName="Asset Model"
			editableValues={[
				{ fieldName: 'assetModel_Name', required: true },
				{ fieldName: 'assetModel_Note', required: false, renderAs: 'textarea' },
			]}
			defaultValues={() =>
				defaultAssetModel({
					assetType_Id: createModalProps.asset.assetType_Id,
					assetMake_Id: createModalProps.asset.assetMake_Id || undefined,
					created: defaultCreated({ create_UserId: createModalProps.user_Id }),
					modified: defaultModified({ modified_UserId: createModalProps.user_Id }),
				})
			}
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			handleSubmit={createModalProps.handleAddAssetModel!}
			validationSchema={assetModelValidationSchema}
		/>
	),
	locationBuilding: (createModalProps: CreateModalProps) => (
		<GenericAddModal<LocationBuilding>
			show={true}
			hide={createModalProps.hide}
			displayName="Location"
			editableValues={[
				{ fieldName: 'locationBuilding_Name', required: true },
				{ fieldName: 'locationBuilding_Note', required: false, renderAs: 'textarea' },
			]}
			defaultValues={() =>
				defaultLocationBuilding({
					site_Id: createModalProps.site_Id,
					created: defaultCreated({ create_UserId: createModalProps.user_Id }),
					modified: defaultModified({ modified_UserId: createModalProps.user_Id }),
				})
			}
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			handleSubmit={createModalProps.handleAddLocationBuilding!}
			validationSchema={locationBuildingValidationSchema}
		/>
	),
	locationFloor: (createModalProps: CreateModalProps) => (
		<GenericAddModal<LocationFloor>
			show={true}
			hide={createModalProps.hide}
			displayName="Floor"
			editableValues={[
				{ fieldName: 'locationFloor_Name', required: true },
				{ fieldName: 'locationFloor_Note', required: false, renderAs: 'textarea' },
			]}
			defaultValues={() =>
				defaultLocationFloor({
					site_Id: createModalProps.site_Id,
					locationBuilding_Id: createModalProps.asset.locationBuilding_Id,
					created: defaultCreated({ create_UserId: createModalProps.user_Id }),
					modified: defaultModified({ modified_UserId: createModalProps.user_Id }),
				})
			}
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			handleSubmit={createModalProps.handleAddLocationFloor!}
			validationSchema={locationFloorValidationSchema}
		/>
	),
	locationArea: (createModalProps: CreateModalProps) => (
		<GenericAddModal<LocationArea>
			show={true}
			hide={createModalProps.hide}
			displayName="Area"
			editableValues={[
				{ fieldName: 'locationArea_Name', required: true },
				{ fieldName: 'locationArea_Note', required: false, renderAs: 'textarea' },
			]}
			defaultValues={() =>
				defaultLocationArea({
					site_Id: createModalProps.site_Id,
					locationFloor_Id: createModalProps.asset.locationFloor_Id,
					created: defaultCreated({ create_UserId: createModalProps.user_Id }),
					modified: defaultModified({ modified_UserId: createModalProps.user_Id }),
				})
			}
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			handleSubmit={createModalProps.handleAddLocationArea!}
			validationSchema={locationAreaValidationSchema}
		/>
	),
	locationRoom: (createModalProps: CreateModalProps) => (
		<GenericAddModal<LocationRoom>
			show={true}
			hide={createModalProps.hide}
			displayName="Room"
			editableValues={[
				{ fieldName: 'locationRoom_Name', required: true },
				{ fieldName: 'locationRoom_Note', required: false, renderAs: 'textarea' },
			]}
			defaultValues={() =>
				defaultLocationRoom({
					site_Id: createModalProps.site_Id,
					locationArea_Id: createModalProps.asset.locationArea_Id,
					created: defaultCreated({ create_UserId: createModalProps.user_Id }),
					modified: defaultModified({ modified_UserId: createModalProps.user_Id }),
				})
			}
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			handleSubmit={createModalProps.handleAddLocationRoom!}
			validationSchema={locationRoomValidationSchema}
		/>
	),
}

export { ModalComponentByName }
