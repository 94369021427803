import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { AppContext } from '../../../App'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import { BacnetPoint, BacnetPointResult } from '../../../models/Bacnet'
import ExportDropdown, { CsvData } from '../Reports/ExportDropdown'
import { Messages, useMessageReducer } from '../Messages/Messages'
import { PageStatus } from '../../../types/PageStatus'
import { DateTime } from 'luxon'
import * as Request from '../../../utilities/request'

const BacnetExporter = () => {
	const context = React.useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [messages, setMessages] = useMessageReducer([])

	const [bacnetPoints, setBacnetPoints] = React.useState<BacnetPoint[] | null>([])

	React.useEffect(() => {
		const getData = async () => {
			const [bacnetReq] = await Promise.all([
				Request.get<BacnetPointResult>(`bacnetPointExport?site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
			])

			setBacnetPoints(bacnetReq.data.bacnetPoints)

			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context.appState.currentSite])

	const getFilename = () =>
		`BACnet Points for ${context.appState.currentSite?.site_Name}: - ${formatIncomingDateTime({
			dateTime: DateTime.now().toISO({ includeOffset: false }) || '',
			format: 'FileDate',
		})}`

	return (
		<>
			<Messages messages={messages} updateMessage={setMessages} />

			<Row>
				<Col sm="auto">
					<ExportDropdown
						buttonText="Export BACnet Points"
						pageStatus={pageStatus}
						setPageStatus={setPageStatus}
						setMessages={setMessages}
						csvDownloads={{
							options: [
								{
									menuLabel: 'CSV',
									csvFilename: getFilename(),
									data: bacnetPoints?.map((bacnetPoint) => ({
										...bacnetPoint,
									})) as unknown as CsvData[],
									headers: [
										{ key: 'pointType', columnName: 'PointType' },
										{ key: 'pointDescription', columnName: 'PointDescription' },
										{ key: 'pointLocation', columnName: 'PointLocation' },
										{ key: 'bacnetDeviceId', columnName: 'BacnetDeviceId' },
										{ key: 'bacnetObjectType', columnName: 'BacnetObjectType' },
										{ key: 'bacnetInstanceId', columnName: 'BacnetInstanceId' },
										{ key: 'bacnetObjectName', columnName: 'BacnetObjectName' },
										{ key: 'bacnetDataDescription', columnName: 'BacnetDataDescription' },
										{ key: 'bacnetLowLevelAlarmEventEnrolmentInstanceId', columnName: 'BacnetLowLevelAlarmEventEnrolmentInstanceId' },
										{ key: 'bacnetHighLevelAlarmEventEnrolmentInstanceId', columnName: 'BacnetHighLevelAlarmEventEnrolmentInstanceId' },
									],
								},
							],
						}}
					/>
				</Col>
			</Row>
		</>
	)
}

export { BacnetExporter }
