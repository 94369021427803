import React from 'react'
import { useNavigate } from 'react-router-dom'

const Back = () => {
	const navigate = useNavigate()

	const handleClick = () => {
		navigate(-1)
	}
	return (
		<p className="fake-link breadcrumb-back" onClick={handleClick}>
			{`< Back`}
		</p>
	)
}

export { Back }
