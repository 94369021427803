import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const TermsAndConditions = () => {
	return (
		<>
			<Row>
				<Col style={{ textAlign: 'center' }}>
					<span style={{ color: 'grey' }}>
						Copyright &copy; {new Date().getFullYear()} <Link to={'https://www.enware.com.au/'}>Enware Australia Pty Limited.</Link> All rights
						reserved.
					</span>
				</Col>
			</Row>
			<Row>
				<Col style={{ textAlign: 'center' }}>
					<span style={{ color: 'grey' }}>Build Version: {process.env.REACT_APP_VERSION}</span>
				</Col>
			</Row>
		</>
	)
}
