export const AssetClassNameValues = ['Fixture', 'Hub', 'Transmitter', 'Network', 'Pipework', 'Tmv'] as const
export type AssetClassName = (typeof AssetClassNameValues)[number]

export const AssetClassIdValues = [
	'fixture0-0000-0000-0000-000000000000',
	'hub00000-0000-0000-0000-000000000000',
	'transmit-0000-0000-0000-000000000000',
	'network0-0000-0000-0000-000000000000',
	'pipework-0000-0000-0000-000000000000',
	'tmv00000-0000-0000-0000-000000000000',
] as const
export type AssetClassId = (typeof AssetClassIdValues)[number]

export type AssetClass = { id: AssetClassId }

export const assetClass: Record<AssetClassName, AssetClass> = {
	Fixture: { id: 'fixture0-0000-0000-0000-000000000000' },
	Hub: { id: 'hub00000-0000-0000-0000-000000000000' },
	Transmitter: { id: 'transmit-0000-0000-0000-000000000000' },
	Network: { id: 'network0-0000-0000-0000-000000000000' },
	Pipework: { id: 'pipework-0000-0000-0000-000000000000' },
	Tmv: { id: 'tmv00000-0000-0000-0000-000000000000' },
}

export const assetClassDropdownValues = AssetClassNameValues.map((value) => ({
	value: assetClass[value].id,
	label: value,
}))

export const getAssetClassNameFromId = (assetClass_Id: AssetClassId) => {
	const assetClass_Name = AssetClassNameValues.find((assetClass_Name) => assetClass[assetClass_Name].id === assetClass_Id)
	if (!assetClass_Name) throw new Error(`Asset name not found for assetClass_Id: ${assetClass_Id}`)
	return assetClass_Name
}
export const getAssetClassIdFromName = (assetClass_Name: AssetClassName) => {
	const assetClass_Id = AssetClassIdValues.find((assetClass_Id) => assetClass[assetClass_Name].id === assetClass_Id)
	if (!assetClass_Id) throw new Error(`AssetClass_Id not found for name: ${assetClass_Name}`)
	return assetClass_Id
}

export const getAssetClassAssetClassFromId = (assetClass_Id: AssetClassId) => {
	return assetClass[getAssetClassNameFromId(assetClass_Id as AssetClassId)]
}
