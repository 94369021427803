import React from 'react'
import './EditAsset.css'
import { AppContext } from '../../../../App'
import * as Request from '../../../../utilities/request'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../../utilities/permissions/permission.d'
import { Row, Col, Card } from 'react-bootstrap'
import { FormText } from '../../Form/Text'
import { FormSelect, SelectOption } from '../../Form/Select'
import PermissionsCheck from '../../../Permissions/PermissionsCheck'
import { CommonComponentProps } from '../../../../screens/Asset/EditAssetDetails'
import { PaymentAgreement } from '../../../../models/PaymentAgreement'
import { Contract, ContractResult } from '../../../../models/Contract'

const AssetContract = (componentProps: CommonComponentProps) => {
	const context = React.useContext(AppContext)

	const [contracts, setContracts] = React.useState<Contract[] | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [contractReq] = await Promise.all([
				Request.get<ContractResult>(`contract?site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
			])
			setContracts(contractReq.data.contracts)
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [componentProps.asset.asset_Id])

	const paymentAgreementOptions = React.useMemo(() => {
		let paymentAgrements: PaymentAgreement[] = []
		if (contracts) {
			contracts.forEach((contract) => {
				paymentAgrements = [...paymentAgrements, ...contract.contract_PaymentAgreements]
			})
		}
		return paymentAgrements.map((p) => ({ label: p.paymentAgreement_OrderNumber || '', value: p.paymentAgreement_Id }))
	}, [contracts])

	return (
		<PermissionsCheck object={PermissionModelObject.Contract} action={PermissionModelAction.GET} context={PermissionModelContext.None}>
			<Card className="site-card">
				<Row className="generic-card-list-heading-row">
					<Col>
						<span className="dashboard-card-titles">Contract Information</span>
					</Col>
				</Row>
				<Row style={styles.row}>
					<Col sm={6} className="edit-asset-field">
						<FormText name={'contract_Name'} label={'Contract'} value={componentProps.asset.contract_Name || ''} disabled />
					</Col>
					<Col sm={6} className="edit-asset-field">
						<FormSelect
							name={'paymentAgreement_Id'}
							label={'Payment Agreement'}
							options={paymentAgreementOptions}
							value={componentProps.asset.paymentAgreement_Id}
							onChange={(e) => {
								componentProps.handleChange('asset.paymentAgreement_Id', e ? (e as SelectOption<string>).value : null)
								componentProps.handleChange('asset.paymentAgreement_OrderNumber', e ? (e as SelectOption<string>).label : null)
							}}
							isClearable
							loading={!contracts}
						/>
					</Col>
				</Row>
			</Card>
		</PermissionsCheck>
	)
}

const styles = {
	row: {
		padding: '20px',
		justifyContent: 'space-between',
	},
	disabledPlusButton: {
		cursor: 'not-allowed',
	},
}

export { AssetContract }
