import React, { useContext } from 'react'
import './SiteCard.css'
import card from '../../../images/icons/group.svg'
import ellipsis from '../../../images/icons/options.svg'
import { Site } from '../../../models/Site'
import { Row, Col, Card, Dropdown } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from '../../../App'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import * as Request from '../../../utilities/request'
import { SiteGroupResult } from '../../../models/SiteGroup'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import PermissionsCheck from '../../Permissions/PermissionsCheck'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../utilities/permissions/permission.d'
import { userRole } from '../../../constants/userRole'
import { SiteStatusIcon } from '../SiteDashboard/SiteStatusIcon'

export interface SiteCardProps {
	siteGroup_Id: string
	siteGroup_Name: string
	siteGroup_Children: SiteCardProps[]
	sites: Site[]
	removeSite: (site_Id: string) => void
	removeSiteGroup: (siteGroup_Id: string) => void
}

// Recursively get a total count of Assets for this site group and all its children
export const calculateTotalAlerts = (siteGroup_Children: SiteCardProps[], sites: Site[], currentAlertCount: number) => {
	currentAlertCount += sites.reduce((sum, site) => sum + site.asset_Count, 0)

	if (siteGroup_Children.length > 0) {
		siteGroup_Children.forEach((siteGroup_Child) => {
			currentAlertCount = calculateTotalAlerts(siteGroup_Child.siteGroup_Children, siteGroup_Child.sites, currentAlertCount)
		})
	}

	return currentAlertCount
}

export const SiteCard = (props: SiteCardProps) => {
	const context = useContext(AppContext)
	const navigate = useNavigate()

	const [showSiteDeleteModal, setShowSiteDeleteModal] = React.useState<boolean>(false)
	const [siteToDelete, setSiteToDelete] = React.useState<string | null>(null)

	const [showSiteGroupDeleteModal, setShowSiteGroupDeleteModal] = React.useState<boolean>(false)
	const [siteGroupToDelete, setSiteGroupToDelete] = React.useState<string | null>(null)

	// Recursively generate the site group tree, listing out each groups sites as we move down the tree
	const generateBody = (siteGroup_Children: SiteCardProps[], sites: Site[], depth: number): React.ReactNode => {
		const siteDepthStyle = { marginLeft: `${depth * 30}px` }

		const listSites = (): React.ReactNode => (
			<>
				{sites.map((site: Site) => (
					<Row key={site.site_Id} className="site-card-list-body-row">
						<Col sm={4} className="site-card-list-name">
							<Link
								className="stealthLink"
								to={`site`}
								style={siteDepthStyle}
								onClick={() =>
									context.setAppState({
										state: 'setCurrentSite',
										data: { site: site },
									})
								}
							>
								{site.site_Name}
							</Link>
						</Col>
						<Col sm={1} className="center-flex">
							<SiteStatusIcon site_IsAliveTs={site.site_IsAliveTs} />
						</Col>
						{context.appState.userAttributes.userRole_Id === userRole['System Admin'].id ? (
							<Col sm={4}>
								<Row>
									<Col>
										{site.site_IsAliveTs
											? formatIncomingDateTime({ dateTime: site.site_IsAliveTs, format: 'TimeAgo' })
											: 'No readings ever received from this site '}
									</Col>
								</Row>
								<Row>
									<Col>{site.detailedStatus}</Col>
								</Row>
							</Col>
						) : (
							<Col sm={4} className="d-flex align-items-center">
								{site.site_IsAliveTs
									? formatIncomingDateTime({ dateTime: site.site_IsAliveTs, format: 'TimeAgo' })
									: 'No readings ever received from this site'}
							</Col>
						)}

						<Col sm={1} className="center-flex">
							{site.site_Version ? site.site_Version : 'Unknown'}
						</Col>
						<Col sm={1} className="center-flex">
							{site.asset_Count}
						</Col>
						<PermissionsCheck object={PermissionModelObject.Site} action={PermissionModelAction.PUT} context={PermissionModelContext.None}>
							<Col sm={1} className="center-flex">
								<Dropdown>
									<Dropdown.Toggle
										as="img"
										src={ellipsis}
										alt="Site options"
										className="site-card-dropdown-toggle"
										data-testid={`site-options-${site.site_Id}`}
									/>
									<Dropdown.Menu>
										<Dropdown.Item
											onClick={() => {
												context.setAppState({
													state: 'setCurrentSite',
													data: { site: site },
												})
												navigate('siteSettings')
											}}
										>
											Edit Site
										</Dropdown.Item>
										<PermissionsCheck
											object={PermissionModelObject.Site}
											action={PermissionModelAction.DELETE}
											context={PermissionModelContext.None}
										>
											<Dropdown.Item
												onClick={() => {
													setSiteToDelete(site.site_Id)
													setShowSiteDeleteModal(true)
												}}
											>
												Delete Site
											</Dropdown.Item>
										</PermissionsCheck>
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</PermissionsCheck>
					</Row>
				))}
			</>
		)

		if (siteGroup_Children.length > 0) {
			return siteGroup_Children.map((siteGroup_Child: SiteCardProps) => (
				<React.Fragment key={siteGroup_Child.siteGroup_Id}>
					{listSites()}
					<Row>
						<Col style={siteDepthStyle}>
							<span className="site-card-list-heading">
								<Link
									className="stealthLink"
									to={`siteGroup`}
									onClick={async () => {
										const siteGroupRes = await Request.get<SiteGroupResult>(
											`siteGroup?id=${siteGroup_Child.siteGroup_Id}`,
											context.appState.authState
										)
										if (siteGroupRes.data.siteGroups.length > 0) {
											context.setAppState({
												state: 'setCurrentSiteGroup',
												data: { siteGroup: siteGroupRes.data.siteGroups[0] },
											})
										}
									}}
								>
									{siteGroup_Child.siteGroup_Name}
								</Link>
							</span>
						</Col>
					</Row>
					<Row>
						<Col>{generateBody(siteGroup_Child.siteGroup_Children, siteGroup_Child.sites, depth + 1)}</Col>
					</Row>
				</React.Fragment>
			))
		} else {
			return listSites()
		}
	}

	return (
		<>
			<Card className="site-card">
				<Row className="site-card-list-heading-row">
					<Col sm={4} className="d-flex align-items-center">
						<img src={card} alt="" />
						<span className="site-card-list-heading">
							{props.siteGroup_Name ? (
								<Link
									className="stealthLink"
									to={`siteGroup`}
									onClick={async () => {
										const siteGroupRes = await Request.get<SiteGroupResult>(
											`siteGroup?id=${props.siteGroup_Id}`,
											context.appState.authState
										)
										if (siteGroupRes.data.siteGroups.length > 0) {
											context.setAppState({
												state: 'setCurrentSiteGroup',
												data: { siteGroup: siteGroupRes.data.siteGroups[0] },
											})
										}
									}}
								>
									{props.siteGroup_Name}
								</Link>
							) : (
								props.sites[0].site_Name
							)}
						</span>
					</Col>
					<Col sm={6}></Col>
					<Col sm={1} className="center-flex site-card-font-weight-bold">
						{calculateTotalAlerts(props.siteGroup_Children, props.sites, 0)}
					</Col>
					<PermissionsCheck object={PermissionModelObject.SiteGroup} action={PermissionModelAction.PUT} context={PermissionModelContext.None}>
						<Col sm={1} className="center-flex">
							{props.siteGroup_Name && (
								<Dropdown>
									<Dropdown.Toggle as="img" src={ellipsis} alt="Group options" className="site-card-dropdown-toggle" />
									<Dropdown.Menu>
										<Dropdown.Item
											onClick={() => {
												navigate(`/editSiteGroup/${props.siteGroup_Id}`)
											}}
										>
											Edit Site Group
										</Dropdown.Item>
										<PermissionsCheck
											object={PermissionModelObject.SiteGroup}
											action={PermissionModelAction.DELETE}
											context={PermissionModelContext.None}
										>
											<Dropdown.Item
												onClick={() => {
													setSiteGroupToDelete(props.siteGroup_Id)
													setShowSiteGroupDeleteModal(true)
												}}
											>
												Delete Site Group
											</Dropdown.Item>
										</PermissionsCheck>
									</Dropdown.Menu>
								</Dropdown>
							)}
						</Col>
					</PermissionsCheck>
				</Row>

				{generateBody(props.siteGroup_Children, props.sites, 0)}
			</Card>

			{siteToDelete && (
				<ConfirmModal
					show={showSiteDeleteModal}
					setShow={setShowSiteDeleteModal}
					title={'Delete Site'}
					body={`Are you sure you want to delete ${props.sites.find((s) => s.site_Id === siteToDelete)?.site_Name || 'this site'}?`}
					confirmButtonText="Delete"
					onConfirm={() => {
						setSiteToDelete(null)
						props.removeSite(siteToDelete)
					}}
				/>
			)}

			{siteGroupToDelete && (
				<ConfirmModal
					show={showSiteGroupDeleteModal}
					setShow={setShowSiteGroupDeleteModal}
					title={'Delete Site Group'}
					body={`Are you sure you want to delete ${props.siteGroup_Name || 'this site group'}?`}
					confirmButtonText="Delete"
					onConfirm={() => {
						setSiteGroupToDelete(null)
						props.removeSiteGroup(siteGroupToDelete)
					}}
				/>
			)}
		</>
	)
}
