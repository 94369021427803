import React from 'react'
import './ContractCard.css'
import card from '../../../images/icons/group.svg'
import { Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import { Contract } from '../../../models/Contract'
import { PaymentAgreement } from '../../../models/PaymentAgreement'

export interface ContractCardProps {
	contract: Contract
}

export const ContractCard = (props: ContractCardProps) => {
	const totalPaymentAgreementAssets = React.useMemo(() => {
		return props.contract.contract_PaymentAgreements.reduce((accumulator, agreement) => {
			return accumulator + agreement.paymentAgreement_AssetCountAssigned
		}, 0)
	}, [props.contract.contract_PaymentAgreements])

	return (
		<>
			<Card className="contract-card">
				<Row className="contract-card-list-heading-row">
					<Col sm={4} className="d-flex align-items-center">
						<img src={card} alt="" />
						<span className="contract-card-list-heading">
							<Link
								className="stealthLink"
								to={{
									pathname: `/contract/${props.contract.contract_Id}`,
								}}
							>
								<Row>
									<Col>{props.contract.site?.site_Name || props.contract.siteGroup?.siteGroup_Name}</Col>
								</Row>
								<Row>
									<Col>{`${props.contract.contract_VisualNumber}_${props.contract.contract_VisualShipTo}_${props.contract.contract_Number}`}</Col>
								</Row>
							</Link>
						</span>
					</Col>
					<Col sm={7}></Col>
					<Col sm={1} className="center-flex contract-card-font-weight-bold">
						{totalPaymentAgreementAssets}
					</Col>
				</Row>

				{props.contract.contract_PaymentAgreements.map((paymentAgreement: PaymentAgreement) => {
					return (
						<Row key={paymentAgreement.paymentAgreement_Id} className="contract-card-list-body-row">
							<Col sm={4} className="contract-card-list-name">
								{paymentAgreement.paymentAgreement_OrderNumber}
							</Col>
							<Col sm={7}>
								{formatIncomingDateTime({
									dateTime: paymentAgreement.paymentAgreement_StartDate || '',
									format: 'Date',
								})}
								{' - '}
								{formatIncomingDateTime({
									dateTime: paymentAgreement.paymentAgreement_EndDate || '',
									format: 'Date',
								})}
							</Col>
							<Col sm={1} className="center-flex">
								{paymentAgreement.paymentAgreement_AssetCountAssigned} / {paymentAgreement.paymentAgreement_AssetCount}
							</Col>
						</Row>
					)
				})}
			</Card>
		</>
	)
}
