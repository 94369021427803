import React from 'react'
import { Button, Card, Col, Dropdown, Form, Row, Table } from 'react-bootstrap'
import './Listing.css'

import * as Paging from '../Paginator/Paginator'
import * as Sort from './SortFilter'
import { Loading } from '../Loading/Loading'
import chevron from '../../../images/icons/chevron.svg'

interface FilterDropdownOption {
	value: string
	text: string
}

type AfterContent = Record<string, JSX.Element | null>

interface ListingColumn<T> {
	// body stuff
	value: (item: T) => string
	render: (item: T) => JSX.Element
	// header stuff
	showHeader: boolean
	headerText?: string
	sortColumnName?: keyof T | string
	filterType?: 'string' | 'dropdown'
	filterOptions?: {
		columnName: keyof T | string
		options?: FilterDropdownOption[]
	}
	centerColumn?: boolean
}

interface ListingProps<T> {
	name: string
	namePlural: string
	getIDFunc: (type: T) => string // (t: T) => t.myID
	selectedActions: {
		name: string
		method: (selected: string[]) => Promise<void>
	}[]
	list: T[]
	headerButtons?: { content?: JSX.Element; onClick?: () => void }[]
	columns: ListingColumn<T>[]
	defaultSearch?: SearchFilter<T>
	defaultSort: Sort.SortFilter<T>
	isLoading: boolean
	afterContent?: AfterContent // Record of content to be rendered in new <tr> below existing <tr> for a given ID string, full column width excluding selected checkbox
}

type SearchFilter<T> = Record<keyof Partial<T> | string, string>

const Listing = <T,>(props: ListingProps<T>) => {
	const [pageFilter, setPageFilter] = React.useState<Paging.FiltersState>({ page: 1, pageSize: Paging.getPagingPreferences() })
	const [searchFilters, setSearchFilters] = React.useState<SearchFilter<T>>(props.defaultSearch || ({} as SearchFilter<T>))
	const [sortFilters, setSortFilters] = React.useState<Sort.SortFilter<T>>(props.defaultSort)
	const [selectFilter, setSelectFilter] = React.useState<string[]>([])

	const searchFilterFunction = (record: T) =>
		props.columns.every((column) => {
			if (column.filterOptions) {
				const filterText = searchFilters[column.filterOptions.columnName]
				const value = column.value(record)

				if (filterText === 'Any' && column.filterType === 'dropdown') {
					return true
				}
				if (filterText && value) {
					return value.toLowerCase().includes(filterText.toLowerCase())
				} else if (filterText && !value) {
					return false
				}
			}
			return true
		})

	const pageFilterFunction = (_record: T, index: number) => Paging.pageFilter(pageFilter, index)
	const sortFunction = Sort.getSortFunction(sortFilters, props.columns)

	const unFilteredRecords = props.list
	const filteredRecords = unFilteredRecords.filter(searchFilterFunction)
	const sortedFilteredRecords = filteredRecords.sort(sortFunction)
	const paginatedFilteredSortRecords = sortedFilteredRecords.filter(pageFilterFunction)

	const setSelect = (id: string, checked: boolean) => {
		if (checked && id === 'All') {
			setSelectFilter(filteredRecords.map(props.getIDFunc))
		} else if (checked) {
			setSelectFilter([...selectFilter, id])
		} else if (id === 'All') {
			setSelectFilter([])
		} else {
			setSelectFilter(selectFilter.filter((t) => t !== id))
		}
	}

	const hasActions = props.selectedActions && props.selectedActions.length > 0

	return (
		<>
			<Row className="listing-action-row  mb-3">
				{props.selectedActions.length > 0 && (
					<Col sm={'auto'}>
						<Dropdown>
							<Dropdown.Toggle
								className="action-dropdown d-flex justify-content-between align-items-center px-3 hide-after"
								variant="secondary"
								id="dropdown-action"
							>
								Action
								<img src={chevron} />
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{props.selectedActions.map((action) => (
									<Dropdown.Item
										key={action.name}
										onClick={async () => {
											await action.method(selectFilter)
											setSelectFilter([])
										}}
									>
										{action.name}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				)}
				{!props.isLoading && hasActions && (
					<Col sm={'auto'} className="center-flex">
						<span className="span-14 span-bold">
							{selectFilter.length} {selectFilter.length === 1 ? props.name : props.namePlural} selected
						</span>
					</Col>
				)}
				<Col />
				{props.headerButtons?.map(
					(button, index) =>
						button.content && (
							<Col key={index} sm={'auto'}>
								<Button className=" btn btn-primary round listing-header-btn" onClick={button.onClick} disabled={props.isLoading}>
									{button.content}
								</Button>
							</Col>
						)
				)}
			</Row>
			<Card className="pt-0">
				<Row>
					<Col className="table-wrapper">
						<Table borderless className="listing-table">
							<thead className="thead-listing">
								<tr>
									{hasActions && (
										<th className="sticky-th">
											<Row>
												<Col className="upper-table-header justify-content-center">
													<span>Select</span>
												</Col>
											</Row>
											<Row className="card-title-filter">
												<Col className="center-flex">
													<Form.Check
														className="checkbox"
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															setSelect(event.target.value, event.target.checked)
														}}
														id={'select_' + props.name}
														name={'select_' + props.name}
														value={'All'}
													/>
												</Col>
											</Row>
										</th>
									)}
									{props.columns.map((column, index) => (
										<th key={index} className="sticky-th">
											{column.showHeader && column.headerText && (
												<>
													<Row>
														<Col className={`upper-table-header`}>
															{column.sortColumnName && column.centerColumn && <Col sm="auto" className="fake-sort-button" />}
															<span>{column.headerText}</span>
															{column.sortColumnName && (
																<Sort.SortButton
																	sort={sortFilters}
																	setSort={setSortFilters}
																	columnName={column.sortColumnName}
																/>
															)}
														</Col>
													</Row>
													<Row className="card-title-filter">
														{column.filterType && column.filterOptions ? (
															<Col>
																{column.filterType === 'string' && (
																	<Form.Control
																		onChange={(event) => {
																			setSearchFilters({
																				...searchFilters,
																				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
																				[column.filterOptions!.columnName]: event.target.value,
																			})
																			setPageFilter({ page: 1, pageSize: Paging.getPagingPreferences() })
																		}}
																		type="text"
																		id={'search_' + String(column.filterOptions.columnName)}
																		name={'search_' + String(column.filterOptions.columnName)}
																		value={searchFilters[column.filterOptions.columnName] || ''}
																		placeholder="Type to filter"
																	/>
																)}
																{column.filterType === 'dropdown' && (
																	<Dropdown>
																		<Dropdown.Toggle
																			className="form w-100 filter-dropdown-toggle"
																			variant="secondary"
																			id="dropdown-assetType"
																		>
																			{column.filterOptions?.options?.find(
																				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
																				(option) => option.value === searchFilters[column.filterOptions!.columnName]
																			)?.text || 'Any'}
																		</Dropdown.Toggle>
																		<Dropdown.Menu className="listing-dropdown">
																			<Dropdown.Item
																				onClick={async () => {
																					setSearchFilters({
																						...searchFilters,
																						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
																						[column.filterOptions!.columnName]: 'Any',
																					})
																					setPageFilter({ page: 1, pageSize: Paging.getPagingPreferences() })
																				}}
																			>
																				Any
																			</Dropdown.Item>
																			{column.filterOptions.options &&
																				column.filterOptions.options.map((option) => (
																					<Dropdown.Item
																						key={option.value}
																						onClick={async () => {
																							setSearchFilters({
																								...searchFilters,
																								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
																								[column.filterOptions!.columnName]: option.value,
																							})
																							setPageFilter({ page: 1, pageSize: Paging.getPagingPreferences() })
																						}}
																					>
																						{option.text}
																					</Dropdown.Item>
																				))}
																		</Dropdown.Menu>
																	</Dropdown>
																)}
															</Col>
														) : (
															<Col></Col>
														)}
													</Row>
												</>
											)}
										</th>
									))}
								</tr>
							</thead>

							<tbody>
								{props.isLoading ? (
									<tr>
										<td colSpan={props.columns.length + 1}>
											<Row>
												<Col className="d-flex align-items-center justify-content-center">
													<Loading show />
												</Col>
											</Row>
										</td>
									</tr>
								) : props.list.length > 0 ? (
									paginatedFilteredSortRecords.map((item, index) => (
										<React.Fragment key={props.getIDFunc(item)}>
											<tr>
												{hasActions && (
													<td className={`center-td ${index % 2 === 0 ? 'even-td' : 'odd-td'}`}>
														<Form.Check
															className="checkbox"
															onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
																setSelect(event.target.value, event.target.checked)
																console.log(event.target.value, event.target.checked)
															}}
															id={`select_${props.getIDFunc(item)}`}
															name={`select_${props.getIDFunc(item)}`}
															checked={selectFilter.find((select) => select === props.getIDFunc(item)) !== undefined}
															value={props.getIDFunc(item)}
														/>
													</td>
												)}
												{props.columns.map((column) => (
													<td
														key={`${props.getIDFunc(item)}_${column.headerText}`}
														className={`${column.centerColumn ? 'center-td' : 'left-td'} ${
															index % 2 === 0 ? 'even-td' : 'odd-td'
														} `}
													>
														{column.render(item)}
													</td>
												))}
											</tr>
											{props.afterContent &&
												props.afterContent[props.getIDFunc(item)] &&
												props.afterContent[props.getIDFunc(item)] !== null && (
													<tr>
														{hasActions && (
															// Spacer for checkbox col
															<td className={`center-td ${index % 2 === 0 ? 'even-td' : 'odd-td'}`} />
														)}
														<td className={`center-td ${index % 2 === 0 ? 'even-td' : 'odd-td'}`} colSpan={props.columns.length}>
															{props.afterContent[props.getIDFunc(item)]}
														</td>
													</tr>
												)}
										</React.Fragment>
									))
								) : (
									<tr>
										<td colSpan={props.columns.length + 1}>
											<Row>
												<Col className="d-flex align-items-center justify-content-center">{`No ${props.namePlural} Loaded`}</Col>
											</Row>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Card>
			{!props.isLoading && (
				<Row className="mb-4">
					<Col sm="auto" className="center-flex">
						<span className="span-grey">
							<span className="span-bold">{Paging.calculatePageStart(pageFilter)}</span>
							{' to '}
							<span className="span-bold">{Paging.calculatePageTo(pageFilter, filteredRecords.length)}</span>
							{' of '}
							<span className="span-bold">
								{filteredRecords.length} {props.namePlural.toLowerCase()}
							</span>
							{unFilteredRecords.length !== filteredRecords.length && (
								<>
									<span className="span-bold">{' shown '}</span>
									{`(${unFilteredRecords.length} ${props.namePlural.toLowerCase()} total)`}
								</>
							)}
						</span>
					</Col>
					<Col />
					<Col sm="auto" className="center-flex">
						<Paging.Paginator filters={pageFilter} setFilterDispatch={setPageFilter} allPaginatedRecordsLength={filteredRecords.length} />
					</Col>
					<Col />
					<Col sm="auto" className="center-flex">
						<Dropdown>
							<Dropdown.Toggle
								className="records-dropdown d-flex justify-content-between align-items-center px-3 hide-after"
								variant="secondary"
								id="dropdown-action"
							>
								<span className="span-14">Records per page</span>
								{pageFilter.pageSize}
								<img src={chevron} />
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item
									onClick={() => {
										setPageFilter({ page: 1, pageSize: 10 })
										Paging.storePagingPreferences(10)
									}}
								>
									10 records
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setPageFilter({ page: 1, pageSize: 25 })
										Paging.storePagingPreferences(25)
									}}
								>
									25 records
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setPageFilter({ page: 1, pageSize: 50 })
										Paging.storePagingPreferences(50)
									}}
								>
									50 records
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setPageFilter({ page: 1, pageSize: 100 })
										Paging.storePagingPreferences(100)
									}}
								>
									100 records
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Row>
			)}
		</>
	)
}

export { Listing }
export type { FilterDropdownOption, ListingColumn, SearchFilter, AfterContent }
