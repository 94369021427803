import React from 'react'
import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { Card, Row, Col } from 'react-bootstrap'
import { DateTime } from 'luxon'

import { Loading } from '../Loading/Loading'
import { FormText } from '../Form/Text'
import { MessageAction } from '../Messages/Message'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import PermissionsCheck from '../../Permissions/PermissionsCheck'

import { TaskComment, TaskCommentResult, defaultTaskComment } from '../../../models/TaskComment'
import { PageStatus } from '../../../types/PageStatus'
import { defaultCreated, defaultModified } from '../../../models/History'

import { formatIncomingDateTime } from '../../../utilities/formatDate'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../utilities/permissions/permission.d'

import submit from '../../../images/icons/submit.svg'
import edit from '../../../images/icons/edit.svg'
import close from '../../../images/icons/close.svg'
import trash from '../../../images/icons/trash.svg'

interface TaskCommentsProps {
	pageStatus: PageStatus
	taskId: string | null
	setMessages: (action: MessageAction) => void
}

const TaskComments = (props: TaskCommentsProps) => {
	const context = React.useContext(AppContext)

	const defaultValues = React.useMemo(() => {
		return defaultTaskComment({
			task_Id: props.taskId || '',
			created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
			modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
		})
	}, [props.taskId])

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [comments, setComments] = React.useState<TaskComment[] | null>(null)
	const [newComment, setNewComment] = React.useState<TaskComment>(defaultValues)

	React.useEffect(() => {
		const fetchData = async () => {
			const commentsReq = await Request.get<TaskCommentResult>(`taskComment?Task_Id=${props.taskId}`, context.appState.authState)
			setComments(commentsReq.data.taskComments)
			setPageStatus('Ready')
		}
		if (context.appState.authState.isLoggedIn && props.taskId) {
			fetchData()
		}
	}, [props.taskId])

	const handleSave = async (values: TaskComment, formikHelpers: FormikHelpers<TaskComment>) => {
		setPageStatus('Submitting')
		values.taskComment_Ts = values.taskComment_Id ? values.taskComment_Ts : DateTime.utc().toISO() || ''
		await Request.handleRequest(
			() =>
				values.taskComment_Id
					? Request.put<TaskCommentResult>('taskComment', values, context.appState.authState)
					: Request.post<TaskCommentResult>('taskComment', values, context.appState.authState),
			{
				successFunction: (data) => {
					setComments((prev) =>
						values.taskComment_Id
							? (prev || []).map((tc) => (tc.taskComment_Id === data.taskComments[0].taskComment_Id ? data.taskComments[0] : tc))
							: [...data.taskComments, ...(prev || [])]
					)
					setNewComment(defaultValues)
					formikHelpers.resetForm()
				},
				setMessageFunction: props.setMessages,
				messageAction: values.taskComment_Id ? 'editing' : 'creating',
				messageObject: 'comment',
			}
		)
		setPageStatus('Ready')
	}

	const handleDelete = async (taskCommentId: string) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.del<TaskCommentResult>(`taskComment?Id=${taskCommentId}`, context.appState.authState), {
			successFunction: () => {
				setComments((prev) => (prev || []).filter((tc) => tc.taskComment_Id !== taskCommentId))
			},
			setMessageFunction: props.setMessages,
			messageAction: 'deleting',
			messageObject: 'comment',
		})
		setPageStatus('Ready')
	}

	return (
		<Card style={styles.card} className="site-card">
			<Row className="generic-card-list-heading-row">
				<Col sm="auto" style={styles.cellGrow}>
					<span className="dashboard-card-titles">Comments</span>
				</Col>
			</Row>
			{comments ? (
				<>
					<TaskCommentBox comments={comments} handleSave={handleSave} pageStatus={pageStatus} handleDelete={handleDelete} />
					{/* Although TaskComment has its own object, it is governed by Task permissions */}
					<PermissionsCheck object={PermissionModelObject.TaskComment} action={PermissionModelAction.POST} context={PermissionModelContext.Site}>
						<NewTaskComment taskComment={newComment} handleSave={handleSave} pageStatus={pageStatus} />
					</PermissionsCheck>
				</>
			) : props.pageStatus === 'Loading' ? (
				<Loading show={true} />
			) : null}
		</Card>
	)
}

interface TaskCommentBoxProps {
	comments: TaskComment[]
	pageStatus: PageStatus
	handleSave: (values: TaskComment, formikHelpers: FormikHelpers<TaskComment>) => Promise<void>
	handleDelete: (taskCommentId: string) => Promise<void>
}

const TaskCommentBox = (props: TaskCommentBoxProps) => {
	return props.comments.length > 0 ? (
		<div className="scroll-box">
			{props.comments.map((comment) => (
				<TaskCommentEntry key={comment.taskComment_Id} taskComment={comment} {...props} />
			))}
		</div>
	) : null
}

interface TaskCommentEntryProps {
	taskComment: TaskComment
	pageStatus: PageStatus
	handleSave: (values: TaskComment, formikHelpers: FormikHelpers<TaskComment>) => Promise<void>
	handleDelete: (taskCommentId: string) => Promise<void>
}

const TaskCommentEntry = (props: TaskCommentEntryProps) => {
	const { appState } = React.useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Ready')
	const [showModal, setShowModal] = React.useState<boolean>(false)

	const commentIsEdited =
		DateTime.fromISO(props.taskComment.modified.modified_Ts).diff(DateTime.fromISO(props.taskComment.created.create_Ts), 'seconds').seconds > 5

	const handleSave = async (values: TaskComment, formikHelpers: FormikHelpers<TaskComment>) => {
		await props.handleSave(values, formikHelpers)
		setPageStatus('Ready')
	}

	const handleCancel = (resetForm: () => void) => {
		resetForm()
		setPageStatus('Ready')
	}

	return (
		<>
			<ConfirmModal
				show={showModal}
				setShow={setShowModal}
				title={'Confirm delete'}
				body={'Are you sure you want to delete this comment?'}
				onConfirm={() => props.handleDelete(props.taskComment.taskComment_Id)}
			/>
			<Formik initialValues={props.taskComment} validationSchema={taskCommentValidationSchema} onSubmit={handleSave} enableReinitialize>
				{({ handleSubmit, errors, values, setFieldValue, resetForm }) => (
					<Row style={styles.row}>
						<Col sm={11}>
							<Col>
								<span style={styles.label}>
									{values.created.create_UserFirstName} {values.created.create_UserLastName}
								</span>{' '}
								- {formatIncomingDateTime({ dateTime: values.taskComment_Ts, format: 'TimeAgo' })}{' '}
								{commentIsEdited ? (
									<span style={styles.edited}>
										(Last edited {formatIncomingDateTime({ dateTime: values.modified.modified_Ts, format: 'TimeAgo' })})
									</span>
								) : null}
							</Col>
							<Col>
								{pageStatus === 'Editing' ? (
									<FormText
										name={'taskComment_Note'}
										label=""
										as="textarea"
										rows={2}
										value={values.taskComment_Note}
										placeholder="Add Comment"
										onChange={(e) => setFieldValue('taskComment_Note', e.target.value)}
										disabled={props.pageStatus === 'Submitting'}
										feedback={errors.taskComment_Note}
										isInvalid={!!errors.taskComment_Note}
										readOnly={pageStatus !== 'Editing'}
									/>
								) : (
									<Col>{values.taskComment_Note}</Col>
								)}
							</Col>
						</Col>
						<Col sm={1} className="center-flex">
							{pageStatus === 'Editing' ? (
								<Row className="no-gutter">
									<Col className="center-flex">
										<img src={close} alt="Cancel icon" style={styles.icon} onClick={() => handleCancel(resetForm)} />
									</Col>
									<Col className="center-flex">
										<img src={submit} alt="Submit icon" style={styles.icon} onClick={() => handleSubmit()} />
									</Col>
								</Row>
							) : appState.userAttributes.user_Id === props.taskComment.created.create_UserId ? (
								<Row className="not-gutter">
									<Col className="center-flex">
										<img src={edit} alt="Edit icon" style={styles.icon} onClick={() => setPageStatus('Editing')} />
									</Col>
									<Col className="center-flex">
										<img src={trash} alt="Delete icon" style={styles.icon} onClick={() => setShowModal(true)} />
									</Col>
								</Row>
							) : null}
						</Col>
					</Row>
				)}
			</Formik>
		</>
	)
}

const taskCommentValidationSchema = yup.object().shape({
	taskComment_Note: yup.string().required('Task comment text is required.').max(1000, 'Max character limit is 1000.'),
})

interface NewTaskCommentProps {
	pageStatus: PageStatus
	taskComment: TaskComment
	handleSave: (values: TaskComment, formikHelpers: FormikHelpers<TaskComment>) => Promise<void>
}

const NewTaskComment = (props: NewTaskCommentProps) => {
	return (
		<Formik initialValues={props.taskComment} validationSchema={taskCommentValidationSchema} onSubmit={props.handleSave} enableReinitialize>
			{({ handleSubmit, errors, values, setFieldValue }) => (
				<Row style={styles.row}>
					<Col sm={11}>
						<FormText
							name={'taskComment_Note'}
							label=""
							as="textarea"
							rows={2}
							value={values.taskComment_Note}
							placeholder="Add Comment"
							onChange={(e) => setFieldValue('taskComment_Note', e.target.value)}
							disabled={props.pageStatus === 'Submitting'}
							feedback={errors.taskComment_Note}
							isInvalid={!!errors.taskComment_Note}
						/>
					</Col>
					<Col sm={1} className="center-flex">
						<img src={submit} alt="Submit icon" style={styles.icon} onClick={() => handleSubmit()} />
					</Col>
				</Row>
			)}
		</Formik>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	card: {
		marginTop: '20px',
		minHeight: '200px',
	},
	row: {
		padding: '20px 20px 0px 20px',
		justifyContent: 'space-between',
		minHeight: '106px', // same as min text area height to avoid elements jumping around when switching to edit
	},
	cellGrow: {
		flex: '1 1 auto',
	},
	icon: {
		width: '35px',
		height: '35px',
		cursor: 'pointer',
	},
	boxRow: {
		flexDirection: 'column',
		padding: '10px',
	},
	label: {
		fontWeight: 'bold',
	},
	edited: {
		fontStyle: 'italic',
		fontWeight: 'lighter',
		fontSize: '12px',
	},
}

export { TaskComments }
