import React from 'react'
import './StatsCard.css'
import { Row, Col, Container } from 'react-bootstrap'

import site from '../../../images/icons/site.svg'
import bell from '../../../images/icons/bell.svg'
import todo from '../../../images/icons/tasks.svg'
import event from '../../../images/icons/event.svg'
import { PageStatus } from '../../../types/PageStatus'
import { Loading } from '../Loading/Loading'
import { SeverityIcon } from '../SeverityIcon/SeverityIcon'
import { SeverityId } from '../../../constants/severity'

type icons = 'site' | 'event' | 'check' | 'cross' | 'bell' | 'todo'

export interface StatsCardStat {
	severityId: SeverityId
	text: string
}
interface StatsCardProps {
	icon: icons
	title: string
	subtitle: string
	stats: StatsCardStat[]
	pageStatus: PageStatus
}

export const StatsCard = (props: StatsCardProps) => {
	const convertStringToIcon = (iconString: icons) => {
		switch (iconString) {
			case 'site':
				return site
			case 'event':
				return event
			case 'bell':
				return bell
			case 'todo':
				return todo
			default:
				return undefined
		}
	}

	return (
		<Container>
			<Row className="stats-card">
				<Col className="stats-card-main">
					<img className="stats-card-icon" src={convertStringToIcon(props.icon)} alt={props.title} />
					{props.pageStatus !== 'Loading' ? <p className="dashboard-card-stat"> {props.title}</p> : <Loading show />}
					<p className="dashboard-card-subtitle">{props.subtitle.toUpperCase()}</p>
				</Col>
				<Col className="stats-card-stats">
					{props.stats.map((stat, index) => {
						return (
							<Row key={index}>
								<Col>
									{props.pageStatus !== 'Loading' ? (
										<>
											<span>{stat.text}</span>
											<SeverityIcon severityId={stat.severityId} style={styles.severityIcon} />
										</>
									) : null}
								</Col>
							</Row>
						)
					})}
				</Col>
			</Row>
		</Container>
	)
}

const styles = {
	severityIcon: {
		width: '15px',
		height: '15px',
	},
}
