import React, { useContext } from 'react'
import { AppContext } from '../../App'
import PickerHeader from '../../components/UI/ContextualPickers/PickerHeader'
import ReportDisplay from '../../components/UI/Reports/ReportDisplay'
import Error from '../../components/UI/Error/Error'
import { Tab, Tabs } from 'react-bootstrap'
import ReportArchive from '../../components/UI/Reports/ReportArchive'
import FleetHealthReport from '../../components/UI/Reports/FleetHealthReport'

/* 
	Reports Architecture Explanation

	The flow of how a user accesses report data to generate a report is as follows:
	1. 	User controls report data parameters on the front end (<Report>Listing.tsx files)
	2. 	This hits the back-end api to fetch that data and display in the listing (with various query string params 
		that match the UI elements)
	3. 	When the users wants a pdf of that data, the same parameters are sent to the report-builder (plus some 
		extra report-only params that a couple of the reports need such as summary true/false)
	4. 	report-builder api opens the render page with those params
	5. 	that render page uses the params to fetch that same data a user would have seen in the listing, which is 
		then captured into the report pdf and returned to the user

	Most of the query string params relating to report data have been kept the same between API calls and page
	renders to allow the 'data trail' to be followed.

	The main exception to this is the fleet health report, which stores various data required to create the report 
	in the ReportGenerationData table as a JSON object, which is then used by the render page to make the required 
	back-end api calls. This is due to the comments feature which needs to store user entered data to be passed to
	the render.

	As a general rule of thumb, here's where you will need to look to make changes in the report process:
	- 	If it's a purely logical data change it'll be in back-end/report.cs endpoint
	- 	If it's a pdf render change it'll be in the render file of the related report 
	- 	If it's a change in how data is displayed to the user it'll need to be a change in the listing and the render
	- 	If it's a change in how the user selects the data, it's a listing change (with possibly needing new query 
		params added)
*/

const Reports = () => {
	const context = useContext(AppContext)
	const usageContext = context.appState.currentSite !== null ? 'site' : context.appState.currentSiteGroup !== null ? 'siteGroup' : null

	return usageContext ? (
		<>
			<PickerHeader title="Reports" type={usageContext} />
			<Tabs defaultActiveKey={1} id="report-tabs" mountOnEnter>
				<Tab eventKey={1} title="Reports" className="pt-3">
					<ReportDisplay usageContext={usageContext} />
				</Tab>
				<Tab eventKey={2} title="Fleet Health" className="pt-3">
					<FleetHealthReport context={usageContext} />
				</Tab>
				<Tab eventKey={3} title="Report Archive" className="pt-3">
					<ReportArchive context={usageContext} />
				</Tab>
			</Tabs>
		</>
	) : (
		<Error showHomeButton />
	)
}

export default Reports
