import React from 'react'
import './EditAsset.css'
import { v4 as uuidv4 } from 'uuid'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { CommonComponentProps } from '../../../../screens/Asset/EditAssetDetails'
import trash from '../../../../images/icons/trash.svg'
import { Button } from '../../Button/Button'
import { AssetTree, calculateMonitored, calculateMonitoredBy, calculateServiced, calculateServicedBy } from '../../../../models/AssetTree'
import { AssetList } from '../../../../models/Asset'
import { Link } from 'react-router-dom'
import { AssetTreeEditModal } from './AssetTreeEditModal'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../../utilities/permissions/permission.d'
import PermissionsCheck from '../../../Permissions/PermissionsCheck'

const AssetTreeEdit = (props: CommonComponentProps) => {
	const [showModal, setShowModal] = React.useState<boolean>(false)
	const [modalType, setModalType] = React.useState<'Serviced By' | 'Monitored By' | 'Serviced' | 'Monitored'>('Serviced')

	const servicedBy = React.useMemo(() => {
		return calculateServicedBy(props.asset)
	}, [props.asset?.asset_Serviced])

	const monitoredBy = React.useMemo(() => {
		return calculateMonitoredBy(props.asset)
	}, [props.asset?.asset_Serviced])

	const serviced = React.useMemo(() => {
		return calculateServiced(props.asset)
	}, [props.asset?.asset_Serviced])

	const monitored = React.useMemo(() => {
		return calculateMonitored(props.asset)
	}, [props.asset?.asset_Serviced])

	const handleDeleteAssetTree = async (assetTree_Id: string) => {
		props.handleChange(
			'asset.asset_Serviced',
			props.asset.asset_Serviced.filter((at) => at.assetTree_Id !== assetTree_Id)
		)
	}

	const handleAddAssetTree = async (assetTree: AssetTree) => {
		props.handleChange('asset.asset_Serviced', props.asset.asset_Serviced.concat({ ...assetTree, assetTree_Id: uuidv4() }))
	}

	return (
		<Card className="site-card" style={styles.card}>
			<AssetTreeEditModal show={showModal} setShow={setShowModal} handleSubmit={handleAddAssetTree} asset={props.asset} type={modalType} />

			<Row className="generic-card-list-heading-row">
				<Col>
					<span className="dashboard-card-titles">Serviced By (Plumbing)</span>
				</Col>
				<PermissionsCheck object={PermissionModelObject.AssetTree} action={PermissionModelAction.POST} context={PermissionModelContext.Site}>
					<Col sm={'auto'}>
						<Button
							variant="secondary"
							onClick={() => {
								setModalType('Serviced By')
								setShowModal(true)
							}}
						>
							Add Asset
						</Button>
					</Col>
				</PermissionsCheck>
			</Row>
			<AssetTreeDisplay assetTrees={servicedBy} handleDeleteAssetTree={handleDeleteAssetTree} />

			<Row className="generic-card-list-heading-row">
				<Col>
					<span className="dashboard-card-titles">Monitored By (Network)</span>
				</Col>
				<PermissionsCheck object={PermissionModelObject.AssetTree} action={PermissionModelAction.POST} context={PermissionModelContext.Site}>
					<Col sm={'auto'}>
						<Button
							variant="secondary"
							onClick={() => {
								setModalType('Monitored By')
								setShowModal(true)
							}}
						>
							Add Asset
						</Button>
					</Col>
				</PermissionsCheck>
			</Row>
			<AssetTreeDisplay assetTrees={monitoredBy} handleDeleteAssetTree={handleDeleteAssetTree} />

			<Row className="generic-card-list-heading-row">
				<Col>
					<span className="dashboard-card-titles">Assets Serviced (Plumbing)</span>
				</Col>
				<PermissionsCheck object={PermissionModelObject.AssetTree} action={PermissionModelAction.POST} context={PermissionModelContext.Site}>
					<Col sm={'auto'}>
						<Button
							variant="secondary"
							onClick={() => {
								setModalType('Serviced')
								setShowModal(true)
							}}
						>
							Add Asset
						</Button>
					</Col>
				</PermissionsCheck>
			</Row>
			<AssetTreeDisplay assetTrees={serviced} handleDeleteAssetTree={handleDeleteAssetTree} />

			<Row className="generic-card-list-heading-row">
				<Col>
					<span className="dashboard-card-titles">Assets Monitored (Network)</span>
				</Col>
				<PermissionsCheck object={PermissionModelObject.AssetTree} action={PermissionModelAction.POST} context={PermissionModelContext.Site}>
					<Col sm={'auto'}>
						<Button
							variant="secondary"
							onClick={() => {
								setModalType('Monitored')
								setShowModal(true)
							}}
						>
							Add Asset
						</Button>
					</Col>
				</PermissionsCheck>
			</Row>
			<AssetTreeDisplay assetTrees={monitored} handleDeleteAssetTree={handleDeleteAssetTree} />
		</Card>
	)
}

const AssetTreeDisplay = (props: { assetTrees: AssetTree[]; handleDeleteAssetTree: (assetTree_Id: string) => Promise<void> }) => {
	return (
		<Row style={styles.row}>
			{props.assetTrees.length > 0 ? (
				props.assetTrees.map((assetTree) => (
					<Col sm={'auto'} key={assetTree.assetTree_Id} style={styles.col}>
						<AssetTreeCell asset={assetTree.asset} assetTree_Id={assetTree.assetTree_Id} handleDeleteAssetTree={props.handleDeleteAssetTree} />
					</Col>
				))
			) : (
				<span className="card-text">-</span>
			)}
		</Row>
	)
}

const AssetTreeCell = (props: { asset: AssetList; assetTree_Id: string; handleDeleteAssetTree: (assetTree_Id: string) => Promise<void> }) => {
	return (
		<Container fluid style={styles.assetTreeCell}>
			<Row>
				<Col>
					<Link to={`/asset/${props.asset.asset_Id}`}>
						{props.asset.asset_Name} - {props.asset.assetType_Name}
					</Link>
				</Col>
				<PermissionsCheck object={PermissionModelObject.AssetTree} action={PermissionModelAction.DELETE} context={PermissionModelContext.Site}>
					<Col sm={'auto'}>
						<img src={trash} alt={'delete icon'} className="generic-icon-button" onClick={() => props.handleDeleteAssetTree(props.assetTree_Id)} />
					</Col>
				</PermissionsCheck>
			</Row>
			<Row>
				<Col>
					<span className="card-text">
						{props.asset.locationFloor_Name} - {props.asset.locationRoom_Name}
						{props.asset.asset_LocationNote ? ` - ${props.asset.asset_LocationNote}` : ''}
					</span>
				</Col>
			</Row>
		</Container>
	)
}

const styles = {
	card: {
		minHeight: '472px',
	},
	row: {
		padding: '20px 20px 0px 20px',
	},
	col: {
		marginBottom: '20px',
	},
	disabledPlusButton: {
		cursor: 'not-allowed',
	},
	cell50: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '48px',
		flexBasis: '50%',
	},
	assetTreeCell: {
		border: '1px solid #000000',
		width: '305px',
		minHeight: '88px',
		borderRadius: '8px',
		padding: '10px',
	},
}

export { AssetTreeEdit }
