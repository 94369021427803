import { ALL_OBJECTS } from '../permission.d'

// System Admin has all permissions for everything
const GET_PERMISSIONS = ALL_OBJECTS
const POST_PERMISSIONS = ALL_OBJECTS
const PUT_PERMISSIONS = ALL_OBJECTS
const DELETE_PERMISSIONS = ALL_OBJECTS

export const systemAdminPermissionObject = {
	GET: GET_PERMISSIONS,
	POST: POST_PERMISSIONS,
	PUT: PUT_PERMISSIONS,
	DELETE: DELETE_PERMISSIONS,
}
