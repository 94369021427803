import React from 'react'
import { ChevronRight, ChevronDown } from 'react-feather'

interface CollapseChevronProps {
	collapsed: boolean
	updateCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

export const CollapseChevron = (props: CollapseChevronProps) =>
	props.collapsed ? (
		<ChevronRight size="24" onClick={() => props.updateCollapsed(false)} />
	) : (
		<ChevronDown size="24" onClick={() => props.updateCollapsed(true)} />
	)
