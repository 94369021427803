import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { AssetTree } from './AssetTree'
import { DateTime } from 'luxon'
import { assetClass, AssetClassId } from '../constants/assetClass'
import { ChannelMap } from './ChannelMap'
import { AssetGroup } from './AssetGroup'

interface Asset {
	asset_Id: string
	asset_Name: string | null
	asset_SerialNo: string | null
	asset_InstallDate: string | null
	asset_LocationNote: string | null
	asset_RoomsServiced: string | null
	asset_CommNote: string | null
	asset_StandardFitment: boolean
	asset_InstallNote: string | null
	asset_FacilityAssetNumber: string | null
	asset_LastCommunicationTs: DateTime | null
	asset_BacnetPoint: number | null

	assetTransmitter_EUI: string | null
	assetTransmitter_Type: string | null
	assetTransmitter_HardwareVersion: string | null
	assetTransmitter_SoftwareVersion: string | null

	asset_Groups: AssetGroup[]

	hub_Number: number | null
	serialPort_COMPort: string | null
	serialPort_IPAddress: string | null

	site_Id: string
	site_Name: string
	site_Timezone: string

	assetClass_Id: AssetClassId
	assetType_Id: string
	assetType_Name: string
	assetMake_Id: string | null
	assetMake_Name: string
	assetModel_Id: string | null
	assetModel_Name: string

	locationBuilding_Id: string | null
	locationBuilding_Name: string
	locationFloor_Id: string | null
	locationFloor_Name: string
	locationArea_Id: string | null
	locationArea_Name: string
	locationRoom_Id: string | null
	locationRoom_Name: string

	paymentAgreement_Id: string | null
	paymentAgreement_OrderNumber: string | null
	contract_Id: string | null
	contract_Name: string | null

	document_Count: number
	asset_Serviced: AssetTree[]
	channelMap: ChannelMap[]

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface AssetList {
	asset_Id: string
	asset_Name: string | null
	asset_InstallDate: string | null
	asset_LocationNote: string | null

	assetType_Id: string
	assetType_Name: string

	assetClass_Id: AssetClassId

	site_Id: string
	site_Name: string
	site_Timezone: string

	locationBuilding_Id: string | null
	locationBuilding_Name: string
	locationFloor_Id: string | null
	locationFloor_Name: string
	locationArea_Id: string | null
	locationArea_Name: string
	locationRoom_Id: string | null
	locationRoom_Name: string
}

interface AssetStub {
	asset_Id: string
	asset_Name: string | null
	locationBuilding_Name: string | null
	locationFloor_Name: string | null
	locationArea_Name: string | null
	locationRoom_Name: string | null
}

interface AssetMonitoredLiveTempView {
	asset_Id: string
	asset_Name: string
	assetType_Id: string
	assetType_Name: string
}

interface AssetServicedLiveTempView {
	asset_Id: string
	asset_Name: string
	locationRoom_Id: string
	locationRoom_Name: string
}

interface AssetLiveTempView {
	asset_Id: string
	asset_Name: string
	assetType_Id: string
	asset_MonitoredBy: AssetMonitoredLiveTempView[]
	asset_Serviced: AssetServicedLiveTempView[]
	asset_RoomsServiced: string | null
}

interface AssetResult extends Validate.Result {
	assets: Asset[]
}

interface AssetListResult extends Validate.Result {
	assets: AssetList[]
}

const defaultAsset = (asset: Partial<Asset>): Asset => ({
	asset_Id: asset.asset_Id || '',
	asset_Name: asset.asset_Name || null,
	asset_SerialNo: asset.asset_SerialNo || null,
	asset_InstallDate: asset.asset_InstallDate || null,
	asset_LocationNote: asset.asset_LocationNote || null,
	asset_RoomsServiced: asset.asset_RoomsServiced || null,
	asset_CommNote: asset.asset_CommNote || null,
	asset_StandardFitment: asset.asset_StandardFitment || false,
	asset_InstallNote: asset.asset_InstallNote || null,
	asset_FacilityAssetNumber: asset.asset_FacilityAssetNumber || null,
	asset_LastCommunicationTs: asset.asset_LastCommunicationTs || null,
	asset_BacnetPoint: asset.asset_BacnetPoint || null,

	assetTransmitter_EUI: asset.assetTransmitter_EUI || null,
	assetTransmitter_Type: asset.assetTransmitter_Type || null,
	assetTransmitter_HardwareVersion: asset.assetTransmitter_HardwareVersion || null,
	assetTransmitter_SoftwareVersion: asset.assetTransmitter_SoftwareVersion || null,

	asset_Groups: asset.asset_Groups || [],

	hub_Number: asset.hub_Number || null,
	serialPort_COMPort: asset.serialPort_COMPort || null,
	serialPort_IPAddress: asset.serialPort_IPAddress || null,

	site_Id: asset.site_Id || '',
	site_Name: asset.site_Name || '',
	site_Timezone: asset.site_Timezone || 'Australia/Sydney',

	assetClass_Id: asset.assetClass_Id || assetClass.Tmv.id,
	assetType_Id: asset.assetType_Id || '',
	assetType_Name: asset.assetType_Name || '',
	assetMake_Id: asset.assetMake_Id || null,
	assetMake_Name: asset.assetMake_Name || '',
	assetModel_Id: asset.assetModel_Id || null,
	assetModel_Name: asset.assetModel_Name || '',

	locationBuilding_Id: asset.locationBuilding_Id || null,
	locationBuilding_Name: asset.locationBuilding_Name || '',
	locationFloor_Id: asset.locationFloor_Id || null,
	locationFloor_Name: asset.locationFloor_Name || '',
	locationArea_Id: asset.locationArea_Id || null,
	locationArea_Name: asset.locationArea_Name || '',
	locationRoom_Id: asset.locationRoom_Id || null,
	locationRoom_Name: asset.locationRoom_Name || '',

	paymentAgreement_Id: asset.paymentAgreement_Id || null,
	paymentAgreement_OrderNumber: asset.paymentAgreement_OrderNumber || null,
	contract_Id: asset.contract_Id || null,
	contract_Name: asset.contract_Name || null,

	document_Count: asset.document_Count || 0,
	asset_Serviced: asset.asset_Serviced || [],
	channelMap: asset.channelMap || [],

	created: asset.created || defaultCreated({}),
	modified: asset.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

const defaultAssetList = (asset: Partial<Asset>): AssetList => ({
	asset_Id: asset.asset_Id || '',
	asset_Name: asset.asset_Name || null,
	asset_InstallDate: asset.asset_InstallDate || null,
	asset_LocationNote: asset.asset_LocationNote || null,

	assetType_Id: asset.assetType_Id || '',
	assetType_Name: asset.assetType_Name || '',

	assetClass_Id: asset.assetClass_Id || assetClass.Tmv.id,

	site_Id: asset.site_Id || '',
	site_Name: asset.site_Name || '',
	site_Timezone: asset.site_Timezone || 'Australia/Sydney',

	locationBuilding_Id: asset.locationBuilding_Id || null,
	locationBuilding_Name: asset.locationBuilding_Name || '',
	locationFloor_Id: asset.locationFloor_Id || null,
	locationFloor_Name: asset.locationFloor_Name || '',
	locationArea_Id: asset.locationArea_Id || null,
	locationArea_Name: asset.locationArea_Name || '',
	locationRoom_Id: asset.locationRoom_Id || null,
	locationRoom_Name: asset.locationRoom_Name || '',
})

const defaultAssetStub = (asset: Partial<Asset>): AssetStub => ({
	asset_Id: asset.asset_Id || '',
	asset_Name: asset.asset_Name || null,
	locationBuilding_Name: asset.locationBuilding_Name || null,
	locationFloor_Name: asset.locationFloor_Name || null,
	locationArea_Name: asset.locationArea_Name || null,
	locationRoom_Name: asset.locationRoom_Name || null,
})

export { defaultAsset, defaultAssetList, defaultAssetStub }
export type { Asset, AssetList, AssetStub, AssetLiveTempView, AssetServicedLiveTempView, AssetResult, AssetListResult }
