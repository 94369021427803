import React from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { AppContext } from '../../App'

import { Line } from '../../components/UI/Line/Line'
import { Loading } from '../../components/UI/Loading/Loading'
import { FormText } from '../../components/UI/Form/Text'

import { SiteGroup, SiteGroupResult } from '../../models/SiteGroup'
import { PageStatus } from '../../types/PageStatus'

import * as Request from '../../utilities/request'

const nameIsUnique = (siteGroups: SiteGroup[] | null, name: string) => {
	if (siteGroups && siteGroups.some((siteGroup) => siteGroup.siteGroup_Name === name)) {
		return false
	}
	return true
}

interface EditSiteGroupDetailsProps {
	siteGroup: SiteGroup | null
	siteGroups: SiteGroup[] | null
	resetData: boolean
	setResetData: React.Dispatch<React.SetStateAction<boolean>>
}

const EditSiteGroupDetails = (props: EditSiteGroupDetailsProps) => {
	const context = React.useContext(AppContext)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Ready')
	const [editedName, setEditedName] = React.useState<string>(props.siteGroup?.siteGroup_Name || '')
	const [nameError, setNameError] = React.useState<string>('')

	React.useEffect(() => {
		setEditedName(props.siteGroup?.siteGroup_Name || '')
	}, [props.siteGroup])

	const handleChange = (value: string) => {
		if (value.length === 0) {
			setNameError('Name is required.')
		} else if (value.length > 100) {
			setNameError('Maximum name length is 100 characters.')
		} else if (!nameIsUnique(props.siteGroups, value)) {
			setNameError('Name must be unique.')
		} else {
			setNameError('')
		}
		setEditedName(value)
	}

	const handleCancel = () => {
		setEditedName(props.siteGroup?.siteGroup_Name || '')
		setNameError('')
	}

	const handleSave = async () => {
		setPageStatus('Submitting')
		await Request.handleRequest(
			() => Request.put<SiteGroupResult>('siteGroup', { ...props.siteGroup, siteGroup_Name: editedName }, context.appState.authState),
			{
				failedFunction: () => {
					setPageStatus('Error')
				},
				successFunction: () => {
					setPageStatus('Ready')
					props.setResetData(!props.resetData)
				},
			}
		)
	}

	return (
		<Card style={styles.card}>
			{props.siteGroup && props.siteGroups ? (
				<>
					<Row>
						<Col sm="auto" className={'center-flex'}>
							<span className="h4 mb-0">Group Details</span>
						</Col>
					</Row>
					<Line />
					<Row className="mt-3" style={styles.row}>
						<Col sm={2}>
							<span className="form-label">Group Name</span>
						</Col>
						<Col sm={4}>
							<FormText
								name={'siteGroup_Name'}
								label={''}
								value={editedName}
								onChange={(e) => handleChange(e.target.value)}
								disabled={pageStatus === 'Submitting'}
								feedback={nameError}
								isInvalid={!!nameError}
							/>
						</Col>
					</Row>
					<Row className="mt-3">
						<Col sm="auto">
							<Button variant="secondary" onClick={handleCancel} disabled={pageStatus === 'Submitting'}>
								Cancel
							</Button>
						</Col>
						<Col sm="auto">
							<Button variant="primary" onClick={handleSave} disabled={pageStatus === 'Submitting' || nameError.length > 0}>
								Save
							</Button>
						</Col>
					</Row>
				</>
			) : (
				<Row>
					<Col className={'center-flex'}>
						<Loading show />
					</Col>
				</Row>
			)}
		</Card>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	card: {
		minHeight: '200px',
	},
	row: {
		height: '62px',
	},
}

export { EditSiteGroupDetails }
