import { recordStatus } from '../constants/recordStatus'
import * as Validate from '../utilities/validate'
import { Created, Modified, defaultCreated, defaultModified } from './History'
import { groupType } from '../constants/groupType'

interface Group {
	group_Id: string
	group_Name: string
	groupType_Id: string
	groupType_Name: string
	site_Id: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface GroupList {
	group_Id: string
	group_Name: string
	groupType_Id: string
	groupType_Name: string
	site_Id: string
}

interface GroupResult extends Validate.Result {
	groups: Group[]
}

interface GroupListResult extends Validate.Result {
	groups: GroupList[]
}

const defaultGroup = (group: Partial<Group>): Group => ({
	group_Id: group.group_Id || '',
	group_Name: group.group_Name || '',
	groupType_Id: group.groupType_Id || '',
	groupType_Name: group.groupType_Name || '',
	site_Id: group.site_Id || '',

	created: group.created || defaultCreated({}),
	modified: group.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

const defaultGroupList = (group: Partial<Group>): GroupList => ({
	group_Id: group.group_Id || '',
	group_Name: group.group_Name || '',
	groupType_Id: group.groupType_Id || '',
	groupType_Name: group.groupType_Name || '',
	site_Id: group.site_Id || '',
})

const defaultTemperatureGroup = defaultGroupList({
	group_Id: 'DefaultTemperatureGroup',
	group_Name: 'Default Temperature Group',
	groupType_Id: groupType.Temperature.id,
	groupType_Name: 'Temperature',
})

const defaultBatteryGroup = defaultGroupList({
	group_Id: 'DefaultBatteryGroup',
	group_Name: 'Default Battery Group',
	groupType_Id: groupType.Battery.id,
	groupType_Name: 'Battery',
})

const defaultGroups = [defaultTemperatureGroup, defaultBatteryGroup]

export { defaultGroup, defaultGroupList, defaultGroups, defaultTemperatureGroup, defaultBatteryGroup }
export type { Group, GroupList, GroupResult, GroupListResult }
