import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import card from '../../../images/icons/card.svg'
import { DateTime } from 'luxon'
import { Contract } from '../../../models/Contract'
import { PaymentAgreement } from '../../../models/PaymentAgreement'
import { Link } from 'react-router-dom'
import { formatIncomingDateTime } from '../../../utilities/formatDate'

interface ContractSidebarStatsProps {
	contracts: Contract[]
}

const ContractSidebarStats = (props: ContractSidebarStatsProps) => {
	const expiringPaymentAgreements: PaymentAgreement[] = React.useMemo(() => {
		return props.contracts.reduce((accumulator: PaymentAgreement[], contract) => {
			const paymentAgreementsToInclude = contract.contract_PaymentAgreements.filter((paymentAgreement) => {
				const paymentAgreementExpiryDate = DateTime.fromISO(paymentAgreement.paymentAgreement_EndDate || '')
				const daysUntilExpiry = paymentAgreementExpiryDate.diffNow('days').days
				return daysUntilExpiry <= 30 && daysUntilExpiry > 0
			})

			return [...accumulator, ...paymentAgreementsToInclude]
		}, [] as PaymentAgreement[])
	}, [props.contracts])

	return (
		<Card style={styles.contractDashboardStats}>
			<Row style={styles.headerRow}>
				<Col sm="auto">
					<img src={card} alt={'Card Icon'} style={styles.icon} />
				</Col>
				<Col>
					<span className="dashboard-card-titles">Upcoming Expiring Payment Agreements</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<span className="dashboard-card-subtitle">
						You have {expiringPaymentAgreements.length} agreements due to expire within the next 30 days
					</span>
				</Col>
			</Row>
			<div style={styles.midRowGap}></div>
			{expiringPaymentAgreements.map((paymentAgreement) => {
				return (
					<Row key={paymentAgreement.paymentAgreement_Id}>
						<Col>
							<Link
								className="stealthLink"
								to={{
									pathname: `/contract/${paymentAgreement.contract_Id}`,
								}}
							>
								<Row>
									<Col>
										<span className="dashboard-card-subtitle" style={styles.underline}>
											- {paymentAgreement.paymentAgreement_OrderNumber}{' '}
											{formatIncomingDateTime({
												dateTime: paymentAgreement.paymentAgreement_EndDate || '',
												format: 'Date',
											})}{' '}
											(Expires in {Math.ceil(DateTime.fromISO(paymentAgreement.paymentAgreement_EndDate || '').diffNow('days').days)}{' '}
											days)
										</span>
									</Col>
								</Row>
							</Link>
						</Col>
					</Row>
				)
			})}
		</Card>
	)
}

const styles = {
	contractDashboardStats: {
		marginTop: '0px',
		paddingTop: '30px',
	},
	headerRow: {
		marginBottom: '10px',
	},
	midRowGap: {
		marginBottom: '30px',
	},
	icon: {
		height: '32px',
	},
	underline: {
		textDecorationLine: 'underline',
	},
}

export default ContractSidebarStats
