import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { FilterDropdownOption, Listing, ListingColumn } from '../../components/UI/Listing/Listing'
import { AppContext } from '../../App'
import { PageStatus } from '../../types/PageStatus'
import * as Request from '../../utilities/request'
import { AssetList, AssetListResult } from '../../models/Asset'
import { formatIncomingDateTime } from '../../utilities/formatDate'
import addIcon from '../../../src/images/icons/add-white.svg'
import { AssetAddModal } from '../../components/UI/AssetDetails/Edit/AssetAddModal'
import { Dropdown } from 'react-bootstrap'
import ellipsis from '../../images/icons/options.svg'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../utilities/permissions/permission.d'
import { hasPermission } from '../../utilities/permissions/permission'
import { AssetClassName, assetClass, assetClassDropdownValues } from '../../constants/assetClass'

interface AssetOverviewListingProps {
	filter?: string // assetModel_Id={id}
	readOnly?: boolean
}

const AssetOverviewListing = (props: AssetOverviewListingProps) => {
	const context = useContext(AppContext)
	const location = useLocation()
	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [showModal, setShowModal] = React.useState<boolean>(false)

	const [assets, setAssets] = React.useState<AssetList[] | null>(null)

	const assetClassNamesForDefaultSearch: AssetClassName[] = ['Tmv', 'Fixture', 'Hub', 'Transmitter']
	const hashLowerCase = location.hash.substring(1).toLowerCase()
	const hash = hashLowerCase !== '' ? hashLowerCase.charAt(0).toUpperCase() + hashLowerCase.substring(1) : ''
	const defaultSearch = assetClassNamesForDefaultSearch.includes(hash as AssetClassName)
		? { assetClass_Name: assetClass[hash as AssetClassName].id }
		: undefined

	React.useEffect(() => {
		const getData = async () => {
			const [assetResponse] = await Promise.all([
				Request.get<AssetListResult>(`asset?${props.filter || `site_Id=${context.appState.currentSite?.site_Id}`}`, context.appState.authState),
			])

			setAssets(assetResponse.data.assets)

			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	const handleSelectedDelete = async (selected: string[]) => {
		setPageStatus('Submitting')

		await Promise.all(selected.map((asset_Id) => Request.del<AssetListResult>(`asset?Id=${asset_Id}`, context.appState.authState)))

		setAssets(assets?.filter((asset) => !selected.includes(asset.asset_Id)) || [])

		context.setAppState({
			state: 'setCurrentSite',
			data: {
				site: context.appState.currentSite ? { ...context.appState.currentSite, asset_Count: assets ? assets.length - selected.length : 0 } : null,
			},
		})

		setPageStatus('Ready')
	}

	const assetClasses =
		assets?.reduce(
			(accumulator, asset) => {
				const typeIndex = accumulator.findIndex((acc) => acc.value === asset.assetClass_Id)
				if (typeIndex < 0) {
					const dropdownValue = assetClassDropdownValues.find((dropdownValue) => dropdownValue.value === asset.assetClass_Id)
					if (dropdownValue) {
						accumulator.push({ value: dropdownValue.value, text: dropdownValue.label })
					}
				}
				return accumulator
			},
			// This is a bit hacky, uses the 4 possible asset class hash values as the base set, which means a user could select a
			// class with no entries in the listing, but it's cleaner than trying to parse the incoming hash value and compare it to
			// these values after the asset array has loaded
			assetClassDropdownValues
				.filter((ac) => assetClassNamesForDefaultSearch.includes(ac.label))
				.map((dropdownValue) => ({ value: dropdownValue.value, text: dropdownValue.label }))
		) || []

	const assetTypes =
		assets?.reduce((accumulator, asset) => {
			const typeIndex = accumulator.findIndex((acc) => acc.value === asset.assetType_Id)
			if (typeIndex < 0) {
				accumulator.push({ value: asset.assetType_Id, text: asset.assetType_Name })
			}
			return accumulator
		}, [] as FilterDropdownOption[]) || []

	return (
		<>
			<Messages messages={messages} updateMessage={setMessages} />

			<AssetAddModal show={showModal} setShow={setShowModal} />

			<Listing
				name="Asset"
				namePlural="Assets"
				list={assets || []}
				getIDFunc={(item) => item.asset_Id}
				selectedActions={
					!props.readOnly && hasPermission(PermissionModelObject.Asset, PermissionModelAction.DELETE, context.appState, PermissionModelContext.Site)
						? [{ name: 'Delete selected', method: handleSelectedDelete }]
						: []
				}
				headerButtons={
					!props.readOnly && hasPermission(PermissionModelObject.Asset, PermissionModelAction.POST, context.appState, PermissionModelContext.Site)
						? [
								{
									content: (
										<>
											<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
											<span className="span-white span-bold">Create New Asset</span>
										</>
									),
									onClick: () => {
										setShowModal(true)
									},
								},
						  ]
						: []
				}
				columns={(
					[
						{
							value: (item) => item.assetClass_Id,
							render: (item) => <>{assetClassDropdownValues.find((dropdownValue) => dropdownValue.value === item.assetClass_Id)?.label}</>,
							showHeader: true,
							headerText: 'Class',
							sortColumnName: 'assetClass_Name',
							filterType: 'dropdown',
							filterOptions: {
								columnName: 'assetClass_Name',
								options: assetClasses,
							},
						},
						{
							value: (item) => item.assetType_Id,
							render: (item) => <>{item.assetType_Name}</>,
							showHeader: true,
							headerText: 'Type',
							sortColumnName: 'assetType_Name',
							filterType: 'dropdown',
							filterOptions: {
								columnName: 'assetType_Name',
								options: assetTypes,
							},
						},
						{
							value: (item) => item.asset_Name || '',
							render: (item) => (
								<Link
									to={{
										pathname: `/asset/${item.asset_Id}`,
									}}
								>
									{item.asset_Name}
								</Link>
							),
							showHeader: true,
							headerText: 'Object ID',
							sortColumnName: 'asset_Name',
							filterType: 'string',
							filterOptions: {
								columnName: 'asset_Name',
							},
						},
						{
							value: (item) => item.asset_InstallDate || '',
							render: (item) => (
								<>
									{item.asset_InstallDate
										? formatIncomingDateTime({
												dateTime: item.asset_InstallDate,
												format: 'Date',
												timeZone: item.site_Timezone,
										  })
										: ''}
								</>
							),
							showHeader: true,
							headerText: 'Install Date',
							sortColumnName: 'asset_InstallDate',
						},
						{
							value: (item) => item.locationBuilding_Name,
							render: (item) => <>{item.locationBuilding_Name}</>,
							showHeader: true,
							headerText: 'Building',
							sortColumnName: 'locationBuilding_Name',
							filterType: 'string',
							filterOptions: {
								columnName: 'locationBuilding_Name',
							},
						},
						{
							value: (item) => item.locationFloor_Name,
							render: (item) => <>{item.locationFloor_Name}</>,
							showHeader: true,
							headerText: 'Floor',
							sortColumnName: 'locationFloor_Name',
							filterType: 'string',
							filterOptions: {
								columnName: 'locationFloor_Name',
							},
						},
						{
							value: (item) => item.locationArea_Name,
							render: (item) => <>{item.locationArea_Name}</>,
							showHeader: true,
							headerText: 'Area',
							sortColumnName: 'locationArea_Name',
							filterType: 'string',
							filterOptions: {
								columnName: 'locationArea_Name',
							},
						},
						{
							value: (item) => item.locationRoom_Name,
							render: (item) => <>{item.locationRoom_Name}</>,
							showHeader: true,
							headerText: 'Room',
							sortColumnName: 'locationRoom_Name',
							filterType: 'string',
							filterOptions: {
								columnName: 'locationRoom_Name',
							},
						},
					] as ListingColumn<AssetList>[]
				).concat(
					props.readOnly || !hasPermission(PermissionModelObject.Asset, PermissionModelAction.DELETE, context.appState, PermissionModelContext.Site)
						? []
						: [
								{
									value: (item) => item.assetType_Id,
									render: (item) => (
										<>
											<Dropdown>
												<Dropdown.Toggle as="img" src={ellipsis} alt="Asset options" className="site-card-dropdown-toggle" />
												<Dropdown.Menu>
													<Dropdown.Item onClick={() => handleSelectedDelete([item.asset_Id])}>Delete</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</>
									),
									showHeader: true,
									headerText: 'Action',
									centerColumn: true,
								},
						  ]
				)}
				defaultSort={{ column: 'asset_Id', order: 'ASC' }}
				defaultSearch={defaultSearch}
				isLoading={pageStatus !== 'Ready'}
			/>
		</>
	)
}

export { AssetOverviewListing }
export type { AssetOverviewListingProps }
