import { userRole } from '../../constants/userRole'
import * as Types from './permission.d'

// Permissions by role
import { contractManagerPermissionObject } from './roles/contractManager'
import { displayUserPermissionObject } from './roles/displayUser'
import { fieldTechnicianPermissionObject } from './roles/fieldTechnician'
import { siteUserPermissionObject } from './roles/siteUser'
import { systemAdminPermissionObject } from './roles/systemAdmin'
import { systemSpecialistPermissionObject } from './roles/systemSpecialist'

// We are already searching sites/groups for current site/group to find correct userRoleId = if it is not a valid Id, then they don't have permissions in this context
export const genericSiteRequirement: Types.PermissionRequirement = ({ userRoleId }) => userRoleId !== ''
export const genericRoleRequirement: Types.PermissionRequirement = ({ userRoleId, object, action }) =>
	userRoleId !== '' && rolePermissions[userRoleId][action].includes(object)

// For use with constants which are universally allowed for GET, forbidden for all else, or programatically generated objects
export const forbidden: Types.PermissionRequirement = () => false
export const allowed: Types.PermissionRequirement = () => true
export const viewOnlyPermissionObject = {
	GET: {
		PermissionsRequired: [allowed],
	},
	POST: {
		PermissionsRequired: [forbidden],
	},
	PUT: {
		PermissionsRequired: [forbidden],
	},
	DELETE: {
		PermissionsRequired: [forbidden],
	},
}

const rolePermissions = {
	[userRole['System Admin'].id]: systemAdminPermissionObject,
	[userRole['System Specialist'].id]: systemSpecialistPermissionObject,
	[userRole['Contract Manager'].id]: contractManagerPermissionObject,
	[userRole['Field Technician'].id]: fieldTechnicianPermissionObject,
	[userRole['Display User'].id]: displayUserPermissionObject,
	[userRole['Site User'].id]: siteUserPermissionObject,
}
