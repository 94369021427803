import React from 'react'
import { AppContext } from '../../../App'
import * as Request from '../../../utilities/request'
import { Card, Row, Col } from 'react-bootstrap'

import { Loading } from '../Loading/Loading'

import { TaskHistory as ITaskHistory, TaskHistoryResult } from '../../../models/TaskHistory'
import { PageStatus } from '../../../types/PageStatus'

import { formatIncomingDateTime } from '../../../utilities/formatDate'

interface TaskHistoryProps {
	pageStatus: PageStatus
	taskId: string | null
	refresh: boolean
}

const TaskHistory = (props: TaskHistoryProps) => {
	const context = React.useContext(AppContext)

	const [histories, setHistories] = React.useState<ITaskHistory[] | null>(null)

	React.useEffect(() => {
		const fetchData = async () => {
			const historiesReq = await Request.get<TaskHistoryResult>(`taskHistory?Task_Id=${props.taskId}`, context.appState.authState)
			setHistories(historiesReq.data.taskHistories)
		}
		if (context.appState.authState.isLoggedIn && props.taskId) {
			fetchData()
		}
	}, [props.taskId, props.refresh])

	return (
		<Card style={styles.card} className="site-card">
			<Row className="generic-card-list-heading-row">
				<Col sm="auto" style={styles.cellGrow}>
					<span className="dashboard-card-titles">History</span>
				</Col>
			</Row>
			{histories ? (
				<>
					<Row style={styles.row}>
						<TaskHistoryBox histories={histories} />
					</Row>
				</>
			) : props.pageStatus === 'Loading' ? (
				<Loading show={true} />
			) : null}
		</Card>
	)
}

interface TaskHistoryBoxProps {
	histories: ITaskHistory[]
}

const TaskHistoryBox = (props: TaskHistoryBoxProps) => {
	return (
		<Row>
			<Col>
				<div className="scroll-box">
					{props.histories.map((history) => (
						<Row key={history.taskHistory_Id} style={styles.boxRow} className="no-gutter">
							<Col style={styles.label}>{formatIncomingDateTime({ dateTime: history.taskHistory_Ts, format: 'TimeAgo' })}</Col>
							<Col>{history.taskHistory_Note}</Col>
						</Row>
					))}
				</div>
			</Col>
		</Row>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	card: {
		marginTop: '20px',
		minHeight: '200px',
	},
	row: {
		padding: '20px',
		justifyContent: 'space-between',
	},
	cellGrow: {
		flex: '1 1 auto',
	},
	boxRow: {
		flexDirection: 'column',
		padding: '10px',
	},
	label: {
		fontWeight: 'bold',
	},
}

export { TaskHistory }
