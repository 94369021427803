import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { useSearchParams } from 'react-router-dom'
import { PageStatus } from '../../../types/PageStatus'
import { TemperatureExceptionResult, TemperatureExceptionSummary } from '../../../models/Report'
import * as Request from '../../../utilities/request'
import { ReportApiTempExceptionFilterType, ReportApiType } from '../../../constants/report'
import { formatIncomingDateTime } from '../../../utilities/formatDate'
import logo from '../../../images/LogoBlack.svg'
import { Col, Row } from 'react-bootstrap'
import { ReportStyles as styles } from './ReportStyles'
import './ReportRender.css'
import { Site, SiteResult } from '../../../models/Site'
import { SiteGroup, SiteGroupResult } from '../../../models/SiteGroup'

const TemperatureExceptionRender = () => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [tempExceptionSummaries, setTempExceptionSummaries] = React.useState<TemperatureExceptionSummary[]>([])
	const [site, setSite] = React.useState<Site | null>(null)
	const [siteGroup, setSiteGroup] = React.useState<SiteGroup | null>(null)

	const [searchParams] = useSearchParams()
	const tempExceptionFilterType = searchParams.get('TempExceptionFilterType')
	const assetId = searchParams.get('Asset_Id')
	const siteId = searchParams.get('Site_Id')
	const siteGroupId = searchParams.get('SiteGroup_Id')
	const date = searchParams.get('Date')
	const displayDate = searchParams.get('DisplayDate')
	const summaryParam = searchParams.get('Summary')

	const summary = summaryParam ? (summaryParam === 'true' ? true : summaryParam === 'false' ? false : null) : null
	const monthReport = tempExceptionFilterType === ReportApiTempExceptionFilterType.MONTH.value

	React.useEffect(() => {
		const getData = async () => {
			try {
				const [monthlyReadingsReq, siteReq, siteGroupReq] = await Promise.all([
					Request.get<TemperatureExceptionResult>(
						`report/${ReportApiType.TEMP_EXCEPTIONS.value}?TempExceptionFilterType=${tempExceptionFilterType}&${
							assetId !== null ? `Asset_Id=${assetId}` : `${siteId !== null ? `Site_Id=${siteId}` : `SiteGroup_Id=${siteGroupId}`}&Date=${date}`
						}`,
						context.appState.authState
					),
					siteId !== null ? Request.get<SiteResult>(`site?Id=${siteId}`, context.appState.authState) : Promise.resolve({ data: { sites: [] } }),
					siteGroupId !== null
						? Request.get<SiteGroupResult>(`siteGroup?Id=${siteGroupId}`, context.appState.authState)
						: Promise.resolve({ data: { siteGroups: [] } }),
				])
				setTempExceptionSummaries(monthlyReadingsReq.data.temperatureExceptionSummaries.sort((a, b) => b.alertCount - a.alertCount))
				setSite(siteReq.data.sites[0] || null)
				setSiteGroup(siteGroupReq.data.siteGroups[0] || null)
				setPageStatus('Ready')
			} catch {
				setPageStatus('Error')
			}
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	const lastRow = (index: number, arr: unknown[]) => {
		return index === arr.length - 1 ? styles.bottomBorder : {}
	}

	return (
		<div style={styles.w100}>
			{pageStatus === 'Ready' && (
				<table style={styles.w100}>
					<thead>
						<tr style={styles.theadTitleRow}>
							<td colSpan={6}>
								<Row>
									<Col sm="auto">
										<img src={logo} alt="logo" style={styles.headerImg} />
									</Col>
									<Col style={styles.headerName}>{site?.site_Name || siteGroup?.siteGroup_Name || ''}</Col>
									<Col sm="auto">
										<div style={styles.headerTitle}>
											<span>
												Temperature Exception{summary ? ' Summary ' : ' '}Report:{' '}
												{date ? displayDate : tempExceptionSummaries.find((t) => t.asset_Id === assetId)?.asset_Name || 'Asset'}
											</span>
											<span style={styles.lightText}>{summary ? ' ' : 'Exception Details'}</span>
										</div>
									</Col>
								</Row>
							</td>
						</tr>
						<tr style={{ ...styles.theadColNameRow, ...styles.lightText }}>
							{summary ? (
								monthReport ? (
									<>
										<th style={styles.cell}>Grade</th>
										<th style={styles.cell}>TMV ID</th>
										<th style={styles.cell}>TMV Location</th>
										<th style={styles.cell}>Exception Count</th>
										<th style={styles.cell}>Last Month</th>
										<th style={styles.cellLast}>Comments</th>
									</>
								) : (
									<>
										<th style={styles.cell}>TMV ID</th>
										<th style={styles.cell}>TMV Location</th>
										<th style={styles.cellLast}>Exception Count</th>
									</>
								)
							) : (
								<>
									<th style={styles.cell}>TMV ID</th>
									<th style={styles.cell}>TMV Location</th>
									<th style={styles.cell}>Start Time</th>
									<th style={styles.cellLast}>End Time</th>
								</>
							)}
						</tr>
					</thead>
					<tbody>
						{summary ? (
							monthReport ? (
								<>
									{tempExceptionSummaries
										.filter((e) => e.isHighGrade)
										.map((e, index, arr) => (
											<tr key={e.asset_Id}>
												<td
													style={{
														...styles.cell,
														...styles.highGrade,
														...styles.centeredCell,
														...lastRow(index, arr),
													}}
												>
													{index === 0 ? 'High' : ''}
												</td>
												<td style={{ ...styles.cell, ...styles.bottomBorder }}>{e.asset_Name}</td>
												<td style={{ ...styles.cell, ...styles.bottomBorder }}>{e.asset_Location}</td>
												<td style={{ ...styles.cell, ...styles.highGrade, ...styles.centeredCell, ...styles.bottomBorder }}>
													{e.alertCount}
												</td>
												<td style={{ ...styles.cell, ...styles.centeredCell, ...styles.bottomBorder }}>{e.previousMonthCount}</td>
												<td style={{ ...styles.cellLast, ...styles.bottomBorder }}>
													{e.thermalFlushCount > 0
														? `${e.thermalFlushCount} Thermal Flush${e.thermalFlushCount > 1 ? 'es' : ''}`
														: ''}
												</td>
											</tr>
										))}
									{tempExceptionSummaries
										.filter((e) => !e.isHighGrade)
										.map((e, index, arr) => (
											<tr key={e.asset_Id}>
												<td
													style={{
														...styles.cell,
														...styles.lowGrade,
														...styles.centeredCell,
														...lastRow(index, arr),
													}}
												>
													{index === 0 ? 'Low' : ''}
												</td>
												<td style={{ ...styles.cell, ...styles.bottomBorder }}>{e.asset_Name}</td>
												<td style={{ ...styles.cell, ...styles.bottomBorder }}>{e.asset_Location}</td>
												<td style={{ ...styles.cell, ...styles.lowGrade, ...styles.centeredCell }}>{e.alertCount}</td>
												<td style={{ ...styles.cell, ...styles.centeredCell }}>{e.previousMonthCount}</td>
												<td style={{ ...styles.cellLast, ...styles.bottomBorder }}>
													{e.thermalFlushCount > 0
														? `${e.thermalFlushCount} Thermal Flush${e.thermalFlushCount > 1 ? 'es' : ''}`
														: ''}
												</td>
											</tr>
										))}
								</>
							) : (
								<>
									{tempExceptionSummaries.map((e) => (
										<tr key={e.asset_Id} style={styles.bottomBorder}>
											<td style={styles.cell}>{e.asset_Name}</td>
											<td style={styles.cell}>{e.asset_Location}</td>
											<td style={{ ...styles.cellLast, ...styles.centeredCell }}>{e.alertCount}</td>
										</tr>
									))}
								</>
							)
						) : (
							<>
								{tempExceptionSummaries.filter((e) => e.alertCount - e.thermalFlushCount > 0).length > 0 ? (
									tempExceptionSummaries
										.filter((e) => e.alertCount - e.thermalFlushCount > 0)
										.sort((a, b) => (a.alertCount - a.thermalFlushCount > b.alertCount - b.thermalFlushCount ? -1 : 1))
										.map((tempExSumm) =>
											tempExSumm.temperatureExceptions
												.filter((tempEx) => !tempEx.isThermalFlush)
												.sort((a, b) => ((a.startTs || '') < (b.startTs || '') ? -1 : 1))
												.map((tempEx, index, arr) => (
													<tr key={`${tempEx.event_Id}`}>
														<td
															style={{
																...styles.cell,
																...styles.exceptionBackground,
																...styles.centeredCell,
																...lastRow(index, arr),
															}}
														>
															{index === 0 ? tempEx.asset_Name : ''}
														</td>
														<td
															style={{
																...styles.cell,
																...styles.topAlignedCell,
																...lastRow(index, arr),
															}}
														>
															{index === 0 ? tempEx.asset_Location : ''}
														</td>

														<td style={{ ...styles.cell, ...styles.bottomBorder }}>
															{tempEx.startTs
																? formatIncomingDateTime({
																		dateTime: tempEx.startTs,
																		format: 'DateAndTime',
																		timeZone: tempEx.site_Timezone,
																  })
																: ''}
														</td>

														<td style={{ ...styles.cellLast, ...styles.bottomBorder }}>
															{tempEx.finishTs
																? formatIncomingDateTime({
																		dateTime: tempEx.finishTs,
																		format: 'DateAndTime',
																		timeZone: tempEx.site_Timezone,
																  })
																: ''}
														</td>
													</tr>
												))
										)
								) : (
									<tr>
										<td colSpan={4} style={{ ...styles.centeredCell, ...styles.largeFont }}>
											<span>No Temperature Exception events detected</span>
										</td>
									</tr>
								)}
								<tr style={{ pageBreakAfter: 'always' }}></tr> {/* Page break before the thermal flush section */}
								{tempExceptionSummaries.filter((e) => e.thermalFlushCount > 0).length > 0 ? (
									<>
										<tr>
											<td colSpan={4} style={{ ...styles.centeredCell, ...styles.largeFont }}>
												<span>Thermal Flush Events</span>
											</td>
										</tr>
										{tempExceptionSummaries
											.filter((e) => e.thermalFlushCount > 0)
											.sort((a, b) => (a.thermalFlushCount > b.thermalFlushCount ? -1 : 1))
											.map((tempExSumm) =>
												tempExSumm.temperatureExceptions
													.filter((tempEx) => tempEx.isThermalFlush)
													.sort((a, b) => ((a.startTs || '') < (b.startTs || '') ? -1 : 1))
													.map((tempEx, index, arr) => (
														<tr key={`${tempEx.event_Id}`}>
															<td
																style={{
																	...styles.cell,
																	...styles.thermalFlushBackground,
																	...styles.centeredCell,
																	...lastRow(index, arr),
																}}
															>
																{index === 0 ? tempEx.asset_Name : ''}
															</td>
															<td
																style={{
																	...styles.cell,
																	...styles.topAlignedCell,
																	...lastRow(index, arr),
																}}
															>
																{index === 0 ? tempEx.asset_Location : ''}
															</td>

															<td style={{ ...styles.cell, ...styles.bottomBorder }}>
																{tempEx.startTs
																	? formatIncomingDateTime({
																			dateTime: tempEx.startTs,
																			format: 'DateAndTime',
																			timeZone: tempEx.site_Timezone,
																	  })
																	: ''}
															</td>

															<td style={{ ...styles.cellLast, ...styles.bottomBorder }}>
																{tempEx.finishTs
																	? formatIncomingDateTime({
																			dateTime: tempEx.finishTs,
																			format: 'DateAndTime',
																			timeZone: tempEx.site_Timezone,
																	  })
																	: ''}
															</td>
														</tr>
													))
											)}
									</>
								) : (
									<tr>
										<td colSpan={4} style={{ ...styles.centeredCell, ...styles.largeFont }}>
											<span>No Thermal Flush events detected</span>
										</td>
									</tr>
								)}
							</>
						)}
						{summary && monthReport && tempExceptionSummaries.length > 0 && (
							<tr>
								<td colSpan={6} style={{ ...styles.centeredCell, ...styles.largeFont }}>
									<span>
										{tempExceptionSummaries.length} TMV{tempExceptionSummaries.length === 1 ? '' : 's'} in Total
									</span>
								</td>
							</tr>
						)}
						{summary && tempExceptionSummaries.length === 0 && (
							<tr>
								<td colSpan={summary ? 6 : 4} style={{ ...styles.centeredCell, ...styles.largeFont }}>
									<span>No Temperature Exception events detected</span>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			)}
			{pageStatus === 'Loading' && <div>Loading...</div>}
			{pageStatus === 'Error' && <div>Error</div>}
		</div>
	)
}

export default TemperatureExceptionRender
