import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppContext } from '../../App'

const ScreensLogin = () => {
	const context = React.useContext(AppContext)
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	React.useEffect(() => {
		// Once a user is logged in, strip the redirect search param and navigate to the page with any remaining params
		// Keep any logic/processing inside the if statement, as this component seems to get rendered twice during the login process
		if (context.appState.authState.isLoggedIn) {
			const redirectLocation = searchParams.get('redirect') || '/'
			searchParams.delete('redirect')
			const searchParamsString = searchParams.toString()

			navigate(`${redirectLocation}${searchParamsString.length > 0 ? `?${searchParamsString}` : ''}`, { replace: true })
		}
	}, [context.appState.authState.isLoggedIn])

	return null
}

export { ScreensLogin }
