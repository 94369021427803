import React from 'react'
import './EditAsset.css'

import { AppContext } from '../../../../App'
import * as Request from '../../../../utilities/request'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../../utilities/permissions/permission.d'

import { Row, Col, Card } from 'react-bootstrap'
import { FormText } from '../../Form/Text'
import { FormSelect, SelectOption } from '../../Form/Select'
import { ModalComponentByName } from '../../../../screens/Asset/EditAssetDetailsModals'
import PermissionsCheck from '../../../Permissions/PermissionsCheck'

import { CommonComponentProps } from '../../../../screens/Asset/EditAssetDetails'
import { Asset } from '../../../../models/Asset'
import { LocationBuilding, LocationBuildingResult } from '../../../../models/LocationBuilding'
import { LocationFloor, LocationFloorResult } from '../../../../models/LocationFloor'
import { LocationArea, LocationAreaResult } from '../../../../models/LocationArea'
import { LocationRoom, LocationRoomResult } from '../../../../models/LocationRoom'

import plus2 from '../../../../images/icons/add.svg'

const AssetLocation = (componentProps: CommonComponentProps) => {
	const context = React.useContext(AppContext)

	const [locationBuildings, setLocationBuildings] = React.useState<LocationBuilding[] | null>(null)
	const [locationFloors, setLocationFloors] = React.useState<LocationFloor[] | null>(null)
	const [locationAreas, setLocationAreas] = React.useState<LocationArea[] | null>(null)
	const [locationRooms, setLocationRooms] = React.useState<LocationRoom[] | null>(null)

	const [modal, setModal] = React.useState<string>('')

	React.useEffect(() => {
		const getData = async () => {
			const [locationBuildingReq, locationFloorReq, locationAreaReq, locationRoomReq] = await Promise.all([
				Request.get<LocationBuildingResult>(`locationBuilding?Site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
				Request.get<LocationFloorResult>(`locationFloor?Site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
				Request.get<LocationAreaResult>(`locationArea?Site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
				Request.get<LocationRoomResult>(`locationRoom?Site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
			])
			if (locationBuildingReq.status === 200 && locationFloorReq.status === 200 && locationAreaReq.status === 200 && locationRoomReq.status === 200) {
				setLocationBuildings(locationBuildingReq.data.locationBuildings)
				setLocationFloors(locationFloorReq.data.locationFloors)
				setLocationAreas(locationAreaReq.data.locationAreas)
				setLocationRooms(locationRoomReq.data.locationRooms)
			}
		}

		if (context.appState.authState.isLoggedIn && context.appState.currentSite) {
			getData()
		}
	}, [componentProps.asset.asset_Id])

	const handleAddLocationBuilding = async (locationBuilding: LocationBuilding) => {
		await Request.handleRequest(() => Request.post<LocationBuildingResult>('locationBuilding', locationBuilding, context.appState.authState), {
			successFunction: (data) => {
				setLocationBuildings((prev) =>
					[...(prev || []), ...data.locationBuildings].sort((a, b) => a.locationBuilding_Name.localeCompare(b.locationBuilding_Name))
				)
				componentProps.handleChange('asset.locationBuilding_Id', data.locationBuildings[0].locationBuilding_Id)
				componentProps.handleChange('asset.locationFloor_Id', null)
				componentProps.handleChange('asset.locationArea_Id', null)
				componentProps.handleChange('asset.locationRoom_Id', null)
			},
			setMessageFunction: componentProps.setMessages,
			messageAction: 'creating',
			messageObject: 'building',
		})
	}

	const handleAddLocationFloor = async (locationFloor: LocationFloor) => {
		await Request.handleRequest(() => Request.post<LocationFloorResult>('locationFloor', locationFloor, context.appState.authState), {
			successFunction: (data) => {
				setLocationFloors((prev) => [...(prev || []), ...data.locationFloors].sort((a, b) => a.locationFloor_Name.localeCompare(b.locationFloor_Name)))
				componentProps.handleChange('asset.locationFloor_Id', data.locationFloors[0].locationFloor_Id)
				componentProps.handleChange('asset.locationArea_Id', null)
				componentProps.handleChange('asset.locationRoom_Id', null)
			},
			setMessageFunction: componentProps.setMessages,
			messageAction: 'creating',
			messageObject: 'floor',
		})
	}

	const handleAddLocationArea = async (locationArea: LocationArea) => {
		await Request.handleRequest(() => Request.post<LocationAreaResult>('locationArea', locationArea, context.appState.authState), {
			successFunction: (data) => {
				setLocationAreas((prev) => [...(prev || []), ...data.locationAreas].sort((a, b) => a.locationArea_Name.localeCompare(b.locationArea_Name)))
				componentProps.handleChange('asset.locationArea_Id', data.locationAreas[0].locationArea_Id)
				componentProps.handleChange('asset.locationRoom_Id', null)
			},
			setMessageFunction: componentProps.setMessages,
			messageAction: 'creating',
			messageObject: 'area',
		})
	}

	const handleAddLocationRoom = async (locationRoom: LocationRoom) => {
		await Request.handleRequest(() => Request.post<LocationRoomResult>('locationRoom', locationRoom, context.appState.authState), {
			successFunction: (data) => {
				setLocationRooms((prev) => [...(prev || []), ...data.locationRooms].sort((a, b) => a.locationRoom_Name.localeCompare(b.locationRoom_Name)))
				componentProps.handleChange('asset.locationRoom_Id', data.locationRooms[0].locationRoom_Id)
			},
			setMessageFunction: componentProps.setMessages,
			messageAction: 'creating',
			messageObject: 'room',
		})
	}

	const locationBuildingOptions = React.useMemo(() => {
		return locationBuildings ? locationBuildings.map((lb) => ({ label: lb.locationBuilding_Name, value: lb.locationBuilding_Id })) : []
	}, [locationBuildings])

	const locationFloorOptions = React.useMemo(() => {
		return locationFloors
			? locationFloors
					.filter((lf) => lf.locationBuilding_Id === componentProps.asset.locationBuilding_Id)
					.map((lf) => ({ label: lf.locationFloor_Name, value: lf.locationFloor_Id }))
			: []
	}, [locationFloors, componentProps.asset.locationBuilding_Id])

	const locationAreaOptions = React.useMemo(() => {
		return locationAreas
			? locationAreas
					.filter((la) => la.locationFloor_Id === componentProps.asset.locationFloor_Id)
					.map((la) => ({ label: la.locationArea_Name, value: la.locationArea_Id }))
			: []
	}, [locationAreas, componentProps.asset.locationFloor_Id])

	const locationRoomOptions = React.useMemo(() => {
		return locationRooms
			? locationRooms
					.filter((lr) => lr.locationArea_Id === componentProps.asset.locationArea_Id)
					.map((lr) => ({ label: lr.locationRoom_Name, value: lr.locationRoom_Id }))
			: []
	}, [locationRooms, componentProps.asset.locationArea_Id])

	const ModalComponent = React.useMemo(
		() => (modalProps: { asset: Asset }) =>
			modal
				? ModalComponentByName[modal]({
						hide: () => setModal(''),
						user_Id: context.appState.userAttributes.user_Id,
						site_Id: context.appState.currentSite?.site_Id || '',
						asset: modalProps.asset,
						handleAddAssetMake: () => Promise.resolve(),
						handleAddLocationBuilding: (locationBuilding) => handleAddLocationBuilding(locationBuilding),
						handleAddLocationFloor: (locationFloor) => handleAddLocationFloor(locationFloor),
						handleAddLocationArea: (locationArea) => handleAddLocationArea(locationArea),
						handleAddLocationRoom: (locationRoom) => handleAddLocationRoom(locationRoom),
				  })
				: null,
		[modal]
	)

	return (
		<Card className="site-card" style={styles.card}>
			<Row className="site-card-list-heading-row">
				<span className="dashboard-card-titles">Asset Location</span>
			</Row>
			<Row style={styles.row}>
				<Col sm={6} className="edit-asset-field">
					<FormSelect
						name={'asset_locationBuilding_Id'}
						label={'Building'}
						options={locationBuildingOptions}
						value={componentProps.asset.locationBuilding_Id}
						onChange={(e) => {
							componentProps.handleChange('asset.locationBuilding_Id', e ? (e as SelectOption<string>).value : null)
							componentProps.handleChange('asset.locationFloor_Id', null)
							componentProps.handleChange('asset.locationArea_Id', null)
							componentProps.handleChange('asset.locationRoom_Id', null)
						}}
						isClearable
						isDisabled={componentProps.pageStatus === 'Submitting'}
						loading={!locationBuildings}
					/>
					<PermissionsCheck object={PermissionModelObject.LocationBuilding} action={PermissionModelAction.POST} context={PermissionModelContext.Site}>
						<img
							src={plus2}
							alt={'plus icon'}
							className="edit-asset-plus-button"
							style={!locationBuildings ? styles.disabledPlusButton : {}}
							onClick={() => locationBuildings && setModal('locationBuilding')}
						/>
					</PermissionsCheck>
				</Col>
				<Col sm={6} className="edit-asset-field">
					<FormSelect
						name={'asset_locationFloor_Id'}
						label={'Floor'}
						options={locationFloorOptions}
						value={componentProps.asset.locationFloor_Id}
						onChange={(e) => {
							componentProps.handleChange('asset.locationFloor_Id', e ? (e as SelectOption<string>).value : null)
							componentProps.handleChange('asset.locationArea_Id', null)
							componentProps.handleChange('asset.locationRoom_Id', null)
						}}
						isClearable
						isDisabled={componentProps.pageStatus === 'Submitting'}
						loading={!locationFloors}
					/>
					<PermissionsCheck object={PermissionModelObject.LocationFloor} action={PermissionModelAction.POST} context={PermissionModelContext.Site}>
						<img
							src={plus2}
							alt={'plus icon'}
							className="edit-asset-plus-button"
							style={!componentProps.asset.locationBuilding_Id || !locationFloors ? styles.disabledPlusButton : {}}
							onClick={() => componentProps.asset.locationBuilding_Id && locationFloors && setModal('locationFloor')}
						/>
					</PermissionsCheck>
				</Col>
			</Row>
			<Row style={styles.row}>
				<Col sm={6} className="edit-asset-field">
					<FormSelect
						name={'asset_locationArea_Id'}
						label={'Area'}
						options={locationAreaOptions}
						value={componentProps.asset.locationArea_Id}
						onChange={(e) => {
							componentProps.handleChange('asset.locationArea_Id', e ? (e as SelectOption<string>).value : null)
							componentProps.handleChange('asset.locationRoom_Id', null)
						}}
						isClearable
						isDisabled={componentProps.pageStatus === 'Submitting'}
						loading={!locationAreas}
					/>
					<PermissionsCheck object={PermissionModelObject.LocationArea} action={PermissionModelAction.POST} context={PermissionModelContext.Site}>
						<img
							src={plus2}
							alt={'plus icon'}
							className="edit-asset-plus-button"
							style={!componentProps.asset.locationFloor_Id || !locationAreas ? styles.disabledPlusButton : {}}
							onClick={() => componentProps.asset.locationFloor_Id && locationAreas && setModal('locationArea')}
						/>
					</PermissionsCheck>
				</Col>
				<Col sm={6} className="edit-asset-field">
					<FormSelect
						name={'asset_locationRoom_Id'}
						label={'Room'}
						options={locationRoomOptions}
						value={componentProps.asset.locationRoom_Id}
						onChange={(e) => componentProps.handleChange('asset.locationRoom_Id', e ? (e as SelectOption<string>).value : null)}
						isClearable
						isDisabled={componentProps.pageStatus === 'Submitting'}
						loading={!locationRooms}
					/>
					<PermissionsCheck object={PermissionModelObject.LocationRoom} action={PermissionModelAction.POST} context={PermissionModelContext.Site}>
						<img
							src={plus2}
							alt={'plus icon'}
							className="edit-asset-plus-button"
							style={!componentProps.asset.locationArea_Id || !locationRooms ? styles.disabledPlusButton : {}}
							onClick={() => componentProps.asset.locationArea_Id && locationRooms && setModal('locationRoom')}
						/>
					</PermissionsCheck>
				</Col>
			</Row>
			<Row style={styles.row}>
				<Col sm={6} className="edit-asset-field">
					<FormText
						name={'asset_LocationNote'}
						label={'Asset Location'}
						as={'textarea'}
						value={componentProps.asset.asset_LocationNote || ''}
						onChange={(e) => componentProps.handleChange('asset.asset_LocationNote', e.target.value)}
						disabled={componentProps.pageStatus === 'Submitting'}
						feedback={componentProps.errors?.asset_LocationNote}
						isInvalid={!!componentProps.errors?.asset_LocationNote}
					/>
				</Col>
			</Row>
			<ModalComponent asset={componentProps.asset} />
		</Card>
	)
}

const styles = {
	card: {
		minHeight: '420px',
	},
	row: {
		padding: '20px',
		justifyContent: 'space-between',
	},
	disabledPlusButton: {
		cursor: 'not-allowed',
	},
}

export { AssetLocation }
