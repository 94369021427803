import React from 'react'

import { severity, SeverityId } from '../../../constants/severity'

import critical from '../../../images/icons/alert-critical.svg'
import high from '../../../images/icons/alert-high.svg'
import medium from '../../../images/icons/alert-medium.svg'
import low from '../../../images/icons/alert-low.svg'
import info from '../../../images/icons/alert-info.svg'

interface SeverityIconProps {
	severityId: SeverityId
	style?: React.CSSProperties
}

export const SeverityIcon = (props: SeverityIconProps) => {
	switch (props.severityId) {
		case severity.Critical.id:
			return <img src={critical} alt={'A critical alert icon'} style={props.style || styles.img}></img>
		case severity.High.id:
			return <img src={high} alt={'A high alert icon'} style={props.style || styles.img}></img>
		case severity.Medium.id:
			return <img src={medium} alt={'A medium alert icon'} style={props.style || styles.img}></img>
		case severity.Low.id:
			return <img src={low} alt={'A low alert icon'} style={props.style || styles.img}></img>
		case severity.Info.id:
			return <img src={info} alt={'An info alert icon'} style={props.style || styles.img}></img>
		default:
			return null
	}
}

const styles = {
	img: {
		height: '25px',
		width: '25px',
	},
}
