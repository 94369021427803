import React from 'react'
import Select from 'react-select'
import { ReportApiType, ReportApiTypeSelectOptions } from '../../../constants/report'
import MonthlyReadingListing from './MonthlyReadingListing'
import TemperatureExceptionReportListing from './TemperatureExceptionListing'
import TempExceptionHistoryListing from './TempExceptionHistoryListing'
import FlowListing from './FlowListing'
import ConsecutiveFlowListing from './ConsecutiveFlowListing'
import { useLocation } from 'react-router-dom'

interface ReportDisplayProps {
	usageContext: 'site' | 'siteGroup'
}

const ReportDisplay = (props: ReportDisplayProps) => {
	const location = useLocation()

	const getReportType = () => {
		const hash = location.hash.substring(1)
		return ReportApiTypeSelectOptions.find((opt) => opt.value === hash) || ReportApiType.MONTHLY_READING
	}

	const [reportType, setReportType] = React.useState<ReportApiType>(getReportType())
	const reportTypeDropdownExclusions: ReportApiType[] = [ReportApiType.FLEET_HEALTH, ReportApiType.TEMP_EXCEPTION_DETAIL]

	const reportTypeSelect = (
		<Select
			options={ReportApiTypeSelectOptions.filter((type) => !reportTypeDropdownExclusions.includes(type))}
			value={reportType}
			onChange={(e) => {
				window.location.hash = (e as ReportApiType).value
				setReportType(e as ReportApiType)
			}}
			isSearchable={false}
		/>
	)

	const listingSwitch = () => {
		switch (reportType) {
			case ReportApiType.MONTHLY_READING:
				return <MonthlyReadingListing reportTypeSelect={reportTypeSelect} usageContext={props.usageContext} />
			case ReportApiType.TEMP_EXCEPTIONS:
				return <TemperatureExceptionReportListing reportTypeSelect={reportTypeSelect} usageContext={props.usageContext} />
			case ReportApiType.TEMP_EXCEPTION_HISTORY:
				return <TempExceptionHistoryListing reportTypeSelect={reportTypeSelect} usageContext={props.usageContext} />
			case ReportApiType.FLOW:
				return <FlowListing reportTypeSelect={reportTypeSelect} usageContext={props.usageContext} />
			case ReportApiType.CONSECUTIVE_FLOW:
				return <ConsecutiveFlowListing reportTypeSelect={reportTypeSelect} usageContext={props.usageContext} />
			case ReportApiType.THERMAL_FLUSH:
				return <TemperatureExceptionReportListing reportTypeSelect={reportTypeSelect} usageContext={props.usageContext} onlyThermalFlush />
			default:
				return reportTypeSelect
		}
	}

	return <>{listingSwitch()}</>
}

export default ReportDisplay
