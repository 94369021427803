import { DateTime } from 'luxon'

type DateDisplay =
	| 'FileDate'
	| 'Date'
	| 'DateAndTime'
	| 'DateAndTimeWithSeconds'
	| 'DateAndTimeAndTimezoneContext'
	| 'DateAndTimeAndTimezoneNoContext'
	| 'TimeAgo'
	| 'Custom'

interface BaseIncomingProps {
	dateTime: string
	format: DateDisplay
}

interface NoTimeZoneProps extends BaseIncomingProps {
	format: 'DateAndTimeAndTimezoneNoContext' | 'TimeAgo' | 'Date'
}

interface WithTimeZoneProps extends BaseIncomingProps {
	format: 'FileDate' | 'DateAndTime' | 'DateAndTimeWithSeconds' | 'DateAndTimeAndTimezoneContext' | 'Date'
	timeZone?: string
}

interface CustomFormatProps extends BaseIncomingProps {
	format: 'Custom'
	timeZone?: string
	customFormat: string
}

/**
 * Takes an ISO-8601 string in UTC or Timezone-less (no Z or offset) as input, along with a IANA timezone name string to
 * convert the provided string to the selected format, optionally converted from UTC to the provided timezone
 *
 * @param props - NoTimeZoneProps | WithTimeZoneProps | CustomFormatProps
 * - NoTimeZoneProps: { dateTime: string, format: 'DateAndTimeAndTimezoneNoContext' | 'TimeAgo' }
 * - WithTimeZoneProps: { dateTime: string, format: 'FileDate' | 'DateAndTime' | 'DateAndTimeWithSeconds' | 'DateAndTimeAndTimezoneContext', timeZone?: string }
 * - CustomFormatProps: { dateTime: string, format: 'Custom', timeZone?: string, customFormat: string }
 * @param dateTime - ISO-8601 string in UTC or Timezone-less (no Z or offset)
 * @param format - 'FileDate' | 'DateAndTime' | 'DateAndTimeWithSeconds' | 'DateAndTimeAndTimezoneContext' | 'DateAndTimeAndTimezoneNoContext' | 'TimeAgo'
 * @param timeZone - IANA Timezone name to convert the UTC DateTime to, defaults to UTC if none provided (ie. no conversion)
 * @param customFormat - ONLY FOR CONVERSION USE, all displayed DateTime outputs must use the pre-defined output formats. Custom output format string, see https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens for valid tokens
 * @returns Formatted date and time string with timezone name where applicable
 *
 * @example formatIncomingDateTime({dateTime: '2023-06-04T03:16:34.341', format: 'DateAndTimeAndTimezoneContext', timeZone: 'Australia/Sydney'}) => '04-Jun-2023 01:16PM (Australia/Sydney)'
 * */

const formatIncomingDateTime = (props: NoTimeZoneProps | WithTimeZoneProps | CustomFormatProps) => {
	if (!props.dateTime.endsWith('Z')) {
		props.dateTime = `${props.dateTime}Z`
	}

	let outputTimeZone: string | null = null

	if (
		props.format === 'FileDate' ||
		props.format === 'DateAndTime' ||
		props.format === 'DateAndTimeWithSeconds' ||
		props.format === 'DateAndTimeAndTimezoneContext' ||
		props.format === 'Custom'
	) {
		outputTimeZone = props.timeZone ? props.timeZone : DateTime.local().zoneName
	}
	// Create DateTime object from incoming UTC string converted to the input timezone, local timezone, or UTC
	const dateConverted = DateTime.fromISO(props.dateTime, { zone: outputTimeZone ? outputTimeZone : 'utc' })

	const FILE_FORMAT = 'yyyy-MM-dd'
	const SHORT_FORMAT = 'dd-MMM-yyyy'
	const FULL_FORMAT = 'dd-MMM-yyyy hh:mm a'
	const FULL_FORMAT_WITH_SECONDS = 'dd-MMM-yyyy hh:mm:ss a'
	const FULL_FORMAT_ZONE = FULL_FORMAT + ' z'
	const FULL_FORMAT_ZONE_BRACKETS = FULL_FORMAT + ' (z)'

	switch (props.format) {
		case 'FileDate':
			return dateConverted.toFormat(FILE_FORMAT)
		case 'Date':
			return dateConverted.toFormat(SHORT_FORMAT)
		case 'DateAndTime':
			return dateConverted.toFormat(FULL_FORMAT)
		case 'DateAndTimeWithSeconds':
			return dateConverted.toFormat(FULL_FORMAT_WITH_SECONDS)
		case 'DateAndTimeAndTimezoneContext':
			return `${dateConverted.toFormat(FULL_FORMAT_ZONE_BRACKETS)}`
		case 'DateAndTimeAndTimezoneNoContext':
			return `${dateConverted.toFormat(FULL_FORMAT_ZONE)} (${DateTime.fromISO(props.dateTime).toFormat(FULL_FORMAT_ZONE)})`
		case 'TimeAgo':
			return dateConverted.toRelative({ base: DateTime.utc() }) || 'Error'
		case 'Custom':
			return dateConverted.toFormat(props.customFormat)
		default:
			return ''
	}
}

type DateFormat = 'DateString' | 'DateTimeString' | 'DateTimeObject'

interface BaseOutgoingProps {
	dateTime: string | DateTime
	format: DateFormat
	timeZone?: string
}

interface DateTimeStringProps extends BaseOutgoingProps {
	dateTime: string
	format: 'DateString' | 'DateTimeString'
}

interface DateTimeObjectProps extends BaseOutgoingProps {
	dateTime: DateTime
	format: 'DateTimeObject'
}

/**
 * Takes a date string, date time string or luxon DateTime object as input, along with a IANA timezone name string to convert
 * the provided date to UTC from the provided timezone, returned as an ISO string without offset for submission to the backend
 *
 * @param props - DateTimeStringProps | DateTimeObjectProps
 * - DateTimeStringProps: {dateTime: string, format: 'DateString' | 'DateTimeString', timeZone?: string}
 * - DateTimeObjectProps: {dateTime: DateTime, format: 'DateTimeObject', timeZone?: string}
 * @param dateTime - Date string (yyyy-MM-dd), Date time string (yyyy-MM-dd HH:mm:ss) or luxon DateTime object
 * @param format - 'DateString' | 'DateTimeString' | 'DateTimeObject' to match the input dateTime type
 * @param timeZone - IANA Timezone name for the input dateTime to be based in, defaults to UTC if none provided
 * @returns ISO string of the converted dateTime in UTC with no offset
 *
 * @example formatOutgoingDateTime({dateTime: '2023-09-01 11:30:00', format: 'DateTimeString', timeZone: 'Australia/Sydney'}) => '2023-09-01T01:30:00.000'
 * */

const formatOutgoingDateTime = (props: DateTimeStringProps | DateTimeObjectProps) => {
	const inputTimeZone = props.timeZone ? props.timeZone : 'UTC'

	let convertedDateTime: DateTime

	switch (props.format) {
		case 'DateString':
			convertedDateTime = DateTime.fromFormat(props.dateTime, 'yyyy-MM-dd', { zone: inputTimeZone, setZone: true })
			break
		case 'DateTimeString':
			convertedDateTime = DateTime.fromFormat(props.dateTime, 'yyyy-MM-dd HH:mm:ss', { zone: inputTimeZone, setZone: true })
			break
		case 'DateTimeObject':
			convertedDateTime = props.dateTime.setZone(inputTimeZone, { keepLocalTime: true })
			break
	}

	return convertedDateTime.toUTC().toISO({ includeOffset: false })
}

export { formatIncomingDateTime, formatOutgoingDateTime }
