import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Col, Row } from 'react-bootstrap'
import { PageStatus } from '../../../types/PageStatus'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Listing, ListingColumn } from '../Listing/Listing'
import { TempExceptionHistoryAsset, TempExceptionHistoryResult } from '../../../models/Report'
import { Link } from 'react-router-dom'
import { formatOutgoingDateTime } from '../../../utilities/formatDate'
import { ReportApiType } from '../../../constants/report'
import * as Request from '../../../utilities/request'
import { DateTime } from 'luxon'
import ExportDropdown, { CsvData } from './ExportDropdown'
import { Messages, useMessageReducer } from '../Messages/Messages'

const getCsvFileHeaders = (monthYear: DateTime) => [
	{ key: 'asset_Name', columnName: 'TMV ID' },
	{ key: 'asset_Location', columnName: 'Location' },
	...Array.from({ length: 6 }, (_, i) => {
		const label = monthYear.minus({ months: 5 - i }).toFormat('MM/yyyy')
		return {
			key: label,
			columnName: label,
		}
	}),
]

interface TempExceptionHistoryListingProps {
	reportTypeSelect: JSX.Element
	usageContext: 'site' | 'siteGroup'
}

const TempExceptionHistoryListing = (props: TempExceptionHistoryListingProps) => {
	const context = useContext(AppContext)
	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [tempExceptionHistoryAssets, setTempExceptionHistoryAssets] = React.useState<TempExceptionHistoryAsset[]>([])
	const [monthYear, setMonthYear] = React.useState(DateTime.now().set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }))

	const reportQueryUrl = `${ReportApiType.TEMP_EXCEPTION_HISTORY.value}?${
		props.usageContext === 'site' ? `Site_Id=${context.appState.currentSite?.site_Id}` : `SiteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`
	}&Date=${formatOutgoingDateTime({
		dateTime: monthYear,
		format: 'DateTimeObject',
		timeZone: props.usageContext === 'site' ? context.appState.currentSite?.site_Timezone : context.appState.currentSiteGroup?.siteGroup_Timezone,
	})}&DisplayDate=${formatOutgoingDateTime({
		dateTime: monthYear,
		format: 'DateTimeObject',
	})}`

	React.useEffect(() => {
		const getData = async () => {
			const [tempExceptionHistoryReq] = await Promise.all([
				Request.get<TempExceptionHistoryResult>(`report/${reportQueryUrl}`, context.appState.authState),
			])
			setTempExceptionHistoryAssets(tempExceptionHistoryReq.data.tempExceptionHistory.assets)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, monthYear])

	const fileName = `${monthYear.toFormat('yyyy-MMMM')} ${
		props.usageContext === 'site' ? context.appState.currentSite?.site_Name : context.appState.currentSiteGroup?.siteGroup_Name
	} - Temperature Exception History Report`

	return (
		<>
			<Messages messages={messages} updateMessage={setMessages} />
			<Row>
				<Col sm="auto" style={styles.select}>
					{props.reportTypeSelect}
				</Col>
				<Col sm="auto">
					<DatePicker
						wrapperClassName="datePicker"
						selected={monthYear.toJSDate()}
						onChange={(date) =>
							setMonthYear(DateTime.fromJSDate(date || new Date()).set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }))
						}
						isClearable={false}
						dateFormat="MMMM yyyy"
						showMonthYearPicker
						showIcon
					/>
				</Col>
				<Col />
				<Col sm="auto">
					<ExportDropdown
						pageStatus={pageStatus}
						setPageStatus={setPageStatus}
						setMessages={setMessages}
						pdfDownloads={{
							options: [
								{
									menuLabel: 'PDF',
									pdfUrl: `${reportQueryUrl}`,
									pdfFilename: fileName,
								},
							],
						}}
						csvDownloads={{
							options: [
								{
									menuLabel: 'CSV',
									csvFilename: fileName,
									data: tempExceptionHistoryAssets.map((asset) => {
										const assetObj = asset as unknown as CsvData
										asset.monthlyAlertCounts.forEach((count, index) => {
											assetObj[monthYear.minus({ months: 5 - index }).toFormat('MM/yyyy')] = count
										})
										return assetObj
									}),
									headers: getCsvFileHeaders(monthYear),
								},
							],
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Listing
						name="Temperature Exception History"
						namePlural="Temperature Exception Histories"
						list={tempExceptionHistoryAssets}
						getIDFunc={(a) => a.asset_Id}
						selectedActions={[]}
						isLoading={pageStatus !== 'Ready' && pageStatus !== 'Submitting'}
						columns={[
							{
								value: (item) => item.asset_Name,
								render: (item) => (
									<Link
										to={{
											pathname: `/asset/${item.asset_Id}`,
										}}
									>
										{item.asset_Name}
									</Link>
								),
								showHeader: true,
								headerText: 'TMV ID',
								sortColumnName: 'asset_Name',
								filterType: 'string',
								filterOptions: {
									columnName: 'asset_Name',
								},
							},
							{
								value: (item) => item.asset_Location || '',
								render: (item) => <>{item.asset_Location}</>,
								showHeader: true,
								headerText: 'Location',
								sortColumnName: 'asset_Location',
								filterType: 'string',
								filterOptions: {
									columnName: 'asset_Location',
								},
							},
							...Array.from(
								{ length: 6 },
								(_, i) =>
									({
										value: (item) => item.monthlyAlertCounts[i].toString().padStart(7, '0') || '0',
										render: (item) => <>{item.monthlyAlertCounts[i].toString() || '0'}</>,
										showHeader: true,
										headerText: `${monthYear.minus({ months: 5 - i }).toFormat('MM/yyyy')}`,
										sortColumnName: `previousMonth${i}`,
									}) as ListingColumn<TempExceptionHistoryAsset>
							),
						]}
						defaultSort={{ column: 'asset_Name', order: 'ASC' }}
					/>
				</Col>
			</Row>
		</>
	)
}

const styles = {
	select: { minWidth: '300px' },
} satisfies Record<string, React.CSSProperties>

export default TempExceptionHistoryListing
