import React, { useContext } from 'react'

import { AppContext } from '../../App'
import * as Request from '../../utilities/request'

import { Col, Container, Row } from 'react-bootstrap'
import { AddGroupModal } from '../../components/UI/SiteDashboard/AddGroupModal'
import { AddSiteModal } from '../../components/UI/SiteDashboard/AddSiteModal'
import { Site, SiteResult, SiteSystemStatusResult } from '../../models/Site'
import { defaultSiteGroup, SiteGroup, SiteGroupResult } from '../../models/SiteGroup'
import { PageStatus } from '../../types/PageStatus'
import { SiteCard, SiteCardProps, calculateTotalAlerts } from '../../components/UI/SiteCard/SiteCard'
import SiteDashboardHeader from '../../components/UI/SiteDashboard/SiteDashboardHeader'
import SiteDashboardStats from '../../components/UI/SiteDashboard/SiteDashboardStats'
import SiteDashboardMonthlyReports from '../../components/UI/SiteDashboard/SiteDashboardMonthlyReports'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import PermissionsCheck from '../../components/Permissions/PermissionsCheck'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../utilities/permissions/permission.d'
import { useInterval } from '../../utilities/useInterval'

export interface Card {
	name: string
	created: string
	assetCount: number
	siteCard: JSX.Element
}

const SiteDashboard = () => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [messages, setMessages] = useMessageReducer([])

	const [sites, setSites] = React.useState<Site[] | null>(null)
	const [siteGroups, setSiteGroups] = React.useState<SiteGroup[] | null>(null)

	const [cards, setCards] = React.useState<Card[]>([])
	const [sortedFilteredCards, setSortedFilteredCards] = React.useState<Card[]>([])

	const [showAddSiteModal, setShowAddSiteModal] = React.useState<boolean>(false)
	const [showAddGroupModal, setShowAddGroupModal] = React.useState<boolean>(false)

	const [sortBy, setSortBy] = React.useState<keyof Card>('name')
	const [filters, setFilters] = React.useState<string>('none')

	const removeSite = async (site_Id: string) => {
		await Request.handleRequest(() => Request.del<SiteResult>(`site?Id=${site_Id}`, context.appState.authState), {
			successFunction: () => {
				setSites(sites?.filter((site) => site.site_Id !== site_Id) || null)
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'site',
			failedFunction: () => {
				setPageStatus('Error')
			},
		})
	}

	const removeSiteGroup = async (siteGroup_Id: string) => {
		await Request.handleRequest(() => Request.del<SiteGroupResult>(`siteGroup?Id=${siteGroup_Id}`, context.appState.authState), {
			successFunction: () => {
				setSiteGroups(siteGroups?.filter((siteGroup) => siteGroup.siteGroup_Id !== siteGroup_Id) || null)
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'site group',
			failedFunction: () => {
				setPageStatus('Error')
			},
		})
	}

	const combineSitesAndGroups = (siteGroups: SiteGroup[], sites: Site[], parentId: string | null = null) => {
		const siteCardProps: SiteCardProps[] = []

		// Filter siteGroups with the provided ParentId
		const filteredGroups = siteGroups.filter((group) => group.siteGroup_ParentId === parentId)
		filteredGroups.forEach((group) => {
			const siteGroup_Children = combineSitesAndGroups(siteGroups, sites, group.siteGroup_Id)

			// Find sites belonging to the current group
			const groupSites = sites.filter((site) => site.siteGroup_Id === group.siteGroup_Id)

			// Create a new group object with children and sites
			const newGroup = { ...group, siteGroup_Children, sites: groupSites, removeSite: removeSite, removeSiteGroup: removeSiteGroup }

			// Add group object to the tree
			siteCardProps.push(newGroup)
		})

		return siteCardProps
	}

	React.useEffect(() => {
		const getData = async () => {
			const [sitesReq, siteGroupsReq] = await Promise.all([
				Request.get<SiteResult>(`site`, context.appState.authState),
				Request.get<SiteGroupResult>(`siteGroup`, context.appState.authState),
			])
			setSites(sitesReq.data.sites)

			// Add default site groups for sites that do not have a site group, this handles cases where a user only exists in a site, and not a group
			const siteGroups = siteGroupsReq.data.siteGroups
			sitesReq.data.sites.forEach((site) => {
				if (site.siteGroup_Id != null && siteGroups.filter((sg) => sg.siteGroup_Id === site.siteGroup_Id).length === 0) {
					siteGroups.push(defaultSiteGroup({ siteGroup_Id: site.siteGroup_Id, siteGroup_Name: site.site_Name }))
				}
			})
			setSiteGroups(siteGroupsReq.data.siteGroups)
			await fetchReadingData()

			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [])

	React.useEffect(() => {
		const generateSiteCards = (siteGroups: SiteGroup[], sites: Site[]) => {
			// Combine Sites and Site Groups into one tree style object so they can be displayed in appropriate site cards
			const siteCards = sites
				.filter((s) => s.siteGroup_Id === null)
				.map((site: Site) => {
					return {
						name: site.site_Name,
						created: site.created.create_Ts,
						assetCount: site.asset_Count,
						siteCard: (
							<SiteCard
								key={site.site_Id}
								siteGroup_Id={site.site_Id}
								siteGroup_Name={''}
								sites={[site]}
								siteGroup_Children={[]}
								removeSite={removeSite}
								removeSiteGroup={removeSiteGroup}
							/>
						),
					}
				})

			const groupCards = combineSitesAndGroups(siteGroups, sites).map((groupCard: SiteCardProps) => {
				const masterGroup = siteGroups.find((g) => g.siteGroup_Id === groupCard.siteGroup_Id)
				return {
					name: masterGroup ? masterGroup.siteGroup_Name : '',
					created: masterGroup ? masterGroup.created.create_Ts : '',
					assetCount: calculateTotalAlerts(groupCard.siteGroup_Children, groupCard.sites, 0),
					siteCard: (
						<SiteCard
							key={groupCard.siteGroup_Id}
							siteGroup_Id={groupCard.siteGroup_Id}
							siteGroup_Name={groupCard.siteGroup_Name}
							siteGroup_Children={groupCard.siteGroup_Children}
							sites={groupCard.sites}
							removeSite={removeSite}
							removeSiteGroup={removeSiteGroup}
						/>
					),
				}
			})

			setCards(groupCards.concat(siteCards))
		}
		if (sites && siteGroups) {
			generateSiteCards(siteGroups, sites)
		}
	}, [sites, siteGroups])

	const siteCardSort = (a: Card, b: Card, value: keyof Card) => {
		const order = 'ASC'

		const valueA = a[value]
		const valueB = b[value]

		if (Number.isInteger(valueA)) {
			return Number(valueB) - Number(valueA)
		}

		if (valueA === '' || valueA === null || valueA === 0) return 1
		if (valueB === '' || valueB === null || valueB === 0) return -1

		return valueA && valueB ? (order === 'ASC' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA))) : 1
	}

	React.useEffect(() => {
		const applySortAndFilter = (Cards: Card[]) => {
			const sortedCards = [...Cards].sort((a: Card, b: Card) => {
				if (sortBy === 'name') {
					return siteCardSort(a, b, 'name')
				} else if (sortBy === 'created') {
					return siteCardSort(a, b, 'created')
				} else if (sortBy === 'assetCount') {
					return siteCardSort(a, b, 'assetCount')
				}
				return 0
			})

			const filteredCards = sortedCards.filter((card) => {
				if (filters === 'none') {
					return true
				} else if (filters === 'withAssets') {
					return card.assetCount > 0
				} else if (filters === 'withoutAssets') {
					return card.assetCount === 0
				}
				return true
			})

			setSortedFilteredCards(filteredCards)
		}

		if (cards) {
			applySortAndFilter(cards)
		}
	}, [cards, sortBy, filters])

	const fetchReadingData = async () => {
		if (sites) {
			const readingReq = await Request.get<SiteSystemStatusResult>(`site/systemStatus`, context.appState.authState)

			// Update the sites isAliveTs with the latest reading
			const updatedSites = sites.map((site) => {
				const reading = readingReq.data.sites.find((reading) => reading.site_Id === site.site_Id)
				return { ...site, site_IsAliveTs: reading ? reading.site_IsAliveTs : null, detailedStatus: reading ? reading.detailedStatus : null }
			})
			setSites(updatedSites || null)
		}
	}

	useInterval(() => {
		fetchReadingData()
	}, 5000)

	return (
		<div>
			<Messages messages={messages} updateMessage={setMessages} />
			<SiteDashboardHeader
				setShowAddSiteModal={setShowAddSiteModal}
				setShowAddGroupModal={setShowAddGroupModal}
				setSortBy={setSortBy}
				setFilters={setFilters}
			/>

			<Container fluid style={styles.siteDashboardContainer}>
				<Row>
					<Col sm={9}>
						<SiteDashboardStats />

						<Row style={styles.siteListHeader}>
							<Col sm={4}>
								<span className="dashboard-list-titles">SITE / GROUP</span>
							</Col>
							<Col sm={1} className="text-center">
								<span className="dashboard-list-titles">STATUS</span>
							</Col>
							<Col sm={4}>
								<span className="dashboard-list-titles">SYSTEM STATUS</span>
							</Col>
							<Col sm={1} className="text-center">
								<span className="dashboard-list-titles">VERSION</span>
							</Col>
							<Col sm={1} className="text-center">
								<span className="dashboard-list-titles">ASSETS</span>
							</Col>
							<PermissionsCheck object={PermissionModelObject.Site} action={PermissionModelAction.PUT} context={PermissionModelContext.None}>
								<Col sm={1} className="text-center">
									<span className="dashboard-list-titles">ACTION</span>
								</Col>
							</PermissionsCheck>
						</Row>
						{pageStatus !== 'Loading' ? (
							<Row>
								<Col>
									{sortedFilteredCards.map((card) => (
										<div key={card.name}>{card.siteCard}</div>
									))}
								</Col>
							</Row>
						) : null}
					</Col>
					<Col sm={3}>
						<Row>
							<Col>
								<SiteDashboardMonthlyReports />
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
			<AddGroupModal
				showAddGroupModal={showAddGroupModal}
				setShowAddGroupModal={setShowAddGroupModal}
				siteGroups={siteGroups}
				setSiteGroups={setSiteGroups}
			/>
			<AddSiteModal showAddSiteModal={showAddSiteModal} setShowAddSiteModal={setShowAddSiteModal} setMessages={setMessages} />
		</div>
	)
}

const styles = {
	siteDashboardContainer: {
		paddingLeft: '50px',
		paddingRight: '50px',
		display: 'grid',
		marginTop: '-3%',
	},
	siteListHeader: { paddingLeft: '10px', paddingRight: '10px', marginTop: '20px' },
}

export { SiteDashboard }
