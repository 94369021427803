import React from 'react'
import { Form, FormCheckProps } from 'react-bootstrap'

interface FormSwitchProps extends FormCheckProps {
	name: string
	checked: boolean
	label?: string
	required?: boolean
	feedback?: string
	switchLabel?: string
}

const FormSwitch = (props: FormSwitchProps) => {
	const { switchLabel, ...htmlProps } = props
	return (
		<Form.Group controlId={props.name}>
			{props.label ? (
				<Form.Label>
					{props.label}
					{props.required ? ' *' : ''}
				</Form.Label>
			) : null}
			<Form.Check type="switch" {...htmlProps} label={switchLabel} onChange={(e) => !props.readOnly && props.onChange && props.onChange(e)} />
			{props.feedback ? <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback> : null}
		</Form.Group>
	)
}

export { FormSwitch }
