import React from 'react'
import './ChannelMaps.css'
import { Row, Col, Card } from 'react-bootstrap'
import { Line } from '../Line/Line'
import { ChannelMapDetail } from './ChannelMapDetail'
import { ChannelMappingHubProps, ChannelMappingPortProps, ChannelMapStructure } from './ChannelMaps'
import { isFlowReadingType, isPressureReadingType, isTempReadingType, readingType, ReadingTypeId } from '../../../constants/readingType'
import { defaultChannelMap } from '../../../models/ChannelMap'

const SFM_TFP_Hub = (props: ChannelMappingHubProps) => {
	return (
		<>
			<Row className="no-gutter">
				<Card className="channel-map-card">
					<Col style={styles.cell}>
						<span className="channel-map-title">
							{props.asset.asset_Name} ({props.asset.assetType_Name})
						</span>
					</Col>
				</Card>
			</Row>
			<Row>
				{Array.from({ length: 6 }).map((val, index) => (
					<div key={index} style={styles.cell25}>
						<SFM_TFP_Port
							asset={props.asset}
							assetOptions={props.assetOptions}
							portNumber={index + 1}
							channelMaps={props.channelMaps ? props.channelMaps.filter((channelMap) => channelMap.channelMap_PortNumber === index + 1) : []}
							channels={channelsByPort[index]}
							pageStatus={props.pageStatus}
							handleChange={props.handleChange}
						/>
					</div>
				))}
			</Row>
		</>
	)
}

const SFM_TFP_Port = (props: ChannelMappingPortProps) => {
	return (
		<Card style={styles.portCard}>
			<Row className="no-gutter">
				<span className="channel-port-title">Port {props.portNumber}</span>
			</Row>
			<Line style={styles.line} />
			<Row className="no-gutter">
				{props.channels.map((channel, index) => (
					<Col key={index} style={styles.cell50}>
						<ChannelMapDetail
							asset={props.asset}
							assetOptions={props.assetOptions}
							pageStatus={props.pageStatus}
							channelMapStructure={channel}
							channelMap={
								props.channelMaps.find(
									(channelMap) =>
										channelMap.channelMap_SubChannel === channel.subChannelNumber &&
										channel.readingTypeFilter(channelMap.readingType_Id as ReadingTypeId)
								) ||
								defaultChannelMap({
									channelMap_PortNumber: props.portNumber,
									channelMap_SubChannel: channel.subChannelNumber,
									channelMap_HubAssetId: props.asset?.asset_Id,
									hub_Asset: props.asset,
								})
							}
							handleChange={props.handleChange}
						/>
					</Col>
				))}
			</Row>
		</Card>
	)
}

const pressureChannels: ChannelMapStructure[] = [
	{
		subChannelNumber: 1,
		readingTypeFilter: isPressureReadingType,
		readingTypeDefault: readingType['Pressure Reading'].id,
		label: 'Pressure Ch 0',
		readingTypeFixed: false,
	},
	{
		subChannelNumber: 2,
		readingTypeFilter: isPressureReadingType,
		readingTypeDefault: readingType['Pressure Reading'].id,
		label: 'Pressure Ch 1',
		readingTypeFixed: false,
	},
]

const standardChannels: ChannelMapStructure[] = [
	{
		subChannelNumber: 1,
		readingTypeFilter: isTempReadingType,
		readingTypeDefault: readingType['Temperature Reading Mixed'].id,
		label: 'Temp Ch 0',
		readingTypeFixed: false,
	},
	{
		subChannelNumber: 1,
		readingTypeFilter: isFlowReadingType,
		readingTypeDefault: readingType['Flow Reading Mixed'].id,
		label: 'Flow Ch 0',
		readingTypeFixed: false,
	},
	{
		subChannelNumber: 2,
		readingTypeFilter: isTempReadingType,
		readingTypeDefault: readingType['Temperature Reading Mixed'].id,
		label: 'Temp Ch 1',
		readingTypeFixed: false,
	},
	{
		subChannelNumber: 2,
		readingTypeFilter: isFlowReadingType,
		readingTypeDefault: readingType['Flow Reading Mixed'].id,
		label: 'Flow Ch 1',
		readingTypeFixed: false,
	},
	{
		subChannelNumber: 3,
		readingTypeFilter: isTempReadingType,
		readingTypeDefault: readingType['Temperature Reading Mixed'].id,
		label: 'Temp Ch 2',
		readingTypeFixed: false,
	},
	{
		subChannelNumber: 3,
		readingTypeFilter: isFlowReadingType,
		readingTypeDefault: readingType['Flow Reading Mixed'].id,
		label: 'Flow Ch 2',
		readingTypeFixed: false,
	},
]

const channelsByPort = [standardChannels, standardChannels, standardChannels, standardChannels, standardChannels, pressureChannels]

const styles: { [key: string]: React.CSSProperties } = {
	portCard: {
		flexDirection: 'column',
		border: '1px solid black',
		padding: '30px 0px',
		minHeight: '450px',
	},
	line: {
		borderBottom: '1px solid black',
	},
	cell: {
		display: 'flex',
		justifyContent: 'center',
	},
	cell25: {
		flexBasis: '25%',
		padding: '0px 20px',
	},
	cell50: {
		flexBasis: '50%',
		marginTop: '20px',
		height: '90px',
	},
}

export { SFM_TFP_Hub }
