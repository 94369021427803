export const calculateSuggestedMin = (readings: { x: number; y: number | null }[], gapForDataLabels?: boolean) => {
	let minValue = readings.length > 0 ? readings[0].y ?? 0 : 0
	for (const currentObject of readings) {
		const currentValue = currentObject.y ?? 0
		minValue = Math.min(minValue, currentValue)
	}
	return Math.max(Math.floor(minValue / 5) * 5 + -(gapForDataLabels ? 10 : 5), 0)
}

export const calculateSuggestedMax = (readings: { x: number; y: number | null }[], gapForDataLabels?: boolean) => {
	let maxValue = readings.length > 0 ? readings[0].y ?? 0 : 0
	for (const currentObject of readings) {
		const currentValue = currentObject.y ?? 0
		maxValue = Math.max(maxValue, currentValue)
	}
	return Math.floor(maxValue / 5) * 5 + (gapForDataLabels ? 10 : 5)
}
