import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Card, Col, Row } from 'react-bootstrap'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import * as yup from 'yup'
import { Button } from '../Button/Button'
import { Loading } from '../Loading/Loading'
import { FormSelect, SelectOption } from '../Form/Select'
import { FormText } from '../Form/Text'
import { Site, SiteResult, defaultSite } from '../../../models/Site'
import { PageStatus } from '../../../types/PageStatus'
import * as Request from '../../../utilities/request'
import { hasPermission } from '../../../utilities/permissions/permission'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../../utilities/permissions/permission.d'
import PermissionsCheck from '../../Permissions/PermissionsCheck'
import { SiteEventSettings, SiteSettingsData } from './SiteEventSettings'

const siteValidationSchema = yup.object().shape({
	site_Name: yup.string().required('Name is Required').max(100, 'Name must be less than ${max} characters'),
	site_Timezone: yup.string().required('Timezone is Required'),
})

const SiteSettingsCard = () => {
	const context = useContext(AppContext)
	const hasEditPermissions = hasPermission(PermissionModelObject.Site, PermissionModelAction.PUT, context.appState, PermissionModelContext.Site)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const saveSiteSettingsRef: React.RefObject<FormikProps<SiteSettingsData>> = React.useRef(null)

	const defaultFormSite = context.appState.currentSite || defaultSite({})
	const [formSite, setFormSite] = React.useState<Site>(defaultFormSite)
	const formRef = React.useRef<FormikProps<Site>>(null)

	React.useEffect(() => {
		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			formRef.current?.resetForm()
			setFormSite(defaultFormSite)
			setPageStatus('Ready')
		}
	}, [context])

	const handleSiteSubmit = async (values: Site, formikHelpers: FormikHelpers<Site>) => {
		saveSiteSettingsRef.current?.handleSubmit()
		return Request.handleRequest(() => Request.put<SiteResult>(`site`, values, context.appState.authState), {
			successFunction: (data) => {
				if (data.sites.length > 0) {
					context.setAppState({ state: 'setCurrentSite', data: { site: data.sites[0] } })
					formikHelpers.resetForm()
				}
			},
			messageAction: 'editing',
			messageObject: 'site',
		})
	}

	const handleCancel = () => {
		context.setAppState({ state: 'setCurrentSite', data: { site: context.appState.currentSite } })
		saveSiteSettingsRef.current?.resetForm()
		setPageStatus('Ready')
	}

	return (
		<Formik initialValues={formSite} validationSchema={siteValidationSchema} onSubmit={handleSiteSubmit} enableReinitialize innerRef={formRef}>
			{({ handleSubmit, isSubmitting, touched, errors, values, handleChange, setFieldValue }) => (
				<Card className="p-0">
					<Card.Header>Monitoring Settings</Card.Header>
					{pageStatus === 'Loading' ? (
						<Card.Body>
							<Loading show />
						</Card.Body>
					) : (
						<Card.Body>
							<Row>
								<Col>
									<FormText
										name="site_Name"
										value={values.site_Name}
										onChange={handleChange}
										label="Site Name"
										required
										feedback={touched.site_Name && errors.site_Name ? errors.site_Name : ''}
										isInvalid={touched.site_Name && !!errors.site_Name}
										disabled={!hasEditPermissions}
									/>
								</Col>
								<Col>
									<FormSelect
										name="site_Timezone"
										required
										// This is a function that exists for browsers to use, but doesn't exist in the typescript definition of node 16
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										options={(Intl as any)
											.supportedValuesOf('timeZone')
											.sort((a: string, b: string) => (a < b ? -1 : 1))
											.map((timezone: string) => ({
												value: timezone,
												label: timezone.replace(/_/g, ' '),
											}))}
										value={values.site_Timezone}
										onChange={(option) => {
											setFieldValue('site_Timezone', (option as SelectOption<string>).value)
										}}
										label="Site Timezone"
										feedback={touched.site_Timezone && errors.site_Timezone ? errors.site_Timezone : ''}
										isDisabled={!hasEditPermissions}
									/>
								</Col>
							</Row>
							<SiteEventSettings
								pageStatus={pageStatus}
								saveSiteSettingsRef={saveSiteSettingsRef}
								setPageStatus={setPageStatus}
								setMessages={() => null}
							/>
							<PermissionsCheck object={PermissionModelObject.Site} action={PermissionModelAction.PUT} context={PermissionModelContext.Site}>
								<Row className="mt-3">
									<Col sm="auto">
										<Button
											disabled={isSubmitting}
											variant="secondary"
											onClick={() => {
												handleCancel()
											}}
										>
											Cancel
										</Button>
									</Col>
									<Col sm="auto">
										<Button
											disabled={isSubmitting}
											variant="primary"
											onClick={() => {
												handleSubmit()
											}}
										>
											Save
										</Button>
									</Col>
								</Row>
							</PermissionsCheck>
						</Card.Body>
					)}
				</Card>
			)}
		</Formik>
	)
}

export { SiteSettingsCard }
