import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'

interface LocationBuilding {
	locationBuilding_Id: string
	locationBuilding_Name: string
	locationBuilding_Note: string | null
	site_Id: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface LocationBuildingResult extends Validate.Result {
	locationBuildings: LocationBuilding[]
}

const defaultLocationBuilding = (locationBuilding: Partial<LocationBuilding>): LocationBuilding => ({
	locationBuilding_Id: locationBuilding.locationBuilding_Id || '',
	locationBuilding_Name: locationBuilding.locationBuilding_Name || '',
	locationBuilding_Note: locationBuilding.locationBuilding_Note || null,
	site_Id: locationBuilding.site_Id || '',

	created: locationBuilding.created || defaultCreated({}),
	modified: locationBuilding.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultLocationBuilding }
export type { LocationBuilding, LocationBuildingResult }
