import React from 'react'
import { AppContext } from '../../../App'
import { Site, SiteResult } from '../../../models/Site'
import { PageStatus } from '../../../types/PageStatus'
import * as Request from '../../../utilities/request'
import { Document, DocumentResult } from '../../../models/Document'
import { DocumentTypeId, ReportArchiveDocumentTypes, documentTypeDropdownValues, getDocumentTypeNameFromId } from '../../../constants/documentType'
import { DateTime } from 'luxon'
import { formatIncomingDateTime, formatOutgoingDateTime } from '../../../utilities/formatDate'
import { Col, Row } from 'react-bootstrap'
import { Listing, ListingColumn } from '../Listing/Listing'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

interface ReportArchiveProps {
	context: 'site' | 'siteGroup' | 'none'
}

const ReportArchive = (props: ReportArchiveProps) => {
	const context = React.useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [documents, setDocuments] = React.useState<Document[]>([])
	const [sites, setSites] = React.useState<Site[]>([])
	const [monthDate, setMonthDate] = React.useState<DateTime>(DateTime.now().set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }))

	const timeZone =
		props.context === 'site'
			? context.appState.currentSite?.site_Timezone
			: props.context === 'siteGroup'
			? context.appState.currentSiteGroup?.siteGroup_Timezone
			: undefined

	const getDocumentsBySiteAndType = async (site: Site, documentType: string) => {
		return Request.get<DocumentResult>(
			`document?Site_Id=${site.site_Id}&DocumentType_Id=${documentType}&MonthDate=${formatOutgoingDateTime({
				dateTime: monthDate,
				format: 'DateTimeObject',
				timeZone: timeZone,
			})}`,
			context.appState.authState
		)
	}

	React.useEffect(() => {
		const getData = async () => {
			const sites: Site[] = []
			if (props.context === 'none') {
				const siteReq = await Request.get<SiteResult>(`site`, context.appState.authState)
				sites.push(...siteReq.data.sites)
			} else if (props.context === 'siteGroup') {
				const siteReq = await Request.get<SiteResult>(
					`site?SiteGroup_Id=${context.appState.currentSiteGroup?.siteGroup_Id}`,
					context.appState.authState
				)
				sites.push(...siteReq.data.sites)
			} else if (context.appState.currentSite) {
				sites.push(context.appState.currentSite)
			}
			setSites(sites)

			const documentResults = await Promise.all(
				ReportArchiveDocumentTypes.flatMap((documentType) => sites.map((site) => getDocumentsBySiteAndType(site, documentType.id)))
			)
			setDocuments(documentResults.flatMap((res) => res.data.documents))

			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, props, monthDate])

	return (
		<>
			<Row>
				<Col sm="auto">
					<DatePicker
						wrapperClassName="datePicker"
						selected={monthDate.toJSDate()}
						onChange={(date) =>
							setMonthDate(DateTime.fromJSDate(date || new Date()).set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }))
						}
						isClearable={false}
						dateFormat="MMMM yyyy"
						showMonthYearPicker
						showIcon
					/>
				</Col>
				<Col />
			</Row>
			<Row>
				<Col>
					<Listing
						name="Document"
						namePlural="Documents"
						list={documents}
						getIDFunc={(d) => d.document_Id}
						selectedActions={[]}
						isLoading={pageStatus !== 'Ready' && pageStatus !== 'Submitting'}
						columns={(
							[
								{
									value: (item) => item.documentType_Id,
									render: (item) => <>{getDocumentTypeNameFromId(item.documentType_Id as DocumentTypeId)}</>,
									showHeader: true,
									headerText: 'Document Type',
									sortColumnName: 'documentType_Id',
									filterType: 'dropdown',
									filterOptions: {
										columnName: 'documentType_Id',
										options: documentTypeDropdownValues
											.filter((docType) => ReportArchiveDocumentTypes.some((raDocType) => raDocType.id === docType.value))
											.map((option) => ({ value: option.value, text: option.label })),
									},
								},
								{
									value: (item) => item.document_Name,
									render: (item) => (
										<div
											onClick={() => {
												window.open(item.document_Url || undefined, '_blank')
											}}
											className="text-link"
										>
											{item.document_Name}
										</div>
									),
									showHeader: true,
									headerText: 'Name',
									sortColumnName: 'document_Name',
									filterType: 'string',
									filterOptions: {
										columnName: 'document_Name',
									},
								},
							] as ListingColumn<Document>[]
						)
							.concat(
								props.context !== 'site'
									? [
											{
												value: (item) => sites.find((s) => s.site_Id === item.site_Id)?.site_Name || '',
												render: (item) => <>{sites.find((s) => s.site_Id === item.site_Id)?.site_Name || ''}</>,
												showHeader: true,
												headerText: 'Site',
												sortColumnName: 'site_Name',
												filterType: 'dropdown',
												filterOptions: {
													columnName: 'site_Name',
													options: sites.map((s) => ({ value: s.site_Name, text: s.site_Name })),
												},
											},
									  ]
									: []
							)
							.concat([
								{
									value: (item) => item.created.create_Ts,
									render: (item) => (
										<>
											{formatIncomingDateTime({
												dateTime: item.created.create_Ts,
												format: 'DateAndTimeAndTimezoneContext',
												timeZone: timeZone,
											})}
										</>
									),
									showHeader: true,
									headerText: 'Created',
									sortColumnName: 'created.create_Ts',
								},
							])}
						defaultSort={{ column: 'document_Name', order: 'ASC' }}
					/>
				</Col>
			</Row>
		</>
	)
}

export default ReportArchive
