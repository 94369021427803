import React, { useContext } from 'react'
import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { PageStatus } from '../../types/PageStatus'
import { Loading } from '../../components/UI/Loading/Loading'
import { User, UserResult } from '../../models/User'
import { UserSite, UserSiteResult, generateUserSiteDescription } from '../../models/UserSite'
import { UserList } from './UserList'
import PickerHeader from '../../components/UI/ContextualPickers/PickerHeader'

const ScreensUserManagementSite = () => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [users, setUsers] = React.useState<User[] | null>(null)
	const [userOptions, setUserOptions] = React.useState<User[] | null>(null)
	const [userSites, setUserSites] = React.useState<UserSite[] | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [userOptionsResponse, usersResponse, userSiteResponse] = await Promise.all([
				Request.get<UserResult>(`user`, context.appState.authState),
				Request.get<UserResult>(`user?site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
				Request.get<UserSiteResult>(`userSite?site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
			])

			// Generate UserSites_Description
			usersResponse.data.users.forEach((user) => {
				user.userSites_Description = generateUserSiteDescription(user, userSiteResponse.data.userSites)
			})
			setUsers(usersResponse.data.users)
			setUserOptions(userOptionsResponse.data.users)
			setUserSites(userSiteResponse.data.userSites)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	return (
		<>
			<PickerHeader type="site" title="Users" />
			{pageStatus !== 'Loading' && users && userOptions && userSites ? (
				<>
					<UserList
						type="Site"
						title={`${context.appState.currentSite?.site_Name} Users`}
						users={users}
						setUsers={setUsers}
						userOptions={userOptions}
						userSites={userSites}
						setUserSites={setUserSites}
					/>
				</>
			) : (
				<Loading show={true} />
			)}
		</>
	)
}

export { ScreensUserManagementSite }
