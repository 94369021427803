import React from 'react'
import { Link } from 'react-router-dom'

interface CardTextProps {
	text: string | null | undefined
	link?: string
}

const CardText = (props: CardTextProps) => {
	if (props.link) {
		if (props.text) {
			return (
				<Link to={props.link}>
					<span className="card-text">{props.text}</span>
				</Link>
			)
		}
	}
	return <span className="card-text">{props.text ? props.text : '-'}</span>
}
export { CardText }
