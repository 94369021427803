import React, { useContext } from 'react'
import './EditSiteGroup.css'

import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { useParams } from 'react-router-dom'

import { Card, Col, Container, Dropdown, Row } from 'react-bootstrap'
import { Site, SiteResult } from '../../models/Site'
import { SiteGroup, SiteGroupResult } from '../../models/SiteGroup'
import { PageStatus } from '../../types/PageStatus'
import { Button } from '../../components/UI/Button/Button'
import { Loading } from '../../components/UI/Loading/Loading'
import { Tree, getBackendOptions, MultiBackend, NodeModel, TreeMethods, DropOptions } from '@minoru/react-dnd-treeview'
import { DndProvider } from 'react-dnd'
import { AddSiteModal } from '../../components/UI/SiteDashboard/AddSiteModal'
import { AddGroupModal } from '../../components/UI/SiteDashboard/AddGroupModal'
import { FormikHelpers } from 'formik'
import group from '../../images/icons/group.svg'
import user from '../../images/icons/user management.svg'
import grabber from '../../images/icons/move_grabber.svg'
import chevron from '../../images/icons/chevron.svg'

import ellipsis from '../../images/icons/options.svg'
import { MoveToExternalModal } from '../../components/UI/EditSiteGroup/MoveToExternalModal'
import { ManageUsersContextual } from '../../components/UI/ManageUsersContextual/ManageUsersContextual'
import { Back } from '../../components/UI/Back/Back'
import { ConfirmModal } from '../../components/UI/ConfirmModal/ConfirmModal'
import { colors } from '../../constants/colors'
import { Line } from '../../components/UI/Line/Line'
import { EditSiteGroupDetails } from './EditSiteGroupDetails'
import { MoveFromExternalModal } from '../../components/UI/EditSiteGroup/MoveFromExtermalModal'

type ModifiedStatus = 'new' | 'modified' | 'deleted' | 'unchanged'
interface NodeDataType {
	object: Site | SiteGroup
	modifiedStatus: ModifiedStatus
}

const EditSiteGroup = () => {
	const context = useContext(AppContext)
	const { siteGroup_Id } = useParams()
	const [resetData, setResetData] = React.useState<boolean>(false)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [sites, setSites] = React.useState<Site[] | null>(null)
	const [siteGroups, setSiteGroups] = React.useState<SiteGroup[] | null>(null)
	const [rootSiteGroup, setRouteSiteGroup] = React.useState<SiteGroup | null>(null)

	const treeRef = React.useRef<TreeMethods>(null)
	const [treeData, setTreeData] = React.useState<NodeModel<NodeDataType>[]>([])

	const [showAddSiteModal, setShowAddSiteModal] = React.useState<boolean>(false)
	const [showAddGroupModal, setShowAddGroupModal] = React.useState<boolean>(false)
	const [subGroupParentId, setSubGroupParentId] = React.useState<string | null>(null)
	const [showMoveExternalModal, setShowMoveExternalModal] = React.useState<boolean>(false)
	const [moveExternalSite, setMoveExternalSite] = React.useState<Site | null>(null)
	const [moveExternalSiteGroup, setMoveExternalSiteGroup] = React.useState<SiteGroup | null>(null)
	const [destinationSiteGroup, setDestinationSiteGroup] = React.useState<SiteGroup | null>(null)

	const [showDeleteNodeModal, setShowDeleteNodeModal] = React.useState<boolean>(false)
	const [nodeToDelete, setNodeToDelete] = React.useState<NodeModel<NodeDataType> | null>(null)

	const [nodeSelected, setNodeSelected] = React.useState<NodeModel<NodeDataType> | null>(null)

	// Note: Throughout this component droppable is used to distinguish between Site and SiteGroup, as only SiteGroups can have children
	const treeBuilder = (parentSiteGroup: string, sites: Site[], siteGroups: SiteGroup[]): NodeModel<NodeDataType>[] => {
		const treeData: NodeModel<NodeDataType>[] = []

		sites
			.filter((s) => s.siteGroup_Id === parentSiteGroup)
			.forEach((s) => {
				treeData.push({
					id: s.site_Id,
					parent: parentSiteGroup,
					text: s.site_Name,
					droppable: false,
					data: { object: s, modifiedStatus: 'unchanged' },
				})
			})

		siteGroups
			.filter((sg) => sg.siteGroup_ParentId === parentSiteGroup)
			.forEach((sg) => {
				treeData.push({
					id: sg.siteGroup_Id,
					parent: parentSiteGroup,
					text: sg.siteGroup_Name,
					droppable: true,
					data: { object: sg, modifiedStatus: 'unchanged' },
				})
				treeData.push(...treeBuilder(sg.siteGroup_Id, sites, siteGroups))
			})

		return treeData
	}

	React.useEffect(() => {
		const getData = async () => {
			const [sitesReq, siteGroupsReq] = await Promise.all([
				Request.get<SiteResult>(`site`, context.appState.authState),
				Request.get<SiteGroupResult>(`siteGroup`, context.appState.authState),
			])
			setSites(sitesReq.data.sites)
			setSiteGroups(siteGroupsReq.data.siteGroups)

			if (siteGroup_Id) {
				setTreeData(treeBuilder(siteGroup_Id, sitesReq.data.sites, siteGroupsReq.data.siteGroups))
				const rootSiteGroup = siteGroupsReq.data.siteGroups?.find((sg) => sg.siteGroup_Id === siteGroup_Id)

				if (rootSiteGroup) {
					setRouteSiteGroup(rootSiteGroup)
					setNodeSelected({
						id: siteGroup_Id,
						parent: '',
						droppable: true,
						text: rootSiteGroup?.siteGroup_Name || '',
						data: { object: rootSiteGroup as SiteGroup, modifiedStatus: 'unchanged' },
					})
				}
			}

			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context, resetData])

	const handleDrop = (newTreeData: NodeModel<NodeDataType>[], options: DropOptions<NodeDataType>) => {
		if (options.dragSource && options.dragSource.data) {
			const node = newTreeData.find((n) => n.id === options.dragSource?.id)

			if (node && node.data) {
				if (node.data.modifiedStatus === 'unchanged') {
					node.data.modifiedStatus = 'modified'
				}
				if (node.droppable) {
					node.data.object = {
						...node.data.object,
						siteGroup_ParentId: options.dropTarget?.id ? (options.dropTarget?.id as string) : siteGroup_Id,
					} as SiteGroup
				} else {
					node.data.object = {
						...node.data.object,
						siteGroup_Id: options.dropTarget?.id ? (options.dropTarget?.id as string) : siteGroup_Id,
					} as Site
				}
			}
		}

		setTreeData(newTreeData)
	}

	const siteOnSubmit = (values: Site, formikHelpers: FormikHelpers<Site>) => {
		return new Promise<void>((resolve) => {
			setTreeData([
				...treeData,
				{
					id: values.site_Id,
					parent: values.siteGroup_Id || '',
					droppable: false,
					text: values.site_Name,
					data: { object: values, modifiedStatus: 'new' },
				},
			])
			setShowAddSiteModal(false)
			setSubGroupParentId(null)
			formikHelpers.resetForm()
			resolve()
		})
	}

	const siteGroupOnSubmit = (values: SiteGroup, formikHelpers: FormikHelpers<SiteGroup>) => {
		return new Promise<void>((resolve) => {
			setTreeData([
				...treeData,
				{
					id: values.siteGroup_Id,
					parent: values.siteGroup_ParentId || '',
					droppable: true,
					text: values.siteGroup_Name,
					data: { object: values, modifiedStatus: 'new' },
				},
			])
			setShowAddGroupModal(false)
			setSubGroupParentId(null)
			formikHelpers.resetForm()
			resolve()
		})
	}

	const deleteNode = (node: NodeModel<NodeDataType>) => {
		const toDeleteNodes = [node, ...getSubTree(node.id as string, true)]

		setTreeData(
			treeData
				.filter((n) => !(toDeleteNodes.some((dn) => dn.id === n.id) && n.data?.modifiedStatus === 'new'))
				.map((n) =>
					toDeleteNodes.some((dn) => dn.id === n.id) ? ({ ...n, data: { ...n.data, modifiedStatus: 'deleted' } } as NodeModel<NodeDataType>) : n
				)
		)
	}

	const getSubTree = (parentNodeId: string, excludeDeleted: boolean) => {
		const subTree: NodeModel<NodeDataType>[] = []

		treeData
			.filter((n) => n.parent === parentNodeId && n.data && (excludeDeleted ? n.data.modifiedStatus !== 'deleted' : true))
			.forEach((n) => {
				subTree.push(n)
				subTree.push(...getSubTree(n.id as string, excludeDeleted))
			})

		return subTree
	}

	const handleSave = async () => {
		setPageStatus('Submitting')

		// Only order that matters for requests is to create new SiteGroups top down in respect to tree hierarchy, to avoid foreign key errors
		const newGroups = getSubTree(siteGroup_Id as string, false).filter((n) => n.droppable && n.data?.modifiedStatus === 'new')

		newGroups.forEach(async (n) => {
			if (n.data) {
				await Request.post<SiteGroup>(`siteGroup`, n.data.object as SiteGroup, context.appState.authState)
			}
		})

		// Then we can do the rest in parallel
		await Promise.all(
			treeData.map((n) => {
				if (n.data) {
					switch (n.data.modifiedStatus) {
						case 'new':
							if (n.droppable === false) {
								return Request.post<Site>(`site`, n.data.object as Site, context.appState.authState)
							} else {
								return Promise.resolve()
							}
						case 'modified':
							if (n.droppable) {
								return Request.put<SiteGroup>(`siteGroup`, n.data.object as SiteGroup, context.appState.authState)
							} else {
								return Request.put<Site>(`site`, n.data.object as Site, context.appState.authState)
							}
						case 'deleted':
							if (n.droppable) {
								return Request.del<SiteGroup>(`siteGroup?id=${(n.data.object as SiteGroup).siteGroup_Id}`, context.appState.authState)
							} else {
								return Request.del<Site>(`site?id=${(n.data.object as Site).site_Id}`, context.appState.authState)
							}
						default:
							return Promise.resolve()
					}
				} else {
					return Promise.resolve()
				}
			})
		)
	}

	const sitesNotOnPage = (sites || []).filter((s) => !treeData.some((td) => String(td.id) === s.site_Id)).sort((a, b) => (a.site_Name < b.site_Name ? -1 : 1))

	return (
		<>
			<Row style={styles.siteDashboardHeader}>
				<Col>
					<Row className="pt-3">
						<Col sm="auto">
							<Back />
						</Col>
					</Row>
					<Row>
						<Col sm="auto" className={'center-flex h1'}>
							<span>{siteGroups?.find((sg) => sg.siteGroup_Id === siteGroup_Id)?.siteGroup_Name}</span>
						</Col>
					</Row>
				</Col>
			</Row>
			<Container fluid>
				{pageStatus === 'Loading' ? (
					<Row>
						<Col className={'center-flex'}>
							<Loading show />
						</Col>
					</Row>
				) : (
					siteGroup_Id && (
						<>
							<EditSiteGroupDetails siteGroup={rootSiteGroup} siteGroups={siteGroups} resetData={resetData} setResetData={setResetData} />
							<Row className="mt-2">
								<Col>
									<Card className="p-4 mt-0 mb-0 h-100">
										<Row>
											<Col sm="auto" className={'center-flex'}>
												<img src={group} className="group-icon" alt="group icon" />
											</Col>
											<Col sm="auto" className={'center-flex'}>
												<span className="h4 mb-0">Group</span>
											</Col>
										</Row>
										<Row>
											<Col>
												<span className="edit-blurb">
													Drag the items into the order you prefer. Click the action icon on the right of the item to reveal options.
												</span>
											</Col>
										</Row>
										<Line />
										<Row className="mt-2">
											<Col className="tree-col">
												<Row
													onClick={() => {
														setNodeSelected({
															id: siteGroup_Id,
															parent: '',
															droppable: true,
															text: rootSiteGroup?.siteGroup_Name || '',
															data: { object: rootSiteGroup as SiteGroup, modifiedStatus: 'unchanged' },
														})
													}}
												>
													<Col
														className="tree-row tree-head"
														style={{ background: nodeSelected?.id === siteGroup_Id ? '#e0e0e0' : '#f5f5f5' }}
													>
														{`${siteGroups?.find((sg) => sg.siteGroup_Id === siteGroup_Id)?.siteGroup_Name}`}
														<Dropdown>
															<Dropdown.Toggle as="img" src={ellipsis} alt="Site options" className="site-card-dropdown-toggle" />
															<Dropdown.Menu>
																<Dropdown.Item
																	onClick={() => {
																		setSubGroupParentId(siteGroup_Id)
																		setShowAddGroupModal(true)
																	}}
																>
																	Add Group
																</Dropdown.Item>
																<Dropdown.Item
																	onClick={() => {
																		setSubGroupParentId(siteGroup_Id)
																		setShowAddSiteModal(true)
																	}}
																>
																	Add Site
																</Dropdown.Item>
																<Dropdown.Item
																	onClick={() => {
																		const sg = siteGroups?.find((sg) => sg.siteGroup_Id === siteGroup_Id)
																		if (sg) {
																			setDestinationSiteGroup(sg)
																		}
																	}}
																>
																	Move External Site to Group
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</Col>
												</Row>

												<DndProvider backend={MultiBackend} options={getBackendOptions()}>
													<Tree
														ref={treeRef}
														tree={treeData.filter((n) => n.data?.modifiedStatus !== 'deleted')}
														rootId={siteGroup_Id}
														initialOpen
														onDrop={handleDrop}
														listComponent="div"
														listItemComponent="div"
														classes={{
															root: 'tree-root',
															dropTarget: 'target-node',
														}}
														enableAnimateExpand
														render={(node, { depth, isOpen, onToggle }) => (
															<div
																style={{
																	marginLeft: `${(depth + 1) * 15}px`,
																	background: nodeSelected?.id === node.id ? '#e0e0e0' : '#f5f5f5',
																}}
																className="tree-row"
																onClick={() => {
																	setNodeSelected(node)
																}}
															>
																<div className="node-content-left">
																	{node.droppable && (
																		<img
																			src={chevron}
																			onClick={onToggle}
																			className={`toggle-btn${isOpen ? '' : ' inverted'}`}
																			alt="toggle arrow"
																		/>
																	)}
																	<img src={grabber} alt="move grabber" />
																	<span>{node.text}</span>
																</div>
																<Dropdown>
																	<Dropdown.Toggle
																		as="img"
																		src={ellipsis}
																		alt="Site options"
																		className="site-card-dropdown-toggle"
																	/>
																	<Dropdown.Menu>
																		{node.droppable && (
																			<>
																				<Dropdown.Item
																					onClick={() => {
																						if (node.data) {
																							setSubGroupParentId((node.data.object as SiteGroup).siteGroup_Id)
																							setShowAddGroupModal(true)
																						}
																					}}
																				>
																					Add Group
																				</Dropdown.Item>

																				<Dropdown.Item
																					onClick={() => {
																						if (node.data) {
																							setSubGroupParentId((node.data.object as SiteGroup).siteGroup_Id)
																							setShowAddSiteModal(true)
																						}
																					}}
																				>
																					Add Site
																				</Dropdown.Item>

																				<Dropdown.Item
																					onClick={() => {
																						if (node.data) {
																							setDestinationSiteGroup(node.data.object as SiteGroup)
																						}
																					}}
																				>
																					Move External Site to Group
																				</Dropdown.Item>
																			</>
																		)}
																		<Dropdown.Item
																			onClick={() => {
																				if (node.data) {
																					if (node.droppable) {
																						setMoveExternalSite(null)
																						setMoveExternalSiteGroup(node.data.object as SiteGroup)
																					} else {
																						setMoveExternalSite(node.data.object as Site)
																						setMoveExternalSiteGroup(null)
																					}
																					setShowMoveExternalModal(true)
																				}
																			}}
																		>
																			Move to External Group
																		</Dropdown.Item>
																		<Dropdown.Item
																			onClick={(e) => {
																				e.stopPropagation()
																				setNodeSelected(null)
																				setNodeToDelete(node)
																				setShowDeleteNodeModal(true)
																			}}
																		>
																			Delete
																		</Dropdown.Item>
																	</Dropdown.Menu>
																</Dropdown>
															</div>
														)}
													/>
												</DndProvider>
											</Col>
										</Row>
										{treeData.some((n) => n.data?.modifiedStatus !== 'unchanged') && (
											<Row className="mt-3">
												<Col className={'center-flex invalid-feedback-custom'}>
													<span>Current changes are unsaved</span>
												</Col>
											</Row>
										)}
										<Row className="mt-3">
											<Col sm="auto">
												<Button
													variant="secondary"
													onClick={() => {
														setTreeData(treeBuilder(siteGroup_Id, sites as Site[], siteGroups as SiteGroup[]))
													}}
													disabled={pageStatus === 'Submitting'}
												>
													Cancel
												</Button>
											</Col>
											<Col sm="auto">
												<Button
													variant="primary"
													onClick={() => {
														handleSave()
														setResetData(!resetData)
														setNodeSelected(null)
													}}
													disabled={pageStatus === 'Submitting'}
												>
													Save
												</Button>
											</Col>
										</Row>
									</Card>
								</Col>

								<Col>
									<Card className="p-4 mt-0 mb-0 h-100">
										<Row>
											<Col sm="auto" className={'center-flex'}>
												<img src={user} className="group-icon" alt="user icon" />
											</Col>
											<Col sm="auto" className={'center-flex'}>
												<span className="h4 mb-0">Users{nodeSelected && ` - ${nodeSelected.text}`}</span>
											</Col>
										</Row>
										<Row>
											<Col>
												<span className="edit-blurb">Select a group or site on the left to edit users.</span>
											</Col>
										</Row>
										<Line />
										<Row className="mt-2 h-100">
											<Col className="d-flex flex-column justify-content-between">
												{nodeSelected ? (
													nodeSelected.data && nodeSelected.data.modifiedStatus !== 'new' ? (
														nodeSelected.droppable ? (
															<ManageUsersContextual
																type="SiteGroup"
																contextLocationId={(nodeSelected.data.object as SiteGroup).siteGroup_Id}
															/>
														) : (
															<ManageUsersContextual type="Site" contextLocationId={(nodeSelected.data.object as Site).site_Id} />
														)
													) : (
														<span>New site must be saved before users can be edited</span>
													)
												) : (
													<span>No Site or Group selected</span>
												)}
											</Col>
										</Row>
									</Card>
								</Col>
							</Row>
						</>
					)
				)}
			</Container>

			<AddGroupModal
				showAddGroupModal={showAddGroupModal}
				setShowAddGroupModal={setShowAddGroupModal}
				siteGroups={siteGroups}
				setSiteGroups={setSiteGroups}
				siteGroup_ParentId={subGroupParentId || siteGroup_Id}
				customOnSubmit={siteGroupOnSubmit}
			/>
			<AddSiteModal
				showAddSiteModal={showAddSiteModal}
				setShowAddSiteModal={setShowAddSiteModal}
				siteGroup_Id={subGroupParentId || siteGroup_Id}
				customOnSubmit={siteOnSubmit}
			/>
			<MoveToExternalModal
				showMoveExternalModal={showMoveExternalModal}
				setShowMoveExternalModal={setShowMoveExternalModal}
				siteGroups={siteGroups}
				resetData={resetData}
				setResetData={setResetData}
				handleSave={handleSave}
				site={moveExternalSite}
				siteGroup={moveExternalSiteGroup}
			/>
			<MoveFromExternalModal
				destinationSiteGroup={destinationSiteGroup}
				setDestinationSiteGroup={setDestinationSiteGroup}
				setResetData={setResetData}
				handleSave={handleSave}
				sites={sitesNotOnPage}
			/>
			{nodeToDelete && (
				<ConfirmModal
					show={showDeleteNodeModal}
					setShow={setShowDeleteNodeModal}
					title={`Delete ${nodeToDelete.droppable ? 'Group' : 'Site'}`}
					body={`Are you sure you want to delete ${nodeToDelete.text}?`}
					confirmButtonText="Delete"
					onConfirm={() => {
						deleteNode(nodeToDelete)
						setNodeToDelete(null)
					}}
				/>
			)}
		</>
	)
}

const styles = {
	siteDashboardHeader: {
		backgroundColor: colors.colorPrimaryCyan,
		height: '150px',
		paddingLeft: '50px',
		paddingRight: '50px',
		marginBottom: '75px',
	},
}

export { EditSiteGroup }
