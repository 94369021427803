import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Formik, FormikErrors } from 'formik'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'

import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { formatIncomingDateTime, formatOutgoingDateTime } from '../../utilities/formatDate'
import { PermissionModelAction, PermissionModelContext, PermissionModelObject } from '../../utilities/permissions/permission.d'

import { useParams } from 'react-router-dom'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { Line } from '../../components/UI/Line/Line'
import { Back } from '../../components/UI/Back/Back'
import { AssetDetails } from '../../components/UI/AssetDetails/Edit/AssetDetails'
import { AssetLocation } from '../../components/UI/AssetDetails/Edit/AssetLocation'
import { AssetFiles } from '../../components/UI/AssetDetails/Edit/AssetFiles'
import { AssetInstallation } from '../../components/UI/AssetDetails/Edit/AssetInstallation'
import { AssetGroups } from '../../components/UI/AssetDetails/Edit/AssetGroups'
import { ConfirmModal } from '../../components/UI/ConfirmModal/ConfirmModal'
import PermissionsCheck from '../../components/Permissions/PermissionsCheck'

import { Asset, AssetResult, defaultAsset } from '../../models/Asset'
import { PageStatus } from '../../types/PageStatus'
import { assetType } from '../../constants/assetType'
import { AssetTreeEdit } from '../../components/UI/AssetDetails/Edit/AssetTreeEdit'
import { MessageAction } from '../../components/UI/Messages/Message'
import { AssetTree, AssetTreeResult } from '../../models/AssetTree'
import { assetClass } from '../../constants/assetClass'
import { ChannelMapping } from '../../components/UI/AssetDetails/ChannelMapping'
import { ChannelMap, ChannelMapResult } from '../../models/ChannelMap'
import { defaultCreated, defaultModified } from '../../models/History'
import { AssetGroupResult } from '../../models/AssetGroup'
import { FormText } from '../../components/UI/Form/Text'
import { Loading } from '../../components/UI/Loading/Loading'
import { AssetContract } from '../../components/UI/AssetDetails/Edit/AssetContract'

const assetValidationSchema = yup.object().shape({
	asset: yup.object().shape({
		asset_Name: yup.string().required('Object ID is required.').max(100, 'Max character limit is 100.'),
		asset_SerialNo: yup.string().nullable().max(100, 'Max character limit is 100.'),
		asset_InstallNote: yup.string().nullable().max(1000, 'Max character limit is 1000.'),
		assetTransmitter_EUI: yup
			.string()
			.nullable()
			.max(23, 'Max character limit is 23.')
			.matches(
				/^([0-9A-Fa-f]{2}-){7}[0-9A-Fa-f]{2}$/,
				'EUI must be eight pairs of hexadecimal characters separated by hyphens, e.g, 00-80-e1-15-05-0c-cb-ba'
			),
		assetClass_Id: yup.string(),
		hub_Number: yup.number().when('assetClass_Id', (assetClass_Id) => {
			if (assetClass_Id[0] === assetClass.Hub.id) {
				return yup.number().required('Hub Number is required.').typeError('Hub Number must be a number.')
			}
			return yup.number().nullable()
		}),
	}),
})

const EditAssetDetails = () => {
	const context = React.useContext(AppContext)
	const navigate = useNavigate()
	const id = useParams().id

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [messages, setMessages] = useMessageReducer([])

	const [asset, setAsset] = React.useState<Asset | null>(null)
	const [editAssetName, setEditAssetName] = React.useState(false)

	const [showModal, setShowModal] = React.useState<boolean>(false)

	React.useEffect(() => {
		const getData = async () => {
			const [assetReq] = await Promise.all([Request.get<AssetResult>(`asset?Id=${id}`, context.appState.authState)])
			if (assetReq.data.assets.length > 0) {
				setAsset(
					defaultAsset({
						...assetReq.data.assets[0],
						asset_InstallDate: assetReq.data.assets[0].asset_InstallDate
							? formatIncomingDateTime({
									dateTime: assetReq.data.assets[0].asset_InstallDate,
									format: 'Custom',
									timeZone: context.appState.currentSite?.site_Timezone,
									customFormat: 'yyyy-MM-dd',
							  })
							: assetReq.data.assets[0].asset_InstallDate,
					})
				)
				setPageStatus('Ready')
			} else {
				setPageStatus('Error')
			}
		}

		if (context.appState.authState.isLoggedIn && context.appState.currentSite) {
			getData()
		}
	}, [id])

	const handleSaveAssetGroups = async (values: Asset) => {
		const assetGroupsToCreate = values.asset_Groups.filter((assetGroup) => assetGroup.assetGroup_Id === '')
		const assetGroupsToDelete =
			asset?.asset_Groups.filter(
				(existingAssetGroup) => !values.asset_Groups.some((assetGroup) => assetGroup.assetGroup_Id === existingAssetGroup.assetGroup_Id)
			) || []
		await Promise.all([
			...assetGroupsToCreate.map((assetGroup) => Request.post<AssetGroupResult>('assetGroup', assetGroup, context.appState.authState)),
			...assetGroupsToDelete.map((assetGroup) => Request.del<AssetGroupResult>(`assetGroup?Id=${assetGroup.assetGroup_Id}`, context.appState.authState)),
		])
	}

	const handleAssetTreeSave = async (successful: boolean, values: { asset: Asset }) => {
		// Asset is guaranteed to exist at this point
		if (asset) {
			// Handle Asset Tree Records
			// Get a list of asset trees that need to be deleted
			const deleteAssetTrees: AssetTree[] = asset.asset_Serviced.filter(
				(oldAt) => !values.asset.asset_Serviced.some((at) => at.assetTree_Id === oldAt.assetTree_Id)
			)
			await Promise.all(
				deleteAssetTrees.map(async (assetTree) => {
					await Request.handleRequest(() => Request.del<AssetTreeResult>(`assetTree?Id=${assetTree.assetTree_Id}`, context.appState.authState), {
						failedFunction: () => {
							successful = false
							setPageStatus('Error')
						},
					})
				})
			)

			// Get a list of asset trees that need to be created
			const newAssetTrees: AssetTree[] = values.asset.asset_Serviced.filter(
				(at) => !asset?.asset_Serviced.some((oldAt) => oldAt.assetTree_Id === at.assetTree_Id)
			)
			await Promise.all(
				newAssetTrees.map(async (assetTree) => {
					await Request.handleRequest(() => Request.post<AssetTreeResult>('assetTree', assetTree, context.appState.authState), {
						failedFunction: () => {
							successful = false
							setPageStatus('Error')
						},
					})
				})
			)
		}
	}

	const handleChannelMapSave = async (successful: boolean, values: { asset: Asset }) => {
		// Asset is guaranteed to exist at this point
		if (asset) {
			// Handle Channel Map Records
			// Get a list of channel maps that need to be deleted, all that have been removed or have null values
			const deleteChannelMap: ChannelMap[] = asset.channelMap
				.filter((oldAt) => !values.asset.channelMap.some((at: ChannelMap) => at.channelMap_Id === oldAt.channelMap_Id))
				.concat(values.asset.channelMap.filter((at: ChannelMap) => (!at.channelMap_TmvAssetId || !at.readingType_Id) && at.created.create_UserId))
			await Promise.all(
				deleteChannelMap.map(async (channelMap) => {
					await Request.handleRequest(() => Request.del<ChannelMapResult>(`channelMap?Id=${channelMap.channelMap_Id}`, context.appState.authState), {
						failedFunction: () => {
							successful = false
							setPageStatus('Error')
						},
					})
				})
			)

			// Get a list of channel maps that need to be created
			const newChannelMaps: ChannelMap[] = values.asset.channelMap.filter(
				(at) =>
					!asset.channelMap.some((oldAt) => oldAt.channelMap_Id === at.channelMap_Id) &&
					at.channelMap_TmvAssetId &&
					at.readingType_Id &&
					!at.created.create_UserId
			)
			await Promise.all(
				newChannelMaps.map(async (channelMap: ChannelMap) => {
					channelMap.created = defaultCreated({ create_UserId: context.appState.userAttributes.user_Id })
					channelMap.modified = defaultModified({ modified_UserId: context.appState.userAttributes.user_Id })
					await Request.handleRequest(() => Request.post<ChannelMapResult>('channelMap', channelMap, context.appState.authState), {
						failedFunction: () => {
							successful = false
							setPageStatus('Error')
						},
					})
				})
			)
		}
	}

	const handleSave = async (values: { asset: Asset }) => {
		setPageStatus('Submitting')
		let successful = true

		await Request.handleRequest(
			() =>
				Request.put<AssetResult>(
					'asset',
					{
						...values.asset,
						asset_InstallDate: values.asset.asset_InstallDate
							? formatOutgoingDateTime({
									dateTime: values.asset.asset_InstallDate,
									format: 'DateString',
									timeZone: context.appState.currentSite?.site_Timezone,
							  })
							: null,
					},
					context.appState.authState
				),
			{
				failedFunction: () => {
					successful = false
					setPageStatus('Error')
				},
				setMessageFunction: setMessages,
				messageAction: 'editing',
				messageObject: 'asset',
			}
		)

		await handleAssetTreeSave(successful, values)
		await handleChannelMapSave(successful, values)
		await handleSaveAssetGroups(values.asset)

		if (successful) {
			navigate(`/asset/${asset?.asset_Id}`)
		}
	}

	const handleDelete = async () => {
		await Request.handleRequest(() => Request.del<AssetResult>(`asset?Id=${asset?.asset_Id}`, context.appState.authState), {
			successFunction: () => {
				navigate('/assets')
			},
			failedFunction: () => {
				setPageStatus('Error')
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'asset',
		})
	}

	return (
		<div className="page-container">
			<Messages messages={messages} updateMessage={setMessages} />
			<ConfirmModal
				show={showModal}
				setShow={setShowModal}
				title={'Confirm delete'}
				body={'Are you sure you want to delete this asset? All of its documents will also be deleted.'}
				onConfirm={handleDelete}
			/>
			<Row className="page-header-row">
				<Col>
					<Back />
				</Col>
			</Row>
			{asset && (
				<Formik initialValues={{ asset }} validationSchema={assetValidationSchema} onSubmit={handleSave} enableReinitialize>
					{({ handleSubmit, errors, values, setFieldValue, setValues }) => (
						<>
							<Row style={styles.row}>
								<Col style={styles.cell}>
									<div className="page-header-primary" onClick={() => setEditAssetName(true)}>
										{editAssetName ? (
											<FormText
												name={'asset.asset_Name'}
												className="page-header-primary"
												value={values.asset.asset_Name || ''}
												onChange={(e) => setFieldValue('asset.asset_Name', e.target.value)}
												disabled={pageStatus === 'Submitting'}
												feedback={errors?.asset?.asset_Name}
												isInvalid={!!errors?.asset?.asset_Name}
											/>
										) : (
											<h1 className="page-header-primary">{asset.asset_Name}</h1>
										)}
									</div>
									<span className="page-header-divider">|</span>
									<h2 className="page-header-secondary">{asset.site_Name}</h2>
								</Col>
								<PermissionsCheck
									object={PermissionModelObject.Asset}
									action={PermissionModelAction.DELETE}
									context={PermissionModelContext.Site}
								>
									<Col sm={'auto'}>
										<Button variant={'danger'} onClick={() => setShowModal(true)}>
											Delete
										</Button>
									</Col>
								</PermissionsCheck>
							</Row>
							<Row style={styles.row}>
								<Line />
							</Row>
							<Row style={styles.row}>
								<h2 className="page-header-secondary">Asset Configuration</h2>
							</Row>
							<>
								{componentsByAssetType[asset.assetType_Id].map((Component, index) => (
									<Component
										key={index}
										asset={values.asset}
										errors={errors.asset}
										pageStatus={pageStatus}
										handleChange={setFieldValue}
										setMessages={setMessages}
									/>
								))}

								{(values.asset.assetClass_Id === assetClass.Hub.id || values.asset.assetClass_Id === assetClass.Transmitter.id) && (
									<ChannelMapping asset={values.asset} editMode={true} setValues={setValues} handleChange={setFieldValue} />
								)}

								<PermissionsCheck object={PermissionModelObject.Asset} action={PermissionModelAction.PUT} context={PermissionModelContext.Site}>
									<Row style={styles.row}>
										<Col sm={'1'}>
											<Button
												style={styles.button}
												variant={'secondary'}
												onClick={() => navigate(`/asset/${asset.asset_Id}`)}
												disabled={pageStatus === 'Submitting'}
											>
												Cancel
											</Button>
										</Col>
										<Col sm={'1'}>
											<Button style={styles.button} onClick={() => handleSubmit()} disabled={pageStatus === 'Submitting'}>
												{pageStatus === 'Submitting' ? <Loading show small variant={'light'} /> : 'Save'}
											</Button>
										</Col>
									</Row>
								</PermissionsCheck>
							</>
						</>
					)}
				</Formik>
			)}
		</div>
	)
}

interface CommonComponentProps {
	asset: Asset
	errors?: FormikErrors<Asset>
	pageStatus: PageStatus
	handleChange: (field: string, value: unknown) => void
	setMessages: (action: MessageAction) => void
}

const TMVandFixtureComponents = [AssetDetails, AssetContract, AssetLocation, AssetTreeEdit, AssetGroups, AssetInstallation, AssetFiles]
const commonComponents = [AssetDetails, AssetLocation, AssetTreeEdit, AssetGroups, AssetInstallation, AssetFiles]

const componentsByAssetType = {
	[assetType.Basin.id]: TMVandFixtureComponents,
	[assetType.Bath.id]: TMVandFixtureComponents,
	[assetType['Other Fixture'].id]: TMVandFixtureComponents,
	[assetType['RF Compact Transmitter'].id]: commonComponents,
	[assetType['RF Multi Transmitter'].id]: commonComponents,
	[assetType['SFM Standard Hub'].id]: commonComponents,
	[assetType['SFM TFP Hub'].id]: commonComponents,
	[assetType['Serial Port'].id]: commonComponents,
	[assetType.Gateway.id]: commonComponents,
	[assetType.Shower.id]: TMVandFixtureComponents,
	[assetType.Sink.id]: TMVandFixtureComponents,
	[assetType.Tub.id]: TMVandFixtureComponents,
	[assetType['Supply Line'].id]: commonComponents,
	[assetType.TMV.id]: TMVandFixtureComponents,
}

const styles: { [key: string]: React.CSSProperties } = {
	row: {
		marginTop: '40px',
	},
	cell: {
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center',
	},
	card: {
		marginTop: '20px',
	},
	button: {
		width: '100%',
	},
}

export { EditAssetDetails }
export type { CommonComponentProps }
