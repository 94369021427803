import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'
import { severity, SeverityId } from '../constants/severity'

interface BacnetTemperatureAlarmSetting {
	bacnetTemperatureAlarmSetting_Id: string
	bacnetTemperatureAlarmSetting_Temperature: number
	bacnetTemperatureAlarmSetting_Duration: number
	severity_Id: SeverityId
	severity_Name: string
	group_Id: string
	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface BacnetTemperatureAlarmSettingsResult extends Validate.Result {
	bacnetTemperatureAlarmSettings: BacnetTemperatureAlarmSetting[]
}

const defaultBacnetTemperatureAlarmSetting = (bacnetTemperatureAlarmSetting: Partial<BacnetTemperatureAlarmSetting>): BacnetTemperatureAlarmSetting => ({
	bacnetTemperatureAlarmSetting_Id: bacnetTemperatureAlarmSetting.bacnetTemperatureAlarmSetting_Id || '',
	bacnetTemperatureAlarmSetting_Temperature: bacnetTemperatureAlarmSetting.bacnetTemperatureAlarmSetting_Temperature || 0,
	bacnetTemperatureAlarmSetting_Duration: bacnetTemperatureAlarmSetting.bacnetTemperatureAlarmSetting_Duration || 0,
	severity_Id: bacnetTemperatureAlarmSetting.severity_Id || severity.High.id,
	severity_Name: bacnetTemperatureAlarmSetting.severity_Name || 'High',
	group_Id: bacnetTemperatureAlarmSetting.group_Id || '',
	created: bacnetTemperatureAlarmSetting.created || defaultCreated({}),
	modified: bacnetTemperatureAlarmSetting.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

/*
	This is the business logic for the default bacnet temperature alarm settings for a group.
*/

const defaultBacnetTemperatureAlarmSettingsForGroup = (group_Id: string, create_UserId: string) => {
	return [
		defaultBacnetTemperatureAlarmSetting({
			bacnetTemperatureAlarmSetting_Id: 'Default Bacnet Alarm Setting 1',
			bacnetTemperatureAlarmSetting_Temperature: 48,
			bacnetTemperatureAlarmSetting_Duration: 20,
			severity_Id: severity.Low.id,
			group_Id: group_Id,
			created: defaultCreated({ create_UserId: create_UserId }),
			modified: defaultModified({ modified_UserId: create_UserId }),
		}),
		defaultBacnetTemperatureAlarmSetting({
			bacnetTemperatureAlarmSetting_Id: 'Default Bacnet Alarm Setting 2',
			bacnetTemperatureAlarmSetting_Temperature: 50,
			bacnetTemperatureAlarmSetting_Duration: 300,
			severity_Id: severity.High.id,
			group_Id: group_Id,
			created: defaultCreated({ create_UserId: create_UserId }),
			modified: defaultModified({ modified_UserId: create_UserId }),
		}),
		defaultBacnetTemperatureAlarmSetting({
			bacnetTemperatureAlarmSetting_Id: 'Default Bacnet Alarm Setting 3',
			bacnetTemperatureAlarmSetting_Temperature: 55,
			bacnetTemperatureAlarmSetting_Duration: 15,
			severity_Id: severity.High.id,
			group_Id: group_Id,
			created: defaultCreated({ create_UserId: create_UserId }),
			modified: defaultModified({ modified_UserId: create_UserId }),
		}),
		defaultBacnetTemperatureAlarmSetting({
			bacnetTemperatureAlarmSetting_Id: 'Default Bacnet Alarm Setting 4',
			bacnetTemperatureAlarmSetting_Temperature: 60,
			bacnetTemperatureAlarmSetting_Duration: 3,
			severity_Id: severity.High.id,
			group_Id: group_Id,
			created: defaultCreated({ create_UserId: create_UserId }),
			modified: defaultModified({ modified_UserId: create_UserId }),
		}),
	]
}

export { defaultBacnetTemperatureAlarmSetting, defaultBacnetTemperatureAlarmSettingsForGroup }
export type { BacnetTemperatureAlarmSetting, BacnetTemperatureAlarmSettingsResult }
