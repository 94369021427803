import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { MenuItem } from './MenuItem'
import './Menu.css'

import dashboard from '../../../images/icons/dashboard.svg'
import assets from '../../../images/icons/assets.svg'
import alerts from '../../../images/icons/alert-critical.svg'
import tasks from '../../../images/icons/tasks.svg'
import reports from '../../../images/icons/graph.svg'
import trends from '../../../images/icons/trends.svg'
import settings from '../../../images/icons/site settings.svg'
import thermometer from '../../../images/icons/readings.svg'
import userManagement from '../../../images/icons/user management.svg'

import menuOpen from '../../../images/icons/side menu expand.svg'
import menuClose from '../../../images/icons/side menu collapsed.svg'

interface MenuProps {
	menuSelectedItem: string
}

export const Menu = (props: MenuProps) => {
	const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false)

	return (
		<Col className={menuIsOpen ? 'sideNav open' : 'sideNav closed'} sm={'auto'}>
			<MenuItem title="Dashboard" link={'/site'} icon={dashboard} isSelected={props.menuSelectedItem.endsWith('/site')} menuIsOpen={menuIsOpen} />
			<MenuItem
				title="Current Status"
				link={'/currentStatus'}
				icon={alerts}
				isSelected={props.menuSelectedItem.startsWith('/events')}
				menuIsOpen={menuIsOpen}
			/>
			<MenuItem title="Assets" link={`/assets`} icon={assets} isSelected={props.menuSelectedItem.startsWith('/assets')} menuIsOpen={menuIsOpen} />
			<MenuItem
				title="Live Temperatures"
				link={'/liveTemperatures'}
				icon={thermometer}
				isSelected={props.menuSelectedItem.startsWith('/liveTemperatures')}
				menuIsOpen={menuIsOpen}
			/>
			<MenuItem title="Tasks" link={'/tasks'} icon={tasks} isSelected={props.menuSelectedItem.startsWith('/tasks')} menuIsOpen={menuIsOpen} />
			<MenuItem title="Reports" link={'/reports'} icon={reports} isSelected={props.menuSelectedItem.startsWith('/reports')} menuIsOpen={menuIsOpen} />
			<MenuItem title="Trends" link={'/trends'} icon={trends} isSelected={props.menuSelectedItem.startsWith('/trends')} menuIsOpen={menuIsOpen} />
			<MenuItem
				title="User Management"
				link="/userManagementSite"
				icon={userManagement}
				isSelected={props.menuSelectedItem.startsWith('/userManagementSite')}
				menuIsOpen={menuIsOpen}
			/>
			<MenuItem
				title="Site Settings"
				link={'/siteSettings'}
				icon={settings}
				isSelected={props.menuSelectedItem.startsWith('/siteSettings')}
				menuIsOpen={menuIsOpen}
			/>
			<Row className="sideNav-burger-icon-row">
				<Col sm="auto">
					<div className={`sideNav-row`}>
						<img
							src={menuIsOpen ? menuClose : menuOpen}
							onClick={() => setMenuIsOpen(!menuIsOpen)}
							className="sideNav-icon"
							alt={'A Menu Icon'}
						></img>
					</div>
				</Col>
			</Row>
		</Col>
	)
}
