import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { FormText } from '../Form/Text'
import { FormStepProps } from '../../../screens/Contracts/ContractWizard'
import { FormDate } from '../Form/Date'
import plus from '../../../images/icons/add-white.svg'
import trash from '../../../images/icons/trash_white.svg'
import { FormNumber } from '../Form/Number'
import { FormikErrors, FormikTouched } from 'formik'
import { PaymentAgreement, defaultPaymentAgreement } from '../../../models/PaymentAgreement'
import { DateTime } from 'luxon'
import { formatIncomingDateTime } from '../../../utilities/formatDate'

const PaymentAgreementValidationSchema = Yup.object().shape({
	contract_PaymentAgreements: Yup.array().of(
		Yup.object().shape({
			paymentAgreement_OrderNumber: Yup.string().notRequired().max(36, 'Cannot be longer than ${max} characters.'),
			paymentAgreement_LineNumber: Yup.number().integer('Quantity must be a whole number'),
			paymentAgreement_StartDate: Yup.string()
				.notRequired()
				.test('validPAStartDate', 'Start Date cannot be later than End Date', (value, context) => {
					return value && context.parent.contract_EndDate ? value < context.parent.contract_EndDate : true
				}),
			paymentAgreement_EndDate: Yup.string()
				.notRequired()
				.test('validPAEndDate', 'End Date cannot be earlier than Start Date', (value, context) => {
					return value && context.parent.contract_StartDate ? value > context.parent.contract_StartDate : true
				}),
			paymentAgreement_AssetCount: Yup.number().integer('Quantity must be a whole number'),
			paymentAgreement_Note: Yup.string().notRequired().max(500, 'Cannot be longer than ${max} characters.'),
		})
	),
})

const PaymentAgreementsForm = (props: FormStepProps) => {
	const { values, setFieldValue, setFieldTouched, errors, touched, setTouched, setErrors } = props.formikProps

	const feedback = (index: number, property: keyof PaymentAgreement) => {
		return touched.contract_PaymentAgreements &&
			(touched.contract_PaymentAgreements[index] as FormikTouched<PaymentAgreement>)?.[property] &&
			errors.contract_PaymentAgreements &&
			(errors.contract_PaymentAgreements[index] as FormikErrors<PaymentAgreement>)?.[property]
			? ((errors.contract_PaymentAgreements[index] as FormikErrors<PaymentAgreement>)[property] as string)
			: ''
	}
	const isInvalid = (index: number, property: keyof PaymentAgreement) => {
		return touched.contract_PaymentAgreements &&
			(touched.contract_PaymentAgreements[index] as FormikTouched<PaymentAgreement>)?.[property] &&
			!!errors.contract_PaymentAgreements &&
			!!(errors.contract_PaymentAgreements[index] as FormikErrors<PaymentAgreement>)?.[property]
			? true
			: false
	}

	return (
		<>
			<Row>
				<Col>
					<span className="span-bold">Active Payment Agreements</span>
				</Col>
			</Row>

			{values.contract_PaymentAgreements.map((pa, index) => (
				<React.Fragment key={index}>
					<Card className="p-0">
						<Card.Header>
							<Row>
								<Col />
								<Col sm="auto">
									<Button
										style={styles.button}
										className="px-3"
										onClick={() => {
											setFieldValue(
												`contract_PaymentAgreements`,
												values.contract_PaymentAgreements.filter((_p, i) => i !== index)
											)
											setTouched({})
											setErrors({})
										}}
									>
										<div className="center-flex">
											<img src={trash} />
										</div>
									</Button>
								</Col>
							</Row>
						</Card.Header>
						<Card.Body>
							<Row>
								<Col>
									<FormText
										name={`contract_PaymentAgreements[${index}].paymentAgreement_OrderNumber`}
										label="Agreement ID"
										value={values.contract_PaymentAgreements[index].paymentAgreement_OrderNumber || ''}
										onChange={(e) =>
											setFieldValue(`contract_PaymentAgreements[${index}].paymentAgreement_OrderNumber`, e.currentTarget.value)
										}
										onBlur={() => setFieldTouched(`contract_PaymentAgreements[${index}].paymentAgreement_OrderNumber`)}
										feedback={feedback(index, 'paymentAgreement_OrderNumber')}
										isInvalid={isInvalid(index, 'paymentAgreement_OrderNumber')}
									/>
								</Col>
								<Col>
									<FormNumber
										name={`contract_PaymentAgreements[${index}].paymentAgreement_LineNumber`}
										label={'PO/Line Item #'}
										value={values.contract_PaymentAgreements[index].paymentAgreement_LineNumber || 0}
										onChange={(value) =>
											setFieldValue(`contract_PaymentAgreements[${index}].paymentAgreement_LineNumber`, Math.round(value))
										}
										onBlur={() => setFieldTouched(`contract_PaymentAgreements[${index}].paymentAgreement_LineNumber`)}
										min={0}
										feedback={feedback(index, 'paymentAgreement_LineNumber')}
										isInvalid={isInvalid(index, 'paymentAgreement_LineNumber')}
									/>
								</Col>
							</Row>
							<Row className="d-flex align-items-end">
								<Col>
									<FormDate
										name={`contract_PaymentAgreements[${index}].paymentAgreement_StartDate`}
										label={'Start Date'}
										value={values.contract_PaymentAgreements[index].paymentAgreement_StartDate || undefined}
										onChange={(e) =>
											setFieldValue(`contract_PaymentAgreements[${index}].paymentAgreement_StartDate`, e.currentTarget.value)
										}
										onBlur={() => setFieldTouched(`contract_PaymentAgreements[${index}].paymentAgreement_StartDate`)}
										feedback={feedback(index, 'paymentAgreement_StartDate')}
										isInvalid={isInvalid(index, 'paymentAgreement_StartDate')}
									/>
								</Col>
								<Col>
									<FormDate
										name={`contract_PaymentAgreements[${index}].paymentAgreement_EndDate`}
										label={'Start Date'}
										value={values.contract_PaymentAgreements[index].paymentAgreement_EndDate || undefined}
										onChange={(e) => setFieldValue(`contract_PaymentAgreements[${index}].paymentAgreement_EndDate`, e.currentTarget.value)}
										onBlur={() => setFieldTouched(`contract_PaymentAgreements[${index}].paymentAgreement_EndDate`)}
										feedback={feedback(index, 'paymentAgreement_EndDate')}
										isInvalid={isInvalid(index, 'paymentAgreement_EndDate')}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormNumber
										name={`contract_PaymentAgreements[${index}].paymentAgreement_AssetCount`}
										label={'Quantity'}
										value={values.contract_PaymentAgreements[index].paymentAgreement_AssetCount || 0}
										onChange={(value) =>
											setFieldValue(`contract_PaymentAgreements[${index}].paymentAgreement_AssetCount`, Math.round(value))
										}
										onBlur={() => setFieldTouched(`contract_PaymentAgreements[${index}].paymentAgreement_AssetCount`)}
										min={0}
										feedback={feedback(index, 'paymentAgreement_AssetCount')}
										isInvalid={isInvalid(index, 'paymentAgreement_AssetCount')}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormText
										name={`contract_PaymentAgreements[${index}].paymentAgreement_Note`}
										label="Notes"
										value={values.contract_PaymentAgreements[index].paymentAgreement_Note || ''}
										onChange={(e) => setFieldValue(`contract_PaymentAgreements[${index}].paymentAgreement_Note`, e.currentTarget.value)}
										onBlur={() => setFieldTouched(`contract_PaymentAgreements[${index}].paymentAgreement_Note`)}
										feedback={feedback(index, 'paymentAgreement_Note')}
										isInvalid={isInvalid(index, 'paymentAgreement_Note')}
										rows={3}
									/>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</React.Fragment>
			))}
			<Row>
				<Col>
					<Button
						style={styles.button}
						onClick={() =>
							setFieldValue('contract_PaymentAgreements', [
								...values.contract_PaymentAgreements,
								defaultPaymentAgreement({
									contract_Id: values.contract_Id,
									paymentAgreement_StartDate: formatIncomingDateTime({
										dateTime: DateTime.now().toISO({ includeOffset: false }) || '',
										format: 'FileDate',
									}),
									paymentAgreement_EndDate: formatIncomingDateTime({
										dateTime: DateTime.now().plus({ years: 1 }).toISO({ includeOffset: false }) || '',
										format: 'FileDate',
									}),
									created: values.created,
									modified: values.modified,
									recordStatus_Id: values.recordStatus_Id,
								}),
							])
						}
					>
						<div className="center-flex">
							<img src={plus} className="me-2" />
							Add Payment Agreement
						</div>
					</Button>
				</Col>
			</Row>
		</>
	)
}

const styles = {
	button: {
		minHeight: '38px',
	},
} satisfies Record<string, React.CSSProperties>

export { PaymentAgreementsForm, PaymentAgreementValidationSchema }
