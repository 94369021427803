import * as Validate from '../utilities/validate'
import { Created, defaultCreated, defaultModified, Modified } from './History'
import { recordStatus } from '../constants/recordStatus'

interface AssetModel {
	assetModel_Id: string
	assetModel_Name: string
	assetModel_Note: string | null
	assetType_Id: string
	assetMake_Id: string

	created: Created
	modified: Modified
	recordStatus_Id: string
}

interface AssetModelResult extends Validate.Result {
	assetModels: AssetModel[]
}

const defaultAssetModel = (assetModel: Partial<AssetModel>): AssetModel => ({
	assetModel_Id: assetModel.assetModel_Id || '',
	assetModel_Name: assetModel.assetModel_Name || '',
	assetModel_Note: assetModel.assetModel_Note || null,
	assetType_Id: assetModel.assetType_Id || '',
	assetMake_Id: assetModel.assetMake_Id || '',

	created: assetModel.created || defaultCreated({}),
	modified: assetModel.modified || defaultModified({}),
	recordStatus_Id: recordStatus.Active.id,
})

export { defaultAssetModel }
export type { AssetModel, AssetModelResult }
